import React, { useEffect, useState, useRef } from 'react'
import './projectStatePreview.css'
import PsBg from './it_process_BG.png'
import PsTTTSS from './TTTSPACESHIPLOGO.png'
import PsTTT from './LogoTTT.png'

const ProjectStatePreview = ({
    isOpen,
    setProjectState
}) => {
    const [test, setTest] = useState({ test: '' })
    const [checkState, setCheckState] = useState([
        {
            stateNo: 1,
            statePass: true,
            stateName: 'TOR',
            stateArc1: 'PROJECT TIMELINE',
            stateArc2: 'SITE MAP',
            stateArc3: 'INITIALIZE TEAM'
        },
        {
            stateNo: 2,
            statePass: true,
            stateName: 'USER ROLES',
            stateArc1: 'MAPPING USER',
            stateArc2: 'WORK FLOW',
            stateArc3: 'INTERVIEW REQUIRMENT'
        },
        {
            stateNo: 3,
            statePass: true,
            stateName: 'SRS',
            stateArc1: 'REVIEW & CONFIRM DOC.',
            stateArc2: 'TECH STACK',
            stateArc3: 'SCREEN MOCKUP'
        },
        {
            stateNo: 4,
            statePass: true,
            stateName: 'SDD',
            stateArc1: 'TECH TEAM REVIEW DOC.',
            stateArc2: 'OPEN API',
            stateArc3: 'ER DIAGRAM/DATA DIC.'
        },
        {
            stateNo: 5,
            statePass: true,
            stateName: 'TEST CASE',
            stateArc1: 'REVIEW & INSER TEST CASE',
            stateArc2: 'TEST RESULT',
            stateArc3: 'AUTOMATE TEST'
        },
        {
            stateNo: 6,
            statePass: true,
            stateName: 'UAT',
            stateArc1: 'UAT ON CUSTOMER SITE',
            stateArc2: 'TEST RUN',
            stateArc3: 'PRODUCTION'
        },
        {
            stateNo: 7,
            statePass: true,
            stateName: 'MA/SLA',
            stateArc1: 'MA/SLA DOC.',
            stateArc2: 'FEEDBACK HANDLING',
            stateArc3: 'MONITOR BUGS'
        }

    ])

    const [stateMember, setStateMember] = useState([1, 2, 3, 4, 5, 6, 7, 8])

    const [noteText, setNoteText] = useState({
        text: '7 Main Process & 21 Steps Auto 5 Reports \n● Project Plan & Timeline \n● Mapping Users \n● System Requirement Spec (SRS) \n● Test Result \n● Final Summary Project'
    })

    function setPsClose() {
        // window.location.href ='/StageMonitoring_package'
        setProjectState({ isOpen: false })
     
    }

    const elementRef1 = useRef();
    const elementRef2 = useRef();
    const elementRef3 = useRef();
    const elementRef4 = useRef();
    const elementRef5 = useRef();
    const elementRef6 = useRef();
    const elementRef7 = useRef();
    const [position, setPosition] = useState([
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 }
    ]);

    async function handleResize() {
        const x1 = elementRef1.current.offsetLeft;
        const y1 = elementRef1.current.offsetTop;
        const w1 = elementRef1.current.offsetWidth;
        const h1 = elementRef1.current.offsetHeight;

        const x2 = elementRef2.current.offsetLeft;
        const y2 = elementRef2.current.offsetTop;
        const w2 = elementRef2.current.offsetWidth;
        const h2 = elementRef2.current.offsetHeight;

        const x3 = elementRef3.current.offsetLeft;
        const y3 = elementRef3.current.offsetTop;
        const w3 = elementRef3.current.offsetWidth;
        const h3 = elementRef3.current.offsetHeight;

        const x4 = elementRef4.current.offsetLeft;
        const y4 = elementRef4.current.offsetTop;
        const w4 = elementRef4.current.offsetWidth;
        const h4 = elementRef4.current.offsetHeight;

        const x5 = elementRef5.current.offsetLeft;
        const y5 = elementRef5.current.offsetTop;
        const w5 = elementRef5.current.offsetWidth;
        const h5 = elementRef5.current.offsetHeight;

        const x6 = elementRef6.current.offsetLeft;
        const y6 = elementRef6.current.offsetTop;
        const w6 = elementRef6.current.offsetWidth;
        const h6 = elementRef6.current.offsetHeight;

        const x7 = elementRef7.current.offsetLeft;
        const y7 = elementRef7.current.offsetTop;
        const w7 = elementRef7.current.offsetWidth;
        const h7 = elementRef7.current.offsetHeight;

        const x11 = x2 - x1
        const y11 = y2 - y1

        const x12 = x3 - x2
        const y12 = y3 - y2

        const x13 = x4 - x3
        const y13 = y4 - y3

        const x14 = x5 - x4
        const y14 = y5 - y4

        const x15 = x6 - x5
        const y15 = y6 - y5

        const x16 = x7 - x6
        const y16 = y7 - y6

        setPosition([
            { x: x1, y: y1, x2: x11, y2: y11, w: w1, h: h1 },
            { x: x2, y: y2, x2: x12, y2: y12, w: w2, h: h2 },
            { x: x3, y: y3, x2: x13, y2: y13, w: w3, h: h3 },
            { x: x4, y: y4, x2: x14, y2: y14, w: w4, h: h4 },
            { x: x5, y: y5, x2: x15, y2: y15, w: w5, h: h5 },
            { x: x6, y: y6, x2: x16, y2: y16, w: w6, h: h6 },
            { x: x7, y: y7, x2: x16, y2: y16, w: w7, h: h7 }
        ])
    }

    useEffect(async () => {
        // await handleResize() // initial call to get position of the element on mount
        if (isOpen) {
            handleResize()
        }


            // console.log('testlog', position,);


            window.addEventListener("resize", handleResize);

            return () => window.removeEventListener("resize", handleResize);

    }, [isOpen]);
    // console.log('testlog', position[0]);

    return (
        <div className='pspBox' style={{ display: `${isOpen ? '' : 'none'}`,backgroundImage: `url(${PsBg})`}}>
            <div className='pspBoxSet'>
                <div className='pspBoxIn'>
                    <div className='row psBoxHeader' style={{margin:'0'}}>
                        <div className='col-2' style={{ margin: '0', padding: '0' }}>
                            <img className='pspTTTSPACESHIPLogo' src={PsTTTSS} alt='Logo'></img>
                        </div>
                        <div className='col-6' style={{ margin: '0', padding: '0 5px' }}>
                            <div className='pspProjectBox'>
                                <div className='pspProjectNameText'>PROJECT NAME:</div>
                                <input className='pspProjectTextBox'></input>
                                <div className='pspProjectLine'></div>
                                <div className='pspProjectLineDown'></div>
                                <div className='pspProjectBoxIn'>
                                    <div className='pspProjectNameTextTl'>PROJECT TIMELINE:</div>
                                    <input className='pspProjectTextBoxTl'></input>
                                    <div className='pspProjectNameTextTo'>TO</div>
                                    <input className='pspProjectTextBoxTl'></input>
                                </div>
                            </div>
                        </div>

                        <div className='col-4' style={{ margin: '0', padding: '0 5px' }}>
                            <div className='pspLogoLine'></div>
                            <div className='pspLogoLineDown'></div>
                            <div className='row pspLogoBox' style={{ margin: '0', padding: '5px' }}>
                                <div className='col-10 pspLogoTextBox' style={{ margin: '0', padding: '0 17.5px' }}>
                                    <div className='pspLogoText'>HEALT CHECKLIST</div>
                                    <div className='pspLogoDesc'>FOR IT IMPLEMENT PROCESS</div>
                                </div>
                                <div className='col-2' style={{ margin: '0', padding: '0', textAlign: 'left' }}>
                                    <img className='pspLogoImg' src={PsTTT}></img>
                                </div>
                            </div>
                        </div>


                        <div className="pspBoxClose" onClick={() => {
                            setPsClose()
                        }}><i class="fa fa-reply" aria-hidden="true"></i></div>
                    </div>
                    <div className='pspBoxBody'>
                        <div className='pspRoad'>
                            <div className='pspState' style={{ marginTop: '5%' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>1</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>TOR</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>PROJECT TIMELINE</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>SITE MAP</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>INITIALIZE TEAM</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '360px', left: '150px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>2</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>USERS ROLES</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>MAPPING USER</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>WORK FLOW</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>INTERVIEW REQUIRMENT</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '0px', left: '400px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>3</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>SRS</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>REVIEW & CONFIRM DOC.</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>TECH STACK</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>SCREEN MOCKUP</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '370px', left: '670px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>4</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>SDD</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>TECH TEAM REVIEW DOC.</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>OPEN API</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>ER DIAGRAM/DATA DIC.</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '30px', left: '700px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>5</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>TEST CASE</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>REVIEW & INSERT TEST CASE</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>TEST RESULT</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>AUTOMATE TEST</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '70px', left: '1060px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>6</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>UAT</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>UAT ON CUSTOMER SITE</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>TEST RUN</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>PRODUCTION</div>
                                </div>
                            </div>

                            <div className='pspState' style={{ top: '330px', left: '1060px' }}>
                                <div className='pspStateHead'>
                                    <div className='pspStateNoBox'>
                                        <div className='pspStateNoBoxTop'></div>
                                        <div className='pspStateNoBoxText'>7</div>
                                    </div>
                                    <div className='pspStateNameBox'>
                                        <div className='pspStateNameText'>MA/SLA</div>
                                        <div className='pspStateNameDesc'>TTT SPACE SHIP</div>
                                    </div>
                                </div>
                                <div className='pspStateArc mt-4'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>MA/SLA DOC.</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>FEEDBACK HANDLING</div>
                                </div>
                                <div className='pspStateArc'>
                                    <div className='pspStateArcCheck'></div>
                                    <div className='pspStateArcName'>MONITOR BUGS.</div>
                                </div>
                            </div>

                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '290px', left: '260px', transform: 'rotate(55deg)', animation: `${checkState[1].statePass ? 'psprocket1 5s infinite' : ''}`, display: `${checkState[1].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' id='element1' ref={elementRef1} style={{ top: '250px', left: '0px', backgroundImage: `${checkState[0].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[0].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[0].y2 < 0 ? (position[0].y - (position[0].y2 * -1)) + (position[0].h / 2) : position[0].y + (position[0].h / 2)}px`,
                                    left: `${position[0].x2 < 0 ? (position[0].x - (position[0].x2 * -1)) + (position[0].w / 2) : position[0].x + (position[0].w / 2)}px`,
                                    width: `${position[0].x2 < 0 ? position[0].x2 * -1 : position[0].x2}px`,
                                    height: `${position[0].y2 < 0 ? position[0].y2 * -1 : position[0].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[0].y2 < 0 ? position[0].y2 * -1 : position[0].y2}px`} width={`${position[0].x2 < 0 ? position[0].x2 * -1 : position[0].x2}px`}>
                                    <line
                                        x1={`${position[0].x2 < 0 ? position[0].x2 * -1 : '1'}px`}
                                        y1={`${position[0].y2 < 0 ? position[0].y2 * -1 : '1'}px`}
                                        x2={`${position[0].x2 < 0 ? '1' : position[0].x2}px`}
                                        y2={`${position[0].y2 < 0 ? '1' : position[0].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>

                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '207.5px', left: '560px', transform: 'rotate(26deg)', animation: `${checkState[2].statePass ? 'psprocket2 5s infinite' : ''}`, display: `${checkState[2].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' ref={elementRef2} style={{ top: '300px', left: '250px', backgroundImage: `${checkState[1].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[1].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[1].y2 < 0 ? (position[1].y - (position[1].y2 * -1)) + (position[1].h / 2) : position[1].y + (position[1].h / 2)}px`,
                                    left: `${position[1].x2 < 0 ? (position[1].x - (position[1].x2 * -1)) + (position[1].w / 2) : position[1].x + (position[1].w / 2)}px`,
                                    width: `${position[1].x2 < 0 ? position[1].x2 * -1 : position[1].x2}px`,
                                    height: `${position[1].y2 < 0 ? position[1].y2 * -1 : position[1].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[1].y2 < 0 ? position[1].y2 * -1 : position[1].y2}px`} width={`${position[1].x2 < 0 ? position[1].x2 * -1 : position[1].x2}px`}>
                                    <line
                                        x1={`${position[1].x2 < 0 ? position[1].x2 * -1 : '1'}px`}
                                        y1={`${position[1].y2 < 0 ? position[1].y2 * -1 : '1'}px`}
                                        x2={`${position[1].x2 < 0 ? '1' : position[1].x2}px`}
                                        y2={`${position[1].y2 < 0 ? '1' : position[1].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '315px', left: '675px', transform: 'rotate(100deg)', animation: `${checkState[3].statePass ? 'psprocket3 5s infinite' : ''}`, display: `${checkState[3].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' ref={elementRef3} style={{ top: '200px', left: '500px', backgroundImage: `${checkState[2].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[2].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[2].y2 < 0 ? (position[2].y - (position[2].y2 * -1)) + (position[2].h / 2) : position[2].y + (position[2].h / 2)}px`,
                                    left: `${position[2].x2 < 0 ? (position[2].x - (position[2].x2 * -1)) + (position[2].w / 2) : position[2].x + (position[2].w / 2)}px`,
                                    width: `${position[2].x2 < 0 ? position[2].x2 * -1 : position[2].x2}px`,
                                    height: `${position[2].y2 < 0 ? position[2].y2 * -1 : position[2].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[2].y2 < 0 ? position[2].y2 * -1 : position[2].y2}px`} width={`${position[2].x2 < 0 ? position[2].x2 * -1 : position[2].x2}px`}>
                                    <line
                                        x1={`${position[2].x2 < 0 ? position[2].x2 * -1 : '1'}px`}
                                        y1={`${position[2].y2 < 0 ? position[2].y2 * -1 : '1'}px`}
                                        x2={`${position[2].x2 < 0 ? '1' : position[2].x2}px`}
                                        y2={`${position[2].y2 < 0 ? '1' : position[2].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '242.5px', left: '865px', transform: 'rotate(12deg)', animation: `${checkState[4].statePass ? 'psprocket4 5s infinite' : ''}`, display: `${checkState[4].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' ref={elementRef4} style={{ top: '350px', left: '600px', backgroundImage: `${checkState[3].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[3].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[3].y2 < 0 ? (position[3].y - (position[3].y2 * -1)) + (position[3].h / 2) : position[3].y + (position[3].h / 2)}px`,
                                    left: `${position[3].x2 < 0 ? (position[3].x - (position[3].x2 * -1)) + (position[3].w / 2) : position[3].x + (position[3].w / 2)}px`,
                                    width: `${position[3].x2 < 0 ? position[3].x2 * -1 : position[3].x2}px`,
                                    height: `${position[3].y2 < 0 ? position[3].y2 * -1 : position[3].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[3].y2 < 0 ? position[3].y2 * -1 : position[3].y2}px`} width={`${position[3].x2 < 0 ? position[3].x2 * -1 : position[3].x2}px`}>
                                    <line
                                        x1={`${position[3].x2 < 0 ? position[3].x2 * -1 : '1'}px`}
                                        y1={`${position[3].y2 < 0 ? position[3].y2 * -1 : '1'}px`}
                                        x2={`${position[3].x2 < 0 ? '1' : position[3].x2}px`}
                                        y2={`${position[3].y2 < 0 ? '1' : position[3].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '252px', left: '1160px', transform: 'rotate(50deg)', animation: `${checkState[5].statePass ? 'psprocket5 5s infinite' : ''}`, display: `${checkState[5].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' ref={elementRef5} style={{ top: '230px', left: '750px', backgroundImage: `${checkState[4].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[4].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[4].y2 < 0 ? (position[4].y - (position[4].y2 * -1)) + (position[4].h / 2) : position[4].y + (position[4].h / 2)}px`,
                                    left: `${position[4].x2 < 0 ? (position[4].x - (position[4].x2 * -1)) + (position[4].w / 2) : position[4].x + (position[4].w / 2)}px`,
                                    width: `${position[4].x2 < 0 ? position[4].x2 * -1 : position[4].x2}px`,
                                    height: `${position[4].y2 < 0 ? position[4].y2 * -1 : position[4].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[4].y2 < 0 ? position[4].y2 * -1 : position[4].y2}px`} width={`${position[4].x2 < 0 ? position[4].x2 * -1 : position[4].x2}px`}>
                                    <line
                                        x1={`${position[4].x2 < 0 ? position[4].x2 * -1 : '1'}px`}
                                        y1={`${position[4].y2 < 0 ? position[4].y2 * -1 : '1'}px`}
                                        x2={`${position[4].x2 < 0 ? '1' : position[4].x2}px`}
                                        y2={`${position[4].y2 < 0 ? '1' : position[4].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket pspRocket" aria-hidden="true" style={{ color: 'white', top: '313px', left: '1208.5px', transform: 'rotate(123deg)', animation: `${checkState[6].statePass ? 'psprocket6 5s infinite' : ''}`, display: `${checkState[6].statePass ? '' : 'none'}` }}></i>
                            <div className='pspCircle' ref={elementRef6} style={{ top: '260px', left: '1000px', backgroundImage: `${checkState[5].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[5].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                            <div className='pspLineDashed'
                                style={{
                                    top: `${position[5].y2 < 0 ? (position[5].y - (position[5].y2 * -1)) + (position[5].h / 2) : position[5].y + (position[5].h / 2)}px`,
                                    left: `${position[5].x2 < 0 ? (position[5].x - (position[5].x2 * -1)) + (position[5].w / 2) : position[5].x + (position[5].w / 2)}px`,
                                    width: `${position[5].x2 < 0 ? position[5].x2 * -1 : position[5].x2}px`,
                                    height: `${position[5].y2 < 0 ? position[5].y2 * -1 : position[5].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[5].y2 < 0 ? position[5].y2 * -1 : position[5].y2}px`} width={`${position[5].x2 < 0 ? position[5].x2 * -1 : position[5].x2}px`}>
                                    <line
                                        x1={`${position[5].x2 < 0 ? position[5].x2 * -1 : '1'}px`}
                                        y1={`${position[5].y2 < 0 ? position[5].y2 * -1 : '1'}px`}
                                        x2={`${position[5].x2 < 0 ? '1' : position[5].x2}px`}
                                        y2={`${position[5].y2 < 0 ? '1' : position[5].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <div className='pspCircle' ref={elementRef7} style={{ top: '350px', left: '1020px', backgroundImage: `${checkState[6].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[6].statePass ? 'pspsetcircle 5s infinite' : ''}` }}></div>
                        </div>

                    </div>
                    <div className='row pspBoxFooter' style={{ margin: '0 auto', padding: '0 2.5%' }}>
                        <div className='col-5' style={{ margin: '0', padding: '0' }}>
                            <div className='pspMiniCheckText'>MINI CHECKLIST</div>
                            <div className='pspMiniCheckBox'>
                                {checkState.map((e, index) => {
                                    return (
                                        <div className='pspMiniCheckBoxIn'>
                                            {e.stateName}
                                            <div className='pspMiniBox'></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='col-2 pspSuccessfulText' style={{ margin: '0', padding: '35px 0' }}>SUCCESSFUL</div>
                        <div className='col-1 pspManager' style={{ margin: '0', padding: '20px 0' }}>
                            MANAGER
                            <div className='pspManagerBox'>
                                <div className='pspManagerBoxTop'></div>
                                <div className='pspManagerBoxText'>AVATAR</div>
                                <i class="fa fa-user" aria-hidden="true" style={{ fontSize: '25px' }}></i>
                            </div>
                        </div>
                        <div className='col-4 pspMember' style={{ margin: '0', padding: '20px 0' }}>
                            MEMBER:
                            <div className='pspMemberSet'>
                                {stateMember.map((e, index) => {
                                    return (
                                        <div className='pspMemberBox'>
                                            <div className='pspMemberBoxTop'></div>
                                            <div className='pspMemberBoxText'>AVATAR</div>
                                            <i class="fa fa-user" aria-hidden="true" style={{ fontSize: '22px' }}></i>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                    <div className='pspBottomLine'></div>
                    <div className='pspBottomLineBox1'></div>
                    <div className='pspBottomLineBox2'></div>
                </div>
            </div>
            <div className='pspBoxNote'>
                <div className='pspBoxNoteIn'>{noteText.text}</div>
            </div>
        </div>
    )
}

export default ProjectStatePreview;
