import React, { useState, useEffect, useRef } from 'react';
import "./cardstageStyle.css";
import moment from "moment";
import moreicon from '../..//assets/img/icon/more-vertical.png'
import icondate from '../../assets/img/images/calendar_story.png'
import iconhorizontal from '../../assets/img/icon/more-horizontal.png'
import chevrondown from '../../assets/img/icon/chevron-down.png'
import styled from "styled-components";
import Cardpercent from "./cardpercent";
import { Flex, Tooltip } from 'antd';
import axios from 'axios';
import Configs from "../../config";
import stakeholderBlue from '../../assets/img/iconSpaceShip/Icon_Stakeholder_Blue.png';
import '../../assets/css/OrganizeModal.css'
// import ModalSRS from '../Package/Srsmodal/modalsrs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faK, faL, faLariSign } from '@fortawesome/free-solid-svg-icons';
import { data } from 'jquery';
import { logDOM } from '@testing-library/react';
function Cardmodule(param) {

/*   param.data.module_menu_list = []; */
  // const [modalSrs, setModalSrs] = useState({
  //   isOpen: false,
  //   onMenu: '',
  //   nameMenu: ''
  // })
  const updateFillStatus = (processID) => {
    setFillStatus(processID);
    if (param.onFillStatusUpdate) {
      param.onFillStatusUpdate(processID);
    }
  };
  const [position, setPosition] = useState({
    posX: 0,
    posY: 0
  })
  const [modalStatus, setModalStatus] = useState(false);
  const [fillStatus, setFillStatus] = useState([]);
  const toggle_modal_status = () => setModalStatus(!modalStatus);
  /*   document.addEventListener('click',()=>{
      if(modalStatus == true){
        setModalStatus(false)
      }
      }) */
  // console.log('testlog',param);

  const iconStyle = {
    // color: 'rgb(0, 71, 255)',    // กำหนดสี
    fontSize: '22px',  // กำหนดขนาด
    // textShadow: '-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff',
    // filter: 'drop-shadow(0px 0px 1.5px #fff)',
    marginTop: '2px'
    // เพิ่ม style อื่น ๆ ตามต้องการ
  };

  //menu_
  const arrayLimit = param.data.module_menu_list.length;
  const [subMenu, setSubMenu] = useState([]);
  const [reload, setReload] = useState(0);
  const [resetMenu, setResetMenu] = useState(0);
  const [reset, setReset] = useState(localStorage.getItem('reset') ? true : false);

  const [processID, setProcessID] = useState([]);

  function sortMenu(data) {
  /*   console.log('เข้าว่ะ',data) */
    const map = new Map();

    data.forEach(item => {
      if (!map.has(item.menu_parent_id)) {
        map.set(item.menu_parent_id, []);
      }
      map.get(item.menu_parent_id).push(item);
    });

    function recursiveSort(parentId) {
      let items = map.get(parentId) || [];

      items.sort((a, b) => {
        let levelA = parseInt(a.menu_level);
        let levelB = parseInt(b.menu_level);
        let sortA = parseInt(a.menu_sort);
        let sortB = parseInt(b.menu_sort);

        if (levelA !== levelB) {
          return levelA - levelB;
        } else {
          return sortA - sortB;
        }
      });

      let result = [];
      items.forEach(item => {
        result.push(item);
        result = result.concat(recursiveSort(item.menu_id));
      });

      return result;
    }

    return recursiveSort(null);
  }
    useEffect(()=>{
      /* console.log('เข้าว่ะ') */
      if( param.data.module_menu_list ){
        param.data.module_menu_list = sortMenu(param.data.module_menu_list);
        setReload(reload + 1);
      }

    },[param.filterMenu])
  
useEffect(()=>{
  if( param.data.module_menu_list ){
  param.data.module_menu_list = sortMenu(param.data.module_menu_list);
        setReload(reload + 1);
  }
},[param.data])

  const addsubMenu = async () => {
    console.log( param.data.module_name,param.data.module_menu_list);
    // const initialArray = Array(arrayLimit).fill(null);
    const array = subMenu;
    let countdif = 0;
    if (subMenu.length > 0) {
      await param.data.module_menu_list.map((item) => {
        const hasId = subMenu.some(i => i.menu_id == item.menu_id);
        if (!hasId) {
          /* console.log(item); */
          const data = {
            menu_id: item.menu_id,
            opensub: /* item.menu_parent_id == null ? true : false */true,
            menu_parent_id: item.menu_parent_id
          }
          array.push(data);
         /*  setSubMenu(prevSub => [...prevSub, data]); */
        }else{
          let showIndex = array.findIndex(i => item.menu_id == i.menu_id);
          console.log('ssss',array[showIndex].menu_parent_id ,item.menu_parent_id);
          /* if (array[showIndex].menu_parent_id !== item.menu_parent_id) {
            console.log('ssss'); */
            array[showIndex].menu_parent_id = item.menu_parent_id
        /*     array[showIndex].opensub = true
           
          } */
         
          
        }
      });

      // subMenu.map((item) => {
      //   const isDelete = param.data.module_menu_list.some(i => i.menu_id == item.menu_id);
      //   if (!isDelete) {
      //     item.menu_id = '0';
      //     item.opensub = false;
      //   }
      // });

    } else {
  
      await param.data.module_menu_list.map((item, index) => {
        const data = {
          menu_id: item.menu_id,
          opensub: /* item.menu_parent_id == null ? true : false */true,
          menu_parent_id: item.menu_parent_id
        }
        array.push(data);
      });
      /* console.log(array); */
     /*  setSubMenu(array); */
    }
    console.log(countdif,array);
   /*  if (countdif > 0) {
      for (let i of array) {
        i.opensub = true
        
      }
    } */
    setSubMenu(array);
  }
  // console.log('sort ->:', param.data.module_menu_list);


  useEffect(() => {
    if (param.data.module_menu_list.length > 0) {
      addsubMenu();
  
    }
  });

  useEffect(() => {
    if (param.mode)
      setProcessID([])
  }, [param.mode]);

  console.log(subMenu);

  useEffect(() => {
    // Revert button colors when mode changes to false
    if (!param.mode) {
      const buttons = document.querySelectorAll('#button-module');
      buttons.forEach(button => {
        button.style.backgroundColor = button.dataset.originalColor;
      });
    }
  }, [param.mode]);

/*   useEffect(()=>{
    console.log('เข้ามั้ย')

  },[param.data]) */

  const findRelatedData = (data, targetId) => {
    const relatedData = [];

    // const findChildren = (parentId) => {
    //   data.forEach(item => {
    //     if (item.menu_parent_id === parentId) {
    //       relatedData.push(item.menu_name);
    //       findChildren(item.menu_name); // Recursively find children
    //     }
    //   });
    // };
    // // Start by finding the target item and its children
    // data.forEach(item => {
    //   if (item.menu_name === targetId) {
    //     relatedData.push(item.menu_name);
    //     findChildren(item.menu_name);
    //   }
    // });

    const findChildren = (parentId) => {
      data.forEach(item => {
        if (item.menu_parent_id === parentId) {
          relatedData.push(item.menu_id);
          findChildren(item.menu_id); // Recursively find children
        }
      });
    };

    // Start by finding the target item and its children
    data.forEach(item => {
      if (item.menu_id === targetId) {
        relatedData.push(item.menu_id);
        findChildren(item.menu_id);
      }
    });

    return relatedData;
  };

  const findLevel = (data, targetId) => {
    // const item = data.find(item => item.menu_name === targetId);
    // if (!item) return null; // Return null if the item is not found

    // let level = 0;
    // let currentItem = item;

    // // Traverse up the hierarchy to calculate the level
    // while (currentItem.menu_parent_id !== null) {
    //   currentItem = data.find(parent => parent.menu_name === currentItem.menu_parent_id);
    //   if (currentItem) {
    //     level++;
    //   } else {
    //     // Handle case where parent_id does not exist in data
    //     return null;
    //   }
    // }

    const item = data.find(item => item.menu_id === targetId);
    if (!item) return null; // Return null if the item is not found

    let level = 0;
    let currentItem = item;

    // Traverse up the hierarchy to calculate the level
    while (currentItem.menu_parent_id !== null) {
      currentItem = data.find(parent => parent.menu_id === currentItem.menu_parent_id);
      if (currentItem) {
        level++;
      } else {
        // Handle case where parent_id does not exist in data
        return null;
      }
    }

    return level;
  };

  useEffect(() => {
    function checkOverlap() {

      const headerElements = document.querySelectorAll('.header-processlist');
  
 
      if (headerElements.length > 0) {
   
        headerElements.forEach(headerElement => {
          const headerRect = headerElement.getBoundingClientRect();
  
          if (headerRect.top <= 350 ) {
            headerElement.style.backgroundColor = '#111215';
          } else {
            headerElement.style.backgroundColor = '';
          }
        });
      }

      requestAnimationFrame(checkOverlap);
    }
  
  
    checkOverlap();
  
  
    return () => cancelAnimationFrame(checkOverlap);
  }, [])

  return (
    <>

      <div className='fontOxygen' style={{ fontSize: '22px', textTransform: 'uppercase', }}>
        <img style={{ /* width: '2.4%', */ width:'26px', cursor: 'pointer', top: '2%', left: '0px', marginRight: '30px', transform: `${param.data.isuse === true ? 'rotate(0rad)' : 'rotate(55rad)'}` }} src={chevrondown}
          onClick={() => {
            param.setisusemodule(param.data.module_id);
          }}
        />
        {param.data.module_name}
        &nbsp;({param.data.module_menu_list.filter((e)=>{return e.menu_type == 'c7fe79cf-d110-46c1-b7be-2f70761e8d03'}).length} MENU , {param.data.module_menu_list.filter((e)=>{return e.menu_type == 'fb609df7-2e46-4a3b-83d8-ef6e3e78f35f'}).length} FEATURE)
      </div>
      
      <div>

        <div>
          <div className='fontOxygen col-6' style={{ fontSize: '16px', padding: '0px', marginLeft: '53px', textTransform: 'uppercase', }}>
            Period
            <div style={{ position: 'absolute', left: '11.8%', top: '-3px', width: '4.6%', marginLeft: '10px', }}>

              {/*    <i class="fa fa-calendar" aria-hidden="true" style={{ fontSize: '22px' }}></i> */}
              {/* <i class="fa fa-calendar" aria-hidden="true" style={{backgroundColor:'transparent'}}></i> */}
              <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />
              {/* <img style={{width:'100%'}} src={icondate} /> */}
            </div>



            <div style={{ marginLeft: '21%', marginTop: '-24px' }}>
              <b >
                {param.data.module_start_date !== undefined && param.data.module_start_date !== null && param.data.module_start_date !== '' ? moment(param.data.module_start_date).format('MMM  DD, YYYY') : 'No Date'} - {param.data.module_end_date !== undefined && param.data.module_end_date !== null && param.data.module_end_date !== '' ? moment(param.data.module_end_date).format('MMM  DD, YYYY') : 'No Date'}
              </b>

            </div>
          </div>
        </div>

        <div /* className='col-2' */ style={{ /* left: '74%', top: '10.5%', */display:'flex',alignItems:'center', width: '85%',position: 'absolute', left: '11%', justifyContent: 'flex-end', gap: '5px' }}>
          <div style={{width: '20%', position: 'relative',display:'flex',alignItems:'center'}}>
          <Cardpercent percent={parseFloat(param.data.module_progress || 0).toFixed(2)} mode />
          </div>
          

          <div style={{ /* position: 'absolute', left: '100%', */ /* top: '-43%', *//* marginTop:'4px', */ width:'70px',display:'flex',alignItems:'center',color: '#FFFFFF' }}>{parseFloat(param.data.module_progress || 0) < 100 ? parseFloat(param.data.module_progress || 0).toFixed(2).toString() : parseFloat(param.data.module_progress || 0).toFixed(2).toString()}%</div>

          <div style={{ textAlign: 'end'}}>
          {param.userismode === false ? (<>
            <img style={{ height: '100%', cursor: 'pointer', /* position: 'absolute',marginTop:'2px', *//*  top: '-120%', *//*  right: '-55%'  */}} src={iconhorizontal}
              onClick={() => {
                param.toggle_modal_config_module();
                param.GetModuleDeatil(param.data.module_id)
                
                localStorage.setItem('reset', true);
              }} /></>) : <></>}
          </div>

        </div>
      </div>

      {/* <div className='cardmoduleNoborder' style={{ display: param.data.isuse == false ? 'none' : 'block', marginLeft: '35px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <div style={{ width: '226px', border:'1px solid red', marginLeft: '30px'}}>

          </div>
          <div style={{ display: 'flex' }}>
            {param.processList.map((e) => {
              return (
                <div style={{ height: '10px', textAlign: 'center', fontSize: '9px', color: ' rgb(255, 255, 255)', margin: '0 15px', width: '50px' }}>{e.process_name}</div>
              )
            })}
          </div>
        </div>
      </div> */}


      <div className={param.data.isuse == false ? 'cardmodulenone' : 'cardmodule2' /* param.data.isuse === true ? 'cardmodule' : 'cardmodulenone' */} style={{ marginBottom: '30px' }}>
        <div className="row" style={{ border: '0px solid green', marginBottom: '40px', display: `${param.data.isuse === true ? '' : 'none'}` }}>
          <div className="col-12 table">
            <div className="rowz"
              style={{ paddingLeft: 0, border: '0px solid red', display: `${param.data.isuse == false ? 'none' : '' /* param.data.isuse === true ? '' : 'none' */}`, justifyContent: 'space-between' }}>
              <div className='header-processlist'
                style={{ display: 'flex', justifyContent: 'space-between', width: '100%', zIndex: 1 }}>
                <div>

                </div>
                <div style={{ display: 'flex', marginTop: '17.5px', marginBottom: '17.5px', height: '100%', /* marginLeft: '30px',  *//* width: '75%' */ }}>
                  {param.processList.map((e) => {
                    return (
                      <span
                        style={{ height: '15px', textAlign: 'center', fontSize: '9px', color: ' rgb(255, 255, 255)', margin: '0 15px', width: '51px' }}>{e.process_name}</span>
                    )
                  })}
                </div>
              </div>

              <div style={{ display: 'flex', border: '1px solid blue', width: '100%', paddingBottom: '2px', justifyContent: 'space-between' }}>
                {param.data.module_menu_list.length > 0 ? <div style={{ /* width: '25%',  */overflowX: 'auto', marginLeft: '12px' }}>
                  {
                    param.data.module_menu_list.map((e2, index) => {
                      if (e2.menu_id && e2.menu_name) {
                        const level = findLevel(param.data.module_menu_list, e2.menu_id);
                        const showIndex = subMenu.findIndex(i => e2.menu_id == i.menu_id);
                        const nextIndex = subMenu.findIndex(i => param.data.module_menu_list[index + 1]?.menu_id == i.menu_id);
                        // console.log(`${e2.menu_name} - ${e2.menu_id}: `, subMenu[nextIndex]);
                        if (showIndex != -1) {
                          return (
                            <>
                              <div style={{ display: subMenu[showIndex].opensub == false ? 'none' : '', whiteSpace: 'nowrap', margin: '5px 0', height: '27px' }}>
                                {
                                  subMenu[nextIndex]?.menu_parent_id == e2.menu_id ?
                                    <div style={{ display: 'inline' }}>
                                      <img src={chevrondown} style={{ transform: subMenu[nextIndex].opensub ? 'rotate(0rad)' : 'rotate(55rad)', cursor: 'pointer', width: '25px', margin: `0 0 0 ${level * 17}px` }}
                                        onClick={() => {
                                          const indexSub = subMenu.findIndex(i => param.data.module_menu_list[index + 1].menu_id == i.menu_id);
                                          console.log(indexSub);
                                          const id = findRelatedData(param.data.module_menu_list, e2.menu_id);
                                          const filterId = id.filter((i) => e2.menu_id !== i);
                                          if (indexSub != -1 && subMenu[indexSub].opensub == true) {
                                           /*  console.log('id- relate: ->', id);
                                            console.log('e2.menu_ide2.menu_id: ', e2.menu_id)
                                            console.log(filterId); */
                                            subMenu.map((item) => {
                                              filterId.forEach(fil => {
                                                if (fil == item.menu_id) {
                                                  item.opensub = false;
                                                }
                                              })
                                            })

                                          } else if (indexSub != -1) {
                                            param.data.module_menu_list.map(i => {
                                              if (i.menu_parent_id == e2.menu_id) {
                                                subMenu.forEach(subItem => {
                                                  if (subItem.menu_id == i.menu_id) {
                                                    subItem.opensub = true;
                                                  }
                                                })
                                              }
                                            });
                                          }
                                          setReload(reload + 1);
                                        }}
                                      />
                                      <span
                                        // onClick={()=>{

                                        //   setModalSrs({...modalSrs,isOpen: true,onMenu: e2.menu_id,nameMenu: e2.menu_name})
                                        //   modalSrs.isOpen = true
                                        //   let vv = {
                                        //     isOpen: true,
                                        //     onMenu: e2.menu_id,nameMenu: e2.menu_name
                                        //   }
                                        //   param.callback(vv)
                                        // }}
                                        style={{ maxWidth: '240px', fontSize: '14px', color: '#fff', margin: `0 0 0` }}>{e2.menu_name}
                                      </span>
                                    </div>
                                    :
                                    <>
                                      <span
                                        // onClick={()=>{

                                        //   setModalSrs({...modalSrs,isOpen: true,onMenu: e2.menu_id,nameMenu: e2.menu_name})
                                        //   modalSrs.isOpen = true
                                        //   let vv = {
                                        //     isOpen: true,
                                        //     onMenu: e2.menu_id,nameMenu: e2.menu_name
                                        //   }
                                        //   param.callback(vv)
                                        // }}
                                        style={{ maxWidth: '240px', fontSize: '14px', color: '#fff', margin: `0 0 0 ${(level * 17) + 25}px` }}>{e2.menu_name}
                                      </span>
                                    </>
                                }
                                <a href="javascript:null"
                                  onClick={(ee) => {
                                    const element = document.getElementById("moduleMenuDiv");
                                    console.log(element.offsetTop)
                                    let xx = ee.clientX/*  - (element.offsetLeft) */;
                                    let yy = ee.clientY /* - (element.offsetTop) */;


                                    param.CalPosition(xx, yy, 'stakeholder')
                                    param.setID2(e2.menu_id, e2.stakeholder_list,e2.qdc,param.data.module_id)

                                  }}

                                >
                                  {/*        <img className = "ml-1" style= {{width:'15px'}} src = {stakeholderBlue}/> */}
                                  <i class="fa fa-users fa-fw ml-1" style={{ color: e2.stakeholder_list.length > 0 ? '#00AB11' : '#FF1414' }} aria-hidden="true"></i>
                                </a>
                              </div>
                            </>)
                        }
                      }
                    })
                  }
                </div>
                  : <div style={{/*  minWidth: '226px', */ overflowX: 'auto', marginLeft: '12px', marginBottom: '60px' }}>
                    {
                      param.data.module_menu_list.map((e2, index) => {
                        if (e2.menu_id && e2.menu_name) {
                          const level = findLevel(param.data.module_menu_list, e2.menu_id);
                          const showIndex = subMenu.findIndex(i => e2.menu_id == i.menu_id);
                          const nextIndex = subMenu.findIndex(i => param.data.module_menu_list[index + 1]?.menu_id == i.menu_id);
                          // console.log(`${e2.menu_name} - ${e2.menu_id}: `, subMenu[nextIndex]);
                          if (showIndex != -1) {
                            return (
                              <>
                                <div style={{ display: subMenu[showIndex].opensub == false ? 'none' : '', whiteSpace: 'nowrap', margin: '5px 0', height: '27px' }}>
                                  {
                                    subMenu[nextIndex]?.menu_parent_id == e2.menu_id ?
                                      <div style={{ display: 'inline' }}>
                                        <img src={chevrondown} style={{ transform: subMenu[nextIndex].opensub ? 'rotate(0rad)' : 'rotate(55rad)', cursor: 'pointer', width: '25px', margin: `0 0 0 ${level * 17}px` }}
                                          onClick={() => {
                                            const indexSub = subMenu.findIndex(i => param.data.module_menu_list[index + 1].menu_id == i.menu_id);
                                            console.log(indexSub);
                                            const id = findRelatedData(param.data.module_menu_list, e2.menu_id);
                                            const filterId = id.filter((i) => e2.menu_id !== i);
                                            if (indexSub != -1 && subMenu[indexSub].opensub == true) {
                                              console.log('id- relate: ->', id);
                                              console.log('e2.menu_ide2.menu_id: ', e2.menu_id)
                                              console.log(filterId);
                                              subMenu.map((item) => {
                                                filterId.forEach(fil => {
                                                  if (fil == item.menu_id) {
                                                    item.opensub = false;
                                                  }
                                                })
                                              })

                                            } else if (indexSub != -1) {
                                              param.data.module_menu_list.map(i => {
                                                if (i.menu_parent_id == e2.menu_id) {
                                                  subMenu.forEach(subItem => {
                                                    if (subItem.menu_id == i.menu_id) {
                                                      subItem.opensub = true;
                                                    }
                                                  })
                                                }
                                              });
                                            }
                                            setReload(reload + 1);
                                          }}
                                        />
                                        <span
                                          // onClick={()=>{

                                          //   setModalSrs({...modalSrs,isOpen: true,onMenu: e2.menu_id,nameMenu: e2.menu_name})
                                          //   modalSrs.isOpen = true
                                          //   let vv = {
                                          //     isOpen: true,
                                          //     onMenu: e2.menu_id,nameMenu: e2.menu_name
                                          //   }
                                          //   param.callback(vv)
                                          // }}
                                          style={{ maxWidth: '240px', fontSize: '14px', color: '#fff', margin: `0 0 0` }}>{e2.menu_name}
                                        </span>
                                      </div>
                                      :
                                      <>
                                        <span
                                          // onClick={()=>{

                                          //   setModalSrs({...modalSrs,isOpen: true,onMenu: e2.menu_id,nameMenu: e2.menu_name})
                                          //   modalSrs.isOpen = true
                                          //   let vv = {
                                          //     isOpen: true,
                                          //     onMenu: e2.menu_id,nameMenu: e2.menu_name
                                          //   }
                                          //   param.callback(vv)
                                          // }}
                                          style={{ maxWidth: '240px', fontSize: '14px', color: '#fff', margin: `0 0 0 ${(level * 17) + 25}px` }}>{e2.menu_name}
                                        </span>
                                      </>
                                  }
                                  <a href="javascript:null"
                                    onClick={(ee) => {
                                      const element = document.getElementById("moduleMenuDiv");
                                      console.log(element.offsetTop)
                                      let xx = ee.clientX/*  - (element.offsetLeft) */;
                                      let yy = ee.clientY /* - (element.offsetTop) */;


                                      param.CalPosition(xx, yy, 'stakeholder')
                                      param.setID2(e2.menu_id, e2.stakeholder_list,e2.qdc,param.data.module_id);
                              

                                    }}

                                  >
                                    {/*        <img className = "ml-1" style= {{width:'15px'}} src = {stakeholderBlue}/> */}
                                    <i class="fa fa-users fa-fw ml-1" style={{ color: e2.stakeholder_list.length > 0 ? '#00AB11' : '#FF1414' }} aria-hidden="true"></i>
                                  </a>
                                </div>
                              </>)
                          }
                        }
                      })
                    }
                  </div>
                }
                <div /* style={{ width: '75%', marginLeft: '10px' }} */>
                  <div>
                    {
                      param.data.module_menu_list.map((e2) => {
                        if (e2.menu_id && e2.menu_name) {
                          const showIndex = subMenu.findIndex(i => e2.menu_id == i.menu_id);
                          return (
                            <div style={{ display: subMenu[showIndex]?.opensub == false ? 'none' : 'flex', height: '27px', margin: '5px 0', width: '100%', alignItems: 'center' }}>
                              {
                                e2.process_list.map((e, i) => {
                                  let clip = "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)";
                                  if (i == 0) {
                                    clip = "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)";
                                  } else if (i + 1 === param.processList.length) {
                                    clip = "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)";
                                  }
                                  return (

                                    <span>
                                      <div onClick={(ee) => {
                                        if (param.mode == true) {
                                          ee.target.style.backgroundColor = param.selectedColor;
                                          console.log(param.selectedColor)

                                          let Status_id = ""
                                          if (param.selectedColor == "#808080") {
                                            Status_id = "02e2fbdb-1350-48aa-8bc6-e86c07f9035b";
                                          } else if (param.selectedColor == "#F00") {
                                            Status_id = "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8";
                                          } else if (param.selectedColor == "#FFFF00") {
                                            Status_id = "f00e63f2-ab27-4828-9d7a-f9316c28703e";
                                          } else {
                                            Status_id = "";
                                          }
                                          const newStatus = {
                                            process_id: e.process_id,
                                            menu_id: e.sm_menu_id,
                                            status_id: Status_id
                                          };
                                          // console.log(processID)
                                          // setFillStatus(processID)
                                          updateFillStatus(newStatus)
                                        } else {

                                          toggle_modal_status();
                                          const element = document.getElementById("moduleMenuDiv");
                                          console.log(element.offsetTop)
                                          let xx = ee.clientX/*  - (element.offsetLeft) */;
                                          let yy = ee.clientY /* - (element.offsetTop) */;

                                          /*      setPosition({...position,posX:xx,posY:yy})
                                              position.posX = xx;
                                              position.posY = yy; */
                                          param.CalPosition(xx, yy, 'stage')
                                          param.setID(e.process_id, e2.menu_id)
                                          /*   param.printMousePos();
                                            param.toggle_modal_status(); */
                                          /* param.isMouse(); */
                                          /*    param.isModal(); */
                                          ee.target.style.backgroundColor = e.ps_color;
                                          /* console.log("process_id",e.process_id,"menu_id",e2.menu_id) */

                                        }
                                      }}
                                        id="button-module"
                                        style={{ clipPath: clip, width: '50px', height: '10px', background: e.ps_color, border: '0px', cursor: 'pointer', margin: '0 15px' }}
                                        data-original-color={e.ps_color}
                                      >
                                      </div>
                                    </span>

                                  )
                                })
                              }
                            </div>
                          )
                        }

                      })
                    }

                  </div>
                </div>
              </div>
              {/* <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>MOCKUP </div> 
                    <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>FRONT-END</div>
                    <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>BACK-END</div>
                    <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>DATABASE</div>
                    <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>INITIAL DATA</div>
                    <div style={{width:'10.28%',height:'10px',marginRight:'25px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>IMPLEMENT</div>
                    <div style={{width:'10.28%',height:'10px',textAlign:'center',fontSize:'9px',color:' rgb(255, 255, 255,0.5)'}}>TESTING &  DEPLOY</div> 
                  */}
            </div>
          </div>
        </div>


      </div>


      {/* {modalStatus && (
  <div className={`detailBox opened`} 
            
  style={{top:`${position.posY}px`,left:`${position.posX}px`,position:'absalute'}}
 >
      <div className='row'>
        
          <div className='col-9'>
              <div className='textFed'>FEDERATION DETAIL</div>
              <div className='textBus'>TTT BROTHER</div>
          </div>

      </div>
      
  </div>
)} */}





      {/*       <ModalSRS 
        isOpen={modalSrs.isOpen}
      /> */}
    </>
  );
}

export default Cardmodule;
