import React, { useState } from 'react';
import Slider from 'react-slick';
import '../../assets/css/information.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";
import Modal from "../../components/Modalpopup/newsModalPopUp";

const title = "wow";
const description = "asffsagdsagfdagdsa"
const Information = () => {
  const [news, setNews] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        }
      },
    ]
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModel, setDataModal] = useState({
    titlename: 'Lorem ipsum.',
    dateValue: '',
    type: 'TTT Spaceship story',
    datas: 'Desember 19, 2023',
    link:'URL : https://tttbrother.com',
    language:'thai'
  })

  const openModalPromotion = () => {
    setDataModal({...dataModel,
      titlename: 'Lorem ipsum.',
      dateValue: `Lorem ipsum dolor sit amet consectetur. 
Velit nec ut donec nulla feugiat malesuada volutpat quis. Ultricies facilisis interdum semper nulla amet. 
Nibh etiam cursus mattis tristique dui. Auctor ac in consectetur porttitor sem eu viverra natoque condimentum.`,
      type: 'PROMOTION',
      datas: 'Desember 19, 2023',
      link:'',
        language:'eng'
    })
    setIsModalOpen(true);
    document.body.classList.add('modal-opened');
  };

  const openModal = () => {
    setDataModal({...dataModel,
      titlename: 'Lorem ipsum.',
      dateValue: `จุดเริ่มต้นของ TTT Spaceship

ในอวกาศที่กว้างใหญ่ ที่ดวงดาวส่องแสงทับซ้อนกันเสมือนอัญมณีที่กระจายอยู่ ยุคของการสำรวจ และการผจญภัยในอวกาศได้เกิดขึ้นแล้วบนจักรวาล ซึ่งเป็นเวลาที่การท้าทายของความรู้ ความแข็งแกร่ง และการค้นพบที่ซ่อนอยู่ในพื้นที่ที่กว้างใหญ่ของจักรวาล ในยุคของการสำรวจ และการผจญภัยของอวกาศนี้ ได้กำเนิดอีกหนึ่งกิจกรรมที่ยิ่งใหญ่เกิดขึ้น คือ "การแข่งขันแห่งความเป็นผู้นำ” ซึ่งเป็นการทดสอบระดับตำนาน ที่รวบรวมกลุ่มของผู้เดินทางที่มีความเชี่ยวชาญ และมีความกล้าหาญพร้อมที่จะผจญภัยจากกาแลคซี่ต่าง ๆ เพื่อแข่งขันในการเดินทางที่เรียกว่าการผจญภัยอันยิ่งใหญ่ของจักรวาล
      
ในยุคของความเป็นไปได้ที่ไม่มีขีดจำกัดนี้ การสำรวจ และการผจญภัยอาจพบเจอกับเหตุการณ์น่าตื่นเต้นต่าง ๆ มากมาย รวมไปถึงสิ่งที่ทำให้คุณอาจสูญเสียบางสิ่งไป เป็นเหตุการณ์ต่าง ๆ ที่ไม่สามารถคาดเดาได้ การเตรียมตัวสำหรับการผจญภัยในแต่ละครั้งนั้น จึงเป็นสิ่งที่สำคัญสำหรับนักผจญภัย ไม่ว่าจะเป็นเรื่องของการมี Federation ที่ให้การสนับสนุนยานอวกาศของทีมนักผจญภัยในการเดินทางไปกับการทำภารกิจต่าง ๆ ของการผจญภัยในทุก ๆ รอบ หรือสมาชิกของยานอวกาศที่มีความรู้ ความแข็งแกร่ง และความสามารถที่จะช่วยนำพายานอวกาศของคุณผ่านไปในทุก ๆ ช่วงของเหตุการณ์ต่าง ๆ ทั้งเรื่องดี และเรื่องร้าย
      
โดยการผจญภัยไปทำภารกิจในแต่ละรอบเจ้าของยานอวกาศ หรือหัวหน้ายานอวกาศสามารถเลือกสมาชิกของยานอวกาศเพื่อเข้าไปทำภารกิจในแต่ละภารกิจตามความเหมาะสมกับขนาดของยานอวกาศ ซึ่งสมาชิกแต่ละคนในทีมมาพร้อมกับทักษะ และประสบการณ์ที่ไม่เหมือนกัน เพราะฉะนั้นแล้วการเลือกผู้ร่วมเดินทางไปกับคุณด้วยนั้น คุณควรที่จะพิจารณาให้เป็นอย่างดี เพราะคุณต้องเดินทางในที่ที่เต็มไปด้วยเหตุการณ์ที่คุณไม่อาจคาดเดาได้นั้นเอง รวมถึงการมีสมาชิกทีมที่ช่วยกันรับรู้ถึงปัญหา และพยายามช่วยกันแก้ไขปัญหาไปด้วยกันคงจะเป็นเรื่องที่ดีไม่น้อย
      
ทั้งนี้ที่ทางแอดมินหวังว่าทุก ๆ ท่านจะเข้ามาร่วมการผจญภัยไปกับพวกเราใน TTT Spaceship แห่งนี้นะครับ บทความข้างต้นเป็นเพียงแค่ส่วนหนึ่งของการผจญภัย บทความบทต่อไปจะเป็นอย่างไร อย่าลืมติดตามกันด้วยน้า`,
      type: 'TTT Spaceship story',
      datas: 'Desember 19, 2023',
      link:'URL : https://tttbrother.com',
      language:'thai'
    })
    setIsModalOpen(true);
    document.body.classList.add('modal-opened');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('modal-opened');
  };

  return (
    <>
      <HeaderTTT />
      <img className='bg-information' src="../assets/img/bg/game_manage_bg.jpg" alt="" />
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-12 p-3 mb-2 bg-dark tournament-schedule-wrap">
              <div className="banner-content">
                <h3 class="text-white text-center">News</h3>
              </div>
              {/* <div className="banner-news-heading">
            <div className="headline">
              <h5>News <span>Update</span></h5>
              <p>Here’s an updated look at the 2020 NFL.</p>
            </div>
            <div className="social-link">
              <ul>
                <li><a href="/#"><i className="fab fa-facebook-f" /></a></li>
                <li><a href="/#"><i className="fab fa-twitter" /></a></li>
                <li><a href="/#"><i className="fab fa-pinterest-p" /></a></li>
              </ul>
            </div>
          </div> */}
              <Slider className="banner-magazine-active" {...settings}>
                <div className="banner-magazine-item red">
                  <div className="banner-magazine-thumb">
                    <div className="tags"><a>sports</a></div>
                    <a href="/Info">
                      <img src="assets/img/slider/banner_magazine_thumb02.jpg" alt="" />
                    </a>
                  </div>
                  <div className="banner-magazine-content">
                    <span className="date">December 19, 2022</span>
                    <h5 className="title"><a href="/Info">UN chief condems atrocious Ghana killings</a></h5>
                  </div>
                </div>

                <div className="banner-magazine-item blue">
                  <div className="banner-magazine-thumb">
                    <div className="tags"><a>sports</a></div>
                    <a href="/Info">
                      <img src="assets/img/slider/banner_magazine_thumb03.jpg" alt="" />
                    </a>
                  </div>
                  <div className="banner-magazine-content">
                    <span className="date">December 19, 2022</span>
                    <h5 className="title"><a href="/Info">Hunger still quite persists in many of India's tribal</a></h5>
                  </div>
                </div>
                <div className="banner-magazine-item">
                  <div className="banner-magazine-thumb">
                    <div className="tags"><a>sports</a></div>
                    <a href="/Info">
                      <img src="assets/img/slider/banner_magazine_thumb04.jpg" alt="" />
                    </a>
                  </div>
                  <div className="banner-magazine-content">
                    <span className="date">December 19, 2022</span>
                    <h5 className="title"><a href="/Info">Hunger still quite persists in many of India's tribal</a></h5>
                  </div>
                </div>
                <div className="banner-magazine-item blue">
                  <div className="banner-magazine-thumb">
                    <div className="tags"><a>sports</a></div>
                    <a href="/Info">
                      <img src="assets/img/slider/banner_magazine_thumb05.jpg" alt="" />
                    </a>
                  </div>
                  <div className="banner-magazine-content">
                    <span className="date">December 19, 2022</span>
                    <h5 className="title"><a href="/Info">Hunger still quite persists in many of India's tribal</a></h5>
                  </div>
                </div>
                <div className="banner-magazine-item">
                  <div className="banner-magazine-thumb">
                    <div className="tags"><a>sports</a></div>
                    <a href="/Info">
                      <img src="assets/img/slider/banner_magazine_thumb01.jpg" alt="" />
                    </a>
                  </div>
                  <div className="banner-magazine-content">
                    <span className="date">December 19, 2022</span>
                    <h5 className="title"><a href="/Info">Hunger the night before in the NFL</a></h5>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="button-header">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-3 col-md-6 col-sm-12 d-flex justify-content-center d-flex justify-content-center">
                <button className="btn col-xl-8 col-md-6 col-sm-12">ทั้งหมด</button>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 d-flex justify-content-center">
                <button className="btn col-xl-8 col-md-6 col-sm-12" onClick={openModal}>ประกาศ</button>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 d-flex justify-content-center">
                <button className="btn col-xl-8 col-md-6 col-sm-12">กิจกรรม</button>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 d-flex justify-content-center">
                <button className="btn col-xl-8 col-md-6 col-sm-12" onClick={openModalPromotion} >โปรโมชั่น</button>
              </div>

            </div>
          </div>
        </div>

        <div className="container">
          <div className="p-3 mb-5 bg-dark tournament-schedule-wrap">

            {news.map((e) => {
              return (<>
                <div className="card-schedule-wrap">
                  <div className="tournament-active">
                    <div className="single-tournament mb-15 grid-item cat-three cat-two">
                      <div className="row no-gutters card-news">
                        <div className="col-lg-4">
                          <div className="tournament-schedule-item" style={{ height: "300px" }}>
                            <a href="/Info"><img className="img-fluid" src="assets/img/slider/banner_magazine_thumb03.jpg" alt="" /></a>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="tournament-schedule-content">
                            <h3><a href="/Info">{title} </a></h3>
                            <p>{description}</p>
                            <div className="tournament-schedule-meta">
                              <a href="/Info">READ MORE</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>

              </>
              );

            })}

            <div className="pagination-wrap mt-60">
              <ul>
                <li><a href="/#">first</a></li>
                <li><a href="/#">1</a></li>
                <li><a href="/#">2</a></li>
                <li><a href="/#">next</a></li>
              </ul>
            </div>
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          headType={dataModel.type}
          titleName={dataModel.titlename}
          datas={dataModel.datas}
          dateValue={dataModel.dateValue}
          link={dataModel.link}
          language={dataModel.language}
        />


      </section>
      <Footer />
    </>

  )
}

export default Information