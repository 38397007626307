import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './HeaderTTT.css';
/* import $ from 'jquery'; */
import Configs from "../../config";
import ScrollToTop from "react-scroll-to-top";
import imgTTT from '../../assets/img/logo/logo_TTT.png'
import imgTTTshot from '../../assets/img/logo/lofo_shot.png'
import TTT_photo from '../../assets/img/icon/Logo TTT 1.png';
import icon_google from '../../assets/img/logo/image google.png';
import icon_apple from '../../assets/img/logo/image apple.png';
import icon_shot from '../../assets/img/logo/Final-Logo-05 2.png';
import Inputwab from "../../components/customElement/inputwab";
import ButtonCustom from "../../components/customElement/buttonCustom";
import Iconwab from "../../components/customElement/iconwab";
import axios from "axios";
import IMGQRCODE from "../../assets/img/qrCode/QRLINETTTADMIN.png";
import ModelAlertRegis from "../AlertMassge/AlertRegis";
import ModelAlertMassge from "../AlertMassge/AlertMassge";
/* import Xxxxx from "../../components/customElement/numbarbig"; */


const HeaderTTT = () => {
  const [test, settest] = useState({
    test: '',
  })
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const [messageBox, setMessageBox] = useState({

  })
  const [alertKickMassge, setAlertKickMassge] = useState(false);
  const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

  const [userForm, setUserForm] = useState({
    username: '',
    password: ''
  });

  const [userDetail, setUserDetail] = useState({
    user: ''
  })
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState('');

  const [messageError, setMessageError] = useState({
    message: '',
    isError: false,
    menuTitle: ''
  })

  const [mobileLogin, setMobileLogin] = useState(false);
  const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
  const [mobileIsLogin, setMobileIsLogin] = useState(false);
  const toggle_mobileIsLogin = () => { setMobileIsLogin(!mobileIsLogin) };
  const [mobileRegister, setMobileRegister] = useState(false);
  const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

  async function getAuth() {

    let temp = {
      user: userForm.username || '',
      password: userForm.password || '',
    }
    setMessageError({ ...messageError, message: '', isError: false });
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Permission_Service}/api/auth/getAuth`,
      headers: {
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp,

    })
      .then(async function (response) {
        /*       console.log(response.data); */
        if (response.data.token) {
          sessionStorage.setItem('access_token', response.data.token);
          sessionStorage.setItem('user_id', response.data.fup);
          sessionStorage.setItem('user', response.data.user);
          sessionStorage.setItem('ttt_id', response.data.ttt_id);
          sessionStorage.setItem('is_skill', true);
          setUserDetail({ ...userDetail, user: response.data.user });
          document.body.classList.remove('login-menu-visible');
          setMessageError({ ...messageError, message: ' Welcome to TTT SPACESHIP', isError: false, menuTitle: 'Login successfully' })
          toggle_modal_alertKickMassge()
        } else {
          if (response.data.message) {
            setMessageError({ ...messageError, message: response.data.message, isError: true, menuTitle: 'LOGIN FAIL' })
            toggle_modal_alertKickMassge()
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function saveregiter() {
    let temp = {
      user: userForm.username || '',
      password: userForm.password || '',
    }
  }


  useEffect(() => {
    // console.log('pathName', location.pathname)
    if (location.pathname == '/tutorial') {
      setActiveMenu('tutorial')
    } else if (location.pathname == '/LeaderBoard') {
      setActiveMenu('leaderboard')
    } else if (location.pathname == '/contactus') {
      setActiveMenu('contactus')
    } else if (location.pathname == '/News') {
      setActiveMenu('news')
    } else if (location.pathname == '/Infodetail') {
      setActiveMenu('infodetail')
    } else if (location.pathname == '/ShopTTT') {
      setActiveMenu('shop')
    } else {
      setActiveMenu('home')
    }
    getConfigMasterRegis();
  }, [])


  const [isRegister, setIsRegister] = useState(false)

  async function getConfigMasterRegis() {
    let code = 'IsRegis';
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/configMgt/getConfigByCode/${code}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log('เช็คหน่อย', response.data);
        if (response.data.length > 0) {
          let is_ = response.data[0].gc_value == "true" ? true : false;
          setIsRegister(is_);
          settest({ ...test, test: '' });
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }



  return (
    <>
      <header className="third-header-bg home-five-header">
        <ScrollToTop smooth top="500" color="#000" />
        <div className="bg" />
        <div className="container custom-container">
          <div className="header-top-area t-header-top-area d-none d-lg-block">
            <div className="row">
              <div className="col-sm-6">
                <div className="header-top-social">
                  <ul>
                    <li>Follow</li>
                    <li><a target="_blank" href="https://www.facebook.com/TTTSpaceShip?mibextid=ZbWKwL"><i className="fab fa-facebook-f" /></a></li>
                    {/* <li><a href="/#"><img src={TTT_photo} alt="Logo" /></a></li> */}

                  </ul>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="header-top-login">
                  <ul>
                    {sessionStorage.getItem('user') && sessionStorage.getItem('user') !== '' ? (<>
                      <li style={{ marginRight: '-10px' }}><a href="#"><i className="far fa-edit" />{sessionStorage.getItem('user')}</a></li>

                      <li className="login-nav-toggler " style={{ marginLeft: '20px' }} onClick={() => { sessionStorage.clear(); window.location.reload(); window.location.href = '/#' }}><Link to='#'><i className="far fa-edit" />Log Out</Link></li>


                    </>) : (<>
                      <li style={{ marginRight: '-10px', cursor: 'pointer' }}>
                        {isRegister == true ? <a href="/Register" ><i className="far fa-edit" />Register</a> :
                          <a /* href="/Register" */ onClick={() => { toggle_modal_alertKick() }}><i className="far fa-edit" />Register</a>
                        }


                      </li>

                      <li className="login-nav-toggler " style={{ marginLeft: '20px' }} onClick={() => {
                        document.body.classList.add('login-menu-visible');
                        /*  getAuth(); */
                      }}><Link ><i className="far fa-edit login-nav-toggler " />Login</Link></li>
                    </>)}

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="sticky-header" className="menu-area">
          <div style={{ height: '3px', backgroundColor: '#0047FF' }} />

          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler"><i className="fas fa-bars" /></div>
                <div className="main-menu menu-style-two">
                  <nav>
                    <div className="logo d-block d-lg-none" >

                      <Link to="/"><img src={imgTTTshot} alt="Logo" /></Link>

                    </div>
                    <div className="navbar-wrap d-none d-lg-flex">
                      <ul className="left">
                        <li className={`${activeMenu == 'home' ? 'show' : ''}`}><Link to="/">Home</Link>
                        </li>
                        {/*   <li><Link to="/tutorial" >Tutorial</Link></li> */}
                        <li className={`${activeMenu == 'tutorial' ? 'show' : ''}`}><Link /* to="/tutorial" */>Tutorial</Link>
                          <ul className="submenu">
                            {/*  <li><Link to="/tutorial">Hard Story</Link></li>
                          <li><Link to="/tutorial">TRANSFORM</Link></li>
                          <li><Link to="/tutorial">TIMELY</Link></li>
                          <li><Link to="/tutorial">TRAILBLAZER</Link></li> */}
                            <li ><Link to="/Achievement">ACHIEVEMENT</Link></li>
                            <li ><Link to="/howToPlay">HOW TO PLAY</Link></li>
                            <li ><Link to="/talentGroup">TALENT GROUP</Link></li>
                          </ul>
                        </li>
                        <li className={`${activeMenu == 'leaderboard' ? 'show' : ''}`} ><Link /* to="/LeaderBoard" */> Leaderboard</Link>
                        <ul className="submenu">
                            <li ><Link to="/UniversalBoard">UNIVERSAL BOARD</Link></li>
                            <li >{sessionStorage.getItem('user_id') ? <Link to="/FederationBoard">FEDERATION BOARD</Link> : <Link to="#" onClick={()=>{
                              setMessageError({...messageError, message: 'You will need to log in first.', isError: true, menuTitle: 'ERROR'});
                              messageError.message = 'You will need to log in first.';
                              messageError.isError = true;
                              messageError.menuTitle = 'ERROR';
                              toggle_modal_alertKickMassge();
                              }}>FEDERATION BOARD</Link>}</li>
                          </ul>
                        </li>
                        <li className={`${activeMenu == 'contactus' ? 'show' : ''}`} style={{ width: "100px" }}><Link to="/contactus"> CONTACT US</Link></li>

                      </ul>
                      <div className="logo" onClick={() => { sessionStorage.setItem('onMenu', '') }}>
                        <Link to="/"><img src={imgTTT} alt="Logo" /></Link>
                      </div>
                      <ul className="right">
                        <li className={`${activeMenu == 'news' ? 'show' : ''}`}><Link to="/News">News</Link></li>
                        <li className={`${activeMenu == 'infodetail' ? 'show' : ''}`} style={{ minWidth: 'fit-content' }}><Link to="/Infodetail">Info detail</Link></li>
                        <li className={`${activeMenu == 'shop' ? 'show' : ''}`}><Link to="/ShopTTT">Store</Link></li>
                        {/* <li><Link to="/lineModal">TEST Moda 3 D</Link></li> */}
                        <li onClick={
                          () => {
                            if (sessionStorage.getItem('access_token')) {
                              window.location.href = "/StageMonitoring";
                            } else {
                              document.body.classList.add('login-menu-visible');
                            }
                          }
                        }><Link>Play</Link>

                        </li>


                      </ul>
                    </div>
                    <div className="header-action">

                    </div>
                  </nav>
                </div>

                <div className="login-menu">
                  <nav className="menu-box">

                    <div className="nav-logo"><Link to="/"><img src={icon_shot} alt="" title='true' /></Link>
                    </div>
                    <div className="menu-outer">

                    </div>
                    <div className="social-links">
                      <div className="login-menu-wrap main-menu ">
                        <nav>
                          <div className="navbar-wrap push-menu">
                            <ul className="navigationlogin" style={{ flexDirection: 'column', marginLeft: '300px' }}>

                              <li style={{ fontSize: '20px', color: '#fff', marginLeft: '-330px', fontWeight: '600', fontFamily: 'Oxanium', marginBottom: '25px' }}>LOG IN</li>

                              <div className='row' style={{ marginLeft: '15px', marginBottom: '25px' }}>
                                <div style={{ marginRight: '15px' }}>
                                  <Iconwab img={icon_google} /* path={'/#'} */ />
                                </div>
                                  <Iconwab img={icon_apple} /* path={'/#'} */ />

                              </div>
                              <li style={{ fontSize: '20px', color: '#fff', marginLeft: '-250px', marginTop: '25px', marginBottom: '25px', fontWeight: '600', fontFamily: 'Oxanium' }}>OR LOG IN WITH</li>

                              <div className="col-10" style={{ marginLeft: '10px' }} >


                                <div style={{ marginBottom: '24px' }}>
                                  <Inputwab placeholder='TTT ID' value={userForm.username} onChangeValue={(e) => {
                                    /* console.log("ASDASDAS") */
                                    setUserForm({ ...userForm, username: e.target.value })
                                  }} />
                                </div>
                                <div style={{ marginBottom: '24px' }}>
                                  <Inputwab placeholder='PASSWORD' type="password" value={userForm.password} onChangeValue={(e) => {
                                    /*   console.log(e) */
                                    setUserForm({ ...userForm, password: e.target.value })
                                  }} />
                                </div>
                                {messageError.isError == true ? <>
                                  <font color='red'>
                                    {messageError.message}
                                  </font>
                                </> : <></>}

                                <br />
                                <br />
                                <div style={{ marginBottom: '35px' }}>

                                  <ButtonCustom
                                    isuse={true}
                                    placeholder="LOG IN"
                                    onClick={() => {
                                      getAuth()
                                    }}
                                  />

                                </div>
                                {isRegister == true ?  <a href="/Register"><li style={{ fontSize: '12px', color: '#00A3FF', fontWeight: '600', fontFamily: 'Oxanium', marginBottom: '8px' }}>CREATE A FREE TTT ACCOUNT</li></a> :
                                 <a /* href="/Register" */ onClick={() => { toggle_modal_alertKick() }}><li style={{ fontSize: '12px', color: '#00A3FF', fontWeight: '600', fontFamily: 'Oxanium', marginBottom: '8px' }}>CREATE A FREE TTT ACCOUNT</li></a>
                                }
                               
                                <a href="/#"><li style={{ fontSize: '12px', color: '#5D9BE1', fontWeight: '600', fontFamily: 'Oxanium' }}>CAN’T LOG IN?</li></a>


                              </div>


                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" onClick={() => {
                  console.log('dsdsdsdsdddddd');
                  document.body.classList.remove('login-menu-visible');
                }} />
              </div>
              {/* Modal Search */}
              <div className="modal fade" id="search-modal" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <form>
                      <input type="text" placeholder="Search here..." />
                      <button><i className="fa fa-search" /></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModelAlertRegis
          isOpen={alertKick}
          toggle_alertKick={() => { toggle_modal_alertKick() }}
        />

        <ModelAlertMassge
          isOpen={alertKickMassge}
          toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
          message={messageError}
        />
        <div className="header-bottom-bg" />
      </header>
    </>


  )
}

export default HeaderTTT