import React, { useEffect, useState, useRef } from 'react'
import "./style.css"

import { NumericFormat, PatternFormat } from 'react-number-format';

import Select from 'react-select'
import Configs from "../../config";

import axios from "axios";
import { DatePicker, Input, Tooltip } from 'antd';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';

import backIcon from './Image/Icon.png'
const { TextArea } = Input;

function SpaceshipWorkload(params) {

    const [test, setTest] = useState({
        test: ''
    });

    const [filter, setFilter] = useState({
        search: '',
        month: null,
        year: moment(new Date()).format("YYYY"),
        type: 'week'
    });

    const [selectedYear, setSelectedYear] = useState(null);
    const [optionsSelectType, setOptionSelectType] = useState([
        {
            value: 'day',
            label: 'day',
        },
        {
            value: 'week',
            label: 'week',
        }
    ])

    const [weekMonthInYear, setWeekMonthInYear] = useState([]);
    const [weekInYear, setWeekInYear] = useState([]);

    const weekRefs = useRef([]);

    const [spsWorkloadData, setSpsWorkloadData] = useState([]);

    const [spsWorkloadSpaceshipData, setSpsWorkloadSpaceshipData] = useState([]);

    const [cashImage, setCashImage] = useState([]);

    async function getFederationWorkloadData() {
        let temp = {
            spaceship_id: params.id,
            search: filter.search || '',
            month: filter.month || null,
            year: filter.year || moment().format("YYYY"),
            type: filter.type || 'week'
        };
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/spaceship/getSpaceshipWorkloadData`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp,
        })
            .then(async function (response) {
                if (response) {
                    setSpsWorkloadData(response.data);
                    WeekCalculator(parseInt(filter.year || moment().format('YYYY')));

                    for (let item of response.data.usr_list) {
                        if (item.usr_image) {
                            let checkIsImage = cashImage.filter((e) => { return e.image_id == item.usr_image });
                            if (checkIsImage.length > 0) {
                                item.url_file_path = checkIsImage[0].image_path
                            } else {
                                let response2 = await axios.get(`https://api.box.com/2.0/files/${item.usr_image}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });

                                item.url_file_path = URL.createObjectURL(response2.data);

                                let cashFile = cashImage;
                                cashFile.push({
                                    image_id: item.usr_image,
                                    image_path: URL.createObjectURL(response2.data)
                                });
                                setCashImage(cashFile);
                            }
                        }
                        setSpsWorkloadData(response.data);
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let onWeek = 1;
    async function WeekCalculator(year) {
        onWeek = 1;
        let data = [];
        let dataWeek = [];
        for (let month = 0; month < 12; month++) {
            const weeks = await getWeeksInMonth(year, month);
            const getDate = new Date(year, month, 1);
            const getMonth = moment(getDate).format('MMM YYYY')

            data.push({
                month: month + 1,
                monthYear: getMonth,
                week: weeks
            })

            dataWeek = [...dataWeek, ...weeks];
        }

        setWeekMonthInYear(data);
        setWeekInYear(dataWeek);
    }

    async function getWeeksInMonth(year, month) {
        let weeks = [];
        const startDate = new Date(year, month, 1);
        const endDate = new Date(year, month + 1, 0);
        const startDay = startDate.getDay();
        const daysInMonth = endDate.getDate();

        let weekStart = 1 - startDay;

        while (weekStart <= daysInMonth) {
            const weekEnd = Math.min(weekStart + 6, daysInMonth);
            const countDay = weekEnd - weekStart;
            const endLastWeek = new Date(year, month, weekStart);
            const getMonth = moment(startDate).format('MMM')

            if (countDay > 5) {
                weeks.push({
                    week: onWeek,
                    day: `${moment(endLastWeek).format('D')} - ${weekEnd}`,
                    month: `${moment(endLastWeek).format('D') > weekEnd ? getMonth : ''}`,
                    startDate: endLastWeek,
                    endDate: new Date(year, month, weekEnd)
                });
                onWeek++
            }
            weekStart += 7;
        }
        return weeks
    }

    async function setToday() {
        setFilter({ ...filter, year: moment().format('YYYY') });
        filter.year = moment().format('YYYY');
        scrollToCurrentWeek();
    }

    const scrollToCurrentWeek = () => {
        const currentWeek = moment().week();
        const targetIndex = weekInYear.findIndex(week => week.week === currentWeek);
        if (targetIndex !== -1 && weekRefs.current[targetIndex]) {
            weekRefs.current[targetIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    };

    useEffect(() => {
        if (params.isOpen) {
            getFederationWorkloadData();
        };

    }, [params.isOpen, filter.year])

    return (
        <>
            <div className='spsWorkloadBg' style={{ display: `${params.isOpen ? '' : 'none'}`,zIndex: 999999 }}>
                <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                        params.toggleIsOpen();
                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedWorkloadBackLine'></div>
                    <div className='fedWorkloadBackNameBox'>
                        <div className='fedWorkloadBackNameHead'>Spaceship workload</div>
                        <div className='fedWorkloadBackNameText'>{spsWorkloadData.spaceship_name}</div>
                    </div>
                </div>

                <div className='row fedWorkloadFilterBox' style={{ margin: 0 }}>
                    <div className='fedWorkloadFilterSpace'>
                        <Input
                            placeholder="Search"
                            prefix={<SearchOutlined style={{ fontSize: '18px', fontWeight: 700, color: '#FFF' }} />}
                            allowClear
                            onChange={(e) => {
                                setFilter({ ...filter, search: e.target.value });
                                filter.search = e.target.value;
                            }}
                        />
                        <DatePicker
                            value={dayjs(filter.year)}
                            onChange={(e, i) => {
                                setFilter({ ...filter, year: i });
                                filter.year = i;
                            }}
                            suffixIcon={<DownOutlined style={{ color: '#FFF' }} />}
                            allowClear={false}
                            picker="year"
                            dropdownClassName="customDatePickerSpsWorkload"
                            defaultValue={dayjs(moment().format('YYYY'), 'YYYY')} />
                    </div>
                    <div className='fedWorkloadFilterSpace'>
                        <div className='fedWorkloadFilterTodayButton' onClick={() => {
                            setToday();
                        }}>Today</div>
                        <Select
                            defaultValue={optionsSelectType.filter((e) => { return e.value == 'week' })}
                            //   onChange={handleChange}
                            options={optionsSelectType}
                        />
                    </div>
                </div>

                <div className='fedWorkloadDataBox' style={{ margin: 0, }}>
                    <table>
                        <thead style={{ backgroundColor: '#272727' }}>
                            <tr>
                                <th className="fedWorkloadStickyCenter boxShadowNone" rowSpan="2" style={{ zIndex: 3 }}>
                                    <div className='fedWorkloadCustomBg'>ALL SPACESHIP</div>
                                </th>
                                {weekMonthInYear && weekMonthInYear.map((e) => (
                                    <th className='boxShadowNone' colSpan={e.week && e.week.length}>
                                        <div className='fedWorkloadHeaderMonth'>
                                            <div className='fedWorkloadCustomBg1'>{e.monthYear}</div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            <tr className='fedWorkloadWeekInYearRowSticky'>
                                {weekInYear && weekInYear.map((e, index) => (
                                    <th
                                        key={index}
                                        className='boxShadowNone'
                                        ref={el => weekRefs.current[index] = el}
                                    >
                                        <div className='fedWorkloadWeekInYear'>W{e.week} {e.day} <span style={{ color: '#0047FF' }}>{e.month}</span></div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {spsWorkloadData.usr_list && spsWorkloadData.usr_list.filter((filUser) => { return `${filUser.usr_name} ${filUser.usr_lastname}`.toLowerCase().includes(filter.search.toLowerCase()) }).map((eu, iu) => {
                                return <>
                                    <tr key={iu}>
                                        <td className="fedWorkloadStickySider">
                                            <div className='fedWorkloadUserNameBg'>
                                                <div className='fedWorkloadSpaceshipNameBox'>
                                                    <div className='fedWorkloadSetImageUser' style={{ backgroundImage: `url(${eu.url_file_path})` }}></div>
                                                    <Tooltip title={`${eu.usr_name} ${eu.usr_lastname}`} placement="topLeft" color={"#000"} zIndex={99999}>
                                                        <div className='fedWorkloadSetTextUser' style={{maxWidth: '70%'}}>{eu.usr_name} {eu.usr_lastname}</div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </td>
                                        {weekInYear && weekInYear.map((ew, iw) => {
                                                    let ts_time = 0;
                                                    let ts_percent = 0
                                                    let ts_project_count = 0;
                                                    let ts_project_list = [];
                                                    eu.usr_timesheet && eu.usr_timesheet.map((et, it) => {
                                                        const eventDate = moment.utc(new Date(et.ts_date));
                                                        let startDate = moment.utc(ew.startDate);
                                                        let endDate = moment.utc(ew.endDate);
                                                        if (moment.utc(eventDate).diff(startDate, 'milliseconds') >= 0 && moment.utc(eventDate).diff(endDate, 'milliseconds') <= 0) {
                                                            ts_time = ts_time + et.ts_time;
                                                            ts_percent = (ts_time / (5 * 8 * 60)) * 100
                                                            ts_project_list = [...(new Set([...ts_project_list, ...et.ts_project_list]))];
                                                            ts_project_count = ts_project_list.length;
                                                        }
                                                        
                                                    });

                                                    return <>
                                                        <td key={iw} className='fedWorkloadCircleBg' ref={el => weekRefs.current[iw] = el}>
                                                            <div className='fedWorkloadCircleBoxUser'>
                                                                {ts_percent < 100 ?
                                                                    <div className='fedWorkloadCircle'>
                                                                        <div className='fedWorkloadCircleIn' style={{ width: `${ts_percent}%`, height: `${ts_percent}%` }}></div>
                                                                    </div>
                                                                    :
                                                                    <div className='fedWorkloadCircle' style={{border: '1px solid rgba(134, 134, 134, 0.4)'}}>
                                                                        <div className='fedWorkloadCircleIn' style={{ width: `${ts_percent}%`, height: `${ts_percent}%`, backgroundColor: 'rgba(29, 204, 55, 0.15)', border: '1px solid green' }}>
                                                                            <div className='fedWorkloadCircleIn2'></div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <Tooltip title={
                                                                    <div>
                                                                        {ts_project_list.map((epl, ipl) => (
                                                                            <div key={ipl}>
                                                                                {ipl + 1}. {epl}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                } placement="topLeft" color={"#000"} zIndex={99999}>
                                                                    <div className='fedWorkloadProjectCount'>{ts_project_count > 0 ? ts_project_count : ''}</div>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                    </>
                                                })}
                                    </tr>
                                </>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default SpaceshipWorkload;
