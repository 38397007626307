import React, { useEffect, useState, useRef } from 'react';
import IMGQRCODE from "../../assets/img/qrCode/QRLINETTTADMIN.png";
import "./alert.css"

function AlertConfrim({ isOpen, toggle_alertKick, mainFuc }) {
    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);


    useEffect(() => {
        console.log('isOpen', isOpen);
        if (isOpen === true) {
            toggle_modal_confrimModal()
        }
    }, [isOpen])

    return (
        <>
            <div className='alertKick' style={{ display: `${isOpen ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' >
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Do you want to save data?
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='alertKickConfirm mr-2' onClick={() => {
                                    mainFuc()
                                    toggle_alertKick()
                                }}>YES</button>

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                    toggle_alertKick()
                                }}>NO</button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AlertConfrim;
