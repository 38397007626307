import React, { useState, useEffect, useRef } from 'react';
import './myfeedback.css'
import Construction from '../../../../assets/img/bg/Construction Page.png'

const DetailConnection = ({ isOpen, check, cardData, imgProfile}) => {
    const [countData, setCountData] = useState(cardData)

    return (
        (check === 'MYFEEDBACK' ? (<>
            <div className="detailConnection" style={{ visibility: `${isOpen === true && check === 'MYFEEDBACK' ? 'visible' : 'hidden'}` }}>
            <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "0px" }}/>
              
            </div>
        </>) : (<></>))
    )
}

export default DetailConnection

