import React, { useEffect, useState, useRef } from 'react'
import './modalsrs.css'
import axios from "axios";
import Configs from "../../../config";
import moment from "moment";
import { func } from 'prop-types';
import { io } from 'socket.io-client';
/* const socket = io("http://150.95.24.71:7776"); */

const ModalSRS = ({
    isOpen,
    setModalSrs,
    onMenu,
    nameMenu,
    getData,
    checkMenu,
    getRiskIssueData,
    getProjectId,

}) => {
    const [riskData, setRiskData] = useState([])
    const [issueData, setIssueData] = useState([])
    const [sendText, setSendText] = useState({
        text: ''
    })
    const [timeSend, setTimeSend] = useState(true)
    const [firstAnimetion, setFirstAnimetion] = useState(true)
    const [isAnimetion, setIsAnimetion] = useState()
    const [test, setTest] = useState({ test: '' })

    async function sendTextData() {
        // let temp = getData
        // let addData = {
        //     pri_comment: sendText.text
        // }
        // temp.push(addData)
        // setRiskIssueData(temp)
        // // console.log('testlog',riskIssueData);
        // setTest({...test,test:''});
        // test.test = '';
        // console.log('testlog',onMenu);
        let temp = {
            project_id: onMenu,
            menu_id: onMenu,
            srs_comment: sendText.text,
            user_id: sessionStorage.getItem('user_id'),
            user_name: sessionStorage.getItem('user'),
            filelist: []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/srsMenu/sendData`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                /*         socketRef.current.emit("chat message",{onMenu:onMenu,project_id:getProjectId}); */
                srsGetData(onMenu)
                setSendText({ ...sendText, text: '' })
                sendText.text = ''
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function setTimeClose() {
        const intervalClose = setInterval(() => {
            setModalSrs({ isOpen: false })
            setIsAnimetion(true)
            clearInterval(intervalClose);
        }, 900);
    }

    function setTimeToSend() {
        if (timeSend) {
            srsGetData(onMenu)
            setTimeSend(false)
        }

        const intervalClose = setInterval(() => {
            setTimeSend(true)
            clearInterval(intervalClose);
        }, 5000);
    }

    const [editData, setEditData] = useState({
        edit_open: false,
        edit_text: '',
        pri_comment: '',
        pri_created_name: '',
        pri_created_date: '',
        pri_id: '',
        pri_updated_date: ''
    })

    async function editRiskIssue(pri_comment, pri_created_name, pri_created_date, pri_id, pri_updated_date) {
        setEditData({ ...editData, edit_open: true, edit_text: pri_comment, pri_comment: pri_comment, pri_created_name: pri_created_name, pri_created_date: pri_created_date, pri_id: pri_id, pri_updated_date: pri_updated_date })
        editData.edit_open = true
    }

    async function editClose() {
        setEditData({ ...editData, edit_open: false })
        editData.edit_open = false
        // if (editData.edit_text != editData.pri_comment) {

        // } else {
        //     setEditData({...editData, edit_open: false})
        //     editData.edit_open = false
        // }
    }

    async function editRefresh() {
        setEditData({ ...editData, edit_text: editData.pri_comment })
        editData.edit_text = editData.pri_comment
    }

    async function editSubmit() {
        // console.log('testlog',editData.pri_id, editData.edit_text);

        let temp = {
            sm_id: editData.pri_id,
            srs_comment: editData.edit_text,
            user_id: sessionStorage.getItem('user_id'),
            user_name: sessionStorage.getItem('user'),
            filelist: []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/srsMenu/updateData`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                srsGetData(onMenu)
                setEditData({ ...editData, edit_open: false })
                editData.edit_open = false
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function auto_area() {
        let sendtext = document.getElementById("edittext")

        sendtext.style.height = "50px";
        sendtext.style.height = (sendtext.scrollHeight) + "px";

    }

    async function auto_grow() {
        let sendtext = document.getElementById("sendtext")
        let sendtextint = 0
        // console.log('testlog',  (sendText.text.length == 0 ? 44 : sendtext.scrollHeight) + "px");

        sendtext.style.height = "44px";
        sendtext.style.height = (sendText.text.length == 0 ? 44 : sendtext.scrollHeight) + "px";

        if (parseInt(sendtext.style.height) >= 125) {
            sendtextint = 75
        } else if (parseInt(sendtext.style.height) <= 50 || sendText.text.length <= 1) {
            sendtextint = 0
        } else {
            sendtextint = (parseInt(sendtext.style.height)) - 50
        }

        sendtext.style.top = "-" + sendtextint + "px";
    }

    async function srsGetData(value){
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/srsMenu/getSRSMenu/${value}`,
            headers: {
              /*  Authorization: getToken(), */
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            }
          })
            .then(async function (response) {
                console.log('response',response.data);
                let temp = [];
                for (let item of response.data) {
                    var temp2 = {
                        pri_comment: item.sm_comment, 
                        pri_created_name: item.sm_created_name, 
                        pri_created_date: item.sm_created_date, 
                        pri_id: item.sm_id, 
                        pri_updated_date: item.sm_updated_date,
                        pri_created_by: item.sm_created_by
                    }
                    temp.push(temp2)
                }
                
                setIssueData(temp)
            })
            .catch(function (error) {
              console.log(error);
            });
    }

    useEffect(() => {
        // console.log('testlog', getData);
        if (firstAnimetion) {
            setFirstAnimetion(false)
            // firstAnimetion = false
            setIsAnimetion(true)
            // isAnimetion = true

        }
        if (isOpen) {
            srsGetData(onMenu)
        }
        
        // console.log('testlog',isOpen);


        /*       setActiveType(onMenu) */
        // console.log('testlog', getData);
        const interval = setInterval(() => {
            if (getData != undefined && getData != null && getData != '') {
                document.getElementById('autoScroll').scrollTop += (getData.length * 1000)
                // console.log('testlog');
                clearInterval(interval);
            }

        }, 150);

    }, [onMenu, isOpen])

    return (
        <>
            <div className='riskIssueBox' style={{ display: `${isOpen ? '' : 'none'}`, animation: `${isAnimetion ? 'openRiskIssue 1s' : 'closeRiskIssue 1s'}` }}>
                <div className='riskIssueBoxTopSRS' style={{ backgroundColor: `${onMenu == 'RISK' ? 'yellow' : 'blue'}` }}>
                    <div className='riskIssueBoxTopInSRS'>
                        <div className='riskIssueBoxTopHeadSRS'>
                            {onMenu == 'RISK' ? 'RISK' : nameMenu}
                        </div>
                        <div className='riskIssueBoxTopText'>
                            View All
                        </div>
                        <div className="riskIssueBoxTopClose" onClick={() => {
                            setTimeClose()
                            setIsAnimetion(false)
                        }}><i class="fa fa-times" aria-hidden="true"></i></div>
                        <div className="riskIssueBoxTopRefresh" onClick={() => { setTimeToSend() }} style={{ pointerEvents: `${timeSend ? '' : 'none'}`, opacity: `${timeSend ? '1' : '0.5'}`, animation: `${timeSend ? '' : 'spin 3s infinite'}` }}><i className="fa fa-spinner" /></div>
                    </div>
                </div>
                <div className='riskIssueBoxBottom' style={{ backgroundColor: `${onMenu == 'RISK' ? 'yellow' : 'blue'}` }}>
                    <div className='riskIssueBoxBottomIn'></div>
                </div>
                <div className='riskIssueChat' id='autoScroll' >

                    {onMenu == 'RISK' ?
                        riskData.map((e, index) => {
                            const commentLines = e.pri_comment.split('\n');
                            // console.log('testlog',e);

                            return (
                                <div className='row riskIssueSetRow' style={{ border: `2px solid ${onMenu == 'RISK' ? 'yellow' : 'blue'}` }}>
                                    <div className="col-11 riskIssueChatText">
                                        {commentLines.map((line, i) => (
                                            <span key={i}>
                                                {line}
                                                {i < commentLines.length - 1 && <br />}
                                            </span>
                                        ))}
                                        <div class="tooltiptext">Create by : {e.pri_created_name} <br /> Create date : {moment(new Date(e.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                                    </div>
                                    <div className="col-1" style={{ padding: '5px' }}>
                                        {e.pri_updated_date === e.pri_created_date ? "" : "EDIT"}
                                        <i class="fa fa-edit"
                                            onClick={() => {
                                                editRiskIssue(e.pri_comment, e.pri_created_name, e.pri_created_date, e.pri_id, e.pri_updated_date)
                                            }}
                                            style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, fontSize: '22px', fontWeight: 100, cursor: 'pointer' }}></i>
                                    </div>
                                </div>
                            )
                        }) :
                        issueData.map((e, index) => {
                            const commentLines2 = e.pri_comment.split('\n');

                            return (
                                <div className='row riskIssueSetRow' style={{ border: `2px solid ${onMenu == 'RISK' ? 'yellow' : 'blue'}` }}>
                                    <div className="col-11 riskIssueChatText">
                                        {commentLines2.map((line, i) => (
                                            <span key={i}>
                                                {line}
                                                {i < commentLines2.length - 1 && <br />}
                                            </span>
                                        ))}
                                        <div class="tooltiptext">Create by : {e.pri_created_name} <br /> Create date : {moment(new Date(e.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                                    </div>
                                    <div className="col-1" style={{ padding: '5px' }}>
                                        {e.pri_updated_date === e.pri_created_date ? "" : "EDIT"}
                                        <i class="fa fa-edit"
                                            onClick={() => {
                                                editRiskIssue(e.pri_comment, e.pri_created_name, e.pri_created_date, e.pri_id, e.pri_updated_date)
                                            }}
                                            style={{ display: `${e.pri_created_by == sessionStorage.getItem('user_id') ? '' : 'none'}`, fontSize: '22px', fontWeight: 100, cursor: 'pointer' }}></i>
                                    </div>
                                </div>
                            )
                        })}
                </div>
                <div className='row riskIssueChatSend'>
                    <textarea type='text'
                        id='sendtext'
                        onKeyDown={(e) => {
                            // console.log('keyDow', e);
                            if (e.shiftKey && e.keyCode === 13) {
                            } else {
                                if (e.keyCode == 13 || e.keyCode == '13') {
                                    e.preventDefault();
                                    if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                                        sendTextData()
                                    }
                                }
                            }

                        }}
                        className='col-8 riskIssueChatTextBox'
                        placeholder="Input text...."
                        value={sendText.text}
                        onChange={(e) => {
                            setSendText({ ...sendText, text: e.target.value })
                            sendText.text = e.target.value
                            auto_grow()
                        }}>
                    </textarea>
                    <div className='col-1'></div>
                    <input
                        type='button'
                        value='SEND'
                        className='col-3 riskIssueChatSendBox'
                        onClick={() => {

                            if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                                sendTextData()
                            }
                        }}></input>
                </div>

            </div>
            <div className='riskIssueEditText' style={{ display: `${editData.edit_open ? '' : 'none'}` }}>
                <div className='riskIssueEditBox'>
                    <div className='riskIssueEditCreateName'>Create by : {editData.pri_created_name}</div>
                    <div className='riskIssueEditCreateDate'>Create date : {moment(new Date(editData.pri_created_date)).format("DD-MM-yyyy HH:mm:ss")}</div>
                    <div className='riskIssueEditCreateDate'>Last update : {editData.pri_updated_date === editData.pri_created_date ? '-' : `${moment(new Date(editData.pri_updated_date)).format("DD-MM-yyyy HH:mm:ss")}`}</div>
                    <textarea type='text'
                        id='edittext'
                        className='riskIssueEditComment'
                        // placeholder={editData.pri_comment}
                        value={editData.edit_text}
                        onChange={(e) => {
                            setEditData({ ...editData, edit_text: e.target.value })
                            editData.edit_text = e.target.value
                            auto_area()
                        }}
                    >

                    </textarea>
                    <div className='row riskIssueEditMenu'>
                        <div className='col-6' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditRefresh" onClick={() => {
                                editRefresh()
                            }}>refresh</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editSubmit()
                            }}>SUBMIT</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editClose()
                            }}>CLOSE</div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalSRS;
