import React, { useEffect,useState,useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider } from 'antd';
import axios from 'axios';
import Configs from "../../../config";
import './modalSpaceShip.css'


function ModalAddModule({
  isOpen,
  toggle_modal_add_module,
  project_id,
  getProjectDetail
  
}){

  const [ModuleDetail,setModuleDetail] = useState({
    module_name:''
  })

  const [errorMessage,setErrorMessage] = useState('')

  function saveAddModule(){
    let temp ={
      project_id:project_id,
      module_name:ModuleDetail.module_name.trim() || ''
    }


    if(temp.module_name == ''){
      setErrorMessage('Please Enter Your Module Name !!!')
    }else {
      console.log("HERERE")
      axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/moduleMenu/addModule`,
        headers: {
          /*  Authorization: getToken(), */
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
  
      })
        .then( function (response) {
          if(response.data){
            setErrorMessage('');
            setModuleDetail({...ModuleDetail,module_name:''});
            ModuleDetail.module_name= '';
            toggle_modal_add_module();
            getProjectDetail(project_id)
          }
  
          /* ProjectTimelinereport('2') */
  
        })
        .catch(function (error) {
          console.log(error);
          setErrorMessage(error.response.data.error[0].errorDis)
          /*  Swal.fire({
             icon: "success",
             title: error,
             showConfirmButton: false,
             timer: 1500,
         }) */
        });

    }
  }

  useEffect(()=>{
     setErrorMessage('');
            setModuleDetail({...ModuleDetail,module_name:''});
            ModuleDetail.module_name= '';

  },[isOpen])


return (

    <>
    
{/*     {isOpen && (
    <> */}
     <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#85BFFF',
                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',
                
                colorTextDescription:'#FFFFFF',
                colorTextQuaternary:'#B0C4DE',// สีตัวหนังสือของ placeholder
         
              },
              backgroundColor:'#000'
            }}
          >
         <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={()=>{toggle_modal_add_module();}}>      </div>  
    <div className={`detailBoxModuleMenu ${isOpen ? 'opened':'closed'}`}   style= {{top:`30%`,left:`50%`,position:'fixed',transform: 'translate(-50%, 50%)',width:'30%'}}
        
  
    >
     
{/*        {isOpen && (<> */}
        <div className = "table-responsive" >
        <div className='row mt-2' >
     
            <div className='col-12'><h4>Add Module</h4></div>
            <div className='col-12 mt-2'>
            Module Name<font color ="red">*</font> <font className='ml-4' color ="red">{errorMessage}</font>
              <Input style= {{backgroundColor:'#000'}} type ='text' placeholder='Module Name...'
              value = {ModuleDetail.module_name}
               onChange={(e)=>{
                setErrorMessage('');
                setModuleDetail({...ModuleDetail,module_name:e.target.value})
               }}
              
              />
            </div>
          
            <div className='col-12'>
          
            </div>
          
  
         
    </div>
    </div>
    <br/><br/>
    <div style= {{position:'absolute',bottom:'5px',right:'5px'}}>
            <button className=' btn-outline-primary mr-2' style ={{padding:'5px'}} onClick={()=>{saveAddModule();}}> Save </button> <button className=' btn-outline-danger' style ={{padding:'5px'}} onClick={()=>{toggle_modal_add_module();}}> Cancel </button>
              </div>
   {/*     </>)} */}
     
    
    </div>
     
    </ConfigProvider>
   
{/*     </>
    
  )} */}
    </>
)


}
export default ModalAddModule;


