import React, { useEffect,useState,useRef } from 'react'
import { Input,Select, Space, Tag, theme, Tooltip, ConfigProvider } from 'antd';
import axios from 'axios';
import Configs from "../../../config";
import './modalSpaceShip.css'


function ModalConfirm({
  isOpen,
  toggle_modal_confirm,
  removeMenu,
  index
  
}){

  
  
  useEffect(()=>{
 /*    setErrorMessage({...errorMessage, module_err:'',menu_err:''});
    setErrorResMessage('');
    setMenuDetail({...MenuDetail,menu_name:"",module_id:''})
    MenuDetail.menu_name = '';
    MenuDetail.module_id = ''; */

   /*  setModuleOptions(moduleOption); */
  },[isOpen])

 



return (

    <>
    
  
     <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#85BFFF',
                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',
                
                colorTextDescription:'#FFFFFF',
                colorBgContainer: '#000',
            
                zIndexPopupBase:999999,
                colorPrimaryBg:'blue',//สีตอน Active ใน Select
                colorBgBase:'#778899',//พื้นหลัง select ตอนกด down ลงมา
        
                colorTextQuaternary:'#B0C4DE',// สีตัวหนังสือของ placeholder

              
                colorFillTertiary:'#87CEFA',// สีตอน hover ใน Select
              
              
    
              },
            /*   backgroundColor:'#000' */
            }}
          >
      <div className={`backdropConfirm ${isOpen ? 'opened' : 'closed'}`} onClick={()=>{toggle_modal_confirm();}}>      </div>
    <div className={`detailBoxModuleMenu ${isOpen ? 'opened':'closed'}`}    style= {{backgroundColor:'#000',top:`30%`,left:`50%`,position:'fixed',transform: 'translate(-50%, 50%)',width:'20%'}}
              
  
    >
      <div className = "table-responsive" >
        <div className='row mt-2' >
     
            <div className='col-12'><h4>Warning </h4><font color = "red"></font></div>
            
       
            <div className='col-12 mt-4'>
             <h5>You won't be able to revert this!</h5>
            </div>  
          
  
         
    </div>
    </div>
    <br/><br/>
    <div style= {{position:'absolute',bottom:'5px',right:'5px'}}>
            <button className=' btn-outline-primary mr-2' style ={{padding:'5px'}} onClick={()=>{removeMenu(index);}}> Yes </button> <button className=' btn-outline-danger' style ={{padding:'5px'}} onClick={()=>{toggle_modal_confirm();}}> No </button>
              </div>
    
    </div>
     
    </ConfigProvider>
   
 
    </>
)


}
export default ModalConfirm;


