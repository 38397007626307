import React, { useState, useEffect } from 'react';
import '../../assets/css/infodetail.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Title from "../../components/customElement/headertitle";
import Pagenew from "../../components/index/indexTTT/board/pagenew";
import iconback from "../../assets/img/icon/iconback.png";
import Card from "../../components/customElement/cardshot";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Configs from "../../config";
import moment from 'moment';
import ModalNew from '../../components/Modalpopup/newsModalPopUp'
import NewsInfoMobile from './Mobile.jsx';
import { Link } from 'react-router-dom';

export default function News() {

  const [modeMobile, setModeMobile] = useState(false);
  const [test, settest] = useState({
    test: ''
  })
  const [newStoryTemp, setStoryTemp] = useState([])
  const [newActivityTemp, setActivityTemp] = useState([])
  const [newPromotionTemp, setPromotionTemp] = useState([])
  const [newAlllNewsTemp, setAllNewsTemp] = useState([])
  const [newHighlightTemp, setHighlightTemp] = useState([])
  const [toogModal, setToogModal] = useState(false)
  const [memunew, setmemunew] = useState({
    menuon: 'ALL',
    menu1: true,
    onset: 'l',
    isswai: false,
    data: [1, 2, 3, 4, 5, 6]
  });
  const [tempModal, setTempModal] = useState({
    headType: '',
    titleName: '',
    datas: '',
    dateValue: '',
    link: '',
    language: '',
    imgpath: '',
    news_id: ''
  })


  async function gettop() {
    let reset = document.getElementById('totop')
    console.log();
    reset.scrollTo({ top: 0, behavior: "smooth" });
  }

  async function GetAccesstokenToken(callback) {
    axios({
        method: "post",
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
        headers: {
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "application/json",
        },
    })
        .then(async function (response) {

            if (callback) {
                callback(response.data.tokenBox);
            }
            sessionStorage.setItem('tokenBox', response.data.tokenBox)
        })
        .catch(function (error) {
            console.log(error);
        });

}

async function setImginStage(fd_img_id) {
  try {
    let response2 = await axios.get(`https://api.box.com/2.0/files/${fd_img_id}/content`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',
    });

    return URL.createObjectURL(response2.data)

  } catch {
    return null
  }

}

  async function filterNewsInfo(value) {

    let temp = {
      keySearch: value,
      page: 1,
      enties: 9999,
      sortList: {},
    };

    await axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/news/filterNewsinspace`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data.data) {
          let getStory = response.data.data.filter((e) => e.kn_name === "TTT SPACESHIP STORY" /* && e.news_is_highlight !== true */) || []
          let getActive = response.data.data.filter((e) => e.kn_name === "ACTIVITY & NEWS" /* && e.news_is_highlight !== true */) || []
          let getPromotion = response.data.data.filter((e) => e.kn_name === "PROMOTION" /* && e.news_is_highlight !== true */) || []
          let getHightLing = response.data.data.filter((l) => l.news_is_highlight === true) || []
          let getAllNOTHightLing = response.data.data.filter((l) => l.news_is_highlight !== true) || []
console.log(getActive,response.data.data);
          setStoryTemp(getStory);
          setActivityTemp(getActive);
          setPromotionTemp(getPromotion);
          setAllNewsTemp(response.data.data);
          setHighlightTemp(getHightLing);

          for (let item = 0; item < response.data.data.length; item++) {
            response.data.data[item].path_file = null
            response.data.data[item].ul_file_path = null
            if (response.data.data[item].imgobject) {
              let getpath = await setImginStage(response.data.data[item].imgobject.fd_img_id);

              response.data.data[item].path_file = getpath;
              response.data.data[item].ul_file_path = getpath;
              setAllNewsTemp(response.data.data);
              settest({ ...test, test: '' })
              test.test = '';
            }
          }

          getStory = response.data.data.filter((e) => e.kn_name === "TTT SPACESHIP STORY" /* && e.news_is_highlight !== true */) || []
          getActive = response.data.data.filter((e) => e.kn_name === "ACTIVITY & NEWS" /* && e.news_is_highlight !== true */) || []
          getPromotion = response.data.data.filter((e) => e.kn_name === "PROMOTION" /* && e.news_is_highlight !== true */) || []
          getHightLing = response.data.data.filter((l) => l.news_is_highlight === true) || []
          getAllNOTHightLing = response.data.data.filter((l) => l.news_is_highlight !== true) || []

          setStoryTemp(getStory);
          setActivityTemp(getActive);
          setPromotionTemp(getPromotion);

          console.log('getActive',getActive);
          setAllNewsTemp(response.data.data);
          setHighlightTemp(getHightLing);

          settest({ ...test, test: '' })
          test.test = '';
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function callback(isOpen, value) {
    var modal = document.querySelector('.modalNews');
    document.querySelector('html').classList.add('modal-opened');
    setTempModal({
      ...tempModal,
      headType: value.kn_name,
      titleName: value.news_title,
      datas: `${moment(value.news_start_date).format('DD MMM')} - ${moment(value.news_end_date).format('DD MMM YYYY')}`,
      dateValue: value.news_description,
      link: value.news_url,
      imgpath: value.ul_file_path,
      news_id: value.news_id
    });
    
    tempModal.headType = value.kn_name;
    tempModal.titleName = value.news_title;
    tempModal.datas = `${moment(value.news_start_date).format('DD MMM')} - ${moment(value.news_end_date).format('DD MMM YYYY')}`;
    tempModal.dateValue = value.news_description;
    tempModal.link = value.news_url;
    tempModal.imgpath = value.ul_file_path;
    tempModal.news_id = value.news_id;

    setToogModal(isOpen)

  }


  function onClose(value) {
    var modal = document.querySelector('.modalNews');
    document.querySelector('html').classList.remove('modal-opened');
    setToogModal(false)
    settest({ ...test, test: '' })
    test.test = '';
  }

/*   useEffect(() => {
    var modal = document.querySelector('.modalNews');
    document.querySelector('html').classList.remove('modal-opened');
    filterNewsInfo()
  }, []) */



  
  useEffect(() => {

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      setModeMobile(true);
    }
    else {
      setModeMobile(false);
      var modal = document.querySelector('.modalNews');
      document.querySelector('html').classList.remove('modal-opened');
      GetAccesstokenToken((result)=>{
        if(result){
          filterNewsInfo()
        }
      })
    

    }

  }, []);




  function OpenModal(value) {

 /*    if (callback !== undefined) {
      callback(true, value)
    } else { */
      sessionStorage.setItem('News_id', value.news_id)
      
      /* window.location.href = '/viewNews' */
  /*   } */
    settest({ ...test, test: '' })
    test.test = '';
  }

  return (
    <>
{modeMobile  ? <>
<NewsInfoMobile />
 </> : (<>
  <HeaderTTT />
      <img className='bg-information' src="../../assets/img/bg/imgmian.png" alt="" />
      <div className='bg-detail' >
        <Fade left delay={100} >
          <div id='totop' style={{ width: '300px', marginBottom: '80px', marginLeft: '-15px' }}>
            <Title name='BULLETIN BOARD' no={1} fix={260} imglong={145} top={7.5} imgbox={110} imgcorner={12} />
          </div>
          <Fade right delay={100} opposite when={memunew.menu1}>
            {memunew.menuon === 'ALL' ?
              (<>
                <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}
                  onClick={() => {
                    setmemunew({
                      ...memunew,
                      menu1: false,
                    });
                    memunew.isswai = 1
                    memunew.menuon = 'ACTIVITY'
                    setTimeout(function () {
                      setmemunew({
                        ...memunew,
                        menu1: true,
                      });
                    }, 450);
                  }}
                >
                  <font className={'fontborderfocus'} >
                    ACTIVITY & NEWS
                  </font>
                </div>
                <div className="readmores" onClick={() => {
                  setmemunew({
                    ...memunew,
                    menu1: false,
                  });
                  memunew.isswai = 1
                  memunew.menuon = 'ACTIVITY'
                  setTimeout(function () {
                    setmemunew({
                      ...memunew,
                      menu1: true,
                    });
                  }, 450);

                }}>
                  READMORE
                </div>
                <div style={{ marginBottom: '100px' }}>
                  <Pagenew data={newActivityTemp} callback={callback} />
                </div>
                <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}
                  onClick={() => {
                    setmemunew({
                      ...memunew,
                      menu1: false,
                    });
                    memunew.isswai = 1
                    memunew.menuon = 'PROMOTION'
                    setTimeout(function () {
                      window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
                      setmemunew({
                        ...memunew,
                        menu1: true,
                      });

                    }, 450);

                  }}
                >
                  <font className={' fontborderfocus'} >
                    PROMOTION
                  </font>
                </div>
                <div className="readmores" onClick={() => {
                  setmemunew({
                    ...memunew,
                    menu1: false,
                  });
                  memunew.isswai = 1
                  memunew.menuon = 'PROMOTION'
                  setTimeout(function () {
                    window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
                    setmemunew({
                      ...memunew,
                      menu1: true,
                    });
                  }, 450);

                }}>
                  READMORE
                </div>
                <div style={{ marginBottom: '100px' }}>
                  <Pagenew data={newPromotionTemp} callback={callback} />
                </div>
                <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}
                  onClick={() => {
                    setmemunew({
                      ...memunew,
                      menu1: false,
                    });
                    memunew.isswai = 1
                    memunew.menuon = 'SPACESHIP'
                    setTimeout(function () {
                      window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
                      setmemunew({
                        ...memunew,
                        menu1: true,
                      });
                    }, 450);
                  }}
                >
                  <font className={' fontborderfocus'} >
                    TTT SPACESHIP STORY
                  </font>
                </div>
                <div className="readmores" onClick={() => {
                  setmemunew({
                    ...memunew,
                    menu1: false,
                  });
                  memunew.isswai = 1
                  memunew.menuon = 'SPACESHIP'
                  setTimeout(function () {
                    window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
                    setmemunew({
                      ...memunew,
                      menu1: true,
                    });
                  }, 450);
                }}>
                  READMORE
                </div>
                <div style={{ marginBottom: '100px' }}>
                  <Pagenew data={newStoryTemp} callback={callback} />
                </div>
              </>)
              :
              (<>

                {memunew.menuon === 'ACTIVITY' ?
                  (<>
                    <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}
                    >
                      <img src={iconback} alt="back" style={{ marginRight: '15px' }} onClick={() => {

                        setmemunew({
                          ...memunew,
                          menuon: 'ALL',
                          menu1: false,

                        });
                        memunew.onset = memunew.isswai > 1 ? 'r' : 'l'
                        memunew.isswai = 1
                        memunew.menuon = 'ALL'
                        setTimeout(function () {
                          window.scrollTo({ top: 200, left: 0, behavior: 'smooth' });
                          setmemunew({
                            ...memunew,
                            menu1: true,
                          });

                        }, 450);

                      }} />
                      <font className={'fontborderfocus'} >
                        ACTIVITY & NEWS
                      </font>
                    </div>
                    <div className='row' style={{ marginBottom: '100px' }}>
                      {newActivityTemp.length > 0 ?
                        newActivityTemp.map((e, inde) => {
                         /*  console.log('newActivityTemp',newActivityTemp); */
                          let typeNews = '';
                          let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
                          if (e.kn_name === "PROMOTION") {
                            typeNews = 'p'
                          } else if (e.kn_name === "ACTIVITY & NEWS") {
                            typeNews = 'n'
                          } else if (e.kn_name === "TTT SPACESHIP STORY") {
                            typeNews = 's'
                          }

                          return (
                            <div className='col-3' style={{ marginBottom: '32px' }}>
                              <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} bgevent={e.ul_file_path !== undefined && e.ul_file_path !== null ? e.ul_file_path : imgDEfule}
                                callback={OpenModal}
                                newsid={e}
                              />
                            </div>

                          )
                        })
                        : (<Card tagevent={'s'} nameevent={'Comming Soon'} date={moment(new Date()).format('DD MMM YYYY')} bgevent={'../../../../assets/img/bg/imgbdsport.jpg'} />)}
                     
                    </div></>)
                  :
                  (<></>)}

                {memunew.menuon === 'PROMOTION' ?
                  (<>
                    <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}

                    >
                      <img src={iconback} alt="back" style={{ marginRight: '15px' }} onClick={() => {

                        setmemunew({
                          ...memunew,
                          menuon: 'ALL',
                          menu1: false,

                        });
                        memunew.onset = memunew.isswai > 1 ? 'r' : 'l'
                        memunew.isswai = 1
                        memunew.menuon = 'ALL'
                        setTimeout(function () {
                          setmemunew({
                            ...memunew,
                            menu1: true,
                          });
                        }, 450);

                      }} />
                      <font className={' fontborderfocus'} >
                        PROMOTION
                      </font>
                    </div>
                    <div className='row' style={{ marginBottom: '100px' }}>
                      {newPromotionTemp.length > 0 ?
                        newPromotionTemp.map((e, inde) => {
                          let typeNews = '';
                          let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
                          if (e.kn_name === "PROMOTION") {
                            typeNews = 'p'
                          } else if (e.kn_name === "ACTIVITY & NEWS") {
                            typeNews = 'n'
                          } else if (e.kn_name === "TTT SPACESHIP STORY") {
                            typeNews = 's'
                          }

                          return (
                            <div className='col-3' style={{ marginBottom: '32px' }} >
                              <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} bgevent={e.ul_file_path !== undefined && e.ul_file_path !== null ? e.ul_file_path : imgDEfule}
                                callback={OpenModal}
                               
                                newsid={e}
                              />
                            </div>

                          )
                        })
                        : (<Card tagevent={'s'} nameevent={'Comming Soon'} date={moment(new Date()).format('DD MMM YYYY')} bgevent={'../../../../assets/img/bg/imgbdsport.jpg'} />)}

                    
                    </div></>)
                  :
                  (<></>)}

                {memunew.menuon === 'SPACESHIP' ?
                  (<>
                    <div className='fontheader' style={{ marginBottom: '40px', marginLeft: '-18px' }}

                    >
                      <img src={iconback} alt="back" style={{ marginRight: '15px' }} onClick={() => {

                        setmemunew({
                          ...memunew,
                          menuon: 'ALL',
                          menu1: false,

                        });
                        memunew.onset = memunew.isswai > 1 ? 'r' : 'l'
                        memunew.isswai = 1
                        memunew.menuon = 'ALL'
                        setTimeout(function () {
                          setmemunew({
                            ...memunew,
                            menu1: true,
                          });
                        }, 450);

                      }} />
                      <font className={' fontborderfocus'} >
                        TTT SPACESHIP STORY
                      </font>
                    </div>
                    <div className='row' style={{ marginBottom: '100px' }}>
                      {newStoryTemp.length > 0 ?
                        newStoryTemp.map((e, inde) => {
                          console.log('newStoryTemp',e);
                          let typeNews = '';
                          let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
                          if (e.kn_name === "PROMOTION") {
                            typeNews = 'p'
                          } else if (e.kn_name === "ACTIVITY & NEWS") {
                            typeNews = 'n'
                          } else if (e.kn_name === "TTT SPACESHIP STORY") {
                            typeNews = 's'
                          }

                          return (
                            <div className='col-3' style={{ marginBottom: '32px' }}>
                              <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} 
                              bgevent={e.ul_file_path !== undefined && e.ul_file_path !== null ? e.ul_file_path : imgDEfule}
                                callback={OpenModal}
                                newsid={e}
                              />
                            </div>

                          )
                        })
                        : (<Card tagevent={'s'} nameevent={'Comming Soon'} date={moment(new Date()).format('DD MMM YYYY')} bgevent={'../../../../assets/img/bg/imgbdsport.jpg'} />)}
                  
                    </div></>)
                  :
                  (<></>)}

              </>)}




          </Fade>
        </Fade>
      </div>

      <ModalNew
        isOpen={toogModal}
        onClose={onClose}
        headType={tempModal.headType}
        titleName={tempModal.titleName}
        datas={tempModal.datas}
        dateValue={tempModal.dateValue}
        link={tempModal.link}
        imgpath={tempModal.imgpath}
        newID={tempModal.news_id}

      />
</>) }
     



    </>

  )
}

