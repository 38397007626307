import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import { ConfigProvider, DatePicker, Select } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
/* import Select from 'react-select' */
import moment from "moment";
import axios from "axios";
import './tabSrs.css'
import Configs from "../../../../config";
import { Card, Switch, Tag, Tooltip, Button, Checkbox, TreeSelect, Input } from 'antd';
import dayjs from 'dayjs';
import styled from 'styled-components';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const StyledDatePicker = styled(DatePicker)`
.ant-picker-clear {
    padding-Right: 10px; 
}
`;
;



function TabCompanentSRS({ roleOptionData, callBackSrsSave, isGetBySRS, tempSrsSaveFackData, callbackSrsSpecificField, srsSpecificField, userismode }) {
    // console.log("SRS:>>", userismode);
    
    const [optionRole, setOptionRole] = useState([])
    const [textAreaId, setTextAreaId] = useState({})
    const [test, settest] = useState({ test: '' })
    const [tempSrsSave, setTempSrsSave] = useState(
        {
            prepared_sp_name: '',
            prepared_sp_position_id: '',
            prepared_sp_position: '',
            prepared_sp_operation: 'PREPARED BY',
            prepared_sp_date_sign: '',
            prepared_sp_operation: '',

            verified_sp_name: '',
            verified_sp_position_id: '',
            verified_sp_position: '',
            verified_sp_operation: 'VERIFIED BY',
            verified_sp_date_sign: '',
            verified_sp_operation: '',

            approved_sp_name: '',
            approved_sp_position_id: '',
            approved_sp_position: '',
            approved_sp_operation: 'APPROVED BY',
            approved_sp_date_sign: '',
            approved_sp_operation: '',

            customer_approved_sp_name: '',
            customer_approved_sp_position_id: '',
            customer_approved_sp_position: '',
            customer_approved_sp_operation: 'CUSTOMER APPROVED',
            customer_approved_sp_date_sign: '',
            customer_approved_sp_operation: '',

        },
    )

    const [specificField, setSpecificField] = useState([]);

    useEffect(() => {
        /*  console.log('isOpen',isOpen); */
        console.log('roleOptionData', tempSrsSaveFackData);
        setOptionRole(roleOptionData);



        setTempSrsSave({
            ...tempSrsSave,
            prepared_sp_name: tempSrsSaveFackData.prepared_sp_name || null,
            prepared_sp_position_id: tempSrsSaveFackData.prepared_sp_position_id || null,
            prepared_sp_position: tempSrsSaveFackData.prepared_sp_position || null,
            prepared_sp_date_sign: tempSrsSaveFackData.prepared_sp_date_sign || null,
            prepared_sp_operation: tempSrsSaveFackData.prepared_sp_operation || null,

            verified_sp_name: tempSrsSaveFackData.verified_sp_name || null,
            verified_sp_position_id: tempSrsSaveFackData.verified_sp_position_id || null,
            verified_sp_position: tempSrsSaveFackData.verified_sp_position || null,
            verified_sp_date_sign: tempSrsSaveFackData.verified_sp_date_sign || null,
            verified_sp_operation: tempSrsSaveFackData.verified_sp_operation || null,

            approved_sp_name: tempSrsSaveFackData.approved_sp_name || null,
            approved_sp_position_id: tempSrsSaveFackData.approved_sp_position_id || null,
            approved_sp_position: tempSrsSaveFackData.approved_sp_position || null,
            approved_sp_date_sign: tempSrsSaveFackData.approved_sp_date_sign || null,
            approved_sp_operation: tempSrsSaveFackData.approved_sp_operation || null,

            customer_approved_sp_name: tempSrsSaveFackData.customer_approved_sp_name || null,
            customer_approved_sp_position_id: tempSrsSaveFackData.customer_approved_sp_position_id || null,
            customer_approved_sp_position: tempSrsSaveFackData.customer_approved_sp_position || null,
            customer_approved_sp_date_sign: tempSrsSaveFackData.customer_approved_sp_date_sign || null,
            customer_approved_sp_operation: tempSrsSaveFackData.customer_approved_sp_operation || null,


        })




    }, [roleOptionData, isGetBySRS])








    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    async function getDocumentTemplate(srs_value) {
        let id = "738fc3d7-b0ee-4bf3-b9fa-b0f3958f77f8"; //id srs document specifig
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {

                if (response.data) {
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                /* let check = srs_value.map((e)=>{return e.dt_input_data.data.map((e2)=>{return e2.value_list.map((e3)=>{return e3.value_id == item3.value_id})})})
                                console.log('check',check) */
                                for (let item_ of srs_value) {
                                    for (let item2_ of item_.dt_input_data.data) {
                                        for (let item3_ of item2_.value_list) {
                                            if (item3.value_id == item3_.value_id) {
                                                if (item3_.value_input) {
                                                    item3.value_input = item3_.value_input || '';
                                                }
                                                if (item3_.data_list) {
                                                    item3.data_list = item3_.data_list || [];
                                                }
                                                if (item3_.value_input_header) {
                                                    item3.value_input_header = item3_.value_input_header || '';
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                if (item3.value_type == 'table_column') {
                                    let temp_in = {
                                        value_input_table: [],
                                    }
                                    for (let item of item3.table_column_list) {
                                        temp_in.value_input_table.push('');
                                    }
                                    if (!item3.data_list) {
                                        item3.data_list = [];
                                    }
                                    if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                                        item3.data_list = [];
                                        console.log('หัวไม่เท่ากับ value')
                                    }
                                    if (item3.data_list.length == 0 && item3.is_multiple == false) {
                                        item3.data_list.push(temp_in);
                                    }

                                }

                                if (item3.value_id == "7f6d4901-319b-4aed-af5b-f0cbea20a942"  || item3.value_id == "8ca1ace7-b475-4c19-9cd4-94453d39b3f7") { // ครั้งที่เก็บความต้องการ
                                    item3.data_list.forEach(item3Value => {
                                        // console.log('value_input_table :>> ', item3Value.value_input_table[2]);
                                        const objEmployees = item3Value.value_input_table[2] && item3Value.value_input_table[2] !== "" ? parseEmployees(item3Value.value_input_table[2]) : null;
                                        const objParseLink = item3Value.value_input_table[5] && item3Value.value_input_table[5] !== "" ? parseLinks(item3Value.value_input_table[5]) : null;
                                        // console.log('objEmployees :>> ', objEmployees);
                                        item3Value.value_participants = objEmployees ? objEmployees : [];
                                        item3Value.value_link = objParseLink ? objParseLink : [];
                                    });
                                }
                            }
                        }
                    }
                    console.log('response.data dev:>> ', response.data);
                    setSpecificField(response.data);
                    callbackSrsSpecificField(response.data);

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function parseEmployees(input) {
        // แยกสตริงโดยใช้คอมม่าเป็นตัวแบ่ง
        let employees = input.split(',');

        // แปลงอาร์เรย์ที่ได้เป็นอาร์เรย์ของออบเจกต์
        let result = employees.map(employee => {
            // แยกชื่อและตำแหน่งโดยใช้ขีดเป็นตัวแบ่ง
            let [name, position] = employee.split('-');
            return {
                name: name.trim(),
                position: position ? position.trim() : null
            };
        });

        return result;
    }

    function parseLinks(input) {
        // ใช้ Regular Expression เพื่อจับคู่สตริงที่อยู่ใน <<link>>...<<link>>
        let regex = /<<link>>(.*?)<<link>>/g;
        let matches = input.match(regex);

        // ถ้าไม่มีการจับคู่ใดๆ ให้คืนค่าเป็นอาเรย์ว่าง
        if (!matches) return [];

        // แปลง matches ให้เป็นอาเรย์ของออบเจกต์
        let result = matches.map(match => {
            // ตัด <<link>> ออก
            match = match.replace(/<<link>>/g, '');

            // แยก name และ path โดยใช้คอมม่า
            let [name, path] = match.split(',');

            return {
                name: name.trim(),
                link: path.trim()
            };
        });

        return result;
    }

    function formatLinks(objects) {
        // ใช้ map เพื่อแปลงอาเรย์ของออบเจกต์เป็นอาเรย์ของสตริงในรูปแบบที่ต้องการ
        let formattedLinks = objects.map(obj => {
            return `<<link>>${obj.name},${obj.link}<<link>>`;
        });

        // รวมสตริงทั้งหมดเข้าด้วยกันโดยคั่นด้วยช่องว่าง
        return formattedLinks.join(' ');
    }

    function formatEmployees(objects) {
        // ใช้ map เพื่อแปลงอาเรย์ของออบเจกต์เป็นอาเรย์ของสตริงในรูปแบบที่ต้องการ
        let formattedEmployees = objects.map(obj => {
            return `${obj.name}-${obj.position}`;
        });

        // รวมสตริงทั้งหมดเข้าด้วยกันโดยคั่นด้วยคอมม่า
        return formattedEmployees.join(',');
    }

    useEffect(() => {
        getDocumentTemplate(srsSpecificField);
    }, [srsSpecificField]);


    const addParticipant = (index, index2, index3, index4) => {

        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants.push({
                name: "",
                position: ""
            })

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    const removeParticipant = (index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants.splice(index5, 1);
            dataItem.value_input_table[2] = dataItem.value_participants.length > 0 ? formatEmployees(dataItem.value_participants) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    const changeParticipantValueInJson = (key, value, index, index2, index3, index4, index5) => {
        // console.log('index, index2, index3, index4, index5 :>> ', index, index2, index3, index4, index5);
        // console.log('value :>> ', value);
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants[index5][key] = value;
            dataItem.value_input_table[2] = dataItem.value_participants.length > 0 ? formatEmployees(dataItem.value_participants) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }

    const addLinkDoc = (index, index2, index3, index4) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link.push({
                name: "",
                link: ""
            })

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };
    const removeLinkDoc = (index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link.splice(index5, 1);
            dataItem.value_input_table[5] = dataItem.value_link.length > 0 ? formatLinks(dataItem.value_link) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };
    const handleLinkInputChange = (key, value, index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link[index5][key] = value;
            dataItem.value_input_table[5] = dataItem.value_link.length > 0 ? formatLinks(dataItem.value_link) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    // const [linkDoc, setLinkDoc] = useState([]);

    // const addLinkDoc = () => {
    //     setLinkDoc([...linkDoc, { name: '', link: '' }]);
    // };

    // const handleLinkInputChange = (index, field, value) => {
    //     const newLinkDoc = [...linkDoc];
    //     newLinkDoc[index][field] = value;
    //     setLinkDoc(newLinkDoc);
    // };
    // const removeLinkDoc = (index) => {
    //     const newLinkDoc = linkDoc.filter((_, i) => i !== index);
    //     setLinkDoc(newLinkDoc);
    // };

    const [textChange, setTextChange] = useState({ text: '' });

    function addValueMultiple(index, index2, index3, type) {
        console.log(index, index2, index3)
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1) {
            if (type == 'table_column') {
                let temp_in = {
                    value_input_table: [],
                }
                for (let item of temp[index].dt_input_data.data[index2].value_list[index3].table_column_list) {
                    temp_in.value_input_table.push('');
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            } else {
                let temp_in = {
                    value_input: '',
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            }



            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }

    function removeValueMultiple(index, index2, index3, index4) {
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1 && index4 > -1) {
            temp[index].dt_input_data.data[index2].value_list[index3].data_list.splice(index4, 1);
            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }


    function changeValueInJson(key, value, index, indexGroup, indexValue, index_, index_column, key_arr, index5) {
        console.log(key, value, index, indexGroup, indexValue, index_, index5)

        let tempData = specificField || [];
        //console.log( tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`])
        setTextChange({ ...textChange, text: value });
        textChange.text = value;
        /* if (key == 'value' || key == 'value_type' || key == 'is_multiple') {
    
          tempData[indexGroup]['value_list'][indexValue][key] = textChange.text;
        } else { */
        if (key == 'value_input_table') {
            console.log(tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_])
            if (index_column === 2) {
                let arrayValue = {
                    name: tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[2][index5]?.name || '',
                    position: tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[2][index5]?.position || ''
                };
                arrayValue[key_arr] = value || ''

                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column][index5] = arrayValue;
            } else {
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column] = textChange.text;
            }
        } else {
            if (index_ > -1) {
                console.log("in")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`] = textChange.text;
            } else {
                console.log("out")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue][`${key}`] = textChange.text;
            }
        }


        /*   } */
        setSpecificField(tempData);
        callbackSrsSpecificField(tempData);
        settest({ ...test, test: '' });
        test.test = '';

    }




    return (
        <>
            <div className="box-content-evens2">
                <div className="row delpm">
                    <div className="col-12">
                        <div className="box-content-evens1" style={{ height: '550px', marginBottom: "5px" }}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Prepared by</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            id={textAreaId.prepared_sp_name}
                                            type='text'
                                            value={tempSrsSave.prepared_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {

                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'prepared_sp_name')
                                            }}
                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px'}}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>
                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.prepared_sp_position_id })}
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                // console.log('ดูข้อมูลในดาว', e, l, l.label);
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_position_id: e,
                                                    prepared_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'prepared_sp_position_id', l.label, 'prepared_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.prepared_sp_date_sign ? dayjs(tempSrsSave.prepared_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'prepared_sp_date_sign')
                                            }}
                                            
                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.prepared_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'prepared_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Verified by </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.verified_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'verified_sp_name')
                                            }}

                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            onSearch={onSearch}
                                            filterOption={filterOption}

                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.verified_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_position_id: e,
                                                    verified_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'verified_sp_position_id', l.label, 'verified_sp_position')

                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                    <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.verified_sp_date_sign ? dayjs(tempSrsSave.verified_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'verified_sp_date_sign')
                                            }}
                                            
                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.verified_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'verified_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Approved by</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.approved_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'approved_sp_name')
                                            }}
                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            options={optionRole}
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.approved_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_position_id: e,
                                                    approved_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'approved_sp_position_id', l.label, 'approved_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                    <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.approved_sp_date_sign ? dayjs(tempSrsSave.approved_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'approved_sp_date_sign')
                                            }}
                                            
                                        />  
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.approved_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'approved_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Customer Approved</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.customer_approved_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'customer_approved_sp_name')

                                            }}

                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.customer_approved_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_position_id: e,
                                                    customer_approved_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'customer_approved_sp_position_id', l.label, 'customer_approved_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                    <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.customer_approved_sp_date_sign ? dayjs(tempSrsSave.customer_approved_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'customer_approved_sp_date_sign')
                                            }}
                                            
                                        />  
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.customer_approved_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'customer_approved_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">

                                    {specificField && specificField.map((e, index) => {
                                        //console.log(0)
                                        return (<>

                                            <div className="row">
                                                <div className="col-12" style={{ marginTop: "20px" }}>
                                                    <span style={{ fontSize: '24px' }}>{e.dt_name}</span>
                                                </div>

                                            </div>
                                            <div className="boxSpecific" >
                                                <div className="boxSpecificIn" >
                                                    {e.dt_input_data.data.length == 0 && (<><font color="red">No specific</font></>)}
                                                    {e.dt_input_data.data.map((e2, index2) => {

                                                        return (<>
                                                            {index2 > 0 && (<br />)}
                                                            <div className="row mt-2">
                                                                <div className="col-12"><Tooltip color='#051F6C' placement="right" title={e2.suggestion}>
                                                                    <span style={{ fontSize: '18px' }}>{index2 + 1}.{e2.group_name}</span>
                                                                </Tooltip></div>

                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                {e2.value_list.length == 0 && (<> <div className="col-6"><font color="red">No value</font></div></>)}
                                                                {e2.value_list.map((e3, index3) => {

                                                                    return (<>
                                                                        <div className="col-12">
                                                                            <div className="mb-2 mt-2">
                                                                                {index2 + 1}.{index3 + 1}. {e3.value}
                                                                                {/* {userismode === false ? 
                                                                                (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px' }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD
                                                                                    </button>)
                                                                                    }
                                                                                </>) : <></>} */}
                                                                            </div>

                                                                            {e3.is_multiple ? (<>
                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                    return (<>
                                                                                        {e3.value_type == 'table' && (<>
                                                                                            {e3.is_border == true ? <table >
                                                                                                <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />


                                                                                                </td>
                                                                                                {userismode === false ? (
                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                        className='btnRed ml-2'
                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </table> :

                                                                                                <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>

                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px', width: '30%' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />



                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                    {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                </div>
                                                                                            }


                                                                                        </>)}
                                                                                        {e3.value_type == 'description' && (<>


                                                                                            <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e4.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        //console.log("test", v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                    }}
                                                                                                />
                                                                                                {userismode === false ? (
                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                        className='btnRed'
                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </div>
                                                                                        </>)}




                                                                                    </>);

                                                                                })}

                                                                                {e3.value_type === 'table_column' && (
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                                                        {e3.data_list && e3.data_list.map((dataItem, index4) => (
                                                                                            <div key={index4} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', border: '2px solid #0349FF', padding: '25px 25px 25px 25px' }}>
                                                                                                <>
                                                                                                    <div className='row'>
                                                                                                        <div style={{ width: '520px' }}>
                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>ครั้งที่ประชุม <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <div style={{ display: 'flex', gap: '5%' }}>
                                                                                                                    <TextArea
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px', width: '240px' }}
                                                                                                                        autoSize={{ minRows: 1 }}
                                                                                                                        type='text'
                                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                        value={dataItem.value_input_table && dataItem.value_input_table[0]
                                                                                                                            ? dataItem.value_input_table[0]
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(v) => {
                                                                                                                            changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 0);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    {userismode === false ? (
                                                                                                                        <>
                                                                                                                            {/* <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                                                                                                className='btnRed'
                                                                                                                                onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                            ><i class="fa fa-trash" aria-hidden="true"></i></p> */}

                                                                                                                            <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                                className='btnRed'
                                                                                                                                onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                            ><i class="fa fa-trash" style={{ paddingBottom: 4 }} aria-hidden="true"></i>
                                                                                                                            </button>
                                                                                                                        </>
                                                                                                                    ) : <></>}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>สถานที่ <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <TextArea
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '435px' }}
                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={dataItem.value_input_table && dataItem.value_input_table[3]
                                                                                                                        ? dataItem.value_input_table[3]
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                    disabled={userismode}
                                                                                                                    onChange={(v) => {
                                                                                                                        changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 3);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>หัวข้อการประชุม <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <TextArea
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '380px', marginRight: '10px' }}
                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={dataItem.value_input_table && dataItem.value_input_table[1]
                                                                                                                        ? dataItem.value_input_table[1]
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                    disabled={userismode}
                                                                                                                    onChange={(v) => {
                                                                                                                        changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 1);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>เวลา <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <RangePicker
                                                                                                                    showTime
                                                                                                                    format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '450px' }}
                                                                                                                    // placeholder='DD/MM/YYYY HH:MM:ss'
                                                                                                                    suffixIcon={<div style={{ marginRight: '10px' }}><CalendarOutlined /></div>}
                                                                                                                    disabled={userismode}
                                                                                                                    defaultValue={
                                                                                                                        dataItem.value_input_table && dataItem.value_input_table[4]
                                                                                                                            ? dataItem.value_input_table[4].split(' - ').map(date => dayjs(date, "DD/MM/YYYY HH:mm:ss"))
                                                                                                                            : [null, null]
                                                                                                                    }
                                                                                                                    onChange={(_, v) => {
                                                                                                                        // console.log("test : >>>", v);
                                                                                                                        let date = v[0] + " - " + v[1]
                                                                                                                        // console.log("test__ : >>>", date);
                                                                                                                        changeValueInJson(`value_input_table`, date, index, index2, index3, index4, 4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div className='row' style={{ width: '700px' }}>
                                                                                                                <p>ผู้เข้าร่วมประชุม </p>
                                                                                                                {!userismode ? <>

                                                                                                                    <button
                                                                                                                        className="btnAddSpecific ml-2"
                                                                                                                        style={{ border: '2px solid #0349FF', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                                                                                                                        onClick={() => addParticipant(index, index2, index3, index4)}
                                                                                                                    >
                                                                                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                                                    </button>
                                                                                                                </> : null}

                                                                                                                <div style={{ flexGrow: 1 }}></div>
                                                                                                                <div className='row'>
                                                                                                                    <div style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0047FF', borderRadius: '15px', marginRight: '10px' }}>
                                                                                                                        <i class="fa fa-user" aria-hidden="true" style={{ color: 'black' }}></i>
                                                                                                                    </div>
                                                                                                                    <p>{dataItem?.value_participants?.length}</p>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div style={{ maxHeight: '320px', overflowY: 'auto' }}>

                                                                                                                {dataItem?.value_participants?.map((obj, index5) => (
                                                                                                                    <div className='row' style={{ display: 'flex', gap: '2%' }}>
                                                                                                                        <div style={{ padding: '10px 0px 0px 10px' }}>
                                                                                                                            <p style={{ border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                                                                                                                                {index5 + 1}
                                                                                                                            </p>
                                                                                                                            <br />
                                                                                                                            {!userismode ? <>
                                                                                                                                <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                                    className='btnRed'
                                                                                                                                    onClick={() => { removeParticipant(index, index2, index3, index4, index5); }}
                                                                                                                                ><i class="fa fa-trash" style={{ paddingBottom: 4 }} aria-hidden="true"></i>
                                                                                                                                </button>
                                                                                                                            </> : null}
                                                                                                                        </div>
                                                                                                                        <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                                <p style={{ paddingRight: '6px' }}>ชื่อ - นามสกุล <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> :</p>
                                                                                                                                <TextArea
                                                                                                                                    className="areaTEst rezieznone"
                                                                                                                                    style={{ borderRadius: '0px', width: '360px' }}
                                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                                    type='text'
                                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                                    value={obj.name ?? ''}
                                                                                                                                    disabled={userismode}
                                                                                                                                    onChange={(e) => changeParticipantValueInJson('name', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                                />
                                                                                                                            </div>

                                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                                <p style={{ padding: '0px 6px 0px 36px' }}>ตำแหน่ง <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> :</p>
                                                                                                                                <TextArea
                                                                                                                                    className="areaTEst rezieznone"
                                                                                                                                    style={{ borderRadius: '0px', width: '360px' }}
                                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                                    type='text'
                                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                                    value={obj.position}
                                                                                                                                    disabled={userismode}
                                                                                                                                    onChange={(e) => changeParticipantValueInJson('position', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='row' style={{ width: '700px' }}>
                                                                                                            <p>LINK DOCUMENT </p>
                                                                                                            {!userismode ? <>
                                                                                                                <button
                                                                                                                    className="btnAddSpecific ml-2"
                                                                                                                    style={{ border: '2px solid #0349FF', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                                                                                                                    onClick={() => addLinkDoc(index, index2, index3, index4)}
                                                                                                                >
                                                                                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                                                </button>
                                                                                                            </> : null}

                                                                                                        </div>

                                                                                                        {dataItem?.value_link?.map((linkDoc, index5) => (
                                                                                                            <div className='row' style={{ display: 'flex', gap: '2%' }}>
                                                                                                                <div style={{ padding: '10px 0px 0px 10px' }}>
                                                                                                                    <p style={{ border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                                                                                                                        {index5 + 1}
                                                                                                                    </p>
                                                                                                                    <br />

                                                                                                                    {!userismode ? <>
                                                                                                                        <button style={{ border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                            className='btnRed'
                                                                                                                            onClick={() => { removeLinkDoc(index, index2, index3, index4, index5); }}
                                                                                                                        ><i class="fa fa-trash" aria-hidden="true" style={{ paddingBottom: 4 }}></i>
                                                                                                                        </button>
                                                                                                                    </> : null}

                                                                                                                </div>
                                                                                                                <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                                                                                                                    <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                        <p style={{ paddingRight: '6px' }}>name :</p>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px', width: '1000px' }}
                                                                                                                            autoSize={{ minRows: 1 }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={linkDoc.name}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(e) => handleLinkInputChange('name', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                        <p style={{ padding: '0px 6px 0px 20px' }}>link :</p>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px', width: '1000px' }}
                                                                                                                            autoSize={{ minRows: 1 }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={linkDoc.link}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(e) => handleLinkInputChange('link', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}

                                                                                                    </div>
                                                                                                </>

                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                )}

                                                                                {/* <table style={{ width: '100%' }}>
                                                                                            <tr>
                                                                                                {e3.table_column_list.map((e4) => {
                                                                                                    return (<>
                                                                                                        <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                    </>)
                                                                                                })}
                                                                                            </tr>

                                                                                            {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                return (<>
                                                                                                    <tr>
                                                                                                        {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                            return (<>
                                                                                                                <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                    {index5 == 4 ? (<>
                                                                                                                    <DatePicker
                                                                                                                        showTime
                                                                                                                        format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                                        // autoSize={{
                                                                                                                        //     minRows: 1
                                                                                                                        // }}
                                                                                                                        // type='date'
                                                                                                                        // value={e5}
                                                                                                                        placeholder='DD/MM/YYYY'
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(_, v) => {
                                                                                                                            console.log("test : >>>", v);
                                                                                                                            changeValueInJson(`value_input_table`, v, index, index2, index3, index4, index5);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    </>) :
                                                                                                                    <TextArea
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                                        autoSize={{
                                                                                                                            minRows: 1
                                                                                                                        }}
                                                                                                                        type='text'
                                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                        value={e5}
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(v) => {
                                                                                                                            //console.log("test", v.target.value);
                                                                                                                            changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    } 
                                                                                                                </td>
                                                                                                            </>)
                                                                                                        })}
                                                                                                        <td>
                                                                                                            {userismode === false ? (
                                                                                                                <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                                                                                    className='btnRed'
                                                                                                                    onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>)

                                                                                            })}

                                                                                        </table> */}

                                                                                {/* </div>
                                                                                </>)} */}

                                                                            </>) : (<>

                                                                                {e3.value_type == 'table' && (<>

                                                                                    {e3.is_border == true ?
                                                                                        <table >
                                                                                            <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px', width: '100%' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR HEADER...'
                                                                                                    value={e3.value_input_header}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>
                                                                                            <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e3.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>

                                                                                        </table> : <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px', width: '30%' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR HEADER...'
                                                                                                value={e3.value_input_header}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                value={e3.value_input}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                        </div>}

                                                                                </>)}

                                                                                {e3.value_type == 'description' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                            style={{ borderRadius: '0px' }}
                                                                                            autoSize={{
                                                                                                minRows: 1
                                                                                            }}
                                                                                            type='text'
                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                            value={e3.value_input}
                                                                                            disabled={userismode}
                                                                                            onChange={(v) => {
                                                                                                console.log(v.target.value);
                                                                                                changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </>)}

                                                                                {e3.value_type == 'table_column' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        {e3.value_type == 'table_column' && (<>

                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    {e3.table_column_list.map((e4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                        </>)
                                                                                                    })}
                                                                                                </tr>

                                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                    return (<>
                                                                                                        <tr>
                                                                                                            {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                                return (<>
                                                                                                                    <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={e5}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(v) => {
                                                                                                                                //console.log("test", v.target.value);
                                                                                                                                changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </>)
                                                                                                            })}

                                                                                                        </tr>
                                                                                                    </>)

                                                                                                })}
                                                                                                {/* {e3.data_list.map((e4, index4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', padding: '1%' }}>
                                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                                    autoSize={{
                                                                                                                        minRows: 1
                                                                                                                    }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={e4}

                                                                                                                    onChange={(v) => {
                                                                                                                        //console.log("test", v.target.value);
                                                                                                                        changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </>)
                                                                                                    })} */}

                                                                                            </table>


                                                                                        </>)}

                                                                                    </div>
                                                                                </>)}

                                                                            </>)}

                                                                            {!userismode ? e3.is_multiple && (<button
                                                                                className="btnAddSpecific" style={{ height: '32px', paddingTop: '2px', marginBottom: '1.6rem', }}
                                                                                onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                            ><i class="fa fa-plus" aria-hidden="true"></i> ADD
                                                                            </button>)
                                                                                : null}

                                                                        </div >
                                                                    </>)
                                                                })}

                                                            </div >

                                                        </>)
                                                    })}
                                                </div></div >
                                            <hr />
                                        </>);
                                    })}

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >

        </>
    );
}

export default TabCompanentSRS;
