import React, { useEffect, useState } from "react";
import { Input, Space, ConfigProvider, Dropdown, Menu, Tooltip } from "antd";
import { DownOutlined } from '@ant-design/icons';
import icondelete from '../../../assets/img/icon/delete4.png';
// import ModelAlertConfrim from "../../../components/AlertMassge/AlertConfrim";
import axios from "axios";
import Configs from "../../../config";
import ModelAlertMassge from '../../../components/AlertMassge/AlertMassge';
import { v4 as uuidv4 } from 'uuid';
import Swal from "sweetalert2";

const { TextArea } = Input;
const items = [
  { key: '1', label: 'Waiting', id: '2dcc380e-be2d-4240-a6c6-ffe1c9cb35a4' },
  { key: '2', label: 'Defect', id: 'cf8959ac-2c62-419e-ad24-592b13c0e983' },
  { key: '3', label: 'Cancel', id: '446f73f6-8930-4ca6-9046-dde9c8d85978' },
  { key: '4', label: 'Done', id: 'a292304c-42e4-4f3f-9951-edf4fd14af5e' },
];


const TestCaseComponent = ({ item, index, selectedItem }) => {
  let backgroundColor;
  let borderColor;

  switch (selectedItem) {
    case 'Defect':
      backgroundColor = '#920000';
      borderColor = '#FF0000';
      break;
    case 'Waiting':
      backgroundColor = '#0A2268';
      borderColor = '#0047FF';
      break;
    case 'Cancel':
      backgroundColor = '#5B5859';
      borderColor = '#A1A1A1';
      break;
    case 'Done':
      backgroundColor = '#0C680A';
      borderColor = '#1FFF1B';
      break;
    default:
      backgroundColor = '#0A2268';
      borderColor = '#0047FF';
  }

  return (
    <div
      className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-2"
      style={{
        border: `2px solid ${borderColor}`,
        width: 'auto',
        display: 'inline-block',
        backgroundColor: backgroundColor,
        marginLeft: '-4px'
      }}
    >
      <div>Test Case : {index + 1}</div>
    </div>
  );
};

function ModalTestCase({ isOpen, toggle_modal_process_srs, project_id, process_id, menu_id }) {
  const [selectedItems, setSelectedItems] = useState({});
  let [projectDetail, setProjectDetail] = useState([]);
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  const [test, settest] = useState({ test: "" });
  const [testCases, setTestCases] = useState([
    // {
    //   id: 0,
    //   // tc_id: '',
    //   tc_test_case_id: '',
    //   tc_created_date: Date.now(),
    //   tc_updated_date: Date.now(),
    //   tc_objective: '',
    //   tc_description: '',
    //   tc_data: '',
    //   tc_expected_result: '',
    //   tc_actual_result: '',
    //   tc_remark: '',
    //   tc_status_id: '2dcc380e-be2d-4240-a6c6-ffe1c9cb35a4',
    // }
  ]);
  function clearTestCase() {
    setTestCases([
      // {
      //   id: 0,
      //   // tc_id: '',
      //   tc_test_case_id: '',
      //   tc_created_date: Date.now(),
      //   tc_updated_date: Date.now(),
      //   tc_objective: '',
      //   tc_description: '',
      //   tc_data: '',
      //   tc_expected_result: '',
      //   tc_actual_result: '',
      //   tc_remark: '',
      //   tc_status_id: '',
      // }
    ]);
    // testCases.id = 0;
    // testCases.tc_test_case_id = '';
    // testCases.tc_created_date = Date.now();
    // testCases.tc_updated_date = Date.now();
    // testCases.tc_objective = '';
    // testCases.tc_description = '';
    // testCases.tc_data = '';
    // testCases.tc_expected_result = '';
    // testCases.tc_actual_result = '';
    // testCases.tc_remark = '';
    // testCases.tc_status_id = '';
    setSelectedItems({ 0: 'Waiting' });
  }

  const [processStage, setProcessStage] = useState({
    module_name: '',
    menu_name: '',
    menu_id: ''
  })
  const [successMassge, setSuccessMassge] = useState(false);
  const toggle_modal_successMassge = () => setSuccessMassge(!successMassge);

  const [messageBox, setMessageBox] = useState({
    message: [],
    isError: false,
    menuTitle: ''
  })

  const [confirmModal, setConfirmModal] = useState(false);
  const handleClick = () => {
    setConfirmModal(true);
  };
  const toggleModalConfirmModal = () => {
    setConfirmModal(!confirmModal);
  };

  const [displayTestCase, setDisplayTestCase] = useState(false);
  const toggleTestCase = () => {
    setDisplayTestCase(!displayTestCase);
  };

  const handleMenuClick = (index, e) => {
    const selectedItemLabel = items.find(item => item.key === e.key).label;
    setSelectedItems(prevState => ({ ...prevState, [index]: selectedItemLabel }));
  };

  const handleAddTestCase = () => {
    const newTestCase = {
      id: testCases.length, // กำหนดค่า id เป็นขนาดของ Array ก่อนหน้า
      tc_test_case_id: '',
      tc_created_date: Date.now(),
      tc_updated_date: Date.now(),
      tc_objective: '',
      tc_description: '',
      tc_data: '',
      tc_expected_result: '',
      tc_actual_result: '',
      tc_remark: '',
      tc_status_id: '',
    };

    setSelectedItems(prevSelectedItems => ({ ...prevSelectedItems, [newTestCase.id]: 'Waiting' }));
    setTestCases([...testCases, newTestCase]);
  };

  const handleDeleteTestCase = (indexToRemove) => {
    setTestCases((prevTestCases) =>
      prevTestCases.filter((_, index) => index !== indexToRemove)
    );
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };
      delete updatedSelectedItems[indexToRemove];
      const newSelectedItems = {};
      Object.keys(updatedSelectedItems).forEach(key => {
        const newIndex = key > indexToRemove ? key - 1 : key;
        newSelectedItems[newIndex] = updatedSelectedItems[key];
      });
      return newSelectedItems;
    });
  };

  const renderMenu = (index) => (
    <Menu onClick={(e) => handleMenuClick(index, e)}>
      {items.map(item => (
        <Menu.Item key={item.key}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSave = () => {
    // สร้างข้อมูลที่ถูกต้องสำหรับการส่งไปยัง API
    const data = {
      tc_project_id: project_id,
      tc_menu_id: menu_id,
      tc_array: testCases.map((testCase, index) => ({
        // tc_id: testCase.tc_id,
        tc_test_case_id: testCase.tc_test_case_id,
        tc_objective: testCase.tc_objective,
        tc_description: testCase.tc_description,
        tc_data: testCase.tc_data,
        tc_expected_result: testCase.tc_expected_result,
        tc_actual_result: testCase.tc_actual_result,
        tc_status_id: items.find(item => item.label === selectedItems[index]).id,
        tc_remark: testCase.tc_remark,
      })),
    };

    // ส่งข้อมูลไปยัง API
    addTestCase(data);
  };

  async function getTestCase(menu_id_) {
    toggle_modal_alertKick();
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/testCase/filterTestCase/${menu_id_}`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        console.log('Response received'); // Log to check if the response is received
        const testCaseData = response.data;
        console.log('testCaseData', testCaseData); // Log the received data

        // Map the received data to state variables
        const initialTestCases = testCaseData.map((testCase, index) => ({
          id: index,
          // tc_id: testCase.tc_id,
          tc_test_case_id: testCase.tc_test_case_id,
          tc_created_date: new Date(testCase.tc_created_date),
          tc_updated_date: new Date(testCase.tc_updated_date),
          tc_objective: testCase.tc_objective,
          tc_description: testCase.tc_description,
          tc_data: testCase.tc_data,
          tc_expected_result: testCase.tc_expected_result,
          tc_actual_result: testCase.tc_actual_result,
          tc_remark: testCase.tc_remark,
          tc_status_id: testCase.tc_status_id,
          tc_created_by: testCase.tc_created_by,
          tc_updated_by: testCase.tc_updated_by,
        }));

        const initialSelectedItems = initialTestCases.reduce(
          (acc, testCase, index) => ({
            ...acc,
            [index]: items.find((item) => item.id === testCase.tc_status_id)?.label || "Waiting",
          }),
          {}
        );
        setTestCases(initialTestCases);
        setSelectedItems(initialSelectedItems);
        setAlertKick(false)
        // toggleTestCase();
      })
      .catch(function (error) {
        console.log('Error occurred', error); // Log the error if any
      });
  };

  // function showLoading() {
  //   Swal.fire({
  //     title: "Loading",
  //     text: `Sync Data in Process.`,
  //     allowEscapeKey: false,
  //     allowOutsideClick: false,
  //     onOpen: () => {
  //       Swal.showLoading();
  //     },
  //   });
  // }

  // // Function to close loading modal
  // function closeLoading() {
  //   Swal.close();
  // }

  // Function to save test case
  async function addTestCase(data) {
    toggle_modal_alertKick();
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/testCase/SaveAddTestCase`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then(async function (response) {
        setAlertKick(false)
        toggle_modal_process_srs();
        clearTestCase();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function getDetailProcessProgress() {
    let temp = {
      project_id: project_id,
      menu_id: menu_id,
      process_id: process_id
    }
    axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/project/getDetailProcessProgress`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        console.log('response.data', response.data)
        setProcessStage({
          ...processStage,
          module_name: response.data.module_name,
          menu_name: response.data.menu_name,
          menu_id: response.data.menu_id
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // async function getProjectDetail(id) {
  //   await axios({
  //     method: "GET",
  //     url: `${Configs.TTT_Game_Service}/api/project/GetProjectDetail/${id}`,
  //     headers: {
  //       Authorization: sessionStorage.getItem("access_token"),
  //       "X-TTT": `${Configs.API_TTT}`,
  //       "Content-Type": "application/json",
  //     },
  //     /* data: temp, */
  //   })
  //     .then(async function (response) {
  //       setProjectDetail(response.data);
  //       projectDetail = response.data;
  //       settest({ ...test, test: "" });
  //       test.test = "";
  //       console.log("response.data", response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });

  //   console.log("projectDetail---<>", projectDetail);
  //   // console.log(JSON.parse(tempData.request?.response) );

  //   // setProjectDetail(JSON.parse(tempData.request?.response))
  //   // settest({ ...test, test: "" });
  //   // test.test = "";
  //   // return JSON.parse(tempData.request?.response);

  //   // tempData.then(async function (response) {

  //   //     setProjectDetail(response.data)

  //   //     console.log("response.data",response.data);
  //   //   })
  //   // .catch(function (error) {
  //   //   console.log(error);
  //   // });
  // }


  useEffect(() => {
    if (isOpen == true) {
      console.log('useEffect called'); // ตรวจสอบว่าฟังก์ชัน useEffect ถูกเรียก
      getDetailProcessProgress();
      getTestCase(menu_id);
    }
  }, [isOpen]);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorInfo: "#0047FF",
            colorBorder: "#0047FF",
            colorText: "#FFF",
            // colorTextDescription: "#000",
            // colorBgContainer: "#000",
            zIndexPopupBase: 9999999,
            colorPrimaryBg: "#0A2268",
            colorBgBase: "#0047FF",
            colorTextQuaternary: "#FFFFFF",
            // colorFillTertiary: "#003ace",
            colorTextBase: "blue",
            borderRadius: 0,
            colorPrimaryBorder: "#0047FF",
          },
        }}
      >
        <div
          className={`backdropModuleMenuTestCase ${isOpen ? "opened" : "closed"}`}
          onClick={() => {
            toggle_modal_process_srs();
            clearTestCase();
          }}
          style={{ zIndex: "99999" }}
        ></div>
        <div
          className={`backdropModuleMenuTestCase ${isOpen ? "opened" : "closed"}`}
          style={{
            top: "30%",
            left: "50%",
            position: "fixed",
            transform: "translate(-50%, -22%)",
            width: "80%",
            height: "80%",
            zIndex: "99999",
            backgroundColor: '#000f2b',
            border: '2px solid blue',/* Updated border property */
            boxSizing: 'border-box',  /* Ensure padding and border are included in the element's total width and height */
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div className="row" style={{ width: "100%", margin: 0, paddingBottom: '15px' }}>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-2">
                <h1>TEST CASE</h1>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "5px",
                }}
              >

                <Tooltip
                  title={testCases.length === 0 ? "Add a test case to save" : ""}
                  classes={{ tooltip: 'custom-tooltip' }}
                >
                  <button
                    className="mr-2 btn-outline-primary"
                    style={{ padding: "5px", fontWeight: 'bold', width: '100px', height: '40px', fontSize: '16px' }} // ขนาดคงที่
                    onClick={handleClick}
                    disabled={testCases.length === 0}
                  >
                    SAVE
                  </button>

                </Tooltip>


                <button
                  className=" btn-outline-danger"
                  style={{ padding: "5px", fontWeight: 'bold', width: '100px', height: '40px', fontSize: '16px' }} // ขนาดคงที่
                  onClick={() => {
                    toggle_modal_process_srs();
                    clearTestCase();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div style={{ borderTop: "1px solid #0047FF", height: "1px", width: "100%" }}></div>

            <div className="row" style={{ width: "100%", margin: 0, paddingTop: '10px' }}>
              <div style={{ marginRight: '10px' }}>
                {/* <h4 style={{ margin: '5px' }}>Module {">"} Menu</h4> */}
                <div className='stageElementHeaderMenu'>{processStage.module_name} {'>'} {processStage.menu_name}</div>
              </div>
              <label type="button"
                className="custom-buttonAdd"
                style={{ padding: "4px", fontSize: "15px", color: 'white' }}
                onClick={handleAddTestCase}
              >
                {"+"} ADD
              </label>
            </div>

            <div className="tcBoxSet">
              <div style={{ /* overflowY: "auto", */ width: '100%', }}>
                {/* testCases.length > 0 &&  */testCases.map((testCase, index, item) => (
                  <div key={index} style={{ border: '2px solid blue', width: '97%', marginTop: '20px', fontFamily: "Oxanium" }}>
                    <div style={{ padding: '30px' }}>
                      <div>
                        <div>
                          <TestCaseComponent
                            item={testCase}
                            index={index}
                            selectedItem={selectedItems[index]}
                          />

                          <div
                            /*  className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-2" */
                            style={{
                              border: '2px solid #0047FF',
                              width: '12%',
                              height: '27px',
                              display: 'inline-block',
                              backgroundColor: '#0A2268',
                              marginLeft: '10px',
                              position: 'relative'
                            }}
                          >
                            <ConfigProvider
                              theme={{
                                token: {
                                  borderRadius: 0,
                                  colorBgBase: '#0A2268',
                                  colorPrimaryBorder: '#0047FF',
                                  width: '100px',
                                  '--arrow-x': '51.8203125px',
                                  '--arrow-y': '-2px',
                                  boxSizing: 'border-box',
                                  maxHeight: '28vh',
                                  overflow: 'auto',
                                  boxShadow: 'none',
                                  // minWidth: '103.641px',
                                  marginLeft: '19.5%',
                                  position: 'absolute',
                                  width: '137px',
                                  // top: '347.43px', // Replace inset with individual position properties
                                  left: '0px'
                                },
                              }}
                            >
                              <Dropdown
                                overlay={renderMenu(index)}
                                trigger={['click']}
                                overlayClassName="custom-dropdown"
                                style={{
                                  width: '95%',
                                  color: 'white',
                                  border: 'none',
                                  fontSize: '14px',
                                  backgroundColor: 'transparent',
                                  borderRadius: 0
                                }}
                              >
                                <div onClick={(e) => e.preventDefault()}>
                                  <Space style={{ justifyContent: 'space-between', alignItems: 'center', width: '99%', paddingLeft: '6px' }}>
                                    <div style={{ flex: 1, textAlign: 'center' }}>
                                      {selectedItems[index] || 'Select'}
                                    </div>
                                    <DownOutlined style={{ marginLeft: 'auto' }} />
                                  </Space>
                                </div>
                              </Dropdown>
                            </ConfigProvider>
                          </div>

                          <div
                            style={{
                              display: 'inline-block',
                              marginLeft: '7px',
                              width: '27px',
                              height: '27px' // หรือค่าคงที่ที่คุณต้องการ
                            }}
                          >
                            <img
                              src={icondelete}
                              alt="description"
                              className="icon-hover2"
                              onClick={() => handleDeleteTestCase(index)}
                              style={{ width: '100%', height: '99%' }} // ให้รูปภาพปรับขนาดตาม div
                            />
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '30px', display: 'flex', justifyContent: 'space-between', fontFamily: "Oxanium" }}>
                          <div style={{ flex: 1, paddingRight: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Test Case ID :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  type='text'
                                  value={testCase.tc_test_case_id}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_test_case_id = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                                {/* <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  value={testCase.tc_id} // ใช้ค่า tc_id ตรงนี้เพื่อแสดง
                                /> */}
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1, paddingLeft: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Test Case Objective :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  value={testCase.tc_objective}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_objective = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', fontFamily: "Oxanium" }}>
                          <div style={{ flex: 1, paddingRight: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Test Description :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  type='text'
                                  value={testCase.tc_description}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_description = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1, paddingLeft: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Test Data :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  type='text'
                                  value={testCase.tc_data}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_data = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', fontFamily: "Oxanium" }}>
                          <div style={{ flex: 1, paddingRight: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Expected Result :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  type='text'
                                  value={testCase.tc_expected_result}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_expected_result = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1, paddingLeft: '20px' }}>
                            <div style={{ display: 'flex', marginBottom: '10px' }}>
                              <div>Actual Result :</div>
                              <div style={{ marginLeft: '10px', flex: 1 }}>
                                <TextArea
                                  className="areaTEst rezieznone"
                                  style={{ borderRadius: '0px', display: 'inline-block', width: '100%' }}
                                  autoSize={{ minRows: 1 }}
                                  type='text'
                                  value={testCase.tc_actual_result}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_actual_result = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', fontFamily: "Oxanium" }}>
                          <div style={{ flex: 1, paddingRight: '20px' }}>
                            <div style={{ marginBottom: '10px' }}>
                              <div>Remark :</div>
                              <div style={{ flex: 1 }}>
                                <TextArea
                                  className="areaTEst"
                                  style={{
                                    width: '673px',
                                    marginTop: '5px',
                                    resize: 'horizontal',
                                    overflow: 'auto'
                                  }}
                                  autoSize={{
                                    minRows: 1,
                                    maxRows: 10
                                  }}
                                  type='text'
                                  value={testCase.tc_remark}
                                  onChange={(e) => {
                                    setTestCases(prev => {
                                      const updated = [...prev];
                                      updated[index].tc_remark = e.target.value;
                                      return updated;
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>


          </div>

        </div>

        {/* {displaySwal && (
          <ModelAlertConfrim
            isOpen={displaySwal}
            mainFuc={() => handleSave()}
            toggle_confrim={() => toggle_modal_confrimModal()}
          />
        )} */}
      </ConfigProvider>

      <ModelAlertMassge
        isOpen={successMassge}
        toggle_alertKick={() => { toggle_modal_successMassge() }}
        message={messageBox}
        mainFuc={() => { /* Optional: เพิ่มฟังก์ชันที่ต้องการเรียกเมื่อกด OK ใน Alert */ }}
      />



      {confirmModal && (
        <div className='alertKick' style={{ display: confirmModal ? '' : 'none' }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox'>
              <div className='alertKickBoxIn'>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    ! warning !
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    WARNING
                  </div>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {/* {confirmControl.massger} */}
                    Do you want to save data?
                  </div>
                </div>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickTextSec'>
                    You won't be able to revert this!
                  </div>
                </div>
                <div className='row alertKickBoxButton'>
                  <button
                    className='mr-2 alertKickConfirm'
                    onClick={() => {
                      // ปิด Modal นั้น
                      toggleModalConfirmModal();

                      // เรียกใช้ฟังก์ชัน handleSave() เพื่อทำการบันทึก
                      handleSave();
                    }}
                  >
                    YES
                  </button>

                  <button
                    className='alertKickConcel'
                    style={{ border: '1px solid red !important' }}
                    onClick={toggleModalConfirmModal}
                  >
                    NO
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 99999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: 'green' }}>
                  Loading
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  <div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalTestCase;