import React from "react";
import "./inputwabStyle.css";

function Inputwab(param) {
/*   console.log("value",param.value)
  console.log("onChangeValue",param.onChangeValue) */
  return (
    <>
      <div className="inputwabShadowStyle">
        <div className="inputwabOuterStyle_1">  
          <div className="inputwabOuterStyle_2">
            <input
              type = {param.type || 'text'}
              className="inputwabInnerStyle"
              placeholder={param.placeholder || ""}
              value = {param.value}
              onChange = {param.onChangeValue}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Inputwab;
