import React,{ useState } from 'react';
import Slider from 'react-slick';
import '../../assets/css/info.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";

const Info = () => {
    return (
      <>
      <HeaderTTT/>
      <img className='bg-information' src="../assets/img/bg/game_manage_bg.jpg" alt="" />
        <section className="info-area live-streaming-two">
            <div className="hf-section-title mb-50 text-center">
                <h4 className="title">ข่าวสาร / โปรโมชั่น</h4>
              </div>
        <div className="container bg-category-new">
        
          <div className="row">
            <div className="col-sm-6">
              <div className="hf-section-title mb-50">
                <h4 className="title">live streaming</h4>
              </div>
            </div>
          </div>
            <div className="category-new col-xl-8 col-lg-7">
              <div className="live-stream-item mb-30">
                <div className="thumb">
                  <img src="assets/img/images/stream_thumb01.jpg" alt="" />
                  <a href="https://www.youtube.com/watch?v=ssrNcwxALS4" className="popup-video"><img src="assets/img/icon/w_play.png" alt="" /></a>
                </div>
                <div className="content">
                  <div className="live-stream-meta">
                    <ul>
                      <li className="cat"><a href="/#">CRICKET</a></li>
                      <li>3 months ago</li>
                    </ul>
                  </div>
                  <h4 className="title"><a href="/#">Ditched The Naked Chicken Chalupa Here’s To Make</a></h4>
                </div>
              </div>
            </div>
            
            <div className="category-new col-xl-8 col-lg-7">
              <div className="live-stream-item mb-30">
                <div className="thumb">
                  <img src="assets/img/images/stream_thumb01.jpg" alt="" />
                  <a href="https://www.youtube.com/watch?v=ssrNcwxALS4" className="popup-video"><img src="assets/img/icon/w_play.png" alt="" /></a>
                </div>
                <div className="content">
                  <div className="live-stream-meta">
                    <ul>
                      <li className="cat"><a href="/#">CRICKET</a></li>
                      <li>3 months ago</li>
                    </ul>
                  </div>
                  <h4 className="title"><a href="/#">Ditched The Naked Chicken Chalupa Here’s To Make</a></h4>
                </div>
              </div>
            </div>
            
        </div>

        <div className="container bg-title-new">
        
        <div className="category-new col-xl-8 col-lg-7">
              <div className="live-stream-item mb-30">
                <div className="thumb">
                  <img src="assets/img/images/stream_thumb01.jpg" alt="" />
                  <a href="https://www.youtube.com/watch?v=ssrNcwxALS4" className="popup-video"><img src="assets/img/icon/w_play.png" alt="" /></a>
                </div>
                <div className="content">
                  <div className="live-stream-meta">
                    <ul>
                      <li className="cat"><a href="/#">CRICKET</a></li>
                      <li>3 months ago</li>
                    </ul>
                  </div>
                  <h4 className="title"><a href="/#">Ditched The Naked Chicken Chalupa Here’s To Make</a></h4>
                </div>
              </div>
            </div>
            
        </div>
        
        <div className="button-share-info ">
                  {/* <h5>Forza <span>Horizon ii</span></h5>
                  <div className="match-rating">
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                    <i className="fas fa-star" />
                  </div> */}
            <div className="match-info-action ">
                <a href="/#" className="btn">แชร์ไปยัง</a>
                <a href="/#" className="btn transparent-btn"><i className="fas fa-comments" /> คัดลอกลิงค์</a>
            </div>
        </div>  

        <div className="container button-info">
                <a href="/#" className="btn col-xl-6 col-lg-6">ข่าวก่อนหน้า</a>
                <a href="/#" className="btn col-xl-6 col-lg-6"> ข่าวถัดไป</a>
        </div>  

        <div className="button-report ">
                <a href="/#" className="btn mb-5">รายงาน</a>
        </div>  
        
      </section>
      <Footer/>
      </>
      )
      
    }
      export default Info