import React, { useEffect, useState, useRef } from 'react';
import "./cardstageStyle.css";
import moment from "moment";
import moreicon from '../../assets/img/icon/more-vertical.png'
import icondate from '../../assets/img/images/calendar_story.png'
import iconlist from '../../assets/img/icon/list.png'
import styled from "styled-components";
import Cardpercent from "./cardpercent";
import { param } from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, } from '@fortawesome/free-solid-svg-icons';
import SelectProject from '../component/SelectProject/selectProject';
import { useLocation } from 'react-router-dom';

function Cardproject(param) {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectSuperDetailId = searchParams.get('id');
  // console.log("projectSuperDetailId: ",projectSuperDetailId);

  useEffect(() => {
    if(projectSuperDetailId){
      let getUser_id = sessionStorage.getItem('user_id');
      localStorage.setItem("projectAct", JSON.stringify({ project_id: projectSuperDetailId, user_on_project: getUser_id }));
      setActiveProject(true)
      param.callBackMonitoring(true)
      param.allStageMonitoring(true)
      param.reloadSlectProject(projectSuperDetailId)
      param.returnfuc(projectSuperDetailId)
    }
  }, []);

  const [displayKickOff, setDisplayKickOff] = useState(false)
  const toggle_modal_displayKickOff = () => setDisplayKickOff(!displayKickOff)

  const [ActiveProject, setActiveProject] = useState(false)

  const Cardsetcolor = styled.div`
      width: 320px;
      height: 165px;
      background:rgba(0, 0, 0);
      border-radius: 13px;
      border: 0.6em solid ${param.cardcolor};
      border-width: 3.5px;
      padding-left: 6%;
      padding-right: 6%;
      padding-top: 3%;
      font-weight: 600;
      
   `;


  //  console.log('ACT >>', ActiveProject)
  // console.log("paramcardproject",param);

   const iconStyle = {
    color: 'rgb(0, 71, 255)',    // กำหนดสี
    fontSize: '14px',  // กำหนดขนาด
/*     textShadow:'-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff', */
    filter:'drop-shadow(0px 0px 1.5px #fff)',
  
 
    
    // เพิ่ม style อื่น ๆ ตามต้องการ
  };



  return (
    <>

      <Cardsetcolor className=" col-12">
       
       <div className="menuKickOff" style={{ display: `${displayKickOff ? '' : 'none'}`}} onClick={() => {
          let getUser_id = sessionStorage.getItem('user_id');
          localStorage.setItem("projectAct", JSON.stringify({ project_id: param.id, user_on_project: getUser_id }));
          window.location.href = `/KickOffProject?id=${param.id}`
        }}>KICK OFF !

        </div>

        <div className="menuKickOff" style={{ display: `${displayKickOff ? '' : 'none'}`,top:'26%'}} onClick={() => {
      /*     let getUser_id = sessionStorage.getItem('user_id'); */
         /*  localStorage.setItem("projectAct", JSON.stringify({ project_id: param.id, user_on_project: getUser_id })); */
          window.location.href = `/ProjectTimeline?id=${param.id}`
        }}>PROJECT TIMELINE

        </div>
        
     
       {(param.spaceship != null && param.pros != '7acfb2d4-eb27-406d-a0ff-f4dff01c7460') ? <div style={{ position: 'absolute', right: '7%', top: '9%', width: '7%', cursor: 'pointer'}} onClick={() => { toggle_modal_displayKickOff() }}> 

          <img style={{ width: '100%',filter:'drop-shadow(0px 0px 1px #fff)' }} src={moreicon} /></div>
      : <></>} 
        <div className='fontOxygen' style={{ fontSize: '20px', overflowX: 'auto', overflowY: 'hidden', width: '90%', height: '36px', whiteSpace: 'nowrap',paddingBottom:'-4px' }}>{param.nameproject}</div>
        <div className="cardprojectdescription " style={{marginTop:'-5px'}}>
          <div className=' fontOxygen' style={{ fontSize: '12px', color: '#C3CBD2',  }}>{param.description}</div>
        </div>
        <div className='fontOxygen' style={{ fontSize: '13px',marginTop:'2px' }}>Period      <div style={{ position: 'absolute', left: '67px', top: '79.5px', width: '5%' }}> 
        
       {/*  <img style={{ width: '100%' }} src={icondate} /> */}
       <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />
        </div>

          <div style={{ color: '#0047FF', marginTop: '-19px', marginLeft: '71px'  /* filter: `drop-shadow(0px 0px 3px #fff` */}}>
           {/*  <p style={{ }}> */}
              <b style={{textShadow:'-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff',filter:'drop-shadow(0px 0px 1px #fff)'}}> {param.startdate ? moment(param.startdate).format('MMM  DD, YYYY') : 'No date'} - {param.enddate ? moment(param.enddate).format('MMM  DD, YYYY') : 'No date'} </b>
          {/*   </p> */}
          </div></div>

        <div className='iconlist' style={{ position: 'absolute', left: '6%', top: '65.5%', zIndex: '0'}}
          onClick={(e) => {
            let getUser_id = sessionStorage.getItem('user_id');
            localStorage.setItem("projectAct", JSON.stringify({ project_id: param.id, user_on_project: getUser_id }));
            setActiveProject(true)
            param.callBackMonitoring(true)
            param.allStageMonitoring(true)
            param.reloadSlectProject(param.id)
            param.returnfuc(param.id)
            //ตรงนี้
          }}> <img style={{ width: '100%' }} src={iconlist} /></div>

        <div style={{ position: 'absolute',height:'20px', left: '28%',width:'62%', top: '84%',display:'flex',alignItems:'center', gap: '10px'}}> 
          <div style={{width: '75%', position: 'relative', height: '100%', display:'flex',alignItems:'center'}}>
          <Cardpercent percent={param.percent <= 100 && param.percent !== 'NaN'  && param.percent !== undefined && param.percent !== null && param.percent !== '' ? param.percent : 0} />
          </div>
          <div style={{ width: '25%',display:'flex',alignItems:'center', justifyContent: 'flex-end' }}>{(param.percent <= 100 && param.percent !== 'NaN' && param.percent !== undefined && param.percent !== null && param.percent !== '' ? param.percent : 0).toFixed(2).toString()}%</div> 
          </div>
      </Cardsetcolor>
  {/*     <SelectProject 
           checng={ActiveProject}
           displays = {false}
      /> */}
    </>
    
  );
}

export default Cardproject;
