import React, { useState, useEffect, useRef } from 'react'
import './stageElementStyle.css'
import Configs from "../../../config";
import axios from 'axios';
import docicon from '../../../assets/img/icon/doc icon.png';
import uploadicon from '../../../assets/img/icon/upload.png';
import { Input } from 'antd';
import moment from 'moment';
import { data } from 'jquery';
const { TextArea } = Input;


const UserManual = ({
  isOpen,
  toggle_modal_status,
  project_id,
  process_id,
  menu_id,
  callBackFigmalink,
  displayMode
}) => {



  const [errorEditSrsData, setErrorEditSrsData] = useState(false);
  const toggle_modal_errorEditSrsData = () => { setErrorEditSrsData(!errorEditSrsData) };
  const [Figmalink, setFigmalink] = useState({
    data: { remark: '' }
  })
  const [fileUploadHtml, setFileUploadHtml] = useState({
    file_path: null,
    file_name: null,
    is_save: false
  })

  function savefigmalink() {

    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/process/savefigmalink`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        project_id: project_id,
        process_id: process_id,
        menu_id: menu_id,
        data: Figmalink.data

      },
    })
      .then(async function (response) {
        toggle_modal_status()
        callBackFigmalink()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getfigmalink() {

    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/process/getfigmalink`,
      headers: {
        /*  Authorization: sessionStorage.getItem('access_token'), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: {
        project_id: project_id,
        process_id: process_id,
        menu_id: menu_id,

      },
    })
      .then(async function (response) {
        setFigmalink({ ...Figmalink, data: response.data })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {

    /*  getfigmalink() */
  }, [isOpen]);


  return (
    <>

      <div className='stageElementBoxShadowfigma' style={{ display: `${isOpen ? 'flex' : 'none'}`, zIndex: '10000', width: '27%', height: 'max-content', position: 'absolute', left: '59.5%', top: '7.4%' }}>
        <div className='stageElementBoxfigma'>
          <div className='stageElementBoxInfigma'>

            <div className='stageElementHeader' style={{ fontSize: '18px', marginLeft: '5px', marginBottom: '10px' }}>
              USER MANUAL

            </div>
            <div className='row' style={{ height: '100%', width: '100%', marginLeft: '-5px', display: 'flex', justifyContent: 'space-between' }}>
              HTML FILE
              <div>
                <button className='' disabled={displayMode == true || displayMode == 'true'} style={{
                  padding: '5px', paddingLeft: '15px', paddingRight: '15px', marginTop: '-10px', background: '#039BE5', borderRadius: '10px', color: '#ffffff', marginBottom: '3px', marginTop: '-15px',
                  cursor: `no-drop`

                }} onClick={() => {
                  /*  savefigmalink(); */
                }}> Save </button>
                {/*    {Figmalink.data.id !== undefined ? <>
                  <a href={Figmalink.data.remark} target="_blank">
                    <button className='' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px', marginTop: '-10px', background: '#259B24', borderRadius: '10px', color: '#ffffff', marginBottom: '3px', marginTop: '-15px', cursor: 'pointer' }} onClick={() => { }}> Go To Figma </button>
                  </a>
                </> :
                  <></>} */}
              </div>
              <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1 })`, width: '100%', marginBottom: '8px' }} >
                <input id="upload-btn" hidden type="file"
                  accept="image/*"
                  disabled={displayMode == true || displayMode == 'true'}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      const regex = /([\u0E00-\u0E7F]+)/gmu;
                      const str = e.target.files[0].name;
                      const m = regex.exec(str);

                      setFileUploadHtml({ ...fileUploadHtml, file_path: URL.createObjectURL(e.target.files[0]), file_name: e.target.files[0].name, image_file: e.target.files[0], is_save: true })
                    }


                  }}
                />

                <label for="upload-btn" type="button" className="areaTEst rezieznone" style={{ width: '100%', cursor: `${displayMode == true || displayMode == 'true' ? 'no-drop' : 'pointer'}` }}>
                  <div className="" style={{ width: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '3px' }} disabled={displayMode == true || displayMode == 'true'} >UPLOAD FILE</div>
                </label>
                {/*  <TextArea
                  className="areaTEst rezieznone"
                  style={{ borderRadius: '0px' }}
                  autoSize={{
                    minRows: 1
                  }}
                  disabled={displayMode == true || displayMode == 'true'}
                  type='text'
                  value={Figmalink.data.remark}
                  onChange={(e) => {
                    let data = Figmalink.data
                    if (data.id !== undefined) {
                      delete data.id
                    }
                    data.remark = e.target.value
                    setFigmalink({ ...Figmalink, data: data })
                  }}
                /> */}

              </div>
            </div>
            {fileUploadHtml.file_name !== null && fileUploadHtml.file_name !== '' ? <>
              <div className='row' style={{ height: '100%', width: '100%', marginLeft: '-5px', display: 'flex', justifyContent: 'space-between', textDecoration: 'underline', cursor: 'pointer' }}>
                {fileUploadHtml.file_name}

              </div>
            </> : <></>}
            <div className='mt-3 row' style={{ height: '100%', width: '100%', marginLeft: '-5px', display: 'flex', justifyContent: 'space-between' }}>
              URL LINK
              <div>
                {/*  <button className='' disabled={displayMode == true || displayMode == 'true'} style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px', marginTop: '-10px', background: '#039BE5', borderRadius: '10px', color: '#ffffff', marginBottom: '3px', marginTop: '-15px', cursor: `${displayMode == false || displayMode == 'false' ? 'pointer' : 'no-drop'}` }} onClick={() => { savefigmalink(); }}> Save </button> */}
                {/*    {Figmalink.data.id !== undefined ? <>
                  <a href={Figmalink.data.remark} target="_blank">
                    <button className='' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px', marginTop: '-10px', background: '#259B24', borderRadius: '10px', color: '#ffffff', marginBottom: '3px', marginTop: '-15px', cursor: 'pointer' }} onClick={() => { }}> Go To Figma </button>
                  </a>
                </> :
                  <></>} */}
              </div>
              <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1 })`, width: '100%', marginBottom: '10px' }} >

                <TextArea
                  className="areaTEst rezieznone"
                  style={{ borderRadius: '0px' }}
                  autoSize={{
                    minRows: 1
                  }}
                  disabled={displayMode == true || displayMode == 'true'}
                  type='text'
                  /* placeholder='Menu Description...' */
                  value={Figmalink.data.remark}
                  onChange={(e) => {
                    let data = Figmalink.data
                    if (data.id !== undefined) {
                      delete data.id
                    }
                    data.remark = e.target.value
                    setFigmalink({ ...Figmalink, data: data })
                  }}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  <div className='' style={{ display: `${isOpen ? 'flex' : 'none'}`, zIndex: '10000',width:'27%',minHeight:'15%',background:'red' ,position:'absolute' ,left:'63%',top:'8%'  }}>
          <div className='stageElementBgClose' onClick={() => {

            toggle_modal_status();
          }}></div>
        
        </div> */}

    </>
  )
}

export default UserManual
