import React, { useState, useEffect } from 'react';
import "./cardhighlightStyle.css";
import moment from "moment";
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import { Link } from 'react-router-dom';
function Cardhighlight({ taghl, nameevent, dateevent, bghl, newsID ,test}) {
  let imgDeful = "../../../../assets/img/bg/imgbdsport.jpg"
  let tag = {
    nametag: 'none',
    color: '',
    width: '',

  }
  if (taghl === 'p') {
    tag.nametag = 'PROMOTION'
    tag.color = '#FDC700'

  } else if (taghl === 'n') {
    tag.nametag = 'NEWS'
    tag.color = '#FF2B2B'

  } else if (taghl === 's') {
    tag.nametag = 'TTT SPACESHIP STORY'
    tag.color = '#0047FF'

  } else {
    tag.nametag = 'COMING SOON'
    tag.color = '#FF2B2B'
  }
  /* console.log(bghl); */

  function newWeb() {
    window.location.href = "/News"
  }


  useEffect(()=>{
      console.log('bghlasdasd',bghl);
  },[test])
  return (
    <>
      <div className="cardbackgroundhl"  >
        <div className="row" style={{ height: '100%' }}>
          <div className="col-6 cardbghl" onClick={() => {
            sessionStorage.setItem('News_id', newsID)
          }} >
            <Link to={"/viewNews"}>
              <div className="col-12  cardmain" style={{ backgroundImage: `url(${bghl !== undefined && bghl !== '' ? bghl : imgDeful})`, height: '100%', width: '100%' }}>
              </div>
            </Link>
          </div>
          <div className="col-6" >
            <div className="mb-1 mt-1" >
              <h style={{ border: `3px solid ${tag.color}`, color: `${tag.color}`, background: 'none', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '3px', fontWeight: '700', fontSize: '10px' }}>
                {tag.nametag}</h>
            </div>
            <div className="mb-1">
              <font style={{ fontSize: '30px', color: '#fffff', fontWeight: '600' }}>{nameevent !== undefined && nameevent !== '' ? nameevent : 'COMING SOON'}</font>
            </div>
            <div className="box-content mb-1" style={{maxHeight:'70%',overflowY:'auto',overflowX:'hidden',wordWrap:'break-word'}}>
              <font style={{ fontSize: '16px', color: '#fffff' }}>{dateevent !== undefined && dateevent !== '' ? dateevent : 'Please stay tuned for new news soon.'}</font>
            </div>
            <div >
            <button className='button-cardhl' type="button"
                style={{cursor:'pointer'}}
                onClick={() => { newWeb(); }}
              >

               <font style={{color:'#fff'}}>  READ MORE  </font> 


              </button>
            </div>
      
      
       

          </div>
        </div>
      </div>
    </>
  );
}

export default Cardhighlight;
