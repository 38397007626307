import React from 'react'
import "../../assets/css/shopdetail.css";
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";

const Shopdetail = () => {
  return (
    <>
    <HeaderTTT />
    <img className='bg-shopdetail' src="../assets/img/bg/game_manage_bg.jpg" alt="" />
    <section className="container con-detail">
        <div className="container bg-detail">
        <div className="banner-content">
            <h3 class="text-white">Shop</h3>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="chair-discount-img">
                <img src="assets/img/images/chair_discount_img.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="chair-discount-content">
                <div className="shop-title mb-25">
                  <h2 className="title">Never <br /> miss 35% off</h2>
                </div>
                <p>Lorem ipsum dolor sit amet thats consectetur incididunt labore dolore.</p>
                <a href="/#" className="btn button">buy now</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      </>
  )
  
}

export default Shopdetail