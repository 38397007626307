import React, { useEffect, useState, useRef } from 'react';
import HeaderTTT from "../../../components/header/HeaderTTT";
import HeaderTTTMobile from '../../../components/header/HeaderTTTMobile';
import './howToPlay.css';
import howToPlayBg from './image/howToPlayBg.png';
import Select from 'react-select'
import axios from "axios";
import Configs from "../../../config";
import HowToPlayStep1 from './component/howToPlayStep1';
import HowToPlayStep2 from './component/howToPlayStep2';
import HowToPlayStep3 from './component/howToPlayStep3';
import HowToPlayStep4 from './component/howToPlayStep4';
import HowToPlayStep5 from './component/howToPlayStep5';
import HowToPlayStep6 from './component/howToPlayStep6';
import HowToPlayStep7 from './component/howToPlayStep7';
import HowToPlayStep8 from './component/howToPlayStep8';
import HowToPlayStep9 from './component/howToPlayStep9';
import HowToPlayStep10 from './component/howToPlayStep10';

function HowToPlay() {

    const [stepData, setStepData] = useState([]);
    const [stepDataDropDown, setStepDataDropDown] = useState([]);

    const [onStepData, setOnStepData] = useState({
        value: 1,
        label: 'Register'
    })

    async function getStepData() {
        await axios({
            method: 'GET',
            url: `${Configs.TTT_Game_Service}/api/howToPlay/getStepData`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: {}
        })
            .then(async function (response) {
                if (response.data) {
                    let data = [];
                    let index = 1;
                    for (let item of response.data) {
                        let temp = {};
                        temp.value = index;
                        temp.label = item.mtr_name;
                        index++
                        data.push(temp);
                    };
                    setStepData(data);

                    let dataDropDown = [];
                    let indexDropDown = 1;
                    for (let item of response.data) {
                        let temp = {};
                        temp.value = indexDropDown;
                        temp.label = 'STEP ' + indexDropDown + ' : ' + item.mtr_name;
                        temp.labelSet = item.mtr_name;
                        indexDropDown++
                        dataDropDown.push(temp);
                    };
                    setStepDataDropDown(dataDropDown);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getStepData();
    }, []);

    const [modeMobile, setModeMobile] = useState(false);

    useEffect(() => {

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        }
        else {
            setModeMobile(false);
        }
    })

    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

    return (
        <>
            {modeMobile ? (<>
                <div style={{ overflow: 'hidden' }}>
                    <HeaderTTTMobile
                        mobileMenu={mobileMenu}
                        toggle_mobileMenu={toggle_mobileMenu}
                        mobileLogin={mobileLogin}
                        toggle_mobileLogin={toggle_mobileLogin}
                        mobileRegister={mobileRegister}
                        toggle_mobileRegister={toggle_mobileRegister}
                    />
                    <div className='howToPlayBgMobile' style={{backgroundImage:`url(${howToPlayBg})`}}>
                        <div className="howToPlayBg" style={{display:'block', padding: '60px 0'}}>
                            <svg height="700" width="1500" style={{ position: 'absolute' }}>
                                <line x1="0" y1="35" x2="36.5" y2="0" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div className="howToPlayBox" style={{clipPath: 'none'}}>
                                <HowToPlayStep1
                                    isOpen={onStepData.value === 1}
                                />
                                <HowToPlayStep2
                                    isOpen={onStepData.value === 2}
                                />
                                <HowToPlayStep3
                                    isOpen={onStepData.value === 3}
                                />
                                <HowToPlayStep4
                                    isOpen={onStepData.value === 4}
                                />
                                <HowToPlayStep5
                                    isOpen={onStepData.value === 5}
                                />
                                <HowToPlayStep6
                                    isOpen={onStepData.value === 6}
                                />
                                <HowToPlayStep7
                                    isOpen={onStepData.value === 7}
                                />
                                <HowToPlayStep8
                                    isOpen={onStepData.value === 8}
                                />
                                <HowToPlayStep9
                                    isOpen={onStepData.value === 9}
                                />
                                <HowToPlayStep10
                                    isOpen={onStepData.value === 10}
                                />
                                <div className="howToPlayStepBox">
                                    <svg height="65" width="300" style={{ position: 'relative' }}>
                                        <line x1="1" y1="80%" x2="1" y2="0" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                        <line x1="10" y1="80%" x2="10" y2="0" style={{ stroke: '#0047FF', strokeWidth: '8' }} />
                                        <line x1="10" y1="4" x2="80" y2="4" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(0 0, 90% 0%, 100% 100%, 0% 100%)' }} />
                                        <line x1="75" y1="6.5" x2="180" y2="6.5" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                        <line x1="137.5" y1="6.5" x2="142.5" y2="1" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                        <line x1="140" y1="4" x2="180" y2="4" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(20% 0, 100% 0, 80% 100%, 0% 100%)' }} />
                                        <line x1="179" y1="6.5" x2="184.5" y2="1" style={{ stroke: '#0047FF', strokeWidth: '3' }} />

                                        <line x1="10" y1="74%" x2="90" y2="74%" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(0 0, 100% 0%, 90% 100%, 0% 100%)' }} />
                                        <line x1="80" y1="70%" x2="125" y2="70%" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                        <line x1="124" y1="70%" x2="140" y2="90%" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                    </svg>
                                    <div style={{ display: 'flex' }}>
                                        <div className="howToPlayStepBoxHeader">STEP {onStepData.value} : <span className='howToPlayStepBoxHeaderName'>{onStepData.label}</span></div>
                                        <Select
                                            placeholder='Select Step'
                                            isSearchable={false}

                                            styles={{
                                                container: (baseStyles) => ({ //ปรับรวม
                                                    ...baseStyles,
                                                    margin: '-62px 25px',
                                                }),
                                                control: (baseStyles, state) => ({ //ปรับช่องหลัก
                                                    ...baseStyles,
                                                    width: '275px',
                                                    borderColor: "#0047FF",
                                                    color: 'white',
                                                    "&:hover": {
                                                        borderColor: "#0047FF",
                                                        color: '#0047FF'
                                                    },
                                                    fontSize: '16px',
                                                }),
                                                menu: (baseStyles) => ({ //ปรับช่องเมนู
                                                    ...baseStyles,
                                                    margin: '-124px 0',
                                                    border: '1px solid #0047FF',
                                                    background: 'rgba(0,24,85,0.5)'
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ //ปรับช่องในเมนู
                                                    ...styles,
                                                    color: isSelected ? 'white' : 'white',
                                                    backgroundColor: isSelected ? 'rgba(0,71,255,0.8)' : '',
                                                    "&:hover": {
                                                        backgroundColor: isSelected ? "rgba(0,71,255,0.8)" : "rgba(0,71,255,0.5)",
                                                    },
                                                }),
                                            }}

                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    // ...theme.colors,
                                                    // primary: '#0047FF',
                                                },
                                            })}

                                            options={stepDataDropDown}
                                            value={stepDataDropDown.filter((e) => { return e.value === onStepData.value })}
                                            onChange={(e) => {
                                                setOnStepData({
                                                    ...onStepData,
                                                    value: e.value,
                                                    label: e.labelSet
                                                });
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="howToPlayStepGoBox">
                                    <div className="howToPlayStepGoNext" style={{ visibility: `${onStepData.value == 1 ? 'hidden' : 'visible'}` }} onClick={() => {
                                        if (onStepData.value > 1) {
                                            setOnStepData({ ...onStepData, value: onStepData.value - 1, label: stepData[onStepData.value - 2].label });
                                        }
                                    }}><span style={{ fontSize: '36px', fontWeight: '400', color: '#0047FF' }}>{'<'}</span>PREV</div>
                                    <div className='howToPlayStepGoDotBox'>
                                        {stepData.map((e, index) => {
                                            return (
                                                <div className="howToPlayStepGoDot" style={{ backgroundColor: `${onStepData.value == index + 1 ? '#0047FF' : 'WHITE'}` }} onClick={() => {
                                                    setOnStepData({ ...onStepData, value: index + 1, label: e.label })
                                                }}></div>
                                            )
                                        })}
                                    </div>
                                    <div className="howToPlayStepGoNext" style={{ visibility: `${onStepData.value == stepData.length ? 'hidden' : 'visible'}` }} onClick={() => {
                                        if (onStepData.value < stepData.length) {
                                            setOnStepData({ ...onStepData, value: onStepData.value + 1, label: stepData[onStepData.value].label })
                                        }
                                    }}>NEXT<span style={{ fontSize: '36px', fontWeight: '400', color: '#0047FF' }}>{'>'}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : (<>
                <HeaderTTT />
                <img className="guide-bg" src={howToPlayBg}></img>

                <div className="howToPlayBg">
                    <svg height="700" width="1500" style={{ position: 'absolute' }}>
                        <line x1="0" y1="35" x2="36.5" y2="0" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                    </svg>
                    <div className="howToPlayBox">
                        <HowToPlayStep1
                            isOpen={onStepData.value === 1}
                        />
                        <HowToPlayStep2
                            isOpen={onStepData.value === 2}
                        />
                        <HowToPlayStep3
                            isOpen={onStepData.value === 3}
                        />
                        <HowToPlayStep4
                            isOpen={onStepData.value === 4}
                        />
                        <HowToPlayStep5
                            isOpen={onStepData.value === 5}
                        />
                        <HowToPlayStep6
                            isOpen={onStepData.value === 6}
                        />
                        <HowToPlayStep7
                            isOpen={onStepData.value === 7}
                        />
                        <HowToPlayStep8
                            isOpen={onStepData.value === 8}
                        />
                        <HowToPlayStep9
                            isOpen={onStepData.value === 9}
                        />
                        <HowToPlayStep10
                            isOpen={onStepData.value === 10}
                        />
                        <div className="howToPlayStepBox">
                            <svg height="65" width="300" style={{ position: 'relative' }}>
                                <line x1="1" y1="80%" x2="1" y2="0" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                <line x1="10" y1="80%" x2="10" y2="0" style={{ stroke: '#0047FF', strokeWidth: '8' }} />
                                <line x1="10" y1="4" x2="80" y2="4" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(0 0, 90% 0%, 100% 100%, 0% 100%)' }} />
                                <line x1="75" y1="6.5" x2="180" y2="6.5" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                <line x1="137.5" y1="6.5" x2="142.5" y2="1" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                <line x1="140" y1="4" x2="180" y2="4" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(20% 0, 100% 0, 80% 100%, 0% 100%)' }} />
                                <line x1="179" y1="6.5" x2="184.5" y2="1" style={{ stroke: '#0047FF', strokeWidth: '3' }} />

                                <line x1="10" y1="74%" x2="90" y2="74%" style={{ stroke: '#0047FF', strokeWidth: '8', clipPath: 'polygon(0 0, 100% 0%, 90% 100%, 0% 100%)' }} />
                                <line x1="80" y1="70%" x2="125" y2="70%" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                                <line x1="124" y1="70%" x2="140" y2="90%" style={{ stroke: '#0047FF', strokeWidth: '3' }} />
                            </svg>
                            <div style={{ display: 'flex' }}>
                                <div className="howToPlayStepBoxHeader">STEP {onStepData.value} : <span className='howToPlayStepBoxHeaderName'>{onStepData.label}</span></div>
                                <Select
                                    placeholder='Select Step'
                                    isSearchable={false}

                                    styles={{
                                        container: (baseStyles) => ({ //ปรับรวม
                                            ...baseStyles,
                                            margin: '-62px 25px',
                                        }),
                                        control: (baseStyles, state) => ({ //ปรับช่องหลัก
                                            ...baseStyles,
                                            width: '275px',
                                            borderColor: "#0047FF",
                                            color: 'white',
                                            "&:hover": {
                                                borderColor: "#0047FF",
                                                color: '#0047FF'
                                            },
                                            fontSize: '16px',
                                        }),
                                        menu: (baseStyles) => ({ //ปรับช่องเมนู
                                            ...baseStyles,
                                            margin: '-124px 0',
                                            border: '1px solid #0047FF',
                                            background: 'rgba(0,24,85,0.5)'
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ //ปรับช่องในเมนู
                                            ...styles,
                                            color: isSelected ? 'white' : 'white',
                                            backgroundColor: isSelected ? 'rgba(0,71,255,0.8)' : '',
                                            "&:hover": {
                                                backgroundColor: isSelected ? "rgba(0,71,255,0.8)" : "rgba(0,71,255,0.5)",
                                            },
                                        }),
                                    }}

                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            // ...theme.colors,
                                            // primary: '#0047FF',
                                        },
                                    })}

                                    options={stepDataDropDown}
                                    value={stepDataDropDown.filter((e) => { return e.value === onStepData.value })}
                                    onChange={(e) => {
                                        setOnStepData({
                                            ...onStepData,
                                            value: e.value,
                                            label: e.labelSet
                                        });
                                    }}
                                />

                            </div>
                        </div>
                        <div className="howToPlayStepGoBox">
                            <div className="howToPlayStepGoNext" style={{ visibility: `${onStepData.value == 1 ? 'hidden' : 'visible'}` }} onClick={() => {
                                if (onStepData.value > 1) {
                                    setOnStepData({ ...onStepData, value: onStepData.value - 1, label: stepData[onStepData.value - 2].label });
                                }
                            }}><span style={{ fontSize: '36px', fontWeight: '400', color: '#0047FF' }}>{'<'}</span>PREV</div>
                            <div className='howToPlayStepGoDotBox'>
                                {stepData.map((e, index) => {
                                    return (
                                        <div className="howToPlayStepGoDot" style={{ backgroundColor: `${onStepData.value == index + 1 ? '#0047FF' : 'WHITE'}` }} onClick={() => {
                                            setOnStepData({ ...onStepData, value: index + 1, label: e.label })
                                        }}></div>
                                    )
                                })}
                            </div>
                            <div className="howToPlayStepGoNext" style={{ visibility: `${onStepData.value == stepData.length ? 'hidden' : 'visible'}` }} onClick={() => {
                                if (onStepData.value < stepData.length) {
                                    setOnStepData({ ...onStepData, value: onStepData.value + 1, label: stepData[onStepData.value].label })
                                }
                            }}>NEXT<span style={{ fontSize: '36px', fontWeight: '400', color: '#0047FF' }}>{'>'}</span></div>
                        </div>
                    </div>
                </div>
            </>)
            }
        </>
    );
}

export default HowToPlay;
