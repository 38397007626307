import React, { useEffect, useState, useRef } from 'react';
import "./reportSend.css"
import axios from "axios";
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
import ModelViewDetail from "./ViewLogReportMail";

import moment from "moment";
import Configs from "../../../../config";


function ViewListReportMail({ isOpen, togle_modal_send_mailFun, stageMonitoringData }) {

    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);

    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);


    const [sendMail, setsendMail] = useState(false);
    const toggle_modal_sendMail = () => setsendMail(!sendMail);

    const [viewDetail, setviewDetail] = useState(false);
    const toggle_modal_viewDetail = () => setviewDetail(!viewDetail);

    const [dateViewReport,setDateViewReport] = useState({
        lsent_date:'',
        lsent_detail:'',
        lsent_created_by_name:'',

    })

    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })

    const [viewListData, setViewListData] = useState([])

    const [valueSave, setValueSave] = useState({
        lsent_date: new Date(),
        lsent_detail: '',
        lsent_project_id: '',
        lsent_spaceship_id: '',
        menulist: []


    })

    useEffect(() => {
        if (isOpen === true) {
            getMailView(stageMonitoringData.project_id)
            setValueSave({
                ...valueSave,
                lsent_detail: '',
                lsent_date: new Date(),
                lsent_project_id: stageMonitoringData.project_id,
                lsent_spaceship_id: stageMonitoringData.project_spaceship_id || null,
                menulist: stageMonitoringData.menulist || []
            })
            toggle_modal_sendMail()
        }
    }, [isOpen])



    function getMailView(project_id) {



        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/mail/getMailReport/${project_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(function (response) {
                if (response.data) {
                     setViewListData(response.data);
                }


            })
            .catch(function (error) {
                console.log(error);


            });




    }



/* 
    function CloseFunc() {
        toggle_modal_sendMail()
        togle_modal_send_mailFun()
    }
 */

    return (
        <>
            <div className='ModalSendMailReport' style={{ display: `${sendMail ? '' : 'none'}` }}>
                <div className='box-send-mail'>
                    <div className='box-headreport'>
                        REPORT HISTORY
                    </div>
                    <div className='boxbody-report' style={{ padding: '0px', overflow: 'auto' }}>
                        <div className="table-responsive" style={{ padding: '0px', margin: '0px', height: '10%' }}>
                            <table style={{ width: '100%' }}>
                                <tr style={{ borderBottom: '1px solid #FFF', height: '40px', textAlign: 'center' }}>
                                    <th style={{ fontSize: '18px', width: '10%',  }}>NO.</th>
                                    <th style={{ fontSize: '18px', width: '30%',  }} >DATE</th>
                                    <th style={{ fontSize: '18px', width: '40%',  }}>REPORT</th>
                                    <th style={{ fontSize: '18px', width: '20%',  }}>VIEW REPORT</th>
                                </tr>
                            </table>
                        </div>
                        <div className="table-responsive" style={{ padding: '0px', margin: '0px', height: '90%' }}>
                            <table style={{ width: '100%' }}>
                                {/*     <tr style={{ borderBottom: '1px solid #FFF', height: '40px', textAlign: 'center' }}>
                                    <th style={{ fontSize: '18px', width: '40px' }}>NO.</th>
                                    <th style={{ fontSize: '18px', width: '100px' }} >DATE</th>
                                    <th style={{ fontSize: '18px', width: '130px' }}>REPORT</th>
                                    <th style={{ fontSize: '18px', width: '70px' }}>VIEW REPORT</th>
                                </tr> */}
                                {viewListData.map((e, index) => {
                                    return (
                                        <tr style={{ height: '40px', textAlign: 'center' }}>
                                            <td style={{ fontSize: '18px', width: '10%', textAlign: 'center', /* border: '1px solid red' */ }}> {index + 1}</td>
                                            <td style={{ fontSize: '18px', width: '30%', textAlign: 'center', /* border: '1px solid red' */ }}> {moment(e.lsent_created_date).format('DD-MM-YYYY')}</td>
                                            <td style={{
                                                fontSize: '18px', width: '40%', textAlign: 'center', whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}> {e.lsent_created_by_name}</td>
                                            <td style={{ fontSize: '18px', width: '20%', textAlign: 'center' }}><i class="fa fa-eye" style={{ cursor: 'pointer' }} aria-hidden="true" 
                                            onClick={()=>{
                                                setDateViewReport({
                                                    ...dateViewReport,
                                                    lsent_date: e.lsent_created_date ,
                                                    lsent_detail: e.lsent_detail,
                                                    lsent_created_by_name: e.lsent_created_by_name,
                                                })
                                                toggle_modal_viewDetail()
                                            }}
                                            
                                            > </i> </td>
                                        </tr>
                                    )
                                })}


                            </table>
                        </div>
                    </div>
                    <div className='row are-btn-box'>
                        <div className='btn-cancen' onClick={() => {
                            setValueSave({
                                ...valueSave,
                                lsent_date: new Date(),
                                lsent_detail: ''
                            })
                            toggle_modal_sendMail()
                            togle_modal_send_mailFun()
                        }}>
                            Cancel
                        </div>
                    </div>

                </div>
            </div>

            {/*       <ModelAlertConfrim
                isOpen={confrimModal}
                mainFuc={() => SaveSendMailReport()}
                toggle_confrim={() => toggle_modal_confrimModal()}
            />

            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => CloseFunc()}
            /> */}


            <ModelViewDetail 
                 isOpen={viewDetail}
                 stageMonitoringData={dateViewReport}
                 togle_modal_send_mailFun={() => { toggle_modal_viewDetail() }}
            />


        </>
    );
}

export default ViewListReportMail;
