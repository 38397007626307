import React, { useState ,useEffect} from 'react';
import '../../assets/css/newsModal.css'
import '../../assets/css/info.css';
import img2 from '../../assets/img/images/pic2_1.jpg'
import axios from "axios";
import Calendar_pomotion from '../../assets/img/images/newsCalendar.png'
import calendar_story from '../../assets/img/images/calendar_story.png'
import calendar_new from '../../assets/img/images/calendar_new.png'
import link_icon from '../../assets/img/images/link_icon.png'
import TTTStoryCustompic from '../../assets/img/images/TTTStoryCustompic.jpg'
import Infodetail from '../../view/information/detailInfo'
import { Link } from 'react-router-dom';
import  storyDetail from '../../view/information/detailInfo'
import Configs from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';


function Modal({ isOpen, onClose, headType, titleName, datas, dateValue, link, language, imgpath,newID }) {
  /*   console.log('isOpen', headType); */
  const [test,settest] = useState({test:''})
  const [profileIMG,setprofileIMG] = useState(img2)
    let type_news = headType;
    let title = titleName;
    let date = datas;
    let text = dateValue;
    let class_title_type = 'square-story';
    let class_headText = 'headerText-story';
    let IMG_calender = calendar_story;
    let IMG_color = '0, 71, 255';
    let main_img = img2;
    let is_link = false;
    let us = 'box-content'
    if (type_news === 'PROMOTION') {
        class_title_type = 'square-promotion';
        class_headText = 'headerText';
        IMG_calender = Calendar_pomotion;
        main_img = img2;
        IMG_color = '253, 199, 0'
    } else if (type_news === 'ACTIVITY & NEWS') {
        class_title_type = 'square-active';
        class_headText = 'headerText-active';
        IMG_calender = calendar_new;
        main_img = img2;
        IMG_color = '255, 43, 43'
    }

    if (imgpath !== undefined && imgpath !== null && imgpath !== '') {
        main_img = `${Configs.TTT_Game_Service_IMG}${imgpath}`;
    }

    if (link !== '' && link !== null) {
        is_link = true
    }

    if (language === 'thai') {
        us = 'box-content-thai'
    }



    const iconStyle = {
        color: `rgb(${IMG_color})` /* 'rgb(0, 71, 255)' */,    // กำหนดสี
        fontSize: '18px',  // กำหนดขนาด
    /*     textShadow:'-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff', */
        filter:'drop-shadow(0px 0px 1.5px #fff)',
        marginTop:'2px',
        
        // เพิ่ม style อื่น ๆ ตามต้องการ
      };
      
    async function getNewsINfo(value) {
        // console.log('news_id: ',newID);
        await axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/news/getNewsByID/${newID}`,
            headers: {
               /*  Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
              if(response.data){
                let commentLines2 = [] 
                if(response.data.data.news_description !== undefined && response.data.data.news_description !== null && response.data.data.news_description !== ''){
                  commentLines2 = response.data.data.news_description.split('\n');
                }

                title = response.data.data.news_title
                if(commentLines2.length > 0){
                    text = commentLines2
                }
            

        
                // if (response.data.fileIMG.length !== undefined && response.data.fileIMG.length > 0) {
                //             console.log('รูxในโมดอล0',`${Configs.TTT_Game_Service_IMG}${response.data.fileIMG[0].ul_file_path}`);
                //     let imgs = `${Configs.TTT_Game_Service_IMG}${response.data.fileIMG[0].ul_file_path}`;
                //     setprofileIMG(imgs)
                //     main_img = `${Configs.TTT_Game_Service_IMG}${response.data.fileIMG[0].ul_file_path}`;
                // }else{
                //     setprofileIMG(img2) 
                // }
            
      

                
       
                settest({...test,test:''})
                test.test = '';
              }
    
            })
            .catch(function (error) {
                console.log(error);
            });
    }
/*     useEffect(() => {
        getNewsINfo(newID)
      }, [newID])
 */
      /*   console.log('ดูรูปหน่อย',main_img); */
    return (
        isOpen && (
            <div className={`modalNews ${isOpen ? 'opened' : ''}`}   onClick={() => { onClose(); }}>
                <div className='mainModal_1'>
                    <div className="modal-contentNews">
                        <span className="closeNews" onClick={onClose}>
                            &times;
                        </span>
                        <div className='row newrow' style={{ padding: '0px',margin:'0px' }}>
                            <div className=' imgDisplay' >
                                <div className="popup-inner popup-inner-photo">
                                    <div className='cardmain1' style={{ backgroundImage: `url(${main_img})` }}> </div>
                                    {/*   <img  src={main_img} alt="" /> */}
                                </div>
                            </div>
                            <div className='displaydetail'>
                                <div className='row'>
                                    <div className={`${class_title_type}`} >
                                        <div className={`${class_headText}`} >
                                            {type_news}
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                  
                                    <t1>{title}</t1> 
                              
                                  

                             
                                </div>
                                <div className='row mt-3' >
                                    <t2>
                                        {/* <img style={{ marginTop: '-3px' }} src={`${IMG_calender}`} />  */}
                                        <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />
                                        {' '}  {date} </t2>   <span style={{fontSize:'12px',padding:'3px 0px 0px 10px',cursor:'pointer'}}> 
                                    <Link to={`/viewNews`} onClick={()=>{
                                         sessionStorage.setItem('News_id', newID)
                                         document.querySelector('html').classList.remove('modal-opened');
                                     }}><b style={{textShadow:'-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff',filter:'drop-shadow(0px 0px 1px #fff)'}}>(more..) </b>  </Link> </span>    
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-12'>
                                        <div className={`${us}`}>
                                            {text}

                                        </div>
                                    </div>

                                </div>
                                {/*   <div className='row mt-2 '>
                                <t3>
                                    {'URL : https://tttbrother.com'}
                                </t3>
                            </div> */}


                                <div className='mt-4 footer'>
                                    {is_link === true ? (<>
                                        <div className='row mt-2 cutname'>
                                            <t3 >
                                                <a target='_blank' href={link}  >{`URL : ${link}`} </a>
                                            </t3>
                          
                                        </div>
                                       
                                    </>) : (<></>)}

                                    <div className='row mt-2 footercarddetail' >
                                        <div className='col-7' style={{ padding: '0px' }} >
                                            {is_link === true ? (<>
                                                <a target='_blank' href={link}>
                                                    <button className='button-58' type="button"

                                                    /*     onClick={() => { newWeb(); }} */
                                                    >
                                                        <img src={link_icon} /> &nbsp; GO TO WEBSITE
                                                    </button>
                                                </a>
                                            </>) : (<></>)}

                                        </div>
                                        {/*  <div className='col-1'>
                                    
                                </div> */}
                                        <div className='col-5' style={{ padding: '0px', marginLeft: 'inherit' }} >
                                            <button className='button-59' type="button"
                                                onClick={() => { onClose(); }}
                                            >
                                                &nbsp; CLOSE
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </div>
                </div></div>
        )
    );
}

export default Modal;