import React, { useEffect, useState, useRef } from 'react'
import '../packagestyle.css'
import axios from "axios";
import Configs from "../../../config";
import Searchicon from '../../../assets/img/icon/Icon Search.png';

function Selectcat({isopen,callback,pcid}) {
    const [Categorylist, setCategorylist] = useState([]);
    const [Categorylistselect, setCategorylistselect] = useState([]);
    const [Categoryshow, setCategoryshow] = useState([]);
    const [categoryseting, setcategoryseting] = useState({
id:'',
name:'',
    });
    const [test, settest] = useState({
        test: ''
    })
    const [filter, setFilter] = useState({
        keySearch: ''
    });

    useEffect(() => {
      setcategoryseting({...categoryseting,id:'',name:''})
        getCategorytest(pcid)

      }, [pcid && isopen])
      useEffect(() => {
        setcategoryseting({...categoryseting,id:'',name:''})
        getCategorytest(pcid)

      }, [isopen])

    async function getCategorytest(id) {
        
        let temp = {
          keySearch: id || '',
    
        }
        await axios({
          method: 'post',
          url: `${Configs.TTT_Game_Service}/api/category/filterCategoryselect`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
          },
          data: temp
    
    
        })
          .then(function (response) {
          let itemlist = []
          let itemsublist = []
          let checkend = false
          let all = ''
          let onlv = 1
  //  console.log(response.data);
       let cat = response.data.map((e, index) => {
        e.pc_name_all = `${e.pc_name} `
            if (e.sub !== undefined && e.on == true) {
                all += `${e.pc_name} `
                let sub_pc_name_all = `${e.pc_name} `
                let onsub = e.sub
               
                do {
                    let subbebe = []
                    let onsubset = []
                    onlv+=1
             
                /*  onsub.sort((b, a) =>a.on - b.on) */
                  onsub.map((s, index2) => { 
                    s.lv = onlv
           
                if (s.sub !== undefined && s.on == true) {
                    s.pc_name_all = `${sub_pc_name_all} > ${s.pc_name} `
                    sub_pc_name_all = `${sub_pc_name_all} > ${s.pc_name} `
                    all += ` > ${s.pc_name} `
                    onsubset = s.sub
                }
               if (s.end === true ) {
                console.log(s.pc_name_all);
                setcategoryseting({...categoryseting,id:s.pc_id,name:`${sub_pc_name_all} > ${s.pc_name} `})
                categoryseting.id = s.pc_id
                categoryseting.name =`${sub_pc_name_all} > ${s.pc_name} `
                checkend = true
               }
               subbebe.push(<>
                <li style={{width:'100%',minHeight:'50px',border:`1px solid ${s.on == true? '#33ccff' : '#ffffff'}`}}  onClick={(x) => {getdataontable(s);
                setcategoryseting({...categoryseting,id:s.pc_id,name:`${sub_pc_name_all} > ${s.pc_name} `})
                categoryseting.id = s.pc_id
                categoryseting.name =`${sub_pc_name_all} > ${s.pc_name} `}}> {s.pc_name} </li>
            </>)
               if (index2 === onsub.length -1) {
               itemsublist.push(<>
                <div  style={{width:'280px',height:'465px',overflowY: "auto"}}>
                {subbebe}
                </div>
                </>)
               }
                })
                
            
                onsub = onsubset
                
            } while (checkend === false)

            }

            if (e.end === true ) {
              setcategoryseting({...categoryseting,id:e.pc_id,name: `${e.pc_name} `})
              categoryseting.id = e.pc_id
              categoryseting.name = `${e.pc_name} `
              checkend = true
             }
            e.lv = 1
            itemlist.push(<>
                <li style={{width:'100%',minHeight:'50px',border:`1px solid ${e.on == true? '#33ccff' : '#ffffff'}`}} onClick={(x) => {getdataontable(e);
                setcategoryseting({...categoryseting,id:e.pc_id,name:`${e.pc_name} `})
                categoryseting.id = e.pc_id
                categoryseting.name =`${e.pc_name} `}}>  {e.pc_name} </li>
            </>)
            if (index === Categorylist.length -1) {
                
                return<>
                <div  style={{width:'280px',height:'465px',overflowY: "auto"}}>
                {itemlist}
                </div>
                {itemsublist}
                </>
            }
                })
console.log(cat,id);
setCategoryshow(cat)
                setCategorylist(response.data)
                settest({ ...test, test: '' })
                test.test = '';
                
          })
          .catch(function (error) {
            console.log(error);
    
          });
      }
    
    
      async function getdataontable(cat) {
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/category/getsubcategoryonid`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            },
            data: {
                pc_id: cat.pc_id
            }
      
          })
            .then(function (response) {
    let catmain = Categorylist
      let catsub = response.data
      let lvoncat = catsub.length > 0 ? cat.lv+1 : cat.lv
      let itemlist = []
      let itemsublist = []
      let all = ''
      let onlv = 1
      let newcat = catmain.map((e, index) => {
        e.pc_name_all = `${e.pc_name} `
        onlv =1
        if (onlv === cat.lv) {
            if (cat.pc_id === e.pc_id ) {
                e.on = true
                e.sub = catsub
               } else {
                e.on = false
               }
        }

            if (e.sub !== undefined && e.on == true) {
                all += `${e.pc_name} `
                let sub_pc_name_all = `${e.pc_name} `
                let onsub = e.sub
             
                do {
                    let subbebe = []
                    let onsubset = []
                    onlv+=1
             
                /*  onsub.sort((b, a) =>a.on - b.on) */
                  onsub.map((s, index2) => { 
                    s.lv = onlv

                    if (onlv === cat.lv) {
                        if (cat.pc_id === s.pc_id ) {
                            s.on = true
                            s.sub = catsub
                           } else {
                            s.on = false
                           }
                    }
                if (s.sub !== undefined && s.on == true) {
                    s.pc_name_all = `${sub_pc_name_all} > ${s.pc_name} `
                    sub_pc_name_all = `${sub_pc_name_all} > ${s.pc_name} `
                    all += ` > ${s.pc_name} `
                    onsubset = s.sub
                }
             
               subbebe.push(<>
                <li style={{width:'100%',minHeight:'50px',border:`1px solid ${s.on == true? '#33ccff' : '#ffffff'}`}}  onClick={(x) => {getdataontable(s);
                 setcategoryseting({...categoryseting,id:s.pc_id,name:`${sub_pc_name_all} > ${s.pc_name} `})
                 categoryseting.id = s.pc_id
                 categoryseting.name =`${sub_pc_name_all} > ${s.pc_name} `}}> {s.pc_name} </li>
            </>)
               if (index2 === onsub.length -1) {
               itemsublist.push(<>
                <div  style={{width:'280px',height:'465px',overflowY: "auto"}}>
                {subbebe}
                </div>
                </>)
               }
                })
                
                console.log(onsub,onsubset);
                onsub = onsubset
                
            } while (lvoncat > onlv)

            }
            e.lv = 1
            itemlist.push(<>
                <li style={{width:'100%',minHeight:'50px',border:`1px solid ${e.on == true? '#33ccff' : '#ffffff'}`}} onClick={(x) => {getdataontable(e);
                 setcategoryseting({...categoryseting,id:e.pc_id,name:`${e.pc_name} `})
                 categoryseting.id = e.pc_id
                 categoryseting.name =`${e.pc_name} `}}>  {e.pc_name} </li>
            </>)
            if (index === Categorylist.length -1) {
                
                return<>
                <div  style={{width:'280px',height:'465px',overflowY: "auto"}}>
                {itemlist}
                </div>
                {itemsublist}
                </>
            }
                })
               /*  setcategoryseting({...categoryseting,id:cat.pc_id,name:cat.pc_name_all})
                categoryseting.id = cat.pc_id
                categoryseting.name =cat.pc_name_all */
                setCategoryshow(newcat)
                setCategorylist(catmain)
                settest({ ...test, test: '' })
                test.test = ''
            })
            .catch(function (error) {
              console.log(error);
      
            });


      }


      async function selectcattegory(name) {
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/category/selectcattegory`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            },
      data:{name:name}
      
          })
            .then( function (response) {
      // console.log(response.data);
      let itemlist = []
      let newcat = response.data.map((e, index) => {
        itemlist.push(<>
            <li style={{width:'100%',minHeight:'50px',border:`1px solid ${e.on == true? '#33ccff' : '#ffffff'}`}} onClick={(x) => {getCategorytest(e.pc_id)}}>  {e.pc_name}</li>
        </>)

        if (index === response.data.length -1) {
           
            return<>
            <div  style={{width:'280px',height:'170px',overflowY: "auto"}}>
            {itemlist}
            </div>
          
            </>
        }
      })
      setCategorylistselect(newcat)
      
            })
            .catch(function (error) {
              console.log(error);
      
            });

      }

   
  return (
   <>
    {isopen === true ?
        <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '70%', height: '80%', backgroundColor: '#212529' }}>
          <div className="pi-closePersonalInfo" style={{left:'95%'}} onClick={() => {
             setcategoryseting({...categoryseting,id:'',name:''})
             setCategorylistselect([])
             setCategorylist([])
             callback()
              }}>
                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
              </div>
       
          <div
              className="row "
              style={{ flexWrap: "nowrap", /* overflow:'hidden', */ minWidth: "20%" ,height:'470px',overflowX:'auto',overflowY:'hidden',width:'98.6%',marginLeft:'8px'}}
            >
         
          <table
                        style={{
                          borderCollapse: "separate",
                          borderSpacing: "0",   
                          fontSize: "16px",
                          minWidth: "fit-content",
                        }}
                      >

<tr>
<div className="row" style={{ flexWrap: "nowrap",height:'470px'}}>
    {Categoryshow}

    </div>
</tr>

  </table>
  </div>

  <div className="row">
                    <div className="col-3">
                        <div className="ds-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px  '#5D9BE1'`, marginBottom: '20px' }}>
                            <div className="ds-infoBoxText">
                                <div className="ds-infoBoxTextCen">
                                    <input className="ds-infoBoxTextIn"
                                        type='text'
                                        value={filter.keySearch}
                                        onChange={(e) => { setFilter({ ...filter, keySearch: e.target.value }); }}

                                        placeholder='search Category...'
                                    >


                                    </input>
                                    <div style={{ position: 'absolute', marginTop: '-27px', left: '68%', cursor: 'pointer' }}
                                        onClick={() => {
                                            selectcattegory(filter.keySearch)
                                        }}
                                    >
                                        <img src={Searchicon} style={{ width: "25px", height: "25px" }}></img>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6" style={{marginTop:'10px'}}>
                    Category: {categoryseting.name}
                    </div>
                   {/*  <div className="col-2" style={{ display: 'flex', justifyContent: 'flex-end',marginLeft:'-150px'}}>
                        <div className="ds-infoBoxTextAddbox" style={{width:'100px'}}
                            onClick={() => {
                                
                                openCreateship(true)
                            }}>
                            search 
                        </div>
                    </div> */}


                </div>

     
    <div
              className="row "
              style={{ flexWrap: "nowrap", /* overflow:'hidden', */ minWidth: "20%" ,height:'170px',overflowX:'auto',overflowY:'hidden',width:'98.6%',marginLeft:'8px'}}
            >
         
          <table
                        style={{
                          borderCollapse: "separate",
                          borderSpacing: "0",
                          fontSize: "16px",
                          minWidth: "fit-content",
                        }}
                      >

<tr>
<div className="row" style={{ flexWrap: "nowrap",height:'170px'}}>
    {Categorylistselect}

    </div>
</tr>

  </table>
  </div>
  <div className='row projectButtonBox' style={{marginTop:'-220px'}}>
              <div className='projectButton' style={{ border: '2px solid green', color: 'green',height:'35px' }} onClick={() => {
                callback(categoryseting.id)

              }}>SUBMIT</div>
              <div className='projectButton' style={{ border: '2px solid red', color: 'red',height:'35px' ,margin:0}} onClick={() => {
              setcategoryseting({...categoryseting,id:'',name:''})
              setCategorylistselect([])
              setCategorylist([])
              callback()
              }}>CANCEL</div>
            </div>
          </div>
        </div>
        :
        <></>
      }
   </>
  )
}

export default Selectcat;

