import React, { useEffect,useState } from 'react'

import './inputCustomStyle.css'
import chevrondown from '../../assets/img/icon/chevron-down.png'

function Dopdownclass({
  placeholder,
  className,
  style,
  value,
  onChange,
  disabled,
  type,
  dopdown,
  returnclass
}) {

  const [isdopdown, setisdopdown] = useState({
    data:[],
    value:'',

  })
  const [isopen, setisopen] = useState(false)
  const [onuse, setonuse] = useState(false)

  useEffect(() => {
    window.onclick = function(event) {
      if (!event.target.matches('.inputInnerStyledop')) {
        var dropdowns = document.getElementsByClassName("dopdownOuterStyle");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('onshow')) {
            openDropdown.classList.remove('onshow');

            setisopen(false)
          }
        }
      }}
  }, [window])

  useEffect(() => {

    setisdopdown({
      ...isdopdown,
      value:value.toLowerCase(),
  });
    isdopdown.value = value.toLowerCase()

    // console.log(value.length);
    if (value.length > 0) {
      let chackitem = dopdown.filter((item) => item.label.toLowerCase().includes(value));

      // console.log(chackitem.length,chackitem);
if (chackitem.length > 0) {
  let dop = chackitem.map((e) => {
     
    return (<>
  
    <li className='dopdowndetail'id={e.value} 
    onClick={(s) => {
                    
                      let dopvalue = dopdown.filter((ih) => ih.value === s.target.id);
                    
                  setisdopdown({
            ...isdopdown,
            value:dopvalue[0].label,
        });
        isdopdown.value =dopvalue[0].label
        returnclass(dopvalue[0])
                        }}  >{e.label}</li>
    
    </>)
    
  })

  // console.log(dop);
  setisdopdown({
    ...isdopdown,
    data:dop,
});
isdopdown.data = dop
// console.log(isdopdown.data );

}else{
  let dop =  (<>
  
    <li /* className='dopdowndetail' */ style={{height:'160px',marginTop:'15%',padding:'5%'}}
   >No Data</li>
    
    </>)
    
 

  // console.log(dop);
  setisdopdown({
    ...isdopdown,
    data:dop,
});
}

    }else{
    let dop = dopdown.map((e) => {
     
        return (<>
      
        <li className='dopdowndetail' id={e.value} 
        onClick={(s) => {
          let dopvalue = dopdown.filter((ih) => ih.value === s.target.id);
          setisdopdown({
            ...isdopdown,
            value:dopvalue[0].label,
        });
        isdopdown.value =dopvalue[0].label
        returnclass(dopvalue[0])
        
                        }} 
                        >{e.label}</li>
        
        </>)
        
      })
      setisdopdown({
        ...isdopdown,
        data:dop,
    });
      isdopdown.data = dop
    }

     
      }, [value || onuse])






      

      function clikdop() {
    if (document.getElementById("onDropdown").className !== 'col-12 dopdownOuterStyle onshow') {
      document.getElementById("onDropdown").classList.toggle("onshow");
      setonuse(true)
      setisopen(true)
    }  
      }

   

  return (
    <>
{/* <div className={`dopdownShadowStyle `}  >
      <div className={`dopdownOuterStyle_1 `} > */}
      <ul  className='col-12 dopdownOuterStyle' id='onDropdown'>
      
            {
                 isdopdown.data
            
        }
           </ul>
      {/*      </div>
      </div> */}

      <div className={`inputShadowStyle `} onClick={() => {
                         clikdop()
                        }} 
                          >

<img style={{width:'8%',position:'absolute',top:'22%',left:'350px',zIndex:'99',transform:`${isopen === true ? 'rotate(185.35rad)' : 'rotate(0rad)'}`}} src={chevrondown}   
    />
      <div className={`inputOuterStyle_1 ${className || ''}`} style={style}>
        <div className="inputOuterStyle_2">
          <input
            type={type ? type : 'text'}
            className="inputInnerStyledop"
            placeholder={placeholder || ''}
            value={isdopdown.value}
            onChange={onChange}
            disabled={disabled}
          />
         
        </div>
      </div>
      </div>
    </>
  )
}

export default Dopdownclass
