import React, { useState, useEffect } from 'react';
import axios from "axios";
import Configs from "../../config";
import './mainpageMobile2.css';
import Slider from "react-slick";
import moment from 'moment';
import Title from "../../components/customElement/headertitle"
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';
import mainpageBg from '../../assets/img/bg/imgmian.jpg';
import ModalNew from '../../components/Modalpopup/newsModalPopUp'
import { Link } from 'react-router-dom';
/* import mainpageBg from '../../../assets/img/bg/imgmian.jpg'; */
  let imgDeful = "../../../../assets/img/bg/imgbdsport.jpg"

/* import Title from "../../../customElement/headertitle" */
function NewInfoMobile() {
    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };
    const [toogModal, setToogModal] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: "center",
        centerPadding: "60px",
        swipeToSlide: true,
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        }
    };

    const [test, settest] = useState({
        test: ''
    })
    const [tempModal, setTempModal] = useState({
        headType: '',
        titleName: '',
        datas: '',
        dateValue: '',
        link: '',
        language: '',
        imgpath: '',
        news_id: ''
    })

    const [bulletMenu, setBulletMenu] = useState('ALL');
    const [bulletMenuData, setBulletMenuData] = useState([]);
    const [bulletMenuDataPomotion, setBulletMenuDataPomotion] = useState([]);
    const [bulletMenuDataStory, setBulletMenuDataStory] = useState([]);

    const [contactsIndex, setContactsIndex] = useState(0);
    const [contactsMenuData, setContactsMenuData] = useState([
        {
            header: 'Services',
            body: '• สนับสนุนบริษัทเพื่อจัดทำระบบสารสนเทศ<br />• สนับสนุนทีมพัฒนาเพื่อให้มีกระบวนการที่ง่ายต่อการดำเนินการ<br />• เอกสาร Template , Process มาตรฐาน ISO29110 , CMMI<br />• สร้างทีม สร้างงาน สร้างอาชีพ<br />• สนุกกับการทำงานในรูปแบบเกมส์ ทำงานจริง รับเงินจริง ปรับจริง<br />'
        },
        {
            header: 'About us',
            body: '• Platform Contact<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />• Technical Support<br />&nbsp;&nbsp;&nbsp;Tel : 098 907 8389. , Line ID : .rivendell<br />• Sales Support<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />• Partner TTT Spaceship<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />'
        },
        {
            header: 'Resouce',
            body: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทที่ให้บริการด้านไอที โซลูชั่น แนะนำแนวทาง การประยุกต์ใช้ระบบสารสนเทศเพื่อการบริหารจัดการและการฝึกอบรม เน้นการพัฒนากระบวนการแบบก้าวกระโดด เพิ่มประสิทธิภาพการทำงาน ลดระยะเวลาการดำเนินการอย่างมีนัยสำคัญ รวมทั้ง แก้ปัญหา Human error เพื่อให้ธุรกิจของลูกค้าทุกท่านบรรลุเป้าหมายอย่างรวดเร็ว และมั่นคง แข็งแรงยั่งยืน'
        },
        {
            header: 'Our Platform',
            body: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แพลตฟอร์มสำหรับบริการให้ บุคคล ,องค์กร , หน่วยงาน ที่ต้องการจัดทำระบบสารสนเทศในด้านต่าง ๆ โดยแพลตฟอร์มจะมีทีมงาน คุณภาพ จากหลากหลายภาคธุรกิจ ในด้านไอที เพื่อรอรับความต้องการ และดำเนินการวิเคราะห์ระบบ และจัดทำตามมาตรฐานการผลิต ISO29110 , CMMI ทำให้ได้ระบบที่มีคุณภาพสามารถต่อยอดและสนับสนุนงานทางธุรกิจได้'
        },
    ]);

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                style={{ ...style, display: "none" }}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                style={{ ...style, display: "none" }}
            />
        );
    }

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function setImginStage(fd_img_id) {
        try {
            let response2 = await axios.get(`https://api.box.com/2.0/files/${fd_img_id}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });

            return URL.createObjectURL(response2.data)

        } catch {
            return null
        }

    }


    async function filterNewsInfo(value) {

        let temp = {
            keySearch: value,
            page: 1,
            enties: 9999,
            sortList: {},
        };

        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/news/filterNewsinspace`,
            headers: {
                /*  Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data) {

                    let temp = response.data.data.filter((e) => { return e.kn_name == 'ACTIVITY & NEWS' });
                    if (temp.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - temp.length;
                        for (let index = 0; index < checkLength; index++) {
                            temp.push(fakeData);
                        }
                    }
                    let tempPomotion = response.data.data.filter((e) => { return e.kn_name == 'PROMOTION' });
                    if (tempPomotion.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - tempPomotion.length;
                        for (let index = 0; index < checkLength; index++) {
                            tempPomotion.push(fakeData);
                        }
                    }
                    let tempStory = response.data.data.filter((e) => { return e.kn_name == 'TTT SPACESHIP STORY' });
                    if (tempStory.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - tempStory.length;
                        for (let index = 0; index < checkLength; index++) {
                            tempStory.push(fakeData);
                        }
                    }
                    setBulletMenuData(temp);
                    setBulletMenuDataPomotion(tempPomotion);
                    setBulletMenuDataStory(tempStory);


                    for (let item = 0; item < response.data.data.length; item++) {
                        response.data.data[item].path_file = null
                        response.data.data[item].ul_file_path = null
                        if (response.data.data[item].imgobject) {
                            let getpath = await setImginStage(response.data.data[item].imgobject.fd_img_id);

                            response.data.data[item].path_file = getpath;
                            response.data.data[item].ul_file_path = getpath;
                            settest({ ...test, test: '' })
                            test.test = '';
                        }
                    }


                     temp = response.data.data.filter((e) => { return e.kn_name == 'ACTIVITY & NEWS' });
                    if (temp.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - temp.length;
                        for (let index = 0; index < checkLength; index++) {
                            temp.push(fakeData);
                        }
                    }
                     tempPomotion = response.data.data.filter((e) => { return e.kn_name == 'PROMOTION' });
                    if (tempPomotion.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - tempPomotion.length;
                        for (let index = 0; index < checkLength; index++) {
                            tempPomotion.push(fakeData);
                        }
                    }
                     tempStory = response.data.data.filter((e) => { return e.kn_name == 'TTT SPACESHIP STORY' });
                    if (tempStory.length < 3) {
                        let fakeData = {
                            kn_name: null
                        };
                        let checkLength = 3 - tempStory.length;
                        for (let index = 0; index < checkLength; index++) {
                            tempStory.push(fakeData);
                        }
                    }
                    setBulletMenuData(temp);
                    setBulletMenuDataPomotion(tempPomotion);
                    setBulletMenuDataStory(tempStory);

                    /*  }
  */

                    settest({ ...test, test: '' })
                    test.test = '';

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAccesstokenToken((result) => {
            if (result) {
                filterNewsInfo()
            }
        })

    }, [bulletMenu])


    function onClose(value) {
        /*  var modal = document.querySelector('.modalNews');
         document.querySelector('html').classList.remove('modal-opened'); */
        setToogModal(false)
        settest({ ...test, test: '' })
        test.test = '';
    }







    return (
        <>
            <div style={{ overflow: 'hidden' }}>

                <HeaderTTTMobile
                    mobileMenu={mobileMenu}
                    toggle_mobileMenu={toggle_mobileMenu}
                    mobileLogin={mobileLogin}
                    toggle_mobileLogin={toggle_mobileLogin}
                    mobileRegister={mobileRegister}
                    toggle_mobileRegister={toggle_mobileRegister}
                />




                <main /* style={{position:'relative'}} */>

                    <div className='mob-mainpageMobileBg2' style={{ backgroundImage: `url(${mainpageBg})`, overflow: 'auto' }}>

                        <div className='mob-mainpageMobileBulletBox2' style={{ zIndex: 999999 }}>
                            <Title name='BULLETIN BOARD ' no={1} fix={140} imglong={75} top={0} imgbox={65} imgcorner={22} />
                            <div className='mob-mainpageMobileBulletSelect2' style={{ top: '7%' }}>

                                <div className='mob-mainpageMobileBulletSelectText2' style={{ zIndex: 999999, color: 'blue', textDecoration: 'underline' }} onClick={() => {
                                    setBulletMenu('ACTIVITY & NEWS')
                                }}>ACTIVITY & NEWS</div>

                            </div>
                            <div className='mob-mainpageMobileBulletSlide' style={{ top: '10%' }}>
                                <Slider {...settings}>
                                    {bulletMenuData && bulletMenuData.map((e, index) => {
                                        return (<>
                                            <Link to={"/viewNews"}>
                                                <div className='mob-mainpageMobileBulletSlideShowBox' style={{ visibility: `${e.kn_name != null ? 'visible' : 'hidden'}` }} onClick={() => {

                                                    sessionStorage.setItem('News_id', e.news_id);
                                                    /* window.location.href = '/viewNews';
                                                      */
                                                    /*  setTempModal({
                                                         ...tempModal,
                                                         headType: e.kn_name,
                                                         titleName: e.news_title,
                                                         datas: `${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`,
                                                         dateValue: e.news_description,
                                                         link: e.news_url,
                                                         imgpath: e.ul_file_path,
                                                         news_id: e.news_id
                                                       })
                                                     setToogModal(true) */
                                                    settest({ ...test, test: '' })
                                                    test.test = '';
                                                }}>
                                                    <div className='mob-mainpageMobileBulletSlideShowImg' style={{ backgroundImage: `url(${e.ul_file_path ? e.ul_file_path : imgDeful})` }}></div>
                                                    <div className='mob-mainpageMobileBulletSlideShowDetail'>
                                                        {e.kn_name && e.kn_name == 'ACTIVITY & NEWS' &&
                                                            <>
                                                                < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #FF2B2B', color: '#FF2B2B' }}>
                                                                    NEWS
                                                                </div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#FF2B2B', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                                                            </>
                                                        }



                                                    </div>
                                                </div >
                                            </Link>
                                        </>)
                                    })}
                                </Slider>
                            </div>

                            {/*  เเยกบะ  */}

                            <div className='mob-mainpageMobileBulletSelect2' style={{ top: '15%' }}>
                                <div className='mob-mainpageMobileBulletSelectText' style={{ zIndex: 999999, color: 'blue', textDecoration: 'underline' }} onClick={() => {
                                    setBulletMenu('PROMOTION')
                                }}>PROMOTION</div>

                            </div>
                            <div className='mob-mainpageMobileBulletSlide' style={{ top: '17%' }}>
                                <Slider {...settings}>
                                    {bulletMenuDataPomotion && bulletMenuDataPomotion.map((e, index) => {
                                        return (<>
                                            <Link to={"/viewNews"}>
                                                <div className='mob-mainpageMobileBulletSlideShowBox' style={{ visibility: `${e.kn_name != null ? 'visible' : 'hidden'}` }} onClick={() => {
                                                    sessionStorage.setItem('News_id', e.news_id);
                                                    /*   setTempModal({
                                                           ...tempModal,
                                                           headType: e.kn_name,
                                                           titleName: e.news_title,
                                                           datas: `${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`,
                                                           dateValue: e.news_description,
                                                           link: e.news_url,
                                                           imgpath: e.ul_file_path,
                                                           news_id: e.news_id
                                                         })
                                                       setToogModal(true) */
                                                    settest({ ...test, test: '' })
                                                    test.test = '';
                                                }}>
                                                    <div className='mob-mainpageMobileBulletSlideShowImg' style={{ backgroundImage: `url(${e.ul_file_path ? e.ul_file_path  :imgDeful})` }}></div>
                                                    <div className='mob-mainpageMobileBulletSlideShowDetail'>

                                                        {e.kn_name && e.kn_name == 'PROMOTION' &&
                                                            <>
                                                                < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #FDC700', color: '#FDC700' }}>
                                                                    PROMOTION
                                                                </div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#FDC700', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                                                            </>
                                                        }

                                                    </div>
                                                </div >
                                            </Link>
                                        </>)
                                    })}
                                </Slider>
                            </div>
                            {/*  เเยกบะ  */}

                            <div className='mob-mainpageMobileBulletSelect2' style={{ top: '23%' }}>
                                <div className='mob-mainpageMobileBulletSelectText' style={{ zIndex: 999999, color: 'blue', textDecoration: 'underline' }} onClick={() => {
                                    setBulletMenu('TTT SPACESHIP STORY')
                                }}>TTT SPACESHIP STORY</div>

                            </div>
                            <div className='mob-mainpageMobileBulletSlide' style={{ top: '25%' }}>
                                <Slider {...settings}>
                                    {bulletMenuDataStory && bulletMenuDataStory.map((e, index) => {
                                        return (<>
                                            <Link to={"/viewNews"}>
                                                <div className='mob-mainpageMobileBulletSlideShowBox' style={{ visibility: `${e.kn_name != null ? 'visible' : 'hidden'}` }} onClick={() => {
                                                    sessionStorage.setItem('News_id', e.news_id);
                                                    /*  setTempModal({
                                                         ...tempModal,
                                                         headType: e.kn_name,
                                                         titleName: e.news_title,
                                                         datas: `${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`,
                                                         dateValue: e.news_description,
                                                         link: e.news_url,
                                                         imgpath: e.ul_file_path,
                                                         news_id: e.news_id
                                                       })
                                                     setToogModal(true) */
                                                    settest({ ...test, test: '' })
                                                    test.test = '';
                                                }}>
                                                    <div className='mob-mainpageMobileBulletSlideShowImg' style={{ backgroundImage: `url(${e.ul_file_path ? e.ul_file_path  :imgDeful})` }}></div>
                                                    <div className='mob-mainpageMobileBulletSlideShowDetail'>

                                                        {e.kn_name && e.kn_name == 'TTT SPACESHIP STORY' &&
                                                            <>
                                                                < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #0047FF', color: '#0047FF' }}>
                                                                    TTT SPACESHIP STORY
                                                                </div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                                                                <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#0047FF', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                                                            </>
                                                        }

                                                    </div>
                                                </div >
                                            </Link>
                                        </>)
                                    })}
                                </Slider>
                            </div>

                            <div className='mob-mainpageMobileBulletSelect2' style={{ border: '1px solid red', top: '30%', visibility: 'hidden' }}>
                                {' asdasd '}
                            </div>
                        </div>
                    </div>

                </main>

                <ModalNew
                    isOpen={toogModal}
                    onClose={onClose}
                    headType={tempModal.headType}
                    titleName={tempModal.titleName}
                    datas={tempModal.datas}
                    dateValue={tempModal.dateValue}
                    link={tempModal.link}
                    imgpath={tempModal.imgpath}
                    newID={tempModal.news_id}

                />

            </div>

        </>
    )
}

export default NewInfoMobile