import React, { useState, useEffect } from 'react';
import '../../assets/css/infodetail.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Title from "../../components/customElement/headertitle";
import { useParams } from "react-router";
import axios from "axios";
import Configs from "../../config";
import Gallery from 'react-photo-gallery';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';

import "yet-another-react-lightbox/styles.css";

const Infodetail = (params) => {
  const [temData, setTempData] = useState({
    news_id: '',
    news_title: '',
    news_description: '',
    news_type: '',
    arrimg: []
  })
  const [temDataimg, setTempDataimg] = useState([])
  const [test, settest] = useState({ test: '' })
  const [index, setIndex] = useState(-1);



  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        /* Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        var temp = {
          tokenBox: response.data.tokenBox
        }

        sessionStorage.setItem('tokenBox', response.data.tokenBox)
        if(callback){
          callback(true)
        }
        /*  console.log('ViewToken', response.data); */
      })
      .catch(function (error) {
        console.log(error);
      });

  }



  async function getNewsINfo(value) {
    await axios({
      method: "get",
      url: `${Configs.TTT_Game_Service}/api/news/getNewsByID/${sessionStorage.getItem('News_id')}`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        console.log('1');
        if (response.data) {
          let commentLines2 = []
          if (response.data.data.news_description !== undefined && response.data.data.news_description !== null && response.data.data.news_description !== '') {
            commentLines2 = response.data.data.news_description.split('\n');
          }
          setTempData({
            ...temData,
            news_id: response.data.data.news_id,
            news_title: response.data.data.news_title,
            news_description: commentLines2,
            news_type: response.data.data.kn_name
          })

          let arrimg = []

          if (response.data.fileIMG.length > 0) {
            for (let ltem of response.data.fileIMG.filter((e)=>{return e.fd_keyfile === 'HORIZONTAL IMAGE'})) {
              const img = new Image();
              const response2 = await axios.get(`https://api.box.com/2.0/files/${ltem.fd_img_id}/content`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
              });
              img.src = URL.createObjectURL(response2.data);
              img.onload = () => {
                arrimg.push(
                  {
                    src: URL.createObjectURL(response2.data),
                    width: img.width,
                    height: img.height,
                  }
                )
              }
            }



            /*      let imgmore = response.data.fileIMG.filter((dp) => dp.ul_menu_name == "Horizontal");
                 let setsize = [];
     
                 if (imgmore.length > 0) {
     
                   for (let i of imgmore) {
                     const img = new Image();
                     img.src = `${Configs.TTT_Game_Service_IMG}${i.ul_file_path}`;
     
                     img.onload = () => {
                       arrimg.push(
                         {
                           src: `${Configs.TTT_Game_Service_IMG}${i.ul_file_path}`,
                           width: img.width,
                           height: img.height,
                         }
                       )
     
     
                     };
     
                   }
     
                 } */



          }



          let finimg = []

          setTimeout(() => {
            const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
            finimg = arrimg.map((p) => {
              const width = p.width;
              const height = p.height;
              return {
                src: p.src,
                width,
                height,
                srcSet: breakpoints.map((breakpoint) => {
                  const breakpointHeight = Math.round((height / width) * breakpoint);
                  return {
                    src: p.src,
                    width: breakpoint,
                    height: breakpointHeight,
                  };
                }),
              };
            });
          }, 100);
      
          setTimeout(() => {
            setTempDataimg(finimg.length > 0 ? finimg : [arrimg[0]]);
            setTempData({
              ...temData,
              news_id: response.data.data.news_id,
              news_title: response.data.data.news_title,
              news_description: commentLines2,
              news_type: response.data.data.kn_name,
              arrimg: finimg.length > 0 ? finimg : [arrimg[0]],
            });
            temData.arrimg = finimg.length > 0 ? finimg : [arrimg[0]];
      
            settest({ ...test, test: '' });
            test.test = '';
          }, 100);


/* 
          setTimeout(function () {
            const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
            finimg = arrimg.map((p) => {
              console.log('pp',p);
              const width = p.width;
              const height = p.height;
              return {
                src: p.src,
                width,
                height,
                srcSet: breakpoints.map((breakpoint) => {
                  const breakpointHeight = Math.round((height / width) * breakpoint);
                  return {
                    src: p.src,
                    width: breakpoint,
                    height: breakpointHeight,
                  };
                }),
              }

            });
          }, 100);

          setTimeout(function () {
            setTempDataimg(finimg)
            setTempData({
              ...temData,
              news_id: response.data.data.news_id,
              news_title: response.data.data.news_title,
              news_description: commentLines2,
              news_type: response.data.data.kn_name,
              arrimg: finimg
            })
            temData.arrimg = finimg

            settest({ ...test, test: '' })
            test.test = '';
          }, 100); */
        }
        console.log('2');

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const [modeMobile, setModeMobile] = useState(false);



  const [mobileMenu, setMobileMenu] = useState(false);
  const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
  const [mobileLogin, setMobileLogin] = useState(false);
  const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
  const [mobileRegister, setMobileRegister] = useState(false);
  const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };



  useEffect(() => {
    console.log(temDataimg);
    /*   getNewsINfo() */
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      setModeMobile(true);
    }
    else {
      setModeMobile(false);
    }

    let getNewsID = sessionStorage.getItem('News_id');

    GetAccesstokenToken((resule) => {
      if (resule) {
        getNewsINfo(getNewsID)
      }
    })

  }, [])
  return (
    <>

      {modeMobile ? (<>
        <HeaderTTTMobile
          mobileMenu={mobileMenu}
          toggle_mobileMenu={toggle_mobileMenu}
          mobileLogin={mobileLogin}
          toggle_mobileLogin={toggle_mobileLogin}
          mobileRegister={mobileRegister}
          toggle_mobileRegister={toggle_mobileRegister}
        />
        <img className='bg-information' src="../../assets/img/bg/imgmian.png" alt="" />
        <div className='bg-detail' style={{ left: '-10%', top: '8%', position: 'fixed', width: '90%', overflow: 'auto', height: '100%' }}>
          <div style={{ width: '100%', fontSize: '10px', marginBottom: '10%' }}>
            <Title name={temData.news_type} no={1} fix={300} imglong={130} top={7.5} imgbox={150} imgcorner={10} />
          </div>
          {temDataimg.length > 0 ? <>

            <Gallery
              photos={temDataimg}
              onClick={(e, i) => setIndex(i.index)} />
          </> :
            <>
            </>}
          <div className='box-detail' style={{ marginBottom: '14%', marginTop: '-1%' }}>

            <div className='text-heading' style={{ fontSize: '28px' }}> {temData.news_title}</div>
            <div className='text-detailt' style={{ fontSize: '18px' }}>

              {temData.news_description.length > 0 ? temData.news_description.map((line, i) => (
                <span key={i}>
                  {line}
                  {i < temData.news_description.length - 1 && <br />}
                </span>
              )) : ''}

              <div style={{ marginTop: '30px' }}></div>
            </div>
          </div>
        </div>

      </>) : (<>

        <HeaderTTT />
        <img className='bg-information' src="../../assets/img/bg/imgmian.png" alt="" />
        <div className='bg-detail' >
          <div style={{ width: '420px' }}>
            <Title name={temData.news_type} no={1} fix={300} imglong={240} top={7.5} imgbox={150} imgcorner={10} />
          </div>
          <div className='box-detail'>
            <div className='text-heading'> {temData.news_title}</div>
            <div className='text-detailt'>

              {temData.news_description.length > 0 ? temData.news_description.map((line, i) => (
                <span key={i}>
                  {line}
                  {i < temData.news_description.length - 1 && <br />}
                </span>
              )) : ''}

              <div style={{ marginTop: '30px' }}></div>


              {temDataimg.length > 0 ? <>
                <Gallery
                  photos={temDataimg}
                  onClick={(e, i) => setIndex(i.index)} />

                <Lightbox
                  index={index}
                  slides={temDataimg}
                  open={index >= 0}
                  close={() => setIndex(-1)}
                  plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                />
              </> :
                <>
                </>}

            </div>
          </div>
        </div>
      </>)}



    </>

  )
}

export default Infodetail