import React, { useEffect, useState, useRef } from 'react'
import {
  Input,
  Space,
  Tag,
  theme,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
} from 'antd'

import axios from 'axios'
import moment from 'moment'
import dayjs from 'dayjs'
import { ExclamationCircleFilled } from '@ant-design/icons'
import Configs from '../../../config'
import IconReport from '../../../assets/img/IconStage4/reportSandMail.svg'

import './processMockup.css'

import { generateUUID } from 'three/src/math/MathUtils.js'
const { RangePicker } = DatePicker
const { confirm } = Modal

function ModalProcessMockupViewDescription({
  isOpen,
  position,
  toggle_modal_process_mockup_view_description,
  dataMockupDescription
}) {
  
  const [textDescription,setTextDescription] = useState('')

  useEffect(() => {
   /*  if(isOpen == true){
      let temp_sort =[];
      for(let item of data_mockup_version){
        let temp ={
          version_view:item.version,
          remark_view:item.remark,
          path_view:item.path,
          name_view:item.name
        }
        temp_sort.push(temp);
      }
      let data_sort = temp_sort.sort((a,b)=> b.version_view - a.version_view);
      setDataVersion(data_sort);
    }else{
      setDataVersion([]);
    } */

    
    if(isOpen == true){
      setTextDescription(dataMockupDescription);
    }

  }, [isOpen && dataMockupDescription && position])

  const [test,settest] = useState({test:''});



  return (
    <>
      {/*     {isOpen && (
    <> */}
      <ConfigProvider
        theme={{
          token: {
            /*     colorPrimary: '#85BFFF', */
            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue', //สีตอน Active ใน Select
            colorBgBase: '#778899', //พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: '#FFFFFF', // สีตัวหนังสือของ placeholder

            colorFillTertiary: '#000', // สีตอน hover ใน Select

            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker

            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */
          },
        }}
      >
        {/* <div
          className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`}
        
        >
          {' '}
        </div> */}
        <div
          className={`decriptionViewCard ${isOpen ? 'opened' : 'closed'}`}
          style= {{top:`${position.posY}px`,left:`${position.posX}px`,position:'fixed',      height: 'fit-content',   width: '500px',}}
         /*  style={{
          left: `50%`,
            position: 'fixed',
            transform: 'translate(-50%, -22%)',
         
      


          }} */
        >
         
        

     {/*       <div className = 'decriptionViewCard'> */}
            <div className = 'decriptionViewCardHeader'>Description</div>
            <div className = 'decriptionViewCardBody'>
            <div style={{ width:'100%', overflowY: 'auto',wordBreak:'break-word' }}>
                
             {textDescription || 'No Description'}

            {/*   asdasda hjgjghjg jhghj ghjg hjg hjkguhg hjkg hjkg hgfhjfghjf hj ghfh jhjgh j jkh h ujgh i gkhj gjk gjkg l gjklg jklgjk gjlg lg */}
   
              </div>
       

            </div>
            
       {/*     </div> */}




            

           
         
          <br />
          <br />

        </div>
      </ConfigProvider>

      {/*     </>
    
  )} */}
    </>
  )
}
export default ModalProcessMockupViewDescription
