import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import { Select } from 'antd';
/* import Select from 'react-select' */
import moment from "moment";
import axios from "axios";
import './tabSrs.css'
import Configs from "../../../../config";
import { Card, Switch, Tag, Tooltip, Button, Checkbox, TreeSelect, Input } from 'antd';
const { TextArea } = Input;


function TabCompanentFinalSummary({  isGetBySRS, callbackSrsSpecificField, srsSpecificField,callBackCheckChang,userismode }) {
    const [optionRole, setOptionRole] = useState([])
    const [textAreaId, setTextAreaId] = useState({})
    const [test, settest] = useState({ test: '' })

    const [specificField, setSpecificField] = useState([]);









    useEffect(() => {
      
    }, [ isGetBySRS])








    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    async function getDocumentTemplate(srs_value) {
        let id = "70146107-244a-48ad-8023-0ec110183002"; //id srs document specifig
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {
                console.log('จะคอเเบล็ก',response.data);
                if (response.data) {
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                /* let check = srs_value.map((e)=>{return e.dt_input_data.data.map((e2)=>{return e2.value_list.map((e3)=>{return e3.value_id == item3.value_id})})})
                                console.log('check',check) */
                                for (let item_ of srs_value) {
                                    for (let item2_ of item_.dt_input_data.data) {
                                        for (let item3_ of item2_.value_list) {
                                            if (item3.value_id == item3_.value_id) {
                                                if (item3_.value_input) {
                                                    item3.value_input = item3_.value_input || '';
                                                }
                                                if (item3_.data_list) {
                                                    item3.data_list = item3_.data_list || [];
                                                }
                                                if (item3_.value_input_header) {
                                                    item3.value_input_header = item3_.value_input_header || '';
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                            if (item3.value_type == 'table_column') {
                                let temp_in = {
                                    value_input_table: [],
                                }
                                for (let item of item3.table_column_list) {
                                    temp_in.value_input_table.push('');
                                }
                                if (!item3.data_list) {
                                    item3.data_list = [];
                                }
                                if(item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length){
                                    item3.data_list = [];
                                    console.log('หัวไม่เท่ากับ value')
                                }
                                if(item3.data_list.length == 0 && item3.is_multiple == false){
                                    item3.data_list.push(temp_in);
                                }
                            
                            }
                        }
                    }
                }
                    setSpecificField(response.data);
                    callbackSrsSpecificField(response.data);
                   
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getDocumentTemplate(srsSpecificField);
    }, [srsSpecificField]);



    const [textChange, setTextChange] = useState({ text: '' });

    function addValueMultiple(index, index2, index3, type) {
        console.log(index, index2, index3)
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1) {
            if (type == 'table_column') {
                let temp_in = {
                    value_input_table: [],
                }
                for (let item of temp[index].dt_input_data.data[index2].value_list[index3].table_column_list) {
                    temp_in.value_input_table.push('');
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            } else {
                let temp_in = {
                    value_input: '',
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            }



            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }


    }


    function removeValueMultiple(index, index2, index3, index4) {
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1 && index4 > -1) {
            temp[index].dt_input_data.data[index2].value_list[index3].data_list.splice(index4, 1);
            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }


    function changeValueInJson(key, value, index, indexGroup, indexValue, index_, index_column) {
        console.log(key, value, index, indexGroup, indexValue, index_)

        let tempData = specificField || [];
        //console.log( tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`])
        setTextChange({ ...textChange, text: value });
        textChange.text = value;
        /* if (key == 'value' || key == 'value_type' || key == 'is_multiple') {
    
          tempData[indexGroup]['value_list'][indexValue][key] = textChange.text;
        } else { */
        if (key == 'value_input_table') {
            console.log(tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_])
            tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column] = textChange.text;
        } else {
            if (index_ > -1) {
                console.log("in")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`] = textChange.text;
            } else {
                console.log("out")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue][`${key}`] = textChange.text;
            }
        }


        /*   } */
        setSpecificField(tempData);
        callbackSrsSpecificField(tempData);
        settest({ ...test, test: '' });
        test.test = '';

    }




    return (
        <>
             <div className="box-content-evens2">
                <div className="row delpm">
                    <div className="col-12">
                        <div className="box-content-evens1" style={{ height: '550px' }}>

                            <div className="row">
                                <div className="col-12">

                                    {specificField && specificField.map((e, index) => {
                                        //console.log(0)
                                        return (<>

                                            <div className="row">
                                                <div className="col-12">
                                                    <span style={{ fontSize: '24px' }}>{e.dt_name}</span>
                                                </div>

                                            </div>
                                            <div className="boxSpecific" >
                                                <div className="boxSpecificIn" >
                                                    {e.dt_input_data.data.length == 0 && (<><font color="red">No specific</font></>)}
                                                    {e.dt_input_data.data.map((e2, index2) => {

                                                        return (<>
                                                            {index2 > 0 && (<br />)}
                                                            <div className="row mt-2">
                                                                <div className="col-12"><Tooltip color='#051F6C' placement="right" title={e2.suggestion}>
                                                                    <span style={{ fontSize: '18px' }}>{index2 + 1}.{e2.group_name}</span>
                                                                </Tooltip></div>

                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                {e2.value_list.length == 0 && (<> <div className="col-6"><font color="red">No value</font></div></>)}
                                                                {e2.value_list.map((e3, index3) => {

                                                                    return (<>
                                                                        <div className="col-12">
                                                                            <div className="mb-2 mt-2">{index2 + 1}.{index3 + 1}. {e3.value}
                                                                                {/* {userismode === false ? (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px' }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD</button>)}</>) : <></>} */}
                                                                            </div>

                                                                            {e3.is_multiple ? (<>
                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                    return (<>
                                                                                        {e3.value_type == 'table' && (<>
                                                                                            {e3.is_border == true ? <table >
                                                                                                <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                   

                                                                                                </td>
                                                                                                {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed ml-2'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </table> :

                                                                                                <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>

                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px', width: '30%' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />



                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                    {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                </div>
                                                                                            }


                                                                                        </>)}
                                                                                        {e3.value_type == 'description' && (<>


                                                                                            <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e4.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        //console.log("test", v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                    }}
                                                                                                />
                                                                                                {userismode === false ? (
                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                        className='btnRed'
                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </div>
                                                                                        </>)}




                                                                                    </>);

                                                                                })}

                                                                                {e3.value_type == 'table_column' && (<>
                                                                                    <div style={{ display: 'flex', gap: '5%' }}>
                                                                                        <table style={{ width: '100%' }}>
                                                                                            <tr>
                                                                                                {e3.table_column_list.map((e4) => {
                                                                                                    return (<>
                                                                                                        <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                    </>)
                                                                                                })}
                                                                                            </tr>

                                                                                            {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                return (<>
                                                                                                    <tr>
                                                                                                        {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                            return (<>
                                                                                                                <td style={{ border: '1px solid #ddd', padding: '1%' }}>
                                                                                                                    <TextArea
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                                        autoSize={{
                                                                                                                            minRows: 1
                                                                                                                        }}
                                                                                                                        type='text'
                                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                        value={e5}
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(v) => {
                                                                                                                            //console.log("test", v.target.value);
                                                                                                                            changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            </>)
                                                                                                        })}
                                                                                                        <td>
                                                                                                            {userismode === false ? (
                                                                                                                <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                                                                                    className='btnRed'
                                                                                                                    onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>)

                                                                                            })}

                                                                                        </table>

                                                                                    </div>
                                                                                </>)}

                                                                            </>) : (<>

                                                                                {e3.value_type == 'table' && (<>

                                                                                    {e3.is_border == true ?
                                                                                        <table >
                                                                                            <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px', width: '100%' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR HEADER...'
                                                                                                    value={e3.value_input_header}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>
                                                                                            <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e3.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>

                                                                                        </table> : <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px', width: '30%' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR HEADER...'
                                                                                                value={e3.value_input_header}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                value={e3.value_input}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                        </div>}

                                                                                </>)}

                                                                                {e3.value_type == 'description' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                            style={{ borderRadius: '0px' }}
                                                                                            autoSize={{
                                                                                                minRows: 1
                                                                                            }}
                                                                                            type='text'
                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                            value={e3.value_input}
                                                                                            disabled={userismode}
                                                                                            onChange={(v) => {
                                                                                                console.log(v.target.value);
                                                                                                changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </>)}

                                                                                {e3.value_type == 'table_column' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        {e3.value_type == 'table_column' && (<>

                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    {e3.table_column_list.map((e4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                        </>)
                                                                                                    })}
                                                                                                </tr>

                                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                    return (<>
                                                                                                        <tr>
                                                                                                            {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                                return (<>
                                                                                                                    <td style={{ border: '1px solid #ddd', padding: '1%' }}>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={e5}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(v) => {
                                                                                                                                //console.log("test", v.target.value);
                                                                                                                                changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </>)
                                                                                                            })}

                                                                                                        </tr>
                                                                                                    </>)

                                                                                                })}
                                                                                                {/* {e3.data_list.map((e4, index4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', padding: '1%' }}>
                                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                                    autoSize={{
                                                                                                                        minRows: 1
                                                                                                                    }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={e4}

                                                                                                                    onChange={(v) => {
                                                                                                                        //console.log("test", v.target.value);
                                                                                                                        changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </>)
                                                                                                    })} */}

                                                                                            </table>


                                                                                        </>)}

                                                                                    </div>
                                                                                </>)}

                                                                            </>)}
                                                                            {userismode === false ? (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px', marginBottom: '1.6rem', }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD</button>)}</>) : <></>}

                                                                        </div>
                                                                    </>)
                                                                })}

                                                            </div>

                                                        </>)
                                                    })}
                                                </div></div>
                                            <hr />
                                        </>);
                                    })}

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}
export default TabCompanentFinalSummary;
