import React, { useEffect, useState, useRef } from 'react';
import "./reportSend.css"
import axios from "axios";
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";

import moment from "moment";
import Configs from "../../../../config";


function SendreportMail({ isOpen, togle_modal_send_mailFun,stageMonitoringData }) {

    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);

   /*  const [displaySwal, setDisplaySwal] = useState(false); */

    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);


    const [sendMail, setsendMail] = useState(false);
    const toggle_modal_sendMail = () => setsendMail(!sendMail);

    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })


    const [valueSave, setValueSave] = useState({
        lsent_date: new Date(),
        lsent_detail: '',
        lsent_project_id:'', 
        lsent_spaceship_id:'',
        menulist:[]


    })

    useEffect(() => {
        if (isOpen === true) {
            console.log('stageMonitoringData',stageMonitoringData);
            setValueSave({...valueSave,
                lsent_detail:'',
                lsent_date: new Date(),
                lsent_project_id: stageMonitoringData.project_id, 
                lsent_spaceship_id: stageMonitoringData.project_spaceship_id || null,
                menulist: stageMonitoringData.menulist || []
            })
            toggle_modal_sendMail()
        }
    }, [isOpen])



    function SaveSendMailReport() {
        toggle_modal_confrimModal()


        const error_list = [];

        if (valueSave.lsent_date === undefined || valueSave.lsent_date === null || valueSave.lsent_date === '') {
            let temp_err = {
                message: "Please fill in the DATE"
            }
            error_list.push(temp_err);
        }

        if (valueSave.lsent_detail === null || valueSave.lsent_detail.trim() === "") {
            let temp_err = {
                message: "Please fill in the DETAIL"
            }
            error_list.push(temp_err);
        }


        if (error_list.length > 0) {

            var err_message = [];
            for (var e = 0; e < error_list.length; e++) {
                err_message.push(error_list[e].message);
            }
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })
            setConfrimModal(false)
            setAlertKickMassge(true)
        } else {
            let temp = {
                lsent_project_id:valueSave.lsent_project_id, 
                lsent_spaceship_id:valueSave.lsent_spaceship_id || null,
                lsent_date: valueSave.lsent_date || null,
                lsent_detail: valueSave.lsent_detail.trim(),
                menulist: valueSave.menulist || [],
                by_user_id: sessionStorage.getItem('user_id'),
            }
            axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/mail/SendMailReport`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp

            })
                .then(function (response) {
                    if (response.data) {

                        setMessageBox({
                            ...messageBox,
                            message: 'success',
                            isError: false,
                            menuTitle: 'Save'
                        })
                        setConfrimModal(false)
                        toggle_modal_alertKickMassge()
                    }


                })
                .catch(function (error) {
                    console.log(error);


                });
        }



    }




    function CloseFunc() {
        toggle_modal_sendMail()
        togle_modal_send_mailFun()
    }


    return (
        <>
            <div className='ModalSendMailReport' style={{ display: `${sendMail ? '' : 'none'}` }}>
                <div className='box-send-mail'>
                    <div className='box-headreport'>
                        Report Matching
                    </div>
                    <div className='boxbody-report'>
                        <div className='col-12'>
                            <div className="pi-infoText">DATE</div>
                            <div className="report1-infoBoxText">
                                <div className="report1-infoBoxTextCen">
                                    <input className="report1-infoBoxTextIn"
                                        type='text'
                                        style={{ textAlign: 'center' }}
                                        disabled
                                        value={moment(valueSave.lsent_date).format('YYYY-MM-DD')}
                                    /*     onChange={(e) => {
                                            setValueSave({ ...valueSave, lsent_date: e.target.value !== "" && e.target.value !== null ? new Date(e.target.value) : null })
                                        }} */
                                    >
                                    </input>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="pi-infoText" style={{ fontSize: '17.005px' }}>DETAIL</div>
                            <textarea className="textArea"
                                id={'input-detailarea'}
                                type='text'
                                style={{ height: '265px', maxHeight: '265px', backgroundColor: 'rgba(1, 19, 64, 0.50) !import', border: '2px solid #0047FF' }}
                                value={valueSave.lsent_detail}
                                onChange={(e) => {
                                    setValueSave({ ...valueSave, lsent_detail: e.target.value })
                                }}
                            >
                            </textarea>


                        </div>
                    </div>
                    <div className='row are-btn-box'>
                        <div className='btn-confrim' onClick={() => {
                                    toggle_modal_confrimModal()
                        }}>
                            Save
                        </div>
                        <div className='btn-cancen' onClick={() => {
                            setValueSave({
                                ...valueSave,
                                lsent_date: new Date(),
                                lsent_detail: ''
                            })
                            toggle_modal_sendMail()
                            togle_modal_send_mailFun()
                        }}>
                            Cancel
                        </div>
                    </div>

                </div>
            </div>

            <ModelAlertConfrim
                isOpen={confrimModal}
                mainFuc={() => SaveSendMailReport()}
                toggle_confrim={() => toggle_modal_confrimModal()}
            />

            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => CloseFunc()}
            />


        </>
    );
}

export default SendreportMail;
