import React from 'react'
import './inputCustomStyle.css'

function input({
  placeholder,
  className,
  style,
  value,
  onChange,
  disabled,
  type,
}) {
  return (
    <>
      <div className={`inputShadowStyle `}   >
      <div className={`inputOuterStyle_1 ${className || ''}`} style={style}>
        <div className="inputOuterStyle_2">
          <input
            type={type ? type : 'text'}
            className="inputInnerStyle"
            placeholder={placeholder || ''}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      </div>
    </>
  )
}

export default input
