import React from "react";
import "./cardeventStyle.css";
import moment from "moment";
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import { Link } from 'react-router-dom';
function cardevent({ tagevent, nameevent, date, bgevent, newsID }) {
  /*   console.log('datedate',date); */
  let tag = {
    nametag: 'none',
    color: '',
    width: '',

  }
  if (tagevent === 'p') {
    tag.nametag = 'PROMOTION'
    tag.color = '#FDC700'

  }

  if (tagevent === 'n') {
    tag.nametag = 'NEWS'
    tag.color = '#FF2B2B'

  }

  if (tagevent === 's') {
    tag.nametag = 'TTT SPACESHIP STORY'
    tag.color = '#0047FF'

  }
  /* console.log('อะไรครับเนี่ย',newsID); */
  return (
    <>
      <Link to={`/viewNews`} onClick={() => {

      }}>
        <div className="cardbackground" style={{ backgroundImage: `url(${bgevent})`, backgroundSize: 'cover' }}
          onClick={() => {
            sessionStorage.setItem('News_id', newsID)
          }} >
          <div className="cardBackgroundFade"></div>
          <div style={{position:'relative',zIndex:1}}>
            <div className="mb-1">
              <h style={{ border: `2px solid ${tag.color}`, color: `${tag.color}`, background: 'none', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '7px', paddingBottom: '5px', fontWeight: '700', fontSize: '10px' }}>
                {tag.nametag}</h>
            </div>
            <div className="mb-1">
              <font style={{ fontSize: '30px', color: '#ffff', fontWeight: '600' }}>{nameevent}</font>
            </div>
            <div className="mb-1">
              <font style={{ fontSize: '16  px', color: '#C3CBD2', fontWeight: '600', fontStyle: 'italic' }}>{date}</font>
            </div>
          </div>
        </div>

      </Link>
    </>
  );
}

export default cardevent;
