import React, { useState, useEffect, useRef} from 'react'
import { useParams } from "react-router";

import './kickOff.css'
import '../../assets/css/landingPageGame.css'
import Configs from "../../config";
import axios from 'axios';
import moment from "moment";
import { Input, Select, Space, Tag, theme, Tooltip, ConfigProvider, message, Upload } from 'antd';
import LandingPagestage from '../LandingPagestage';
import { InboxOutlined } from '@ant-design/icons';
import bg from '../../assets/img/bg/kickoffprojectbg.png'
import budgetLogo from '../../assets/img/logo/budgetLogo.png'
import MemberCountLogo from '../../assets/img/logo/MemberCountLogo.png'
import profileImg from '../../assets/img/userlogo/defultUser.png';
import readyRed from '../../assets/img/logo/readyRed.png';
import readyGreen from '../../assets/img/logo/readyGreen.png';
import BoxAddOnSpace from './img/boxAddOnSpace.png';
import startRed from '../../assets/img/logo/startRed.png';
import startGreen from '../../assets/img/logo/startGreen.png';
import onSpaceBlue from '../../assets/img/logo/onSpaceBlue.png';
import finishBlue from '../../assets/img/logo/finishBlue.png';
import ComponentAddMenberOnSpace from './component/addMember.js';
import timelineIcon from './img/timelineIcon.png';
import backIcon from './img/Vector.png';
import stateKickoff from './img/stateKickoff.png';
import stateOnMission from './img/stateOnMission.png';
import stateFinish from './img/stateFinish.png';
import { io } from 'socket.io-client';
import { method, result } from 'lodash';

const { Dragger } = Upload;
function KickOffProject(aaa) {
    const [test, setTest] = useState({
        test: ''
    });

    const id = new URLSearchParams(document.location.search).get('id');
    const [modelvideo, setmodelvideo] = useState({
        isOpen: false
    })
    const toggle_modal_modelvideo = () => setmodelvideo(!modelvideo);
    const [modeTimeline, setModeTimeline] = useState(false);
    const toggle_modeTimeline = () => setModeTimeline(!modeTimeline);
    const [modeVideoView, setMmodeVideoView] = useState(false);
    const toggle_modeVideoView = () => setMmodeVideoView(!modeVideoView);
    const [checkOnModal, setCheckOnModal] = useState({
        isOpen: false
    })
    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);
    const [confrimModal2, setConfrimModal2] = useState(false);
    const toggle_modal_confrimModal2 = () => setConfrimModal2(!confrimModal2);

    const [projectStateData, setProjectStateData] = useState({
        /*  member: [] */
    })

    function setMenuValue(value) {
        sessionStorage.setItem('isMenu', value)
    }
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);

    const [dataAddMeber, setDataAddMeber] = useState({
        project_id: id,
        space_id: ''
    })
    const [countMenberOnpace, setCountMenberOnpace] = useState(false)
    const [isLeaderSpace, setIsLeaderSpace] = useState(false)

    const [addMenberOnSpaceModal, setAddMenberOnSpaceModal] = useState(false);
    const toggle_modal_AddMenberOn = () => setAddMenberOnSpaceModal(!addMenberOnSpaceModal);

    const [waitConfrim, setWaitCofrim] = useState({
        ipt_usr_id: '',
        ipt_id: '',
        ipt_spaceship_id: '',
    })

    const [currentUser, setCurrentUser] = useState({})

    const [memberCount, setMemberCount] = useState(0)

    const [allReady, setAllReady] = useState(false)
    const [projectTimelineDataResult, setProjectTimelineDataResult] = useState([])
    const [projectTimelineData, setProjectTimelineData] = useState([]);

    
  const [oldFileList, setOldFileList] = useState([]);
  const [otherFileList, setOtherFileList] = useState([]);
  const [importFileList, setImportFileList] = useState({});
  const [loading, setLoading] = useState(false);

    function getProjectStateById() {
        // console.log('testlog getProjectStateById');
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/project/getProjectStateById/${id}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                //project_spaceship_id
                // console.log('testlog res sss', response.data);


                let checkLeder = response.data.member.filter((e) => { return e.su_is_leader == true })
                if (checkLeder.length > 0) {
                    let getIS = checkLeder[0].usr_id == sessionStorage.getItem('user_id')
                    if (getIS == true) {
                        setIsLeaderSpace(true)
                    } else {
                        setIsLeaderSpace(false)
                    }
                } else {
                    setIsLeaderSpace(false)
                }


                setDataAddMeber({
                    ...dataAddMeber,
                    project_id: id,
                    space_id: response.data.project_spaceship_id

                })
                let getIsmember = response.data.member.filter((e) => { return e.is_member == true }) || []
                let getNumber = parseInt(getIsmember.length || 0) + parseInt(response.data.memberWaitForAccpt.length || 0);
                let getSumAgin = parseInt(getNumber) == parseInt(response.data.spaceship_size || 0);

                setCountMenberOnpace(getSumAgin)
                setProjectStateData(response.data)
                let checkCurrentUser = response.data.member.filter((e) => {
                    return e.usr_id === sessionStorage.getItem('user_id')
                })
                if (checkCurrentUser.length > 0) {
                    setCurrentUser(checkCurrentUser[0])
                }

                let checkMemberCount = response.data.member.filter((e) => {
                    return e.is_member === true
                })
                if (checkMemberCount.length > 0) {
                    setMemberCount(checkMemberCount.length)
                }

                let checkMemberReady = response.data.member.filter((e) => {
                    return e.isReady === true
                })
                if (checkMemberReady.length == checkMemberCount.length) {
                    setAllReady(true)
                } else {
                    setAllReady(false)
                }

                // if (response.data.image_list && response.data.image_list.length > 0) {
                //     setOtherFileList(response.data.image_list || []);
                // };

                if (response.data.member) {
                    for (let item of response.data.member) {
                        if (item.fd_img_id) {
                            const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });

                            item.ul_file_path = URL.createObjectURL(response2.data);
                        };
                        setProjectStateData(response.data);
                    };
                };

                if (response.data.memberWaitForAccpt) {
                    for (let item of response.data.memberWaitForAccpt) {
                        if (item.fd_img_id) {
                            const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });

                            item.ul_file_path = URL.createObjectURL(response2.data);
                        };
                        setProjectStateData(response.data);
                    };
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const [time, setTime] = useState('');
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const setCurrentTime = () => {
        if (projectStateData.pp_finish_date) {
            let currentTime = moment(projectStateData.pp_finish_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).diff(moment(new Date()), 'second')
            const hours = Math.floor(currentTime / 3600);
            const minutes = Math.floor((currentTime % 3600) / 60);
            const seconds = currentTime % 60;

            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');

            return `${formattedHours}:${formattedMinutes >= 0 ? formattedMinutes : formattedMinutes * -1}:${formattedSeconds >= 0 ? formattedSeconds : formattedSeconds * -1}`;
        } else {
            return 'LOADING..'
        }

    };

    const [fileUploadList,setFileUploadList] = useState([]);

    async function CheckAndCreateFolderBox(project_id,folder_name,ref_id,callback){
        let temp = {
          folderLis:["PROJECT", `PROJECT_${project_id}`, "PROJECT VIDEO", folder_name ]
        }
        console.log('PROJECT_ID',id);
        await axios({
          method: "POST",
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
          headers: {
              Authorization:sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
          },data:temp,
      })
          .then(async function (response) {
            if(response.data){
               let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: ref_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        callback(res)
                
              }
              /* UploadFiles(folder_id,folder_name,fed_id,file); */
            }
    
          })
          .catch(function (error) {
              console.log(error);
          });
      }

      async function UploadFiles(folder_id, key, ref_id, file, callback) {
        let image_id_list = fileUploadList;
        let dataVideo = new FormData();


        file.forEach((item, index) => {
        if (item.is_chacge) {
            if (item.file) {
                dataVideo.append('File', item.file);
            }

        }

        });
        dataVideo.append('folder_id', folder_id);
        dataVideo.append('key', key);
        dataVideo.append('image_old_list', JSON.stringify(image_id_list));
        dataVideo.append('ref_id', id);
  
    
        await axios({
          method: "POST",
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "multipart/form-data",
          }, data: dataVideo,
        })
          .then(async function (response) {
            let temp = fileUploadList || [];
            for (let item of response.data.image_id_list) {
              temp.push(item);
            }
            setFileUploadList(temp);
            console.log('test22222',fileUploadList);
            setTest({ ...test, test: '' });
            test.test = '';
            if (callback) {
              callback(true)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      const [saveVideoFile,setSaveVideoFile] = useState([]);

      async function SaveVideoFile2Text() {
        console.log(fileUploadList);
        await CheckAndCreateFolderBox(id,'PROJECT KICK-OFF',id, async (result) => {
            if(result){
                UploadFiles(result.folder_id,'PROJECT TIMELINE', result.ref_id, saveVideoFile, async(result2) => {
                    await axios({
                        method: "POST",
                        url: `${Configs.TTT_Game_Service}/api/project/SaveVideoFile2Text`,
                        headers: {
                            Authorization: sessionStorage.getItem('access_token'),
                            "X-TTT": `${Configs.API_TTT}`,
                            "Content-Type": "application/json",
                        },
                        data: {
                                folder_id:result.folder_id,
                                project_id: id,
                                file : fileUploadList[fileUploadList.length-1],
                                text_video: sendData.text,
                            }
                    })
                        .then(async function (response) {
                            
                        })
                        .catch(function (error) {
                            console.log(error);
                      
                        });
                })
            }
        })
    };

    const [fileVideoImport,setFileVideoImport] = useState({});

    async function setVideoImport(image_id){
        setTextVideo();
        setFileVideoImport();
        await axios({
          method: "get",
          url: `https://api.box.com/2.0/files/${image_id}/content`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
        },
        responseType: 'blob',
    
        })
          .then(async function (response) { 
            let link = URL.createObjectURL(response.data);
            setFileVideoImport(link);
            console.log('fileVideoImport',fileVideoImport);
          }).catch((err)=>{
            console.log('err',err);
          })
      }
    
    
    const [videoFile,setVideoFile] = useState([]);
    const [sendData,setSendData] = useState({
        text : ''
    });
    const [isLoading,setIsLoading] = useState(false)

    const UploadVideo = async () => {
        try{
            setIsLoading(true)
            const dataFile = new FormData();
            for (let i = 0; i < videoFile.length; i++) {
                console.log(videoFile[i].file)
                dataFile.append('Profile', videoFile[i].file);
            }
                axios({
                    method: "POST",
                    url: "http://192.168.1.148:8000/api/ContentVideo2Text/",
                    headers: {
                        /* Authorization: getToken(), */
                        "X-TTT": `${Configs.API_TTT}`,
                'Content-Type': 'multipart/form-data',
                    },
                    data: dataFile,
                }).then(async function (response) {
                    console.log('file',response.data);
                    setSendData({text:response.data.text})
                    console.log(sendData.text);
                    setVideoFile([])
                    setIsLoading(false)
                }).catch((err)=>{

                })
        }   catch (error) {
          console.error('Error fetching data:', error);
        }  
    };

    const[textVideo,setTextVideo] = useState({})

    function filterVideoTimeline(search){
        let user_id = sessionStorage.getItem('user_id')
        let fed_id = sessionStorage.getItem('fed_id')
        console.log(user_id);
        console.log(fed_id);

        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/filterVideoTimeline`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: {
                project_id : id,
                search : search
            }
        })
            .then(async function (response) {
                let array = [];
                console.log("response.data",response.data);
                for (let item of response.data){
                let temp = {
                    fileId: item.fd_img_id, 
                    date: item.fd_created_date,
                    name: item.usr_name + ' ' + item.usr_lastname,
                    fileName : item.fd_origin_name,
                    userId : item.fd_created_by,
                    boxId : item.fd_box_id,
                    textVideo : item.fd_text_description
                    }
                array.push(temp)
                }
                console.log(array);
                setProjectTimelineData(array)
                setProjectTimelineDataResult(array)
                console.log(projectTimelineData);
            })
            .catch(function (error) {
                console.log(error);
          
            });
    };


    const [countdown, setCountdown] = useState(0);

    function sendProjectReady() {
        // console.log('testlog sendProjectReady');
        let temp = {
            project_id: id,
            space_id: projectStateData.project_spaceship_id,
            member_kick_off: memberCount || 99999
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/updateReadyProjectKickOff`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {

                socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + id });
                //getProjectStateById();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function sendProjectStart() {
        // console.log('testlog sendProjectStart');
        let temp = {
            project_id: id,
            space_id: projectStateData.project_spaceship_id,
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/startProjectKickOff`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {

                socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + id });
                //getProjectStateById();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdown + 1);
            //setCurrentTime();
            // console.log('testlog', countdown);
        }, 1000); // อัปเดตเวลาคูลดาวน์ทุกๆ 1 วินาที

        return () => clearInterval(interval);
    }, [countdown]);



    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])


    useEffect(() => {
        socketRef.current.emit('join', "KickOffProject" + id);

    }, [])

    useEffect(() => {
        // console.log('onRoom', "KickOffProject:" + id);
        /*     if (isOpen === true) {
    
                socketRef.current.emit('leave', "KickOffProject:" + id) */
        socketRef.current.emit('join', "KickOffProject:" + id)

        /* 
                } else {
                    socketRef.current.emit('leave', 'KickOffProject:' + id)
                    socketRef.current.emit('leave', 'KickOffProject:' + id)
                } */
    }, [])


    useEffect(() => {

        getProjectStateById()


        const { current: socket } = socketRef;



        const socFnc = (data) => {
            // console.log('onRoom__1', data)
            getProjectStateById();
            //getChatData(data.project_id);
            /*     socket.emit('leave',data.room) */
        }

        try {
            socket.open();



            socket.on('KickOff', socFnc)


        } catch (error) {
            console.log(error);
        }


        return () => {

            socket.off("KickOff", socFnc);

            socket.close();

        };
        /* } */



    }, []);






    const props = {

        maxCount: 1,
        name: 'file',
        accept: ".mp4",
        multiple: false,
        method: 'get',
        headers: {
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },

        action: Configs.TTT_Game_Service + '/api/upload/fackFile',
        onChange(info) {
            const { status } = info.file;
            info.status = 'done';
            // console.log('status', status)
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                /*  setFileImport(info.file); */

                /* message.success(`${info.file.name} file uploaded successfully.`); */
            } else if (status === 'error') {
                /* message.error(`${info.file.name} file upload failed.`); */
            }
        },
        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const [fileImport, setFileImport] = useState({
        file: [],
        remark: null,
        mode: 'view',
        newfile: null
    });
    const [fileImportList, setFileImportList] = useState([]);
    const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });



    function autoResize(value) {
        const textarea = document.getElementById(value);
        if (textarea != null || textarea != undefined) {
            textarea.style.height = '35px'; // รีเซ็ตความสูงก่อนที่จะปรับขนาด
            textarea.style.height = textarea.scrollHeight + 5 + 'px'; // ปรับความสูงใหม่ตามข้อความที่ป้อนเข้าไป
        }
    }



    async function saveAddModule() {
        setLoading(true);
        let ole_file = [];
        let filelist = fileImport.file
        let byidchack = false
        // if (filelist && filelist.length > 0) {
        //     ole_file = filelist.filter((e) => e.byid !== undefined && e.byid == true)
        //     if (ole_file.length > 0) {
        //         byidchack = true
        //     }

        //     let newProfile = filelist.filter((e) => e.byid == undefined || e.byid == false)
        //     if (newProfile.length > 0) {
        //         const dataImg = new FormData();
        //         for (let i = 0; i < newProfile.length; i++) {
        //             dataImg.append('Profile', newProfile[i].image_file);
        //         }
        //         dataImg.append('typeFile', 'kickoffvideo');
        //         dataImg.append('projectKey', "kickoff");
        //         await axios({
        //             method: 'post',
        //             url: `${Configs.TTT_Game_Service}/api/upload/document2`,
        //             headers: {
        //                 'X-TTT': `${Configs.API_TTT}`,
        //                 'Content-Type': 'application/octet-stream',
        //             },
        //             data: dataImg,
        //         })
        //             .then(async function (response) {
        //                 if (response.data.status == true) {
        //                     response.data.data.forEach((e) => {
        //                         const tmp = {
        //                             name: e.orgin_name,
        //                             path: e.path,
        //                             is_save: true,
        //                             ul_is_use: true,
        //                             ul_file_size: e.size,
        //                             url: e.path,
        //                             ul_type: newProfile[0].ul_type,
        //                             ul_group_name: "kickoff",
        //                             ul_file_name: e.orgin_name,
        //                             ul_file_path: e.path,
        //                             ul_menu_name: "kickoff",
        //                             ul_is_profile: true,
        //                             status: 'done'

        //                         }
        //                         ole_file.push(tmp);
        //                     })
        //                 }

        //             })
        //             .catch(function (error) {
        //                 console.log(error);
        //             });
        //     } else {
        //         let checkIMG = filelist.filter((e) => e.byid !== undefined && e.byid == true)
        //         if (checkIMG.length > 0) {
        //             ole_file.push(checkIMG[0])
        //         }
        //     }
        // }


        let temp = {
            description: fileImport.remark,
            file: ole_file,
            spaceship_id: projectStateData.project_spaceship_id,
            project_id: id
        }
        if (byidchack === true) {
            temp.byid = true
        }
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updatevideo`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(function (response) {
                if (response.data) {
                    if (fileImport.file.length > 0 && !fileImport.file[0].byid) {
                        CheckAndCreateFolderBox1(response.data.uv_id, 'PROJECT KICK-OFF'); 
                    } else if (fileImport.file.length == 0) {
                        CheckAndCreateFolderBox1(response.data.uv_id, 'PROJECT KICK-OFF'); 
                    } else {
                        setErrorMessage({ ...errorMessage, message: 'Success', status: false });
                        toggle_modal_alertKick();
                        toggle_modal_modelvideo();
                        getProjectStateById();
                        setLoading(false);
                    };
                }

            })
            .catch(function (error) {
                console.log(error);
                setErrorMessage({ ...errorMessage, message: error.response.data.error[0].errorDis, status: true });

            });


    }

    async function CheckAndCreateFolderBox1(uv_id, typeList) {
        let temp = {
            folderLis: ["PROJECT", "PROJECT_" + id, 'PROJECT VIDEO', typeList, 'UV_' + uv_id]
        }
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    UploadFiles1(uv_id, response.data.folder_id, typeList);
                }
            })
            .catch(function (error) {
                // Swal.fire("Error", error.response.data.error[0].errorDis, "error");
                console.log(error);
            });
    }
    
    async function UploadFiles1(uv_id, folder_id, typeList) {
        let oldFileList_ = oldFileList.filter((e)=>{return e != null});
        let otherFileList_ = otherFileList.map(item => item.fd_img_id);
        let setOldFile = [...oldFileList_,...otherFileList_];
        const dataImg = new FormData();
        if (fileImport.file.length > 0) {
            dataImg.append('File', fileImport.file[0].image_file);
        }
        dataImg.append('folder_id', folder_id);
        dataImg.append('key', typeList);
        dataImg.append('image_old_list', JSON.stringify(setOldFile));
        dataImg.append('ref_id', uv_id);
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'multipart/form-data'
            },
            data: dataImg
        })
            .then(async function (response) {
                if (response.data) {
                    // Swal.fire({
                    //     icon: "success",
                    //     title: "Save",
                    //     showConfirmButton: false,
                    //     timer: 1500,
                    // }).then((result) => {
                    //     window.location.href = "/M-20240220226"
                    // });
                    setErrorMessage({ ...errorMessage, message: 'Success', status: false });
                    toggle_modal_alertKick();
                    toggle_modal_modelvideo();
                    getProjectStateById();
                    setLoading(false);
                }
            })
            .catch(function (error) {
                // Swal.fire("Error", error.response.data.error[0].errorDis, "error");
                console.log(error);
            });
    }

    async function CancelMemberWait(trgetUser, tabelid, spaceid) {

        var temp = {
            trgetUser: waitConfrim.ipt_usr_id,
            ipt_spaceship_id: dataAddMeber.space_id,
            ipt_id: waitConfrim.ipt_id,
            project_id: id,
            ipt_spaceship_id: waitConfrim.ipt_spaceship_id
        }

        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/cancelMemberWait`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(function (response) {
                socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + id });
                socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + waitConfrim.ipt_usr_id });

                getProjectStateById()
                /*         toggle_modal_AddMenberOn() */

            })
            .catch(function (error) {
                console.log(error);
                setErrorMessage({ ...errorMessage, message: error.response.data.error[0].errorDis, status: true });

            });

    }



    function getuserformation(usrid) {
        setLoading(true);
        let temp = {
            project_id: id,
            usr_id: usrid,
            spaceship_id: projectStateData.project_spaceship_id,
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/getuserformation`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                setFileImportList([]);
                setOtherFileList(response.data || []);

                let list = []
                let remark = ''
                let usridon = sessionStorage.getItem('user_id')
                if (usridon === usrid) {


                    setFileImport({
                        ...fileImport, file: list,
                        remark: remark,
                        mode: 'edit'
                    })
                    fileImport.mode = 'edit'



                } else {
                    if (response.data.length > 0) {
             
                    
                            for(let index_check = 0 ; index_check < response.data.length; index_check++){
                                if (response.data[index_check].fd_origin_name) {
                                const parts = response.data[index_check].fd_origin_name.split('.');
                                const extension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
                                const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']; 
                                const isNotImage = !imageExtensions.includes(extension);
                                
                                if(isNotImage ){
                                
                                    
                                    if (response.data[index_check].fd_img_id) {
                                        const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data[index_check].fd_img_id}/content`, {
                                            headers: {
                                            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                            },
                                            responseType: 'blob',
                                        });
                                    console.log("extension: ",extension,isNotImage);
                                    list.push({
                                        ul_file_path: URL.createObjectURL(response2.data),
                                        ul_file_name: response.data[index_check].fd_origin_name,
                                        name: response.data[index_check].fd_origin_name,
                                        path: URL.createObjectURL(response2.data),
                                        url: URL.createObjectURL(response2.data),
                                        is_save: false,
                                        ul_is_use: true,
                                        ul_file_size: 0,
                                        ul_type: 'video/mp4',
                                        ul_group_name: "kickoff",
                                        ul_menu_name: "kickoff",
                                        ul_is_profile: true,
                                        status: 'done',
                                        byid: true
                                    })
                                    setFileImportList([
                                        {
                                            uid: "rc-upload-1705392044222-3",
                                            lastModified: 1705387231365,
                                            lastModifiedDate: new Date(),
                                            name: response.data[index_check].fd_origin_name,
                                            size: 4918014,
                                            type: 'video/mp4',
                                            webkitRelativePath: ''
                                        }
                                    ])
                                    if (response.data[index_check].uv_description) {
                                        remark = response.data[index_check].uv_description;
                                    };

                                    setFileImport({
                                        ...fileImport, file: list,
                                        remark: remark,
                                        mode: 'view'
                                    })
                                    fileImport.mode = 'view'
                                }
                                }else{
                                    console.log("เข้า else มันเป็นรูป");
                                    
                                }
                            }
                                
                            }
                           
     
                        // }

                 
                    }
    
                }

                toggle_modal_modelvideo();
                setmodelvideo(true);
                setLoading(false);
                // if (response.data.file_list.length > 0) {
                //     if (response.data.file_list[0].fd_img_id) {
                //         const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.file_list[0].fd_img_id}/content`, {
                //             headers: {
                //               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                //             },
                //             responseType: 'blob',
                //         });

                //         list.push({
                //             ul_file_path: URL.createObjectURL(response2.data),
                //             ul_file_name: response.data.file_list[0].fd_origin_name,
                //             name: response.data.file_list[0].fd_origin_name,
                //             path: URL.createObjectURL(response2.data),
                //             url: URL.createObjectURL(response2.data),
                //             is_save: false,
                //             ul_is_use: true,
                //             ul_file_size: 0,
                //             ul_type: 'video/mp4',
                //             ul_group_name: "kickoff",
                //             ul_menu_name: "kickoff",
                //             ul_is_profile: true,
                //             status: 'done',
                //             byid: true
                //         })
                //         setFileImportList([
                //             {
                //                 uid: "rc-upload-1705392044222-3",
                //                 lastModified: 1705387231365,
                //                 lastModifiedDate: new Date(),
                //                 name: response.data.file_list[0].fd_origin_name,
                //                 size: 4918014,
                //                 type: 'video/mp4',
                //                 webkitRelativePath: ''
                //             }
                //         ])
                //     }
                // }

                // if (usridon === usrid) {


                //     setFileImport({
                //         ...fileImport, file: list,
                //         remark: remark,
                //         mode: 'edit'
                //     })
                //     fileImport.mode = 'edit'



                // } else {

                //     setFileImport({
                //         ...fileImport, file: list,
                //         remark: remark,
                //         mode: 'view'
                //     })
                //     fileImport.mode = 'view'
                // }


                // if (response.data.length > 0) {
                //     let data = response.data[0]
                //     remark = data.uv_description || ''
                //     if (data.uv_video_path !== null) {
                //         list.push({
                //             ul_file_path: data.uv_video_path,
                //             ul_file_name: data.uv_video_name,
                //             name: data.uv_video_name,
                //             path: data.uv_video_path,
                //             url: data.uv_video_path,
                //             is_save: false,
                //             ul_is_use: true,
                //             ul_file_size: 0,
                //             ul_type: 'video/mp4',
                //             ul_group_name: "kickoff",
                //             ul_menu_name: "kickoff",
                //             ul_is_profile: true,
                //             status: 'done',
                //             byid: true
                //         })
                //         setFileImportList([
                //             {
                //                 uid: "rc-upload-1705392044222-3",
                //                 lastModified: 1705387231365,
                //                 lastModifiedDate: new Date(),
                //                 name: data.uv_video_name,
                //                 size: 4918014,
                //                 type: 'video/mp4',
                //                 webkitRelativePath: ''
                //             }
                //         ])
                //     }

                // }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onRemoveFile = (info, index) => {
        // console.log("onRemove info = " + info);
        setFileImportList([])

        setFileImport({
            ...fileImport, file: []
        })
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#85BFFF',
                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#000',

                        zIndexPopupBase: 999999,
                        colorPrimaryBg: 'blue',//สีตอน Active ใน Select
                        colorBgBase: '#778899',//พื้นหลัง select ตอนกด down ลงมา

                        colorTextQuaternary: '#B0C4DE',// สีตัวหนังสือของ placeholder


                        colorFillTertiary: '#87CEFA',// สีตอน hover ใน Select



                    },

                    /*   backgroundColor:'#000' */
                }}
            >
                {modelvideo === true && modeTimeline == false ?
                    <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='cardvideo' style={{ width: '70%', height: '80%', paddingLeft: '20px', paddingRight: '20px', position: 'relative' }}>
                            <div className='row' style={{ margin: 0, padding: '0 5px', height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: '10' }}>

                            </div>
                            <div style={{ position: 'absolute', fontSize: '38px', fontFamily: 'Oxanium', color: '#ffffff', marginTop: '-20px' }}>UPLOAD VIDEO KICK-OFF PROJECT </div>
                            <div className='kickOffTimelineButton' onClick={() => {
                                toggle_modal_modelvideo();
                                toggle_modeTimeline();
                                filterVideoTimeline();
                            }}>
                                <svg height="calc(100% + 2px)" width="calc(100% + 2px)" style={{ position: 'absolute' }}>
                                    <line x1="0" y1="8px" x2="8px" y2="0" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                    <line x1="100%" y1="calc(100% - 11px)" x2="calc(100% - 11px)" y2="100%" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                </svg>
                                <img src={timelineIcon} style={{ height: '30px', padding: '0 0 0 10px' }}></img>
                                <div style={{ padding: '5px 10px' }} >VIDEO TIMELINE</div>
                            </div>

                            <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                                {fileImport.mode === 'edit' ? <>
                                    <Dragger {...props} onRemove={e => onRemoveFile(e)} fileList={fileImportList} beforeUpload={(file, fileList) => {
                                        // Access file content here and do something with it
                                        /* console.log(file,fileList); */
                                        setFileImportList([
                                            {
                                                uid: "rc-upload-1705392044222-3",
                                                lastModified: 1705387231365,
                                                lastModifiedDate: new Date(),
                                                name: file.name,
                                                size: 4918014,
                                                type: 'video/mp4',
                                                webkitRelativePath: ''
                                            }
                                        ])

                                        let datalist = [];/* fileImport.file.filter((e) => e.byid !== undefined && e.byid == true) */
                                        datalist.push({
                                            ul_file_path: URL.createObjectURL(file),
                                            ul_file_name: file.name,
                                            name: file.name,
                                            path: URL.createObjectURL(file),
                                            url: URL.createObjectURL(file),
                                            is_save: true,
                                            ul_is_use: true,
                                            ul_file_size: 0,
                                            ul_type: file.type,
                                            ul_group_name: "kickoff",
                                            ul_menu_name: "kickoff",
                                            ul_is_profile: true,
                                            status: 'done',
                                            image_file: file,
                                            is_img_upload: true
                                        })
                                        setFileImport({
                                            ...fileImport, file: datalist,
                                            newfile: true

                                        })
                                        /*   setFileImport(file); */

                                        // Prevent upload
                                        return false;
                                    }}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text" >Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint"  >
                                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                                            banned files.
                                        </p>
                                    </Dragger>
                                </>
                                    :
                                    <>
                                        <video width="640" height="360" controls>
                                            {fileImport.file.length > 0 ? <>
                                                <source src={`${fileImport.file[0].ul_file_path/* Configs.TTT_Game_Service_IMG + fileImport.file[0].ul_file_path */}`} type="video/mp4" />
                                            </> :
                                                <></>}


                                        </video>
                                    </>}




                            </div>
                            <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center', marginTop: '5%' }}>

                                <div className=" " style={{ textAlign: '-webkit-center', width: '55%', marginTop: '30px' }} >
                                    <div style={{ marginLeft: '-86%' }}>Description :</div>
                                    {fileImport.mode === 'edit' ? <>
                                        <textarea className="cardvideo-infoBoxTextIn-arex"
                                            id={'descriptionvideo'}
                                            type='text'

                                            value={fileImport.remark || ''}
                                            onChange={(e) => {
                                                autoResize('descriptionvideo')
                                                setFileImport({
                                                    ...fileImport, remark: e.target.value
                                                })
                                            }}
                                        >
                                        </textarea>
                                    </> :

                                        <div style={{ display: 'flex', justifyContent: 'start', height: '50%' }}>
                                            <div style={{ wordWrap: 'break-word', width: '100%', fontFamily: 'Oxanium', overflowY: 'auto', height: '100%' }}>{fileImport.remark}</div>

                                        </div>
                                    }
                                </div>

                            </div>
                            <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
                                {fileImport.mode === 'edit' ? <><button className=' btn-outline-primary mr-2' style={{ padding: '5px' }} onClick={() => { 
                                    // console.log('testlog file',fileImport);
                                    saveAddModule();
                                }}> Save </button> </> : <></>}   <button className=' btn-outline-danger' style={{ padding: '5px' }} onClick={() => {
                                    setFileImport({
                                        ...fileImport, remark: null, file: []
                                    })
                                    setFileImportList([])
                                    toggle_modal_modelvideo();
                                }}> Cancel </button>
                            </div>
                        </div>

                    </div>

                    :
                    <></>
                }

                {modelvideo == false && modeTimeline == true ?
                    <>
                        <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='cardvideo' style={{ width: '70%', height: '80%', padding: '20px', position: 'relative', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                                <div className='kickOffTimelineBoxHeader'>
                                    <div>VIDEO TIMELINE</div>
                                    <input 
                                        className='inputSearch' style={{ position: 'absolute',left: '69%',top: '4%'}}
                                        placeholder='search'
                                        type = 'text'
                                        onChange={(e)=>{
                                            filterVideoTimeline(e.target.value);
                                    }}
                                        >
                                    </input>
                                    <div className='kickOffTimelineButton' style={{ fontSize: '20px' }} onClick={() => {
                                        toggle_modal_modelvideo();
                                        toggle_modeTimeline();
                                    }}>
                                        
                                        <svg height="calc(100% + 2px)" width="calc(100% + 2px)" style={{ position: 'absolute' }}>
                                            <line x1="0" y1="8px" x2="8px" y2="0" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                            <line x1="100%" y1="calc(100% - 11px)" x2="calc(100% - 11px)" y2="100%" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                        </svg>
                                      
                                        <div style={{ padding: '5px 10px' }}>
                                            <img src={backIcon} style={{ height: '15px' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className='kickoffTimelineProject'>
                                    <div style={{ fontSize: '15px', fontWeight: 600, color: '#7BD7FF', textTransform: 'uppercase' }}>PROJECT :</div>
                                    <div style={{ fontSize: '30px', fontWeight: 600, color: '#7BD7FF', textTransform: 'uppercase' }}>{projectStateData.project_name}</div>
                                </div>

                                <div className='kickoffTimelineBody'>
                                    <div className='kickoffTimelineBodySet'>
                                        {projectTimelineDataResult.map((e, i) => {
                                            if (e.state == '1') {
                                                return <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                                        <div style={{ color: '#0047FF', fontSize: '16px', fontWeight: 600, width: '250px' }}>KICK-OFF</div>
                                                        <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                                        <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${stateKickoff})` }} >
                                                            <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                                                toggle_modeTimeline();
                                                                toggle_modeVideoView();
                                                                setVideoImport(e.fileId);
                                                                setTextVideo(e.textVideo);
                                                            }}></div>
                                                            <div style={{ color: '#0047FF', fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                                            <div style={{ color: '#0047FF', fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                                        </div>

                                                    </div>
                                                </>
                                            } else if (e.state == '2') {
                                                return <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                                        <div style={{ color: '#0047FF', fontSize: '16px', fontWeight: 600, width: '250px' }}>ON MISSION</div>
                                                        <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                                        <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${stateKickoff})` }} >
                                                            <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                                                toggle_modeTimeline();
                                                                toggle_modeVideoView();
                                                                setVideoImport(e.fileId);
                                                                setTextVideo(e.textVideo);
                                                            }}></div>
                                                            <div style={{ color: '#0047FF', fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                                            <div style={{ color: '#0047FF', fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                                        </div>

                                                    </div>
                                                </>
                                            } else {
                                                return <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                                        <div style={{ color: '#0047FF', fontSize: '16px', fontWeight: 600, width: '250px' }}>BACK TO THE EARTH</div>
                                                        <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                                        <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${stateKickoff})` }} >
                                                            <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                                                toggle_modeTimeline();
                                                                toggle_modeVideoView();
                                                                setVideoImport(e.fileId);
                                                                setTextVideo(e.textVideo);
                                                            }}></div>
                                                            <div style={{ color: '#0047FF', fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                                            <div style={{ color: '#0047FF', fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                        })}
                                    </div>
                                </div>
                                {/* <div style={{display: 'flex',flexDirection:'row'}}>
                                    <div style={{border: '1px solid red'}}>
                                        <input
                                        id = 'UploadVideo'
                                        type='file'
                                        accept = 'video/*,.mp4'
                                        onChange={(e)=>{ 
                                                        let temp = videoFile;
                                                        let temp_save = saveVideoFile;
                                                        for (let item of e.target.files){
                                                            let files = {
                                                                type: 'FILE',
                                                                file_name: item.name,
                                                                file: item,
                                                                path: URL.createObjectURL(item),
                                                                url: URL.createObjectURL(item),
                                                                is_chacge: true,
                                                                image_file: item,
                                                            }
                                                            temp.push(files);
                                                            temp_save.push(files) 
                                                        }
                                                        setSaveVideoFile(temp_save)
                                                        console.log('saveVideoFile......',saveVideoFile);
                                                        setVideoFile(temp)
                                                        setTest({ ...test, test: '' });
                                                        test.test = '';
                                                        console.log('LOG temp',temp);
                                                        console.log('LOG VIDEO FILE',videoFile);
                                                        UploadVideo()
                                                    }
                                                } 
                                        onClick={()=>{setSaveVideoFile([]);
                                            console.log('saveVideoFile......',saveVideoFile);
                                        }}
                                        />
                                    </div>
                                    <div style={{border: '1px solid red'}} 
                                    onClick={()=>{
                                        SaveVideoFile2Text(); 
                                    }}>
                                        SAVE
                                    </div>
                                </div>
                                    <div style={{border: '1px solid red'}}>
                                    {isLoading == true ? 'Loading...': sendData.text}
                                    </div> */}
                            </div>
                        </div>
                    </>
                    :
                    <></>}

                {modelvideo == false && modeTimeline == false && modeVideoView == true ?
                    <>
                        <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='cardvideo' style={{ width: '70%', height: '80%', padding: '20px', position: 'relative', display: 'flex', flexDirection: 'column', gap: '25px' }}>
                                <div className='kickOffTimelineBoxHeader'>
                                    <div>KICK-OFF VIDEO</div>
                                    <div className='kickOffTimelineButton' style={{ fontSize: '20px' }} onClick={() => {
                                        toggle_modeTimeline();
                                        toggle_modeVideoView();
                                        filterVideoTimeline(null)
                                    }}>
                                        <svg height="calc(100% + 2px)" width="calc(100% + 2px)" style={{ position: 'absolute' }}>
                                            <line x1="0" y1="8px" x2="8px" y2="0" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                            <line x1="100%" y1="calc(100% - 11px)" x2="calc(100% - 11px)" y2="100%" style={{ stroke: '#7BD7FF', strokeWidth: 2 }} />
                                        </svg>
                                        <div style={{ padding: '5px 10px' }}>
                                            <img src={backIcon} style={{ height: '15px' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className='kickoffTimelineVideoViewBody' style={{height:'90%'}}>
                                    <div className='kickoffTimelineVideoViewBox' style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        
                                            {/* {fileImport.file.length > 0 ? <>
                                                <source src={`${fileImport.file[0].ul_file_path Configs.TTT_Game_Service_IMG + fileImport.file[0].ul_file_path }`} type="video/mp4" />
                                            </> :
                                                <></>} */}
                                            {!fileVideoImport ?  <>Loading... </>
                                            :  <>
                                            <video width="65%" height="65%" controls>
                                            <source src={fileVideoImport} type="video/mp4" />
                                            </video>
                                            <div style={{marginRight:'92%'}}>Description</div>
                                            <div style={{border:'lightblue solid 1px', height:'25%',width:'100%', marginTop:'1%', overflow:'auto'}}>
                                                <div style={{padding:'10px 10px 0px 10px'}}>{textVideo}</div>
                                            </div>
                                            </>
                                            }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>}

                <LandingPagestage
                    display={'kickoff'}
                    setMenuValue={setMenuValue}
                    setCheckOnModal={setCheckOnModal}
                />
                <div className='kickOffBg' style={{ backgroundImage: `url(${bg})` }}></div>
                <div className='kickOffSet'>
                    <div className='kickOffSetLeft'>
                        <div className='kickOffProjectBox'>
                            <svg height="150" width="500" style={{ position: 'absolute' }}>
                                <line x1="350" y1="40" x2="314" y2="0" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="315" y1="1" x2="8" y2="1" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="9" y1="1" x2="1" y2="9" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="8" x2="1" y2="80" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="80" x2="25" y2="105" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                                <line x1="1" y1="85" x2="1" y2="105" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="0" y1="105" x2="20" y2="105" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>

                            <div className='kickOffProjectLine' style={{ width: '353px', height: '6px', top: '36px', clipPath: 'polygon(0 0, 98.5% 0%, 100% 100%, 0% 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '23.5px', height: '23.5px', top: '82.5px', left: '0px', clipPath: 'polygon(0 0, 95% 100%, 60% 100%, 0 40%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '320px', height: '6px', top: '100px', left: '19px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 1.5% 100%)' }}></div>

                            <div className='kickOffProjectLeftTop'></div>
                            <div className='kickOffProjectNameHead'>PROJECT NAME</div>
                            <div className='kickOffProjectName'>{projectStateData.project_name}</div>
                            <div className='kickOffProjectDate'>{projectStateData.pp_start_date != null ? moment(projectStateData.pp_start_date).format('DD/MM/YYYY') : ''} - {projectStateData.pp_finish_date != null ? moment(projectStateData.pp_finish_date).format('DD/MM/YYYY') : ''}</div>
                        </div>

                        <div className='kickOffItemBox'>
                            <div className='kickOffBudget'>
                                <div className='kickOffBudgetHead' style={{ backgroundColor: '#A9A219' }}>BUDGET</div>
                                <img className='kickOffBudgetLogo' src={budgetLogo}></img>
                                <div className='kickOffBudgetCount'>{numberWithCommas(projectStateData.project_budget || 0)}</div>
                            </div>

                            <div className='kickOffMemberCount'>
                                <div className='kickOffBudgetHead' style={{ backgroundColor: '#2352AC', marginBottom: '5px' }}>MEMBERS</div>
                                {/* <div className='kickOffMemberCountBox'> */}
                                <img className='kickOffMemberCountLogo' src={MemberCountLogo}></img>
                                <div className='kickOffMemberCountCount'>x {memberCount || 0}</div>
                                {/* </div> */}
                            </div>
                        </div>

                        <div className='kickOffTimeBox'>
                            <div className='kickOffProjectLine' style={{ width: '400px', height: '187px', top: '10px', left: '0px', clipPath: 'polygon(0 0, 90% 0%, 100% 16%, 100% 100%, 9% 100%, 0% 82%)', backgroundColor: 'rgba(0,0,0,0.8)' }}></div>
                            <div className='kickOffCurrentTimeText'>CURRENT TIME</div>
                            <div className='kickOffTimeText'>{setCurrentTime()}</div>
                            <div className='kickOffDateText'>{projectStateData.pp_finish_date != null ? moment(projectStateData.pp_finish_date).format('DD MMMM YYYY') : ''}</div>

                            <svg height="196" width="400" style={{ position: 'absolute', marginTop: '-143px' }}>
                                <line x1="1" y1="1" x2="370" y2="1" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="10" x2="360" y2="10" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="360" y1="10" x2="400" y2="40" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="10" x2="1" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="50" x2="230" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="230" y1="50" x2="240" y2="40" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="240" y1="40" x2="240" y2="20" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                                <line x1="1" y1="195" x2="1" y2="170" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="1" y1="195" x2="30" y2="195" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                                <line x1="1" y1="163" x2="37" y2="195" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="37" y1="195" x2="400" y2="195" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                                <line x1="385" y1="195" x2="385" y2="153" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="385" y1="154" x2="360" y2="130" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                                <line x1="385" y1="142" x2="385" y2="121" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="385" y1="122" x2="360" y2="122" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div className='kickOffProjectLine' style={{ width: '14px', height: '10px', top: '1px', left: '386px', clipPath: 'polygon(0 0, 100% 100%, 100% 0)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '38px', height: '30px', top: '1px', left: '362px', clipPath: 'polygon(0 0, 50% 0, 100% 50%, 100% 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '152px', height: '15px', top: '20px', left: '239px', clipPath: 'polygon(0 0, 89% 0, 100% 100%, 0 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '5px', height: '160px', top: '37px', left: '396px', clipPath: 'polygon(0 0, 100% 4%, 100% 100%, 0 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '15px', height: '125px', top: '51px', left: '0px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 90%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '338px', height: '10px', top: '122.5px', left: '25px', clipPath: 'polygon(0 0, 97.5% 0, 100% 100%, 0 90%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '27px', height: '27px', top: '123.5px', left: '359px', clipPath: 'polygon(0 0, 40% 0, 100% 60%, 100% 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '150px', height: '15px', top: '181px', left: '20px', clipPath: 'polygon(0 0, 90% 0, 100% 100%, 10% 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '130px', height: '15px', top: '181px', left: '270px', clipPath: 'polygon(10% 0, 100% 0, 100% 100%, 0% 100%)' }}></div>
                            <div className='kickOffProjectLine' style={{ width: '32px', height: '27px', top: '170px', left: '0px', clipPath: 'polygon(0 0, 100% 100%, 70% 100%, 0 30%)' }}></div>

                        </div>
                    </div>
                    {/* fb902c5e-d5c1-46b9-91a5-91aa8172925c On Space */}
                    <div className='kickOffSetCenter' style={{ visibility: `${projectStateData.project_pros_id ? 'visible' : 'hidden'}` }}>
                        {projectStateData.project_pros_id == 'fb902c5e-d5c1-46b9-91a5-91aa8172925c' || projectStateData.project_pros_id == '4d45f176-a656-49d9-89dd-69ccb7422274' || projectStateData.project_pros_id == '8598c755-de86-4a8c-b661-859e453547bc' || projectStateData.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? (<>
                            {/* <div className='kickOffReadyButton' style={{ display: `${projectStateData.project_pros_id == 'fb902c5e-d5c1-46b9-91a5-91aa8172925c' ? '' : 'none'}`, width: 'fit-content',border: '5px solid #0047FF', color: '#0047FF', padding: '0 10px',cursor: 'default' }}>ON SPACE !</div> */}
                            <div
                                className='kickOffReadyButton'
                                style={{ backgroundImage: `url(${projectStateData.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? finishBlue : onSpaceBlue})`, cursor: 'default' }}
                                onClick={() => {
                                    console.log("On Space");
                                }}

                            >



                            </div>
                        </>) : (<>
                            {/* <div className='kickOffReadyButton' style={{ border: `5px solid ${currentUser.isReady ? '#1FFF1B' : 'red'}`, color: `${currentUser.isReady ? '#1FFF1B' : 'red'}` }} onClick={() => {
                                if (allReady && currentUser.su_is_leader && projectStateData.project_pros_id === '20df99c6-a4b1-4f08-8fe3-52a6f33eea6b') { //20df99c6-a4b1-4f08-8fe3-52a6f33eea6b คือ สถานะ READY ของ PROJECT
                                    sendProjectStart()
                                } else {
                                    sendProjectReady()
                                }
                            }}>{allReady && currentUser.su_is_leader && projectStateData.project_pros_id === '20df99c6-a4b1-4f08-8fe3-52a6f33eea6b' ? 'START' : 'READY'}</div> */}
                            {projectStateData.member && projectStateData.member.length > 1 ? <>
                                {allReady && currentUser.su_is_leader && projectStateData.project_pros_id == '20df99c6-a4b1-4f08-8fe3-52a6f33eea6b' ? (<>
                                    <div className='kickOffReadyButton' style={{ backgroundImage: `url(${startGreen})` }} onClick={() => {
                                        sendProjectStart()
                                    }}></div>
                                </>) : (<>
                                    {(projectStateData.member && projectStateData.member.filter((e) => { return e.usr_id == sessionStorage.getItem('user_id') }).length > 0) ? <div className='kickOffReadyButton' style={{ backgroundImage: `url(${currentUser.isReady ? readyGreen : readyRed})` }} onClick={() => {
                                        sendProjectReady()
                                    }}></div> : <></>}
                                </>)}
                            </> : <>
                                <div className='kickOffSetOneUserInSpace'>
                                    {allReady && currentUser.su_is_leader && projectStateData.project_pros_id == '20df99c6-a4b1-4f08-8fe3-52a6f33eea6b' && <div className='kickOffReadyButton' style={{ backgroundImage: `url(${startGreen})` }} onClick={() => {
                                        sendProjectStart()
                                    }}></div>}

                                    {(projectStateData.member && projectStateData.member.filter((e) => { return e.usr_id == sessionStorage.getItem('user_id') }).length > 0) ? <div className='kickOffReadyButton' style={{ backgroundImage: `url(${currentUser.isReady ? readyGreen : readyRed})` }} onClick={() => {
                                        sendProjectReady()
                                    }}></div> : <></>}
                                </div>
                            </>}

                        </>)}
                    </div>




                    <div className='kickOffSetRight'>
                        <div className='kickOffSpaceNameBox'>
                            <div className='kickOffSpaceNameBoxIn'>
                                <div className='kickOffSpaceName'>{projectStateData.spaceship_name}</div>
                            </div>
                            <div className='kickOffSpaceNameLine'></div>
                        </div>
                        <div className='kickOffMemberList'>
                            <div className='kickOffMemberListIn'>
                                <div className='kickOffMemberListBox'>
                                    <div className='kickOffMemberListBoxIn'/*  style={{border:'1px solid red'}}  */>
                                        {projectStateData.member && projectStateData.member.map((e, index) => {
                                            if (e.is_member) {
                                                return (
                                                    <>
                                                        <img className='kickOffMemberImg' style={{ bobjectFit: 'contain' }} src={e.ul_file_path !== null ? `${e.ul_file_path}` : profileImg}>
                                                        </img>
                                                        <div className='kickOffMemberBox' style={{ border: `2px solid ${e.isReady ? '#1FFF1B' : '#0047FF'}` }}>
                                                            <div className='kickOffMemberBoxHeadBox' style={{ backgroundColor: `${e.isReady ? '#1FFF1B' : '#0047FF'}` }}>
                                                                <div className='kickOffMemberBoxHeadBoxIn' style={{ backgroundColor: `${e.isReady ? '#00AB11' : '#5E9BE1'}` }}>{e.isReady ? 'Ready' : 'Waiting'}</div>
                                                            </div>
                                                            <svg height="25" width="150" style={{ position: 'absolute', marginTop: '-20px' }}>
                                                                <line x1="95" y1="5" x2="135" y2="5" style={{ stroke: `${e.isReady ? '#1FFF1B' : '#0047FF'}`, strokeWidth: '2' }} />
                                                                <line x1="135" y1="5" x2="135" y2="15" style={{ stroke: `${e.isReady ? '#1FFF1B' : '#0047FF'}`, strokeWidth: '2' }} />
                                                            </svg>
                                                            <Tooltip title={e.usr_name} color='blue' ><div className='kickOffMemberName' style={{ backgroundColor: `${e.isReady ? '#00AB11' : '#5E9BE1'}`, borderTop: `2px solid ${e.isReady ? '#1FFF1B' : '#0047FF'}` }}>
                                                                {e.usr_name}
                                                            </div></Tooltip>
                                                            <div style={{ position: 'relative', width: '20px', height: '20px', left: '80%', top: '-99%', cursor: 'pointer' }} onClick={() => {
                                                                getuserformation(e.usr_id)
                                                            }}>
                                                                <i class="fa fa-camera" aria-hidden="true" style={{ color: `${e.isVdo ? 'green' : 'red'}` }}></i>
                                                            </div>
                                                        </div>

                                                    </>
                                                )
                                            }

                                        })}
                                        {projectStateData.memberWaitForAccpt && projectStateData.memberWaitForAccpt.map((e, index) => {
                                            // console.log('TOOOOOO', e);
                                            return (
                                                <>
                                                    <img className='kickOffMemberImg' style={{ bobjectFit: 'contain' }} src={e.ul_file_path !== null ? `${e.ul_file_path}` : profileImg}>
                                                    </img>
                                                    <div className='kickOffMemberBox' style={{ border: `2px solid ${'#F3A250'}` }}>
                                                        <div className='kickOffMemberBoxHeadBox' style={{ backgroundColor: `${'#F3A250'}` }}>
                                                            <div className='kickOffMemberBoxHeadBoxIn' style={{ backgroundColor: `${'#F3A250'}` }}>{'Waiting'}</div>
                                                        </div>
                                                        <svg height="25" width="150" style={{ position: 'absolute', marginTop: '-20px' }}>
                                                            <line x1="95" y1="5" x2="135" y2="5" style={{ stroke: `${'#F3A250'}`, strokeWidth: '2' }} />
                                                            <line x1="135" y1="5" x2="135" y2="15" style={{ stroke: `${'#F3A250'}`, strokeWidth: '2' }} />
                                                        </svg>
                                                        <div className='kickOffMemberName' style={{ backgroundColor: `${'#F3A250'}`, borderTop: `2px solid ${'#F3A250'}` }}>
                                                            {e.usr_name}
                                                        </div>
                                                        <div style={{ position: 'relative', width: '20px', height: '20px', left: '80%', top: '-99%', cursor: 'pointer' }} onClick={() => {
                                                            getuserformation(e.usr_id)
                                                        }}>
                                                            <i class="fa fa-camera" aria-hidden="true" style={{ color: `${e.isVdo ? 'green' : 'red'}` }}></i>
                                                        </div>

                                                        {e.ipt_is_success == false ? <div className='testDonhave' style={{ position: 'absolute', color: 'rgb(255,0,0)', left: '0%', top: '62%', fontFamily: 'Bebas Neue', fontSize: '18px', width: '100%', padding: '0px 2px 0px 5px', fontWeight: '500' }}>
                                                            {/*  leader  */}doesn't have enough Sapphire
                                                        </div> : <></>}


                                                        {isLeaderSpace == true ?
                                                            <div /* className='' */ style={{ height: '21px', backgroundColor: 'red', position: 'absolute', width: '42px', fontSize: '14px', textAlign: 'center', color: '#FFFFF', left: '68%', top: '76%', border: '1px solid red', cursor: 'pointer', fontFamily: 'Bebas Neue', borderRadius: '6px', fontWeight: '500' }}
                                                                onClick={() => {
                                                                    setWaitCofrim({
                                                                        ...waitConfrim,
                                                                        ipt_usr_id: e.ipt_usr_id,
                                                                        ipt_id: e.ipt_id,
                                                                        ipt_spaceship_id: e.ipt_spaceship_id,
                                                                    })
                                                                    waitConfrim.ipt_usr_id = e.ipt_usr_id;
                                                                    waitConfrim.ipt_id = e.ipt_id;
                                                                    waitConfrim.ipt_spaceship_id = e.ipt_spaceship_id;

                                                                    toggle_modal_confrimModal2()
                                                                    /*   CancelMemberWait(e.ipt_usr_id,e.ipt_id,e.ipt_spaceship_id) */
                                                                }}
                                                            >
                                                                CANCEL
                                                            </div>
                                                            : <></>}

                                                    </div>

                                                </>
                                            )


                                        })}
                                        {projectStateData.project_pros_id == "fb902c5e-d5c1-46b9-91a5-91aa8172925c" && isLeaderSpace == true && countMenberOnpace !== true ? <>
                                            <img className='kickOffMemberImgAddBox' src={BoxAddOnSpace} onClick={() => {
                                                /*  toggle_modal_confrimModal(); */
                                                toggle_modal_AddMenberOn();
                                            }}>
                                            </img>
                                        </> : <></>}

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='kickOffMemberBottomSet'>
                            <div className='kickOffMemberBottom'>
                                <div className='kickOffMemberBottomIn'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ComponentAddMenberOnSpace
                    isOpen={addMenberOnSpaceModal}
                    toggle_modal_AddMenberOn={() => { toggle_modal_AddMenberOn() }}
                    dataAddMeber={dataAddMeber}
                    getProjectStateById={() => { getProjectStateById() }}
                    dataWit={projectStateData}
                />


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                    <div className='alertKickBoxShadow' >
                        <div className='alertKickBox' style={{ height: 'fit-content' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'red' : 'green'}` }}>
                                        {errorMessage.status ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMessage.status ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        {errorMessage.message}

                                    </div>
                                </div>


                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick()
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className='alertKick' style={{ display: `${confrimModal2 ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox'>
                            <div className='alertKickBoxIn'>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: 'red' /* ,textDecorationLine:'underline' */ }}>
                                        ! warning !
                                    </div>
                                </div>
                                <div className='row' style={{ height: '5.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning' >

                                    </div>
                                </div>
                                <div className='row' style={{ height: '8.5%' }}>
                                    <div className='alertKickText'>
                                        ! warning !
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ height: '28%',/* border:'1px solid red', */fontSize: '12px' }}>
                                    <div className='alertKickTextSec'>
                                        Do you want to confirm and cancel the invitation?
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-2' >
                                    <button className='alertKickConfirm mr-2' onClick={() => {
                                        toggle_modal_confrimModal2();
                                        CancelMemberWait()
                                        /*   CheckBeforSaveData() */
                                        /*  toggle_modal_AddMenberOn(); */
                                    }}>YES</button>

                                    <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                        /*  toggle_confrim() */
                                        toggle_modal_confrimModal2()
                                    }}>NO</button>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>





                <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox'>
                            <div className='alertKickBoxIn'>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: 'red' /* ,textDecorationLine:'underline' */ }}>
                                        ! warning !
                                    </div>
                                </div>
                                <div className='row' style={{ height: '5.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning' >

                                    </div>
                                </div>
                                <div className='row' style={{ height: '8.5%' }}>
                                    <div className='alertKickText'>
                                        ! Everything comes with a price !
                                    </div>
                                </div>
                                <div className='row mt-2' style={{ height: '28%',/* border:'1px solid red', */fontSize: '12px' }}>
                                    <div className='alertKickTextSec'>
                                        You can add members, but you have to pay 10,000 Sapphire.
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-2' >
                                    <button className='alertKickConfirm mr-2' onClick={() => {
                                        toggle_modal_confrimModal();
                                        /*   toggle_modal_AddMenberOn(); */
                                    }}>YES</button>

                                    <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                        /*  toggle_confrim() */
                                        toggle_modal_confrimModal()
                                    }}>NO</button>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ConfigProvider>

            {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    )
}

export default KickOffProject
