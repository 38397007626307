import React, { useState, useEffect, useRef } from 'react';
import './myfeedback.css'
import axios from "axios";
import Configs from "../../../../config";
import moment from "moment";
/* import editIcon from "./Image/editIcon.png"; */
/* import timesheetIcon from "./Image/timesheetIcon.png"; */
import AlertConfrimDelete from "../../../../components/AlertMassge/AlertConfrimDelete";
import AlertMassge from "../../../../components/AlertMassge/AlertMassge";
import AlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import load from '../../../../assets/img/icon/load.png'
import { Select, DatePicker, Input, ConfigProvider, Tooltip, Tag } from 'antd';
import {
    CalendarOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
const { TextArea } = Input;

// import load from '../../../assets/img/icon/load.png'
const { RangePicker } = DatePicker;

const Timesheet = ({ isOpen, check }) => {

    const [test, setTest] = useState({
        test: ''
    });
    const [fileUploadList, setFileUploadList] = useState([]);
    const [feedBackData, setfeedBackData] = useState([]);

    const [alertMsgDelete, setAlertMsgDelete] = useState(false);
    const toggle_alertMsgDelete = () => { setAlertMsgDelete(!alertMsgDelete) };
    const [alertMsgEditMsg, setAlertMsgEditMsg] = useState({
        menuTitle: 'ERROR',
        isError: true,
        message: 'Please fill out the information completely.'
    });
    const [loading, setLoading] = useState(false);
    const [alertMsgEdit, setAlertMsgEdit] = useState(false);
    const toggle_alertMsgEdit = () => { setAlertMsgEdit(!alertMsgEdit) }
    const [fileList, setFileList] = useState([]);
    const [alertMsgConfirm, setAlertMsgConfirm] = useState(false);
    const toggle_alertMsgConfirm = () => { setAlertMsgConfirm(!alertMsgConfirm) }
    const [feedbackTemp, setfeedbackTemp] = useState({
        fb_title: '',
        fb_description: '',
    })
    const [ViewfeedbackAdminTemp, setViewfeedbackAdminTemp] = useState({
        fb_title: '',
        fb_description: '',
        fb_code: '',
        fs_name: '',
        fileupload: []
    })

    const [alertSaveConfirm, setAlertSaveConfirm] = useState(false);
    const toggle_alertSaveConfirm = () => { setAlertSaveConfirm(!alertSaveConfirm) };

    const [viewFeedbackAdmin, setviewFeedbackAdmin] = useState(false);
    const toggle_viewFeedbackAdmin = () => { setviewFeedbackAdmin(!viewFeedbackAdmin) };

    const [descIsNull, setDescIsNull] = useState(false);
    const toggle_descIsNull = () => { setDescIsNull(!descIsNull) };
    const [timeIsNull, setTimeIsNull] = useState(false);
    const toggle_timeIsNull = () => { setTimeIsNull(!timeIsNull) };
    const [descIsNullEdit, setDescIsNullEdit] = useState(false);
    const toggle_descIsNullEdit = () => { setDescIsNullEdit(!descIsNullEdit) };
    const [timeIsNullEdit, setTimeIsNullEdit] = useState(false);
    const toggle_timeIsNullEdit = () => { setTimeIsNullEdit(!timeIsNullEdit) };







    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }




    useEffect(() => {
        if (isOpen && check == 'MYFEEDBACK') {
            setfeedbackTemp({
                ...feedbackTemp,
                fb_title: '',
                fb_description: '',
            });
            feedbackTemp.fb_title = '';
            feedbackTemp.fb_description = '';
            setFileList([]);

            setTest({ ...test, test: '' });
            test.test = '';

            GetAccesstokenToken(async (result) => {
                if (result) {
                    GetFeedbackByID();
                }
            })

        }
    }, [check]);




    async function CheckAndCreateFolderBox(user_id, folder_name, fb_id, callback) {
        let temp = {
            folderLis: ["USER", `USER_${user_id}`, "MY FEEDBACK"]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: fb_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        callback(res)
                    }
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function UploadFiles(folder_id, key, ref_id, file, callback) {
        let image_id_list = fileUploadList;
        let data_ = new FormData();

        file.forEach((item, index) => {
            if (item.is_chacge) {
                if (item.image_file) {
                    data_.append('File', item.image_file);
                }
            }
        });
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);
        data_.append('folder_id', folder_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                setTest({ ...test, test: '' });
                test.test = '';
                if (callback) {
                    callback(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function isFileDoc(file) {
        const acceptedImageTypes = [
            'image/gif',
            'image/jpeg',
            'image/png',
            /*   'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation', */
        ];
        let ll = []
        for (let item of file) {
            let x = item.type;
            let value = acceptedImageTypes.includes(x);
            if (value === false) {
                return false
            } else {
                ll.push(value)
            }
            console.log('เช็ต Type', value);
        }
        return true;
    }





    function delfile(index) {
        let oleFile = fileList;
        oleFile.splice(index, 1);
        setFileList(oleFile);
        setTest({ ...test, test });
        test.test = '';
    }

    async function saveDocment(value) {
        setLoading(true);
        var temp = {
            fb_title: (feedbackTemp.fb_title || '').trim(),
            fb_description: (feedbackTemp.fb_description || '').trim(),
            fileDoc: []
        }



        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/feedback/saveFeedBack`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                if (response.data) {

                    await CheckAndCreateFolderBox(response.data.user_id, "MY FEEDBACK", response.data.id, async (result) => {
                        if (result) {
                            await UploadFiles(result.folder_id, result.folder_name, result.ref_id, fileList, async (result2) => {
                                setfeedbackTemp({
                                    ...feedbackTemp,
                                    fb_title: '',
                                    fb_description: '',
                                });
                                feedbackTemp.fb_title = '';
                                feedbackTemp.fb_description = '';
                                setFileList([]);

                                setTest({ ...test, test: '' });
                                test.test = '';
                                GetFeedbackByID();
                                setLoading(false);
                            })
                        }
                    })


                    /*   setfeedbackTemp({
                          ...feedbackTemp,
                          fb_title: '',
                          fb_description: '',
                      });
                      feedbackTemp.fb_title = '';
                      feedbackTemp.fb_description = '';
                      setFileList([]);
      
                      setTest({ ...test, test: '' });
                      test.test = '';
                      GetFeedbackByID(); */
                }

            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });



        /*  if (fileList.length > 0) {
             const dataImg = new FormData();
             console.log('fileList', fileList);
             for (let i = 0; i < fileList.length; i++) {
                 dataImg.append('Profile', fileList[i].image_file);
             }
             dataImg.append('typeFile', "FeedbackFile");
             dataImg.append('projectKey', "TTTSPACESHIP");
 
             await axios({
                 method: 'post',
                 url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                 headers: {
                     'Authorization': sessionStorage.getItem('access_token'),
                     'X-TTT': `${Configs.API_TTT}`,
                     'Content-Type': 'multipart/form-data',
                 },
                 data: dataImg,
             })
                 .then(async function (response) {
 
                     let ole_file = [];
 
                     if (response.data.data.length > 0) {
 
                         response.data.data.forEach((e) => {
                             const tmp = {
                                 name: e.orgin_name,
                                 path: e.path,
                                 is_save: true,
                                 ul_is_use: true,
                                 ul_file_size: e.size,
                                 url: e.path,
                                 ul_type: "FeedbackFile",
                                 ul_group_name: "FeedbackFile",
                                 ul_file_name: e.orgin_name,
                                 ul_file_path: e.path,
                                 ul_menu_name: "FeedbackFile",
                                 ul_is_profile: true,
                                 status: 'done'
 
                             }
                             ole_file.push(tmp);
                         })
                     }
 
                     SaveFeedback(ole_file);;
 
                 })
                 .catch(function (error) {
                     console.log(error);
                     let face = [];
                     SaveFeedback(face);
                 });
         } else {
             let face = [];
             SaveFeedback(face);
         } */



    }
    async function SaveFeedback(getPateFile) {

        var temp = {
            fb_title: (feedbackTemp.fb_title || '').trim(),
            fb_description: (feedbackTemp.fb_description || '').trim(),
            fileDoc: getPateFile || []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/feedback/saveFeedBack`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                setfeedbackTemp({
                    ...feedbackTemp,
                    fb_title: '',
                    fb_description: '',
                });
                feedbackTemp.fb_title = '';
                feedbackTemp.fb_description = '';
                setFileList([]);

                setTest({ ...test, test: '' });
                test.test = '';
                GetFeedbackByID();
            })
            .catch(function (error) {
                console.log(error);
            });


    }



    async function setImgBox(value) {
        let final = [];
        for (let item of value) {
            const response2 = await axios.get(`https://api.box.com/2.0/files/${item.ul_file_path}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });
            final.push({
                fd_img_id: item.ul_file_path,
                img: URL.createObjectURL(response2.data)
            })
        }


        return final;
    }

    async function GetFeedbackByID() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/feedback/getFeedbackByID/${sessionStorage.getItem('user_id')}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                setfeedBackData(response.data);
                /*       for(let item of response.data){
                          item.fileShow = [];
                          if(item.fileupload.length > 0){
                              let getFoleImg = await setImgBox(item.fileupload);
                              item.fileShow = getFoleImg
                          }
                          setfeedBackData(response.data);
                      } */

                setTest({ ...test, test: '' });
                test.test = '';

            })
            .catch(function (error) {
                console.log(error);
            });


    }
    async function ViewDetailFeedbackAdmin(value) {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/feedback/getFeedbackByAdminID/${value}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                if (response.data.length > 0) {
                    setViewfeedbackAdminTemp({
                        ...ViewfeedbackAdminTemp,
                        fb_title: response.data[0].fb_title,
                        fb_description: response.data[0].fb_description,
                        fb_code: response.data[0].fb_code,
                        fs_name: response.data[0].fs_name,
                        fileupload: response.data[0].fileupload,

                    });
                    ViewfeedbackAdminTemp.fb_title = response.data[0].fb_title || null;
                    ViewfeedbackAdminTemp.fb_description = response.data[0].fb_description || null;
                    ViewfeedbackAdminTemp.fb_code = response.data[0].fb_code || null;
                    ViewfeedbackAdminTemp.fs_name = response.data[0].fs_name || null;
                    ViewfeedbackAdminTemp.fileupload = response.data[0].fileupload || null;

                } else {
                    setViewfeedbackAdminTemp({
                        ...ViewfeedbackAdminTemp,
                        fb_title: '',
                        fb_description: '',
                        fb_code: '',
                        fs_name: '',
                        fileupload: [],

                    });
                    ViewfeedbackAdminTemp.fb_title = '';
                    ViewfeedbackAdminTemp.fb_description = '';
                    ViewfeedbackAdminTemp.fb_code = '';
                    ViewfeedbackAdminTemp.fs_name = '';
                    ViewfeedbackAdminTemp.fileupload = [];
                }

                toggle_viewFeedbackAdmin();
            })
            .catch(function (error) {
                console.log(error);
            });


    }


    const downloadFile = async (url, filename) => {
        console.log(Configs.TTT_Game_Service_IMG + url);
        try {
            /*     const response = await fetch(`${Configs.TTT_Game_Service_IMG + url}`);
                const blob = await response.blob();
                const urlBlob = window.URL.createObjectURL(blob); */
            const response2 = await axios.get(`https://api.box.com/2.0/files/${url}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });
            const urlBlob = window.URL.createObjectURL(response2.data);
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('Error downloading file', error);
        }
    };

    const downloadAllFiles = async (fileList) => {
        try {
            await Promise.all(fileList.map(file => downloadFile(file.ul_file_path, file.ul_file_name)));
            setLoading(false);
            console.log('All files downloaded');
        } catch (error) {
            console.error('Error downloading files', error);
        }
    };




    return (
        (check === 'MYFEEDBACK' ? (<>
            <div className="myfeedbackbox" style={{ visibility: `${isOpen === true && check === 'MYFEEDBACK' ? 'visible' : 'hidden'}` }}>
                <div className='lb-1'>
                    <div className='tableFeedHeader'>
                        <div style={{ width: '5%', textAlign: 'center' }}>NO</div>
                        <div style={{ width: '13%', textAlign: 'center' }}>Feedback Code</div>
                        <div style={{ width: '17%', textAlign: 'center' }}>Title</div>
                        <div style={{ width: '30%', textAlign: 'center' }}>Description</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Patch code</div>
                        <div style={{ width: '15%', textAlign: 'center' }}>Status</div>
                        <div style={{ width: '5%', textAlign: 'center' }}>Doc.</div>
                    </div>

                    <div className='tableBody' style={{/* border:'1px solid blue', */height: '93%', padding: '3px' }}>
                        {feedBackData && feedBackData.map((e, i) => {

                            return <>
                                <div className='timesheetBox'>
                                    <div className='timesheetBoxSet' style={{ width: '5%', textAlign: 'center' }}>{i + 1}</div>
                                    <Tooltip placement="topLeft" title={e.fb_code} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '13%', textAlign: 'center' }}>{e.fb_code}</div>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={e.fb_title} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '17%', textAlign: 'center' }}>{e.fb_title}</div>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={e.fb_description} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '30%', textAlign: 'center' }}>{e.fb_description}</div>
                                    </Tooltip>

                                    <div className='statusBoxSet' style={{ width: '15%', textAlign: 'center', textDecoration: "underline", cursor: 'pointer' }}>
                                        {e.relationadmin && e.relationadmin.map((fe) => {

                                            return <div className='row'>
                                                <div className='col-12' onClick={() => {
                                                    ViewDetailFeedbackAdmin(fe.parent_fb_id)
                                                }}>
                                                    {fe.fb_code}
                                                </div>
                                            </div>
                                        })}

                                    </div>
                                    {/* style={{color:``}} */}
                                    <div className='statusBoxSet' style={{ width: '15%', textAlign: 'center' }}>
                                        {e.relationadmin && e.relationadmin.length > 0 ? e.relationadmin.map((fe) => {

                                            return <div className='row'>
                                                <div className='col-12 mt-1'>
                                                    <Tag color={`${fe.fb_fs_name == 'in process' ? '#108ee9' : '#87d068'}`}> {fe.fb_fs_name} </Tag>
                                                </div>
                                            </div>
                                        }) : <div className='row'>
                                            <div className='col-12'>
                                                <Tag color={`#f50`} > {e.fs_name} </Tag>

                                            </div>
                                        </div>}

                                    </div>
                                    <div className='timesheetBoxSet' style={{ width: '5%', textAlign: 'left', cursor: `${e.fileupload.length > 0 ? 'pointer' : 'no-drop'}` }}>
                                        <button style={{ marginLeft: ' 10px', cursor: `${e.fileupload.length > 0 ? 'pointer' : 'no-drop'}` }} type="button" className='btn-dowloaddoc'
                                            onClick={() => {
                                                if (e.fileupload.length > 0) {
                                                    setLoading(true);
                                                    downloadAllFiles(e.fileupload)
                                                }
                                            }}
                                        >
                                            <img
                                                src={load}
                                                alt="description"
                                            />
                                        </button>
                                    </div>


                                </div>
                            </>

                        })}
                    </div>

                </div>
                <div className='lb-2'>
                    <div className='underInput'>
                        <div className='decboxInput'>
                            <div style={{ color: '#FFFF' }}>Title</div>
                            <TextArea
                                className='decInput'
                                autoSize={{
                                    minRows: 1
                                }}

                                value={feedbackTemp.fb_title}
                                style={{ textAlign: 'start', border: `1px solid #0047FF` }}
                                onChange={(e) => {
                                    setfeedbackTemp({
                                        ...feedbackTemp,
                                        fb_title: e.target.value
                                    });

                                }}
                            />
                        </div>
                    </div>
                    <div className='underInput'>
                        <div className='decboxInput'>
                            <div style={{ color: '#FFFF'/* ,border:'1px solid red'  */ }}>Description</div>
                            <TextArea
                                className='decInput'
                                styles={{ overflow: 'auto' }}
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 5
                                }}

                                value={feedbackTemp.fb_description}
                                style={{ textAlign: 'start', border: `1px solid #0047FF` }}
                                onChange={(e) => {
                                    setfeedbackTemp({
                                        ...feedbackTemp,
                                        fb_description: e.target.value
                                    });

                                }}
                            />
                        </div>
                    </div>
                    <div className='underInput mt-3' style={{ display: 'flex', justifyContent: 'center', gap: '10px'}} >
                        <label for="upload-btn-multiple-feedback" style={{ height: '34px', width: '100%', margin: '0px', padding: '0px', display: 'flex', gap: '12px',fontFamily:'Poppins',fontWeight: '700', fontSize: '18px' }}
                            type="button" className='pi-picSend' ><i class="fa fa-image mt-1" aria-hidden="true" style={{ /* display: 'flex', gap: '5px' */ marginBottom:'3px' }} onClick={() => {
                                let fileInput = document.getElementById('upload-btn-multiple-feedback')
                                console.log('fileInput', fileInput);
                            }} >
                                </i>Upload</label>
                        <div className='timesheetBoxMgt' style={{ width: '40%', textAlign: 'center' }}>
                            <div className='myFeedbackButtonSave' style={{ width: '100%' }}
                                onClick={() => {
                                    console.log('asdasdasd');
                                    if (feedbackTemp.fb_title == '') {
                                        setAlertMsgEditMsg({
                                            ...alertMsgEditMsg,
                                            menuTitle: 'ERROR',
                                            isError: true,
                                            message: 'Please fill in complete information ==> [Title].'
                                        });
                                        alertMsgEditMsg.menuTitle = 'ERROR';
                                        alertMsgEditMsg.isError = true;
                                        alertMsgEditMsg.message = 'Please fill in complete information ==> [Title].';
                                        toggle_alertMsgEdit();
                                    } else {
                                        toggle_alertMsgConfirm();
                                        // saveDocment();
                                    }
                                }}>SAVE</div>
                        </div>
                        <input id="upload-btn-multiple-feedback" hidden type="file"
                            accept="image/*"
                            multiple
                            onChange={(e) => {
                                let fileInput = document.getElementById('upload-btn-multiple-feedback')
                                console.log('fileInput', fileInput);
                                if (e.target.files.length > 0) {
                                    let ole_file = fileList;
                                    let count = ole_file.length + 1;
                                    for (let item of e.target.files) {
                                        var list = {
                                            uid: count,
                                            ul_file_path: item,
                                            ul_file_name: item.name,
                                            name: item.name,
                                            path: URL.createObjectURL(item),
                                            url: URL.createObjectURL(item),
                                            image_file: item,
                                            is_chacge: true,
                                        }
                                        ole_file.push(list)
                                        count++

                                    }
                                    setFileList(ole_file)
                                    setTest({ ...test, test: '' })
                                    test.test = ''



                                    /*    setFileList(list) */


                                }



                            }}
                        />
                    </div>

                    <div className='underInput mt-3' style={{ /* border: '1px solid red', */ overflow: 'auto', height: 'calc(75% + -93px)', display: 'grid' }}>
                        <div className='tableBody'>
                            {fileList && fileList.map((e, i) => {
                                console.log('ไฟล์ทั้งหมด', e);
                                return <>
                                    <div className='feedBox' id="scrollable-container" >
                                        <div className='feedbackBoxSet' style={{ width: '5%', textAlign: 'center' }}>
                                            {i + 1}
                                        </div>
                                        <div className='feedbackBoxSet' style={{ width: '75%', textAlign: 'center' }}>
                                            {e.name}
                                        </div>
                                        <div className='feedbackBoxMgt' style={{ width: '10%' }}>

                                            <div className='timesheetDelete' onClick={() => {
                                                delfile(i);
                                                /*  toggle_alertMsgDelete(); */
                                            }}><i class="fa fa-trash" aria-hidden="true" style={{ fontSize: '12px' }}></i></div>
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>

                    </div>



                </div>


            </div>

            <ConfigProvider
                theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#FFFFFF',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }}
            >
                <div className='timesheetAddContainer' style={{ display: `${viewFeedbackAdmin ? 'flex' : 'none'}` }}>
                    <div className='timesheetAddBox'>
                        <div className='row'>
                            <div className='col-12'>
                                <Tag color={`${ViewfeedbackAdminTemp.fs_name == 'in process' ? '#108ee9' : '#87d068'}`}> {ViewfeedbackAdminTemp.fs_name} </Tag>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='underInput'>
                                    <div className='decboxInput'>
                                        <div style={{ color: '#FFFF' }}>Feedback Code</div>
                                        <TextArea
                                            className='decInput'
                                            autoSize={{
                                                minRows: 1
                                            }}
                                            disabled
                                            value={ViewfeedbackAdminTemp.fb_code}
                                            style={{ textAlign: 'start', border: `1px solid #0047FF` }}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='underInput'>
                                    <div className='decboxInput'>
                                        <div style={{ color: '#FFFF' }}>Title</div>
                                        <TextArea
                                            className='decInput'
                                            autoSize={{
                                                minRows: 1
                                            }}

                                            value={ViewfeedbackAdminTemp.fb_title}
                                            style={{ textAlign: 'start', border: `1px solid #0047FF` }}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='underInput'>
                                    <div className='decboxInput'>
                                        <div style={{ color: '#FFFF' }}>Description</div>
                                        <TextArea
                                            className='decInput'
                                            autoSize={{
                                                minRows: 1
                                            }}
                                            disabled
                                            value={ViewfeedbackAdminTemp.fb_description}
                                            style={{ textAlign: 'start', border: `1px solid #0047FF` }}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3' style={{ /* display: 'flex'  */ }}>
                            <div className='col-12' style={{ display: 'flex', justifyContent: 'flex-end', gap: '12px' }}>

                                {/*   <div className='timesheetBoxSet' style={{ width: '30%', textAlign: 'left', cursor: `${ViewfeedbackAdminTemp.fileupload.length > 0 ? 'pointer' : 'no-drop'}` }}> */}
                                <button style={{ cursor: `${ViewfeedbackAdminTemp.fileupload.length > 0 ? 'pointer' : 'no-drop'}` }} type="button" className='btn-dowloaddocModel'
                                    onClick={() => {
                                        if (ViewfeedbackAdminTemp.fileupload.length > 0) {
                                            downloadAllFiles(ViewfeedbackAdminTemp.fileupload)
                                        }
                                    }}
                                >
                                    <img
                                        src={load}
                                        alt="description"
                                    />
                                </button>
                                {/*       </div> */}

                                <div className='btn-moel-view' style={{ placeSelf: 'flex-end' }} onClick={() => {
                                    toggle_viewFeedbackAdmin();
                                }}>
                                    CANCEL
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'orange' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </ConfigProvider>
            <AlertConfrimDelete
                isOpen={alertMsgDelete}
                mainFuc={() => { }}
                toggle_confrim={toggle_alertMsgDelete}
            />
            <AlertMassge
                isOpen={alertMsgEdit}
                toggle_alertKick={toggle_alertMsgEdit}
                message={alertMsgEditMsg}
            // mainFuc={}
            />
            <AlertConfrim
                isOpen={alertMsgConfirm}
                toggle_alertKick={toggle_alertMsgConfirm}
                mainFuc={saveDocment}
            />
            <AlertConfrim
                isOpen={alertSaveConfirm}
                toggle_alertKick={toggle_alertSaveConfirm}
                mainFuc={() => { }}
            />




        </>) : (<></>))
    )
}

export default Timesheet

