import React, { useState, useEffect, useRef } from 'react';
import '../federation/detailFederation.css'
import FedCard from "./cardFederation.js"
import './cradFederation.css'
import ImgLogo from "./ttt.png"
import Imgiconfed from "./iconUse.png"
import Construction from '../../../../assets/img/bg/Construction Page.png'

const DetailFederation = ({ isOpen, check }) => {
    const [federationdata, setFederation] = useState([
        {
            id: "1",
            img: "1",
            name: "TTTBrother",
            logo_img: '',
            type: 'federation',
            createdata: '21 MAY 2023',
            rank: '01',
            spaceship: '4',
            count: '',
            onSpaceship: '9',
            detail: [
                { name: 'Raziros', people: '4', maxpeople: '4', status: true },
                { name: 'TTTT', people: '4', maxpeople: '8', status: true },
                { name: 'Mrico', people: '4', maxpeople: '8', status: true },
                { name: 'STTTE', people: '4', maxpeople: '8', status: true },
                { name: 'FGRT', people: '4', maxpeople: '8', status: true },
                { name: 'SDapoe', people: '4', maxpeople: '8', status: true },
                { name: 'RGB', people: '4', maxpeople: '8', status: true },
                { name: 'Thaiple', people: '4', maxpeople: '8', status: true },
                { name: 'Gopo', people: '4', maxpeople: '8', status: true },
                { name: 'ssg', people: '4', maxpeople: '8', status: false },
                { name: 'ssc', people: '4', maxpeople: '8', status: false },
                { name: 'cgtx', people: '4', maxpeople: '8', status: false },
                { name: 'xyz', people: '4', maxpeople: '8', status: false },
                { name: 'abc', people: '4', maxpeople: '8', status: false },
                { name: 'team', people: '4', maxpeople: '8', status: false },
                { name: 'work', people: '4', maxpeople: '8', status: false },
                { name: 'donnto', people: '4', maxpeople: '8', status: false },
            ]

        },
        {
            id: "2",
            img: "",
            name: "TTTBrother",
            logo_img: '',
            type: 'federation',
            createdata: '23 MAY 2023',
            rank: '02',
            spaceship: '4',
            count: '',
            onSpaceship: '2',
            detail: [
                { name: 'Raziros', people: '4', maxpeople: '4', status: true },
                { name: 'TTTT', people: '4', maxpeople: '8', status: true },
                { name: 'Raziros', people: '4', maxpeople: '8', status: false },
            ]

        },
    {
        id: "3",
        img: "",
        name: "TTTBrother",
        logo_img: '',
        type: 'federation',
        createdata: '21 MAY 2023',
        rank: '03',
        spaceship: '4',
        count: '',
        onSpaceship: '3',
        detail: [
            { name: 'Raziros', people: '4', maxpeople: '4', status: true },
            { name: 'TTTT', people: '4', maxpeople: '8', status: true },
            { name: 'Raziros', people: '4', maxpeople: '8', status: false },
        ]
        },
    {
        id: "4",
        img: "",
        name: "TTTBrother",
        logo_img: '',
        type: 'federation',
        createdata: '21 MAY 2023',
        rank: '04',
        spaceship: '4',
        count: '',
        onSpaceship: '3',
        detail: [
            { name: 'Raziros', people: '4', maxpeople: '4', status: true },
            { name: 'TTTT', people: '4', maxpeople: '8', status: true },
            { name: 'Raziros', people: '4', maxpeople: '8', status: false },
        ]
        },
    {
        id: "4",
        img: "",
        name: "TTTBrother",
        logo_img: '',
        type: 'federation',
        createdata: '21 MAY 2023',
        onSpaceship: '3',
        spaceship: '4',
        rank: '05',
        detail: [
            { name: 'Raziros', people: '4', maxpeople: '4', status: true },
            { name: 'TTTT', people: '4', maxpeople: '8', status: true },
            { name: 'Raziros', people: '4', maxpeople: '8', status: false },
        ]
    },
    {
        id: "4",
        img: "",
        name: "TTTBrother",
        logo_img: '',
        type: 'federation',
        createdata: '21 MAY 2023',
        rank: '06',
        spaceship: '4',
        count: '',
        onSpaceship: '3',
        detail: [
            { name: 'Raziros', people: '4', maxpeople: '4', status: true },
            { name: 'TTTT', people: '4', maxpeople: '8', status: true },
            { name: 'Raziros', people: '4', maxpeople: '8', status: false },
        ]
        },
        
    {
        id: "4",
        img: "",
        name: "TTTBrother",
        logo_img: '',
        type: 'federation',
        createdata: '21 MAY 2023',
        rank: '07',
        spaceship: '4',
        count: '',
        onSpaceship: '3',
        detail: [
            { name: 'Raziros', people: '4', maxpeople: '4', status: true },
            { name: 'TTTT', people: '4', maxpeople: '8', status: true },
            { name: 'Raziros', people: '4', maxpeople: '8', status: false },
        ]
        },
    ])

return (
    (check === 'FEDERATION' ? (<>
        <div /* className="detailFederation" */ style={{ visibility: `${isOpen === true && check === 'FEDERATION' ? 'visible' : 'hidden'}` }}>
        <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "0px" }}/>

            {/* <div className='row delp'>
                {federationdata.length !== undefined && federationdata.length > 0 ? (<>

                    {federationdata.map((e) => {
                        return (

                            <div className='col-4 delp'>
                                <div className='cead-body'>
                                    <div className='row delpTop'>
                                        <img
                                            className='logo-img'
                                            src={ImgLogo}
                                        ></img>
                                        <div className='herder-type'>
                                            <ht1> {e.type} </ht1>
                                            <ht2>  <img className='imgicon' src={Imgiconfed} />{' '} {e.name}  </ht2>
                                            <ht3> CREATE DATE: &nbsp;{e.createdata} </ht3>
                                        </div>
                                        <div className='col-5 herder-coll'>
                                            <ht4> RANK:  &nbsp; {e.rank}</ht4>
                                            <ht4> SPACESHIP:  &nbsp; {e.onSpaceship}</ht4>
                                        </div>

                                    </div>
                                    <div className='row delpHearTable'>
                                        <div className='col-6 delpHearTable'>
                                            <ht5> &nbsp; SPACESHIP IN FEDERATION</ht5>
                                        </div>
                                        <div className='col-6 delpHearTable' style={{ textAlign: 'right' }}>
                                            <ht5>count : &nbsp; {e.onSpaceship} / {e.detail !== undefined ? e.detail.length : '0'} &nbsp; &nbsp;</ht5>
                                        </div>

                                    </div>
                                    <div className='row delpBottom'>
                                        <div className='box-class'>
                                            {e.detail !== undefined && e.detail.length > 0 ? (
                                                <>
                                                    <table>
                                                        <tr>
                                                            <th style={{ display: 'none' }}>no</th>
                                                            <th style={{ display: 'none' }}>SPACESHIP IN FEDERATION</th>
                                                            <th style={{ display: 'none' }}>status</th>
                                                            <th style={{ display: 'none' }}>COUNT</th>
                                                        </tr>
                                                        {e.detail.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ width: '30px', textAlign: 'center' }}>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td style={{ width: '200px', textAlign: 'left' }}>
                                                                        {item.name}
                                                                    </td>
                                                                    <td style={{ textAlign: 'right', width: '100px' }}>
                                                                        {item.status === true ? (<st1 > ON OPERTION </st1>) : (<st2> OPERTION OFF </st2>)}
                                                                    </td>
                                                                    <td style={{ textAlign: 'right' }}>
                                                                        PEOPLE : {`${item.people} / ${item.maxpeople}`} &nbsp; &nbsp;
                                                                    </td>

                                                                </tr>



                                                            )

                                                        })}

                                                    </table>
                                                </>
                                            ) : (<></>)}</div>
                                    </div>

                                </div>

                                 <FedCard
                                            logoimg={e.logo_img}
                                            herdertype={e.type}
                                            createdata={e.createdata}
                                            rank = {e.rank}
                                            spaceship = {e.spaceship}
                                        />


                            </div>



                        )
                    })}

                </>) 
                : 
                (<>


                </>)}
            </div> */}

        </div>
    </>) : (<></>))
)
}

export default DetailFederation