import React, { useState, useEffect } from 'react';
import Header from '../header/HeaderTTT';
import Title from "../../components/customElement/headertitle"

function Footer() {

  return (
    <>
  
        <footer>
          <div className="footer-top footer-bg">

            <div className="container">
              <div className='row'>
                <div className='col-3 footerHead'>
                  <a href="/"><img src="assets/img/images/TTTspace.png" alt="" /></a>
                </div>
                <div className='col-4 footerHead mt-1' >
                  <Title no={1} name='CONTACTS' fix={220} imglong={58} imgbox={79} imgcorner={9} top={2} />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-xl-3 col-lg-3 col-sm-6 mt-50">
                  <div className="footer-widget mb-50">
                    <div className="fw-title mb-35">
                      <Title no={2} name='Services' fix={220} imglong={50} imgbox={80} imgcorner={8} top={2} />
                    </div>

                    <ul className='stylbr'>
                      <li className='styli'>สนับสนุนบริษัทเพื่อจัดทำระบบสารสนเทศ</li>
                      <li className='styli'>สนับสนุนทีมพัฒนาเพื่อให้มีกระบวนการที่ง่ายต่อการดำเนินการ</li>
                      <li className='styli'>เอกสาร Template , Process มาตรฐาน ISO29110 , CMMI</li>
                      <li className='styli'>สร้างทีม สร้างงาน สร้างอาชีพ</li>
                      <li className='styli'>สนุกกับการทำงานในรูปแบบเกมส์ ทำงานจริง รับเงินจริง ปรับจริง</li>

                    </ul>
                  </div>

                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 mt-50">
                  <div className="footer-widget mb-50">
                    <div className="fw-title mb-35">
                      <Title no={2} name='About us' fix={220} imglong={50} imgbox={80} imgcorner={8} top={2} />
                    </div>

                    <ul className='stylbr'>
                      <li className='styli'>Platform Contact <br />
                        Tel : 085 818 8910. , Line ID : n.teewin
                      </li>
                      <li className='styli'>Technical Support <br />
                        Tel : 098 907 8389. , Line ID : .rivendell
                      </li>
                      <li className='styli'>Sales Support <br />
                        Tel : 085 818 8910. , Line ID : n.teewin
                      </li>
                      <li className='styli'>Partner TTT Spaceship <br />
                        Tel : 085 818 8910. , Line ID : n.teewin
                      </li>

                    </ul>
                  </div>

                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 mt-50">
                  <div className="footer-widget mb-50">
                    <div className="fw-title mb-35">
                      <Title no={2} name='Resouce' fix={220} imglong={50} imgbox={80} imgcorner={8} top={2} />
                    </div>

                    <ul className='stylbr'>
                      <li className='styli'>บริษัทที่ให้บริการด้านไอที โซลูชั่น <br />
                        แนะนำแนวทาง การประยุกต์ใช้ระบบสารสนเทศเพื่อการบริหารจัดการ <br />
                        และการฝึกอบรม เน้นการพัฒนากระบวนการ <br />
                        แบบก้าวกระโดด เพิ่มประสิทธิภาพการทำงาน ลดระยะเวลาการดำเนินการอย่างมีนัยสำคัญ รวมทั้ง แก้ปัญหา Human error เพื่อให้ธุรกิจของลูกค้า<br />
                        ทุกท่านบรรลุเป้าหมายอย่างรวดเร็ว และมั่นคง แข็งแรงยั่งยืน</li>




                    </ul>
                  </div>

                </div>
                <div className="col-xl-3 col-lg-3 col-sm-6 mt-50">
                  <div className="footer-widget mb-50">
                    <div className="fw-title mb-35">
                      <Title no={2} name='Our Platform' fix={220} imglong={50} imgbox={80} imgcorner={8} top={2} />
                    </div>

                    <ul className='stylbr'>
                      <li className='styli'>แพลตฟอร์มสำหรับบริการให้ บุคคล , <br />  องค์กร , หน่วยงาน ที่ต้องการจัดทำระบบสารสนเทศในด้านต่าง ๆ โดยแพลตฟอร์มจะมีทีมงาน
                        คุณภาพ จากหลากหลายภาคธุรกิจ ในด้านไอที เพื่อรอรับความต้องการ และดำเนินการวิเคราะห์ระบบ และจัดทำตามมาตรฐานการผลิต ISO29110 , CMMI <br />ทำให้ได้ระบบที่มีคุณภาพสามารถต่อยอด <br />และสนับสนุนงานทางธุรกิจได้ </li>




                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className="copyright-wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="copyright-text">
                    {/*          <p>Copyright © 2022 <a href="/#">Geco</a> All Rights Reserved.</p> */}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 d-none d-md-block">
                  <div className="payment-method-img text-right">
                    {/*  <img src="assets/img/images/card_img.png" alt="img" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

    </>


  )
}

export default Footer;
