import React, { useEffect, useState, useRef } from 'react';
import '../component/howToPlayStep.css';
import step1A from '../image/step1-a.png';
import step11 from '../image/step1-1.png';
import step12 from '../image/step1-2.png';
import redHand from '../image/redHand.png'

function HowToPlayStep1(params) {
    return (
        <>
            <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}` }}>
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step1A} alt="" style={{ bottom: '-7px', left: '-4px' }} />
                <div className='howToPlayStep1Detail' style={{ top: '190px', left: '475px' }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '0.75%' }}><div style={{ fontWeight: '700' }}>สมัครสมาชิก (สำหรับผู้ใช้ใหม่)</div><div>ทำการคลิกที่ปุ่ม Register เพื่อทำการสมัครสมาชิก</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '325px', left: '475px' }}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>Scan QR Code เพื่อสมัครสมาชิก</div><div>ระบบจะทำการขึ้นหน้า QR Code ให้แสกนเพื่อติดต่อการสมัครกับทาง Line Admin</div></div>
                </div>
                <div className='howToPlayStep1Image' style={{ top: '50px', right: '75px' }}>
                    <div className='howToPlayStep1DetailNo' style={{width: '40px'}}>01</div>
                    <img className='howToPlayStep1ImageImg' src={step11} alt="" />
                </div>
                <div className='howToPlayStep1Image' style={{ top: '275px', right: '75px' }}>
                    <div className='howToPlayStep1DetailNo' style={{width: '40px'}}>02</div>
                    <img className='howToPlayStep1ImageImg' src={step12} alt="" />
                </div>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '160px', right: '145px', width: '2.5%' }} />
            </div>
        </>
    );
}

export default HowToPlayStep1;
