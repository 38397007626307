import React, { useState, useEffect, useRef } from 'react';
import '../timesheet/timesheet.css'
import axios from "axios";
import Configs from "../../../../config";
import moment from "moment";
import editIcon from "./Image/editIcon.png";
import timesheetIcon from "./Image/timesheetIcon.png";
import AlertConfrimDelete from "../../../../components/AlertMassge/AlertConfrimDelete";
import AlertMassge from "../../../../components/AlertMassge/AlertMassge";
import AlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import { Select, DatePicker, Input, ConfigProvider, Tooltip,Switch } from 'antd';
import {
    CalendarOutlined,
    ClockCircleOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import timesheertreport from '../../../component/Report/timesheertreport';
import generateDocument  from '../../../component/Report/doctesting';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const Timesheet = ({ isOpen, check }) => {

    const [test, setTest] = useState({
        test: ''
    });

    const [timesheetData, setTimesheetData] = useState([]);
    const [oldTimesheetData, setOldTimesheetData] = useState([]);

    const [timesheetProjectList, setTimesheetProjectList] = useState([]);
    const [timesheetProcessList, setTimesheetProcessList] = useState([]);
    const [timesheetModuleList, setTimesheetModuleList] = useState([]);
    const [timesheetMenuList, setTimesheetMenuList] = useState([]);

    const [alertMsgDelete, setAlertMsgDelete] = useState(false);
    const toggle_alertMsgDelete = () => { setAlertMsgDelete(!alertMsgDelete) };
    const [idToFuncDelete, setIdToFuncDelete] = useState('');

    const [idEditList, setIdEditList] = useState([]);

    const [todayUse, setTodayUse] = useState({
        hours: 0,
        minute: 0
    });
    let [loadinddisplay, setloadinddisplay] = useState(false);
    const [alertMsgEditMsg, setAlertMsgEditMsg] = useState({
        menuTitle: 'ERROR',
        isError: true,
        message: 'Please fill out the information completely.'
    });
    const [alertMsgEdit, setAlertMsgEdit] = useState(false);
    const toggle_alertMsgEdit = () => { setAlertMsgEdit(!alertMsgEdit) }

    const [alertDataConfirm, setAlertDataConfirm] = useState({});
    const [alertMsgConfirm, setAlertMsgConfirm] = useState(false);
    const toggle_alertMsgConfirm = () => { setAlertMsgConfirm(!alertMsgConfirm) }

    const [filterDataType, setFilterDateType] = useState('today');
    const [timeSheetData, setTimeSheetData] = useState({
        project_id: '',
        project_name: '',
        ts_process_id: '',
        process_name: '',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
        date_start: new Date(),
        date_end: new Date(),
        time_start: '',
        time_end: '',
        ts_project_detail: '',
        count_hour: 0,
        count_minute: 0
    })
    const [err_message, setErrorMessage] = useState('');

    const [alertSaveConfirm, setAlertSaveConfirm] = useState(false);
    const toggle_alertSaveConfirm = () => { setAlertSaveConfirm(!alertSaveConfirm) };

    const [alertSavePopup, setAlertSavePopup] = useState(false);
    const toggle_alertSavePopup = () => { setAlertSavePopup(!alertSavePopup) };

    const [descIsNull, setDescIsNull] = useState(false);
    const toggle_descIsNull = () => { setDescIsNull(!descIsNull) };
    const [timeIsNull, setTimeIsNull] = useState(false);
    const toggle_timeIsNull = () => { setTimeIsNull(!timeIsNull) };
    const [descIsNullEdit, setDescIsNullEdit] = useState(false);
    const toggle_descIsNullEdit = () => { setDescIsNullEdit(!descIsNullEdit) };
    const [timeIsNullEdit, setTimeIsNullEdit] = useState(false);
    const toggle_timeIsNullEdit = () => { setTimeIsNullEdit(!timeIsNullEdit) };



    const [isOpenHistoryReport, setIsOpenHistoryReport] = useState(false);
    const toggle_history_report = () => { setIsOpenHistoryReport(!isOpenHistoryReport) };

    const [genHistory, setGenHistory] = useState({
        start_date: dayjs(),
        end_date: dayjs()
    });

    async function getTimesheetById() {
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetById/${user_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                for(let item of response.data){
                    if(item.ts_project_id){
                        item.typProject = true;
                    }else{
                        item.typProject = false;
                    }
                }
                setTimesheetData(JSON.parse(JSON.stringify(response.data)));
                setOldTimesheetData(JSON.parse(JSON.stringify(response.data)));
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetProjectById() {
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetProjectById/${user_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.project_name,
                        value: item.project_id,
                    });
                };
                setTimesheetProjectList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetProcess() {
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetProcess`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.process_name,
                        value: item.process_id,
                    });
                };
                setTimesheetProcessList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetModuleById() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetModuleById/${sessionStorage.getItem('user_id')}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.module_name,
                        value: item.module_id,
                        project_id: item.project_id,
                        project_name: item.project_name,
                    });
                };
                setTimesheetModuleList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function getTimesheetMenuById() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getTimesheetMenuById/${sessionStorage.getItem('user_id')}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    temp.push({
                        label: item.menu_name,
                        value: item.menu_id,
                        module_id: item.module_id,
                        module_name: item.module_name,
                        project_id: item.project_id,
                        project_name: item.project_name,
                    });
                };
                setTimesheetMenuList(temp);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function editTimesheet() {
        let temp = alertDataConfirm;
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/editTimesheet`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                setAlertMsgEditMsg({
                    ...alertMsgEditMsg,
                    menuTitle: 'SUCCESS',
                    isError: false,
                    message: 'EDIT SUCCESSFULL.'
                });
                alertMsgEditMsg.menuTitle = 'SUCCESS';
                alertMsgEditMsg.isError = false;
                alertMsgEditMsg.message = 'EDIT SUCCESSFULL.';

                let checkIndex = idEditList.findIndex((ele) => ele == temp.ts_id);
                if (checkIndex > -1) {
                    idEditList.splice(checkIndex, 1)
                };

                let checkIndexOld = oldTimesheetData.findIndex((e) => e.ts_id == temp.ts_id);
                oldTimesheetData[checkIndexOld].ts_date = temp.ts_date;
                oldTimesheetData[checkIndexOld].ts_hours = temp.ts_hours;
                oldTimesheetData[checkIndexOld].ts_time = temp.ts_time;
                oldTimesheetData[checkIndexOld].ts_process_id = temp.ts_process_id;
                oldTimesheetData[checkIndexOld].ts_project_name = temp.ts_project_name;
                oldTimesheetData[checkIndexOld].module_id = temp.module_id;
                oldTimesheetData[checkIndexOld].module_name = temp.module_name;
                oldTimesheetData[checkIndexOld].ts_process_id = temp.ts_process_id;
                oldTimesheetData[checkIndexOld].process_name = temp.process_name;
                oldTimesheetData[checkIndexOld].ts_menu_id = temp.ts_menu_id;
                oldTimesheetData[checkIndexOld].menu_name = temp.menu_name;
                oldTimesheetData[checkIndexOld].ts_project_detail = temp.ts_project_detail;

                let set = {};
                setAlertDataConfirm(set);

                toggle_alertMsgEdit();

                getCountTodayTimeSheet();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    async function deleteTimesheetById() {
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/timeSheet/deleteTimesheetById/${idToFuncDelete}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                setIdToFuncDelete('');
                getTimesheetById();
                setAlertMsgDelete(false);
                getCountTodayTimeSheet();
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function getCountTodayTimeSheet() {

        axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getCountTodayTimeSheet/${sessionStorage.getItem('user_id')}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {
                setTodayUse({ ...todayUse, hours: response.data.hours, minute: response.data.minute })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function saveTimeSheet() {
        let temp = {
            project_id: timeSheetData.project_id,
            project_name: timeSheetData.project_name,
            menu_id: timeSheetData.menu_id,
            process_id: timeSheetData.ts_process_id,
            date_start: timeSheetData.date_start,
            date_end: timeSheetData.date_end,
            count_hour: timeSheetData.count_hour,
            count_minute: timeSheetData.count_minute,
            description: timeSheetData.ts_project_detail.trim() || ''
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/addTimeSheet`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                toggle_alertSaveConfirm();
                toggle_alertSavePopup();
                getTimesheetById();
                setTimeSheetData({
                    ...timeSheetData,
                    project_id: '',
                    project_name: '',
                    ts_process_id: '',
                    process_name: '',
                    module_id: '',
                    module_name: '',
                    menu_id: '',
                    menu_name: '',
                    date_start: new Date(),
                    date_end: new Date(),
                    time_start: '',
                    time_end: '',
                    ts_project_detail: '',
                    count_hour: 0,
                    count_minute: 0
                });
                getCountTodayTimeSheet();
            })
            .catch(function (error) {
                console.log(error);
                if (!timeIsNull) {
                    toggle_timeIsNull();
                }

                setAlertMsgEditMsg({
                    ...alertMsgEditMsg,
                    menuTitle: 'ERROR',
                    isError: true,
                    message: 'PLEASE FILL OUT THE INFORMATION COMPLETELY.'
                });
                alertMsgEditMsg.menuTitle = 'ERROR';
                alertMsgEditMsg.isError = true;
                alertMsgEditMsg.message = 'PLEASE FILL OUT THE INFORMATION COMPLETELY.';
                toggle_alertMsgEdit();
            });
    }

    useEffect(() => {
        if (isOpen && check == 'TIMESHEET') {
            getTimesheetById();
            getTimesheetProjectById();
            getTimesheetProcess();
            getTimesheetModuleById();
            getTimesheetMenuById();
            getCountTodayTimeSheet();
         
        }
    }, [check]);



    async function gettimesheertreport() {

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/timesheertreport`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
            /*      user_id:sessionStorage.getItem('user_id'), */
                 startdate: genHistory.start_date,
                 enddate:  genHistory.end_date,
               
            }
        })
            .then(async function (response) {
                console.log(response.data);
                response.data.s = genHistory.start_date
                response.data.e = genHistory.end_date
                timesheertreport(response.data,null,'version',(result) => {
                    console.log('result timesheet',result)
                    saveGenTimeSheet(result)
                    
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    function saveGenTimeSheet(data) {
        let temp = {
            start_date:genHistory.start_date,
            end_date:genHistory.end_date,
            file_path:data.pathimg || '',
            file_name:data.file_name || '',
            ref_id:data.ref_id
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/saveHistoryTimeSheet`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {
                getGenTimeSheet();
            })
            .catch(function (error) {
             
            });
    }

    const[historyTimeSheetList,setHistoryTimeSheetList] = useState([]);

    function getGenTimeSheet() {
        setloadinddisplay(true)
        axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/timeSheet/getHistoryTimeSheet`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
         
        })
            .then(async function (response) {

for (let i of response.data) {
    if (i.rtv_path_file !== null && !isNaN(parseInt(i.rtv_path_file))) {
       let response2 = await axios.get(`https://api.box.com/2.0/files/${i.rtv_path_file}/content`, {
           headers: {
               Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
           },
           responseType: 'blob',
       });
       i.href = URL.createObjectURL(response2.data);
   }

}




               setHistoryTimeSheetList(response.data)
               setloadinddisplay(false)
            })
            .catch(function (error) {
             
            });
    }

    const [typeProject,setTypeProject] = useState(false);

    return (
        (check === 'TIMESHEET' ? (<>
            <div className="detailTimesheet" style={{ visibility: `${isOpen === true && check === 'TIMESHEET' ? 'visible' : 'hidden'}` }}>
                <div className='timesheetHead'>
                    <div className='timesheetToDayText'>TO DAY : <span style={{ color: '#0047FF' }}>{todayUse.hours}</span> HR <span style={{ color: '#0047FF' }}>{todayUse.minute}</span> MIN</div>
                   
                    <div style={{ display: 'flex', gap: '10px' }}>
                    <div className='timesheetAddButton' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF' }} onClick={() => {
                          window.location.href = `/Timesheettimeline`
                          
                        }}> PERSONAL TIMESHEET</div>
                        <div className='timesheetAddButton' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFF' }} onClick={() => {
                               getGenTimeSheet();
                            toggle_history_report();
                           /*  generateDocument() */
                          /*   gettimesheertreport() */
                         /*  gettimesheertreport() */
                          
                        }}>Report time sheet</div>
                        <div className='timesheetAddButton' onClick={() => {
                            toggle_alertSavePopup();
                           
                        }}><img src={timesheetIcon} style={{ height: '100%' }} /></div>
                    </div>

                </div>
                <div className='timesheetHeader'>
                    <div style={{ width: '10%' }}>Date</div>
                    <div style={{ width: '10%' }}>Time</div>
                    <div style={{ width: '15%' }}>Project</div>
                    <div style={{ width: '10%' }}>Process</div>
                    <div style={{ width: '10%' }}>Module</div>
                    <div style={{ width: '10%' }}>Menu</div>
                    <div style={{ width: '25%' }}>Description</div>
                    <div style={{ width: '10%' }}>Management</div>
                </div>

                <div className='timesheetBody'>
                    {timesheetData && timesheetData.map((e, i) => {
                        
                       
                        let checkMode = idEditList.filter((el) => { return el == e.ts_id });
                        if (checkMode.length > 0) {
                            return <>
                                <div className='timesheetBox' id="scrollable-container" >
                                    <div className='timesheetBoxSet' style={{ width: '10%', textAlign: 'center' }}>
                                        <ConfigProvider
                                            theme={{
                                                token: {

                                                    colorInfo: 'red',
                                                    colorBorder: '#0047FF',
                                                    colorText: '#FFF',

                                                    colorTextDescription: '#FFFFFF',
                                                    colorBgContainer: '#FFFFFF',
                                                    zIndexPopupBase: 999999,
                                                    colorPrimaryBg: '#FFFFFF',
                                                    colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                                    colorTextQuaternary: 'White',//สีตัวอักษร

                                                    colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                                    colorTextBase: 'White', // สี scroll bar

                                                    colorPrimaryBg: '#0047FF',// สีตอนเลือก
                                                    /*       
                                                           colorBgLayout:'red',
                                                           colorFillQuaternary:'red', */


                                                }
                                            }}
                                        >
                                            <DatePicker
                                                value={dayjs(e.ts_date)}
                                                onChange={(f) => {
                                                    e.ts_date = new Date(f);
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }}
                                                onBlur={(f) => {
                                                    if (e.ts_date == '' || e.ts_date == null || e.ts_date == undefined) {
                                                        e.ts_date = new Date(f);
                                                        setTest({ ...test, test: '' });
                                                        test.test = '';
                                                    }
                                                }}
                                                allowClear={false}
                                                format={'DD/MM/YYYY'}
                                                getPopupContainer={() => document.getElementById('scrollable-container')}
                                            />
                                        </ConfigProvider>
                                    </div>
                                    <div className='timesheetBoxSet' style={{ width: '10%', textAlign: 'center' }}>
                                        <input
                                            type='text'
                                            className='timesheetInput'
                                            style={{
                                                width: '30px',
                                                border: `1px solid ${e.time_null ? 'red' : '#0047FF'}`,
                                                textAlign: 'center'
                                            }}
                                            value={e.ts_hours}
                                            onChange={(f) => {
                                                if (f.target.value <= 23) {
                                                    e.ts_hours = f.target.value;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                } else {
                                                    e.ts_hours = 23;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }
                                                if (e.time_null) {
                                                    e.time_null = false;
                                                }
                                            }}
                                            onBlur={(f) => {
                                                if (e.ts_hours == '' || e.ts_hours == null || e.ts_hours == undefined) {
                                                    e.ts_hours = 0;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                if (event.key == '.') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        /> hr
                                        <input
                                            type='text'
                                            className='timesheetInput'
                                            style={{
                                                width: '30px',
                                                border: `1px solid ${e.time_null ? 'red' : '#0047FF'}`,
                                                textAlign: 'center'
                                            }}
                                            value={e.ts_time}
                                            onChange={(f) => {
                                                if (f.target.value <= 59) {
                                                    e.ts_time = f.target.value;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                } else {
                                                    e.ts_time = 59;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }
                                                if (e.time_null) {
                                                    e.time_null = false;
                                                }
                                            }}
                                            onBlur={(f) => {
                                                if (e.ts_time == '' || e.ts_time == null || e.ts_time == undefined) {
                                                    e.ts_time = 0;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                                if (event.key == '.') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        /> min
                                    </div>

                                    <ConfigProvider
                                        theme={{
                                            token: {

                                                colorInfo: 'red',
                                                colorBorder: '#0047FF',
                                                colorText: '#FFF',

                                                colorTextDescription: '#FFFFFF',
                                                colorBgContainer: '#0A2268',
                                                borderRadius: '2px',
                                                colorBorder: '#0047FF !imporstant',
                                                zIndexPopupBase: 999,
                                                colorPrimaryBg: '#FFFFFF',
                                                colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                                colorTextQuaternary: '#D4D4D4',//สีตัวอักษร

                                                colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                                colorTextBase: 'White', // สี scroll bar

                                                colorPrimaryBg: '#0047FF',// สีตอนเลือก

                                            }
                                        }}
                                    >
                                        <div className='timesheetBoxSet' style={{ width: '15%' }}>
                                            <div style={{display:'flex',flexDirection:'column',gap:'5px'}}>
                                                <div style= {{display:'flex',gap:'5px'}}>
                                           
                                                <Switch checked = {e.typProject} onChange={(value)=>{
                                                let temp = timesheetData || [];
                                                temp[i].typProject = value;
                                                temp[i].ts_project_id  = '';
                                                temp[i].ts_project_name  = '';
                                                temp[i].process_name='';
                                                temp[i].ts_process_id='';
                                                temp[i].module_id = '';
                                                temp[i].module_name = '';
                                                temp[i].menu_id = '';
                                                temp[i].menu_name = '';
                                                setTimeSheetData(temp);
                                                setTest({...test,test:''});
                                                test.test = '';
                                               
                                               }}/>
                                               On Spaceship
                                                </div>
                                              
                                               {e.typProject ? (
                                                 <Select
                                                 showSearch
                                                 className='timesheetInput'
                                                 options={timesheetProjectList}
                                                 value={e.ts_project_id}
                                                 onChange={(_, f) => {
                                                     e.ts_project_id = f.value;
                                                     e.ts_project_name = f.label;
                                                     // let checkModule = timesheetMenuList.filter((ef) => { return ef.project_id == e.ts_project_id });
                                                     // if (checkModule.length > 0) {
                                                     //     e.module_id = checkModule[0].module_id;
                                                     //     e.module_name = checkModule[0].module_name;
                                                     //     e.menu_id = checkModule[0].value;
                                                     //     e.menu_name = checkModule[0].label;
                                                     // } else {
                                                     e.module_id = '';
                                                     e.module_name = '';
                                                     e.menu_id = '';
                                                     e.menu_name = '';
                                                     // };
                                                     setTest({ ...test, test: '' });
                                                     test.test = '';
                                                 }}
                                             />

                                               ):(  <Input 
                                                 className='timesheetInput'
                                                 value = {e.ts_project_name}
                                                 onChange={(v)=>{
                                                    setTimeSheetData({ ...timeSheetData,  project_name: v.target.value });
                                                    timeSheetData.project_name = v.target.value;
                                                    let check = timesheetProjectList.filter((e2)=>{return e2.label == timeSheetData.project_name});
                                                    e.ts_project_name = timeSheetData.project_name;
                                                    e.ts_project_id = '';
                                                    if(check.length > 0){
                                                        e.ts_project_id = check[0].value;
                                                    }
                                                    
                                                    setTimeSheetData({ ...timeSheetData,  project_name: '' });
                                                    timeSheetData.project_name = '';
                                                  }}
                                              
                                                />)}
                                              
                                           
                                            </div>
                                            
                                        </div>
                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>
                                            <Select
                                                showSearch
                                                className='timesheetInput'
                                                options={timesheetProcessList}
                                                value={e.process_name}
                                                onChange={(_, f) => {
                                                    e.ts_process_id = f.value;
                                                    e.process_name = f.label;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }}
                                            />
                                        </div>
                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>
                                            <Select
                                                showSearch
                                                className='timesheetInput'
                                                options={timesheetModuleList.filter((ef) => {
                                                    return ef.project_id == e.ts_project_id
                                                })}
                                                value={e.module_name}
                                                onChange={(_, f) => {
                                                    e.module_id = f.value;
                                                    e.module_name = f.label;
                                                    // let checkModule = timesheetMenuList.filter((ef) => { return ef.module_id == e.module_id });
                                                    // if (checkModule.length > 0) {
                                                    //     e.menu_id = checkModule[0].value;
                                                    //     e.menu_name = checkModule[0].label;
                                                    // } else {
                                                    e.menu_id = '';
                                                    e.menu_name = '';
                                                    // };
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }}
                                            />
                                        </div>

                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>
                                            <Select
                                                showSearch
                                                className='timesheetInput'
                                                options={timesheetMenuList.filter((ef) => {
                                                    return ef.module_id == e.module_id && ef.project_id == e.ts_project_id
                                                })}
                                                value={e.menu_name}
                                                onChange={(_, f) => {
                                                    e.ts_menu_id = f.value;
                                                    e.menu_name = f.label;
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }}
                                            />
                                        </div>
                                    </ConfigProvider>

                                    <div className='timesheetBoxDesc' style={{ width: '25%' }}>
                                        <TextArea
                                            className='timesheetInput'
                                            value={e.ts_project_detail}
                                            autoSize={{
                                                minRows: 1
                                            }}
                                            style={{ textAlign: 'start', border: `1px solid ${/* e.ts_project_detail == '' || e.ts_project_detail == null || e.ts_project_detail == undefined */descIsNullEdit ? 'red' : '#0047FF'}` }}
                                            onChange={(f) => {
                                                e.ts_project_detail = f.target.value;
                                                if (descIsNullEdit) {
                                                    toggle_descIsNullEdit();
                                                }
                                                setTest({ ...test, test: '' });
                                                test.test = '';
                                            }}
                                        />
                                    </div>
                                    <div className='timesheetBoxMgt' style={{ width: '10%' }}>
                                        <div className='timesheetEditButtonSave' onClick={() => {
                                            if (e.ts_project_detail == '' || e.ts_project_detail == null || e.ts_project_detail == undefined || (e.ts_hours == 0 && e.ts_time == 0)) {
                                                setAlertMsgEditMsg({
                                                    ...alertMsgEditMsg,
                                                    menuTitle: 'ERROR',
                                                    isError: true,
                                                    message: 'Please fill out the information completely.'
                                                });
                                                alertMsgEditMsg.menuTitle = 'ERROR';
                                                alertMsgEditMsg.isError = true;
                                                alertMsgEditMsg.message = 'Please fill out the information completely.';

                                                if (e.ts_hours == 0 && e.ts_time == 0) {
                                                    e.time_null = true;
                                                }
                                                if (e.ts_project_detail == '' || e.ts_project_detail == null || e.ts_project_detail == undefined) {
                                                    if (!descIsNullEdit) {
                                                        toggle_descIsNullEdit();
                                                    }
                                                }
                                                toggle_alertMsgEdit();
                                            } else if ((parseInt(e.ts_hours) * 60) + parseInt(e.ts_time) + (((parseInt(todayUse.hours) * 60) + parseInt(todayUse.minute)) - ((parseInt(oldTimesheetData[i].ts_hours) * 60) + parseInt(oldTimesheetData[i].ts_time))) > 1440) {
                                                setAlertMsgEditMsg({
                                                    ...alertMsgEditMsg,
                                                    menuTitle: 'ERROR',
                                                    isError: true,
                                                    message: 'YOU ARE USE EFFORT OVER DAYS!'
                                                });
                                                alertMsgEditMsg.menuTitle = 'ERROR';
                                                alertMsgEditMsg.isError = true;
                                                alertMsgEditMsg.message = 'YOU ARE USE EFFORT OVER DAYS!';
                                                e.time_null = true;
                                                toggle_alertMsgEdit();
                                            } else {
                                                setAlertDataConfirm(e);
                                                toggle_alertMsgConfirm();

                                                setTest({ ...test, test: '' });
                                                test.test = '';
                                            }
                                        }}>SAVE</div>
                                        <div className='timesheetEditButtonCancel' onClick={() => {
                                            let checkIndex = idEditList.findIndex((ele) => ele == e.ts_id);
                                            if (checkIndex > -1) {
                                                idEditList.splice(checkIndex, 1)
                                            };
                                            e.ts_date = oldTimesheetData[i].ts_date;
                                            e.ts_hours = oldTimesheetData[i].ts_hours;
                                            e.ts_time = oldTimesheetData[i].ts_time;
                                            e.ts_process_id = oldTimesheetData[i].ts_process_id;
                                            e.ts_project_name = oldTimesheetData[i].ts_project_name;
                                            e.module_id = oldTimesheetData[i].module_id;
                                            e.module_name = oldTimesheetData[i].module_name;
                                            e.ts_process_id = oldTimesheetData[i].ts_process_id;
                                            e.process_name = oldTimesheetData[i].process_name;
                                            e.ts_menu_id = oldTimesheetData[i].ts_menu_id;
                                            e.menu_name = oldTimesheetData[i].menu_name;
                                            e.ts_project_detail = oldTimesheetData[i].ts_project_detail;

                                            if (e.time_null) {
                                                e.time_null = false;
                                            }

                                            setTest({ ...test, test: '' });
                                            test.test = '';
                                        }}>CANCEL</div>
                                    </div>
                                </div>
                            </>
                        } else {
                            return <>
                                <div className='timesheetBox'>
                                    <div className='timesheetBoxSet' style={{ width: '10%', textAlign: 'center' }}>{moment(e.ts_date).format('DD/MM/YYYY')}</div>
                                    <div className='timesheetBoxSet' style={{ width: '10%', textAlign: 'center' }}>{e.ts_hours} hr {e.ts_time} min</div>
                                    <Tooltip placement="topLeft" title={e.ts_project_name} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '15%' }}>{e.ts_project_name}</div>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={e.process_name} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>{e.process_name}</div>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={e.module_name} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>{e.module_name}</div>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title={e.menu_name} color='#0047FF' getPopupContainer={trigger => trigger.parentElement}>
                                        <div className='timesheetBoxSet' style={{ width: '10%' }}>{e.menu_name}</div>
                                    </Tooltip>
                                    <div className='timesheetBoxDesc' style={{ width: '25%' }}>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorBgBase: 'transparent',
                                                    colorTextQuaternary: '#D4D4D4',
                                                    colorFillTertiary: 'transparent'
                                                }
                                            }}
                                        >
                                            <TextArea
                                                className='timesheetBoxDescInput'
                                                value={e.ts_project_detail}
                                                autoSize={{
                                                    minRows: 1
                                                }}
                                                disabled={true}
                                                style={{ textAlign: 'start', cursor: 'context-menu', border: 'none' }}
                                                onChange={(f) => {
                                                    e.ts_project_detail = f.target.value;
                                                    if (descIsNullEdit) {
                                                        toggle_descIsNullEdit();
                                                    }
                                                    setTest({ ...test, test: '' });
                                                    test.test = '';
                                                }}
                                            />
                                        </ConfigProvider>
                                    </div>

                                    <div className='timesheetBoxMgt' style={{ width: '10%' }}>
                                        <div className='timesheetEdit' style={{ backgroundImage: `url(${editIcon})` }} onClick={() => {
                                            idEditList.push(e.ts_id);
                                            setTest({ ...test, test: '' });
                                            test.test = '';
                                        }}></div>
                                        <div className='timesheetDelete' onClick={() => {
                                            setIdToFuncDelete(e.ts_id);
                                            toggle_alertMsgDelete();
                                        }}><i class="fa fa-trash" aria-hidden="true" style={{ fontSize: '12px' }}></i></div>
                                    </div>
                                </div>
                            </>
                        }
                    })}
                </div>
            </div>
            <ConfigProvider
                theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#FFFFFF',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }}
            >
                <div className='timesheetAddContainer' style={{ display: `${alertSavePopup ? 'flex' : 'none'}`,fontFamily:  'Oxanium' }}>
                    <div className='timesheetAddBox'>
                        <div className='timesheetAddBoxHeader'>
                            <div>TIME SHEET</div>
                            <div className='timesheetAddBoxClose' onClick={() => {
                                setTimeSheetData({
                                    ...timeSheetData,
                                    project_id: '',
                                    project_name: '',
                                    ts_process_id: '',
                                    process_name: '',
                                    module_id: '',
                                    module_name: '',
                                    menu_id: '',
                                    menu_name: '',
                                    date_start: new Date(),
                                    date_end: new Date(),
                                    time_start: '',
                                    time_end: '',
                                    ts_project_detail: '',
                                    count_hour: 0,
                                    count_minute: 0
                                });
                                toggle_alertSavePopup();
                                if (timeIsNull) {
                                    toggle_timeIsNull();
                                }
                                if (descIsNull) {
                                    toggle_descIsNull();
                                }
                            }}>X</div>
                        </div>
                        <div className='timesheetAddBoxDateRang'>
                            <CalendarOutlined style={{ fontSize: '25px' }} />
                            <RangePicker id='dateRange' style={{ width: '250px' }} className='inputTimeSheet2' allowClear={false}
                                format={'DD/MM/YYYY'}
                                value={[timeSheetData.date_start ? dayjs(timeSheetData.date_start) : '', timeSheetData.date_end ? dayjs(timeSheetData.date_end) : '']}
                                onChange={(date) => {
                                    if (date && date.length == 2) {
                                        setFilterDateType(null);
                                        setTimeSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) })
                                        /*      changeMenuValue('menu_start_stop_date', date, menu.menu_id); */

                                    } else {
                                        //console.log('date', date)
                                        setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
                                    }
                                }}
                            />
                            <div className={filterDataType == 'today' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('today');
                                    setTimeSheetData({ ...timeSheetData, date_start: new Date(), date_end: new Date() });

                                }}
                            >
                                Today
                            </div>
                            <div className={filterDataType == 'this_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('this_week');
                                    let start = moment(new Date()).startOf('weeks').add(1, 'days');
                                    let end = moment(new Date()).endOf('weeks').add(-1, 'days');
                                    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                                }}
                            >
                                This Week
                            </div>
                            <div className={filterDataType == 'last_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                                onClick={() => {
                                    setFilterDateType('last_week')
                                    let start = moment(new Date()).startOf('weeks').add(1, 'days').add(-1, 'weeks');
                                    let end = moment(new Date()).endOf('weeks').add(-1, 'days').add(-1, 'weeks');
                                    setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                                }}
                            >
                                Last Week
                            </div>
                        </div>
                        <div className='timesheetAddBoxDateRang'>
                            <ClockCircleOutlined style={{ fontSize: '25px' }} />
                            <RangePicker style={{ width: '220px', height: '32px' }} className='inputTimeSheet2'
                                format={'HH:mm'}
                                value={[timeSheetData.time_start ? dayjs(timeSheetData.time_start) : '', timeSheetData.time_end ? dayjs(timeSheetData.time_end) : '']}
                                onChange={(date) => {
                                    //console.log('date',date)
                                    if (date && date.length == 2) {
console.log(date);
                                        let minute_all = moment(new Date(date[1])).seconds(0).milliseconds(0).diff(moment(new Date(date[0])).seconds(0).milliseconds(0), 'minutes');
                                        let hours = Math.floor(minute_all / 60);
                                        let minute = minute_all - (Math.floor(minute_all / 60)) * 60;
                                        //console.log(hours,minute)
                                        setTimeSheetData({ ...timeSheetData, count_hour: hours, count_minute: minute, time_start: new Date(date[0]), time_end: new Date(date[1]) });


                                    }
                                }}
                                allowClear={false}
                                picker="time"
                            />

                            OR
                            <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center', border: `1px solid ${timeIsNull ? 'red' : '#0047FF'}` }} className='inputTimeSheet2'
                                value={timeSheetData.count_hour}
                                onChange={(e) => {
                                    if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 23) || e.target.value == '') {
                                        setTimeSheetData({ ...timeSheetData, count_hour: e.target.value.trim(), time_start: null, time_end: null });
                                        setErrorMessage('');
                                        if (timeIsNull) {
                                            toggle_timeIsNull();
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key == '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            hrs.
                            <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center', border: `1px solid ${timeIsNull ? 'red' : 'blue'}` }} className='inputTimeSheet2'
                                value={timeSheetData.count_minute}
                                onChange={(e) => {
                                    if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 59) || e.target.value == '') {
                                        setTimeSheetData({ ...timeSheetData, count_minute: e.target.value.trim(), time_start: null, time_end: null });
                                        setErrorMessage('');
                                        if (timeIsNull) {
                                            toggle_timeIsNull();
                                        }
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key == '.') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            min
                        </div>
                        <ConfigProvider
                            theme={{
                                token: {

                                    colorInfo: 'red',
                                    colorBorder: '#0047FF',
                                    colorText: '#FFF',

                                    colorTextDescription: '#FFFFFF',
                                    colorBgContainer: '#0A2268',
                                    borderRadius: '2px',
                                    colorBorder: '#0047FF !imporstant',
                                    zIndexPopupBase: 999,
                                    colorPrimaryBg: '#FFFFFF',
                                    colorBgBase: '#001D5F',//พื้นหลัง datepicker

                                    colorTextQuaternary: '#D4D4D4',//สีตัวอักษร

                                    colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                                    colorTextBase: 'White', // สี scroll bar

                                    colorPrimaryBg: '#0047FF',// สีตอนเลือก

                                }
                            }}
                        >
  <div className='timesheetAddBoxDateRang'>
    Other
<Switch checked={typeProject} onChange={(value)=>{
    setTimeSheetData({ ...timeSheetData, project_id: '' ,project_name:'',
        process_name:'',
        process_id:'',
        module_id: '',
        module_name: '',
        menu_id: '',
        menu_name: '',
     });
    setTypeProject(value);
}} />
On Spaceship
</div>
                    {typeProject ? (
     <div className='timesheetAddBoxDateRang'>
     <div style={{ width: '15%' }}>PROJECT :</div>
     <Select
         showSearch
         className='timesheetInput'
         style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.project_id == '' || timeSheetData.project_id == null || timeSheetData.project_id == undefined ? 'red' : '#0047FF'}` */ }}
         options={timesheetProjectList}
         value={timeSheetData.project_id}
         onChange={(_, f) => {
             setTimeSheetData({ ...timeSheetData, project_id: f.value, project_name: f.label });
             timeSheetData.project_id = f.value;
             timeSheetData.project_name = f.label;
             // let checkModule = timesheetMenuList.filter((ef) => { return ef.project_id == timeSheetData.project_id });
             // if (checkModule.length > 0) {
             //     setTimeSheetData({
             //         ...timeSheetData,
             //         module_id: checkModule[0].module_id,
             //         module_name: checkModule[0].module_name,
             //         menu_id: checkModule[0].value,
             //         menu_name: checkModule[0].label,
             //     });
             //     timeSheetData.module_id = checkModule[0].module_id;
             //     timeSheetData.module_name = checkModule[0].module_name;
             //     timeSheetData.menu_id = checkModule[0].value;
             //     timeSheetData.menu_name = checkModule[0].label;
             // } else {
             setTimeSheetData({
                 ...timeSheetData,
                 module_id: '',
                 module_name: '',
                 menu_id: '',
                 menu_name: '',
             });
             timeSheetData.module_id = '';
             timeSheetData.module_name = '';
             timeSheetData.menu_id = '';
             timeSheetData.menu_name = '';
             // };
             setTest({ ...test, test: '' });
             test.test = '';
         }}
     />
 </div>

                    ):(

<div className='timesheetAddBoxDateRang'>
<div style={{ width: '15%' }}>PROJECT :</div>
<Input
      className='timesheetInput'
      value={timeSheetData.project_name}
      style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.ts_process_id == '' || timeSheetData.ts_process_id == null || timeSheetData.ts_process_id == undefined ? 'red' : '#0047FF'}` */ }}
      onChange={(e)=>{
        let check = timesheetProjectList.filter((e2)=>{return e2.label == e.target.value});
        console.log(check)
        
        setTimeSheetData({ ...timeSheetData, project_id: check.length > 0 ? check[0].value : '', project_name: e.target.value });
      }}
/>
</div>
                    )}
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>PROCESS :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.ts_process_id == '' || timeSheetData.ts_process_id == null || timeSheetData.ts_process_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetProcessList}
                                    value={timeSheetData.process_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            ts_process_id: f.value,
                                            process_name: f.label,
                                        });
                                        timeSheetData.ts_process_id = f.value;
                                        timeSheetData.process_name = f.label;
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>MODULE :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.module_id == '' || timeSheetData.module_id == null || timeSheetData.module_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetModuleList.filter((ef) => {
                                        return ef.project_id == timeSheetData.project_id
                                    })}
                                    value={timeSheetData.module_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            module_id: f.value,
                                            module_name: f.label,
                                        });
                                        timeSheetData.module_id = f.value;
                                        timeSheetData.module_name = f.label;
                                        // let checkModule = timesheetMenuList.filter((ef) => { return ef.module_id == timeSheetData.module_id });
                                        // if (checkModule.length > 0) {
                                        //     setTimeSheetData({
                                        //         ...timeSheetData,
                                        //         menu_id: checkModule[0].value,
                                        //         menu_name: checkModule[0].label,
                                        //     });
                                        //     timeSheetData.menu_id = checkModule[0].value;
                                        //     timeSheetData.menu_name = checkModule[0].label;
                                        // } else {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            menu_id: '',
                                            menu_name: '',
                                        });
                                        timeSheetData.menu_id = '';
                                        timeSheetData.menu_name = '';
                                        // };
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                            <div className='timesheetAddBoxDateRang'>
                                <div style={{ width: '15%' }}>MENU :</div>
                                <Select
                                    showSearch
                                    className='timesheetInput'
                                    style={{ flexGrow: 1, width: 'fit-content'/* , border: `1px solid ${timeSheetData.menu_id == '' || timeSheetData.menu_id == null || timeSheetData.menu_id == undefined ? 'red' : '#0047FF'}` */ }}
                                    options={timesheetMenuList.filter((ef) => {
                                        return ef.module_id == timeSheetData.module_id && ef.project_id == timeSheetData.project_id
                                    })}
                                    value={timeSheetData.menu_name}
                                    onChange={(_, f) => {
                                        setTimeSheetData({
                                            ...timeSheetData,
                                            menu_id: f.value,
                                            menu_name: f.label,
                                        });
                                        timeSheetData.menu_id = f.value;
                                        timeSheetData.menu_name = f.label;
                                        setTest({ ...test, test: '' });
                                        test.test = '';
                                    }}
                                />
                            </div>
                        </ConfigProvider>

                        <div className='timesheetAddBoxDateRang'>
                            <div>DESCRIPTION :</div>
                            <TextArea
                                className='timesheetInput'
                                value={timeSheetData.ts_project_detail}
                                autoSize={{
                                    minRows: 1
                                }}
                                style={{ textAlign: 'start', border: `1px solid ${/* timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined */descIsNull ? 'red' : '#0047FF'}`  ,maxHeight: '250px'}}
                                onChange={(f) => {
                                    setTimeSheetData({ ...timeSheetData, ts_project_detail: f.target.value });
                                    timeSheetData.ts_project_detail = f.target.value;
                                    if (descIsNull) {
                                        toggle_descIsNull();
                                    }
                                    setTest({ ...test, test: '' });
                                    test.test = '';
                                }}
                            />
                        </div>
                        <div className='btnSave' onClick={() => {
                            if (timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined || (timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0) /* || timeSheetData.project_id == '' || timeSheetData.ts_process_id == '' || timeSheetData.module_id == '' || timeSheetData.menu_id == '' */) {
                                setAlertMsgEditMsg({
                                    ...alertMsgEditMsg,
                                    menuTitle: 'ERROR',
                                    isError: true,
                                    message: 'Please fill out the information completely.'
                                });
                                alertMsgEditMsg.menuTitle = 'ERROR';
                                alertMsgEditMsg.isError = true;
                                alertMsgEditMsg.message = 'Please fill out the information completely.';
                                if (timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0) {
                                    if (!timeIsNull) {
                                        toggle_timeIsNull();
                                    }
                                }
                                if (timeSheetData.ts_project_detail == '' || timeSheetData.ts_project_detail == null || timeSheetData.ts_project_detail == undefined) {
                                    if (!descIsNull) {
                                        toggle_descIsNull();
                                    }
                                }
                                toggle_alertMsgEdit();
                            } else {
                                toggle_alertSaveConfirm();
                                getCountTodayTimeSheet();
                                setTest({ ...test, test: '' });
                                test.test = '';
                            }
                        }}
                        >SAVE</div>
                    </div>
                </div>


                <ConfigProvider
                    theme={{
                        token: {

                            /*     colorPrimary: '#85BFFF', */
                            colorInfo: '#85BFFF',
                            colorBorder: '#85BFFF',
                            colorText: '#FFF',

                            colorTextDescription: '#ffff',
                            colorBgContainer: '#000',

                            zIndexPopupBase: 999999,
                            colorPrimaryBg: 'blue',//สีตอน Active ใน Select
                            colorBgBase: '#001D5F',//พื้นหลัง select ตอนกด down ลงมา

                            colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder


                            colorFillTertiary: 'rgba(0,0,0,0.4)',// สีตอน hover ใน Select


                            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker




                            colorPrimaryBg: '#85BFFF',
                            /*       
                                   colorBgLayout:'red',
                                   colorFillQuaternary:'red', */


                        }
                    }}
                >
                    <div className={`historyReportBackDrop ${isOpenHistoryReport ? 'opened' : 'closed'}`} onClick={() => { toggle_history_report(); }}></div>
                    <div className={`historyReport ${isOpenHistoryReport ? 'opened' : 'closed'}`}>
                        <div className='historyReportBody' /* style={{maxHeight:'500px'}} */>
                            <div className='fontHeader'>Report Time Sheet</div>
                            <div style={{ display: 'flex', borderBottom: '1px solid rgba(133, 191, 255, 1)', paddingBottom: '5px', gap: '15px', alignItems: 'flex-end' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>START DATE</div>
                                    <DatePicker
                                        value={genHistory.start_date}
                                        disabledDate={(current) => {
                                            return current && current > genHistory.end_date;
                                        }}
                                        placeholder='Start Date'
                                        onChange={(date) => {
                                            if (date) {
                                                setGenHistory({ ...genHistory, start_date: date });
                                            }
                                        }}
                                    />
                                </div>


                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div>END DATE</div>
                                    <DatePicker
                                        disabledDate={(current) => {
                                            return current && current < genHistory.start_date;
                                        }}

                                        placeholder='End Date'
                                        value={genHistory.end_date}
                                        onChange={(date) => {

                                            if (date) {
                                                setGenHistory({ ...genHistory, end_date: date });
                                            }

                                        }} />
                                </div>


                                <div
                                    onClick={() => {
                                        gettimesheertreport();
                                    }}
                                    style={{ background: 'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)', height: '34px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '7px', width: '200px', cursor: 'pointer', color: '#fff', fontSize: '20px' }}>Generate</div>


                            </div>




                            {loadinddisplay === true ? <>
                                <div style={{display:'flex',height:'340px',overflowY:'auto',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                                <div class="loadingspinnerna" style={{ width: '180px', height: '180px' }}></div>
                            
                                </div>
                            </>
                            :
                            <>
                              <div style={{display:'flex',height:'340px',overflowY:'auto'}}>
                            <table style={{ width: '100%',height:'fit-content' }}>
                                <tr style={{ background: 'linear-gradient(to right, rgba(0, 40, 156, 1) 0%, rgba(0, 71, 255, 1) 50%, rgba(0, 40, 156, 1) 100%)', height: '34px',position:'sticky',top:0 }}>
                                    <td style={{ textAlign: 'center', fontSize: '18px' }}>No.</td>
                                    <td style={{ textAlign: 'center', fontSize: '18px' }}>Generate Version</td>
                                    <td style={{ textAlign: 'center', fontSize: '18px' }}>Download</td>
                                </tr>
                                {
                                    historyTimeSheetList.map((e, index) => {
                                        return (<>
                                            <tr style={{height: '34px'}}>
                                                <td style={{ textAlign: 'center', fontSize: '16px' }}>{index +1}</td>
                                                <td style={{ textAlign: 'center', fontSize: '16px',textTransform:'none' }}>{e.rtv_file_name}</td>
                                                <td style={{ textAlign: 'center', fontSize: '16px' }}><a href = {`${e.href}`} download={e.rtv_file_name} target='_blank'><DownloadOutlined style={{cursor:'pointer'}}
                                                
                                                />
                                                </a>
                                                </td>
                                            </tr>
                                        </>)
                                    })
                                }
                            </table>
                            </div>
                            
                            </>
}
                        
                          
                  

                        </div>

                        <div className='boxAddConfirmFooter'>
                            <button className=' btn-outline-danger' style={{ padding: '5px', fontSize: '16px', height: '40px', cursor: 'pointer' }}
                              onClick={()=>{toggle_history_report();}}
                            > Cancel </button>
                        </div>

                    </div>
                </ConfigProvider>







            </ConfigProvider>

            <AlertConfrimDelete
                isOpen={alertMsgDelete}
                mainFuc={deleteTimesheetById}
                toggle_confrim={toggle_alertMsgDelete}
            />
            <AlertMassge
                isOpen={alertMsgEdit}
                toggle_alertKick={toggle_alertMsgEdit}
                message={alertMsgEditMsg}
            // mainFuc={}
            />
            <AlertConfrim
                isOpen={alertMsgConfirm}
                toggle_alertKick={toggle_alertMsgConfirm}
                mainFuc={editTimesheet}
            />
            <AlertConfrim
                isOpen={alertSaveConfirm}
                toggle_alertKick={toggle_alertSaveConfirm}
                mainFuc={saveTimeSheet}
            />
        </>) : (<></>))
    )
}

export default Timesheet

