import React, { useEffect, useMemo, useState } from "react";
import "./SpaceshipConsolCSS.css";
import "./effect.scss";
import Req1 from "./ImageSVG/Req1.svg";
import Req2 from "./ImageSVG/Req2.svg";
import Req3 from "./ImageSVG/Req3.svg";
import Req4 from "./ImageSVG/Req4.svg";
import AD1 from "./ImageSVG/AD1.svg";
import AD2 from "./ImageSVG/AD2.svg";
import TR1 from "./ImageSVG/TR1.svg";
import TR2 from "./ImageSVG/TR2.svg";
import TR3 from "./ImageSVG/TR3.svg";
import all1 from "./ImageSVG/all1.svg";
import CU1 from "./ImageSVG/CU1.svg";
import { transform } from "lodash";

import AddElement from "./components/addElement/index";

const Tooltip = ({ title, detail, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="tooltip-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div class="tooltip">
          <svg class="tooltip-arrow" width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          
            <line x1="0" y1="10" x2="15" y2="10" stroke="#002b99" stroke-width="1" />
            <circle cx="15" cy="10" r="3" fill="#0047ff" />
          </svg>
          <div class="tooltip-content">
            <div class="tooltip-title">{title}</div>
            <div class="tooltip-detail">{detail}</div>
          </div>
        </div>
      )}
    </div>
  );
};




function Consoledetail() {
  const [addElementIsOpen, setAddElementIsOpen] = useState(false);
  const toggleAddElementIsOpen = () => { setAddElementIsOpen(!addElementIsOpen) };
  const numStars = 50; // จำนวนดาวที่ต้องการ
  const stars = Array.from({ length: numStars }, (_, index) => (
    <div
      key={index}
      className="star"
      style={{
        left: `${Math.random() * 100}vw`,
        top: `${Math.random() * 100}vh`,
      }}
    ></div>
  ));
  return (
    <>
      <div className="SCbgLayer2">

        <div className="bodySC">


          <div className="parent-container-logo">
            {/*   <div className="IconSpaceShip"></div> */}
          </div>
          {/* <div
            className="Box-containerC"
            style={{ transform: "Rotate(356deg)", marginTop: "72px" }}
          >
            <div
              className="BoxElementName2"
              style={{ marginLeft: "274px", fontSize: "18px" }}
            >
              {" "}
              Planning{" "}
            </div>
            <div className="BoxOutElementCol1-2">
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
            </div>
          </div>
          <div
            className="Box-containerC"
            style={{ transform: "Rotate(5deg)", marginTop: "72px" }}
          >
            <div
              className="BoxElementName2"
              style={{ marginRight: "auto", fontSize: "18px" }}
            >
              {" "}
              Testing{" "}
            </div>
            <div className="BoxOutElementCol1-2">
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
              <div className="BoxElement2"> </div>
            </div>
          </div> */}
          <div className="parent-container">

            <div className="Wing-left-border"></div>
            <div className="Wing-left"></div>


            <div className="Wing-Right-border"></div>
            <div className="Wing-Right"></div>
            <div className="ParallelograpPath-right-border">
              <div className="ParallelograpPath-right"></div>
            </div>
            <div className="ParallelograpPath-left-border">
              <div className="ParallelograpPath-left"></div>
            </div>
            <div className="ONparalle-border-right"></div>
            <div className="ONparallrl-right"></div>
            <div className="ONparalle-border-left"></div>
            <div className="ONparallrl-left"></div>
            <div className="HexagonClipPathBorder">
              <div className="HexagonClipPath">
                <div className="stars">{stars}</div>;
              </div>

              <div className="box-1"></div>
              <div className="flashPoint"></div>
            </div>
            <div className="UnderHex-border">
            </div>

            <div className="UnderHex">
              <div className="BoxOutSpace">
                <div className="SpaceName" style={{ fontSize: "25px" }}>Raizeros</div>
              </div>
            </div>
            <div className="footer-borderSC"> </div>
            <div className="footerSPC">
              <div className="ExpireDate">EXPIRE DATE: 31 JAN 2027</div>
            </div>

            <div className="BoxSpaceShipName">
              <div className="SpaceshipName">SPACESHIP NAME</div>
            </div>
            <div className="linetunder-left"></div>
            <div className="linetunder-right"></div>
          </div>

        </div>

        <div className="SCcontainer">
          <div className="col1-row1">

            <div
              className="Box-container"
              style={{ transform: "Rotate(356deg)", marginTop: "72px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginLeft: "auto", fontSize: "15px" }}
              >
                {" "}
                Planning{" "}
              </div>
              <div className="BoxOutElementCol1">
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
              </div>
            </div>
          </div>

          <div className="col1-row3">
            <div
              className="Box-container"
              style={{
                transform: "Rotate(359deg)",
                marginBottom: "-29px",

              }}
            >
              <div
                className="BoxElementName"
                style={{ marginBottom: "2px", fontSize: "15px" }}
              >
                Analysis and Design
              </div>
              <div className="BoxOutElementCol3" style={{ padding: "10px" }}>
                <div className="BoxElement" style={{ margin: "3px" }}>



                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${AD1})`, transform: "Rotate(4deg)" }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{
                      backgroundImage: `url(${AD2})`,
                      width: "38px",
                      height: "35px", transform: "Rotate(5deg)"
                    }}
                  >
                    <Tooltip title="ScreenShot" detail="Elementไว้สำหรับการนำภาพหน้าจอเข้าสู่ระบบแล้วสามารถกำหนดเวอร์ชั่นได้">

                      <div
                        className="IconElement"
                        style={{
                          backgroundImage: `url('image1.jpg')`,
                          transform: "rotate(4deg)",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
              </div>
            </div>
            <div
              className="Box-container"
              style={{ transform: "Rotate(359deg)", marginRight: "30px", marginBottom: "-37px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginBottom: "2px", fontSize: "15px" }}
              >
                Requirement
              </div>
              <div className="BoxOutElementCol3" style={{ padding: "10px" }}>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${Req1})`, width: "37px", transform: "Rotate(5deg)" }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{
                      backgroundImage: `url(${Req2})`, transform: "Rotate(5deg)", width: "37px",
                      marginRight: "6px",
                      marginTop: "6px",
                    }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${Req3})` }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${Req4})` }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col1-row4">
            <div
              className="Box-container"
              style={{ transform: "Rotate(356deg)", marginLeft: "8px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginLeft: "auto", fontSize: "15px" }}
              >
                {" "}
                Coding and Unit Test{" "}
              </div>
              <div className="BoxOutElementCol4">
                <div className="BoxElement">
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${CU1})` }}
                  ></div>
                </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
              </div>
            </div>
          </div>
          <div className="col1-row5"></div>
          <div className="col2-row1">
            <div className="LogoSpaceship"></div>
          </div>
          <div className="col2-row2">
            <div className="BoxOutTool">
              <div className="BoxSet">
                <div className="BoxTool" onClick={() => {
                  toggleAddElementIsOpen();
                }}>
                </div>
                <div className="BoxBackName">BACK TO SPACE</div>
              </div>
              <div className="BoxSet">
                <div className="BoxToolAddelement" onClick={() => {
                  toggleAddElementIsOpen();
                }}>
                </div>
                <div className="BoxBackName">ADD ELEMENT</div>

              </div>
            </div>
          </div>
          <div className="col2-row4">
            <div className="BoxFooter">
              <div
                className="Box-container"

              >
                <div className="FooterStack" style={{ color: "yellow" }}>
                  {" "}
                  1{" "}
                </div>
                RANK
              </div>
              <div
                className="Box-container"

              >
                <div className="FooterStack" style={{ color: "purple" }}>
                  {" "}
                  12{" "}
                </div>
                ELEMENT
              </div>
              <div
                className="Box-container"

              >
                <div className="FooterStack" style={{ color: "red" }}>
                  {" "}
                  18{" "}
                </div>
                SEATS
              </div>
            </div>
          </div>

          <div className="col2-row3">
            <div className="ImgSpaceShip"></div>
          </div>
          <div className="col3-row1">
            <div
              className="Box-container"
              style={{ transform: "Rotate(5deg)", marginTop: "72px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginRight: "auto", fontSize: "15px" }}
              >
                {" "}
                Testing{" "}
              </div>
              <div className="BoxOutElementCol1">
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
              </div>
            </div>
          </div>


          <div className="col3-row3">
            <div
              className="Box-container"
              style={{
                transform: "Rotate(7deg)",
                marginTop: "15px",
                marginLeft: "49px",
              }}
            >
              <div
                className="BoxElementName"
                style={{ marginBottom: "2px", fontSize: "15px" }}
              >
                Training
              </div>
              <div className="BoxOutElementCol3" style={{ padding: "10px" }}>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${TR1})`, transform: 'rotate(352deg)' }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${TR2})` }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${TR3})`, transform: 'rotate(353deg)' }}
                  ></div>
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
              </div>
            </div>
            <div
              className="Box-container"
              style={{ transform: "Rotate(7deg)", marginBottom: "30px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginBottom: "2px", fontSize: "15px" }}
              >
                Installation
              </div>
              <div className="BoxOutElementCol3" style={{ padding: "10px" }}>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
                <div className="BoxElement" style={{ margin: "3px" }}>
                  {" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col3-row4">
            <div
              className="Box-container"
              style={{ transform: "Rotate(3deg)", marginRight: "20px" }}
            >
              <div
                className="BoxElementName"
                style={{ marginRight: "auto", fontSize: "15px" }}
              >
                {" "}
                All{" "}
              </div>
              <div className="BoxOutElementCol4">
                <div
                  className="BoxElement"
                  style={{ backgroundColor: "#0a2268" }}
                >
                  <div
                    className="IconElement"
                    style={{ backgroundImage: `url(${all1})`, transform: 'rotate(353deg)' }}
                  ></div>
                </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
                <div className="BoxElement"> </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddElement
        isOpen={addElementIsOpen}
        toggleIsOpen={toggleAddElementIsOpen}
      />
    </>
  );
}
export default Consoledetail;
