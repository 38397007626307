import React from 'react'
import './inputCustomStyle.css'
import { NumericFormat, PatternFormat } from 'react-number-format';

function Patternformat({
    placeholder,
    className,
    style,
    value,
    onChange,
    disabled,
    type,
}) {
    return (
        <>
    {/*         <div className={`inputShadowStyle `}   > */}
                {/*       <div className={`inputOuterStyle_1 ${className || ''}`} style={style}>
        <div className="inputOuterStyle_2"> */}
                <PatternFormat
                    /*   style={{ border: '1px solid #ddd', paddingRight: '30px', height: '38px' }} */
                    placeholder={placeholder}
                    type="text"
          
                    format={placeholder != "PHONE NUMBER" ? "#-####-#####-##-#" : "###-###-####"}
                    className="areaTEst"
                    required="false"
                    value={value}
                    onChange={onChange}
                />
       {/*      </div> */}
            {/*     </div>
      </div> */}
        </>
    )
}

export default Patternformat
