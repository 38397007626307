import React, { useEffect,useState,useRef } from 'react'
import { Input,Select, Space, Tag, theme, Tooltip, ConfigProvider } from 'antd';
import axios from 'axios';
import Configs from "../../../config";
import './modalSpaceShip.css'


function ModalAddMenu({
  isOpen,
  toggle_modal_add_menu,
  project_id,
  moduleOption,
  getProjectDetail
  
}){

  const [MenuDetail,setMenuDetail] = useState({
    module_id:'',
    menu_name:'',
  })

    const [ModuleDetail,setModuleDetail] = useState({
    module_name:''
  })

  
  const [moduleOptions,setModuleOptions] = useState([]);

  const [errorMessage,setErrorMessage] = useState({
    module_err:'',
    menu_err:''
  });
   const [errorResMessage,setErrorResMessage] = useState('');

  
  useEffect(()=>{
    setErrorMessage({...errorMessage, module_err:'',menu_err:''});
    setErrorResMessage('');
    setMenuDetail({...MenuDetail,menu_name:"",module_id:''})
    MenuDetail.menu_name = '';
    MenuDetail.module_id = '';

   /*  setModuleOptions(moduleOption); */
  },[isOpen])

  useEffect(()=>{

    setModuleOptions(moduleOption);
  },[moduleOption])

  function saveAddModule(){
    let temp ={
      project_id:project_id,
      module_id:MenuDetail.module_id,
      menu_name:MenuDetail.menu_name.trim() || ''

    }


    console.log(temp)
    let countERR =0;
    let module_err ='';
    let menu_err ='';
    if(temp.module_id == ''){
      module_err = 'Please Select Your Module !!!';

      countERR++;
    }
    
    if(temp.menu_name == ''){
      menu_err = 'Please Enter Your Menu Name !!!';
    
        countERR++;
    }
    
    if(countERR > 0 ){
        setErrorMessage({...errorMessage,menu_err:menu_err,module_err:module_err})
    }else {
      console.log("HERERE")
      axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/moduleMenu/addMenu`,
        headers: {
          /*  Authorization: getToken(), */
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp
  
      })
        .then( function (response) {
          if(response.data){

      /*       toggle_modal_status();
            getProjectDetail(projectDetail.project_id) */
            toggle_modal_add_menu();
            getProjectDetail(project_id)
            setErrorMessage({...errorMessage, module_err:'',menu_err:''});
            setErrorResMessage('');
            setMenuDetail({...MenuDetail,menu_name:"",module_id:''})
             
     
           
          }
  
          /* ProjectTimelinereport('2') */
  
        })
        .catch(function (error) {
          console.log(error);
          setErrorResMessage(error.response.data.error[0].errorDis)
          /*  Swal.fire({
             icon: "success",
             title: error,
             showConfirmButton: false,
             timer: 1500,
         }) */
        });

    }


  }


return (

    <>
    
  
     <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#85BFFF',
                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',
                
                colorTextDescription:'#FFFFFF',
                colorBgContainer: '#000',
            
                zIndexPopupBase:999999,
                colorPrimaryBg:'blue',//สีตอน Active ใน Select
                colorBgBase:'#778899',//พื้นหลัง select ตอนกด down ลงมา
        
                colorTextQuaternary:'#B0C4DE',// สีตัวหนังสือของ placeholder

              
                colorFillTertiary:'#87CEFA',// สีตอน hover ใน Select
              
              
    
              },
            /*   backgroundColor:'#000' */
            }}
          >
      <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={()=>{toggle_modal_add_menu();}}>      </div>
    <div className={`detailBoxModuleMenu ${isOpen ? 'opened':'closed'}`}    style= {{top:`30%`,left:`50%`,position:'fixed',transform: 'translate(-50%, 50%)',width:'30%'}}
              
  
    >
      <div className = "table-responsive" >
        <div className='row mt-2' >
     
            <div className='col-12'><h4>Add Menu </h4><font color = "red">{errorResMessage}</font></div>
            <div className='col-12 mt-2'>
            Module<font color ="red">*</font> <font className='ml-4' color ="red">{errorMessage.module_err}</font><br/>
            <Select
    showSearch
    style={{width: '100%'}}
      
     
    
    placeholder="Search to Select Module..."
    optionFilterProp="children"
    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes((input || '').trim().toLocaleLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    options={moduleOptions}
    onChange={(value)=>{
      setErrorResMessage('');
      setErrorMessage({...errorMessage,module_err:''});
      setMenuDetail({...MenuDetail,module_id:value})}}
   value={moduleOptions.filter((e)=> {return e.value === MenuDetail.module_id})}   
  />
              
            </div>
            <div className='col-12 mt-2'>
            Menu Name<font color ="red">*</font> <font className='ml-4' color ="red">{errorMessage.menu_err}</font>
              <Input style= {{backgroundColor:'#000'}} type ='text' placeholder='Module Name...'
              value = {MenuDetail.menu_name}
               onChange={(e)=>{
                setErrorResMessage('');
                setErrorMessage({...errorMessage,menu_err:''});
                setMenuDetail({...MenuDetail,menu_name:e.target.value})
               }}
              
              />
            </div>
          
            <div className='col-12'>
          
            </div>
          
  
         
    </div>
    </div>
    <br/><br/>
    <div style= {{position:'absolute',bottom:'5px',right:'5px'}}>
            <button className=' btn-outline-primary mr-2' style ={{padding:'5px'}} onClick={()=>{saveAddModule();}}> Save </button> <button className=' btn-outline-danger' style ={{padding:'5px'}} onClick={()=>{toggle_modal_add_menu();}}> Cancel </button>
              </div>
    
    </div>
     
    </ConfigProvider>
   
 
    </>
)


}
export default ModalAddMenu;


