import React, { useState } from "react";
import Slider from "react-slick";
import { Modal } from "react-bootstrap";

function Achievement() {
  const [modal, setModal] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="brand-area brand-bg" style={{height:"350px"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section-title home-four-title text-center mb-30">
              <h2>Contact</h2>
            </div>
          </div>
        </div>
        <Slider className="row brand-active" {...settings}>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo01.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo02.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo03.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo04.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo05.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo06.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
          <div className="col-xl">
            <div className="brand-item">
              <img
                src="assets/img/brand/brand_logo03.png"
                alt=""
                onClick={() => setModal(true)}
              />
            </div>
          </div>
        </Slider>
      </div>

      {/* Modal */}
      <Modal className="fade" show={modal} size="lg">
        <Modal.Header className="align-items-center d-flex">
          <Modal.Title>
            <div className="modal-text-header">Achievement</div>
          </Modal.Title>
          <div>
            <button className="btn" onClick={() => setModal(false)}>
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center mt-3">
            <img
              className="justify-content-center"
              src="assets/img/brand/brand_logo03.png"
              alt=""
              onClick={() => setModal(true)}
            />
          </div>
          <div className="modal-text-body mt-5">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem
            ipsa, quisquam harum laudantium error natus deleniti molestias
            aspernatur consequuntur, esse explicabo odit exercitationem odio
            pariatur repellat culpa labore iure. Nemo! Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Temporibus aut alias odio
            quisquam cum? Cupiditate odio tempora velit eos assumenda recusandae
            at temporibus officiis deserunt autem, tenetur maiores
            necessitatibus labore.
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Achievement;
