import React, { useEffect, useState, useRef } from 'react'
import './projectstate.css'

import Configs from "../../../config";
import axios from "axios";
import moment from "moment";

import PsBg from './Image/it_process_BG.png'
import PsTTTSS from './Image/TTTSPACESHIPLOGO.png'
import PsTTT from './Image/LogoTTT.png'
import PsRocket from './Image/rocket.png'
import PsIsCheck from './Image/IsCheck.png'
import defualtUser from '../../../assets/img/userlogo/defultUser.png'
import ViewUserProfile from '../../component/ViewUserProfile/'
import PsRocketFlow from './Image/RocketFlow.gif'
import PsRocketFlowStop from './Image/RocketFlowStop.png'
import ModelAlertMassge from "../../../components/AlertMassge/AlertMassge";


import { Checkbox, ConfigProvider } from 'antd';

const ProjectState = ({
    isOpen,
    setProjectState,
    close,
    projectId
}) => {
    const [test, setTest] = useState({ test: '' })
    const [isOpenView, setIsOpenView] = useState(false);
    const toggle_view = () => setIsOpenView(!isOpenView);
    const [pathProfile, setPathProfile] = useState(null);
    const [personalInfoCheck, setPersonalInfoCheck] = useState({
        isOpen: false,
        submode: null
    });
    const [tempValue, setTempValue] = useState({
        user_id: ''
    });

    const [isRocketFlow, setIsRocketFlow] = useState(false);
    const toggle_isRocketFlow = () => setIsRocketFlow(!isRocketFlow);

    const [isOpenInfoDetail,setIsOpenInfoDetail] = useState(false);
    const toggle_Info_Detail = () =>setIsOpenInfoDetail(!isOpenInfoDetail);

    const [infoDeatil,setInfoDeatil] = useState({
        state_name:'',
        state_info:'',
        x:0,
        y:0
    })

    function setValueAndOpenView(value) {
        setTempValue({ user_id: value })
        setPersonalInfoCheck({ isOpen: true })
        personalInfoCheck.isOpen = true
        /*  toggle_view() */
        // console.log('testlog value', value);
        /*        setTest({test:''});
               test.test = ''; */


    }

    const [checkState, setCheckState] = useState([
        {
            stateId: 'ead061f9-cf19-4a29-986d-5534d49d4331',
            stateNo: 1,
            statePass: true,
            stateName: 'TOR',
            stateArc1: 'PROJECT TIMELINE',
            stateArc2: 'SITE MAP',
            stateArc3: 'INITIALIZE TEAM'
        },
        {
            stateId: '276354d0-19b3-4059-ae38-a34ecdf9f02a',
            stateNo: 2,
            statePass: true,
            stateName: 'USER ROLES',
            stateArc1: 'MAPPING USER',
            stateArc2: 'WORK FLOW',
            stateArc3: 'INTERVIEW REQUIRMENT'
        },
        {
            stateId: '018a3e45-cd87-43d8-9621-f72ba4ee1b60',
            stateNo: 3,
            statePass: true,
            stateName: 'SRS',
            stateArc1: 'REVIEW & CONFIRM DOC.',
            stateArc2: 'TECH STACK',
            stateArc3: 'SCREEN MOCKUP'
        },
        {
            stateId: '766a5072-8ea1-4e8e-a023-8f67fafee29a',
            stateNo: 4,
            statePass: true,
            stateName: 'SDD',
            stateArc1: 'TECH TEAM REVIEW DOC.',
            stateArc2: 'OPEN API',
            stateArc3: 'ER DIAGRAM/DATA DIC.'
        },
        {
            stateId: '8dc2a0f9-bb76-410b-a461-7b4e9181a1b2',
            stateNo: 5,
            statePass: true,
            stateName: 'TEST CASE',
            stateArc1: 'REVIEW & INSER TEST CASE',
            stateArc2: 'TEST RESULT',
            stateArc3: 'AUTOMATE TEST'
        },
        {
            stateId: '541754f7-87e1-486e-a66f-e1119ef9bad9',
            stateNo: 6,
            statePass: true,
            stateName: 'UAT',
            stateArc1: 'UAT ON CUSTOMER SITE',
            stateArc2: 'TEST RUN',
            stateArc3: 'PRODUCTION'
        },
        {
            stateId: 'fd72d1c6-06cb-49c5-be48-6c7ed00275b3',
            stateNo: 7,
            statePass: true,
            stateName: 'MA/SLA',
            stateArc1: 'MA/SLA DOC.',
            stateArc2: 'FEEDBACK HANDLING',
            stateArc3: 'MONITOR BUGS'
        }

    ])

    const [projectStateData, setProjectStateData] = useState({
        member: []
    });
   
    const [stateMember, setStateMember] = useState([])

    function setPsClose() {
        setProjectState({ isOpen: false })
        if (close !== undefined) {
            close(false)
        }

    }

    const elementRef1 = useRef();
    const elementRef2 = useRef();
    const elementRef3 = useRef();
    const elementRef4 = useRef();
    const elementRef5 = useRef();
    const elementRef6 = useRef();
    const elementRef7 = useRef();
    const [position, setPosition] = useState([
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 },
        { x: 0, y: 0, x2: 0, y2: 0, w: 0, h: 0 }
    ]);

    async function handleResize() {
        const x1 = elementRef1.current.offsetLeft;
        const y1 = elementRef1.current.offsetTop;
        const w1 = elementRef1.current.offsetWidth;
        const h1 = elementRef1.current.offsetHeight;

        const x2 = elementRef2.current.offsetLeft;
        const y2 = elementRef2.current.offsetTop;
        const w2 = elementRef2.current.offsetWidth;
        const h2 = elementRef2.current.offsetHeight;

        const x3 = elementRef3.current.offsetLeft;
        const y3 = elementRef3.current.offsetTop;
        const w3 = elementRef3.current.offsetWidth;
        const h3 = elementRef3.current.offsetHeight;

        const x4 = elementRef4.current.offsetLeft;
        const y4 = elementRef4.current.offsetTop;
        const w4 = elementRef4.current.offsetWidth;
        const h4 = elementRef4.current.offsetHeight;

        const x5 = elementRef5.current.offsetLeft;
        const y5 = elementRef5.current.offsetTop;
        const w5 = elementRef5.current.offsetWidth;
        const h5 = elementRef5.current.offsetHeight;

        const x6 = elementRef6.current.offsetLeft;
        const y6 = elementRef6.current.offsetTop;
        const w6 = elementRef6.current.offsetWidth;
        const h6 = elementRef6.current.offsetHeight;

        const x7 = elementRef7.current.offsetLeft;
        const y7 = elementRef7.current.offsetTop;
        const w7 = elementRef7.current.offsetWidth;
        const h7 = elementRef7.current.offsetHeight;

        const x11 = x2 - x1
        const y11 = y2 - y1

        const x12 = x3 - x2
        const y12 = y3 - y2

        const x13 = x4 - x3
        const y13 = y4 - y3

        const x14 = x5 - x4
        const y14 = y5 - y4

        const x15 = x6 - x5
        const y15 = y6 - y5

        const x16 = x7 - x6
        const y16 = y7 - y6

        setPosition([
            { x: x1, y: y1, x2: x11, y2: y11, w: w1, h: h1 },
            { x: x2, y: y2, x2: x12, y2: y12, w: w2, h: h2 },
            { x: x3, y: y3, x2: x13, y2: y13, w: w3, h: h3 },
            { x: x4, y: y4, x2: x14, y2: y14, w: w4, h: h4 },
            { x: x5, y: y5, x2: x15, y2: y15, w: w5, h: h5 },
            { x: x6, y: y6, x2: x16, y2: y16, w: w6, h: h6 },
            { x: x7, y: y7, x2: x16, y2: y16, w: w7, h: h7 }
        ])
    }

    function getProjectStateById() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/project/getProjectStateById/${projectId}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                setProjectStateData(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(async () => {
        // await handleResize() // initial call to get position of the element on mount
        if (isOpen) {
            setIs_auto(true)
            handleResize()
            // console.log('testlog', projectActive);
            // setStateMember(projectActive.member)
            getProjectStateById();
            getProjectStateList();
            getProjectStateCheckedList();

            window.addEventListener("resize", handleResize);

            return () => window.removeEventListener("resize", handleResize);
        }
    }, [isOpen]);

    const [projectStateList, setProjectStateList] = useState({
        state1: {},
        state2: {},
        state3: {},
        state4: {},
        state5: {},
        state6: {},
        state7: {},
    })

    const [projectStateCheckedList, setProjectStateCheckedList] = useState([]);
    const [projectStateCheckedListManual, setProjectStateCheckedListManual] = useState([]);
    // console.log('projectStateCheckedListManual', projectStateCheckedListManual)
    function getProjectStateList() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/projectState/getStateList`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                setProjectStateList({
                    ...projectStateList,
                    state1: response.data[0] ? response.data[0] : {},
                    state2: response.data[1] ? response.data[1] : {},
                    state3: response.data[2] ? response.data[2] : {},
                    state4: response.data[3] ? response.data[3] : {},
                    state5: response.data[4] ? response.data[4] : {},
                    state6: response.data[5] ? response.data[5] : {},
                    state7: response.data[6] ? response.data[6] : {},
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    function getProjectStateCheckedList() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/projectState/getStateListChecked/${projectId}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
        .then(async function (response) {
            setProjectStateCheckedList(response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    function getProjectStateCheckedListManual() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/projectState/getStateListCheckedManual/${projectId}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
        .then(async function (response) {
            let temp = [];
            let for_push = [...projectStateCheckedList];

            if(response.data.length === 0){
                for(let item_ of for_push){
                    temp.push({
                        prost_id: item_.prost_id,
                        psm_project_id: projectId,
                        checked: item_.checked
                    });
                }
                setProjectStateCheckedListManual(temp)
            }else{
                setProjectStateCheckedListManual(response.data)
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    const [countdown, setCountdown] = useState(0);
    useEffect(() => {
        if (isOpen) {
            const interval = setInterval(() => {
                setCountdown(countdown + 1);
                if (countdown == 0) {
                    toggle_isRocketFlow();
                } else if (countdown >= 11) {
                    setIsRocketFlow(false);
                    setCountdown(0);
                }
            }, 2000); // อัปเดตเวลาคูลดาวน์ทุกๆ 1 วินาที
            return () => clearInterval(interval);
        } else {
            setIsRocketFlow(false);
            setCountdown(0);
        }
    }, [isOpen, countdown]);


    //-----------------------------New------------------------------------------------
    const [Is_auto , setIs_auto] = useState(true);
    const [Is_openModalSave ,setIs_openModalSave] = useState(false);
    const toggle_save = () => {setIs_openModalSave(!Is_openModalSave)}

    //-----------------Create-------------------------------------
    const [messageError, setMessageError] = useState({
        message: '',
        isError: false,
        menuTitle: ''
    });

    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

    //--------------------------------------------------------------

    const handleClick = (index,prostid,project_id) => {
        let temp = [...projectStateCheckedListManual];
        // let check_ = temp[index]?.checked ? true : false;
        if(index > -1){
            temp[index].checked = !temp[index].checked;
            setProjectStateCheckedListManual(temp)
        }else{
            temp.push({
                prost_id: prostid,
                psm_project_id: project_id,
                checked: true
            });
            setProjectStateCheckedListManual(temp)
        }
    
    };

    const CreateManualList = async() => {
        try{
            let temp = {
                manual_list: projectStateCheckedListManual || [],
                project_id: projectId || null,
                user_id: sessionStorage.getItem("user_id"),
                user_name: sessionStorage.getItem("user")
            }
            await axios({
                method: "post",
                url: `${Configs.TTT_Game_Service}/api/projectState/createManualList`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "application/json",
                },
                data: temp,
            })
            .then(async function (response) {
                setMessageError({ ...messageError, message: 'Save Successfully', isError: false, menuTitle: '' })
                toggle_modal_alertKickMassge()
                // if(!alertKickMassge){
                //     toggle_save();
                // }
                getProjectStateCheckedListManual();
            })
            .catch(function (error) {
                console.log(error);
            });
        }catch(error){
            console.log('CreateManualList error => ', error)
        }
    };

    useEffect(() => {
        // getProjectStateById();
        getProjectStateList();

        if(!Is_auto){
            getProjectStateCheckedList();
            getProjectStateCheckedListManual()
        }else{
            getProjectStateCheckedList();
        }
    }, [Is_auto])
    


    return (
        <div className='psBox' style={{ display: `${isOpen ? '' : 'none'}` }}>
            <div className='psBoxSet'>
                <div className='psBoxIn' style={{ backgroundImage: `url(${PsBg})` }}>
                    <div className='row psBoxHeader' style={{ margin: '0' }}>
                        <div className='col-2' style={{ margin: '0', padding: '0' }}>
                            <img className='psTTTSPACESHIPLogo' src={PsTTTSS} alt='Logo'></img>
                        </div>
                        <div className='col-6' style={{ margin: '0', padding: '0 5px' }}>
                            <div className='psProjectBox'>
                                <div className='psProjectNameText'>PROJECT NAME:</div>
                                <div className='d-flex checkbox_manual'>
                                    <div className='psProjectTextBox'>{projectStateData.project_name}</div>
                                    {projectStateData.member.filter((el) => el.su_is_leader).find((tl) => tl.usr_id === sessionStorage.getItem('user_id')) ?
                                        <div className='frame_checkbox'>
                                            <div className='frame_checkbox_in'>
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            colorPrimary: "#0047FF",
                                                        }
                                                    }}
                                                >
                                                    <Checkbox 
                                                        checked={Is_auto}
                                                        value={Is_auto}
                                                        onChange={async(e) => {
                                                            let {checked} = e.target;

                                                            if(!Is_auto){
                                                                setProjectStateCheckedListManual([])
                                                                setProjectStateCheckedList([])
                                                            }
                                                            setIs_auto(checked)
                                                        }}
                                                    />
                                                    <span className='text_auto'>AUTO CHECK</span>
                                                </ConfigProvider>

                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                    {/* <div className='frame_checkbox'>
                                            <div className='frame_checkbox_in'>
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            colorPrimary: "#0047FF",
                                                        }
                                                    }}
                                                >
                                                    <Checkbox 
                                                        checked={Is_auto}
                                                        value={Is_auto}
                                                        onChange={async(e) => {
                                                            let {checked} = e.target;

                                                            if(!Is_auto){
                                                                setProjectStateCheckedListManual([])
                                                                setProjectStateCheckedList([])
                                                            }
                                                            setIs_auto(checked)
                                                        }}
                                                    />
                                                    <span className='text_auto'>AUTO CHECK</span>
                                                </ConfigProvider>

                                            </div>
                                    </div> */}
                                    {
                                        !Is_auto ?
                                            <>
                                                <div className='btn_save'>
                                                    <div 
                                                        className='fedMgtButton_manual' 
                                                        style={{ backgroundColor: 'rgb(2 5 13)' }} 
                                                        onClick={() => {
                                                            toggle_save();
                                                        }}
                                                    >
                                                        SAVE
                                                    </div>
                                                </div>             
                                                {/* <div className='btn_cancel'>
                                                    <div 
                                                        className='fedMgtButton_manual_cancel' 
                                                        style={{ backgroundColor: 'rgb(2 5 13)' }} 
                                                        onClick={() => {
                                                          
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                </div>       */}
                                            </>       
                                        : null
                                    }
                                    
                                </div>
                                <div className='psProjectLine'></div>
                                <div className='psProjectLineDown'></div>
                                <div className='psProjectBoxIn'>
                                    <div className='psProjectNameTextTl'>PROJECT TIMELINE:</div>
                                    <div className='psProjectTextBoxTl'>{projectStateData.pp_start_date != null ? moment(projectStateData.pp_start_date).format('DD MMM YYYY') : ''}</div>
                                    <div className='psProjectNameTextTo'>TO</div>
                                    <div className='psProjectTextBoxTl'>{projectStateData.pp_finish_date != null ? moment(projectStateData.pp_finish_date).format('DD MMM YYYY') : ''}</div>
                                </div>
                            </div>
                        </div>

                 {/*        <div className='col-1' style={{ margin: '0', padding: '0 5px' }}></div> */}

                        <div className='col-4' style={{ margin: '0', padding: '0 5px' }}>
                            <div className='psLogoLine'></div>
                            <div className='psLogoLineDown'></div>
                            <div className='row psLogoBox' style={{ margin: '0', padding: '5px' }}>
                                <div className='col-10 psLogoTextBox' style={{ margin: '0', padding: '0 17.5px' }}>
                                    <div className='psLogoText'>HEALTH CHECKLIST</div>
                                    <div className='psLogoDesc'>FOR IT IMPLEMENT PROCESS</div>
                                </div>
                                <div className='col-2' style={{ margin: '0', padding: '0', textAlign: 'left' }}>
                                    <img className='psLogoImg' src={PsTTT}></img>
                                </div>
                            </div>
                        </div>


                        <div className="psBoxClose" onClick={() => {
                            setPsClose()
                        }}><i class="fa fa-times" aria-hidden="true"></i></div>
                    </div>
                    
                    <div className='psBoxBody'>
                        <div>
                        <div className='psRoad'>
                            <div className='psState' style={{ marginTop: '3%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>1</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state1.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state1.state_list && projectStateList.state1.state_list.map((e, index) => {
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                        onClick={(el)=>{
                                                         /*    console.log(el.clientX) */
                                                            var cursorX = el.clientX + 20;
                                                            var cursorY = el.clientY;
                                                     
                                                           /*  console.log(cursorX,cursorY) */
                                                            setIsOpenInfoDetail(false);
                                                            setIsOpenInfoDetail(true);
                                                            setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                        }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}

                            </div>

                            <div className='psState' style={{ marginTop: '23%', left: '13%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>2</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state2.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state2.state_list && projectStateList.state2.state_list.map((e, index) => {
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}

                            </div>

                            <div className='psState' style={{ marginTop: '0%', left: '32.5%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>3</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state3.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state3.state_list && projectStateList.state3.state_list.map((e, index) => {
                                   let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                   let checked = tmp.length > 0 ? tmp[0].checked : false;
                                   let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                   let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>

                            <div className='psState' style={{ marginTop: '24%', left: '50%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>4</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state4.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state4.state_list && projectStateList.state4.state_list.map((e, index) => {
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox' 
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}
                                                        
                                                        
                                                        >i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>

                            <div className='psState' style={{ marginTop: '2%', left: '54.5%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>5</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state5.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state5.state_list && projectStateList.state5.state_list.map((e, index) => {
                                    
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox' 
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>

                            <div className='psState' style={{ marginTop: '4.5%', left: '81.5%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>6</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state6.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state6.state_list && projectStateList.state6.state_list.map((e, index) => {
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });

                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>

                            <div className='psState' style={{ marginTop: '21.5%', left: '84%' }}>
                                <div className='psStateHead'>
                                    <div className='psStateNoBox'>
                                        <div className='psStateNoBoxTop'></div>
                                        <div className='psStateNoBoxText'>7</div>
                                    </div>
                                    <div className='psStateNameBox'>
                                        <div className='psStateNameText'>{projectStateList.state7.prost_name}</div>
                                        <div className='psStateNameDesc'>TTT SPACESHIP</div>
                                    </div>
                                </div>
                                {projectStateList.state7.state_list && projectStateList.state7.state_list.map((e, index) => {
                                    let tmp = projectStateCheckedList.filter((c) => { return c.prost_id == e.prost_id });
                                    let checked = tmp.length > 0 ? tmp[0].checked : false;
                                    let _findIndex = projectStateCheckedListManual.findIndex((c) => { return c.prost_id == e.prost_id });
                                    let _find = projectStateCheckedListManual.find((c) => { return c.prost_id == e.prost_id });
                                    
                                    return (
                                        <>
                                            {!Is_auto ?
                                                <>
                                                    <div  className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${_find?.checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${_find?.checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div
                                                                onClick={() => {
                                                                    handleClick(_findIndex,e.prost_id,projectId);
                                                                }}
                                                                className='psStateArcCheck' 
                                                                style={{cursor: "pointer",backgroundImage: `${_find?.checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${_find?.checked ? 'transparent' : 'white'}`, width: `${_find?.checked ? '30px' : '20px'}`, height: `${_find?.checked ? '30px' : '20px'}`, marginTop: `${_find?.checked ? '-5px' : '0'}`, marginLeft: `${_find?.checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e?.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${_find?.checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${_find?.checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                                :
                                                <>     
                                                    <div className={`psStateArc${index == 0 ? ' mt-4' : ' mt-1'}`}>
                                                        <div className='psStateIsCheck' style={{ background: `${checked ? 'linear-gradient(45deg, rgba(0,0,0,0.5) 15%, rgba(255,85,0,0.9) 80%, rgba(255,141,0,1) 100%)' : ''}`, animation: `${checked ? `setColorCheck1${index + 1} 2s forwards` : ''}` }}>
                                                            <div className='psStateArcCheck' style={{ backgroundImage: `${checked ? `url(${PsIsCheck})` : ''}`, backgroundColor: `${checked ? 'transparent' : 'white'}`, width: `${checked ? '30px' : '20px'}`, height: `${checked ? '30px' : '20px'}`, marginTop: `${checked ? '-5px' : '0'}`, marginLeft: `${checked ? '0px' : '5px'}` }}></div>
                                                            <div className='psStateArcName'>{e.prost_name}</div>
                                                        </div>
                                                        <div className='PsRocketBox' style={{ backgroundImage: `${checked ? `url(${PsRocket})` : ''}` }}></div>
                                                        <div className='psStateInfoBox'
                                                         onClick={(el)=>{
                                                            /*    console.log(el.clientX) */
                                                               var cursorX = el.clientX + 20;
                                                               var cursorY = el.clientY;
                                                        
                                                              /*  console.log(cursorX,cursorY) */
                                                               setIsOpenInfoDetail(false);
                                                               setIsOpenInfoDetail(true);
                                                               setInfoDeatil({...infoDeatil,state_name:e.prost_name,state_info:e.prost_info,x:cursorX,y:cursorY});
                                                           }}
                                                        style={{ marginLeft: `${checked ? '15px' : '-40px'}` }}>i</div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </div>

                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                                style={{ 
                                    color: 'white', 
                                    transform: 'rotate(55deg)',
                                    animation: `${checkState[1].statePass ? 'rocket1 5s infinite' : ''}`, 
                                    display: `${checkState[1].statePass ? '' : 'none'}`,
                                    
                                   /*  top: '290px', 
                                    left: '260px',  */
                                    
                                     }}>

                            </i>
                            {/* <div className='psRocket'
                                style={{
                                    top: `${position[0].y2 < 0 ? (position[0].y - (position[0].y2 * -1)) + (position[0].h / 2) : position[0].y + (position[0].h / 2)}px`,
                                    left: `${position[0].x2 < 0 ? (position[0].x - (position[0].x2 * -1)) + (position[0].w / 2) : position[0].x + (position[0].w / 2)}px`,
                                     
                                    animation: `${checkState[1].statePass ? 'rocket1 5s infinite' : ''}`, 
                                    display: `${checkState[1].statePass ? '' : 'none'}`,
                                    transform: 'rotate(55deg)',
                                }}
                                
                            >
                                <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 512 512"
                                    width="30" 
                                    height="30"
                                    x1={`${position[0].x2 < 0 ? position[0].x2 * -1 : '1'}px`}
                                    y1={`${position[0].y2 < 0 ? position[0].y2 * -1 : '1'}px`}
                                    x2={`${position[0].x2 < 0 ? '1' : position[0].x2}px`}
                                    y2={`${position[0].y2 < 0 ? '1' : position[0].y2}px`}
                                >
                                    <path 
                                        fill="white" 
                                        d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"

                                    />
                                        
                                </svg>

                            </div> */}
                            {/* <svg 
                                className='psRocket'
                                style={{
                                    color: 'white',
                                    transform: 'rotate(55deg)',
                                    animation: `${checkState[1].statePass ? 'rocket1 5s infinite' : ''}`, 
                                    display: `${checkState[1].statePass ? '' : 'none'}`,

                                    
                                }}
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 512 512"
                                width="30" 
                                height="30"
                            >
                                <path fill="white" d="M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/>
                                
                            </svg> */}



                            <div className='psCircle' id='element1' ref={elementRef1} style={{ marginTop: '16.2%', left: '0%', backgroundImage: `${checkState[0].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[0].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[0].y2 < 0 ? (position[0].y - (position[0].y2 * -1)) + (position[0].h / 2) : position[0].y + (position[0].h / 2)}px`,
                                    left: `${position[0].x2 < 0 ? (position[0].x - (position[0].x2 * -1)) + (position[0].w / 2) : position[0].x + (position[0].w / 2)}px`,
                                    width: `${position[0].x2 < 0 ? position[0].x2 * -1 : position[0].x2}px`,
                                    height: `${position[0].y2 < 0 ? position[0].y2 * -1 : position[0].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[0].y2 < 0 ? position[0].y2 * -1 : position[0].y2}px`} width={`${position[0].x2 < 0 ? position[0].x2 * -1 : position[0].x2}px`}>
                                    <line
                                        x1={`${position[0].x2 < 0 ? position[0].x2 * -1 : '1'}px`}
                                        y1={`${position[0].y2 < 0 ? position[0].y2 * -1 : '1'}px`}
                                        x2={`${position[0].x2 < 0 ? '1' : position[0].x2}px`}
                                        y2={`${position[0].y2 < 0 ? '1' : position[0].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>

                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                               style={{ 
                                color: 'white', 
                                // top: '207.5px', 
                                // left: '560px', 
                                transform: 'rotate(26deg)', 
                                animation: `${checkState[2].statePass ? 'rocket2 5s infinite' : ''}`, 
                                display: `${checkState[2].statePass ? '' : 'none'}` }}>

                            </i>
                            <div className='psCircle' ref={elementRef2} style={{ marginTop: '19.4%', left: '19.4%', backgroundImage: `${checkState[1].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[1].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[1].y2 < 0 ? (position[1].y - (position[1].y2 * -1)) + (position[1].h / 2) : position[1].y + (position[1].h / 2)}px`,
                                    left: `${position[1].x2 < 0 ? (position[1].x - (position[1].x2 * -1)) + (position[1].w / 2) : position[1].x + (position[1].w / 2)}px`,
                                    width: `${position[1].x2 < 0 ? position[1].x2 * -1 : position[1].x2}px`,
                                    height: `${position[1].y2 < 0 ? position[1].y2 * -1 : position[1].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[1].y2 < 0 ? position[1].y2 * -1 : position[1].y2}px`} width={`${position[1].x2 < 0 ? position[1].x2 * -1 : position[1].x2}px`}>
                                    <line
                                        x1={`${position[1].x2 < 0 ? position[1].x2 * -1 : '1'}px`}
                                        y1={`${position[1].y2 < 0 ? position[1].y2 * -1 : '1'}px`}
                                        x2={`${position[1].x2 < 0 ? '1' : position[1].x2}px`}
                                        y2={`${position[1].y2 < 0 ? '1' : position[1].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                               style={{ 
                                color: 'white', 
                                // top: '315px', 
                                // left: '675px', 
                                transform: 'rotate(100deg)', 
                                animation: `${checkState[3].statePass ? 'rocket3 5s infinite' : ''}`, 
                                display: `${checkState[3].statePass ? '' : 'none'}` }}>

                            </i>
                            <div className='psCircle' ref={elementRef3} style={{ marginTop: '13%', left: '38.9%', backgroundImage: `${checkState[2].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[2].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[2].y2 < 0 ? (position[2].y - (position[2].y2 * -1)) + (position[2].h / 2) : position[2].y + (position[2].h / 2)}px`,
                                    left: `${position[2].x2 < 0 ? (position[2].x - (position[2].x2 * -1)) + (position[2].w / 2) : position[2].x + (position[2].w / 2)}px`,
                                    width: `${position[2].x2 < 0 ? position[2].x2 * -1 : position[2].x2}px`,
                                    height: `${position[2].y2 < 0 ? position[2].y2 * -1 : position[2].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[2].y2 < 0 ? position[2].y2 * -1 : position[2].y2}px`} width={`${position[2].x2 < 0 ? position[2].x2 * -1 : position[2].x2}px`}>
                                    <line
                                        x1={`${position[2].x2 < 0 ? position[2].x2 * -1 : '1'}px`}
                                        y1={`${position[2].y2 < 0 ? position[2].y2 * -1 : '1'}px`}
                                        x2={`${position[2].x2 < 0 ? '1' : position[2].x2}px`}
                                        y2={`${position[2].y2 < 0 ? '1' : position[2].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                               style={{ 
                                color: 'white', 
                                // top: '242.5px', 
                                // left: '865px', 
                                transform: 'rotate(12deg)', 
                                animation: `${checkState[4].statePass ? 'rocket4 5s infinite' : ''}`, 
                                display: `${checkState[4].statePass ? '' : 'none'}` }}>

                            </i>
                            <div className='psCircle' ref={elementRef4} style={{ marginTop: '22.6%', left: '45.3%', backgroundImage: `${checkState[3].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[3].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[3].y2 < 0 ? (position[3].y - (position[3].y2 * -1)) + (position[3].h / 2) : position[3].y + (position[3].h / 2)}px`,
                                    left: `${position[3].x2 < 0 ? (position[3].x - (position[3].x2 * -1)) + (position[3].w / 2) : position[3].x + (position[3].w / 2)}px`,
                                    width: `${position[3].x2 < 0 ? position[3].x2 * -1 : position[3].x2}px`,
                                    height: `${position[3].y2 < 0 ? position[3].y2 * -1 : position[3].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[3].y2 < 0 ? position[3].y2 * -1 : position[3].y2}px`} width={`${position[3].x2 < 0 ? position[3].x2 * -1 : position[3].x2}px`}>
                                    <line
                                        x1={`${position[3].x2 < 0 ? position[3].x2 * -1 : '1'}px`}
                                        y1={`${position[3].y2 < 0 ? position[3].y2 * -1 : '1'}px`}
                                        x2={`${position[3].x2 < 0 ? '1' : position[3].x2}px`}
                                        y2={`${position[3].y2 < 0 ? '1' : position[3].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                               style={{ 
                                color: 'white', 
                                // top: '252px', 
                                // left: '1160px', 
                                transform: 'rotate(50deg)', 
                                animation: `${checkState[5].statePass ? 'rocket5 5s infinite' : ''}`, 
                                display: `${checkState[5].statePass ? '' : 'none'}` }}>
                                
                            </i>
                            <div className='psCircle' ref={elementRef5} style={{ marginTop: '14.9%', left: '58.3%', backgroundImage: `${checkState[4].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[4].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[4].y2 < 0 ? (position[4].y - (position[4].y2 * -1)) + (position[4].h / 2) : position[4].y + (position[4].h / 2)}px`,
                                    left: `${position[4].x2 < 0 ? (position[4].x - (position[4].x2 * -1)) + (position[4].w / 2) : position[4].x + (position[4].w / 2)}px`,
                                    width: `${position[4].x2 < 0 ? position[4].x2 * -1 : position[4].x2}px`,
                                    height: `${position[4].y2 < 0 ? position[4].y2 * -1 : position[4].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[4].y2 < 0 ? position[4].y2 * -1 : position[4].y2}px`} width={`${position[4].x2 < 0 ? position[4].x2 * -1 : position[4].x2}px`}>
                                    <line
                                        x1={`${position[4].x2 < 0 ? position[4].x2 * -1 : '1'}px`}
                                        y1={`${position[4].y2 < 0 ? position[4].y2 * -1 : '1'}px`}
                                        x2={`${position[4].x2 < 0 ? '1' : position[4].x2}px`}
                                        y2={`${position[4].y2 < 0 ? '1' : position[4].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <i class="fa fa-rocket psRocket" aria-hidden="true" 
                               style={{ 
                                color: 'white', 
                                // top: '313px', 
                                // left: '1208.5px', 
                                transform: 'rotate(123deg)', 
                                animation: `${checkState[6].statePass ? 'rocket6 5s infinite' : ''}`, 
                                display: `${checkState[6].statePass ? '' : 'none'}` }}>
                    
                            </i>
                            <div className='psCircle' ref={elementRef6} style={{ marginTop: '16.8%', left: '77.6%', backgroundImage: `${checkState[5].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[5].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                            <div className='psLineDashed'
                                style={{
                                    top: `${position[5].y2 < 0 ? (position[5].y - (position[5].y2 * -1)) + (position[5].h / 2) : position[5].y + (position[5].h / 2)}px`,
                                    left: `${position[5].x2 < 0 ? (position[5].x - (position[5].x2 * -1)) + (position[5].w / 2) : position[5].x + (position[5].w / 2)}px`,
                                    width: `${position[5].x2 < 0 ? position[5].x2 * -1 : position[5].x2}px`,
                                    height: `${position[5].y2 < 0 ? position[5].y2 * -1 : position[5].y2}px`,
                                    /* border: '1px solid red' */
                                }}>
                                <svg height={`${position[5].y2 < 0 ? position[5].y2 * -1 : position[5].y2}px`} width={`${position[5].x2 < 0 ? position[5].x2 * -1 : position[5].x2}px`}>
                                    <line
                                        x1={`${position[5].x2 < 0 ? position[5].x2 * -1 : '1'}px`}
                                        y1={`${position[5].y2 < 0 ? position[5].y2 * -1 : '1'}px`}
                                        x2={`${position[5].x2 < 0 ? '1' : position[5].x2}px`}
                                        y2={`${position[5].y2 < 0 ? '1' : position[5].y2}px`}
                                        style={{ stroke: 'rgba(255, 255, 255, 0.5)', strokeWidth: '3', strokeDasharray: "20 20" }} />
                                </svg>
                            </div>
                            <div className='psCircle' ref={elementRef7} style={{ marginTop: '22.7%', left: '78.9%', backgroundImage: `${checkState[6].statePass ? 'linear-gradient(to bottom right, rgba(0,0,100,1), rgba(0,0,250,1))' : 'linear-gradient(to bottom right, rgba(255,255,255,1), rgba(255,255,255,1))'}`, animation: `${checkState[6].statePass ? 'setcircle 5s infinite' : ''}` }}></div>
                        </div>
                        </div>
                        

                    </div>
                    <div className='row psBoxFooter' style={{ margin: '0 auto', padding: '0 75px' }}>
                        <div className='col-5' style={{ margin: '0', padding: '0' }}>
                            <div className='psMiniCheckText'>MINI CHECKLIST</div>
                            <div className='psMiniCheckBox'>
                                {checkState.map((e, index) => {
                                    let state = projectStateList[`state${index + 1}`].state_list || [];
                                    let checked_count = 0;
                                    let is_checked = false;
                                    // console.log(state);
                                    for (let item of state) {
                                        // let checked = projectStateCheckedList.filter((c) => { return c.prost_id == item.prost_id && c.checked == true });
                                        let checked = !Is_auto ? projectStateCheckedListManual.filter((c) => { return c.prost_id == item.prost_id && c.checked == true }) : projectStateCheckedList.filter((c) => { return c.prost_id == item.prost_id && c.checked == true })
                                        if (checked.length > 0) {
                                            checked_count++;
                                        }
                                    }
                                    // console.log(checked_count)
                                    if (checked_count == state.length) {
                                        is_checked = true;
                                    }

                                    return (
                                        <div className='psMiniCheckBoxIn'>
                                            {e.stateName}
                                            <div className={`psMiniBox${isRocketFlow ? ' active' : ''}`} style={{ background: `${is_checked ? '' : 'white'}` }}>
                                                {/* <img style={{width: '100%', height: '100%'}} src={isRocketFlow ? PsRocketFlow : PsRocketFlowStop} alt="Your GIF" once ></img> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='col-2 psSuccessfulText' style={{ fontSize: projectStateData?.pros_name && projectStateData?.pros_name.length > 6 ? "35px"  : '', margin: '0', padding: '35px 0' }}>{projectStateData.pros_name || ''}</div>
                        <div className='col-2 psManager' style={{ margin: '0', padding: '20px 0' }}>
                            MANAGER
                            {projectStateData.member.map((e) => {
                                if (e.su_is_leader) {
                                    return (<>
                                        <div className='psManagerBox' onClick={() => {
                                            setValueAndOpenView(e.usr_id)
                                        }}>
                                            <img className='psMemberBoxImg' style={{ width: '58px', height: '58px' }} src={(e.ul_file_path != '' && e.ul_file_path != null) ? `${Configs.TTT_Game_Service_IMG}${e.ul_file_path}` : defualtUser} alt='Logo'></img>
                                            <div className='psManagerBoxTop' style={{ marginTop: '-58px' }}></div>
                                            {/* <div className='psManagerBoxText'>AVATAR</div>
                                            <i class="fa fa-user" aria-hidden="true" style={{ fontSize: '25px' }}></i> */}
                                        </div >
                                    </>)
                                }
                            })}

                        </div>
                        <div className='col-3 psMember' style={{ margin: '0', padding: '20px 0' }}>
                            MEMBER:
                            <div className='psMemberSet'>
                                <div className='psMemberSetOverflow'>
                                    {projectStateData.member.map((e, index) => {
                                        if (e.su_is_leader != true) {
                                            return (
                                                <div className='psMemberBox' onClick={() => {
                                                    setValueAndOpenView(e.usr_id)
                                                }}>
                                                    <img className='psMemberBoxImg' src={(e.ul_file_path != '' && e.ul_file_path != null) ? `${Configs.TTT_Game_Service_IMG}${e.ul_file_path}` : defualtUser} alt='Logo'></img>
                                                    <div className='psMemberBoxTop'></div>
                                                    {/* <div className='psMemberBoxText'>AVATAR</div>
                                                    <i class="fa fa-user" aria-hidden="true" style={{ fontSize: '22px' }}></i> */}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='psBottomLine'></div>
                    <div className='psBottomLineBox1'></div>
                    <div className='psBottomLineBox2'></div>
                </div>
            </div>
            <ViewUserProfile
                isOpen={personalInfoCheck.isOpen}
                fncSetProfile={pathProfile}
                submode={personalInfoCheck}
                setPersonalInfoCheck={setPersonalInfoCheck}
                close={null}
                tempValue={tempValue}

            />


            {/* -----------------Alert Create------------------------------- */}

            <div className='alertKick' style={{ display: `${Is_openModalSave ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox'>
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Do you want to save data?
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button 
                                    className='mr-2 alertKickConfirm' 
                                    onClick={async() => {
                                        toggle_save();
                                        await CreateManualList();

                                    }}
                                >   
                                    YES
                                </button>

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                    toggle_save();
                                }}>NO</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageError}
            />



        {isOpenInfoDetail == true && (
              <div className='modalInfoDetail' style = {{top:`${infoDeatil.y}px`,left:`${infoDeatil.x}px`}}>
              <div className='headerInfo'>
                  <div style={{display:'flex',flexDirection:'column'}}>
                  <div className='fontInfo1'>
                  HOW TO UNLOCK :
                  </div>
                  <div className='fontInfo2'>
                  {infoDeatil.state_name}
                  </div>
                  </div>
  
                  <div className='xButton' onClick={()=>{toggle_Info_Detail();}}>X</div>
  
  
                  </div>
  
                  <div className='bodyInfo'>
                  <div className='fontInfo1'>
                  ● {infoDeatil.state_info}
                  </div>
                 
                  </div>
              </div>
                 
        )}
          
        </div >



                          
    )
}

export default ProjectState;
