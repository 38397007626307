import React, { useState, useEffect, useRef } from 'react';
import './cradFederation.css'
import ImgLogo from "./ttt.png"
import Imgiconfed from "./iconUse.png"

function CradFedertion(params) {
    let logoimg = params.logo_img
    let herdertype = params.type || 'federation'
    let createdata = params.createdata
    let rank = params.rank
    let spaceship = params.spaceship


    return (
        <>
            <div className='cead-body'>
                <div className='row delp'>
                    <img
                        className='logo-img'
                        src={ImgLogo}
                    ></img>
                    <div className='herder-type'>
                        <ht1> {herdertype} </ht1>
                        <ht2>  <img className='imgicon' src={Imgiconfed} />{' '} TTTTTTT  </ht2>
                    </div>
                </div>




            </div>


        </>
    )
}


export default CradFedertion