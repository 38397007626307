import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import "./alert.css"


function AlertMassge({isOpen,toggle_alertKick,message,mainFuc,mode}) {
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [displayAlert,setDisplayAlert] = useState({
        colorTitle:'',
        textTitle:'',
        massger: []
    })


    function SetMassge(value) {
        let massgerIS = []
        if(Array.isArray(value) === false){
            massgerIS = [value]
        }else{
            massgerIS = value
        }

        return massgerIS
    }

    useEffect(()=>{
       /*  console.log('isOpen',isOpen); */
        if(isOpen === true){
            toggle_modal_alertKick()

            if(message.isError !== undefined && message.isError === true){
                let textMass_e = SetMassge(message.message);
                setDisplayAlert({...displayAlert,
                    colorTitle:'#F00',
                    textTitle:'! ERROR !',
                    menuTitle:message.menuTitle || 'ERROR',
                    massger: textMass_e || []
                })
            }else if(message.isError !== undefined && message.isError === null){
                let textMass_w = SetMassge(message.message);
                setDisplayAlert({...displayAlert,
                    colorTitle:'#ffcc00',
                    textTitle:'! WARNING !',
                    menuTitle:message.menuTitle || 'WARNING',
                    massger: textMass_w || []
                }) 
            }else{
                let textMass_S = SetMassge(message.message);
                setDisplayAlert({...displayAlert,
                    colorTitle:'#339900',
                    textTitle:'SUCCESS',
                    menuTitle:message.menuTitle || 'SUCCESS ',
                    massger: textMass_S || []
                })  
            }

        }
         },[isOpen])

  return (
    <>
    <div className='alertRegisterKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKicRrgisterkBoxShadow' style={{padding: `${mode == 'mobile' ? '5%' : '0'}`}}>
            <div className='alertRegisterKickBox' style={{ height: 'fit-content', width: `${mode == 'mobile' ? '100%' : '575px'}` }}>
                <div className='alertRegisterKickBoxIn' style={{ height: 'fit-content', width: `${mode == 'mobile' ? '100%' : '573px'}` }}>
                    <div className='row' style={{ height: '25%' }}>
                        <div className='alertKickHead' style={{ color:  `${displayAlert.colorTitle}`}}>
                            { `${displayAlert.textTitle}`}
                        </div>
                    </div>
                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                        <div className='alertMassgerKickWarning'>
                        {displayAlert.menuTitle}
                        </div>
                    </div>
                    <div className='row' style={{ height: '12.5%' }}>
                        <div className='alertKickText'>
                            {displayAlert.massger.length > 0 ? displayAlert.massger.map((e)=>{
                               /*  console.log('sssssssssss',e); */
                                return (<div>
                                    {e}
                                </div>)
                            }) : ''}
                        </div>
                    </div>


                    <div className='row alerRegistertKickBoxButton'>
                        <button className='alertRegisterKickButton' onClick={() => {
                               toggle_alertKick()
                               toggle_modal_alertKick()

                            if(mainFuc !== undefined && message.isError !== null){
                                mainFuc()
                            }
                         
                          
                            
                        }}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</>
  );
}

export default AlertMassge;
