import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import "./stly.css"
import Construction from '../../../assets/img/bg/Construction Page.png'



function DashbordFederation({ isOpen, toggle_dashFedModal_modal }) {

    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);



    useEffect(() => {
        // console.log('TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT', isOpen);
        if (isOpen === true) {
            toggle_modal_alertKick()
        }
    }, [isOpen])

    return (
        <>
            <div className='opacity' style={{ display: `${isOpen ? '' : 'none'}` }}>
                <div className='modalDashbord' style={{ display: `${isOpen ? '' : 'none'}` }}>
                    <div className='close-butt'>
                        <div className='row' style={{ padding: '0', margin: '0' }}>
                            <div className='col-10' style={{padding: '6px', margin: '0px' }}>
                                <div className='textDashbord'>
                                FEDERATION DASHBORD
                                </div>                               
                            </div>
                            <div className='col-2' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                <div className='x-butt' onClick={() => { toggle_dashFedModal_modal() }}>
                                    X
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='Area-box'>
                        <img src={Construction} style={{ width: "90%", height: '100%', position: 'relative', marginTop: '50px' }} />
                    </div>

                </div>
            </div>


        </>
    );
}

export default DashbordFederation;
