import React, { useEffect, useState, useRef } from 'react';
import HeaderTTT from "../../../components/header/HeaderTTT";
import HeaderTTTMobile from '../../../components/header/HeaderTTTMobile';
import './tribe.css';
import axios from "axios";
import Configs from "../../../config";
import howToPlayBg from './image/bgTrid.png';
import TransformIMG from './image/tramform.png';
import TimelrIMG from './image/timely.png';
import TrlblzerIMG from './image/trelb.png';
import bgSeawaterIMG from './image/bgButom-sm2.png';
import TarlentTransform from './image/talent_transform.png';
import frameTransform from './image/frame_transform.png';
import TarlentTimely from './image/talent_timely.png';
import frameTimely from './image/frame_timely.png';
import TarlentTrailblazer from './image/talent_trailblazer.png';
import frameTrailblazer from './image/frame_trailblazer.png';

function HowToPlay() {

    const [groupData, setGroupData] = useState([]);
    const [divHeight, setDivHeight] = useState([]);
    const [isUseD, setIsUseD] = useState([])
    const [isUseD2, setIsUseD2] = useState([])
    const [isUseD3, setIsUseD3] = useState([])
    const [test, settest] = useState({
        test: ''
    });

    async function inputDe(index) {
        let check = isUseD.filter((r) => { return r == index });
        let ole = isUseD;
        if (check.length > 0) {
            let findIndex = isUseD.findIndex((r) => { return r == index });
            if (findIndex > -1) {
                ole.splice(findIndex, 1)
            }
        } else {
            ole.push(index);
        }
        setIsUseD(ole);

        settest({ ...test, test: '' });
        test.test = '';
    }
    async function inputDe2(index) {
        let check = isUseD2.filter((r) => { return r == index });
        let ole = isUseD2;
        if (check.length > 0) {
            let findIndex = isUseD2.findIndex((r) => { return r == index });
            if (findIndex > -1) {
                ole.splice(findIndex, 1)
            }
        } else {
            ole.push(index);
        }
        setIsUseD2(ole);
        settest({ ...test, test: '' });
        test.test = '';
    }
    async function inputDe3(index) {
        let check = isUseD3.filter((r) => { return r == index });
        let ole = isUseD3;
        if (check.length > 0) {
            let findIndex = isUseD3.findIndex((r) => { return r == index });
            if (findIndex > -1) {
                ole.splice(findIndex, 1)
            }
        } else {
            ole.push(index);
        }
        setIsUseD3(ole);
        settest({ ...test, test: '' });
        test.test = '';
    }

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
           
              if(callback){
                callback(response.data.tokenBox);
              }
              sessionStorage.setItem('tokenBox',response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });
    
    }

    async function getTalentGroupData() {
        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/howToPlay/getTalentGroupData`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: temp

        })
            .then(async function (response) {
                if (response.data) {
                    let temp = response.data;
                    setGroupData(temp);
                    settest({ ...test, test: '' });
                    test.test = '';
                    if (temp.length > 0) {
                        for (let item of temp) {
                            if (item.fd_img_id) {
                                const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });

                                item.ul_file_path = URL.createObjectURL(response2.data);
                            }
                        }
                        setGroupData(temp);
                        settest({ ...test, test: '' });
                        test.test = '';
                    }

                    
                    let checkLength = Math.max(8, response.data.filter((el) => { return el.role_gr_id == '0b7fceb7-892b-4ec0-a46d-03719c5fa2d7' }).length, response.data.filter((el) => { return el.role_gr_id == '6c0683b2-9e06-439b-a78c-9dd4b05b708a' }).length, response.data.filter((el) => { return el.role_gr_id == '8910e890-2beb-4ed0-9418-b6367e5e5171' }).length,)

                    let setHeight = ((checkLength * 50) + ((checkLength - 1) * 25)) + 515;
                    setDivHeight(setHeight);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    };

    function setSplitText(text, id) {
        let checkSplit = text.split(",\n");
        let checkIndex = groupData.findIndex((el) => { return el.role_id == id })
        groupData[checkIndex].role_descriptionBullet = checkSplit;
    };

    useEffect(() => {
        GetAccesstokenToken((callback)=>{
            if (callback) {
                getTalentGroupData();
            }
        });
    }, []);

    const [modeMobile, setModeMobile] = useState(false);

    useEffect(() => {

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        }
        else {
            setModeMobile(false);
        }

    }, []);

    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

    return (
        <>
            {modeMobile ? (<>
                <HeaderTTTMobile
                    mobileMenu={mobileMenu}
                    toggle_mobileMenu={toggle_mobileMenu}
                    mobileLogin={mobileLogin}
                    toggle_mobileLogin={toggle_mobileLogin}
                    mobileRegister={mobileRegister}
                    toggle_mobileRegister={toggle_mobileRegister}
                />


                <div className='howToPlayBgMobile' style={{ backgroundImage: `url(${howToPlayBg})` }}>
                    <div className='area-main' style={{ width: '100%' }}>
                        <div className='row' style={{ padding: '0px', margin: '0px 0px 0px 0px' }}>
                            <div className='col-12 col-xl-4 devt1' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }}>
                                <img className="" src={TransformIMG} style={{ width: '100%', padding: '00px', margin: '0px' }}></img>
                                <div className='divBlack-t1'>  <div className='font-t1 '> TRANSFORM </div> </div>
                            </div>
                            <div className='col-12 col-xl-4 devt2' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }} >
                                <img className="" src={TimelrIMG} style={{ width: '100%', padding: '0px', margin: '0px'/* , border:'1px solid blue' */ }}></img>
                                <div className='divBlack-t2'> <div className='font-t2'> TIMELY </div> </div>
                            </div>
                            <div className='col-12 col-xl-4 devt3' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }}>
                                <img className="" src={TrlblzerIMG} style={{ width: '100%', padding: '0px', margin: '0px'/* , border:'1px solid blue' */ }} ></img>
                                <div className='divBlack-t3'>  <div className='font-t3'> TRAILBLAZER </div> </div>
                            </div>
                        </div>
                    </div>
                    <div className='area-buton' style={{ width: '100%' }}>
                        <div className='row talentGroupStckShowdown' style={{ width: '100%', height: '30px' }}></div>
                        <div className='row' style={{ padding: '0px', margin: '0px', zIndex: 99, backgroundImage: `url(${bgSeawaterIMG})`, width: '100%', height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative', top: '-30px', marginBottom: '-2.5%' }}>
                            <div className='col-12 col-xl-4 devt1 mt-4'>
                                <div className='talentGroupSet'>
                                    <img src={TarlentTransform} style={{ width: '100%', marginLeft: '-5%', position: 'relative', zIndex: 1 }}></img>
                                    <div className='talentGroupBoxList' style={{ marginTop: '-15%', height: 'calc(100% - 310px)' }}>
                                        <div className='telentGroupSetFrame'>
                                            <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTransform})` }}></div>
                                            {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTransform})`}}></div> */}
                                            <div className='talentGroupBoxCenterTran' style={{ padding: '0 6px 0 8px' }}>
                                                <div className='talentGroupBoxCenterTranIn' style={{ padding: '0 10px', borderRight: '1px solid red' }}>
                                                    <div className='talentGroupBoxCenterTranIn2' style={{ borderRight: '9px solid red' }}>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTransform})` }}></div>
                                        </div>
                                        {
                                            groupData.filter((el) => { return el.role_gr_id == '0b7fceb7-892b-4ec0-a46d-03719c5fa2d7' }).map((e, index) => {
                                                let textName = e.role_description;

                                                let check = isUseD.filter((r) => { return r == index })
                                                if (check.length == 0) {
                                                    if (e.role_description !== null && e.role_description !== '') {
                                                        if (e.role_description.toString().length > 80) {
                                                            textName = e.role_description.toString().substring(0, 80) + "...";
                                                        }
                                                    }
                                                }


                                                return (<>
                                                    <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                        inputDe(index)
                                                        setSplitText(e.role_description, e.role_id);
                                                    }} >
                                                        <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                            {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%' }}></img> */}
                                                            <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                        </div>
                                                        <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                            <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                            <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                                {check.length < 1 && e.role_description}
                                                            </div>
                                                        </div>
                                                        {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                            {e.role_descriptionBullet.map((e) => {
                                                                return (<>
                                                                    <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                        ●
                                                                    </div>
                                                                    <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                        {e}
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>}
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-xl-4 devt1 mt-4'>
                                <div className='talentGroupSet'>
                                    <img src={TarlentTimely} style={{ width: '82.5%', marginLeft: '7.5%', position: 'relative', zIndex: 1 }}></img>
                                    <div className='talentGroupBoxList' style={{ marginTop: '-16%', height: 'calc(100% - 310px)' }}>
                                        <div className='telentGroupSetFrame'>
                                            <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTimely})` }}></div>
                                            {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTimely})`}}></div> */}
                                            <div className='talentGroupBoxCenterTime' style={{ margin: '0 2px 0 1px' }}>
                                                <div className='talentGroupBoxCenterTimeIn'>

                                                </div>
                                            </div>
                                            <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTimely})` }}></div>
                                        </div>
                                        {
                                            groupData.filter((el) => { return el.role_gr_id == '6c0683b2-9e06-439b-a78c-9dd4b05b708a' }).map((e, index) => {
                                                let textName = e.role_description;

                                                let check = isUseD2.filter((r) => { return r == index })
                                                if (check.length == 0) {
                                                    if (e.role_description !== null && e.role_description !== '') {
                                                        if (e.role_description.toString().length > 80) {
                                                            textName = e.role_description.toString().substring(0, 80) + "...";
                                                        }
                                                    }
                                                }
                                                return (<>
                                                    <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                        inputDe2(index)
                                                        setSplitText(e.role_description, e.role_id);
                                                    }}>
                                                        <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                            {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%', height: '100%' }}></img> */}
                                                            <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                        </div>
                                                        <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                            <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                            <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                                {check.length < 1 && e.role_description}
                                                            </div>
                                                        </div>
                                                        {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                            {e.role_descriptionBullet.map((e) => {
                                                                return (<>
                                                                    <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                        ●
                                                                    </div>
                                                                    <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                        {e}
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>}
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-xl-4 devt1 mt-4'>
                                <div className='talentGroupSet'>
                                    <img src={TarlentTrailblazer} style={{ width: '95%', marginLeft: '2.5%', position: 'relative', zIndex: 1 }}></img>
                                    <div className='talentGroupBoxList' style={{ marginTop: '-17%', height: 'calc(100% - 310px)' }}>
                                        <div className='telentGroupSetFrame'>
                                            <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTrailblazer})`, height: '300px' }}></div>
                                            {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTrailblazer})`}}></div> */}
                                            <div className='talentGroupBoxCenterTrail'></div>
                                            <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTrailblazer})` }}></div>
                                        </div>
                                        {
                                            groupData.filter((el) => { return el.role_gr_id == '8910e890-2beb-4ed0-9418-b6367e5e5171' }).map((e, index) => {
                                                let textName = e.role_description;

                                                let check = isUseD3.filter((r) => { return r == index })
                                                if (check.length == 0) {
                                                    if (e.role_description !== null && e.role_description !== '') {
                                                        if (e.role_description.toString().length > 80) {
                                                            textName = e.role_description.toString().substring(0, 80) + "...";
                                                        }
                                                    }
                                                }
                                                return (<>
                                                    <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                        inputDe3(index)
                                                        setSplitText(e.role_description, e.role_id);
                                                    }}>
                                                        <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                            {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%' }}></img> */}
                                                            <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                        </div>
                                                        <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                            <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                            <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                                {check.length < 1 && e.role_description}
                                                            </div>
                                                        </div>
                                                        {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                            {e.role_descriptionBullet.map((e) => {
                                                                return (<>
                                                                    <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                        ●
                                                                    </div>
                                                                    <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                        {e}
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>}
                                                    </div>
                                                </>)
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : (<>
                <HeaderTTT />

                <img className="guide-bg" src={howToPlayBg} style={{ border: '2px solid #7BD7FF' }}></img>

                <div className='area-main'>
                    <div className='row' style={{ padding: '0px', margin: '0px 0px 0px 0px' }}>
                        <div className='col-12 col-xl-4 devt1' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }}>
                            <img className="" src={TransformIMG} style={{ width: '100%', padding: '00px', margin: '0px' }}></img>
                            <div className='divBlack-t1'>  <div className='font-t1 '> TRANSFORM </div> </div>
                        </div>
                        <div className='col-12 col-xl-4 devt2' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }} >
                            <img className="" src={TimelrIMG} style={{ width: '100%', padding: '0px', margin: '0px'/* , border:'1px solid blue' */ }}></img>
                            <div className='divBlack-t2'> <div className='font-t2'> TIMELY </div> </div>
                        </div>
                        <div className='col-12 col-xl-4 devt3' style={{ width: '33%', padding: '0px', margin: '0px', cursor: 'pointer' }}>
                            <img className="" src={TrlblzerIMG} style={{ width: '100%', padding: '0px', margin: '0px'/* , border:'1px solid blue' */ }} ></img>
                            <div className='divBlack-t3'>  <div className='font-t3'> TRAILBLAZER </div> </div>
                        </div>
                    </div>
                </div>
                <div className='area-buton' >
                    <div className='row talentGroupStckShowdown' style={{ width: '100%', height: '30px' }}></div>
                    <div className='row' style={{ padding: '0px', margin: '0px', zIndex: 99, backgroundImage: `url(${bgSeawaterIMG})`, width: '100%', height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative', top: '-30px', marginBottom: '-2.5%' }}>
                        <div className='col-12 col-xl-4 devt1 mt-4'>
                            <div className='talentGroupSet'>
                                <img src={TarlentTransform} style={{ width: '100%', marginLeft: '-5%', position: 'relative', zIndex: 1 }}></img>
                                <div className='talentGroupBoxList' style={{ marginTop: '-15%' }}>
                                    <div className='telentGroupSetFrame'>
                                        <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTransform})` }}></div>
                                        {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTransform})`}}></div> */}
                                        <div className='talentGroupBoxCenterTran'>
                                            <div className='talentGroupBoxCenterTranIn'>
                                                <div className='talentGroupBoxCenterTranIn2'>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTransform})` }}></div>
                                    </div>
                                    {
                                        groupData.filter((el) => { return el.role_gr_id == '0b7fceb7-892b-4ec0-a46d-03719c5fa2d7' }).map((e, index) => {
                                            let textName = e.role_description;

                                            let check = isUseD.filter((r) => { return r == index })
                                            // if (check.length == 0) {
                                            //     if (e.role_description !== null && e.role_description !== '') {
                                            //         if (e.role_description.toString().length > 80) {
                                            //             textName = e.role_description.toString().substring(0, 80) + "...";
                                            //         }
                                            //     }
                                            // }

                                            return (<>
                                                <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                    inputDe(index);
                                                    setSplitText(e.role_description, e.role_id);
                                                }} >
                                                    <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                        {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%' }}></img> */}
                                                        <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                    </div>
                                                    <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                        <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                        <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                            {check.length < 1 && e.role_description}
                                                        </div>
                                                    </div>
                                                    {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                        {e.role_descriptionBullet.map((e) => {
                                                            return (<>
                                                                <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                    ●
                                                                </div>
                                                                <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                    {e}
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>}
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-xl-4 devt1 mt-4'>
                            <div className='talentGroupSet'>
                                <img src={TarlentTimely} style={{ width: '82.5%', marginLeft: '7.5%', position: 'relative', zIndex: 1 }}></img>
                                <div className='talentGroupBoxList' style={{ marginTop: '-16%' }}>
                                    <div className='telentGroupSetFrame'>
                                        <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTimely})` }}></div>
                                        {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTimely})`}}></div> */}
                                        <div className='talentGroupBoxCenterTime'>
                                            <div className='talentGroupBoxCenterTimeIn'>

                                            </div>
                                        </div>
                                        <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTimely})` }}></div>
                                    </div>
                                    {
                                        groupData.filter((el) => { return el.role_gr_id == '6c0683b2-9e06-439b-a78c-9dd4b05b708a' }).map((e, index) => {
                                            let textName = e.role_description;

                                            let check = isUseD2.filter((r) => { return r == index })
                                            // if (check.length == 0) {
                                            //     if (e.role_description !== null && e.role_description !== '') {
                                            //         if (e.role_description.toString().length > 80) {
                                            //             textName = e.role_description.toString().substring(0, 80) + "...";
                                            //         }
                                            //     }
                                            // }
                                            return (<>
                                                <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                    inputDe2(index)
                                                    setSplitText(e.role_description, e.role_id);
                                                }}>
                                                    <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                        {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%', height: '100%' }}></img> */}
                                                        <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                    </div>
                                                    <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                        <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                        <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                            {check.length < 1 && e.role_description}
                                                        </div>
                                                    </div>
                                                    {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                        {e.role_descriptionBullet.map((e) => {
                                                            return (<>
                                                                <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                    ●
                                                                </div>
                                                                <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                    {e}
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>}
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-xl-4 devt1 mt-4'>
                            <div className='talentGroupSet'>
                                <img src={TarlentTrailblazer} style={{ width: '95%', marginLeft: '2.5%', position: 'relative', zIndex: 1 }}></img>
                                <div className='talentGroupBoxList' style={{ marginTop: '-17%' }}>
                                    <div className='telentGroupSetFrame'>
                                        <div className='talentGroupBoxTop' style={{ backgroundImage: `url(${frameTrailblazer})` }}></div>
                                        {/* <div className='talentGroupBoxCenter' style={{backgroundImage: `url(${frameTrailblazer})`}}></div> */}
                                        <div className='talentGroupBoxCenterTrail'></div>
                                        <div className='talentGroupBoxBottom' style={{ backgroundImage: `url(${frameTrailblazer})` }}></div>
                                    </div>
                                    {
                                        groupData.filter((el) => { return el.role_gr_id == '8910e890-2beb-4ed0-9418-b6367e5e5171' }).map((e, index) => {
                                            let textName = e.role_description;

                                            let check = isUseD3.filter((r) => { return r == index })
                                            if (check.length == 0) {
                                                if (e.role_description !== null && e.role_description !== '') {
                                                    if (e.role_description.toString().length > 80) {
                                                        textName = e.role_description.toString().substring(0, 80) + "...";
                                                    }
                                                }
                                            }
                                            return (<>
                                                <div className='row' style={{ margin: 0, padding: '5px', cursor: 'pointer'/* , minHeight: '80px'  */, borderRadius: '5px', background: `${check.length > 0 ? 'rgba(255, 255, 255, 0.25)' : 'none'}` }} onClick={() => {
                                                    inputDe3(index)
                                                    setSplitText(e.role_description, e.role_id);
                                                }}>
                                                    <div className='col-3' style={{ margin: 0, padding: 0 }}>
                                                        {/* <img src={Configs.TTT_Game_Service_IMG + e.role_current_path} style={{ width: '100%' }}></img> */}
                                                        <div className='talentGroupSetImg' style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.role_current_path */})` }}></div>
                                                    </div>
                                                    <div className='col-9' style={{ margin: 0, padding: '0 0 0 10px' }}>
                                                        <div className={check.length > 0 ? 'talentGroupNameTextActive' : 'talentGroupNameText'}>{e.role_name}</div>
                                                        <div className='talentGroupDesc' style={{ display: `${check.length > 0 ? '' : '-webkit-box'}` }} /* dangerouslySetInnerHTML={{ __html: e.role_description }} */ >
                                                            {check.length < 1 && e.role_description}
                                                        </div>
                                                    </div>
                                                    {check.length > 0 && <div className='col-12 row' style={{ margin: 0, padding: 0 }}>
                                                        {e.role_descriptionBullet.map((e) => {
                                                            return (<>
                                                                <div className='col-1' style={{ textAlign: 'center', margin: 0, padding: 0 }}>
                                                                    ●
                                                                </div>
                                                                <div className='col-11' style={{ margin: 0, padding: 0 }}>
                                                                    {e}
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>}
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
        </>
    );
}

export default HowToPlay;
