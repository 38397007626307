import React, { useEffect, useState, useRef } from 'react';
import IMGQRCODE from "../../assets/img/qrCode/qrCodePay.jpg";
import "./alert.css"

function AlertRegis({ isOpen, toggle_alertKick }) {
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);


    useEffect(() => {
        /* console.log('isOpen', isOpen); */
        if (isOpen === true) {
            toggle_modal_alertKick()
        }
    }, [isOpen])

    return (
        <>
            <div className='alertRegisterKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKicRrgisterkBoxShadow' >
                    <div className='alertRegisterKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertRegisterKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHeadAtt' style={{ color: '#F00' }}>
                                    {'ATTENTION'}
                                </div>

                            </div>
                            <div className='underline' style={{ borderBottom: '2px solid #D9D9D9', width: '80%', margin: '0px auto auto' }} />
                            <div className='row' style={{ height: '40px'}}>
                                <div className='alertHeadBody' style={{ color: '#FFF' }}>
                                    {'PLEASE Contact Via LIne Admin '}
                                </div>

                            </div>
                            <div className='row' style={{ height: '87px' }}>
                                <div className='alertKickText'>
                                    {`Contact line Admin to request a Soft Opening password to receive special privileges for you.`}

                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertRegisterKickWarning'>
                                    <img
                                        style={{ width: '150px',borderRadius:'8px' }}
                                        src={IMGQRCODE}
                                    />
                                </div>
                            </div>


                            <div className='row alerRegistertKickBoxButton'>
                                <button className='alertRegisterKickButton' onClick={() => {
                                    toggle_modal_alertKick()
                                    toggle_alertKick()
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default AlertRegis;
