import React, { useEffect, useState } from 'react';
import HeaderTTTMobile from '../../header/HeaderTTTMobile';
import LandingpageTTTMobile from './LandingpageTTTMobile';
import MainpageMobile from './mainpageMobile'
import FooterMobile from '../../footer/FooterMobile';

const IndexTTTMobile = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
  const [mobileLogin, setMobileLogin] = useState(false);
  const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
  const [mobileRegister, setMobileRegister] = useState(false);
  const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

  return (
    <>
      <div style={{overflow: 'hidden'}}>
      <HeaderTTTMobile
        mobileMenu={mobileMenu}
        toggle_mobileMenu={toggle_mobileMenu}
        mobileLogin={mobileLogin}
        toggle_mobileLogin={toggle_mobileLogin}
        mobileRegister={mobileRegister}
        toggle_mobileRegister={toggle_mobileRegister}
      />
      <main>
        <LandingpageTTTMobile
          mobileMenu={mobileMenu}
          toggle_mobileMenu={toggle_mobileMenu}
          mobileLogin={mobileLogin}
          toggle_mobileLogin={toggle_mobileLogin}
          mobileRegister={mobileRegister}
          toggle_mobileRegister={toggle_mobileRegister}
        />
        <MainpageMobile />
      </main>
      {/* <FooterMobile /> */}
      </div>
    </>
  )
}

export default IndexTTTMobile