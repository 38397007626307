import React from "react";
import Vector from '../../assets/img/icon/Vector.png'
import "./cardstepStyle.css";

function cardstep({ num ,active,vector}) {
  return (
    <>
      <div className={`${active=== true?'cardstepShadowactiveStyle':'cardstepShadownotactiveStyle'}`} >
        <div className="cardstepOuterStyle">
          <div className="cardstepInnerStyle"  >
            {vector === true ? (<><img src={Vector} style={{paddingBottom:'10px'}} alt=""  /></>):(<>{num}</>)}
          </div>
        </div>
      </div>
    </>
  );
}

export default cardstep;
