import MicRecorder from "mic-recorder-to-mp3";
import { useEffect, useState, useRef } from "react";
import Configs from "../../config";
import axios from 'axios';

const VoiceRecorder = () => {
    const recorder = useRef(null); //Recorder
    const audioPlayer = useRef(null); //Ref for HTML Audio tag
    const timer = useRef(null); //Ref for interval object
    const time = useRef(0); //Stores the value of time
    const displayTime = useRef(null); //Stores dom ref for div to be used to display time

    const [blobURL, setBlobUrl] = useState(null);
    const [isRecording, setIsRecording] = useState(null);
    const [play, setPlay] = useState(false);

    useEffect(() => {
        //Declares the recorder object and stores it in ref
        recorder.current = new MicRecorder({ bitRate: 128 });
    }, []);

    const startRecording = () => {
        //start() returns a promise incase if audio is not blocked by browser
        recorder.current.start().then(() => {
            setIsRecording(true);
        });
    };

    const stopRecording = () => {
        recorder.current
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
                const newBlobUrl = URL.createObjectURL(blob); //generates url from blob
                setBlobUrl(newBlobUrl); //refreshes the page
                setIsRecording(false);
            })
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        //start/stops timer depending on recording state
        if (isRecording) {
            startTimer();
        } else {
            stopTimer();
        }
    }, [isRecording]);

    const startTimer = () => {
        //sets interval that updates time on UI every second
        time.current = 0;
        timer.current = setInterval(() => {
            time.current = time.current + 1;
            displayTime.current.innerText = time.current;
            //Displays time by updating the DOM directly
        }, 1000);
    };

    const stopTimer = () => {
        clearInterval(timer.current);
    };

    const setTime = () => {
        if (audioPlayer.current) {
            displayTime.current.innerText = Math.floor(audioPlayer.current.duration);
            //Displays time by updating the DOM directly
            //Note: Math.floor is used since audio time is in decimal and player only displays floor values
            //eg 3.86 will be displayed as 3 seconds in player
        }
    };

    const saveBlobToDatabase = async (blobURL) => {
        try {
            // Fetch the blob data from the blobURL
            const response = await fetch(blobURL);
            const blobData = await response.blob();

            // Prepare FormData to send to server
            const formData = new FormData();
            formData.append('Profile', blobData, 'recordedAudio.mp3'); // 'audioFile' is the field name
            formData.append('typeFile', "audio");
            formData.append('projectKey', "TTTSPACESHIP");

            // Example of sending the formData to server using fetch
            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/upload/document3`,
                headers: {

                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/octet-stream',
                },
                data: formData,
            })
                .then(async function (response) {
                    if (response.data.status == true) {
                       
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (error) {
            console.error('Error saving audio:', error);
            // Handle error
        }
    };

    return (
        <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(60, 60, 60, 1)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <button onClick={startRecording} disabled={isRecording}>
                Record
            </button>
            <button onClick={stopRecording} disabled={!isRecording}>
                Stop
            </button>
            <button onClick={() => saveBlobToDatabase(blobURL)}>
                Save
            </button>
            <audio
                ref={audioPlayer}
                src={blobURL}
                controls="controls"
                onLoadedMetadata={setTime} //fethches metadata info like duration
                onTimeUpdate={setTime} //event handler whenever time progresses on player
                onEnded={() => setPlay(false)} //event handler when audio has stopped playing
            />
        </div>
    );
};

export default VoiceRecorder;
