import React, { useEffect, useState } from "react";
import "./leaderBoardCardStyle.css";
import ImgCard from '../../assets/img/userlogo/defultUser.png';
import IconProject from '../../assets/img/icon/Group11.png';
import test from '../../assets/img/blog/1.png';
// width: 245px;
// height: 335px;
function LeaderBoardCard(params) {
    const [size, setSize] = useState({
        cardWidth: '',
        cardHeight: ''
    })

    useEffect((e) => {
        // console.log("cardWidth",params.cardHeight)
        setSize({ ...size, cardWidth: params.cardWidth, cardHeight: params.cardHeight });
        size.cardWidth = params.cardWidth;
        size.cardHeight = params.cardHeight;
    }, [])
    return (
        <>
            
            <div className="cardBody" style={{ backgroundImage: `url(${params.imgTEST !== '' && params.imgTEST != null ? params.imgTEST : ImgCard})`, width: `${size.cardWidth !== '' ? size.cardWidth : '245'}px`, height: `${size.cardHeight !== '' ? size.cardHeight : '335'}px` }}>
                {/* <img src={ImgCard} className="bgCard"></img> */}
                <div className="gradient" style={{width: `${size.cardWidth !== '' ? size.cardWidth : '245'}px`, height: `${size.cardHeight !== '' ? size.cardHeight : '335'}px` }}>
                <div className="frameTop">
                    <div className="iLevel">LEVEL
                        <div className="iLevelIn">210</div>
                    </div>
                    <div className="iName">NAME :
                        <div className="iNameIn">{params.cardName}</div>
                    </div>
                    <div className="iClass">

                    </div>
                </div>
                <div className="frameBottom">
                    <div className="frameBottomLeft">
                        <div className="fbLine"></div>
                        <div className="fbBoxShort">MVP :
                            <div className="fbBoxShortInM">449</div>
                        </div>
                        <div className="fbBoxShort">RATTING :
                            <div className="fbBoxShortInR">{params.cardRating}%</div>
                        </div>
                        <div className="fbLine"></div>
                        <div className="fbBoxLong"> <img src={IconProject} style={{ height: "8px", marginTop: "1px" }}></img> SUCCESSFUL PROJECTS
                            <div className="fbBoxLongIn">59</div>
                            <div className="fbBoxLongInText">PROJECT</div>
                        </div>
                        <div className="fbBoxLong"> <img src={IconProject} style={{ height: "8px", marginTop: "1px" }}></img> PROJECTS INVOLVED
                            <div className="fbBoxLongIn">9</div>
                            <div className="fbBoxLongInText">PROJECT</div>
                        </div>
                    </div>
                    <div className="frameBottomRight">
                        <div className="fbAm">ACHIEVEMENT</div>
                        <div className="fbRBoxMain"></div>
                        <div className="fbRBoxSec"></div>
                        <div className="fbRBoxSec"></div>
                        <div className="fbRBoxSec"></div>
                        <div className="fbRBoxSec"></div>
                        <div className="fbRBoxSec"></div>
                    </div>
                </div>
                </div>
            </div>

        </>
    );
}

export default LeaderBoardCard;