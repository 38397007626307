import React from "react";
import "./numbarbigStyle.css";


function numbarbig({ num }) {
  return (
    <>
      <div className="numbigfont" style={{fontSize:'233px',marginRight:'-48px',bottom:'83px'}} >
        {num || ""}
      </div>
    </>
  );
}

export default numbarbig;
