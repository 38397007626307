import React, { useEffect, useState } from 'react';
import '../../assets/css/contactus.css';
import axios from "axios";
import Configs from "../../config";

import HeaderTTT from "../../components/header/HeaderTTT";
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';

import Title from "../../components/customElement/headertitle";

import saturnBg from './image/saturn.png';
import galaxyBg from './image/galaxy.png';
import detailImg from './image/detail.png';

import { NumericFormat, PatternFormat } from 'react-number-format';

function ContactusT() {
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [checkError, setCheckError] = useState('')
    const [checkErrorTrue, setCheckErrorTrue] = useState(false);

    const [sendMailTemp, setSendMailTemp] = useState({
        contact_name: '',
        contact_email: '',
        contact_to: 'd.issara.ttt07@gmail.com',
        contact_phone: '',
        title_text: '',
    });

    async function sendMailContactForm(params) {

        const error_list = [];

        if (sendMailTemp.contact_name.trim() === '') {
            let temp_err = {
                message: "Please enter information in the fields > [NAME]."
            };
            error_list.push(temp_err);
        };
        if (sendMailTemp.contact_email.trim() === '') {
            let temp_err = {
                message: "Please enter information in the fields > [EMAIL]."
            };
            error_list.push(temp_err);
        };
        if (sendMailTemp.title_text.trim() === '') {
            let temp_err = {
                message: "Please enter information in the fields > [TITLE]."
            };
            error_list.push(temp_err);
        };
        if (sendMailTemp.contact_phone.trim() === '') {
            let temp_err = {
                message: "Please enter information in the fields > [PHONE]."
            };
            error_list.push(temp_err);
        };
        let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        let isValid = emailRegex.test(sendMailTemp.contact_email);

        if (isValid === false) {
            let temp_err = {
                message: "Your email format is incorrect."
            };
            error_list.push(temp_err);
        };


        if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
                err_message += " " + error_list[e].message;
            };
            setCheckErrorTrue(true)
            setCheckError(err_message);;
            toggle_modal_alertKick();
        } else {
            var temp = {
                to: `${sendMailTemp.contact_to},zeerot28@gmail.com,t.kingkan.ttt16@gmail.com`,
                userby: 'TTT',
                subject: 'CONTACT FORM TTT SpaceShip',
                body: `<b>${sendMailTemp.title_text} <br /> ${sendMailTemp.contact_name} <br />  ${sendMailTemp.contact_email}  <br /> ${sendMailTemp.contact_phone}</b>`
            }

            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/mail/getmail`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "application/json",
                },
                data: temp,
            })
                .then(async function (response) {
                    setCheckError('Email sent successfully.');
                    setCheckErrorTrue(false);
                    toggle_modal_alertKick();
                    setSendMailTemp({
                        ...sendMailTemp,
                        contact_name: '',
                        contact_email: '',
                        contact_to: 'd.issara.ttt07@gmail.com',
                        contact_phone: '',
                        title_text: '',
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        };





    }

    const [modeMobile, setModeMobile] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

    useEffect(() => {

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        }
        else {
            setModeMobile(false);
        }

    }, []);

    return (
        <>
            {modeMobile ? (<>
                <HeaderTTTMobile
                    mobileMenu={mobileMenu}
                    toggle_mobileMenu={toggle_mobileMenu}
                    mobileLogin={mobileLogin}
                    toggle_mobileLogin={toggle_mobileLogin}
                    mobileRegister={mobileRegister}
                    toggle_mobileRegister={toggle_mobileRegister}
                />
                <div style={{ position: 'absolute', top: '70px', left: '15px', width: 'calc(100% - 15px)', zIndex: 1 }}>
                    <Title name='ADDRESS' no={1} fix={320} imglong={125} top={7.5} imgbox={110} imgcorner={10} />
                    <div className="contact-detail" style={{ marginTop: '35px' }}><Title name='CONTACT DETAIL' no={3} fix={190} imglong={19} top={5} imgbox={110} imgcorner={10} /></div>
                </div>
                <div className='contactUsSetBg' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '160%', overflow: 'auto' }}>
                    <div style={{ backgroundImage: `url(${galaxyBg})`, backgroundSize: 'cover', width: 'fit-content', height: '100%' }}>
                        <div className='bg-information' style={{ backgroundImage: `url(${saturnBg})`, width: '500px', height: '250px', position: 'relative', top: '32.5%', zIndex: 1 }} >
                            <div className='contactUsMarker' style={{ position: 'absolute', top: '40%', left: '52.5%', width: '20px', height: '20px', border: '2px solid #0047FF', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ backgroundColor: '#0047FF', width: '14px', height: '14px', borderRadius: '50%' }}></div>
                                <div className='contactUsMarkerDetail' style={{ width: '400px', height: '250px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-55%, -105%)' }}>
                                    <img src={detailImg} style={{ width: '100%' }}></img>
                                    <svg height="40" width="30" style={{ position: 'absolute', bottom: '-5%', right: '45%' }}>
                                        <line x1="30" y1="0" x2="30" y2="40" style={{ stroke: '#0047FF', strokeWidth: '4' }} />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', top: '87.5%', left: '15px', width: 'calc(100% - 15px)', zIndex: 1 }}>
                    <div className="contact-detail" style={{ marginTop: '35px' }}><Title name='CONTACT FORM' no={3} fix={190} imglong={19} top={5} imgbox={110} imgcorner={10} /></div>
                </div>
                <div style={{ position: 'absolute', top: '100%', margin: '0 15px', width: 'calc(100% - 15px)', zIndex: 1 }}>
                    <div className="contact-detail-box-form" style={{ gridTemplateColumns: 'auto' }}>
                        <div className="contact-detail-box-left">
                            <div className="form-text">NAME</div>
                            <input className="form-box-left" type="text"
                                style={{ width: 'calc(100% - 15px)' }}
                                value={sendMailTemp.contact_name}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_name: e.target.value
                                    })
                                }}
                            ></input>

                            <div className="form-text">EMAIL</div>
                            <input className="form-box-left" type="text"
                                style={{ width: 'calc(100% - 15px)' }}
                                value={sendMailTemp.contact_email}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_email: e.target.value
                                    })
                                }}
                            ></input>

                            <div className="form-text">PHONE</div>
                            <input
                                type="text"
                                id="numberInput"
                                name="numberInput"
                                style={{ width: 'calc(100% - 15px)' }}
                                value={sendMailTemp.contact_phone.replace(/\D/g, '')}
                                className="form-box-left"
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_phone: e.target.value.replace(/\D/g, ''),
                                    });

                                }}
                            />
                            <div className="form-text">TITLE</div>
                            <input className="form-box-left" type="text"
                                style={{ width: 'calc(100% - 15px)' }}
                                value={sendMailTemp.title_text}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        title_text: e.target.value
                                    })
                                }}
                            ></input>
                            <div className="form-text">&nbsp;</div>
                            <button className="form-box-submit"
                                style={{ width: 'calc(100% - 15px)' }}
                                onClick={() => { sendMailContactForm() }}
                            >SUBMIT</button>
                        </div>
                        <div className="contact-detail-box-right">


                        </div>
                    </div>
                </div>
                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999, width: '100%' }}>
                    <div className='alertKickBoxShadow' style={{width: '100%'}}>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '90%' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '100%' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${checkErrorTrue ? 'red' : 'green'}` }}>
                                        {checkErrorTrue ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {checkErrorTrue ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        {checkError}
                                    </div>
                                </div>


                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick()
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>) : (<>
                <HeaderTTT />
                <img className='bg-information' src={galaxyBg} alt="" />
                <div style={{ position: 'absolute', top: '20%', left: '7.5%', width: 'calc(100% - 7.5%)', zIndex: 1 }}>
                    <Title name='ADDRESS' no={1} fix={320} imglong={125} top={7.5} imgbox={110} imgcorner={10} />
                    <div className="contact-detail" style={{ marginTop: '35px' }}><Title name='CONTACT DETAIL' no={3} fix={190} imglong={19} top={5} imgbox={110} imgcorner={10} /></div>
                </div>
                <div className='contactUsSetBg' style={{ position: 'relative', marginTop: '0%', left: 0, width: '100%', height: '100%', overflow: 'auto' }}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='bg-information' style={{ backgroundImage: `url(${saturnBg})`, width: '1500px', height: '750px', position: 'relative', zIndex: 1 }} >
                            <div className='contactUsMarker' style={{ position: 'absolute', top: '40%', left: '52.5%', width: '20px', height: '20px', border: '2px solid #0047FF', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <div style={{ backgroundColor: '#0047FF', width: '14px', height: '14px', borderRadius: '50%' }}></div>
                                <div className='contactUsMarkerDetail' style={{ width: '400px', height: '250px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-95%, -100%)' }}>
                                    <img src={detailImg} style={{ width: '100%', zIndex: 1, position: 'relative' }}></img>
                                    <svg height="40" width="30" style={{ position: 'absolute', bottom: 0, right: '5%' }}>
                                        <line x1="20" y1="0" x2="30" y2="40" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                        <line x1="1" y1="1" x2="20" y2="0" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'relative', top: '0', left: '7.5%', width: 'calc(100% - 7.5%)', zIndex: 1 }}>
                    <div className="contact-detail" style={{ marginTop: '35px' }}><Title name='CONTACT FORM' no={3} fix={190} imglong={19} top={5} imgbox={110} imgcorner={10} /></div>
                </div>
                <div style={{ position: 'relative', top: '0', padding: '0 7.5% 2.5% 7.5%', zIndex: 1 }}>
                    <div className="contact-detail-box-form">
                        <div className="contact-detail-box-left">
                            <div className="form-text">NAME</div>
                            <input className="form-box-left" type="text"
                                value={sendMailTemp.contact_name}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_name: e.target.value
                                    })
                                }}
                            ></input>

                            <div className="form-text">PHONE</div>
                            <input
                                type="text"
                                id="numberInput"
                                name="numberInput"
                                value={sendMailTemp.contact_phone.replace(/\D/g, '')}
                                className="form-box-left"
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_phone: e.target.value.replace(/\D/g, ''),
                                    });

                                }}
                            />
                            <div className="form-text" style={{ visibility: 'hidden' }}>To </div>
                            <div className="form-box-left" style={{ visibility: 'hidden' }}>xxxxxxx</div>
                        </div>
                        <div className="contact-detail-box-right">
                            <div className="form-text">EMAIL</div>
                            <input className="form-box-right" type="text"
                                value={sendMailTemp.contact_email}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        contact_email: e.target.value
                                    })
                                }}
                            ></input>
                            <div className="form-text">TITLE</div>
                            <input className="form-box-right" type="text"
                                value={sendMailTemp.title_text}
                                onChange={(e) => {
                                    setSendMailTemp({
                                        ...sendMailTemp,
                                        title_text: e.target.value
                                    })
                                }}
                            ></input>
                            <div className="form-text">&nbsp;</div>
                            <button className="form-box-submit"
                                onClick={() => { sendMailContactForm() }}
                            >SUBMIT</button>
                        </div>
                    </div>
                </div>
                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                    <div className='alertKickBoxShadow' >
                        <div className='alertKickBox' style={{ height: 'fit-content' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${checkErrorTrue ? 'red' : 'green'}` }}>
                                        {checkErrorTrue ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {checkErrorTrue ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        {checkError}
                                    </div>
                                </div>


                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick()
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}
        </>
    )
}

export default ContactusT