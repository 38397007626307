import React, { useEffect, useState, useRef } from 'react';
import HeaderTTT from "../../../components/header/HeaderTTT";
import './achievement.css';
import axios from "axios";
import Configs from "../../../config";
import SpaceShipIMG_1 from "./image/spaceship_3.png";
import Pepro_1 from "./image/pepro_2.png";
import TextAch_1 from "./image/TextAch_1.png";
import Acm_1 from "./image/acm-1.png";
import Acm_2 from "./image/acm-2.png";
import Acm_3 from "./image/acm-3.png";
import Acm_4 from "./image/acm-4.png";
import Acmpr_1 from "./image/acmpe-1.png";
import Acmpr_2 from "./image/acmpe-2.png";
import UnderImg from "./image/img-under2.png";
import HeaderTTTMobile from '../../../components/header/HeaderTTTMobile';

function Achievement() {


    const [modeMobile, setModeMobile] = useState(false);



    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };



    useEffect(() => {
        /*   getNewsINfo() */
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        }
        else {
            setModeMobile(false);
        }


    }, [])

    return (
        <>

            {modeMobile ? (<>
                <HeaderTTTMobile
                    mobileMenu={mobileMenu}
                    toggle_mobileMenu={toggle_mobileMenu}
                    mobileLogin={mobileLogin}
                    toggle_mobileLogin={toggle_mobileLogin}
                    mobileRegister={mobileRegister}
                    toggle_mobileRegister={toggle_mobileRegister}
                />

                <main className='' style={{ position: 'absolute', top: '55px', width: '100%', overflowY: 'hidden', overflowX: 'hidden', height: '895px', background: 'linear-gradient(112.1deg, rgb(32, 38, 57) 100%, rgb(195,203,210) 100%)' }}>
                    <div style={{ width: '100%', height: '100%', backgroundImage: `url(${SpaceShipIMG_1})`, backgroundPosition: 'top', backgroundSize: '100%',backgroundRepeat: 'no-repeat' }}></div>
                    <div className='act-text-2' style={{ top: '18%' }}>
                        <div className='text-con-1' style={{ fontSize: '55px' }}> SPACESHIP </div>
                    </div>
                    <div className='act-text' style={{ top: '27%' }}>
                        <img src={TextAch_1} className='img-text-1' style={{ width: '90%' }} />
                    </div>
                    <div className='row crad-1' style={{ top: '34%', width: '100%' }}>
                        <img src={Acm_1} className='card-box-ac-1' style={{ width: '35%' }} ></img>
                        <img src={Acm_2} className='card-box-ac-2' style={{ width: '35%' }}></img>
                    </div>
                    <div className='row crad-1' style={{ top: '64%', width: '100%' }}>
                    <img src={Acmpr_1} className='card-box-ac-3' style={{width:'35%'}}></img>
                        <img src={Acm_4} className='card-box-ac-4' style={{width:'35%'}}></img>
                    </div>y
                    <div className='undertop' style={{ bottom:'190px' }}>
                        <img src={UnderImg} className='underline' />
                    </div>
                </main>
                {/*  <div className='box-shodow' style={{ backgroundSize: '20%' }} /> */}
                <main className='' style={{ position: 'absolute', top: '895px', width: '100%', overflowY: 'hidden', overflowX: 'hidden', height: '625px', background: 'linear-gradient(112.1deg, rgb(32, 38, 57) 100%, rgb(195,203,210) 100%)' }}>
                    <div style={{ width: '100%', height: '100%', backgroundImage: `url(${Pepro_1})`, backgroundPosition: 'top', backgroundSize: '100%',backgroundRepeat: 'no-repeat' }}></div>
                    <div className='act-text-3' style={{top:'24%'}}>
                        <div className='text-con-pr-1' style={{fontSize:'55px'}}> PERSONAL </div>
                    </div>
                    <div className='act-text-4' style={{top:'36%'}}>
                        <img src={TextAch_1} style={{width:'90%'}} className='img-text-2' />
                    </div>
                    <div className='row crad-2' style={{top:'50%'}}>

                        <img src={Acm_3} className='card-box-pr-1' style={{width:'35%'}} ></img>
                        <img src={Acmpr_2} className='card-box-pr-2' style={{width:'35%'}} ></img>

                    </div>
                    <div className='undertop' style={{ bottom:'150px'}} >
                        <img src={UnderImg} className='underline-1' />
                    </div>

                </main>


            </>) : (<>
                <HeaderTTT />
                <main className='main-css' >
                    <div style={{ backgroundImage: `url(${SpaceShipIMG_1})`, height: 'fit-content', backgroundSize: '100%' }} className='img-contan-1' >
                        <img src={SpaceShipIMG_1} className='img-contan-1' style={{ backgroundSize: '100%', visibility: 'hidden' }} />

                    </div>
                    <div className='act-text-2'>
                        <div className='text-con-1'> SPACESHIP </div>
                    </div>
                    <div className='act-text'>
                        <img src={TextAch_1} className='img-text-1' />
                    </div>

                    <div className='row crad-1'>
                        <img src={Acm_1} className='card-box-ac-1' ></img>
                        <img src={Acm_2} className='card-box-ac-2' ></img>
                        <img src={Acmpr_1} className='card-box-ac-3' ></img>
                        <img src={Acm_4} className='card-box-ac-4' ></img>

                    </div>
                    <div className='undertop' >
                        <img src={UnderImg} className='underline' />
                    </div>

                </main>
                <div className='box-shodow' style={{ backgroundSize: '20%' }} />
                <main className='main-css' >

                    <div style={{ backgroundImage: `url(${Pepro_1})`, height: 'fit-content', backgroundSize: '100%' }} className='img-contan-1' >
                        <img src={Pepro_1} className='img-contan-1' style={{ backgroundSize: '100%', visibility: 'hidden' }} />
                    </div>
                    <div className='act-text-3'>
                        <div className='text-con-pr-1'> PERSONAL </div>
                    </div>
                    <div className='act-text-4'>
                        <img src={TextAch_1} className='img-text-2' />
                    </div>
                    <div className='row crad-2'>

                        <img src={Acm_3} className='card-box-pr-1' ></img>
                        <img src={Acmpr_2} className='card-box-pr-2' ></img>

                    </div>
                    <div className='undertop' >
                        <img src={UnderImg} className='underline-1' />
                    </div>
                </main>
            </>)}




        </>
    );
}

export default Achievement;
