import React, { useEffect, useState, useRef } from 'react';
import step5A from '../image/step5-a.png';
import step1011 from '../image/step10-11.png';
import step101 from '../image/step10-1.png';
import step102 from '../image/step10-2.png';
import redHand from '../image/redHand.png';

function HowToPlayStep10(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step5A} alt="" style={{ bottom: '-7px', left: '-4px', width: '25%' }} />

                <div className='howToPlayStep1Detail' style={{ top: '120px', left: '350px', width: '525px',height:'190px'  }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>Specific Requirement</div><div style={{fontSize:'13.2px'}}>โดยเข้ามาในหน้า Timeline จะเจอกับเมนูไอคอน Elements ที่จะอธิบายถึงความต้องการต่างๆ เมื่อเราจะอธิบายถึงความต้องการ
ของเราให้ชัดเจนมากขึ้น เช่น เมื่อเราต้องการอธิบายถึงการรายงาน หรือ Report โดยการกดที่ไอคอน Elements ของ Report ในการบ่งบอก
ให้คนที่เข้ามาอ่านเข้าใจถึงความต้องการมากขึ้น</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '330px', left: '350px', width: '525px',height:'170px'}}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>แก้ไข ลบ และ ย้าย</div><div style={{fontSize:'13.2px'}}><span style={{fontWeight: 1000, fontSize: '14px'}}>แก้ไข: </span>สามารถแก้ไขข้อความ แก้ไขการแนบรูป การแนบไฟล์ได้ เมื่อแก้ไขเสร็จสิ้นให้กด Save หรือ Cancel เมื่อยกเลิก
                    <br /><span style={{fontWeight: 1000, fontSize: '14px'}}>ลบ: </span>สามารถลบข้อความที่เราไม่ต้องการ เมื่อกดแล้วจะแจ้งเตือนก่อนกดตกลง
                    <br /><span style={{fontWeight: 1000, fontSize: '14px'}}>ย้าย: </span>เป็นการย้ายข้อความไปยังเมนูใหม่ที่เราต้องการย้าย หลังจากนั้นข้อความจะทำการย้ายอัตโนมัติ</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '520px', left: '350px', width: '525px',height:'90px' }}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>แนบไฟล์และรูปภาพ</div><div style={{fontSize:'13.2px'}}>กดที่ไอคอนแนบไฟล์และแนบรูป เมื่อกดเข้าไปให้กดไอคอน ADD FILE
และ ADD IMAGE เมื่อแนบเสร็จสิ้นให้กด TTT Prompt</div></div>
                </div>

                <img className='howToPlayStep1ImgBg' src={step1011} alt="" style={{ top: '260px', left: '600px', width: '3%' }} />
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '275px', left: '620px', width: '2.5%'}}/>


                <img className='howToPlayStep1ImgBg' src={step101} alt="" style={{ top: '115px', left: '905px', width: '36%' }} />
                <img className='howToPlayStep1ImgBg' src={step102} alt="" style={{ top: '430px', left: '905px', width: '36%' }} />

            </div>
        </>
    );
}

export default HowToPlayStep10;