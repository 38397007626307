import React, { useEffect, useState, useRef } from 'react';
import "./tabSrs.css"

function AlertConfrim({ isOpen,mainFuc }) {
    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);
    const [tempValue,setTempValue] = useState({
        dv_summary_of_change:''
    })

    useEffect(() => {
        /* console.log('isOpen', isOpen); */
        if (isOpen === true) {
            setTempValue({...tempValue,
                dv_summary_of_change:''
            })
            toggle_modal_confrimModal()
        }else{
       
            setConfrimModal(false)
        }
    }, [isOpen])

    return (
        <>
            <div className={`ModalSummary ${confrimModal ? 'opened' : 'closed'}`}>          </div>
            <div className={`box-body-summary  ${confrimModal ? 'opened' : 'closed'}`} style={{ left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', width: '600px', height: '300px' }}>
                <div className="col-12" style={{ margin: '30px auto auto', padding: '0 10px',height:'205px' }}>
                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '3px', marginBottom: '10px' }}> Summary of change</div>
                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                        <textarea className="areaTEst"
                            id={'input-summary'}
                            type='text'
                            style={{ marginTop: '1px', maxHeight: '155px',height:'155px' }}
                            value={tempValue.dv_summary_of_change}
                            onChange={(e) => {
                                setTempValue({...tempValue,
                                    dv_summary_of_change: e.target.value
                                })
                            }}
                        >
                        </textarea>

                    </div>
                </div>
                <div className='commentAretButton' style={{margin:'20px 0px 0px -11px'}}>


                    <div className='row' style={{padding:'0px',margin:'0px'}}>
                        <div className="editCommentEditbttTYes" onClick={() => {
                            mainFuc('save',tempValue)
                        }}>  SAVE</div>
                        &nbsp;
                        <div className="editCommentEditbttTYes" onClick={() => {
                              mainFuc('close')
                        }}>CANCEL</div>
                    </div>


                </div>
            </div>


        </>
    );
}

export default AlertConfrim;
