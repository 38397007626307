

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/THSarabun-normal";
import moment from "moment";
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";


/* import QRCode from "qrcode"; */

async function ProjectTimelinereport(data,id,mode,summaryofchange) {

    // console.log("waveee",data);
    let project = data.project[0],
        formation = data.formation,
        delivery = data.delivery,
        customer = data.customer,
        schedule = data.schedule,
        img =  {}
       
        if(data.img){
   
            let getpath = await axios.get(`https://api.box.com/2.0/files/${data.img.fd_img_id}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });
      
            img = URL.createObjectURL(getpath.data);
        }     
        console.log("logItem: ",img);
        // if (data.img.length > 0) {
        //     img = data.img[0]
        // }


const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
    /*  const mmToPx = (mm) => (mm * 96) / 25.4; */

     return new Promise((resolve, reject) => {
         const img = new Image();
         img.crossOrigin = 'Anonymous'; 
         img.src = imageUrl;
         img.onload = () => {
             const canvas = document.createElement('canvas');
             const ctx = canvas.getContext('2d');

             let wpx = img.width;
             let hpx = img.height;
             const mwpx = /* mmToPx(maxWidth) */maxWidth;
             const mhpx = /* mmToPx(maxHeight) */maxHeight;
            
            /*  console.log('w',mwpx,wpx);
             console.log('h',mhpx,hpx); */

 if (wpx > hpx) {
     if (wpx > mwpx) {
         hpx = Math.round((hpx * mwpx) / wpx);
         wpx = mwpx;
     }
 } else {
     if (hpx > mhpx) {
         wpx = Math.round((wpx * mhpx) / hpx);
         hpx = mhpx;
     }
 }

 canvas.width = wpx;
 canvas.height = hpx;

 ctx.drawImage(img, 0, 0, wpx, hpx);

 resolve(canvas.toDataURL('image/jpeg', 0.8)); 

            
         };

         img.onerror = () => {
             reject(new Error('Failed to load image.'));
         };
     });
 };

 async function CheckAndCreateFolderBox(data,olddata,file) {
    let temp = {
        folderLis: ["PROJECT",`PROJECT_${id}`,"PROJECT DOCUMENTS","PROJECT & TIMELINE"]
    }
   /*  temp.folderLis.push(folder_name) */
    await axios({
        method: 'post',
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
        data: temp
    })
        .then(async function (response) {
            if (response.data) {
                data.files = file
                await UploadFiles(data, response.data.folder_id,olddata)
         
            }
        })
        .catch(function (error) {
           
            console.log(error);
        });
};

async function UploadFiles(item, folder_id,olddata) {
    console.log(item);
    const dataImg = new FormData();
    dataImg.append('File', item.files);
    dataImg.append('folder_id', folder_id);
    dataImg.append('key', "PROJECT & TIMELINE");
    dataImg.append('image_old_list', JSON.stringify(olddata));
    dataImg.append('ref_id', item.id);
  
    await axios({
        method: 'post',
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "multipart/form-data",
        },
        data: dataImg
    })
        .then(async function (response) {
            if (response.data) {
              if (response.data.image_id_list.length > 0) {
                  return true
              
              }
             
            }
        })
        .catch(function (error) {
          
            console.log(error);
        });
  };

    let imghead = ''

    
   /*  console.log(imghead); */
    const doc = new jsPDF("p", "mm", "a4");
    
    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
    doc.setFont("THSarabunNew", 'normal');
    ///////////กำหนดตั้งค่าหน้าแนวตั้ง
    let padding = 12////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 296///สูง
        , inpage = 36
        , page = 1
        , r = 194
        , g = 194
        , b = 194
        ,endpage = 254

        const mmToPx = (mm) => (mm * 96) / 25.4;
        
        if (img.length > 0) {
            // img = data.img[0]
            imghead = img
            // console.log("check imghead: ", imghead);
        }else{
            
            imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg
            // console.log("check imghead: ", imghead);
        }
    let tabledelivery = {
        col1: CutPadding('w', 4, -padding),
        col2: CutPadding('w', 12, -padding),
        col3: CutPadding('w', 59, -padding),
        col4: CutPadding('w', 25, -padding),
        name1: 'No',
        name2: 'Due Date',
        name3: 'Delivery Items List',
        name4: 'Remark',
        loop: 4
    },
        tableformation = {
            col1: CutPadding('w', 4, -padding),
            col2: CutPadding('w', 18, -padding),
            col3: CutPadding('w', 13, -padding),
            col4: CutPadding('w', 33, -padding),
            col5: CutPadding('w', 22, -padding),
            col6: CutPadding('w', 10, -padding),
            name1: 'No',
            name2: 'Fullname',
            name3: 'Position',
            name4: 'Short Responsibility (Optional)',
            name5: 'Email',
            name6: 'Tel',
            loop: 6
        },
        tablecustomer = {
            col1: CutPadding('w', 4, -padding),
            col2: CutPadding('w', 18, -padding),
            col3: CutPadding('w', 13, -padding),
            col4: CutPadding('w', 33, -padding),
            col5: CutPadding('w', 22, -padding),
            col6: CutPadding('w', 10, -padding),
            name1: 'No',
            name2: 'Fullname',
            name3: 'Position',
            name4: 'Short Responsibility (Optional)',
            name5: 'Email',
            name6: 'Tel',
            loop: 6
        },
        tableschedule = {
            col1: CutPadding('w', 11, -padding),
            col2: CutPadding('w', 45, -padding),
            col3: CutPadding('w', 11, -padding),
            col4: CutPadding('w', 11, -padding),
            col5: CutPadding('w', 11, -padding),
            col6: CutPadding('w', 11, -padding),
            name1: 'Task No.',
            name2: 'Task Name',
            name3: 'Duration',
            name4: 'Start',
            name5: 'Finish',
            name6: 'Pre Task.',
            loop: 6
        }


        let colordrop = [
            {
                before:'#676767',
                after:'#DEDEDE'
            },
            {
                before:'#FF00E5',
                after:'#FBDCFF'
            },
            {
                before:'#BA84FF',
                after:'#CCD9FA'
            },
            {
                before:'#00A5FF',
                after:'#CDEEFF'
            },
            {
                before:'#259B24',
                after:'#D3FFCF'
            },
            {
                before:'#FFEB3B',
                after:'#FFFDCF'
            },
            {
                before:'#FF8000',
                after:'#FFE0C1'
            },
            {
                before:'#FF0000',
                after:'#FFD9D9'
            },
         ]
        if (project.federation_color !== null) {
            let selectcolor = colordrop.findIndex((e) => e.before == project.federation_color || e.after == project.federation_color)
            console.log(selectcolor,project.federation_color);
            if (selectcolor !== -1) {
                let hex = colordrop[selectcolor].after.replace(/^#/, '');
                let bigint = parseInt(hex, 16);
        
                r = (bigint >> 16) & 255;
                g = (bigint >> 8) & 255;
                b = bigint & 255;
            }
        
        }


    Shot('fs', 11)

    setpage()

    Shot('fs', 23)

    Shot('t', CutPadding('w', 50), 39, TexttoString(`Project Plan & Timeline`), 'c')
    Shot('t', CutPadding('w', 50), 39, TexttoString(`Project Plan & Timeline`), 'c')

    Shot('fs', 20)
    Shot('t', CutPadding('w', 50), 49, TexttoString(`แผนการดำเนินการและระยะเวลาโครงการ`), 'c')
    inpage += 20

    Shot('dc', 0, 0, 0);
    Shot('fs', 13)

    let namehead = `${project.project_name || ''}`.split('\n')
    console.log(namehead);

    let numh = 4

    for (let f of namehead) {

        let textcut = doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            Shot('t', CutPadding('w', 16, 2), inpage + numh, TexttoString(c), 'l')

            numh += 5
        }

        console.log(textcut);


    }

    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numh)
    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numh, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Project Name:`), 'l')
    inpage += numh
    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), 8, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 5, TexttoString(`Customer Code:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 14, -padding), 8)
    Shot('t', CutPadding('w', 16, 13), inpage + 5, TexttoString(project.pp_customer_code || ''), 'c')


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 30), inpage, CutPadding('w', 22, -padding), 8, 'fd')
    Shot('t', CutPadding('w', 30, 2), inpage + 5, TexttoString(`Customer Name:`), 'l')

    Shot('r', CutPadding('w', 52), inpage, CutPadding('w', 48, -padding), 8)
    Shot('t', CutPadding('w', 52, 2), inpage + 5, TexttoString(`${project.pp_customer_name || ''}`), 'l')
    inpage += 8
    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), 8, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 5, TexttoString(`Team Member`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 14, -padding), 8)
    Shot('t', CutPadding('w', 16, 10), inpage + 5, TexttoString(formation.length), 'c')
    Shot('i', iconMember, CutPadding('w', 16, 13), inpage + 2.5, 5, 3)
    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 30), inpage, CutPadding('w', 22, -padding), 8, 'fd')
    Shot('t', CutPadding('w', 30, 2), inpage + 5, TexttoString(`Project Start - Finish Date:`), 'l')
    Shot('r', CutPadding('w', 52), inpage, CutPadding('w', 21, -padding), 8)
    Shot('t', CutPadding('w', 52, 2), inpage + 5, TexttoString(project.pp_start_date !== null ? moment(project.pp_start_date).add('year', 543).format('DD/MM/YYYY') : ''), 'l')
    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 73), inpage, CutPadding('w', 6, -padding), 8, 'fd')
    Shot('t', CutPadding('w', 73, 4), inpage + 5, TexttoString(`To`), 'l')
    Shot('r', CutPadding('w', 79), inpage, CutPadding('w', 21, -padding), 8)
    Shot('t', CutPadding('w', 79, 2), inpage + 5, TexttoString(project.pp_finish_date !== null ? moment(project.pp_finish_date).add('year', 543).format('DD/MM/YYYY') : ''), 'l')

    inpage += 12

    Shot('fs', 15)
    Shot('t', CutPadding('w', 0), inpage + 5, TexttoString('Project Outline (Development Method / Development Environment)'), 'l')

    Shot('fs', 12)
    inpage += 7

    let nameobj = `${project.pp_objective || ''}`.split('\n'), numobj = 4

    for (let f of nameobj) {
        let ho = customSplit(`${f}`)
        console.log(ho);
        let textcut = splitTextToSizeMultilingual(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numobj > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numobj, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Objective:`), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numobj)
                Shot("add");
                inpage = 36
                numobj = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numobj, TexttoString(c), 'l')

            numobj += 5


        }
    }
    if (numobj < 14) {
        numobj = 14
    }

    Shot('fc', r, g, b);

    console.log(numobj);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numobj /* - 7 */, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Objective:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numobj /* - 7 */)
    inpage += numobj /* - 7 */

    let namesco = `${project.project_description || ''}`.split('\n'), numsco = 4

    for (let f of namesco) {

        let textcut =  doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numsco > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numsco, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Scope/ Short`), 'l')
                Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Description:`), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numsco)
                Shot("add");
                inpage = 36
                numsco = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numsco, TexttoString(c), 'l')


            numsco += 5


        }
    }


    if (numsco < 14) {
        numsco = 14
    }


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numsco - 2, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Scope/ Short`), 'l')
    Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Description:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numsco - 2)
    inpage += numsco - 2


    let namedt = `${project.pp_dev_tool || ''}`.split('\n'), numdt = 4

    for (let f of namedt) {

        let textcut = doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numdt > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numdt, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Development `), 'l')
                Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Tools:`), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numdt)
                Shot("add");
                inpage = 36
                numdt = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numdt, TexttoString(c), 'l')


            numdt += 5


        }
    }
    

    if (numdt < 14) {
        numdt = 14
    }


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numdt - 2, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Development `), 'l')
    Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Tools:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numdt - 2)

    inpage += numdt - 2

    let namedl = `${project.pp_dev_language || ''}`.split('\n'), numdl = 4

    for (let f of namedl) {

        let textcut = doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numdl > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numdl, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Development`), 'l')
                Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Language:`), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numdl)
                Shot("add");
                inpage = 36
                numdl = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numdl, TexttoString(c), 'l')


            numdl += 5


        }
    }
   

    if (numdl < 14) {
        numdl = 14
    }


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numdl - 2, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Development`), 'l')
    Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Language:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numdl - 2)

    inpage += numdl - 2

    let namest = `${project.pp_solution_technology || ''}`.split('\n'), numst = 4

    for (let f of namest) {

        let textcut = doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numst > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numst, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Solution / `), 'l')
                Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Technology:`), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numst)
                Shot("add");
                inpage = 36
                numst = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numst, TexttoString(c), 'l')


            numst += 5


        }
    }


    if (numst < 14) {
        numst = 14
    }


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numst - 2, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`Solution / `), 'l')
    Shot('t', CutPadding('w', 0, 2), inpage + 9, TexttoString(`Technology:`), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numst - 2)

    inpage += numst - 2

    let namesdlc = `${project.pp_sdlc || ''}`.split('\n'), numsdlc = 4

    for (let f of namesdlc) {

        let textcut = doc.splitTextToSize(`${f}`, CutPadding('w', 83, -(padding)));

        for (let c of textcut) {
            if (inpage + numsdlc > endpage) {
                Shot('fc', r, g, b);
                Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numsdlc, 'fd')
                Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`SDLC `), 'l')
                Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numsdlc)
                Shot("add");
                inpage = 36
                numsdlc = 4
                page += 1
                setpage()

            }
            Shot('t', CutPadding('w', 16, 2), inpage + numsdlc, TexttoString(c), 'l')


            numsdlc += 5


        }
    }
   

    if (numsdlc < 11) {
        numsdlc = 11
    }


    Shot('fc', r, g, b);
    Shot('r', CutPadding('w', 0), inpage, CutPadding('w', 16, -padding), numsdlc - 2, 'fd')
    Shot('t', CutPadding('w', 0, 2), inpage + 4, TexttoString(`SDLC `), 'l')
    Shot('r', CutPadding('w', 16), inpage, CutPadding('w', 84, -padding), numsdlc - 2)
    /* Delivery Items------------------------------------------------------------------------------------------------------------------------------------------ */
    inpage += numsdlc + 8

    if (inpage + 28 > endpage) {
        Shot("add");
        inpage = 36
        page += 1
        setpage()
    }
    Shot('fs', 15)
    Shot('t', CutPadding('w', 0), inpage, TexttoString(`Delivery Items `), 'l')

    Shot('fs', 12)
    inpage += 2

    ShotTable('ht', tabledelivery, inpage, tabledelivery.loop, 8, '', 'fd')
    inpage += 8
    if (delivery.length > 0) {


        for (let idl = 0; idl < delivery.length; idl += 1) {


            let textcutitem = doc.splitTextToSize(`${delivery[idl].pd_delivery_item_list || ''}`, CutPadding('w', 0, tabledelivery.col3 - (padding + 2))),
                textcutremark = doc.splitTextToSize(`${delivery[idl].pd_remark || ''}`, CutPadding('w', 0, tabledelivery.col4 - (padding + 2)))

            let chceklength = textcutitem.length
            if (chceklength < textcutremark.length) {
                chceklength = textcutremark.length
            }
            let currentDate = new Date(delivery[idl].pd_due_date)
            let day = currentDate.getDate();
            let monthNames = [
                "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
                "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
            ];
            let monthIndex = currentDate.getMonth();
            let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2);

            let formattedDate = `${day} ${monthNames[monthIndex]} ${thaiYear}`;

            let numecut = 4

            for (let i = 0; i < chceklength; i += 1) {
                if (inpage + numecut > endpage) {

                    Shot('r', Colposition('r', tabledelivery, 1), inpage, CutPadding('w', 0, tabledelivery[`col1`] - padding), numecut);
                    Shot('r', Colposition('r', tabledelivery, 2), inpage, CutPadding('w', 0, tabledelivery[`col2`] - padding), numecut);
                    Shot('r', Colposition('r', tabledelivery, 3), inpage, CutPadding('w', 0, tabledelivery[`col3`] - padding), numecut);
                    Shot('r', Colposition('r', tabledelivery, 4), inpage, CutPadding('w', 0, tabledelivery[`col4`] - padding), numecut);


                    Shot("add");
                    inpage = 36
                    numecut = 4
                    page += 1
                    ShotTable('ht', tabledelivery, inpage, tabledelivery.loop, 8, '', 'fd')
                    inpage += 8

                    Shot('t', Colposition('t', tabledelivery, 1, 'c'), inpage + numecut, TexttoString(idl + 1), 'c');

                    Shot('t', Colposition('t', tabledelivery, 2, 'c'), inpage + numecut, TexttoString(formattedDate), 'c');
                    setpage()


                }
                if (i === 0) {
                    Shot('t', Colposition('t', tabledelivery, 1, 'c', 1), inpage + (numecut), TexttoString(idl + 1), 'c');
                    Shot('t', Colposition('t', tabledelivery, 2, 'c', 1), inpage + (numecut), TexttoString(formattedDate), 'c');
                
                }
                if (i <= textcutitem.length - 1) {
                    Shot('t', Colposition('t', tabledelivery, 3, 'l', 1), inpage + numecut, TexttoString(textcutitem[i]), 'l');
                }
                if (i <= textcutremark.length - 1) {
                    Shot('t', Colposition('t', tabledelivery, 4, 'l', 1), inpage + numecut, TexttoString(textcutremark[i]), 'l');
                }

                numecut += 5
            }
           /*  Shot('t', Colposition('t', tabledelivery, 1, 'c'), inpage + (numecut), TexttoString(idl + 1), 'c');
            Shot('t', Colposition('t', tabledelivery, 2, 'c'), inpage + (numecut), TexttoString(formattedDate), 'c'); */
            Shot('r', Colposition('r', tabledelivery, 1), inpage, CutPadding('w', 0, tabledelivery[`col1`] - padding), numecut);
            Shot('r', Colposition('r', tabledelivery, 2), inpage, CutPadding('w', 0, tabledelivery[`col2`] - padding), numecut);
            Shot('r', Colposition('r', tabledelivery, 3), inpage, CutPadding('w', 0, tabledelivery[`col3`] - padding), numecut);
            Shot('r', Colposition('r', tabledelivery, 4), inpage, CutPadding('w', 0, tabledelivery[`col4`] - padding), numecut);
            inpage += numecut



        }
    }
    /* Project formation------------------------------------------------------------------------------------------------------------------------------------------ */
    inpage += 12

    if (inpage + 28 > endpage) {
        Shot("add");
        inpage = 36
        page += 1
        setpage()
    }




    Shot('fs', 15)
    Shot('t', CutPadding('w', 0), inpage, TexttoString(`Project formation`), 'l')

    Shot('fs', 12)
    inpage += 2

    ShotTable('ht', tableformation, inpage, tableformation.loop, 8, '', 'fd')
    inpage += 8
    if (formation.length > 0) {
        for (let ifm = 0; ifm < formation.length; ifm++) {

            let Fullname = `${formation[ifm].pf_fullname || ''}`.split('\n'),
                Position = `${formation[ifm].pf_position || ''}`.split('\n'),
                Responsibility = `${formation[ifm].pf_short_responsibility || ''}`.split('\n'),
                Email = `${formation[ifm].pf_email || ''}`.split('\n')

            let Fullnamecut = ''
                , Positioncut = ''
                , Responsibilitycut = ''
                , Emailcut = ''
         
            Fullname.map((e) => {
                Fullnamecut += e
            })
            Position.map((e) => {
                Positioncut += e
            })

            Responsibility.map((e) => {
                Responsibilitycut += e
            })

            Email.map((e) => {
                Emailcut += e
            })
            let textcutFullname = doc.splitTextToSize(`${Fullnamecut || ''}`, CutPadding('w', 0, tableformation.col2 - (padding + 2))),
                textcutPosition = doc.splitTextToSize(`${Positioncut || ''}`, CutPadding('w', 0, tableformation.col3 - (padding + 2))),
                textcutResponsibility = doc.splitTextToSize(`${Responsibilitycut || ''}`, CutPadding('w', 0, tableformation.col4 - (padding + 2))),
                textcutEmail = doc.splitTextToSize(`${Emailcut || ''}`, CutPadding('w', 0, tableformation.col5 - (padding + 2)));

            let chceklength = textcutFullname.length
            if (chceklength < textcutPosition.length) {
                chceklength = textcutPosition.length
            }
            if (chceklength < textcutResponsibility.length) {
                chceklength = textcutResponsibility.length
            }
            if (chceklength < textcutEmail.length) {
                chceklength = textcutEmail.length
            }
            let numecut = 4

            for (let i = 0; i < chceklength; i += 1) {
                if (inpage + numecut > endpage) {
                    if (i > 0) {
    
                        Shot('r', Colposition('r', tablecustomer, 1), inpage, CutPadding('w', 0, tablecustomer[`col1`] - padding), numecut);
                        Shot('r', Colposition('r', tablecustomer, 2), inpage, CutPadding('w', 0, tablecustomer[`col2`] - padding), numecut);
                        Shot('r', Colposition('r', tablecustomer, 3), inpage, CutPadding('w', 0, tablecustomer[`col3`] - padding), numecut);
                        Shot('r', Colposition('r', tablecustomer, 4), inpage, CutPadding('w', 0, tablecustomer[`col4`] - padding), numecut);
                        Shot('r', Colposition('r', tablecustomer, 5), inpage, CutPadding('w', 0, tablecustomer[`col5`] - padding), numecut);
                        Shot('r', Colposition('r', tablecustomer, 6), inpage, CutPadding('w', 0, tablecustomer[`col6`] - padding), numecut);
                    
                    }
                    // Shot('t', Colposition('t', tableformation, 1, 'c', 1), inpage + numecut-5, TexttoString(ifm + 1), 'c');
                    // Shot('t', Colposition('t', tableformation, 6, 'c', 1), inpage + numecut-5, TexttoString(formation[ifm].pf_tel !== null ?formation[ifm].pf_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''), 'c');
                    Shot("add");
                    inpage = 36
                    numecut = 4
                    page += 1
                    ShotTable('ht', tableformation, inpage, tableformation.loop, 8, '', 'fd')
                    inpage += 8

                    Shot('t', Colposition('t', tableformation, 1, 'c', 1), inpage + numecut, TexttoString(ifm + 1), 'c');
                    Shot('t', Colposition('t', tableformation, 6, 'c', 1), inpage + numecut, TexttoString(formation[ifm].pf_tel !== null ?formation[ifm].pf_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''), 'c');
                    setpage()


                }

                if (i === 0) {
                    Shot('t', Colposition('t', tableformation, 1, 'c', 1), inpage + (numecut), TexttoString(ifm + 1), 'c');
                    Shot('t', Colposition('t', tableformation, 6, 'c', 1), inpage + (numecut), TexttoString(formation[ifm].pf_tel !== null ?formation[ifm].pf_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''), 'c');
        
                }
                if (i <= textcutFullname.length - 1) {
                    Shot('t', Colposition('t', tableformation, 2, 'l', 2), inpage + numecut, TexttoString(textcutFullname[i]), 'l');
                }
                if (i <= textcutPosition.length - 1) {
                    Shot('t', Colposition('t', tableformation, 3, 'l', 4), inpage + numecut, TexttoString(textcutPosition[i]), 'l');
                }
                if (i <= textcutResponsibility.length - 1) {
                    Shot('t', Colposition('t', tableformation, 4, 'l', 2), inpage + numecut, TexttoString(textcutResponsibility[i]), 'l');
                }
                if (i <= textcutEmail.length - 1) {
                    Shot('t', Colposition('t', tableformation, 5, 'l', 2), inpage + numecut, TexttoString(textcutEmail[i]), 'l');
                }
                numecut += 5
            }


        
            Shot('r', Colposition('r', tableformation, 1), inpage, CutPadding('w', 0, tableformation[`col1`] - padding), numecut);
            Shot('r', Colposition('r', tableformation, 2), inpage, CutPadding('w', 0, tableformation[`col2`] - padding), numecut);
            Shot('r', Colposition('r', tableformation, 3), inpage, CutPadding('w', 0, tableformation[`col3`] - padding), numecut);
            Shot('r', Colposition('r', tableformation, 4), inpage, CutPadding('w', 0, tableformation[`col4`] - padding), numecut);
            Shot('r', Colposition('r', tableformation, 5), inpage, CutPadding('w', 0, tableformation[`col5`] - padding), numecut);
            Shot('r', Colposition('r', tableformation, 6), inpage, CutPadding('w', 0, tableformation[`col6`] - padding), numecut);

            inpage += numecut
            console.log(textcutFullname, textcutPosition, textcutResponsibility, textcutEmail);
        }
    }
    /* Customer Contact Point------------------------------------------------------------------------------------------------------------------------------------------ */
    inpage += 12
    if (inpage + 28 > endpage) {
        Shot("add");
        inpage = 36
        page += 1
        setpage()
    }


    Shot('fs', 15)
    Shot('t', CutPadding('w', 0), inpage, TexttoString(`Customer Contact Point`), 'l')

    Shot('fs', 12)
    inpage += 2

    ShotTable('ht', tablecustomer, inpage, tablecustomer.loop, 8, '', 'fd')
    inpage += 8
    if (customer.length > 0) {
        for (let ifm = 0; ifm < customer.length; ifm++) {

            let Fullname = `${customer[ifm].pc_fullname || ''}`.split('\n')
                , Position = `${customer[ifm].pc_position || ''}`.split('\n')
                , Responsibility = `${customer[ifm].pc_short_responsibility || ''}`.split('\n')
                , Email = `${customer[ifm].pc_email || ''}`.split('\n')

            let Fullnamecut = ''
                , Positioncut = ''
                , Responsibilitycut = ''
                , Emailcut = ''
            console.log(Fullname, Position, Responsibility, Email);
            Fullname.map((e) => {
                Fullnamecut += e
            })
            Position.map((e) => {
                Positioncut += e
            })

            Responsibility.map((e) => {
                Responsibilitycut += e
            })

            Email.map((e) => {
                Emailcut += e
            })
            let textcutFullname = doc.splitTextToSize(`${Fullnamecut || ''}`, CutPadding('w', 0, tableformation.col2 - (padding + 2)))
                , textcutPosition = doc.splitTextToSize(`${Positioncut || ''}`, CutPadding('w', 0, tableformation.col3 - (padding + 2)))
                , textcutResponsibility = doc.splitTextToSize(`${Responsibilitycut || ''}`, CutPadding('w', 0, tableformation.col4 - (padding + 2)))
                , textcutEmail = doc.splitTextToSize(`${Emailcut || ''}`, CutPadding('w', 0, tableformation.col5 - (padding + 2)));

            let chceklength = textcutFullname.length
            if (chceklength < textcutPosition.length) {
                chceklength = textcutPosition.length
            }
            if (chceklength < textcutResponsibility.length) {
                chceklength = textcutResponsibility.length
            }
            if (chceklength < textcutEmail.length) {
                chceklength = textcutEmail.length
            }
            let numecut = 4

            for (let i = 0; i < chceklength; i += 1) {

                if (inpage + numecut > endpage) {
if (i > 0) {
    
    Shot('r', Colposition('r', tablecustomer, 1), inpage, CutPadding('w', 0, tablecustomer[`col1`] - padding), numecut);
    Shot('r', Colposition('r', tablecustomer, 2), inpage, CutPadding('w', 0, tablecustomer[`col2`] - padding), numecut);
    Shot('r', Colposition('r', tablecustomer, 3), inpage, CutPadding('w', 0, tablecustomer[`col3`] - padding), numecut);
    Shot('r', Colposition('r', tablecustomer, 4), inpage, CutPadding('w', 0, tablecustomer[`col4`] - padding), numecut);
    Shot('r', Colposition('r', tablecustomer, 5), inpage, CutPadding('w', 0, tablecustomer[`col5`] - padding), numecut);
    Shot('r', Colposition('r', tablecustomer, 6), inpage, CutPadding('w', 0, tablecustomer[`col6`] - padding), numecut);

}
                    Shot("add");
                    inpage = 36
                    numecut = 4
                    page += 1
                    ShotTable('ht', tablecustomer, inpage, tablecustomer.loop, 8, '', 'fd')
                    inpage += 8

                    Shot('t', Colposition('t', tablecustomer, 1, 'c', 1), inpage + numecut, TexttoString(ifm + 1), 'c');
                    Shot('t', Colposition('t', tablecustomer, 6, 'c', 1), inpage + numecut, TexttoString(customer[ifm].pc_tel !== null ?customer[ifm].pc_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''), 'c');
                    setpage()


                }
if (i === 0) {
    Shot('t', Colposition('t', tablecustomer, 1, 'c', 1), inpage + (numecut), TexttoString(ifm + 1), 'c');
    Shot('t', Colposition('t', tablecustomer, 6, 'c', 1), inpage + (numecut), TexttoString(customer[ifm].pc_tel !== null ?customer[ifm].pc_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''), 'c');

}
                if (i <= textcutFullname.length - 1) {
                    Shot('t', Colposition('t', tablecustomer, 2, 'l', 2), inpage + numecut, TexttoString(textcutFullname[i]), 'l');
                }
                if (i <= textcutPosition.length - 1) {
                    Shot('t', Colposition('t', tablecustomer, 3, 'l', 4), inpage + numecut, TexttoString(textcutPosition[i]), 'l');
                }
                if (i <= textcutResponsibility.length - 1) {
                    Shot('t', Colposition('t', tablecustomer, 4, 'l', 2), inpage + numecut, TexttoString(textcutResponsibility[i]), 'l');
                }
                if (i <= textcutEmail.length - 1) {
                    Shot('t', Colposition('t', tablecustomer, 5, 'l', 2), inpage + numecut, TexttoString(textcutEmail[i]), 'l');
                }
                numecut += 5
            }

          
            Shot('r', Colposition('r', tablecustomer, 1), inpage, CutPadding('w', 0, tablecustomer[`col1`] - padding), numecut);
            Shot('r', Colposition('r', tablecustomer, 2), inpage, CutPadding('w', 0, tablecustomer[`col2`] - padding), numecut);
            Shot('r', Colposition('r', tablecustomer, 3), inpage, CutPadding('w', 0, tablecustomer[`col3`] - padding), numecut);
            Shot('r', Colposition('r', tablecustomer, 4), inpage, CutPadding('w', 0, tablecustomer[`col4`] - padding), numecut);
            Shot('r', Colposition('r', tablecustomer, 5), inpage, CutPadding('w', 0, tablecustomer[`col5`] - padding), numecut);
            Shot('r', Colposition('r', tablecustomer, 6), inpage, CutPadding('w', 0, tablecustomer[`col6`] - padding), numecut);

            inpage += numecut
            console.log(textcutFullname, textcutPosition, textcutResponsibility, textcutEmail);
        }

    }


    Shot("add");
    inpage = 36
    page += 1


    setpage()

   /* Project Schedule------------------------------------------------------------------------------------------------------------------------------------------ */
    Shot('fs', 15)
    Shot('t', CutPadding('w', 0), inpage, TexttoString(`Project Schedule`), 'l')


    inpage += 2
 /*    r -= 70;
    g -= 70;
    b -= 70; */
    Shot('fs', 12)
  /*   Shot('tc', 255, 255, 255) */
    ShotTable('ht', tableschedule, inpage, tableschedule.loop, 8, '', 'fd')
    Shot('tc', 0, 0, 0)
    inpage += 8





    Shot('fs', 12)
    if (schedule.length > 0) {
        for (let is = 0; is < schedule.length; is += 1) {
            let textcuttaskname = doc.splitTextToSize(`${schedule[is].ps_task_name || ''}`, CutPadding('w', 0, tableschedule.col2 - (padding + 3)))
            let datecount = moment(schedule[is].ps_finish).diff(moment(schedule[is].ps_start), 'days')
            console.log('ss',datecount);
if (datecount === 'NaN' || isNaN(datecount) === true) {
    datecount = -1
}
console.log('ssx',datecount);
            let chceklength = textcuttaskname.length
            let numecut = 4
            for (let i = 0; i < chceklength; i += 1) {
                if (inpage + numecut > endpage) {
                    if (i > 0) {
                    Shot('r', Colposition('r', tableschedule, 1), inpage, CutPadding('w', 0, tableschedule[`col1`] - padding), numecut);
                    Shot('r', Colposition('r', tableschedule, 2), inpage, CutPadding('w', 0, tableschedule[`col2`] - padding), numecut);
                    Shot('r', Colposition('r', tableschedule, 3), inpage, CutPadding('w', 0, tableschedule[`col3`] - padding), numecut);
                    Shot('r', Colposition('r', tableschedule, 4), inpage, CutPadding('w', 0, tableschedule[`col4`] - padding), numecut);
                    Shot('r', Colposition('r', tableschedule, 5), inpage, CutPadding('w', 0, tableschedule[`col5`] - padding), numecut);
                    Shot('r', Colposition('r', tableschedule, 6), inpage, CutPadding('w', 0, tableschedule[`col6`] - padding), numecut);
                    }
                    Shot("add");
                    inpage = 36
                    numecut = 4
                    page += 1
                    ShotTable('ht', tableschedule, inpage, tableschedule.loop, 8, '', 'fd')
                    inpage += 8

                    Shot('t', Colposition('t', tableschedule, 1, 'c', 1), inpage + numecut, TexttoString(schedule[is].ps_task_no), 'c');
                    if (datecount > -1) {
                    Shot('t', Colposition('t', tableschedule, 3, 'r', 7), inpage + numecut, TexttoString(`${datecount + 1} days`), 'r');
                    }
                    setpage()


                }

                if (i <= textcuttaskname.length - 1) {
                    if (i === 0) {
                        Shot('t', Colposition('t', tableschedule, 1, 'c'), inpage + (numecut), TexttoString(schedule[is].ps_task_no), 'c');
                        if (datecount > -1) {
                            Shot('t', Colposition('t', tableschedule, 3, 'r', 15), inpage + (numecut), TexttoString(`${datecount + 1} days`), 'r');
                        }
                       
                     
                        if (schedule[is].ps_start) {
                            setDetethai('day', schedule[is].ps_start, 4, numecut, chceklength)
                        }
                        if (schedule[is].ps_start) {
                            setDetethai('day', schedule[is].ps_finish, 5, numecut, chceklength)
                        }
                        
                        Shot('t', Colposition('t', tableschedule, 6, 'c'), inpage + (numecut), TexttoString(schedule[is].ps_predecessors || ''), 'c');
                    }
                    Shot('t', Colposition('t', tableschedule, 2, 'l', 1), inpage + numecut, TexttoString(textcuttaskname[i]), 'l');
                }
                if (chceklength - 1 > i) {
                    numecut += 4
                } else {
                    numecut += 3
                }

            }
            Shot('r', Colposition('r', tableschedule, 1), inpage, CutPadding('w', 0, tableschedule[`col1`] - padding), numecut);
            Shot('r', Colposition('r', tableschedule, 2), inpage, CutPadding('w', 0, tableschedule[`col2`] - padding), numecut);
            Shot('r', Colposition('r', tableschedule, 3), inpage, CutPadding('w', 0, tableschedule[`col3`] - padding), numecut);
            Shot('r', Colposition('r', tableschedule, 4), inpage, CutPadding('w', 0, tableschedule[`col4`] - padding), numecut);
            Shot('r', Colposition('r', tableschedule, 5), inpage, CutPadding('w', 0, tableschedule[`col5`] - padding), numecut);
            Shot('r', Colposition('r', tableschedule, 6), inpage, CutPadding('w', 0, tableschedule[`col6`] - padding), numecut);
           
            inpage += numecut
        }
    }


    /*  Shot('save', `ProjectTimeline ${project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`); */
    
    if (mode === 'version') {
        const dataUri = doc.output('datauristring');
const binaryData = atob(dataUri.split(',')[1]);
const arrayBuffer = new ArrayBuffer(binaryData.length);
const uint8Array = new Uint8Array(arrayBuffer);
for (let i = 0; i < binaryData.length; i++) {
  uint8Array[i] = binaryData.charCodeAt(i);
}

const blob = new Blob([uint8Array], { type: 'application/pdf' });
const file = new File([blob], `ProjectTimeline ${project.project_name} ${moment(new Date()).add('year', 543).format('DD_MM_YY')}.pdf`, { type: 'application/pdf' });
// console.log(file);
axios({
    method: 'post',
    url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
    headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
    },
    data: {
        project_id: id,
       /*  path_file: response.data.data[0].path, */
        file_name: file.name,
        template_type: 'project_plan',
        dv_summary_of_change: summaryofchange || null

    }

})
    .then(function (response) {
        if (response.data) {


            CheckAndCreateFolderBox(response.data.data,response.data.olddata,file)




       /*      return true */
        }



    })
    .catch(function (error) {
        console.log(error);

    });
    // const dataImg = new FormData();
    // dataImg.append('Profile', file);
    // dataImg.append('typeFile', 'document_version');
    // dataImg.append('projectKey', "tttspaceship");
    // await axios({
    //   method: 'post',
    //   url: `${Configs.TTT_Game_Service}/api/upload/document2`,
    //   headers: {
    //     /*  Authorization: getToken(), */
    //     'X-TTT': `${Configs.API_TTT}`,
    //     'Content-Type': 'application/octet-stream',
    //   },
    //   data: dataImg,
    // })
    //   .then(async function (response) {
    //     if (response.data.status == true) {

    //       axios({
    //         method: 'post',
    //         url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
    //         headers: {
    //           Authorization: sessionStorage.getItem('access_token'),
    //           'X-TTT': `${Configs.API_TTT}`,
    //           'Content-Type': 'application/json',
    //         },
    //         data: {
    //             project_id:id,
    //             path_file:response.data.data[0].path,
    //             file_name:response.data.data[0].orgin_name,
    //             template_type:'project_plan',
    //             dv_summary_of_change: summaryofchange || null
                
    //         }
    
    //       })
    //         .then(function (response) {
    //           if (response.data) {
    //          return true
    //           }
    
    
    
    //         })
    //         .catch(function (error) {
    //           console.log(error);
            
    //         });

    //     }

    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
 







    } else {
        Shot('save', `ProjectTimeline ${project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`);
        return true
    }
    

/* const link = document.createElement('a');
link.href = URL.createObjectURL(blob);
link.download = `ProjectTimeline ${project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`;
link.click();
     console.log('his',URL.createObjectURL(blob)); */
/* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */
    function setpage() {
        
        Shot('i', imghead, 0, 0, wpage+1, 27)
        Shot('t', CutPadding('w', 0, 2), 283, TexttoString(`Create By : ${project.pp_created_name || ''}`), 'l')
        Shot('t', CutPadding('w', 0, 2), 288, TexttoString(`Update Date :  ${moment(project.pp_updated_date).add('year', 543).format('DD/MM/YYYY')}`), 'l')
        Shot('t', CutPadding('w', 100,), 283, TexttoString(`All Rights Reserved, Copyright ${/* project.federation_name */'TTT Brother Co., Ltd.'} | หน้า ${page}`), 'r')
        Shot('t', CutPadding('w', 100,), 288, TexttoString(`Template Version 1.0 - TTT Spaceship`), 'r')

    }
    function customSplit(input) {
        let topics = input.split('|');
    
    // นำข้อมูลแต่ละหัวข้อมาแยกเป็นคำย่อยๆ
    let result = topics.map(topic => {
        // แบ่งคำด้วยเว้นวรรค
        let words = topic.split(' ');
        // นำคำแต่ละคำมาเชื่อมกันด้วย |
        let subTopics = words.join(' | ');
        return subTopics;
    });

    // นำผลลัพธ์มาเชื่อมกันด้วยช่องว่าง
    let finalResult = result.join(' ');

    return finalResult;
    }
    function splitTextToSizeMultilingual(text, maxWidth) {
        const words = text.split(/(\s+)/); 
      
        let result = [];
        let line = '';
      
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
      
        
          let wordWidth = doc.getTextWidth(word);
          console.log(wordWidth,maxWidth,word);
      
          if (line === '') {
            line = word;
          } else {
            let currentLine = line + word;
            let width = doc.getTextWidth(currentLine);
           /*  console.log(width,maxWidth,words); */
      
            if (width <= maxWidth || wordWidth > maxWidth) {
              line = currentLine;
            } else {
              result.push(line);
              line = word;
            }
          }
        }
      
        if (line !== '') {
          result.push(line);
        }
      
        return result;
      }

    function setDetethai(mode, date, ps, cut, length) {
        let currentDate = new Date(date);
        let day = currentDate.getDate();
        let monthNamesShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        let monthIndex = currentDate.getMonth();
        let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2); // แปลงเป็น ปีไทยย่อ
        let dayNameShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'][currentDate.getDay()];

        let formattedDate = ''
        if (mode === 'day') {
            formattedDate = `${dayNameShort} ${day}/${monthNamesShort[monthIndex]}/${thaiYear}`;
        }

        Shot('t', Colposition('t', tableschedule, ps, 'r', 5), inpage + (cut), TexttoString(formattedDate), 'r');
    }



    async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {
            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }

        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {
                    if (c6 === 'fit') {
                        const imgar = c7.width / c7.height;
                        const reactar = c4 / c5;

                        let scale = 1;
                        if (imgar > reactar) {
                            scale = c4 / c7.width;
                        } else {
                            scale = c5 / c7.height;
                        }
                        const nw = c7.width * scale;
                        const nh = c7.height * scale;

                        const x = (c4 - nw) / 2;
                        const y = (c5 - nh) / 2;
                        return doc.addImage(c1, 'JPEG', c2 + x, c3 + y, nw, nh);
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }



    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((hpage - (padding * 2)) * percent) / 100) + padding;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {    
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color) {
        console.log(mode, head, pos, col, coll, loop, color);
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



}






export default ProjectTimelinereport;
