import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Getimgshow({img,style,isnew = false,callback}) {
  const [loading, setLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState(null);
  const imageUrl = img;
console.log(img);
  useEffect(() => {
    if (isnew !== true) {
     
      getimg()
    
    }
 
   /*  const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageSrc(imageUrl);
      setLoading(false);
    }; */
    
  }, [imageUrl]);
 async function getimg() {
  try {
    const response2 = await axios.get(`https://api.box.com/2.0/files/${imageUrl}/content`, {
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',
  })  ;
  /* console.log(response2.request.responseURL); */
  setImageSrc(response2.request.responseURL);
  callback(response2.request.responseURL)
  setLoading(false);
  }catch (error) {
    console.log(error.response.status);
    if (error.response.status === 429 || error.response.status === '429') {
      setTimeout(async () => {
        getimg()
      }, 5000);
    }
   
}
   
  }
  return (
   <>
   {isnew === true ? 
   <>
   <img src={imageUrl}  style={style}/>
   </>
:<>
{loading ? (
        <div class="animated-background" style={style}>
          <div class="background-masker btn-divide-left">

          </div>
      </div>
                  
      ) : (
        <img src={imageSrc}  style={style}/>
      )}
</>}
      
    </>
  );
}

export default Getimgshow;