

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
import docicon from '../../../assets/img/icon/doc icon.png';
import imageicon from '../../../assets/img/icon/image icon.png';
import pdficon from '../../../assets/img/icon/pdf icon.png';
import ppticon from '../../../assets/img/icon/ppt icon.png';
import xlsxicon from '../../../assets/img/icon/xlsx icon.png';
/* import QRCode from "qrcode"; */

async function changereport(data, id, mode, summaryofchange) {
    const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
        /*  const mmToPx = (mm) => (mm * 96) / 25.4; */
    
         return new Promise((resolve, reject) => {
             const img = new Image();
             img.crossOrigin = 'Anonymous'; 
             img.src = imageUrl;
             img.onload = () => {
                 const canvas = document.createElement('canvas');
                 const ctx = canvas.getContext('2d');
    
                 let wpx = img.width;
                 let hpx = img.height;
                 const mwpx = /* mmToPx(maxWidth) */maxWidth;
                 const mhpx = /* mmToPx(maxHeight) */maxHeight;
                /*  console.log('w',mwpx,wpx);
                 console.log('h',mhpx,hpx); */
    
     if (wpx > hpx) {
         if (wpx > mwpx) {
             hpx = Math.round((hpx * mwpx) / wpx);
             wpx = mwpx;
         }
     } else {
         if (hpx > mhpx) {
             wpx = Math.round((wpx * mhpx) / hpx);
             hpx = mhpx;
         }
     }
    
     canvas.width = wpx;
     canvas.height = hpx;
    
     ctx.drawImage(img, 0, 0, wpx, hpx);
    
     resolve(canvas.toDataURL('image/jpeg', 0.8)); 
    
                
             };
    
             img.onerror = () => {
                 reject(new Error('Failed to load image.'));
             };
         });
     };
let imghead = ''
    /*  console.log(imghead); */
    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    ///////////กำหนดตั้งค่าหน้าแนวตั้ง
    let padding = 12////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 297///สูง
        , page = 1
        , r = 194
        , g = 194
        , b = 194
        , endpage = 260,
        his = [],
        img = {}
    let heightpage = 27

    if (data.img.length > 0) {
        img = data.img[0]
    }

    let colordrop = [
        {
            before:'#676767',
            after:'#DEDEDE'
        },
        {
            before:'#FF00E5',
            after:'#FBDCFF'
        },
        {
            before:'#BA84FF',
            after:'#CCD9FA'
        },
        {
            before:'#00A5FF',
            after:'#CDEEFF'
        },
        {
            before:'#259B24',
            after:'#D3FFCF'
        },
        {
            before:'#FFEB3B',
            after:'#FFFDCF'
        },
        {
            before:'#FF8000',
            after:'#FFE0C1'
        },
        {
            before:'#FF0000',
            after:'#FFD9D9'
        },
     ]
    if (data.project.federation_color !== null) {
        let selectcolor = colordrop.findIndex((e) => e.before == data.project.federation_color || e.after == data.project.federation_color)
        console.log(selectcolor);
        if (selectcolor !== -1) {
            let hex = colordrop[selectcolor].after.replace(/^#/, '');
            let bigint = parseInt(hex, 16);
    
            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        }
    
    }
    console.log(r,g,b);
    let autosetdata = [],maxauto = 1
  let datalist = data.datachange
let datadynamic = datalist.cv_dynamic_value.data[0].dt_input_data.data[0].value_list


  
    let beforheightborder = 0,beforpadding = padding,beforheight=0,colortc=0
    Shot('fc', r, g, b);
    Shot('dc', r, g, b);

    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 15, 'fd')
    Shot('tc',colortc, colortc, colortc);
    Shot('fs', 24)
    Shot('t', CutPadding('w', 100,-2), heightpage + 5, TexttoString(`Change Request`), 'r')
    Shot('t', CutPadding('w', 100,-2), heightpage + 5, TexttoString(`Change Request`), 'r')
    Shot('fs', 16)
    Shot('t', CutPadding('w', 100,-2), heightpage + 9, TexttoString(`Change Date ${moment(new Date()).format('DD/MM/YY')}`), 'r')
    Shot('t', CutPadding('w', 100,-2), heightpage + 9, TexttoString(`Change Date ${moment(new Date()).format('DD/MM/YY')}`), 'r')
    heightpage+= 16
    beforheightborder = heightpage
    padding += 2 
  
    heightpage+= 3
    autosetbox(0,50,`Request No:`,datalist.cv_change_no)
    autosetbox(50,50,`Request Date:`,moment(datalist.cv_created_date).format('DD/MM/YY'))
    autosetbox(0,50,`Customer Name:`,datadynamic[0].value_input !== undefined ? datadynamic[0].value_input : '')
    autosetbox(50,50,`Project Name:`,data.project.project_name)
    autosetbox(0,100,`Module Name:`,datadynamic[1].value_input !== undefined ? datadynamic[1].value_input : '')
    autosetbox(0,100,`Synopsis:`,datadynamic[2].value_input !== undefined ? datadynamic[2].value_input : '')

    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('tc',colortc, colortc, colortc);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 20, -padding), 5*3+2, 'fd')
    Shot('fs', 18)
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 3)/2)+2, TexttoString('Request Phase:'), 'l')
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 3)/2)+2, TexttoString('Request Phase:'), 'l')
    Shot('r', CutPadding('w', 0+20), heightpage, CutPadding('w', 0-20, -padding), 5*3+2)

    Shot('fs', 16)
    Shot('tc',0, 0, 0);
    Shot('r', CutPadding('w', 20), heightpage, CutPadding('w', 26, -padding), 5*3+2)
    Shot('r', CutPadding('w', 46), heightpage, CutPadding('w', 28, -padding), 5*3+2)
    Shot('r', CutPadding('w', 74), heightpage, CutPadding('w', 26, -padding), 5*3+2)
    autosetcheckbox(datadynamic[3].table_column_list,heightpage)
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('tc',colortc, colortc, colortc);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 20, -padding), 5*3+2, 'fd')
    Shot('fs', 18)
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 3)/2)+2, TexttoString('Request Type:'), 'l')
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 3)/2)+2, TexttoString('Request Type:'), 'l')

    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 74), heightpage, CutPadding('w', 12, -padding), 5*3+2, 'fd')
    Shot('fs', 18)
    Shot('t', CutPadding('w', 74, 2), heightpage+((5* 3)/2)+2, TexttoString('Priority:'), 'l')
    Shot('t', CutPadding('w', 74, 2), heightpage+((5* 3)/2)+2, TexttoString('Priority:'), 'l')
  
    Shot('fs', 16)
    Shot('tc',0, 0, 0);
    Shot('r', CutPadding('w', 20), heightpage, CutPadding('w', 26, -padding), 5*3+2)
    Shot('r', CutPadding('w', 46), heightpage, CutPadding('w', 28, -padding), 5*3+2)
    
    Shot('r', CutPadding('w', 86), heightpage, CutPadding('w', 14, -padding), 5*3+2)
    autosetcheckbox(datadynamic[4].table_column_list,heightpage,datadynamic[5].table_column_list)


    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('tc',colortc, colortc, colortc);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
    Shot('fs', 18)
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Purpose:'), 'l')
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Purpose:'), 'l')
    Shot('fs', 16)
    Shot('tc',0, 0, 0);
    heightpage+=5*1+2
if (datadynamic[6].value_input !== undefined) {
    let cutText = doc.splitTextToSize(`${datadynamic[6].value_input}`, CutPadding('w', 100, -(padding + 4)));

    let count = 1
    for (let c of cutText) {
        if (endpage < heightpage+((5* count))+2 ) {
            heightpage += ((5* count))+2 
            Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), heightpage - beforheightborder-(page > 1 ? 0 : 3))
            padding = beforpadding
            Shot('lw',0.5);
            Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
            newpage(endpage)
            Shot('lw',0.2);
            heightpage+= 16
            beforheightborder = heightpage
            padding+=2
            count = 1

        }
        Shot('t', CutPadding('w', 0, 2), heightpage+((5* count)), TexttoString(c), 'l')
        count+=1
    }
    if ((count-1) < 4) {
        count = 4
    }
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*(count-1)+2)
   
    heightpage+=5*(count-1)+2
}else{
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*3+2)  
    heightpage+=5*3+2
}
    


Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Detail:'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Detail:'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=5*1+2
beforheight = heightpage
let manday = 0
if (data.docsrs.length > 0) {
    let coutdoc = 1
    for (let i of data.docsrs) {
        manday+=  parseFloat(i.sm_manday || 0)
        Shot('t', CutPadding('w', 0, 2), heightpage+((5* coutdoc)), TexttoString(`${i.module_name} > ${i.menu_name}`), 'l')
        coutdoc+=1
        heightpage+=2
        let cutText = doc.splitTextToSize(`${i.sm_comment}`, CutPadding('w', 100, -(padding + 4)));
        for (let c of cutText) {
            console.log( heightpage );
            if (endpage < heightpage+((5* coutdoc))+2 ) {
                heightpage += ((5* coutdoc))+2 
                Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3))
                padding = beforpadding
                Shot('lw',0.5);
                Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                newpage(endpage)
                Shot('lw',0.2);
                heightpage+= 16
                beforheightborder = heightpage
                padding+=2
                coutdoc = 1

            }
                Shot('t', CutPadding('w', 0, 2), heightpage+((5* coutdoc)), TexttoString(`${c}`), 'l')
               
                coutdoc+=1
           
        }
        if (cutText[cutText.length-1].trim() === '') {
            coutdoc-=1
        }
        if ((endpage- 20) < heightpage+((5* coutdoc))) {
            heightpage = endpage+1
            Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3))
            padding = beforpadding
            Shot('lw',0.5);
            Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
            newpage(endpage)
            Shot('lw',0.2);
            heightpage+= 16
            beforheightborder = heightpage
            padding+=2
            coutdoc = 1
        }
        heightpage +=2
        let h = 0
        if (i.have_file.length > 0) {
           /*  Shot('t', CutPadding('w', 0, 2), heightpage+((5* coutdoc)), TexttoString(`รูปภาพที่เกี่ยวข้อง`), 'l')
            coutdoc+=1
            for (let d of i.have_file.filter((e)=>{return e.ul_type === "IMAGE"})) {
                console.log(endpage ,heightpage+((5* coutdoc))+2+h+75);
                if (endpage < heightpage+((5* coutdoc))+2+h+75 ) {
                    heightpage += ((5* coutdoc))+2+h+75
                    Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3))
                    padding = beforpadding
                    Shot('lw',0.5);
                    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                    newpage(endpage)
                    Shot('lw',0.2);
                    heightpage+= 16
                    beforheightborder = heightpage
                    padding+=2
                    coutdoc = 1
                    h = 0
                }
                 Shot(
                    'i',
                    `${Configs.TTT_Game_Service_IMG}${d.ul_file_path}`,
                    CutPadding('w', 0, 2),
                    heightpage+((5* coutdoc)+h),
                     CutPadding('w', 100, -padding),
                    75,
                    'fit',
                    d
                );
           
                h+= 75
                coutdoc+=1
    
            }   
            heightpage +=(2+h) */
            if ((endpage- 20) < heightpage+((5* coutdoc))) {
                heightpage = endpage+1
                Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3))
                padding = beforpadding
                Shot('lw',0.5);
                Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                newpage(endpage)
                Shot('lw',0.2);
                heightpage+= 16
                beforheightborder = heightpage
                padding+=2
                coutdoc = 1
            }
            Shot('t', CutPadding('w', 0, 2), heightpage+((5* coutdoc)), TexttoString(`Download File`), 'l')
            coutdoc+=1
            for (let d of i.have_file/* .filter((e)=>{return e.ul_type !== "IMAGE"}) */) {
                let cuttype = d.ul_file_name.split('.')
                let icon = docicon
                if (cuttype[cuttype.length - 1] === 'pdf') {
                  icon = pdficon
                }
                if (cuttype[cuttype.length - 1] === 'doc') {
                  icon = docicon
                }
                if (cuttype[cuttype.length - 1] === 'xls' || cuttype[cuttype.length - 1] === 'xlsx') {
                  icon = xlsxicon
                }
                if (cuttype[cuttype.length - 1] === 'pptm') {
                  icon = ppticon
                }
                if (cuttype[cuttype.length - 1] === 'jpg' || cuttype[cuttype.length - 1] === 'jpeg' || cuttype[cuttype.length - 1] === 'png') {
                    icon = imageicon
                  }
                console.log(icon);
                Shot('fc', r, g, b);
                Shot('dc', r, g, b);
                Shot('r', CutPadding('w', 0,5),  heightpage+((5* coutdoc)), 5, 5,'fd')
                Shot(
                    'i',
                    `${icon}`,
                    CutPadding('w', 0,6),
                    heightpage+((5* coutdoc))+1,
                    3,
                    3,
                );
                doc.textWithLink(TexttoString(d.ul_file_name), CutPadding('w', 0, 13), heightpage+((5* coutdoc))+3.5, { url:  /* `${Configs.TTT_Game_Service_IMG}${d.ul_file_path}` */getboximg(d.ul_file_path) });
              /*   Shot('t', CutPadding('w', 0, 13), heightpage+((5* coutdoc))+3.5, TexttoString(d.ul_file_name), 'l') */
                coutdoc+=1
                heightpage +=2
            } 
            coutdoc+=1
        }
       
        if (i.sm_remark !== null) {
            cutText = doc.splitTextToSize(`รายละเอียด : ${i.sm_remark}`, CutPadding('w', 100, -(padding + 4)));
            for (let c of cutText) {
                console.log( heightpage );
                if (endpage < heightpage+((5* coutdoc))+2 ) {
                    heightpage += ((5* coutdoc))+2 
                    Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3))
                    padding = beforpadding
                    Shot('lw',0.5);
                    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                    newpage(endpage)
                    Shot('lw',0.2);
                    heightpage+= 16
                    beforheightborder = heightpage
                    padding+=2
                    coutdoc = 1
    
                }
                    Shot('t', CutPadding('w', 0, 2), heightpage+((5* coutdoc)), TexttoString(`${c}`), 'l')
                   
                    coutdoc+=1
               
            }
        }
        

        heightpage +=((5* coutdoc))
        coutdoc = 1
    }
}else{
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*3+2)  
    heightpage+=5*3+2
}
autosetbox(0,100,`Expected Date:`,datadynamic[7].value_input !== undefined ? datadynamic[7].value_input : '')
Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), heightpage - beforheightborder-(page > 1 ? 0 : 3))

heightpage+=5*2+2

if ((endpage-20) < heightpage ) {
    heightpage = endpage+1
    /* Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}


Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('CIs Impact Analysis: '), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('CIs Impact Analysis: '), 'l')
Shot('fs', 16)
heightpage+=5*1+2
if ((endpage-20) < heightpage ) {
    heightpage = endpage+1
    /* Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
let tableaffect = {
    col1: CutPadding('w', 9, -padding),
    col2: CutPadding('w', 91, -padding),
    name1: 'No.',
    name2: 'Configuration Items',
    loop: 2
}
ShotTable('ht', tableaffect, heightpage, tableaffect.loop, 7, '', 'fd')
if ((endpage-20) < heightpage ) {
    heightpage = endpage+1
    /* Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=7
let index = 0
if (datalist.cv_affect_value.data.length > 0) {
    let dataaffect = datalist.cv_affect_value.data
   
    for (let i of dataaffect) {
        if ((endpage-10) < heightpage) {
            heightpage = endpage+1
           /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
            padding = beforpadding
            Shot('lw',0.5);
            Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
            newpage(endpage)
            Shot('lw',0.2);
            heightpage+= 16
            beforheightborder = heightpage
            padding+=2
         
        }
        Shot('t', Colposition('t', tableaffect, 1, 'c'), heightpage +5*(1), TexttoString(`${index + 1}.`), 'c')
        Shot('t', Colposition('t', tableaffect, 2, 'l'), heightpage +5*(1), TexttoString(`${i.module_name} > ${i.menu_name} > ${i.process_name}`), 'l')
      /*   Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), ((countrow + 1) * 6) + 2);

        Shot('t', Colposition('t', jsonunit2, 1, 'c'), heightpage, TexttoString(`${subsortelement + 1}.${countel + 1}`), 'c')
 */
        Shot('r', Colposition('r', tableaffect, 1), heightpage, CutPadding('w', 0, tableaffect.col1 - padding), 5*(1)+2);
        Shot('r', Colposition('r', tableaffect, 2), heightpage, CutPadding('w', 0, tableaffect.col2 - padding), 5*(1)+2);
        heightpage+= 5*(1)+2
      
        index+=1
    }
    if (dataaffect.length < 5) {
        for (let i = dataaffect.length; i < 5; i++) {
            if ((endpage-10) < heightpage) {
                heightpage = endpage+1
                /* Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
                padding = beforpadding
                Shot('lw',0.5);
                Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                newpage(endpage)
                Shot('lw',0.2);
                heightpage+= 16
                beforheightborder = heightpage
                padding+=2
             
            }
            Shot('t', Colposition('t', tableaffect, 1, 'c'), heightpage +5*(1), TexttoString(`${index + 1}.`), 'c')
            Shot('r', Colposition('r', tableaffect, 1), heightpage, CutPadding('w', 0, tableaffect.col1 - padding), 5*(1)+2);
        Shot('r', Colposition('r', tableaffect, 2), heightpage, CutPadding('w', 0, tableaffect.col2 - padding), 5*(1)+2);
        heightpage+= 5*(1)+2
        index+=1
        }
    }

}else{
   
        for (let i = 0; i < 5; i++) {
            if ((endpage-10) < heightpage) {
                heightpage = endpage+1
/*                 Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
                padding = beforpadding
                Shot('lw',0.5);
                Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
                newpage(endpage)
                Shot('lw',0.2);
                heightpage+= 16
                beforheightborder = heightpage
                padding+=2
           
            }
            Shot('t', Colposition('t', tableaffect, 1, 'c'), heightpage +5*(1), TexttoString(`${index + 1}.`), 'c')
            Shot('r', Colposition('r', tableaffect, 1), heightpage, CutPadding('w', 0, tableaffect.col1 - padding), 5*(1)+2);
        Shot('r', Colposition('r', tableaffect, 2), heightpage, CutPadding('w', 0, tableaffect.col2 - padding), 5*(1)+2);
        heightpage+= 5*(1)+2
        index+=1
        }
    
}
heightpage+= 5*(1)+2
if ((endpage) < heightpage + 5*(6)+2) {
    heightpage = endpage+1
    /* Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 50, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Phase Impact Analysis: '), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Phase Impact Analysis: '), 'l')
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 50), heightpage, CutPadding('w', 50, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 50, 2), heightpage+((5* 1)/2)+2, TexttoString('Remark: '), 'l')
Shot('t', CutPadding('w', 50, 2), heightpage+((5* 1)/2)+2, TexttoString('Remark: '), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+= 5*(1)+2
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 27, -padding), 5*4+2)
Shot('r', CutPadding('w', 27), heightpage, CutPadding('w', 23, -padding), 5*4+2)
Shot('r', CutPadding('w', 50), heightpage, CutPadding('w', 50, -padding), 5*4+2)
autosetcheckbox(datadynamic[8].table_column_list,heightpage,[],[27],4,0)
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Operative Impact Detail:'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Operative Impact Detail:'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=5*1+2
if (datadynamic[6].value_input !== undefined) {
let cutText = doc.splitTextToSize(`${datadynamic[9].value_input}`, CutPadding('w', 100, -(padding + 4)));

let count = 1
for (let c of cutText) {
    if (endpage < heightpage+((5* count))+2 ) {
        heightpage += ((5* count))+2 
        Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), heightpage - beforheightborder-(page > 1 ? 0 : 3))
        padding = beforpadding
        Shot('lw',0.5);
        Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
        newpage(endpage)
        Shot('lw',0.2);
        heightpage+= 16
        beforheightborder = heightpage
        padding+=2
        count = 1

    }
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* count)), TexttoString(c), 'l')
    count+=1
}
if ((count-1) < 4) {
    count = 4
}
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*(count-1)+2)

heightpage+=5*(count-1)+2
}else{
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*3+2)  
heightpage+=5*3+2
}

heightpage+= 5*(1)+2

if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Development Outline:'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Development Outline:'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=5*1+2
if (datadynamic[10].value_input !== undefined) {
let cutText = doc.splitTextToSize(`${datadynamic[9].value_input}`, CutPadding('w', 100, -(padding + 4)));

let count = 1
for (let c of cutText) {
    if (endpage < heightpage+((5* count))+2 ) {
        heightpage += ((5* count))+2 
/*         Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), heightpage - beforheightborder-(page > 1 ? 0 : 3)) */
        padding = beforpadding
        Shot('lw',0.5);
        Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
        newpage(endpage)
        Shot('lw',0.2);
        heightpage+= 16
        beforheightborder = heightpage
        padding+=2
        count = 1

    }
    Shot('t', CutPadding('w', 0, 2), heightpage+((5* count)), TexttoString(c), 'l')
    count+=1
}
if ((count-1) < 4) {
    count = 4
}
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*(count-1)+2)

heightpage+=5*(count-1)+2
}else{
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*3+2)  
heightpage+=5*3+2
}

heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString(`Effort Impact Estimation (Man Days): ${isNaN(manday) === false ? manday : 0}`), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString(`Effort Impact Estimation (Man Days): ${isNaN(manday) === false ? manday : 0}`), 'l')
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
let tableEffort = {
    col1: CutPadding('w', 100/3, -padding),
    col2: CutPadding('w', 100/3, -padding),
    col3: CutPadding('w', 100/3, -padding),
    name1: 'PM',
    name2: 'SA',
    name3: 'DEV',
    loop: 3
}
ShotTable('ht', tableEffort, heightpage, tableEffort.loop, 7, '', 'fd')
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('r', Colposition('r', tableEffort, 1), heightpage, CutPadding('w', 0, tableEffort.col1 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableEffort, 2), heightpage, CutPadding('w', 0, tableEffort.col2 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableEffort, 3), heightpage, CutPadding('w', 0, tableEffort.col3 - padding), 5*(1)+2);
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
tableEffort = {
    col1: CutPadding('w', 100/3, -padding),
    col2: CutPadding('w', 100/3, -padding),
    col3: CutPadding('w', 100/3, -padding),
    name1: 'DEV',
    name2: '',
    name3: '',
    loop: 3
}
ShotTable('ht', tableEffort, heightpage, tableEffort.loop, 7, '', 'fd')
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('tc',0, 0, 0);
Shot('r', Colposition('r', tableEffort, 1), heightpage, CutPadding('w', 0, tableEffort.col1 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableEffort, 2), heightpage, CutPadding('w', 0, tableEffort.col2 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableEffort, 3), heightpage, CutPadding('w', 0, tableEffort.col3 - padding), 5*(1)+2);
heightpage+= 5*(2)+2
if ((endpage- 20) < heightpage +5*(3)) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Schedule Impact Estimation:'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Schedule Impact Estimation:'), 'l')
heightpage+= 5*(1)+2
let tableSchedule = {
    col1: CutPadding('w', 100/3, -padding),
    col2: CutPadding('w', 100/3, -padding),
    col3: CutPadding('w', 100/3, -padding),
    name1: 'Start',
    name2: 'Test',
    name3: 'Finish',
    loop: 3
}
ShotTable('ht', tableSchedule, heightpage, tableSchedule.loop, 7, '', 'fd')
heightpage+= 5*(1)+2
Shot('r', Colposition('r', tableSchedule, 1), heightpage, CutPadding('w', 0, tableSchedule.col1 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableSchedule, 2), heightpage, CutPadding('w', 0, tableSchedule.col2 - padding), 5*(1)+2);
Shot('r', Colposition('r', tableSchedule, 3), heightpage, CutPadding('w', 0, tableSchedule.col3 - padding), 5*(1)+2);
heightpage+= 5*(1)+2
if ((endpage- 20) < heightpage) {
    heightpage = endpage+1
   /*  Shot('r', CutPadding('w', 0), beforheightborder+(page > 1 ? 0 : 3), CutPadding('w', 100, -padding), 260 - beforheightborder-(page > 1 ? 0 : 3)) */
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), 260 - beforheightborder)
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16
    beforheightborder = heightpage
    padding+=2
}
Shot('tc',0, 0, 0);
Shot('fs', 16)
Shot('r', CutPadding('w', 0,2), heightpage+((5* 1))-2, 3, 3);
Shot('t', CutPadding('w', 0, 8), heightpage+((5* 1)+1), TexttoString('มีผลทำให้ Project Schedule เกิดการล่าช้ากว่าที่กำหนดไว้ในการเริ่มงาน ส่วน/เฟส ถัดไป'), 'l')
heightpage+= 5*(2)
let endh = 20
padding = beforpadding
Shot('lw',0.5);
Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
heightpage = endpage+1
newpage(endpage)
Shot('lw',0.2);
heightpage+= 16+endh
beforheightborder = heightpage
padding+=2
/* if ((endpage- 20) < heightpage+endh) {
    
   
    padding = beforpadding
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
    heightpage = endpage+1
    newpage(endpage)
    Shot('lw',0.2);
    heightpage+= 16+endh
    beforheightborder = heightpage
    padding+=2
}else{
    Shot('lw',0.5);
    Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
    heightpage+= endh
} */
padding = beforpadding
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Status of Approve Change Request…'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Status of Approve Change Request…'), 'l')
padding+=2
heightpage+= 5*(2)
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 5, -padding), 5*2+2)  
Shot('r', CutPadding('w', 0,3), heightpage+4,3, 3)  
Shot('r', CutPadding('w', 5), heightpage, CutPadding('w', 12, -padding), 5*2+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 5, 2), heightpage+((5* 2)/2)+2, TexttoString('Approve'), 'l')
Shot('t', CutPadding('w', 5, 2), heightpage+((5* 2)/2)+2, TexttoString('Approve'), 'l')

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('r', CutPadding('w', 17), heightpage, CutPadding('w', 5, -padding), 5*1+1)  
Shot('r', CutPadding('w', 17,3), heightpage+1.5,3, 3)  
Shot('r', CutPadding('w', 22), heightpage, CutPadding('w', 18, -padding), 5*1+1, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 1)/2)+2, TexttoString('With Charge'), 'l')
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 1)/2)+2, TexttoString('With Charge'), 'l')

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('r', CutPadding('w', 17), heightpage+(5*1+1), CutPadding('w', 5, -padding), 5*1+1)  
Shot('r', CutPadding('w', 17,3), heightpage+1.5+(5*1+1),3, 3)  
Shot('r', CutPadding('w', 22), heightpage+(5*1+1), CutPadding('w', 18, -padding), 5*1+1, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 2)+0.5), TexttoString('No-Charge'), 'l')
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 2)+0.5), TexttoString('No-Charge'), 'l')
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('r', CutPadding('w', 40), heightpage, CutPadding('w', 5, -padding), 5*2+2)  
Shot('r', CutPadding('w', 40,3), heightpage+4,3, 3)  
Shot('r', CutPadding('w', 45), heightpage, CutPadding('w', 12, -padding), 5*2+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 45, 2), heightpage+((5* 2)/2)+2, TexttoString('Deny'), 'l')
Shot('t', CutPadding('w', 45, 2), heightpage+((5* 2)/2)+2, TexttoString('Deny'), 'l')
heightpage+= 5*(3)+2

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Company Approve'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Company Approve'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=5*1+2
autosetbox(0,50,`Create by (SA):`,'',16)
autosetbox(50,50,`Approved by (PM):`,'',16)
autosetbox(0,50,`Sign:`,'',16)
autosetbox(50,50,`Sign:`,'',16)
autosetbox(0,50,`Date:`,'',16)
autosetbox(50,50,`Date:`,'',16)
heightpage+=5*1+2

padding = beforpadding
Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Status of Approve Impact Change Request from Customer/CIO/SE'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Status of Approve Impact Change Request from Customer/CIO/SE'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+= 5*(2)
padding+= 2

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 5, -padding), 5*2+2)  
Shot('r', CutPadding('w', 0,3), heightpage+4,3, 3)  
Shot('r', CutPadding('w', 5), heightpage, CutPadding('w', 12, -padding), 5*2+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 5, 2), heightpage+((5* 2)/2)+2, TexttoString('Approve'), 'l')
Shot('t', CutPadding('w', 5, 2), heightpage+((5* 2)/2)+2, TexttoString('Approve'), 'l')

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 17), heightpage, CutPadding('w', 5, -padding), 5*2+2)  
Shot('r', CutPadding('w', 17,3), heightpage+4,3, 3)  
Shot('r', CutPadding('w', 22), heightpage, CutPadding('w', 29, -padding), 5*2+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 2)/2)+2, TexttoString('Cancel (Change Request)'), 'l')
Shot('t', CutPadding('w', 22, 2), heightpage+((5* 2)/2)+2, TexttoString('Cancel (Change Request)'), 'l')
heightpage+= 5*(3)+2

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 50, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Customer'), 'l')
Shot('t', CutPadding('w', 0, 2), heightpage+((5* 1)/2)+2, TexttoString('Customer'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);

Shot('fc', r, g, b);
Shot('dc', 0, 0, 0);
Shot('tc',colortc, colortc, colortc);
Shot('r', CutPadding('w', 50), heightpage, CutPadding('w', 50, -padding), 5*1+2, 'fd')
Shot('fs', 18)
Shot('t', CutPadding('w', 50, 2), heightpage+((5* 1)/2)+2, TexttoString('TD Approve'), 'l')
Shot('t', CutPadding('w', 50, 2), heightpage+((5* 1)/2)+2, TexttoString('TD Approve'), 'l')
Shot('fs', 16)
Shot('tc',0, 0, 0);
heightpage+=5*1+2
autosetbox(0,50,`Approve by:`,'',16)
autosetbox(50,50,`Approved by :`,'',16)
autosetbox(0,50,`Sign:`,'',16)
autosetbox(50,50,`Sign:`,'',16)
autosetbox(0,50,`Date:`,'',16)
autosetbox(50,50,`Date:`,'',16)
heightpage+=5*1+2





padding = beforpadding
Shot('lw',0.5);
Shot('r', CutPadding('w', 0), beforheightborder, CutPadding('w', 100, -padding), heightpage - beforheightborder)
Shot('tc',0, 0, 0);

const mmToPx = (mm) => (mm * 96) / 25.4;
    if (data.img.length > 0) {

        img = await getboximg(data.img[0].fd_img_id)
        imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) */img
    }else{
        imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg

    }

    for (let p = 1; p <= page; p += 1) {
        console.log(page,p);
            doc.setPage(p)
                sethead()
                setfooter(p, page)
        

    }


    Shot('save', `Change Request ${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`);
    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 11

        }
    }

    async function getboximg(imageid,check = null) {
        try{
        const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
       /*  if (check !== null) {
            listdatabox.push({
                id:imageid,
                path:URL.createObjectURL(response2.data)
            })
        } */
     return URL.createObjectURL(response2.data);
    }catch (error) {
        return null 
    }
    }


  
    function autosetbox(s,w,h,t,font =18) {
      let cutText = doc.splitTextToSize(`${t}`, CutPadding('w', w-20, -(padding + 4)));
                    console.log(cutText);
                    Shot('tc',0, 0, 0);
                    Shot('fs', 16)
                    let count = 1
                    for (let c of cutText) {
                        Shot('t', CutPadding('w', s+20, 2), heightpage+((5* count)), TexttoString(c), 'l')
                        count+=1
                    }
  
 
    
                    if (maxauto < count-1) {
                        maxauto = count-1
                    }

    autosetdata.push({
        s:s,
        w:w,
        h:h,
        t:t,
    }

    )
if (s+w === 100) {
  
    for (let i of autosetdata) {
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('tc',colortc, colortc, colortc);
    Shot('r', CutPadding('w', i.s), heightpage, CutPadding('w', 20, -padding), 5*maxauto+2, 'fd')
    Shot('fs', font)
    Shot('t', CutPadding('w', i.s, 2), heightpage+((5* maxauto)/2)+2, TexttoString(i.h), 'l')
    Shot('t', CutPadding('w', i.s, 2), heightpage+((5* maxauto)/2)+2, TexttoString(i.h), 'l')
    Shot('r', CutPadding('w', i.s+20), heightpage, CutPadding('w', i.w-20, -padding), 5*maxauto+2)
        
    }
    autosetdata = []
    heightpage += (5*maxauto)+2
    maxauto = 1
 
}
    }
    function autosetcheckbox(arr,h,arr2 = [],seting=[26,28],row=3,w = 20) {
        let count = 1,pos=seting,l=0,check =false
       for (let i of arr) {
        if (i.value_check_box !== undefined && i.value_check_box === true) {
            check = true
        }
            drawCheckbox(CutPadding('w', w,3),h+((5* count)-3),3,check)

        Shot('t', CutPadding('w', w, 8), h+((5* count)), TexttoString(i.header), 'l')
        count+=1
        check = false
        if (count === row+1) {
            count = 1
            w+=pos[l]
            l+=1
           }
       }
       if (arr2.length > 0) {
        count = 1
        w =  86
        for (let i of arr2) {
            if (i.value_check_box !== undefined && i.value_check_box === true) {
                check = true
            }
                drawCheckbox(CutPadding('w', w,3),h+((5* count)-3),3,check)
    
            Shot('t', CutPadding('w', w, 8), h+((5* count)), TexttoString(i.header), 'l')
            count+=1
            check = false
           }
       }
      
       heightpage+=5*row+2
      }
      function drawCheckbox(x, y, size, ischeck) {
        Shot('r',x, y, size, size);
        if (ischeck === true) {
            Shot('lw',0.3);
            Shot('l',x + 0.5, y + size / 2, x + size / 3, y + size - 0.5); 
            Shot('l',x + size / 3, y + size - 0.5, x + size - 0.5, y + 0.5);
           Shot('lw',0.2);
        }
    }

    function sethead() {
        Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)


    }
    function setfooter(on, max) {

        Shot('lw', 0.3)
        Shot('fs', 16)
        Shot('t', CutPadding('w', 50, 2), 273 + 2, TexttoString(`Page ${on} of ${max}`), 'c')
        Shot('l',CutPadding('w', 0, 2), 273 + 4, CutPadding('w', 100, 2), 273 + 4);
        Shot('t', CutPadding('w', 0, 2), 273 + 8, TexttoString(`Template v2.0 Copyright © TTT Brother CO., LTD. `), 'l')
      /*   Shot('t', CutPadding('w', 95, 2), 273 + 8, TexttoString(`V${parseFloat(data.version.version) + 1}.0`), 'r') */

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */


    function setDetethai(mode, date, ps, cut, length) {
        let currentDate = new Date(date);
        let day = currentDate.getDate();
        let monthNamesShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        let monthIndex = currentDate.getMonth();
        let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2); // แปลงเป็น ปีไทยย่อ
        let dayNameShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'][currentDate.getDay()];

        let formattedDate = ''
        if (mode === 'day') {
            formattedDate = `${dayNameShort} ${day}/${monthNamesShort[monthIndex]}/${thaiYear}`;
        }

        /*  Shot('t', Colposition('t', tableschedule, ps, 'r', 15), inpage + (cut - (length * 5) + 2), TexttoString(formattedDate), 'r'); */
    }



    function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {
            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {
                    if (c6 === 'fit') {
                        let Width = c7.width === undefined ? 1920 : c7.width;
                         let Height = c7.height === undefined ? 1080 : c7.height; 
                 
                        const imgar = Width / Height;
                        const reactar = c4 / c5;

                        let scale = 1;
                        if (imgar > reactar) {
                            scale = c4 / Width;
                        } else {
                            scale = c5 / Height;
                        }
                        const nw = Width * scale;
                        const nh = Height * scale;

                        const x = (c4 - nw) / 2;
                        const y = (c5 - nh) / 2;

                        return doc.addImage(c1, 'JPEG', c2 + x, c3 + y, nw, nh, '', 'FAST')
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

        if (mode === 'l') {
            return doc.line(c1, c2, c3, c4); 
        }
    }



    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig, link = [], header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {
                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            let chackrow = link.filter((e) => { return e.n === cx })
                                            console.log(chackrow, cx, 'in');
                                            if (chackrow.length > 0) {
                                                /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                                               */
                                                let y = higbefor + (fakecolsum), x = Colposition('t', head, t2, 'l', 3)
                                                let width = doc.getTextWidth(chackrow[0].n);
                                                let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                                                y += height * 0.2;
                                                doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                                            } /* else { */
                                            Shot('t', Colposition('t', head, t2, t2 === 1 ? 'c' : 'l', 3), higbefor + (fakecolsum), TexttoString(cx), t2 === 1 ? 'c' : 'l')
                                            /* } */
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                            higbefor += 7


                        }


                        let chackrow = link.filter((e) => { return e.n === c })
                        if (chackrow.length > 0) {
                            /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                           */
                            let y = higbefor + (collengthsum), x = Colposition('t', head, t, 'l', 3)
                            let width = doc.getTextWidth(chackrow[0].n);
                            let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                            y += height * .2;
                            doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                        }
                        Shot('t', Colposition('t', head, t, t === 1 ? 'c' : 'l', 3), higbefor + (collengthsum), TexttoString(c), t === 1 ? 'c' : 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



}






export default changereport;
