import React from 'react';
// import HeaderTwo from '../../../components/header/HeaderTwo';
 import Footer from '../../components/footer/FooterTTT';
 import Breadcrumb from './BreadcrumbTTT';
// import AboutInner from './AboutInner'
// import LatestMatch from './LatestMatch'
import ChatAdmin from './admin-chat';
import TeamMem from './TeamMemTTT'
import AboutPage from './about-page';
import HeaderTTT from '../../components/header/HeaderTTT';
import ReleaseGame from './releaseGame';
import Contact from './contact';


function IndexAbout() {
  return (
	<>
	<HeaderTTT/>
	<main>
	<Breadcrumb/>
    <AboutPage/>
	<ReleaseGame/>
    <ChatAdmin/>
	{/* <AboutInner/> */}
	{/* <LatestMatch/> */}
	<TeamMem/> 
	<Contact/>
	</main>
	<Footer/>
	</>
  )
}

export default IndexAbout
