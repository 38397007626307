import React, { useEffect, useState, useRef, useMemo  } from 'react';
import Configs from "../../config";
import axios from 'axios';
import './risk.css'
import iconback_doorStage from "../../assets/img/icon/IconBack_stage.png";
import iconPlus from "../../assets/img/icon/plus-radius.png";
import iconSaveRisk from "../../assets/img/icon/save-risk_management.png";
import iconVectorRisk from "../../assets/img/icon/Vector-Risk.png";
import iconBin from "../../assets/img/icon/icon-Bin.png";
import { Menu,Dropdown, ConfigProvider, DatePicker, Select, Space,Tooltip} from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { SearchOutlined, DownOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; 
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { label } from 'yet-another-react-lightbox';
import { v4 as uuidv4 } from 'uuid';
import ModelAlertConfrim from "../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../components/AlertMassge/AlertMassge";

function RiskManagement({project_id}) {

    const StyledDatePicker = styled(DatePicker)`
    .ant-picker-clear {
        padding-Right: 10px; 
    }
    `;
    ;
    const location = useLocation();
    // const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const getProjectId = queryParams.get('id');
    // const clearQueryParams = () => {
    //   history.push(location.pathname);
    // };


    const [test, setTest] = useState({ test: '' });
    const [RiskManagementList, setRiskManagementList] = useState([]);
    const [DelRiskManagementList, setDelRiskManagementList] = useState([]);
    const [optionRiskCategoty, setoptionRiskCategoty] = useState([]);
    const [optionRiskManagementStatus1, setRiskManagementStatus1] = useState([]);
    const [optionRiskManagementStatus2, setRiskManagementStatus2] = useState([]);
    const [idSpaceshipAndFederation, setidSpaceshipAndFederation] = useState({
        spaceship_id:"",
        federation_id:"",
        project_name:""
    });
    const [CountRiskManagement, setCountRiskManagement] = useState({
        AllCountRiskManagement:"",
        CloseCountRiskManagement:"",
        UnCloseCountRiskManagement:"",
        LastUpdateRiskManagement:""
    });
    const [selectedValue, setSelectedValue] = useState(null);
    const [loading, setLoading] = useState(false);
   
    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);
    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [displaySwal, setDisplaySwal] = useState(false);
    const toggle_modal_confrimModal = () => setDisplaySwal(!displaySwal);

    const handleMenuClick = (e) => {
        const selectedLabel = items.find(item => item.key === e.key)?.label;
        setSelectedValue(selectedLabel);
        filterRiskManagement(selectedLabel); 
        // console.log('Selected value:', selectedLabel);
      };

    
    
      // รายการของเมนู
      const items = [
        {
          label: 'All',
          key: '1',
        },
        {
          label: 'Weekly',
          key: '2',
        },
        {
          label: 'Monthly',
          key: '3',
        },
      ];
    
      const menu = (
        <Menu onClick={handleMenuClick}>
          {items.map(item => (
            <Menu.Item key={item.key}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      );

    // console.log("optionRiskManagementStatus1: ",optionRiskManagementStatus1);
    const getRiskManagementName = (rmr_impact, rmr_probability, rmr_id) => {
        let numRisk = rmr_impact * rmr_probability;
        let temp_rms_name = "";
        let temp_rms_id = "";
    
        for (let item of optionRiskManagementStatus1) {
            if (numRisk <= 8 ) {
                if(item.rms_id === '77e8ab34-304c-42c0-93ba-d00fdc68c55f'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            } else if(numRisk >= 9 && numRisk <= 15) {
                if(item.rms_id === '04bd9e8e-8146-4bdb-9344-18f1ce822a2a'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            } else if(numRisk >= 16 && numRisk <= 25) {
                if(item.rms_id === '45c5e389-dae9-4e43-9fd8-5865f6f92628'){
                    temp_rms_name = item.rms_name;
                    temp_rms_id = item.rms_id;
                    break;
                }
            }
        }
        
        return temp_rms_name;
    };

    const getRiskClassCardMain = (numRisk) => {
        if (numRisk <= 8) return 'containerCardRiskManagementRisk-low';
        if (numRisk >= 9 && numRisk <= 15) return 'containerCardRiskManagementRisk-medium';
        if (numRisk >= 16 && numRisk <= 25) return 'containerCardRiskManagementRisk-high';
    };
    
    const getRiskClassName = (numRisk) => {
        if (numRisk <= 8) return 'risk-low';
        if (numRisk >= 9 && numRisk <= 15) return 'risk-medium';
        if (numRisk >= 16 && numRisk <= 25) return 'risk-high';
    };
    
    
    const optionProbability = [
        {
            value:"1",
            label:"1"
        },
        {
            value:"2",
            label:"2"
        },
        {
            value:"3",
            label:"3"
        },
        {
            value:"4",
            label:"4"
        },
        {
            value:"5",
            label:"5"
        }
    ]
      useEffect(() => {
        getIdSpaceshipAndFederation();
        filterRiskManagement();
        selesctRiskCategoty();
        selesctRiskManagementStatus();
      
      }, []);

    //   useEffect(() => {
    //     const updatedList = RiskManagementList.map((item) => {
    //         const { riskColorBackground, riskColorBorder, rms_id } = getRiskManagementDetails(item.rmr_impact, item.rmr_probability);
    
    //         return {
    //             ...item,
    //             riskColorBackground,
    //             riskColorBorder,
    //             rms_id, // เพิ่ม `rms_id` ที่คำนวณได้ลงในรายการ
    //         };
    //     });
    
    //     setRiskManagementList(updatedList);
    // }, [RiskManagementList]);


      const onClickNewRisk = () => {
        
        let temp_data =   {
            rmr_id: uuidv4(),
            rmr_rc_id: '',
            rmr_descriptions: '',
            rmr_probability: '',
            rmr_impact: '',
            rmr_exposure: '',
            rmr_rms_id_1: '',
            rmr_mitigation_plan: '',
            rmr_contingency_plan: '',
            rmr_target_date: null,
            rmr_rms_id_2: '81ba9127-d22a-4bc5-9f77-f51ca1ede2ff',
            rmr_closed_date: null,
            rmr_remark: null,
            rmr_project_id: getProjectId,
            rmr_federation_id: idSpaceshipAndFederation.federation_id,
            rmr_spaceship_id: idSpaceshipAndFederation.spaceship_id,
            rmr_is_use: true,
            rmr_created_date:  moment(new Date()).toISOString(),
            rmr_created_by: sessionStorage.getItem('user_id'),
            rmr_updated_date:  moment(new Date()).toISOString(),
            rmr_updated_by: sessionStorage.getItem('user_id'),
            arrMasterRc:[],
          }
          setRiskManagementList([...RiskManagementList, temp_data]);
      }

    //   console.log("RiskManagementList: ", RiskManagementList);

      async function filterRiskManagement(selectedData) {
        // console.log('testlog',editData.pri_id, editData.edit_text);
        setLoading(true);
        let temp = {
            project_id:getProjectId,
            selectedValue:selectedData
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/filterRiskManagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                
                setRiskManagementList(response.data.data);
                setCountRiskManagement({...CountRiskManagement, 
                AllCountRiskManagement:response.data.AllCountRiskManagement,
                CloseCountRiskManagement:response.data.CloseCountRiskManagement,
                UnCloseCountRiskManagement:response.data.UnCloseCountRiskManagement,
                LastUpdateRiskManagement:response.data.LastUpdateRiskManagement
            });
             
                setTest({ ...test, test: '' });
                test.test = '';
                setLoading(false);
                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getIdSpaceshipAndFederation() {

        let temp = {
            project_id:getProjectId
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/getIdSpaceshipAndFederation`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);  
                
                setidSpaceshipAndFederation({...idSpaceshipAndFederation,
                    spaceship_id:response.data.spaceship_id,
                    federation_id:response.data.federation_id,
                    project_name:response.data.project_name
                 });
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const removeItemById = (idToRemove) => {
        // กรองข้อมูลเพื่อเอาข้อมูลที่ไม่ตรงกับ id ที่ต้องการลบออก
        const updatedData = RiskManagementList.filter(item => item.rmr_id !== idToRemove);
        
        // อัพเดท state ด้วยข้อมูลที่กรองแล้ว
        DelRecordRiskmanagement(idToRemove);
        setRiskManagementList(updatedData);
      };


    //   console.log("DelRiskManagementList: ",DelRiskManagementList);
      
      async function DelRecordRiskmanagement(rmr_id) {
        setDelRiskManagementList((prevList) => {
            // ตรวจสอบว่ามีค่าเดิมหรือไม่ หากมีให้เพิ่ม ID ใหม่เข้าไป
            return [...prevList, { rmr_id: rmr_id }];
        });
    }
    
    
    async function saveRecordRiskmanagement() {
  
        const error_list = [];
        for(let checkData of RiskManagementList){
            // console.log("checkData: ",checkData);
            
        if (checkData.rmr_rc_id == '' || checkData.rmr_rc_id == null) {
            let temp_err = {
                message: "You must select the Risk Category."
            }
            error_list.push(temp_err);
        }
        if (checkData.rmr_descriptions == '' || checkData.rmr_descriptions == null) {
            let temp_err = {
                message: "You must enter Risk Descriptions."
            }
            error_list.push(temp_err);
        }
        if (checkData.rmr_target_date == '' || checkData.rmr_target_date == null) {
            let temp_err = {
                message: "You must select the Target Date."
            }
            error_list.push(temp_err);
        }
        if (checkData.rmr_rms_id_2 == '177ee013-076d-4d0c-96e8-fdcaceacdc2c' && checkData.rmr_closed_date == null) {
            let temp_err = {
                message: "You must select the Closed date."
            }
            error_list.push(temp_err);
        }
  
    }
    // console.log("error_list: ",error_list);
    
        if (error_list.length > 0) {

            var err_message = [];
            for (var e = 0; e < error_list.length; e++) {
                err_message.push(error_list[e].message);
            }
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })
            setDisplaySwal(false)
            setAlertKickMassge(true)
        } else {
           let temp = {
            RiskManagementList:RiskManagementList,
            DelRiskManagementList:DelRiskManagementList
           }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/saveRecordRiskmanagement`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                
          
                setTest({ ...test, test: '' });
                test.test = '';

                // filterRiskManagement();
                setMessageBox({
                    ...messageBox,
                    message: 'success',
                    isError: false,
                    menuTitle: 'Save'
                })
                // setDisplaySwal(false)

                toggle_modal_alertKickMassge();
                

            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }

        
    async function selesctRiskCategoty() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/selesctRiskCategoty`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: temp
        })
            .then(async function (response) {
                // console.log(response.data);
                setoptionRiskCategoty(response.data);
                
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // console.log("optionRiskManagementStatus2: ",optionRiskManagementStatus2);
    
    async function selesctRiskManagementStatus() {

        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/riskManagement/selesctRiskManagementStatus`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            // data: temp
        })
            .then(async function (response) {
                // console.log(response.data.risk_status_1);
                setRiskManagementStatus1(response.data.risk_status_1);
                setRiskManagementStatus2(response.data.risk_status_2);
         
                setTest({ ...test, test: '' });
                test.test = '';

                // console.log(response);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

return(<>

<ConfigProvider
                theme={{
                    token: {


                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',
                        colorTextQuaternary: 'White',//สีตัวอักษร
                        colorTextDescription: 'White',
                        colorBgContainer: '#16285C',

                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#0047FF',
                        colorBgBase: '#051F6C',

                        colorTextQuaternary: '#FFFFFF',


                        colorFillTertiary: 'blue',


                        colorTextBase: 'White',




                        colorPrimaryBg: '#85BFFF',


                    }
                }}
            >
    <div className='bgRiskManagement'>
        <div className='containerRiskManagement'>
            <div className='containerRiskManagement1'>
                <div>
            <img 

                onClick={() => {
                    window.location.href = `/StageMonitoring`
                }}
                style={{ 
                    cursor: 'pointer', 
                    // position: 'absolute', 
                    width: "70px", 
                    height: "70px", 
                    // marginTop: "8px",
                    border: "2px solid #fff",
                    borderRadius: "4px",
                    padding: "10px",
                    boxSizing: "border-box"
                }} 
                src={iconback_doorStage}
                />
                <div 
                style={{ 
                    position: 'absolute',       
                    top: '-1px',              
                    left: '90px',             
                    width: "10px",      
                    height: "72px",            
                    background: "linear-gradient(#0047FF, #002B99)" 
                }}
                ></div>
                <span 
                style={{ 
                    position: 'absolute',       
                    top: '8px',     
                    left: '110px',          
                    color: '#fff',           
                    fontSize: '40px',          
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    fontFamily: 'Oxanium'          
                }}
                >
                Risk Management - {idSpaceshipAndFederation.project_name}
                </span>
                </div>
                    <div style={{display:"flex"}}>
                        <div className='boxNewRisk'style={{width: "120px"}} 
                        onClick={()=>{
                            onClickNewRisk();
                        }}
                        >
                            <img src={iconPlus} style={{width:"20px", height:"20px"}}/>
                            <span style={{display:"flex", fontSize:"16px", fontWeight:500, paddingLeft:"5px"}}>NEW RISK</span>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
                        <div className='boxNewRisk' style={{ width:"80px"}}
                        onClick={()=>{
                            toggle_modal_confrimModal();
                            // saveRecordRiskmanagement();
                        }}
                        >
                            <img src={iconSaveRisk} style={{width:"20px", height:"20px"}}/>
                            <span style={{display:"flex", fontSize:"16px", fontWeight:500, paddingLeft:"5px"}}>SAVE</span>
                        </div>
                        <span style={{fontSize:"12px", fontWeight:500, paddingTop:"5px"}}>LAST UPDATE: {CountRiskManagement.LastUpdateRiskManagement ?
                             moment(CountRiskManagement.LastUpdateRiskManagement).format('DD/MM/YYYY') : <>ไม่มีอัปเดตล่าสุด</>}</span>
                        </div>
                    </div>
            </div>
          
                <div style={{display:"flex", marginTop:"25px", justifyContent:"space-between"}}>
                <div style={{display:"flex"}}>
                    <div style={{boxShadow: "0 0 10px #227016", border: "2px solid #227016",backgroundColor:"#1e2a1e", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"15px"}}>{"< 8"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>ให้ความใส่ใจ</span>
                    <div style={{boxShadow: "0 0 10px #726c0d", border: "2px solid #726c0d",marginLeft:"5px",backgroundColor:"#363300", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{fontFamily:"Oxanium", display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"12px"}}>{"9-15"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>เฝ้าระวัง และควบคุม</span>
                    <div style={{boxShadow: "0 0 10px #7b0505", border: "2px solid #7b0505", marginLeft:"5px",backgroundColor:"#3a0101", width:"60px", height:"30px", borderRadius:"5px", marginTop:"-5px"}}>
                        <span style={{display:"flex" ,fontSize:"16px", fontWeight:500, color:"#fff", textAlign:"center", paddingTop:"2px",paddingLeft:"8px"}}>{"16-25"}</span>
                    </div>
                    <span style={{marginLeft:"5px"}}>อันตราย</span>
                </div>
                    <div style={{paddingTop:"8px", backgroundColor:"#272727",width:"300px", height:"40px", textAlign:"center"}}>
                        <span style={{fontSize:"16px", fontWeight:500}}>ความถี่ในการติดตามความเสี่ยง:  </span>
                        <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                            {selectedValue ? ` ${selectedValue}` : 'All'}
                            <DownOutlined />
                            </Space>
                        </a>
                        </Dropdown>
                        </div>
                </div>

                <div style={{display:"flex", marginTop:"20px", justifyContent:"space-between", height:"130px", textAlign:"center"}}>
                    <div style={{display:"flex", width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between",backgroundColor:"#1b1b1b",borderRadius:"5px",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", width:"100%"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.AllCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#1e2a2b",borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"#07c0c7",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงทั้งหมด</span></div> 
                        </div>
      
                    </div>
                    <div style={{display:"flex",width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between",backgroundColor:"#1b1b1b",width:"100%",borderRadius:"5px",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", marginLeft:"20px"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.UnCloseCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#29291f",borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"#c59f08",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงที่เปิดอยู่</span></div> 
                        </div>
                    </div>
                    <div style={{display:"flex",width:"100%", height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", borderRadius:"5px", backgroundColor:"#1b1b1b",width:"100%",boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)", marginLeft:"20px"}}>
                        <div style={{paddingTop:"20px", fontSize:"36px"}}>{CountRiskManagement.CloseCountRiskManagement}</div>    
                        <div style={{backgroundColor:"#202b24", borderRadius:"5px", height:"30px", paddingTop:"5px"}}><span style={{color:"#259d53",fontSize:"16px", fontWeight:700}}>จำนวนความเสี่ยงที่ปิดแล้ว</span></div> 
                        </div>
                    </div>
                </div>
                <div className='row mt-3' style={{height:"100%", display:"flex", overflowY:"auto", gap:"20px"}}>
                {RiskManagementList.length <= 0 ? (<></>) : <>
                    {RiskManagementList.map((item, index)=> (
                          <div key={item.rmr_id} className={getRiskClassCardMain(item.rmr_impact * item.rmr_probability)}> 
                          <div style={{/* border: "2px solid red" , */ width:"10%", flexGrow:1}}>
                              <div style={{border: "2px solid #2c2c2c", width:"80%", height:"30%",display:"flex", marginLeft:"10%", marginTop:"10%", flexDirection:"column", textAlign:"center", paddingTop:"10%", borderRadius:"5px"}}>
                                  <div style={{flexGrow:1}}>No.</div>
                                  <div style={{flexGrow:1}}>{index+1}</div>
                              </div>
                          </div>
                          
                          <div style={{/* border: "2px solid red", */
                               width:"100%", 
                               display:"flex", 
                               flexDirection:"column", 
                               flexWrap:"nowrap", 
                               flexGrow:1,
                              //  width:"100%", 
                              //  display:"flex", 
                              //  flexDirection:"column", 
                              //  flexWrap:"nowrap", 
                              //  flexGrow:1,
                               overflowY: "auto", 
                               wordWrap: "break-word", 
                               overflowWrap: "break-word",
                               textOverflow: "ellipsis",
                               boxSizing: "border-box"
                               }}>
      
                              <div className='row mt-2' style={{flexGrow:1}}>
                              <div className='col-4'>
                              <Tooltip title={(<>
                                    {item.arrMasterRc.length > 0 ? 
                                    item.arrMasterRc.map((itemT, indexT)=> (<>
                                        <div key={itemT.rm_id}>
                                            <div>Master Risk Category</div>
                                            <div style={{ border: '1px solid #fff'}}></div>
                                            <div> Low: {itemT.rm_low} </div>
                                            <div> Medium: {itemT.rm_medium} </div>
                                            <div> High: {itemT.rm_high} </div>
                                        </div>
                                    </>)) :(<div>No Data</div>)
                                    }
                                    </>) || 'No Data'} placement="topLeft" color={"#0047FF"} >
                                  <div><span style={{fontSize:"12px", fontWeight:500}}>Risk Category</span></div> 
                                  </Tooltip>
                                  <Select
                                        className='testSelect-01'
                                        showSearch
                                        options={optionRiskCategoty}
                                        value={optionRiskCategoty.find((e) => e.value === item.rmr_rc_id)}
                                        onChange={(e, l, s) => {
                                            const updatedList = RiskManagementList.map((riskItem) =>
                                                riskItem.rmr_id === item.rmr_id
                                                    ? { ...riskItem, rmr_rc_id: e }
                                                    : riskItem
                                            );
                                            setRiskManagementList(updatedList);
                                        }}
                                    />
                             
                              </div>
                              <div className='col-8' style={{display:'flex', paddingTop:"5px"}}>
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1,alignItems:"center" }}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>โอกาสเกิด</span>
                                      <Select
                                         style={{width:"80%"}}
                                                  className='testSelect-01'
                                                  // showSearch
                                                  options={optionProbability}
                                                  // value={optionRole.filter((e) => { return e.value === tempSrsSave.prepared_sp_position_id })}
                                                  // onSearch={onSearch}
                                                  // filterOption={filterOption}
                                                  // disabled={userismode}
                                                  value={optionProbability.find((e) => e.value === item.rmr_probability)}
                                                  onChange={(e, l, s) => {
                                                    const updatedList = RiskManagementList.map((riskItem) =>
                                                        riskItem.rmr_id === item.rmr_id
                                                            ? { ...riskItem, rmr_probability: e }
                                                            : riskItem
                                                    );
                                                    setRiskManagementList(updatedList);
                                                }}
                                                //   onChange={(e, l, s) => {
                                                //       const updatedList = RiskManagementList.map((riskItem) =>
                                                //           riskItem.rmr_probability === item.rmr_probability
                                                //               ? { ...riskItem, rmr_probability: e }
                                                //               : riskItem
                                                //       );
                                                //       setRiskManagementList(updatedList);
                                                //   }}
                                              />
      
                                      
                                      {/* <input style={{width:"20px", height:"20px"}}></input> */}
                                  </div>
      
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1,alignItems:"center"}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>ระดับผลกระทบ</span>
                                      <Select
                                              style={{width:"60%"}}
                                                  className='testSelect-01'
                                                  // showSearch
                                                  options={optionProbability}
                                                  // value={optionRole.filter((e) => { return e.value === tempSrsSave.prepared_sp_position_id })}
                                                  // onSearch={onSearch}
                                                  // filterOption={filterOption}
                                                  // disabled={userismode}
                                                  value={optionProbability.find((e) => e.value === item.rmr_impact)}
                                                  onChange={(e, l, s) => {
                                                    const updatedList = RiskManagementList.map((riskItem) =>
                                                        riskItem.rmr_id === item.rmr_id
                                                            ? { ...riskItem, rmr_impact: e }
                                                            : riskItem
                                                    );
                                                    setRiskManagementList(updatedList);
                                                }}
                                                //   onChange={(e, l, s) => {
                                                //       const updatedList = RiskManagementList.map((riskItem) =>
                                                //           riskItem.rmr_impact === item.rmr_impact
                                                //               ? { ...riskItem, rmr_impact: e }
                                                //               : riskItem
                                                //       );
                                                //       setRiskManagementList(updatedList);
                                                //   }}
                                              />
      
                                      {/* <input style={{width:"20px", height:"20px"}}></input> */}
                                  </div>
      
                                  <div style={{display:"flex", flexDirection:"column",paddingTop:"20px", flexGrow:1,alignItems:"center" }}>=</div>
      
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1,alignItems:"center"}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>ระดับความเสี่ยง</span>
                                      <div 
                                      className={getRiskClassName(item.rmr_impact * item.rmr_probability)}
                                      style={{
                                          width:"35px", 
                                          height:"35px",
                                          color: '#ffffff', // สีข้อความ
                                          borderRadius:"50px",
                                          textAlign:"center",
                                          paddingTop:"6px"
                                          }}>{item.rmr_impact * item.rmr_probability || 0}</div>
                                  </div>
                                  
                                  <div style={{display:"flex", flexDirection:"column", flexGrow:1,alignItems:"center"}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>การจัดการความเสี่ยง</span>
                                      <div
                                      className={getRiskClassName(item.rmr_impact * item.rmr_probability)} 
                                      style={{
                                          width:"80%", 
                                          height:"25px",
                                          color: '#ffffff', // สีข้อความ
                                          borderRadius:"10px",
                                          textAlign:"center",
                                          marginTop:"7px"
                                      }}>
                                       <span style={{fontSize:"14px", fontWeight:500}}>
                                       {getRiskManagementName(item.rmr_impact, item.rmr_probability,item.rmr_id)}
                                        </span>
                                        </div>
                                  </div>
                              </div>
                          </div>
      
                          <div className='row' style={{flexGrow:1}}>
                          <div className='col-12'>
                                  <div><span style={{fontSize:"12px", fontWeight:500}}>Risk Descriptions</span></div> 
                                  <textarea
                                    className="areaTEst rezieznone"
                                    type="text"
                                    value={item.rmr_descriptions}
                                    onChange={(e) => {
                                        setRiskManagementList((prevList) =>
                                        prevList.map((listItem) =>
                                            listItem.rmr_id === item.rmr_id
                                            ? { ...listItem, rmr_descriptions: e.target.value } 
                                            : listItem
                                        )
                                        );
                                    }}
                                    />
                     
                                 
                              </div>
                          </div>
      
                          <div className='row' style={{flexGrow:1}}>
                          <div className='col-4' style={{display:'flex'}}>
                          <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>Target Date</span>
                                      <StyledDatePicker
                                          className="dateText3"
                                          value={item.rmr_target_date ? dayjs(item.rmr_target_date) : null}
                                          // disabled={userismode}
                                          placeholder="วว/ดด/ปปปป"
                                          format={"DD/MM/YYYY"}
                                          onChange={(date, dateString) => {
                                            const updatedList = RiskManagementList.map((riskItem) =>
                                                riskItem.rmr_id === item.rmr_id
                                                    ? { ...riskItem, rmr_target_date: date ? date.$d : null }
                                                    : riskItem
                                            );
                                            setRiskManagementList(updatedList);
                                        }}
                                          
                                      />
      
                          
                                  </div>
                          </div>
                          <div className='col-4' style={{display:'flex'}}>
                          <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>สถานะ</span>
                                      <Select
                                                  className='testSelect-01'
                                                  showSearch
                                                  options={optionRiskManagementStatus2}
                                                 value={optionRiskManagementStatus2.filter((e) => { return e.value === item.rmr_rms_id_2 })}
                                                  // onSearch={onSearch}
                                                  // filterOption={filterOption}
                                                  // disabled={userismode}
                                                  onChange={(e, l, s) => {
                                                    const updatedList = RiskManagementList.map((riskItem) =>
                                                        riskItem.rmr_id === item.rmr_id
                                                            ? { ...riskItem, rmr_rms_id_2: e }
                                                            : riskItem
                                                    );
                                                    setRiskManagementList(updatedList);
                                                }}
                                              />
                       
                                  </div>
                          </div>
                          <div className='col-4' style={{display:'flex'}}>
                          <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                      <span style={{fontSize:"12px", fontWeight:500}}>วันที่ปิดความเสี่ยง</span>
                                      <StyledDatePicker
                                          className="dateText3"
                                          value={item.rmr_closed_date ? dayjs(item.rmr_closed_date) : null}
                                          // disabled={userismode}
                                          placeholder="วว/ดด/ปปปป"
                                          format={"DD/MM/YYYY"}
                                          onChange={(date, dateString) => {
                                            const updatedList = RiskManagementList.map((riskItem) =>
                                                riskItem.rmr_id === item.rmr_id
                                                    ? { ...riskItem, rmr_closed_date: date ? date.$d : null }
                                                    : riskItem
                                            );
                                            setRiskManagementList(updatedList);
                                        }}
                              
                                
                                          
                                      />
                                  </div>
                          </div>
                          </div>
                          </div>
      
                          <div style={{/* border: "2px solid red", */ 
                              width:"100%", 
                              display:"flex", 
                              flexGrow:1,
                              overflowY: "auto", 
                              wordWrap: "break-word", 
                              overflowWrap: "break-word",
                              textOverflow: "ellipsis",
                              boxSizing: "border-box",
                              marginTop:"5px"
                              }}>
                                      <div className='row mt-2' style={{display:"flex", flexDirection:"row", width:"100%"}}>
                                      <div className='col-12' style={{display:'flex'}}>
                                      <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                                  <span style={{fontSize:"12px", fontWeight:500}}>แผนจัดการความเสี่ยง</span>
                                                  <textarea
                                                        className="areaTEst rezieznone"
                                                        type="text"
                                                        value={item.rmr_mitigation_plan}
                                                        onChange={(e) => {
                                                            setRiskManagementList((prevList) =>
                                                            prevList.map((listItem) =>
                                                                listItem.rmr_id === item.rmr_id
                                                                ? { ...listItem, rmr_mitigation_plan: e.target.value } // อัปเดตเฉพาะรายการที่ id ตรงกัน
                                                                : listItem
                                                            )
                                                            );
                                                        }}
                                                        />

                              
                                              </div>
                                      </div>
                                      <div className='col-12' style={{display:'flex'}}>
                                      <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                                  <span style={{fontSize:"12px", fontWeight:500}}>แผนฉุกเฉิน</span>
                                                  <textarea
                                                        className="areaTEst rezieznone"
                                                        type="text"
                                                        value={item.rmr_contingency_plan}
                                                        onChange={(e) => {
                                                            setRiskManagementList((prevList) =>
                                                            prevList.map((listItem) =>
                                                                listItem.rmr_id === item.rmr_id
                                                                ? { ...listItem, rmr_contingency_plan: e.target.value } // อัปเดตเฉพาะรายการที่ id ตรงกัน
                                                                : listItem
                                                            )
                                                            );
                                                        }}
                                                        />
                                             
                                              </div>
                                      </div>
                                      <div className='col-12' style={{display:'flex'}}>
                                      <div style={{display:"flex", flexDirection:"column", flexGrow:1}}>
                                                  <span style={{fontSize:"12px", fontWeight:500}}>Remark</span>
                                                  <textarea
                                                        className="areaTEst rezieznone"
                                                        type="text"
                                                        value={item.rmr_remark}
                                                        onChange={(e) => {
                                                            setRiskManagementList((prevList) =>
                                                            prevList.map((listItem) =>
                                                                listItem.rmr_id === item.rmr_id
                                                                ? { ...listItem, rmr_remark: e.target.value } // อัปเดตเฉพาะรายการที่ id ตรงกัน
                                                                : listItem
                                                            )
                                                            );
                                                        }}
                                                        />
                                        
                                              </div>
                                      </div>
                                      </div>
                          </div>
                          <div style={{/* border: "2px solid red", */ 
                              width:"20%", 
                              display:"flex", 
                              flexGrow:1,
                              overflow:"clip", 
                              // wordWrap: "break-word", 
                              // overflowWrap: "break-word",
                              // textOverflow: "ellipsis",
                              boxSizing: "border-box"
                              }}>
                              <div className='row' style={{flexGrow:1}}>
                                  <div className='col-12 mt-2' style={{textAlign:"center", flexDirection:"column", display:"flex", alignItems:"center"}}>
                                      <div style={{textAlign:"center"}}> <span style={{fontSize:"12px", fontWeight:500, }}>Risk Descriptions</span></div>
                                     
                                      <div style={{borderRadius:"5px", width:"30px", height:"30px", border: "2px solid #fff", marginTop:"50%"}}>
                                          <img src={iconVectorRisk} style={{width:"20px", height:"20px", marginTop:"3px", cursor:"pointer"}}/>
                                      </div>
                                      <div style={{width:"30px", height:"30px", border: "2px solid #730b0b", backgroundColor:"#ae0303", marginTop:"5%" }}
                                      onClick={()=>{
                                        removeItemById(item.rmr_id);
                                        
                                      }}
                                      >
                                          <img src={iconBin} style={{width:"20px", height:"20px",marginTop:"3px", cursor:"pointer"}}/>
                                      </div>
                                      </div>
                               
                              </div>
                          </div>
                       </div>
                ))}
                </>

                }
     
            </div>

        </div>
       
    </div>

    <ModelAlertConfrim
                isOpen={displaySwal}
                mainFuc={() => saveRecordRiskmanagement()}
                toggle_alertKick={() => toggle_modal_confrimModal()}
                
            />
    <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => { filterRiskManagement(); }}
            />
    {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'green' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ConfigProvider>
</>)
}
export default RiskManagement;