import React, { useEffect, useRef, useState } from 'react';
import './openApi.css';
import { Upload } from 'antd';
import uploadIcon from '../../../assets/img/icon/upload.png';
import Configs from '../../../config';
import styled from 'styled-components';
import axios from 'axios';




const MyP = styled.p`
  font-family: Oxanium;
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
`;

const ScBtn = styled.p`
    background-color: black; 
    display: flex; 
    justify-content: center;
    padding: 2px 5px 1px 5px; 
    cursor: pointer;
`;

function PostmanCollection({ toggleModalOpenApi }) {

    const [isBoxVisible, setIsBoxVisible] = useState(false);
    const [fileName, setFileName] = useState('');
    const [listData, setListData] = useState([]);
    const [myFile, setMyFile] = useState(null);
    const [reload, setReload] = useState(0);
    const project = JSON.parse(localStorage.getItem('projectAct'));
    const projectId = project.project_id;
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);


    const { Dragger } = Upload;
    const [test, settest] = useState({
        test: ''
    })
    useEffect(() => {
        getOpenApiData();


        /*  let temp = {
             test:'test'
         }
          axios({
             method: 'post',
             url: `${Configs.TTT_Game_Service}/api/openApi/insertOpenApi`,
             headers: {
                 Authorization: sessionStorage.getItem('access_token'),
                 'X-TTT': `${Configs.API_TTT}`,
                 'Content-Type': 'application/json',
             },
             data: temp,
         }).then(async (res) => {
              console.log(res);
         
         }).catch(function (error) {
             console.log(error);
         }); */

    }, [reload]);


    const handleInnerClick = (event) => {
        event.stopPropagation();
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setIsBoxVisible(!isBoxVisible);
    };
// console.log("listData: ",listData);
    const getOpenApiData = async () => {
        setLoading(true);
        try {
            setListData([]);
            const response = await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/openApi/getOpenApi`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: { project_id: projectId },
            })

            if (response.data) {
               
                const data = response.data

                // for (let item of data) {
                //     const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                //         headers: {
                //             Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                //         },
                //         responseType: 'blob',
                //     });
                //     const filePostman = URL.createObjectURL(response2.data);
                //     item.filePostman = filePostman;
    
                    // ตั้งค่าข้อมูลทีละตัว
                //     setListData((prevData) => [...prevData, item]);
                // }
                
                    const filePromises = data.map(async (item) => {
                        if(item.fd_img_id){
                        const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                            },
                            responseType: 'blob',
                        });
                    
                        const filePostman = URL.createObjectURL(response2.data);
                        return { ...item, filePostman };
                    }
                    });
        
                    const updatedData = await Promise.all(filePromises);
                  
               
                    setListData(updatedData);

          
                // settest({ ...test, test: '' });
                // test.test = '';
                setLoading(false);
            }

        } catch (error) {

            console.log(error);
        }



    }
    

    const props = {
        name: 'file',
        multiple: false,
        accept: '.json',
        method: 'get',
        showUploadList: false,
        headers: {
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },
        action: Configs.TTT_Game_Service + '/api/upload/fackFile',
        beforeUpload: (file) => {
            const isJson = file.type === 'application/json';
            if (!isJson) {
                setErrorMessage({ status: false, message: 'You can only upload JSON Postman collection!' });
                setAlertKick(true);
                // alert('You can only upload JSON files!');
                return false;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    // Check for key properties of a Postman collection
                    if (json.info && json.info.schema && json.item) {
                        setFileName(file.name);
                        setMyFile(file);
                    } else {
                        // alert('The uploaded JSON file is not a valid Postman collection!');
                        setErrorMessage({ status: false, message: 'The uploaded JSON file is not a valid Postman collection!' });
                        setAlertKick(true);
                    }
                } catch (error) {
                    setErrorMessage({ status: false, message: 'The uploaded file is not a valid JSON file!' });
                    setAlertKick(true);
                    // alert('The uploaded file is not a valid JSON file!');
                }
            };
            reader.readAsText(file);

            // Prevent automatic upload
            return false;
        },

        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };


    const clearFile = () => {
        setFileName('');
        setMyFile(null);
        setIsBoxVisible(false);
    }

    const addToListTable = async () => {
        if (myFile != null && fileName != '') {
            setAlertKick(true);
            setErrorMessage({ status: true, message: '' });
            const fromdata = new FormData();
            fromdata.append('Profile', myFile);
            fromdata.append('typeFile', "jsonPostman");
            fromdata.append('projectKey', "TTTSpaceShip");
            fromdata.append('oa_gen_by', sessionStorage.getItem('user'));
            fromdata.append('project_id', projectId);
            // console.log(list);

            try {
                const response = await axios({
                    method: 'post',
                    url: `${Configs.TTT_Game_Service}/api/upload/insertOpenApi2`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/octet-stream',
                    },
                    data: fromdata,
                })
                // console.log(response.data);
                if (response.data.status === true) {
                    setIsBoxVisible(false);
                    clearFile();
                    const data = await response.data.data;
                    const data2 = await response.data.data2;
           
               
                    setMyFile(null);
                    setAlertKick(false);
                    setErrorMessage({ status: '', message: '' });
                    // console.log("datadddd:",data);
                   CheckAndCreateFolderBox(data, [],myFile,data2);
                
           
                    // setReload(reload + 1);
                } else {
                    setErrorMessage({ status: false, message: 'API response was not successful!' });
                    console.error('API call was not successful: ', response.data);
                }

            } catch (error) {
                setErrorMessage({ status: false, message: 'API call was not successful!', exit: true });
                console.log(error);
            }

        } else {
            setIsBoxVisible(false);
            clearFile();
        }
    }

    async function CheckAndCreateFolderBox(data,olddata,file,data2) {
        
        let temp = {
            folderLis: ["PROJECT",`PROJECT_${projectId}`,"PROJECT POSTMAN COLLECTION"]
        }
        // console.log("dataCheckAndCreateFolderBox: ",data);
       /*  temp.folderLis.push(folder_name) */
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {

                    data.files = file
              await UploadFiles(data, response.data.folder_id,olddata,data2)
            //   console.log("data2: ",data2);
            //   setListData(data2);
              setReload(reload + 1);
                }
            })
            .catch(function (error) {
               
                console.log(error);
            });
    };
    async function UploadFiles(item, folder_id,olddata,data2) {
        // console.log("item:", item[0].oa_id);
        setLoading(true);
        const dataImg = new FormData();
        dataImg.append('File', item.files);
        dataImg.append('folder_id', folder_id);
        dataImg.append('key', "PROJECT POSTMAN COLLECTION");
        dataImg.append('image_old_list', JSON.stringify(olddata));
        dataImg.append('ref_id', item[0].oa_id);
      
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "multipart/form-data",
            },
            data: dataImg
        })
            .then(async function (response) {
                if (response.data) {
                  if (response.data.image_id_list.length > 0) {
                    setLoading(false);
                      return true
                  
                  }
              
                }
         
            })
            .catch(function (error) {
              
                console.log(error);
            });
      };

      async function DelFiles(item) {
        // console.log(item);
        let temp = {
            id:item
        }
        console.log("temp: ",temp);
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/DelFiles`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "multipart/form-data",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    console.log("ลบ");
                }
            })
            .catch(function (error) {
              
                console.log(error);
            });
      }

    const savePostman = async (obj) => {
        try {
            if (obj != null) {
                console.log('workhere');

                return await axios({
                    method: 'POST',
                    url: `${Configs.TTT_Game_Service}/api/upload/insertOpenApi`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: obj,
                });
            }

        } catch (error) {
            console.log(error);
            return undefined
        }
    }

    // console.log(listData.length);

    const removeDatafromList = async (id, fileName, item) => {

        // console.log("item: ",item);
        DelFiles(item.fd_ref_id);
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/openApi/deleteOpenApi`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: { project_id: id, file: fileName }
        }).then(async (res) => {
            // console.log(res);
            setReload(reload + 1);
        })
    }

    // console.log(listData);

    return (
        <>
            <div className='popupBg' onClick={toggleModalOpenApi}>
                <div className='mainBox' onClick={handleInnerClick}>
                    <div style={{ margin: '1.5% 1.5% 0.5% 1.5%', width: '97%', height: '95%', position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h1 style={{ fontWeight: '600', fontSize: '50px' }}>
                                POSTMAN COLLECTION
                            </h1>

                            <button style={{ cursor: 'pointer', backgroundColor: '#102456', border: '1px solid #0047FF', padding: '5px', height: '50%' }} onClick={handleButtonClick}>
                                <span style={{ fontSize: '20px', fontFamily: 'Oxanium', color: 'white' }}>
                                    + Upload Postman Collection
                                </span>
                            </button>
                        </div>
                        {isBoxVisible && (
                            <div
                                className="floatingBox"
                                style={{
                                    top: '48px',
                                    right: '0px',
                                }}
                            >
                                <Dragger {...props} className='dragUpload' style={{ border: '1px dashed #0047FF', borderRadius: '0px' }}>
                                    {
                                        fileName == '' ? (
                                            <>
                                                <img style={{ width: '25px', height: '25px' }} src={uploadIcon} alt="uploadIcon" />
                                                <span style={{ paddingLeft: '10px', fontFamily: 'Oxanium', color: 'white' }}>Upload File</span>
                                            </>
                                        ) : (
                                            <>
                                                <MyP style={{ paddingLeft: '10px', fontFamily: 'Oxanium', color: 'white', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '247px' }}>{fileName}</MyP>
                                            </>
                                        )
                                    }
                                </Dragger>

                                <MyP style={{ margin: '5px 0 5px 45%' }}>
                                    OR
                                </MyP>

                                <Upload {...props}>
                                    <div className='browseBtn' style={{ cursor: 'pointer' }}>
                                        <MyP>Browse File</MyP>
                                    </div>
                                </Upload>

                                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '15%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <ScBtn style={{ border: '1px solid #0047FF', marginRight: '5px' }} onClick={() => { addToListTable(); }}>
                                            <MyP style={{ color: '#0047FF' }}>Save</MyP>
                                        </ScBtn>

                                        <ScBtn style={{ border: '1px solid #FF0000' }} onClick={clearFile}>
                                            <MyP style={{ color: '#FF0000' }}>Cancel</MyP>
                                        </ScBtn>
                                    </div>
                                </div>
                            </div>
                        )}
                        <hr style={{ borderColor: '#0047FF' }} />

                        <div style={{ border: '0px solid red', height: '75%', overflowY: 'auto' }}>
                            <table style={{ borderSpacing: '10px' }}>
                                <tr className='trHeader'>
                                    <td>Version</td>
                                    <td style={{ width: '250px' }}>Link</td>
                                    <td style={{ width: '250px' }}>Download</td>
                                    <td>Upload Date</td>
                                    <td style={{ minWidth: '160px' }}>Uploader</td>
                                    <td style={{ width: '185px' }}>Management</td>
                                </tr>

                                {/* <tr className='trBody'>
                                    <td>dwdwdwd</td>
                                    <td>
                                        <button className='genBtn'> Gen OpenAPI </button>
                                    </td>
                                    <td>
                                        <button className='genBtn'> Download </button>
                                    </td>
                                    <td>dwdwdwd</td>
                                    <td>dwdwdwd</td>
                                    <td>
                                        <button style={{ color: 'white', backgroundColor: '#910000', width: '29px', border: '1px solid red', cursor: 'pointer' }}>
                                            <i class='fa fa-trash' style={{ marginLeft: '-2px', marginTop: '5px' }}  ></i>
                                        </button>
                                    </td>
                                </tr> */}

                                {
                                    listData && listData.length > 0 ?
                                        listData.map((item, index) => {
                                            if (item?.oa_is_use == true) {
                                                const date = new Date(item.oa_gen_date);
                                                const toDate = date.toLocaleDateString('en-GB');

                                                return (
                                                    <tr className='trBody'>
                                                        <td>{item.oa_version}</td>
                                                        <td>
                                                            <button className='genBtn'> Gen OpenAPI </button>
                                                        </td>
                                                        <td>
                                                            {
                                                                item.fd_img_id == null ? (
                                                                    <button className='genBtn' style={{ cursor: 'not-allowed' }}> Download </button>
                                                                ) : (
                                                                    <a href={item.filePostman} download={item.fd_origin_name} type="application/json" >
                                                                        <button className='genBtn'> Download </button>
                                                                    </a>

                                                                    // <a href={`http://150.95.25.61:7778/static/${item.oa_file_path}`}>
                                                                    //     <button className='genBtn'> Download </button>
                                                                    // </a>
                                                                )
                                                            }
                                                        </td>
                                                        <td>{toDate}</td>
                                                        <td>{item.oa_gen_by}</td>
                                                        <td>
                                                            <button style={{ color: 'white', backgroundColor: '#910000', width: '29px', border: '1px solid red', cursor: 'pointer' }} onClick={() => removeDatafromList(item.oa_id, item.oa_file_name,item)}>
                                                                <i class='fa fa-trash' style={{ marginLeft: '-2px', marginTop: '5px' }}  ></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }) : ''
                                }

                            </table>
                        </div>
                        <div style={{ marginTop: '15px', position: 'absolute', bottom: '0px', right: '0px' }}>
                            <div style={{ display: 'flex' }}>
                                <ScBtn style={{ border: '0px solid #0047FF', marginRight: '10px', paddingLeft: '10px', paddingRight: '10px', marginBottom: '0px', backgroundColor: 'rgba(0, 0, 0, 0)', cursor: 'default' }} >
                                </ScBtn>

                                <ScBtn style={{ border: '1px solid #FF0000', paddingLeft: '10px', paddingRight: '10px', marginBottom: '0px' }} onClick={toggleModalOpenApi}>
                                    <MyP style={{ color: '#FF0000', fontSize: '16px' }}>Cancel</MyP>
                                </ScBtn>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: `${errorMessage.status ? '#ffffff' : 'yellow'}` }}>
                                    {errorMessage.status ? 'Loading' : 'Warning'}
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning' style={{ border: 'none' }}>
                                    {errorMessage.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                                </div>
                            </div>
                            {errorMessage.status ? <></> : <>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        {errorMessage.message}

                                    </div>
                                </div>


                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick();
                                        if(errorMessage.exit === true){
                                            toggleModalOpenApi();
                                        }
                                    }}>OK</button>

                                </div>
                            </>}


                        </div>
                    </div>
                </div>
            </div>

            {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    )
}

export default PostmanCollection