import React, { useEffect, useState, useRef } from 'react'
import '../Package/packagestyle.css'
import axios from "axios";
import Configs from "../../config";
import moment from "moment";
import { func } from 'prop-types';
import Title from "../../components/customElement/headertitle"
import checkicon from "../../assets/img/bg/imgmian.jpg"
import poin from '../../assets/img/bg/imgbdsport.jpg';
import Switch from '../../components/customElement/Switch ';
import iconedit from '../../assets/img/icon/edit-3.png';
import iconsave from '../../assets/img/icon/Icon Save SS.png';
import iconlike from '../../assets/img/icon/Icon Like SS.png';
import iconcomment from '../../assets/img/icon/Icon Comment SS.png';
import iconlikein from '../../assets/img/icon/Icon Like SS_Blue.png';
import viewimg from '../../assets/img/icon/Cover_Default Doc.png';
import Select from 'react-select'
import { Tooltip } from 'antd';
import JSZip from 'jszip';
import CommentReport from '../../components/commentReport/ModelComment.jsx';
function Listtemplate({ template, callback }) {
  const [templatedeteil,setTemplatedeteil] = useState({

  })
  const [templateListshow,setTemplateListshow] = useState([])
  const [commentReport, setCommentReport] = useState(false);
  const toggle_modal_commentReport = () => setCommentReport(!commentReport);
  const [templateComment,setTemplateComment] = useState({
    tp_id:'',
    tc_id:'',
    })
    const [templateview, settemplateview] = useState(false);
    const toggle_modal_templateview = () => settemplateview(!templateview);
  function openCommentReport(tp_id,tp_name) {
    setTemplateComment({...templateComment,tp_id:tp_id,tp_name:tp_name})
    templateComment.tp_id = tp_id;
    templateComment.tp_name = tp_name;
    setCommentReport(true)
  }

  function CloseCommentReport(params) {
    setCommentReport(false)
    
  }
  const [templateList,setTemplateList] = useState([])
  const [templateType, setTemplateType] = useState('baed5cf7-533e-486c-9515-c0b7e4de96a1');
  useEffect(() => {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/templateMgt/getTemplateByType/${template.idtemplate}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {
        if (response.data) {
          setloopcard(response.data)
          setTemplateList(response.data)
        }



      })
      .catch(function (error) {
        console.log(error);

      });
    setTemplateType(template.idtemplate);
  }, [template.idtemplate])


  function getTmplateAllByType() {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/templateMgt/getTemplateByType/${templateType}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {
        if (response.data) {
          setloopcard(response.data)
          setTemplateList(response.data);

        }



      })
      .catch(function (error) {
        console.log(error);

      });
  }

  function saveCountDownload(template_id) {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/templateMgt/saveCountDownLoadTemplate/${template_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {

        if (response.data) {
          getTmplateAllByType();
        }



      })
      .catch(function (error) {
        console.log(error);

      });
  }

  function saveCountLike(template_id) {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/templateMgt/saveLikeTemplate/${template_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {

        if (response.data) {
          getTmplateAllByType();
        }



      })
      .catch(function (error) {
        console.log(error);

      });
  }
  function formatNumberToK(value) {


    if (parseInt(value) > 999999) {
      return parseFloat(value / 1000000).toFixed(1) + 'M';
    } else if (parseInt(value) > 999) {
      return parseFloat(value / 1000).toFixed(1) + 'K';
    } else {
      return value;
    }

  }


  async function gettemplate() {
    console.log(templateList);
    if (templateList.length > 0) {
      const zip = new JSZip();

      let pdfUrls = templateList
      let fetchPromises = pdfUrls.map(async ({ tp_path_name, tp_file_name }) => {
        console.log(tp_path_name, tp_file_name);
        let cut = tp_file_name.split('.')
        const response = await fetch(`${Configs.TTT_Game_Service_IMG}${tp_path_name}`);
        const pdfData = await response.blob();
        zip.file(`${cut[0]}.${cut[1]}`, pdfData);
      });

      await Promise.all(fetchPromises);

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = `Template_Spaceship_${template.nametemplate}.zip`;
      link.click();

      saveCountDownLoadAllTemplateByType()
    }




    function saveCountDownLoadAllTemplateByType() {
      axios({
        method: 'get',
        url: `${Configs.TTT_Game_Service}/api/templateMgt/saveCountDownLoadAllTemplateByType/${templateType}`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },


      })
        .then(function (response) {

          if (response.data) {
            getTmplateAllByType();
          }



        })
        .catch(function (error) {
          console.log(error);

        });
    }

  }

  function setloopcard(data) {
    console.log(data);
    let loop = []
    let count = 0
    let looptemplate = data.map((e, index) => {
      console.log(count,index);

let checttooltip = false

if (e.tp_name.length > 20) {
  checttooltip = true
}
      
      loop.push(<>
        <div className='col' style={{ padding: '0 20px' }}>
          <svg className='postBoxLine' height="515" width="310">
            <line x1="7" y1="20" x2="26.5" y2="0" style={{ stroke: '#676767', strokeWidth: 3 }} />
            <line x1="250" y1="507.5" x2="295" y2="462.5" style={{ stroke: '#676767', strokeWidth: 3 }} />
            <line x1="295" y1="462.5" x2="295" y2="425" style={{ stroke: '#676767', strokeWidth: 3 }} />
          </svg>
          <div className='postBox'>

            <div className='postBoxIn'>
              <div style={{ width: '100%', paddingLeft: '40px', paddingRight: '40px', position: 'absolute'/* ,background:'#000' */ }}>
                <img src={ /* e.tp_profile_path ? `${Configs.TTT_Game_Service_IMG}${e.tp_profile_path}` :  */viewimg} style={{ width: "100%", height: "250px",objectFit:'contain', marginTop: "10px" }} />
              </div>
              <div className='postLineInTop' style={{ top: '0px', position: 'absolute' }}>


              </div>
{checttooltip === true ? 
<>

<Tooltip title={e.tp_name}>
              <div className="col " style={{ textAlign: 'center', marginTop: '270px', position: 'absolute', width: '230px',marginLeft:'20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
wordBreak:'break-all',
    WebkitLineClamp: 1 }}>
                {e.tp_name}
              </div>
          
              </Tooltip>
</>

:
<>
<div className="col " style={{ textAlign: 'center', marginTop: '270px', position: 'absolute', width: '230px',marginLeft:'20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
wordBreak:'break-all',
    WebkitLineClamp: 1 }}>
                {e.tp_name}
              </div>
</>}
            
              <div className="row " style={{ textAlign: 'left', fontWeight: '100', fontSize: '12px', paddingLeft: '40px', paddingRight: '90px', position: 'absolute', marginTop: '295px' }}>
               {/*  <div className='break' style={{width:'230px',height:'200px' }}> {e.tp_description}</div> */}
               <div style={ {
    width: '230px',
    maxHeight: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
wordBreak:'break-all',
    WebkitLineClamp: 7, // Limit to three lines
  }}>
     {e.tp_description}
    </div>
                <div style={{ marginLeft: '3px', color: '#0047FF', cursor: 'pointer',textShadow:'-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff',filter:'drop-shadow(0px 0px 1px #fff)'}}
onClick={(x) => {
  setTemplatedeteil(e)
  
  
  toggle_modal_templateview()}}
                >More detail</div>
              </div>
              <div className='postLineInLeft'></div>


              <div className='postLineInBottom'>

                <div className="row mt-1">
                  <div className='col-3' style={{ whiteSpace: 'nowrap' }}><a href="javascript:null" onClick={() => { saveCountLike(e.tp_id); }}><img src={e.haveLike ? iconlikein : iconlike} style={{ width: '15px', marginTop: '-5px' }} /></a> {formatNumberToK(e.tp_total_like)}</div>
                  <div className='col-3' style={{ whiteSpace: 'nowrap' }}><a href="javascript:null"><img src={iconcomment} style={{ width: '15px', marginTop: '-5px' }} onClick={() => { openCommentReport(e.tp_id, e.tp_name); }} /></a>  {formatNumberToK(e.comment_count)}</div>
                  <div className='col-3' style={{ whiteSpace: 'nowrap' }}><a href={`${Configs.TTT_Game_Service_IMG}${e.tp_path_name}`} onClick={() => { saveCountDownload(e.tp_id); }}><img src={iconsave} style={{ width: '15px', marginTop: '-5px' }} /></a>  {formatNumberToK(e.tp_total_download)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>)
     
     if (index === data.length - 1) {
      
for (let i = 0; i < (4-count); i++) {

  loop.push(<>
  <div className='col' style={{ padding: '0 20px' }}>
      </div>
  </>)
  
}
      
     }

      if (count === 4 || index === data.length - 1) {
        count = 0
        let setloop = loop 
        loop = []
          return (<>
            <div className='row' style={{ position: 'relative', width: '100%', marginBottom: `${index === data.length - 1 ? '0px' : '75px'}` }}>
              {setloop}
            </div>
          </>)
      /*   } */
    
      }
      count += 1

    })

    setTemplateListshow(looptemplate)
  }

  function getviewtemplate() {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/templateMgt/getTemplateByType/${templateType}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {
        if (response.data) {
          setloopcard(response.data)
          setTemplateList(response.data);

        }



      })
      .catch(function (error) {
        console.log(error);

      });
  }









  return (
    <>
{templateview === true ?
<div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/*   <div className='templateview'>
                  <div className='row' style={{ margin: 0, padding: '0 5px', height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',zIndex:'10' }}>
              <div className="pi-closePersonalInfo" onClick={() => {
           

           toggle_modal_templateview()
              }}>
                <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
              </div>
            </div>
            <div style={{position:'absolute',fontSize:'48px', fontFamily: 'Oxanium',color:'#ffffff',marginTop:'-20px'}}>SELECT YOUR SPACESHIP</div>
            <div style={{position:'absolute',fontSize:'21px', fontFamily: 'Oxanium',color:'#ffffff',marginTop:'40px'}}></div>
      
            
          </div> */}
           
      
      
    
      
        <div className="group-8">
        
            <img
              className="vector-66"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-309.svg"
            />
           { <img
              className="vector-67"
              alt="Vector"
              src={viewimg}
            />}
            <img
              className="vector-68"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-320.svg"
            />
            <img
              className="vector-69"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-312.svg"
            />
            <img
              className="vector-70"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-314.svg"
            />
            <img
              className="vector-71"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-319.svg"
            />
            <img
              className="vector-72"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-317.svg"
            />
            <img
              className="vector-73"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-318.svg"
            />
            <img
              className="vector-74"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-321.svg"
            
              onClick={() => {
                toggle_modal_templateview()
                   }}
            />
            <img
              className="vector-75"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-323.svg"
            />
            <div className="text-wrapper-19" 
            onClick={() => {
           toggle_modal_templateview()
              }}>CLOSE</div>
   
          <div className="overlap-9">
            <img
              className="vector-76"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-310.svg"
            />
            <img
              className="vector-77"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-311.svg"
            />
            <img
              className="vector-78"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-315.svg"
            />
            <img
              className="vector-79"
              alt="Vector"
              src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-313.svg"
            />
          </div>
        </div>
        <div className="software-requirement-2">{templatedeteil.tp_name}</div>
        <div className="the-quick-brown-fox-2" >
                    <div className='the-quick-brown-fox-2-textIn' style={{ wordWrap: 'break-word', width: '100%', fontFamily: 'Oxanium',overflowY:'auto',height:'450px' }}>{templatedeteil.tp_description} </div>
                  </div>
     
        <p className="create-date-april">CREATE DATE : {moment(templatedeteil.tp_created_date).format('DD MMMM YYYY')}</p>
        {/* <div className="rectangle-7" />
        <div className="rectangle-8" /> */}
        <img
          className="vector-80"
          alt="Vector"
          src="https://cdn.animaapp.com/projects/659621710b69ca79054f4fcd/releases/659621ce54d2cb6082c1f813/img/vector-322.svg"
        />
      </div>
  


:
<></>
}
      <div className='packageReport'>
        <svg className='packageReportLine' height="105" width="703">
          <line x1="2" y1="20" x2="25" y2="2" style={{ stroke: '#5E9BE1', strokeWidth: 3 }} />
          <line x1="25" y1="2" x2="120" y2="2" style={{ stroke: '#5E9BE1', strokeWidth: 3 }} />
          <line x1="119" y1="2" x2="119" y2="20" style={{ stroke: '#5E9BE1', strokeWidth: 3 }} />
          <line x1="119" y1="19" x2="295" y2="19" style={{ stroke: '#5E9BE1', strokeWidth: 3 }} />

          <line x1="2" y1="90" x2="320" y2="90" style={{ stroke: '#FFF', strokeWidth: 3 }} />

          <line x1="290" y1="97.5" x2="460" y2="97.5" style={{ stroke: '#5E9BE1', strokeWidth: 3 }} />

          <line x1="450" y1="90" x2="625" y2="90" style={{ stroke: '#FFF', strokeWidth: 3 }} />
          <line x1="624" y1="90" x2="635" y2="80" style={{ stroke: '#FFF', strokeWidth: 3 }} />
          <line x1="635" y1="81" x2="635" y2="55" style={{ stroke: '#FFF', strokeWidth: 3 }} />
        </svg>
        <div className='packageReportText' >PACKAGE <span style={{ color: '#FFF' }}>: REPORT</span></div>
        <div className='packageReportDownload'>
          <svg className='packageReportLine' height="45" width="160">
            <line x1="1" y1="10" x2="1" y2="1" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />
            <line x1="0" y1="1" x2="30" y2="1" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />

            <line x1="135" y1="1" x2="156" y2="1" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />
            <line x1="156" y1="0" x2="156" y2="13" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />

            <line x1="148" y1="35" x2="139" y2="42" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />
            <line x1="140" y1="42" x2="130" y2="42" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />

            <line x1="10" y1="42" x2="1" y2="42" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />
            <line x1="1" y1="43" x2="1" y2="33" style={{ stroke: '#5E9BE1', strokeWidth: 2 }} />
          </svg>
          <div className='packageReportDownloadBox'>
            <div className='packageReportDownloadBoxIn' onClick={gettemplate}>DOWNLOAD</div>
          </div>
        </div>
        <span style={{ color: '#FFF', fontSize: '20px', marginLeft: '850px', position: 'absolute', top: '50px' }}> ALL TEMPLATE</span>
        <div style={{ position: 'absolute', top: '30px', right: '3.5%', height: '50px', width: '50px',cursor:'pointer' }} onClick={() => { callback(template) }}>
          <svg height="50" width="50">
            <line x1="1" y1="1" x2="49" y2="1" style={{ stroke: '#5E9BE1', strokeWidth: 4 }} />
            <line x1="49" y1="0" x2="49" y2="36" style={{ stroke: '#5E9BE1', strokeWidth: 4 }} />
            <line x1="49" y1="35" x2="35" y2="49" style={{ stroke: '#5E9BE1', strokeWidth: 4 }} />
            <line x1="36" y1="49" x2="1" y2="49" style={{ stroke: '#5E9BE1', strokeWidth: 4 }} />
            <line x1="1" y1="49" x2="1" y2="1" style={{ stroke: '#5E9BE1', strokeWidth: 4 }} />
          </svg>
          <i class="fa fa-angle-double-left" aria-hidden="true" style={{fontSize:'40px', color: '#5E9BE1', position:'absolute',left: '12.5%', top: '10%'}}></i>
        </div>
      </div>





      {/* <div className='packageReport'>
                <svg className='packageReportLine' height="105" width="703">
                  <line x1="2" y1="20" x2="25" y2="2" style={{stroke: '#5E9BE1', strokeWidth: 3}} />
                  <line x1="25" y1="2" x2="175" y2="2" style={{stroke: '#5E9BE1', strokeWidth: 3}} />
                  <line x1="174" y1="2" x2="174" y2="20" style={{stroke: '#5E9BE1', strokeWidth: 3}} />
                  <line x1="174" y1="19" x2="335" y2="19" style={{stroke: '#5E9BE1', strokeWidth: 3}} />

                  <line x1="2" y1="90" x2="320" y2="90" style={{stroke: '#FFF', strokeWidth: 3}} />

                  <line x1="290" y1="97.5" x2="455" y2="97.5" style={{stroke: '#5E9BE1', strokeWidth: 3}} />

                  <line x1="450" y1="90" x2="645" y2="90" style={{stroke: '#FFF', strokeWidth: 3}} />
                  <line x1="644" y1="90" x2="655" y2="80" style={{stroke: '#FFF', strokeWidth: 3}} />
                  <line x1="655" y1="80" x2="655" y2="55" style={{stroke: '#FFF', strokeWidth: 3}} />
                </svg>
                <div className='packageReportText'>PACKAGE <span style={{color: '#FFF'}}>: REPORT</span></div>
            </div>
            <button    onClick={() => {gettemplate}} style={{position:'absolute',top:'200px',left:'45%',cursor:'pointer'}}>back</button>
            <button onClick={gettemplate} style={{position:'absolute',top:'200px',left:'45%',cursor:'pointer'}}>download</button>
 */}
      <div className='cardpackagebgnone'  >
        {templateListshow}
      </div>



      <CommentReport
        isOpen={commentReport}
        toggle_modal_commentReport={toggle_modal_commentReport}
        ModuleDetails={templateComment}
        getTmplateAllByType = {getTmplateAllByType}
        closeReportModal = {CloseCommentReport}
      />

      {/*       <div className='row' style={{ position: 'relative', width: '100%', marginBottom: '75px' }}>
        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>
      </div>
      <div className='row' style={{ position: 'relative', width: '100%', marginBottom: '75px' }}>
        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>
          <div className='postBoxLineTop'></div>
          <div className='postBox'>
            <div className='postBoxIn'>
              <div className='postLineInTop'></div>
              <div className='postLineInLeft'></div>
              <div className='postLineInBottom'></div>
            </div>
          </div>
          <div className='postBoxLineRight'></div>
          <div className='postBoxLineBottom'></div>
        </div>

        <div className='col' style={{ padding: '0 20px' }}>

        </div>

        <div className='col' style={{ padding: '0 20px' }}>

        </div>
      </div> */}
    </>
  )
}

export default Listtemplate;

/*  <CommentReport
 isOpen={commentReport}
 toggle_modal_commentReport = {toggle_modal_commentReport}
 ModuleDetails={templateComment}
 getTmplateAllByType = {getTmplateAllByType}
/>
 */