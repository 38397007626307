import React, { useState, useEffect } from 'react'
import './index.css';
import Configs from '../../../../config';
import axios from 'axios';
import {
  CloseOutlined,
  CalendarOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { Row, Col, DatePicker, Input, Table, ConfigProvider } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { TextArea } = Input;






const TimeSheetModal = ({ toggle_modal_time_sheet,
  project_id,
  project_name,
  process_id,
  module_id,
  process_name,
  module_name,
  menu_id,
  menu_name }) => {
  const [filterDataType, setFilterDateType] = useState('today');
  const [timeSheetData, setTimeSheetData] = useState({
    project_id: '',
    project_name: '',
    module_id: '',
    module_name: '',
    menu_id: '',
    menu_name: '',
    date_start: new Date(),
    date_end: new Date(),
    time_start: '',
    time_end: '',
    description: '',
    count_hour: 0,
    count_minute: 0
  })
  const [todayUse, setTodayUse] = useState({
    hours: 0,
    minute: 0
  });


  const [err_message, setErrorMessage] = useState('');
  const [err_message2, setErrorMessage2] = useState('');



  useEffect(() => {
    getCountTodayTimeSheet();
    /*    setTimeSheetData({...timeSheetData,
        project_id:project_id

       }) */
  }, [])


  function saveTimeSheet() {
    let temp = {
      project_id: project_id,
      project_name: project_name,
      menu_id: menu_id,
      process_id: process_id,

      date_start: timeSheetData.date_start,
      date_end: timeSheetData.date_end,
      count_hour: timeSheetData.count_hour,
      count_minute: timeSheetData.count_minute,
      description: timeSheetData.description.trim() || ''

    }
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/timeSheet/addTimeSheet`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        toggle_modal_time_sheet();
      })
      .catch(function (error) {
        console.log(error);

        setErrorMessage(error.response.data.error[0].errorDis)
      });
  }

  function getCountTodayTimeSheet() {

    axios({
      method: "get",
      url: `${Configs.TTT_Game_Service}/api/timeSheet/getCountTodayTimeSheet/${sessionStorage.getItem('user_id')}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },

    })
      .then(async function (response) {
        setTodayUse({ ...todayUse, hours: response.data.hours, minute: response.data.minute })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <div className='ModalTimesheet' /* style={{ display: 'block' }} */>
        <ConfigProvider
          theme={{
            token: {

              colorInfo: 'red',
              colorBorder: '#0047FF',
              colorText: '#FFF',

              colorTextDescription: '#FFFFFF',
              colorBgContainer: '#FFFFFF',
              zIndexPopupBase: 999999,
              colorPrimaryBg: '#FFFFFF',
              colorBgBase: '#001D5F',//พื้นหลัง datepicker

              colorTextQuaternary: 'White',//สีตัวอักษร

              colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

              colorTextBase: 'White', // สี scroll bar

              colorPrimaryBg: '#0047FF',// สีตอนเลือก
              /*       
                     colorBgLayout:'red',
                     colorFillQuaternary:'red', */


            }
          }}
        >

          <div className='headerTimeSheet'>
            <div className='headerTimeSheetBg'></div>
            <div className='headerTimeSheetText' style={{ fontFamily:  'Oxanium' }} >
              Time sheet
            </div>
            <div className='headerTimeSheetTimeCal'>
              Today : <font color="#0047FF">{todayUse.hours}</font> hr <font color="#0047FF">{todayUse.minute}</font> min  {"     "}  <CloseOutlined onClick={() => { toggle_modal_time_sheet(); }} style={{ cursor: 'pointer', marginLeft: '5px' }} />
            </div>
          </div>
          <div className='bodyTimeSheet'>
            <Row style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <CalendarOutlined style={{ fontSize: '20px' }}

              />
              <RangePicker id='dateRange' style={{ width: '220px', height: '32px' }} className='inputTimeSheet2' allowClear={false}
                format={'DD/MM/YYYY'}
                value={[timeSheetData.date_start ? dayjs(timeSheetData.date_start) : '', timeSheetData.date_end ? dayjs(timeSheetData.date_end) : '']}
                onChange={(date) => {
                  if (date && date.length == 2) {
                    setFilterDateType(null);
                    setTimeSheetData({ ...timeSheetData, date_start: new Date(date[0]), date_end: new Date(date[1]) })
                    /*      changeMenuValue('menu_start_stop_date', date, menu.menu_id); */

                  } else {
                    //console.log('date', date)
                    setTimeSheetData({ ...timeSheetData, date_start: null, date_end: null })
                  }
                }}
              />
              <div className={filterDataType == 'today' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                onClick={() => {
                  setFilterDateType('today');
                  setTimeSheetData({ ...timeSheetData, date_start: new Date(), date_end: new Date() });

                }}
              >
                Today
              </div>
              <div className={filterDataType == 'this_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                onClick={() => {
                  setFilterDateType('this_week');
                  let start = moment(new Date()).startOf('weeks').add(1, 'days');
                  let end = moment(new Date()).endOf('weeks').add(-1, 'days');
                  setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                }}
              >
                This Week
              </div>
              <div className={filterDataType == 'last_week' ? 'buttonTimeSheetActive' : 'buttonTimeSheet'}
                onClick={() => {
                  setFilterDateType('last_week')
                  let start = moment(new Date()).startOf('weeks').add(1, 'days').add(-1, 'weeks');
                  let end = moment(new Date()).endOf('weeks').add(-1, 'days').add(-1, 'weeks');
                  setTimeSheetData({ ...timeSheetData, date_start: start, date_end: end });
                }}
              >
                Last Week
              </div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <ClockCircleOutlined style={{ fontSize: '20px' }} />
              <RangePicker style={{ width: '220px', height: '32px' }} className='inputTimeSheet2'
                format={'HH:mm'}
                value={[timeSheetData.time_start ? dayjs(timeSheetData.time_start) : '', timeSheetData.time_end ? dayjs(timeSheetData.time_end) : '']}
                onChange={(date) => {
                  //console.log('date',date)
                  if (date && date.length == 2) {

                    let minute_all = moment(new Date(date[1])).diff(moment(new Date(date[0])), 'minutes');
                    let hours = Math.floor(minute_all / 60);
                    let minute = minute_all - (Math.floor(minute_all / 60)) * 60;
                    //console.log(hours,minute)
                    setTimeSheetData({ ...timeSheetData, count_hour: hours, count_minute: minute, time_start: new Date(date[0]), time_end: new Date(date[1]) });


                  }
                }}
                allowClear={false}
                picker="time"
              />

              OR
              <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center' }} className='inputTimeSheet2'
                value={timeSheetData.count_hour}
                onChange={(e) => {

                  if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 23) || e.target.value == '') {
                    setTimeSheetData({ ...timeSheetData, count_hour: e.target.value.trim(), time_start: null, time_end: null });
                    setErrorMessage('');

                  }
                }}
              />
              hrs.
              <Input style={{ width: '35px', padding: 0, height: '32px', textAlign: 'center' }} className='inputTimeSheet2'
                value={timeSheetData.count_minute}
                onChange={(e) => {
                  if ((!isNaN(e.target.value) && parseInt(e.target.value) <= 59) || e.target.value == '') {
                    setTimeSheetData({ ...timeSheetData, count_minute: e.target.value.trim(), time_start: null, time_end: null });
                    setErrorMessage('');
                  }
                }}
              />
              min
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Project : </div>
              <div className='value'>{project_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Process : </div>
              <div className='value'>{process_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Menu : </div>
              <div className='value'>{module_name} {`>`} {menu_name}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Date : </div>
              <div className='value'>{moment(timeSheetData.date_start).format('DD/MM/YYYY')} - {moment(timeSheetData.date_end).format('DD/MM/YYYY')}</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Time : </div>
              <div className='value'><font color="#0047FF">{timeSheetData.count_hour || 0}</font> hr <font color="#0047FF">{timeSheetData.count_minute || 0}</font> min</div>
            </Row>

            <Row style={{ display: 'flex', gap: '5px', marginTop: "5px", alignItems: 'center' }}>
              <div className='subject'>Description : </div>
              <div className='value'>

              </div>
            </Row>
            <Row>
              <TextArea className='inputTimeSheet' autoSize={{
                minRows: 2,

              }}
              style={{ maxHeight:'230px'}}
                value={timeSheetData.description}
                onChange={(e) => {
                  setErrorMessage2('');
                  setTimeSheetData({ ...timeSheetData, description: e.target.value });
                }}
              />
            </Row>

          </div>

          <div className='footerTimeSheet'>
            <div className='btnSave'
              onClick={() => {
                let error = 0;

                if (timeSheetData.count_hour == 0 && timeSheetData.count_minute == 0) {
                  setErrorMessage("Please enter effort in hours or minute!");
                  error++;
                }
                if (timeSheetData.description.trim() == '') {
                  setErrorMessage2("Please enter description!");
                  error++;
                }
                if (error == 0) {
                  saveTimeSheet();
                }

                //toggle_modal_time_sheet();
              }}
            >Save</div>
            <div className='errorSave' style={{display: `${err_message != '' || err_message2 != '' ? 'flex': 'none'}`}}>
            <font color="red" style={{display: `${err_message != '' ? 'block': 'none'}`}}>{err_message}</font>
            <font color="red" style={{display: `${err_message2 != '' ? 'block': 'none'}`}}>{err_message2}</font>
            </div>
          </div>


        </ConfigProvider>
      </div>
    </>
  )
}

export default TimeSheetModal
