import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';

/* import Select from 'react-select' */
import moment from "moment";
import axios from "axios";
import './tabSrs.css'
import Configs from "../../../../config";
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
import { Card, Switch, Tag, Tooltip, Button, Checkbox, TreeSelect, Input } from 'antd';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
    UploadOutlined
} from '@ant-design/icons';
import ProjectState from '../../../LandingPagestage/ProJectState/projectstate';
import changereport from "../../../component/Report/changereport";
import { NumericFormat, PatternFormat } from 'react-number-format';
const { TextArea } = Input;


function TabChange({ callbackChangeSpecificField, changeSpecificFieldValue, userismode, project_id }) {

    const [test, settest] = useState({ test: '' })


    const [specificField, setSpecificField] = useState([]);
    const [loading, setLoading] = useState(false);


    const [changeList, setChangeList] = useState([])
    const [changeListTemp, setchangeListTemp] = useState([]);
    const [isDisabled, setIsDisabled] = useState(changeListTemp.length === 0);

    const [changeSelectList, setChangeSelectList] = useState([]);


    const [processList, setProcessList] = useState([]);

    const [processAffectSelect, setProcessAffectSelect] = useState([]);

    const [displaySwal, setDisplaySwal] = useState(false);
    const toggle_modal_confrimModal = () => setDisplaySwal(!displaySwal);

    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);
    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [messageBoxManDay, setMessageBoxBoxManDay] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [alertKickMassgeManDay, setAlertKickMassgeManDay] = useState(false);
    const toggle_modal_alertKickMassgeManDay = () => setAlertKickMassgeManDay(!alertKickMassgeManDay);
    const [searchchange, setsearchchange] = useState({input:'',hisinput:'' })

    function selectChange(target, targer_parent) {
        console.log(target, targer_parent)
        let tempSelect = changeSelectList;
        let temp = changeList;
        let check = tempSelect.filter((e) => { return e.sm_id == target.sm_id });
        if (check.length == 0) {

            tempSelect.push(target);
        } else {
            let index = tempSelect.findIndex((e) => e.sm_id == target.sm_id);
            if (index > -1) {
                tempSelect.splice(index, 1);
            }
        }


        setChangeSelectList(tempSelect);
        settest({ ...test, test: '' });
        test.test = '';
    }

    

    function selectAffectProcess(target) {
        console.log(target)
        let tempSelect = processAffectSelect;

        let check = tempSelect.filter((e) => { return e.process_id == target.process_id && e.sm_menu_id == target.sm_menu_id });
        if (check.length == 0) {

            tempSelect.push(target);
        } else {
            let index = tempSelect.findIndex((e) => e.process_id == target.process_id && e.sm_menu_id == target.sm_menu_id);
            if (index > -1) {
                tempSelect.splice(index, 1);
            }
        }


        setProcessAffectSelect(tempSelect);
        settest({ ...test, test: '' });
        test.test = '';
    }


    async function getModuleMenuChangeList() {
        //let project_id = "47fd3842-adfc-490e-aa1f-cbda3ba4d636";
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getChangeListByProject/${project_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {
                console.log(response.data);
                setChangeList(response.data);


            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function getProjectDetail(id) {
        //let project_id = "47fd3842-adfc-490e-aa1f-cbda3ba4d636";
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/project/GetProjectDetail/${project_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            /* data: temp, */
        })
            .then(async function (response) {
console.log(response.data);
for (let index of response.data.moduleList) {
  
    let sortedData = sortMenu(index.module_menu_list);
    index.module_menu_list = sortedData
  for (let i of index.module_menu_list) {
    if (i.menu_level === null) {
      i.menu_level = 1
    } else {
      i.menu_level = parseInt(i.menu_level)
    }
    i.menu_sort = parseInt(i.menu_sort)

  }

}




                setProcessList(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }




    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    async function getDocumentTemplate(srs_value) {
        console.log(srs_value)
        let id = "6f2b2dc6-efa4-4f43-a766-44b66f9b8696"; //id srs document specifig
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {

                if (response.data) {
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                /* let check = srs_value.map((e)=>{return e.dt_input_data.data.map((e2)=>{return e2.value_list.map((e3)=>{return e3.value_id == item3.value_id})})})
                                console.log('check',check) */
                                for (let item_ of srs_value) {
                                    for (let item2_ of item_.dt_input_data.data) {
                                        for (let item3_ of item2_.value_list) {

                                            if (item3.value_id == item3_.value_id) {
                                                if (item3.table_column_list && item3_.table_column_list) {
                                                    item3.table_column_list = item3_.table_column_list;
                                                }

                                                if (item3_.value_input) {
                                                    item3.value_input = item3_.value_input || '';
                                                }
                                                if (item3_.data_list) {
                                                    item3.data_list = item3_.data_list || [];
                                                }
                                                if (item3_.value_input_header) {
                                                    item3.value_input_header = item3_.value_input_header || '';
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {

                                if (item3.value_type == 'table_column') {
                                    let temp_in = {
                                        value_input_table: [],
                                    }
                                    for (let item of item3.table_column_list) {
                                        temp_in.value_input_table.push('');
                                    }
                                    if (!item3.data_list) {
                                        item3.data_list = [];
                                    }
                                    if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                                        item3.data_list = [];
                                        console.log('หัวไม่เท่ากับ value')
                                    }
                                    if (item3.data_list.length == 0 && item3.is_multiple == false) {
                                        item3.data_list.push(temp_in);
                                    }

                                }
                            }
                        }
                    }
                    setSpecificField(response.data);
                    callbackChangeSpecificField(response.data);
                    settest({ ...test, test: '' });
                    test.test = '';
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

               /*  if (callback) {
                    callback(response.data.tokenBox);
                } */
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }



    useEffect(() => {
        GetAccesstokenToken();

        getDocumentTemplate(/* changeSpecificFieldValue || [] */[]);

        getProjectDetail();
        getAllChangeDocument();
    }, [changeSpecificFieldValue]);

    useEffect(() => {
        setIsDisabled(changeListTemp.length === 0);
    }, [changeListTemp]);

    const [textChange, setTextChange] = useState({ text: '' });

    function addValueMultiple(index, index2, index3, type) {
        console.log(index, index2, index3)
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1) {
            if (type == 'table_column') {
                let temp_in = {
                    value_input_table: [],
                }
                for (let item of temp[index].dt_input_data.data[index2].value_list[index3].table_column_list) {
                    temp_in.value_input_table.push('');
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            } else {
                let temp_in = {
                    value_input: '',
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            }



            setSpecificField(temp);
            callbackChangeSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }


    }


    function removeValueMultiple(index, index2, index3, index4) {
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1 && index4 > -1) {
            temp[index].dt_input_data.data[index2].value_list[index3].data_list.splice(index4, 1);
            setSpecificField(temp);
            callbackChangeSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }


    function changeValueInJson(key, value, index, indexGroup, indexValue, index_, index_column) {
        console.log(key, value, index, indexGroup, indexValue, index_)

        let tempData = specificField || [];
        //console.log( tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`])
        setTextChange({ ...textChange, text: value });
        textChange.text = value;
        console.log('textChange.text', textChange.text)
        /* if (key == 'value' || key == 'value_type' || key == 'is_multiple') {
    
          tempData[indexGroup]['value_list'][indexValue][key] = textChange.text;
        } else { */
        if (key == 'value_input_table') {
            console.log(tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_])
            tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column] = textChange.text;
        } else {
            if (index_ > -1) {
                console.log("in", index_)
                if (key == 'value_check_box') {
                    tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].table_column_list[index_][`${key}`] = textChange.text;
                } else {
                    tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`] = textChange.text;
                }

            } else {
                console.log("out")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue][`${key}`] = textChange.text;
            }
        }


        /*   } */
        console.log('tempData', tempData)
        setSpecificField(tempData);
        callbackChangeSpecificField(tempData);
        settest({ ...test, test: '' });
        test.test = '';

    }

    const [versionId, setVersionId] = useState(null);
    const [pageType, setPageType] = useState('table');
    const [pageMode, setPageMode] = useState('add');
    const [changeDocumentList, setChangeDocumentList] = useState([]);


    async function saveChangeDocument() {
        //console.log('รายการ Change', changeSelectList);
        //console.log('กระทบ Process', processAffectSelect);
        const error_list = [];

        if (changeSelectList.length == 0) {
            let temp_err = {
                message: "At least 1 change item must be selected."
            }
            error_list.push(temp_err);
        }

        /*    
           console.log('error_list',error_list);
           console.log('tempSrsSave',tempSrsSave); */

        if (error_list.length > 0) {

            var err_message = [];
            for (var e = 0; e < error_list.length; e++) {
                err_message.push(error_list[e].message);
            }
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })
            setDisplaySwal(false)
            setAlertKickMassge(true)
        } else {
            if (pageMode == 'add') {
                let temp = {
                    project_id: project_id,
                    specificField: specificField,
                    changeSelectList: changeSelectList,
                    processAffectSelect: processAffectSelect
                }
                await axios({
                    method: "POST",
                    url: `${Configs.TTT_Game_Service}/api/documentTemplate/addChangeDocument`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        "X-TTT": `${Configs.API_TTT}`,
                        "Content-Type": "application/json",
                    },
                    data: temp
                })
                    .then(async function (response) {
                        if (response.data) {
                            setMessageBox({
                                ...messageBox,
                                message: 'success',
                                isError: false,
                                menuTitle: 'Save'
                            })
                            setDisplaySwal(false)
                            toggle_modal_alertKickMassge();
                            setPageType('table');
                            settest({ ...test, test: '' });
                            test.test = '';

                        }


                    })
                    .catch(function (error) {
                        console.log(error);

                    });
            } else if (pageMode == 'edit') {
                let temp = {
                    cv_id: versionId,
                    project_id: project_id,
                    specificField: specificField,
                    changeSelectList: changeSelectList,
                    processAffectSelect: processAffectSelect
                }
                await axios({
                    method: "POST",
                    url: `${Configs.TTT_Game_Service}/api/documentTemplate/editChangeDocument`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        "X-TTT": `${Configs.API_TTT}`,
                        "Content-Type": "application/json",
                    },
                    data: temp
                })
                    .then(async function (response) {
                        if (response.data) {
                            setMessageBox({
                                ...messageBox,
                                message: 'success',
                                isError: false,
                                menuTitle: 'Save'
                            })
                            setDisplaySwal(false)
                            toggle_modal_alertKickMassge();
                            setPageType('table');
                            settest({ ...test, test: '' });
                            test.test = '';
                            /*     getAllChangeDocument(); */
                        }


                    })
                    .catch(function (error) {
                        console.log(error);

                    });
            }

        }




    }

    async function getAllChangeDocument() {
        //console.log('รายการ Change', changeSelectList);
        //console.log('กระทบ Process', processAffectSelect);

        let temp = {
            project_id: project_id,
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getAllChangeDocument`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                setChangeDocumentList(response.data);


            })
            .catch(function (error) {
                console.log(error);
            });


    }
    async function getChangeDocument(id) {
        //console.log('รายการ Change', changeSelectList);
        //console.log('กระทบ Process', processAffectSelect);


        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getChangeDocument/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {
                setVersionId(response.data.cv_id);
                setChangeSelectList(response.data.cv_value_change.data);
                /*     if(pageMode != 'add'){
                        let temp = changeList;
                        for(let item of response.data.cv_value_change.data){
                            let check = temp.filter((e)=>{return e.sm_id == item.sm_id});
                            if(check.length == 0){
                                temp.push(item);
                            }
                        }
                        console.log('temp',temp)
                        setChangeList(temp);
                    } */
                setProcessAffectSelect(response.data.cv_affect_value.data);
                setSpecificField(response.data.cv_dynamic_value.data)


            })
            .catch(function (error) {
                console.log(error);
            });


    }
    async function approveChangeDocument(doc_id, smIdsArray) {
        let temp = {
            cv_id: doc_id,
            smIdsArray: smIdsArray
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/approveChangeDocument`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge();
                    setPageType('table');
                    settest({ ...test, test: '' });
                    test.test = '';
                    /*     getAllChangeDocument(); */
                }


            })
            .catch(function (error) {
                console.log(error);

            });
    }

    async function EditManDay() {
        // setLoading(true);
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/editManDay`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: changeList

        })
            .then(async function (response) {
                // console.log("editManDay: ", response.data);
                getModuleMenuChangeList();
                setchangeListTemp([]);
                // setLoading(false);
                setMessageBoxBoxManDay({
                    ...messageBox,
                    message: 'success',
                    isError: false,
                    menuTitle: 'Save'
                })
                toggle_modal_alertKickMassgeManDay();
            })
            .catch(function (error) {
                console.log(error);
            });


    }



    async function getreportChangeDocument(cv_id, e) {
        let sm_id = null;
        if (e.cv_value_change.data.length > 0) {
            sm_id = e.cv_value_change.data.map(item => item.sm_id);
        }
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/report/reportChange`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: {
                cv_id: cv_id,
                sm_id: sm_id
            }

        })
            .then(async function (response) {
                if (response) {
                    changereport(response.data)
                }

                /*  setVersionId(response.data.cv_id);
                 setChangeSelectList(response.data.cv_value_change.data);
             
                 setProcessAffectSelect(response.data.cv_affect_value.data);
                 setSpecificField(response.data.cv_dynamic_value.data) */


            })
            .catch(function (error) {
                console.log(error);
            });


    }

    async function saveDocment(fileList, ref_id, ul_file_path_old) {
        if (fileList.length > 0) {
            const dataImg = new FormData();
            for (let i = 0; i < fileList.length; i++) {
                dataImg.append('Profile', fileList[i].image_file);
            }
            dataImg.append('typeFile', "changeRequest");
            dataImg.append('projectKey', "TTTSPACESHIP");

            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: dataImg,
            })
                .then(async function (response) {
                    let ole_file = [];
                    if (response.data.data.length > 0) {
                        response.data.data.forEach((e) => {
                            const temp = {
                                name: e.orgin_name,
                                path: e.path,
                                is_save: true,
                                ul_is_use: true,
                                ul_file_size: e.size,
                                url: e.path,
                                ul_type: "changeRequest",
                                ul_group_name: "changeRequest",
                                ul_file_name: e.orgin_name,
                                ul_file_path: e.path,
                                ul_menu_name: "changeRequest",
                                ul_is_profile: true,
                                status: 'done',
                                ref_id: ref_id,
                                old_ul_file_path: ul_file_path_old
                            }
                            ole_file.push(temp);
                        })
                        saveUpload(ole_file)
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    async function saveUpload(file) {
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/upload/addFileUploadDelold`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: file
        })
            .then(async function (response) {
                getAllChangeDocument();
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function sortMenu(data) {
        const map = new Map();
    
        data.forEach(item => {
          if (!map.has(item.menu_parent_id)) {
            map.set(item.menu_parent_id, []);
          }
          map.get(item.menu_parent_id).push(item);
        });
    
        function recursiveSort(parentId) {
          let items = map.get(parentId) || [];
    
          items.sort((a, b) => {
            let levelA = parseInt(a.menu_level);
            let levelB = parseInt(b.menu_level);
            let sortA = parseInt(a.menu_sort);
            let sortB = parseInt(b.menu_sort);
    
            if (levelA !== levelB) {
              return levelA - levelB;
            } else {
              return sortA - sortB;
            }
          });
    
          let result = [];
          items.forEach(item => {
            result.push(item);
            result = result.concat(recursiveSort(item.menu_id));
          });
    
          return result;
        }
    
        return recursiveSort(null);
      }


      async function CheckAndCreateFolderBox(project_id, folder_name, fb_id, fileList) {
        let temp = {
            folderLis: ["PROJECT", `PROJECT_${project_id}`, "PROJECT DOCUMENTS","CHANG REQUEST"]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    /* if (callback) { */
                        let res = {
                            ref_id: fb_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                      UploadFiles(folder_id,folder_name,fb_id,fileList)
                        /* callback(res) */
                   /*  } */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function UploadFiles(folder_id, key, ref_id, file) {
        console.log('เข้าใหม่',file);
        let image_id_list = /* fileUploadList */ [];
        let data_ = new FormData();

     /*    file.forEach((item, index) => {
                if (item.image_file) {
                    data_.append('File', item.image_file);
                }
            
        }); */
        for (let i = 0; i < file.length; i++) {
            data_.append('File', file[i].image_file);
        }

        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);
        data_.append('folder_id', folder_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp =  [];
             /*    for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp); */
                getAllChangeDocument();
                setLoading(false);
                settest({ ...test, test: '' });
                test.test = '';
              
            })
            .catch(function (error) {
                setLoading(false);
                console.log(error);
            });
    }



    const downloadFile = async (url, filename) => {
        console.log(Configs.TTT_Game_Service_IMG + url);
        try {
            const response2 = await axios.get(`https://api.box.com/2.0/files/${url}/content`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
            });
            const urlBlob = window.URL.createObjectURL(response2.data);
            const link = document.createElement('a');
            link.href = urlBlob;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(urlBlob);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error downloading file', error);
        }
    };



    return (
        <>
            <div className="box-content-evens2">
                <div className="row delpm">
                    <div className="col-12">
                        <div className="box-content-evens1" style={{ height: '550px' }}>
                            {pageType == 'table' && (<>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <span style={{ fontSize: '24px' }}>Change Document List</span>
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <button
                                                        className="btnAddSpecific ml-2" style={{ height: '32px' }}
                                                        onClick={() => {
                                                            // setSpecificField([]);
                                                            /*        setChangeSelectList([]);
                                                                   setProcessAffectSelect([]);
                                                                   getDocumentTemplate([]);
                                                                   getModuleMenuChangeList();
                                                                   setPageMode('add');
                                                                   setPageType('mgt'); */
                                                        }}
                                                    ><i class="fa fa-plus" aria-hidden="true"></i> Quotation</button>

                                                    <button
                                                        className="btnAddSpecific ml-2" style={{ height: '32px' }}
                                                        onClick={() => {
                                                            // setSpecificField([]);
                                                            setChangeSelectList([]);
                                                            setProcessAffectSelect([]);
                                                            getDocumentTemplate([]);
                                                            getModuleMenuChangeList();
                                                            setPageMode('add');
                                                            setPageType('mgt');
                                                        }}
                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD</button>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="boxSpecific" >
                                            <div className="boxSpecificIn" style={{ minHeight: '350px' }}>
                                                <div className="row" style={{ backgroundColor: '#0047FF', margin: '10px', padding: 0 }}>
                                                    <div className='col-1' style={{ textAlign: 'center', padding: 0 }}>
                                                        No
                                                    </div>
                                                    <div className='col-1' style={{ textAlign: 'center', padding: 0 }}>
                                                        Change No
                                                    </div>
                                                    <div className='col-2' style={{ textAlign: 'center', padding: 0 }}>
                                                        Date
                                                    </div>

                                                    <div className='col-2' style={{ textAlign: 'center', padding: 0 }}>
                                                        Status
                                                    </div>
                                                    <div className='col-2' style={{ textAlign: 'center', padding: 0 }}>
                                                        Approve Date
                                                    </div>
                                                    <div className='col-2' style={{ textAlign: 'center', padding: 0 }}>
                                                        Upload
                                                    </div>
                                                    <div className='col-2' style={{ textAlign: 'center', padding: 0 }}>
                                                        Management
                                                    </div>
                                                </div>

                                                {changeDocumentList.map((e, index) => {
                                                    return (<>
                                                        <div className='row' style={{ margin: '10px', padding: 0 }}>
                                                            <div className='col-1' style={{ textAlign: 'center' }}>
                                                                {index + 1}
                                                            </div>
                                                            <div className='col-1' style={{ textAlign: 'center' }}>
                                                                {e.cv_change_no}
                                                            </div>
                                                            <div className='col-2' style={{ textAlign: 'center' }}>
                                                                {moment(e.cv_created_date).format('DD/MM/YYYY HH:mm')}
                                                            </div>

                                                            <div className='col-2' style={{ textAlign: 'center' }}>
                                                                {e.scv_status_name == 'approve' ? <Tag color="#87d068" icon={<CheckCircleOutlined />}>{e.scv_status_name}</Tag> : <Tag icon={<SyncOutlined spin />} color="#d46b08">{e.scv_status_name}</Tag>}


                                                            </div>
                                                            <div className='col-2' style={{ textAlign: 'center' }}>
                                                                {e.cv_approve_date && moment(e.cv_approve_date).format('DD/MM/YYYY HH:mm')}
                                                            </div>
                                                            <div className='col-2' style={{ textAlign: 'center'/* , whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: 'blue', textDecoration: 'underline', cursor: 'pointer' */ }}>
                                                                <a style={{color:'#1696e7',outline: 'medium',cursor:'pointer'}}
                                                                onClick={()=>{
                                                                    setLoading(true);
                                                                    downloadFile(e.ul_file_path,e.ul_file_name)
                                                                }}
                                                                >{e.ul_file_name}</a>
                                                            </div>
                                                            <div className='col-2' style={{ textAlign: 'center', display: 'flex', gap: '15px', justifyContent: 'center' }}>
                                                                <i class="fa fa-eye" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getChangeDocument(e.cv_id);
                                                                        getModuleMenuChangeList();
                                                                        setPageType('mgt');
                                                                        setPageMode('view');
                                                                    }}
                                                                />
                                                                {e.scv_status_name == 'wait for approve' ? (<><i class="fa fa-pen" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        getChangeDocument(e.cv_id);
                                                                        getModuleMenuChangeList();
                                                                        setPageType('mgt');
                                                                        setPageMode('edit');
                                                                    }}
                                                                />
                                                                    {e.ul_file_path && <i class="fa fa-check-circle" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            //toggle_modal_confrimModal();
                                                                            
                                                                            const smIdsArray = e.cv_value_change.data.map(item => item.sm_id);
                                                                            approveChangeDocument(e.cv_id, smIdsArray);

                                                                        }}
                                                                    />}
                                                                    <i class="fa fa fa-file" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            getreportChangeDocument(e.cv_id, e)
                                                                            /* handleDownloadPdf() */
                                                                            //toggle_modal_confrimModal();
                                                                            /*   approveChangeDocument(e.cv_id); */

                                                                        }}
                                                                    />
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                                        <label for={"uploadChangeReq" + e.cv_id} type='button'><UploadOutlined style={{ fontSize: '16px', color: '#FFF' }} /></label>
                                                                        <input id={"uploadChangeReq" + e.cv_id} hidden type="file"
                                                                            accept=".png, .jpg, .jpeg, .pdf, .doc, .xls, .xlsx, .pptm, .svg"
                                                                            onChange={(el) => {
                                                                                setLoading(true);
                                                                                var list = [{
                                                                                    ul_file_path: URL.createObjectURL(el.target.files[0]),
                                                                                    ul_file_name: el.target.files[0].name,
                                                                                    name: el.target.files[0].name,
                                                                                    path: URL.createObjectURL(el.target.files[0]),
                                                                                    url: URL.createObjectURL(el.target.files[0]),
                                                                                    is_save: true,
                                                                                    ul_is_use: true,
                                                                                    ul_file_size: 0,
                                                                                    ul_type: "file",
                                                                                    ul_group_name: "changeRequest",
                                                                                    ul_menu_name: "changeRequest",
                                                                                    ul_is_profile: true,
                                                                                    status: 'done',
                                                                                    image_file: el.target.files[0],
                                                                                    is_img_upload: true
                                                                                }]
                                                                                CheckAndCreateFolderBox(project_id,"CHANG REQUEST",e.cv_id,list)
                                                                               /*  saveDocment(list, e.cv_id, e.ul_file_path); */
                                                                                // setProfileImg(list)
                                                                                // settest({ ...test, test: '' })
                                                                                // test.test = ''


                                                                            }}
                                                                        />
                                                                    </div>
                                                                </>)
                                                                    : (<>
                                                                        <i class="fa fa fa-file" aria-hidden="true" style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                getreportChangeDocument(e.cv_id, e)
                                                                                /* handleDownloadPdf() */
                                                                                //toggle_modal_confrimModal();
                                                                                /*   approveChangeDocument(e.cv_id); */

                                                                            }}
                                                                        ></i>



                                                                    </>)}

                                                                {/*     <button
                                                                className="btnAddSpecific ml-2" style={{ height: '32px',backgroundColor:'#87d068' }}
                                                                onClick={() => {
                                                                    //toggle_modal_confrimModal();
                                                                    approveChangeDocument();
                                                               
                                                                }}
                                                            >APPROVE</button> */}
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}



                                            </div></div>
                                    </div></div>
                            </>)}
                            {pageType == 'mgt' && (<>
                                <div className="row">
                                    <div className="col-12">

                                        {specificField && specificField.map((e, index) => {
                                            //console.log(0)
                                            return (<>

                                                <div className="row">
                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span style={{ fontSize: '24px' }}>{e.dt_name}</span>
                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            {/*    {pageMode == 'edit' && (<>
                                                                <button
                                                                className="btnAddSpecific ml-2" style={{ height: '32px',backgroundColor:'#87d068' }}
                                                                onClick={() => {
                                                                    //toggle_modal_confrimModal();
                                                                    approveChangeDocument();
                                                               
                                                                }}
                                                            >APPROVE</button>
                                                            </>)} */}
                                                            {pageMode != 'view' &&
                                                                <button
                                                                    className="btnAddSpecific ml-2" style={{ height: '32px' }}
                                                                    onClick={() => {
                                                                        toggle_modal_confrimModal();
                                                                        //saveChangeDocument();

                                                                    }}
                                                                >SAVE</button>}
                                                            <button
                                                                className="btnAddSpecific ml-2" style={{ height: '32px' }}
                                                                onClick={() => {
                                                                    setsearchchange({...searchchange,input:'',hisinput:''})
                                                                    searchchange.input = ''
                                                                    searchchange.hisinput = ''
                                                                    setPageType('table');
                                                                }}
                                                            >BACK</button>

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="boxSpecific" >
                                                    <div className="boxSpecificIn" >
                                                        {e.dt_input_data.data.length == 0 && (<><font color="red">No specific</font></>)}
                                                        {e.dt_input_data.data.map((e2, index2) => {

                                                            return (<>
                                                                {index2 > 0 && (<br />)}
                                                                <div className="row mt-2">
                                                                    <div className="col-12"><Tooltip color='#051F6C' placement="right" title={e2.suggestion}>
                                                                        <span style={{ fontSize: '18px' }}>{index2 + 1}.{e2.group_name}</span>
                                                                    </Tooltip></div>

                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    {e2.value_list.length == 0 && (<> <div className="col-6"><font color="red">No value</font></div></>)}
                                                                    {e2.value_list.map((e3, index3) => {

                                                                        return (<>
                                                                            <div className="col-12">
                                                                                <div className="mb-2 mt-2">{index2 + 1}.{index3 + 1}. {e3.value}
                                                                                    {/* {userismode === false ? (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px' }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD</button>)}</>) : <></>} */}
                                                                                </div>

                                                                                {e3.is_multiple ? (<>
                                                                                    {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                        return (<>
                                                                                            {e3.value_type == 'table' && (<>
                                                                                                {e3.is_border == true ? <table >
                                                                                                    <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            placeholder='INPUT YOUR HEADER...'
                                                                                                            type='text'
                                                                                                            value={e4.value_input_header}
                                                                                                            disabled={pageMode == 'view'}
                                                                                                            onChange={(v) => {
                                                                                                                console.log(v.target.value);
                                                                                                                changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                            }}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                            type='text'
                                                                                                            value={e4.value_input}
                                                                                                            disabled={pageMode == 'view'}
                                                                                                            onChange={(v) => {
                                                                                                                //console.log(v.target.value);
                                                                                                                changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                            }}
                                                                                                        />


                                                                                                    </td>
                                                                                                    {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed ml-2'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                </table> :

                                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>

                                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                                            style={{ borderRadius: '0px', width: '30%' }}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            placeholder='INPUT YOUR HEADER...'
                                                                                                            type='text'
                                                                                                            value={e4.value_input_header}
                                                                                                            disabled={pageMode == 'view'}
                                                                                                            onChange={(v) => {
                                                                                                                console.log(v.target.value);
                                                                                                                changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                            }}
                                                                                                        />



                                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                            type='text'
                                                                                                            value={e4.value_input}
                                                                                                            disabled={pageMode == 'view'}
                                                                                                            onChange={(v) => {
                                                                                                                //console.log(v.target.value);
                                                                                                                changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                            }}
                                                                                                        />
                                                                                                        {userismode === false ? (
                                                                                                            <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                                className='btnRed'
                                                                                                                onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                            ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                    </div>
                                                                                                }


                                                                                            </>)}
                                                                                            {e3.value_type == 'description' && (<>


                                                                                                <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        type='text'
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={pageMode == 'view'}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log("test", v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                    {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                </div>
                                                                                            </>)}




                                                                                        </>);

                                                                                    })}

                                                                                    {e3.value_type == 'table_column' && (<>
                                                                                        <div style={{ display: 'flex', gap: '5%' }}>
                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    {e3.table_column_list.map((e4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                        </>)
                                                                                                    })}
                                                                                                </tr>

                                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                    return (<>
                                                                                                        <tr>
                                                                                                            {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                                return (<>
                                                                                                                    <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={e5}
                                                                                                                            disabled={pageMode == 'view'}
                                                                                                                            onChange={(v) => {
                                                                                                                                //console.log("test", v.target.value);
                                                                                                                                changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </>)
                                                                                                            })}
                                                                                                            <td>
                                                                                                                {userismode === false ? (
                                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                                                                                        className='btnRed'
                                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>)

                                                                                                })}

                                                                                            </table>

                                                                                        </div>
                                                                                    </>)}

                                                                                </>) : (<>

                                                                                    {e3.value_type == 'table' && (<>

                                                                                        {e3.is_border == true ?
                                                                                            <table >
                                                                                                <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px', width: '100%' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        type='text'
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        value={e3.value_input_header}
                                                                                                        disabled={pageMode == 'view'}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                        }}

                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        type='text'
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        value={e3.value_input}
                                                                                                        disabled={pageMode == 'view'}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                        }}

                                                                                                    />
                                                                                                </td>

                                                                                            </table> : <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px', width: '30%' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR HEADER...'
                                                                                                    value={e3.value_input_header}
                                                                                                    disabled={pageMode == 'view'}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e3.value_input}
                                                                                                    disabled={pageMode == 'view'}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </div>}

                                                                                    </>)}

                                                                                    {e3.value_type == 'description' && (<>
                                                                                        <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                value={e3.value_input}
                                                                                                disabled={pageMode == 'view'}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </>)}

                                                                                    {e3.value_type == 'table_column' && (<>
                                                                                        <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                            {e3.value_type == 'table_column' && (<>

                                                                                                <table style={{ width: '100%' }}>
                                                                                                    <tr>
                                                                                                        {e3.table_column_list.map((e4) => {
                                                                                                            return (<>
                                                                                                                <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                            </>)
                                                                                                        })}
                                                                                                    </tr>

                                                                                                    {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                        return (<>
                                                                                                            <tr>
                                                                                                                {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                                    return (<>
                                                                                                                        <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                            <TextArea
                                                                                                                                className="areaTEst rezieznone"
                                                                                                                                style={{ borderRadius: '0px' }}
                                                                                                                                autoSize={{
                                                                                                                                    minRows: 1
                                                                                                                                }}
                                                                                                                                type='text'
                                                                                                                                placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                                value={e5}
                                                                                                                                disabled={pageMode == 'view'}
                                                                                                                                onChange={(v) => {
                                                                                                                                    //console.log("test", v.target.value);
                                                                                                                                    changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </td>
                                                                                                                    </>)
                                                                                                                })}

                                                                                                            </tr>
                                                                                                        </>)

                                                                                                    })}
                                                                                                    {/* {e3.data_list.map((e4, index4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', padding: '1%' }}>
                                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                                    autoSize={{
                                                                                                                        minRows: 1
                                                                                                                    }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={e4}

                                                                                                                    onChange={(v) => {
                                                                                                                        //console.log("test", v.target.value);
                                                                                                                        changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </>)
                                                                                                    })} */}

                                                                                                </table>


                                                                                            </>)}

                                                                                        </div>
                                                                                    </>)}



                                                                                    {e3.value_type == 'table_checkbox' && (<>

                                                                                        {e3.table_column_list.map((e4, index4) => {
                                                                                            return (<>
                                                                                                <Checkbox
                                                                                                    checked={e4.value_check_box}
                                                                                                    disabled={pageMode == 'view'}
                                                                                                    onChange={(v) => {
                                                                                                        //console.log(v)
                                                                                                        changeValueInJson(`value_check_box`, v.target.checked, index, index2, index3, index4);
                                                                                                    }}
                                                                                                > {e4.header}</Checkbox>

                                                                                            </>)

                                                                                        })}
                                                                                    </>)}
















                                                                                </>)}
                                                                                {userismode === false ? (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px', marginBottom: '1.6rem', }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD</button>)}</>) : <></>}

                                                                            </div>
                                                                        </>)
                                                                    })}

                                                                </div>

                                                            </>)
                                                        })}
                                                    </div></div>
                                                <hr />
                                            </>);
                                        })}


                                        <div className="row">
                                            <div className="col-12">
                                                <span style={{ fontSize: '24px' }}>Change List (เลือกแล้ว {changeSelectList.length})</span>
                                            </div>

                                        </div>
                                        <div className="boxSpecific" >
                                            <div className="boxSpecificIn" >
                                                <div className="row" style={{display: 'flex',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}} >
                                                <div style={{ margin: '0', fontSize: '25px', fontWeight: 700,width:'30%' }}>CHANGE LIST
                                                </div>
                                        
                                                <div style={{ display: 'flex', gap: '%'/* , marginBottom: '1%' */ ,width:'40%'}}>
                                                                                    <button
                                                                                        disabled={isDisabled}
                                                                                        style={{
                                                                                            width:"45%", 
                                                                                            height:"30px",
                                                                                            marginRight:"10px", 
                                                                                            // marginTop:"px",
                                                                                            fontFamily:'Oxanium',
                                                                                            borderRadius:"2px",
                                                                                            color:'#fff',
                                                                                            fontSize:'17px',
                                                                                            fontStyle:'normal',
                                                                                            fontWeight:500,
                                                                                            lineHeight:'normal',
                                                                                            textTransform:'uppercase',
                                                                                            background: isDisabled ? "gray" : "#0047FF",
                                                                                            cursor: isDisabled ? "not-allowed" : "pointer",
                                                                                            border:'none'

                                                                                        }}
                                                                                    
                                                                                        onClick={() => {
                                                                                            EditManDay();
                                                                                        }}
                                                                                    >SAVE MANDAY</button>
                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px'}}
                                                                                                        autoSize={{
                                                                                                            minRows: 1,
                                                                                                            maxRows: 1
                                                                                                        }}
                                                                                                        type='text'
                                                                                                        placeholder='SEARCH CHANGE...'
                                                                                                        value={searchchange.input}
                                                                                                       /*  disabled={pageMode == 'view'} */
                                                                                                        onChange={(v) => {
                                                                                                            setsearchchange({...searchchange,input:v.target.value})
                                                                                                            searchchange.input = v.target.value
                                                                                                           /*  changeValueInJson('value_input', v.target.value, index, index2, index3, index4); */
                                                                                                        }}
                                                                                                    />
                                                                    {/* </div> */}
      
                                                                                               
                                                                                                </div>



                                                </div>
                                                <div className="row" style={{ backgroundColor: '#0047FF', margin: '10px' }}>
                                                    <div className='col-2'>
                                                        โมดูล - เมนู
                                                    </div>
                                                    <div className='col-5'>
                                                        รายการที่ Change
                                                    </div>
                                                    <div className='col-1'>
                                                        Man-Day
                                                    </div>
                                                    <div className='col-4'>
                                                        Description
                                                    </div>
                                                </div>
                                                {changeList
    .map(i => ({
        ...i,
        change_list: i.change_list.filter(c =>
            c.sm_is_change !== true &&
            ['module_name', 'menu_name', 'sm_comment', 'sm_remark', 'sm_manday']
                .some(k => c[k] && c[k].includes(searchchange.input.trim()))
        )
    }))
    .filter(item => item.change_list.length > 0)
    .map((e, moduleIndex) => {
        let check = e.change_list.filter(ef => ef.sm_is_change !== true);
        if (check.length > 0) {
            return (
                <>
                    <div className="row" key={e.module_id}>
                        <div className='col-2 pl-4'>
                            {e.module_name} - {e.menu_name}
                        </div>
                        <div className='col-10' style={{ display: 'flex', flexDirection: 'column' }}>
                            {check.map((e2, changeIndex) => {
                                let checked = changeSelectList.some(ck => ck.sm_id === e2.sm_id);
                                return (
                                    <div className='row' key={e2.menu_id}>
                                        <div className='col-6' style={{ borderBottom: '1px solid #848484' }}>
                                            <Checkbox
                                                checked={checked}
                                                onChange={() => {
                                                    let temp = {
                                                        menu_id: e.menu_id,
                                                        menu_name: e.menu_name,
                                                        module_id: e.module_id,
                                                        module_name: e.module_name
                                                    };
                                                    selectChange(e2, temp);
                                                }}
                                                disabled={pageMode === 'view'}
                                            >
                                                {e2.sm_comment}
                                            </Checkbox>
                                        </div>
                                        <div className='col-1' style={{ borderBottom: '1px solid #848484', textAlign: 'center' }}>
                                            <NumericFormat
                                                type="text"
                                                placeholder=""
                                                value={e2.sm_manday || ''}
                                                thousandSeparator={true}
                                                allowNegative={false}
                                                max={2}
                                                onChange={(event) => {
                                                    const updatedList = changeList.map(module => {
                                                        let item_temp = [];
                                                        if (module.module_id === e.module_id) {
                                                            return {
                                                                ...module,
                                                                change_list: module.change_list.map(item => {
                                                                    if (item.sm_id === e2.sm_id) {
                                                                       
                                                                        item_temp.push(item);
                                                                        setchangeListTemp(item_temp);
                                                                        return { ...item, sm_manday: event.target.value };
                                                                    }
                                                                    return item;
                                                                })
                                                            };
                                                        }
                                                        return module;
                                                    });
                                                    // console.log("updatedList",updatedList);
                                                    
                                                    setChangeList(updatedList);
                                                   
                                                }}
                                                style={{ background: 'none', color: '#ffffff', borderColor: "#85BFFF", boxShadow: "none", width: "45%", textAlign: "center", height: "90%" }}
                                            />
                                        </div>
                                        <div className='col-5' style={{ borderBottom: '1px solid #848484' }}>
                                            {e2.sm_remark || '-'}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {moduleIndex + 1 !== changeList.length && (<hr />)}
                </>
            );
        }
        return null;
    })}

                                                 <div className="row" style={{display: 'flex',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}} >
                                                <div style={{ margin: '0', fontSize: '25px', fontWeight: 700,width:'30%' }}>CHANGE LIST HISTORY
                                                </div>
                                                <div style={{ display: 'flex', gap: '%'/* , marginBottom: '1%' */ ,width:'30%'}}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1,
                                                                                                            maxRows: 1
                                                                                                        }}
                                                                                                        type='text'
                                                                                                        placeholder='SEARCH CHANGE...'
                                                                                                        value={searchchange.hisinput}
                                                                                                       /*  disabled={pageMode == 'view'} */
                                                                                                        onChange={(v) => {
                                                                                                            setsearchchange({...searchchange,hisinput:v.target.value})
                                                                                                            searchchange.hisinput = v.target.value
                                                                                                           /*  changeValueInJson('value_input', v.target.value, index, index2, index3, index4); */
                                                                                                        }}
                                                                                                    />
                                                                                               
                                                                                                </div>



                                                </div>
                                                <div className="row" style={{ backgroundColor: '#0047FF', margin: '10px' }}>
                                                    <div className='col-2'>
                                                        โมดูล - เมนู
                                                    </div>
                                                    <div className='col-5'>
                                                        รายการที่ Change
                                                    </div>
                                                    <div className='col-1'>
                                                        Man-Day
                                                    </div>
                                                    <div className='col-4'>
                                                        Description
                                                    </div>
                                                </div>
                                                {changeList.map(i => ({...i,
                                                change_list: i.change_list.filter(c => 
                                                     c.sm_is_change == true && ['module_name', 'menu_name', 'sm_comment', 'sm_remark', 'sm_manday'].some(k => c[k] && c[k].includes(searchchange.hisinput.trim())))}))
                                                     .filter(item => item.change_list.length > 0)
                                                     .map((e, index) => {
                                                    let check = e.change_list.filter((ef) => { return ef.sm_is_change == true });
                                                    if (check.length > 0) {
                                                        return (<>
                                                            <div className="row">
                                                                <div className='col-2 pl-4'>
                                                                    {e.module_name} - {e.menu_name}
                                                                </div>
                                                                <div className='col-10' style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    {check.map((e2, index2) => {
                                                                        let checked = false;
                                                                        let check_checked = changeSelectList.filter((ck) => { return ck.sm_id == e2.sm_id });
                                                                        if (check_checked.length > 0) {
                                                                            checked = true;
                                                                        }
                                                                        return (<>
                                                                            <div className='row'>
                                                                                <div className='col-6' style={{ borderBottom: '1px solid #848484' }}>
                                                                                    {/* <Checkbox
                                                                                        checked={e2.sm_is_change}
                                                                                        // onChange={() => {
                                                                                        //     let temp = {
                                                                                        //         menu_id: e.menu_id,
                                                                                        //         menu_name: e.menu_name,
                                                                                        //         module_id: e.module_id,
                                                                                        //         module_name: e.module_name
                                                                                        //     }
                                                                                        //     selectChange(e2, temp);
                                                                                        // }}
                                                                                        disabled={pageMode == 'view'}
                                                                                    >{e2.sm_comment}</Checkbox> */}
                                                                                    {e2.sm_comment}
                                                                                </div>
                                                                                <div className='col-1' style={{ borderBottom: '1px solid #848484', textAlign: 'center' }}>
                                                                                    {e2.sm_manday}
                                                                                </div>
                                                                                <div className='col-5' style={{ borderBottom: '1px solid #848484' }}>
                                                                                    {e2.sm_remark || '-'}
                                                                                </div>
                                                                            </div>
                                                                        </>)
                                                                    })}

                                                                </div>

                                                            </div>
                                                            {index + 1 != changeList.length && (<hr />)}
                                                        </>)
                                                    }
                                                })}

                                            </div>
                                        </div>



                                        <hr />

                                        <div className="row">
                                            <div className="col-12">
                                                <span style={{ fontSize: '24px' }}>Process ที่กระทบ  (เลือกแล้ว {processAffectSelect.length})</span>
                                            </div>

                                        </div>
                                        <div className="boxSpecific" >
                                            <div className="boxSpecificIn">




                                                {processList.moduleList && processList.moduleList.map((e) => {



  /* console.log(e.module_menu_list); */

                                                    return (<>
                                                        <div className="row" style={{ backgroundColor: '#0047FF', margin: '10px', textAlign: 'center' }}>
                                                            <div className='col-12'>
                                                                {e.module_name}  ({e.module_menu_list.length} Menu)
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-5'>Menu</div>
                                                            <div className='col-7' style={{ display: 'flex', gap: '1%' }}>

                                                                {processList.processList.map((p) => {
                                                                    return (<>
                                                                        <div style={{ width: '120px', textAlign: 'center', fontSize: '12px' }}>
                                                                            {p.process_name}
                                                                        </div>

                                                                    </>)
                                                                })}

                                                            </div>
                                                        </div>
                                                        {e.module_menu_list && e.module_menu_list.map((e2) => {
      
                                                            return (<>

                                                                <div className='row mt-4'>
                                                                    <div className='col-5' style={{width:'100%', paddingLeft: `${1.5 +((e2.menu_level - 1) * 2.5)}%`, overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'flex-start'}}>{e2.menu_name}</div>
                                                                    <div className='col-7' style={{ display: 'flex', gap: '1%' }}>
                                                                        {e2.process_list && e2.process_list.map((pm, i_pm) => {
                                                                            let clip = "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)";
                                                                            if (i_pm == 0) {
                                                                                clip = "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)";
                                                                            } else
                                                                                if (i_pm + 1 === processList.processList.length) {
                                                                                    clip = "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)";
                                                                                }

                                                                            let checkSelect = processAffectSelect.filter((cp) => { return cp.process_id == pm.process_id && cp.sm_menu_id == pm.sm_menu_id });
                                                                            let border = "";
                                                                            if (checkSelect.length > 0) {
                                                                                border = "#fff";
                                                                            }
                                                                            pm.menu_name = e2.menu_name;
                                                                            pm.module_name = e.module_name;
                                                                            return (<>


                                                                                <div style={{ marginLeft: '15px', marginRight: '15px', marginTop: '5px', marginBottom: '5px', width: '100%', textAlign: 'center', backgroundColor: `${checkSelect.length > 0 ? '#fff' : pm.ps_color}`, clipPath: clip, cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        if (pageMode != 'view') {
                                                                                            selectAffectProcess(pm);
                                                                                        }

                                                                                    }}
                                                                                >



                                                                                </div>

                                                                            </>)
                                                                        })}

                                                                    </div>
                                                                </div>

                                                            </>)
                                                        })}
                                                    </>)
                                                })}



                                            </div></div>




                                        {/*                     <button onClick={() => {
                                            console.log('รายการ Change', changeSelectList);
                                            console.log('กระทบ Process', processAffectSelect);
                                        }}>check</button> */}


                                    </div>
                                </div>
                            </>)}

                        </div>

                    </div>
                </div>
            </div>

            {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'orange' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <ModelAlertConfrim
                isOpen={displaySwal}
                mainFuc={() => saveChangeDocument()}
                toggle_confrim={() => toggle_modal_confrimModal()}
            />
            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => { getAllChangeDocument(); }}
            />
            <ModelAlertMassge
                isOpen={alertKickMassgeManDay}
                toggle_alertKick={() => { toggle_modal_alertKickMassgeManDay() }}
                message={messageBoxManDay}
                mainFuc={() => { EditManDay(); }}
            />
        </>
    );
}

export default TabChange;
