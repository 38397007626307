import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import moment from "moment";
import Select from 'react-select'
import './personalInfo.css'
import proResume from "../../../assets/img/icon/ProResume.png"
import paperClip from "../../../assets/img/icon/paperclip.png"
import ImgCard from "./cardPersonalInfo.js"
import DetailProject from "./project/detailProject.js"
import DetailSpaceShip from "./spaceship/detailSpaceShip.js"
import DetailFederation from "./federation/detailFederation.js"
import DetailPayment from "./payment/detailPayment.js"
import DetailConnection from "./connection/detailConnection.js"
import DetailAchievement from "./achievement/detailAchievement.js"
import DetailBackground from "./background/detailBackground.js"
import Configs from "../../../config";
import PatternFormat from '../../../components/customElement/PatternFormat.jsx'
import IconSendPic from '../../../assets/img/icon/IconSendPic.png'
import IconSave from '../../../assets/img/icon/IconSave.png'
import ImgCardDefault from '../../../assets/img/images/userImg4.jpg';
import Swal from "sweetalert2";
import { Row } from 'react-bootstrap';
import ModalDetail from './Detail/detail.jsx'
import AddMember from './AddMember/addMember.jsx';
import NyFeedback from './MyFeedback/index.js';

const ViewUserProfile = ({
    isOpen,
    setPersonalInfoCheck,
    fncSetProfile,
    submode,
    close,
    tempValue,

}) => {
    const [modalDetail, setModalDetail] = useState(false)
    const [addMemberModal, setAddMemberModal] = useState(false);
    const toggle_modal_addMember = () => setAddMemberModal(!addMemberModal);
    const [addMemberReload, setAddMemberReload] = useState(false);
    const toggle_modal_addMemberReload = () => setAddMemberReload(!addMemberReload);
    const [memberInSpaceShip, setMemberInSpaceShip] = useState([])
    const [memberSize, setMemberSize] = useState({
        max: 6
    })
    async function callBackMemberInSpaceShip(value) {
        let temp = [];
        for (let item of value) {
            temp.push(item.usr_id)
        }
        await setMemberInSpaceShip(temp)
    }

    const [menu, setMenu] = useState("PROJECT");
    const [imgProfile, setImgProfile] = useState([])
    const [roleOption, setRoleOption] = useState([])
    const [maritalstOption, setMaritalstOption] = useState([])
    const [genderSOption, setGenderSOption] = useState([])
    const [displayNone, setDisplayNone] = useState({
        status: true,
        check: false
    })
    const [profileImg, setProfileImg] = useState([]);
    let profileIMGMain = profileImg.length !== undefined && profileImg.length > 0 && profileImg[0].ul_file_path !== '' ? `${profileImg[0].url}` : ''
    const [test, settest] = useState({
        test: ''
    })
    const [document, setDocument] = useState([])


    const [cardData, setCardData] = useState([
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '6',
            card_name: 'dsaasd',
            card_lastname: 'dsadas',
            card_mvp: '49',
            card_rating: '12',
            card_success: '22',
            card_project: '92'
        },
        {
            card_level: '16',
            card_name: 'ds',
            card_lastname: 'dsadas',
            card_mvp: '249',
            card_rating: '49',
            card_success: '95',
            card_project: '62'
        },
        {
            card_level: '555',
            card_name: 'dsadsa',
            card_lastname: 'dsadas',
            card_mvp: '949',
            card_rating: '15',
            card_success: '45',
            card_project: '69'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
    ])

    const [data, setData] = useState({
        role_current_path: '',
        info_name: '',
        info_lastname: '',
        info_phone: '',
        info_email: '',
        info_role: '',
        info_role_label: '',
        info_dob: '',
        info_gender: '',
        info_gender_label: '',
        info_marital: '',
        info_marital_label: '',
        info_national: '',
        usr_username: '',
        usr_password: '',
    })

    let [displayText, setDisplayText] = useState(false);
    const parentRef = useRef(null);
    const childRef = useRef(null);

    const [oldFileList, setOldFileList] = useState([]);
    const [otherFileList, setOtherFileList] = useState([]);

    async function getInfomaionUserByID() {
        let clear = [];
        setProfileImg(clear);
        setDocument(clear);
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/userprofile/getUserByID/${tempValue.user_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response.data);
                let cutphone = ''
                let count = 0
                if (response.data.usr_phone_number !== undefined && response.data.usr_phone_number !== null && response.data.usr_phone_number !== '') {
                    for (let item of response.data.usr_phone_number) {
                        if (count == 3) {
                            cutphone += '-' + item
                        } else if (count == 6) {
                            cutphone += '-' + item
                        } else {
                            cutphone += item
                        }
                        count++
                    }
                }
                let cutNaID = '';
                let count_1 = 0;
                if (response.data.usr_national_id_card_number !== undefined && response.data.usr_national_id_card_number !== null && response.data.usr_national_id_card_number !== '' && response.data.usr_national_id_card_number.length !== 17) {
                    for (let item of response.data.usr_national_id_card_number) {
                        if (count_1 == 1 ) {
                            cutNaID += '-' + item
                        }else if(count_1 == 5){
                            cutNaID += '-' + item
                        }else if(count_1 == 10){
                            cutNaID += '-' + item
                        }else if(count_1 == 12){
                            cutNaID += '-' + item
                        }else{
                            cutNaID += item
                        }
                        count_1++
                    }
                }else{
                    cutNaID = response.data.usr_national_id_card_number
                }

                let img_path = '';

                if (response.data.fd_img_id) {
                    const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.fd_img_id}/content`, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                        },
                        responseType: 'blob',
                    });

                    img_path = URL.createObjectURL(response2.data);
                }

                /* console.log('cutNaID',response.data.usr_national_id_card_number.length); */
                setData({
                    ...data,
                    info_role_logo: img_path,
                    info_name: response.data.usr_name,
                    info_lastname: response.data.usr_lastname,
                    info_phone: cutphone,
                    info_email: response.data.usr_email,
                    info_role: response.data.usr_role_id,
                    info_role_label: response.data.usr_role_name,
                    info_dob: response.data.usr_date_of_birth !== null && response.data.usr_date_of_birth !== undefined && response.data.usr_date_of_birth !== '' ? moment(response.data.usr_date_of_birth).format('DD-MM-YYYY') : '',
                    info_gender: response.data.usr_gender_id,
                    info_gender_label: response.data.usr_gender_name,
                    info_marital: response.data.usr_ms_id,
                    info_marital_label: response.data.usr_ms_name,
                    info_national:cutNaID,
                    usr_username: response.data.usr_username,
                    usr_password: response.data.usr_password,

                })

                // if (response.data.filelist.length > 0) {
                //     let tempfile = [];
                //     let getProfile = response.data.filelist.filter((e) => e.fd_keyfile == 'PROFILE')
                //     let getfile = response.data.filelist.filter((e) => e.ul_is_profile !== true)
                //     if (getProfile.length > 0) {


                //         let elment = getProfile[0];
                //         /*    fncSetProfile(`${Configs.TTT_Game_Service_IMG}${elment.ul_file_path}`); */
                //         /*   sessionStorage.setItem('img_profile', `${Configs.TTT_Game_Service_IMG}${elment.ul_file_path}`) */

                //         let arra = [];
                //         var list = [{
                //             uid: elment.ul_id,
                //             ul_file_path: elment.ul_file_path,
                //             ul_file_name: elment.ul_file_name,
                //             name: elment.ul_file_name,
                //             path: elment.ul_file_path,
                //             url: `${/* Configs.TTT_Game_Service_IMG}${ */elment.ul_file_path}`,
                //             is_save: true,
                //             ul_is_use: true,
                //             ul_file_size: elment.ul_file_size,
                //             ul_type: "img",
                //             ul_group_name: "document user",
                //             ul_menu_name: "document user",
                //             ul_is_profile: true,
                //             status: 'done',
                //             byid: true
                //         }]
                //         arra.push(list)
                //         setProfileImg(list);

                //         // console.log('getProfile', getProfile);
                //     }

                //     for (let item of getfile) {
                //         var list = {
                //             uid: item.ul_id,
                //             ul_file_path: item.ul_file_path,
                //             ul_file_name: item.ul_file_name,
                //             name: item.ul_file_name,
                //             path: item.ul_file_path,
                //             url: `${Configs.TTT_Game_Service_IMG}${item.ul_file_path}`,
                //             is_save: true,
                //             ul_is_use: true,
                //             ul_file_size: item.ul_file_size,
                //             ul_type: "file",
                //             ul_group_name: "document user",
                //             ul_menu_name: "document user",
                //             ul_is_profile: false,
                //             status: 'done',
                //             byid: true
                //         }
                //         tempfile.push(list)
                //     }
                //     setDocument(tempfile);
                // } else {
                //     setProfileImg([]);
                //     setDocument([]);
                // }

                // if (response.data.filelist.length > 0) {
                //     if (response.data.filelist[0].fd_img_id) {
                //         const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.filelist[0].fd_img_id}/content`, {
                //             headers: {
                //                 Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                //             },
                //             responseType: 'blob',
                //         });
                //         profileImg.push({
                //             url: URL.createObjectURL(response2.data)
                //         });
                //     };
                // };

                if (response.data.filelist.length > 0) {
                    // let checkProfile = response.data.filelist.filter((e)=>{return e.fd_keyfile == 'PROFILE'})
                    let oldFile = [];
                    let otherFile = [];
                    let tempProfile = [];
                    let tempFile = [];
                    
                    for (let item of response.data.filelist) {
                        if (item.fd_img_id) {
                            let temp = {};
                            const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });
                            temp = {
                                url: URL.createObjectURL(response2.data),
                                ul_file_name: item.fd_origin_name,
                                fd_img_id: item.fd_img_id
                            }
                            if (item.fd_keyfile == 'PROFILE') {
                                // fncSetProfile(`${/* Configs.TTT_Game_Service_IMG}${elment.ul_file_path */URL.createObjectURL(response2.data)}`);
                                tempProfile.push(temp);
                                oldFile.push(item.fd_img_id)
                            } else if (item.fd_keyfile == 'RESUME PROFILE') {
                                tempFile.push(temp);
                                oldFile.push(item.fd_img_id);
                            } else {
                                otherFile.push(item.fd_img_id);
                            }
                        };
                     
                        setProfileImg(tempProfile);
                        setDocument(tempFile);
                        settest({...test,test: ''});
                        test.test = '';
                    }

                    setOldFileList(oldFile);
                    setOtherFileList(otherFile);
                    setProfileImg(tempProfile);
                    setDocument(tempFile);
                    // let checkResume = response.data.filelist.filter((e)=>{return e.fd_keyfile == 'RESUME PROFILE'})
                    // if (checkResume.length > 0) {
                    //     let tempFile = [];
                    //     for (let item of checkResume) {
                    //         if (item.fd_img_id) {
                    //             let temp = {};
                    //             const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                    //                 headers: {
                    //                     Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                    //                 },
                    //                 responseType: 'blob',
                    //             });
                    //             temp = {
                    //                 url: URL.createObjectURL(response2.data)
                    //             }
                    //             tempFile.push(temp);
                    //         };
                    //     }
                    //     setDocument(tempFile);
                    // }
                };

                settest({ ...test, test: '' })
                test.test = ''

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function RoleOption() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/roleOption`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response.data);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.role_id,
                        label: e.role_name,
                        logo: e.role_current_path
                    }
                    tem.push(setT)
                })

                setRoleOption(tem);


            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function maritalStatusOption() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/maritalStatusOption`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.ms_id,
                        label: e.ms_name
                    }
                    tem.push(setT)
                })
                setMaritalstOption(tem)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function optionGender() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/optionGender`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.gender_id,
                        label: e.gender_name
                    }
                    tem.push(setT)
                })
                setGenderSOption(tem)
            })
            .catch(function (error) {
                console.log(error);
            });
    }







    const [spacedata, setspacedata] = useState({
        id: null,
        spaceName: ''
    })


    async function callBackspace(id, model, spaceName) {
        setspacedata({
            ...spacedata,
            id: id,
            spaceName: spaceName
        })
        spacedata.id = id
        spacedata.spaceName = spaceName

        setModalDetail(model)


    }



    useEffect(() => {
        /* console.log('isOpen', isOpen, submode); */
        if (submode !== null) {
            if (submode === 'SPACESHIP') {
                setMenu('SPACESHIP')
            }
        }
        const parent = parentRef.current;
        const child = childRef.current;


        const handleScroll = () => {
            const scrollTop = parent.scrollTop;
            const childHeight = child.clientHeight;
            const triggerScroll = childHeight / 2;


            let keyCheck = displayText;
            if (scrollTop >= 200) {
                keyCheck = true
            } else {
                keyCheck = false
            }


            if (keyCheck != displayText) {
                if ((scrollTop >= 200)) {
                    setDisplayText(true)
                    displayText = true

                    setDisplayNone({ ...displayNone, status: false })
                    displayNone.status = false;

                } else {

                    displayText = false
                    setDisplayText(false)
                    let myTimer = setInterval(() => {
                        if (displayText == false) {
                            setDisplayNone({ ...displayNone, status: true })
                            displayNone.status = true;


                        }
                        clearInterval(myTimer);
                    }, 1000);



                }
            }


        };

        parent.addEventListener('scroll', handleScroll);

        
        if (isOpen == true) {
            getInfomaionUserByID()
            RoleOption()
            maritalStatusOption()
            optionGender()
        }


        return () => {
            parent.removeEventListener('scroll', handleScroll);
        };

    }, [isOpen])














    return (
        <>
            <div style={{ visibility: `${isOpen ? 'visible' : 'hidden'}` }}>
                <div className="pi-screenBox-1">
                    <div className="pi-overFlow" ref={parentRef}>
                        <div className="pi-personalInfoBox" ref={childRef}>
                            <div className="pi-closePersonalInfo" onClick={() => {
                                setProfileImg([]);
                                setDocument([]);
                                setData([]);
                                setPersonalInfoCheck({
                                    isOpen: false
                                })
                                setMenu('PROJECT')
                            }}><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
                            </div>
                            <div className="row pi-frameTop">
                                <div className="pi-frameTopText">VIEW PERSONAL INFORMATION</div>

                            </div>
                            <div className='row pi-frameHeader' style={{ animation: `${displayText ? 'mymove 1s' : 'returnmove 1s'}`, display: `${displayNone.status ? 'none' : ''}` }}>
                                <div className='col-10 pi-frameHeaderText'>
                                    <div className='pi-imgProfileIn' style={{ backgroundImage: `url(${profileIMGMain !== '' ? profileIMGMain : ImgCardDefault}` }}></div>
                                    <div className='pi-frameHeaderTextIn'>{data.info_name} {data.info_lastname}</div>
                                </div>
                                <div className='col-2 pi-imgProfile'>

                                </div>
                            </div>
                            <div className="pi-frameCenter">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="pi-infoText">NAME</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_name !== undefined && data.info_name !== null && data.info_name !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <label className="pi-infoBoxTextIn"
                                                 
                                                    >
                                                        {data.info_name}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">PHONE NUMBER</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_phone !== undefined && data.info_phone !== null && data.info_phone !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">

                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_phone} </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">CURRENT ROLE</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_role_label !== undefined && data.info_role_label !== null && data.info_role_label !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">

                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_role_label} </label>

                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="pi-infoText">GENDER</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_gender_label !== undefined && data.info_gender_label !== null && data.info_gender_label !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">

                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_gender_label} </label>

                                                </div>
                                            </div>
                                        </div>
                                      

                                
                                        <div className="pi-infoText">NATIONAL ID CARD NUMBER</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_national !== undefined && data.info_national !== null && data.info_national !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">

                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_national} </label>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="pi-identityBox">
                                            <div className="pi-identityHead">IDENTITY</div>
                                            <div className="pi-identityBoxIn">
                                                {document.map((e, index) => {
                                                    return (
                                                        <div className='row' style={{ margin: '0 0 5px 0 ', padding: '0px', height: '25px' }}>
                                                            <div className='col-2' style={{ textAlign: 'left', margin: '0', height: '32px' }}>
                                                                <img className="pi-identityIcon" src={paperClip}></img>
                                                            </div>

                                                            <div className='col-8 pi-identityText'>
                                                                <a href={`${Configs.TTT_Game_Service_IMG}${e.ul_file_path}`}>
                                                                    {e.ul_file_name}
                                                                </a>
                                                            </div>
                                                            <div className='col-2'>

                                                            </div>
                                                           
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 center">
                                        <div className="pi-infoText">LASTNAME</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_lastname !== undefined && data.info_lastname !== null && data.info_lastname !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                               
                                                    <label className="pi-infoBoxTextIn" >{data.info_lastname} </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">EMAIL</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_email !== undefined && data.info_email !== null && data.info_email !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                              
                                                    <label className="pi-infoBoxTextIn"> {data.info_email } </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">DATE OF BIRTH</div>
                                        <div className="pi-infoBoxTextShadow">
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                   
                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_dob} </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pi-infoText">MARITAL STATUS</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_marital_label !== undefined && data.info_marital_label !== null && data.info_marital_label !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">

                                                    <label className="pi-infoBoxTextIn-1" style={{ padding: '8px' }} >{data.info_marital_label} </label>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="pi-infoText">&nbsp;</div>
                                        <div className="pi-infoResumeTextShadow"   >
                                            {/*   <div className="pi-infoResumeText">
                                                <label className="pi-infoResumeTextIn" for="upload-btn-multiple" type="button" ><img src={proResume} style={{ width: "16px", height: "16px", position: "relative", top: "-3px", left: "-10px" }}></img>  PRO RESUME </label>

                                                <input id="upload-btn-multiple" hidden type="file"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={(e) => {
                                                        if (e.target.files.length > 0) {
                                                            let ole_file = document;
                                                            for (let item of e.target.files) {
                                                                var list = {
                                                                    ul_file_path: URL.createObjectURL(item),
                                                                    ul_file_name: item.name,
                                                                    name: item.name,
                                                                    path: URL.createObjectURL(item),
                                                                    url: URL.createObjectURL(item),
                                                                    is_save: true,
                                                                    ul_is_use: true,
                                                                    ul_file_size: 0,
                                                                    ul_type: "img",
                                                                    ul_group_name: "document user",
                                                                    ul_menu_name: "document user",
                                                                    ul_is_profile: false,
                                                                    status: 'done',
                                                                    image_file: item,
                                                                    is_img_upload: true,
                                                                    originFileObj: item
                                                                }
                                                                ole_file.push(list)

                                                            }
                                                            setDocument(ole_file)

                                                            settest({ ...test, test: '' })
                                                            test.test = ''


                                                        }



                                                    }}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="pi-setCard">
                                            <ImgCard
                                                setCard={1}
                                                imgProfile={profileImg}
                                                imgName={data.info_name}
                                                imgLastName={data.info_lastname}
                                                imgRolelogo={data.info_role_logo}
                                            ></ImgCard>
                                        </div>
                                        <div className='row pi-picButton'>
                                            {/*      <label for="upload-btn-picth" className='col-5 pi-picSend' type='button'><img src={IconSendPic} style={{ width: "17px", height: "17px" }}></img>Send Picture</label>
                                            <input id="upload-btn-picth" hidden type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                        const str = e.target.files[0].name;
                                                        const m = regex.exec(str);
                                                        var list = [{
                                                            ul_file_path: URL.createObjectURL(e.target.files[0]),
                                                            ul_file_name: e.target.files[0].name,
                                                            name: e.target.files[0].name,
                                                            path: URL.createObjectURL(e.target.files[0]),
                                                            url: URL.createObjectURL(e.target.files[0]),
                                                            is_save: true,
                                                            ul_is_use: true,
                                                            ul_file_size: 0,
                                                            ul_type: "img",
                                                            ul_group_name: "document user",
                                                            ul_menu_name: "document user",
                                                            ul_is_profile: true,
                                                            status: 'done',
                                                            image_file: e.target.files[0],
                                                            is_img_upload: true
                                                        }]
                                                        // console.log('file ที่อัพโหลดเเล้ว', list);
                                                        setProfileImg(list)
                                                        settest({ ...test, test: '' })
                                                        test.test = ''


                                                    }



                                                }}
                                            />
                                            <button className='col-7 pi-picHis'>Ai Generate History</button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pi-frameBottom">
                                <div className="pi-menuBottom" style={menu === 'PROJECT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('PROJECT')}}>PROJECT</div>
                                <div className="pi-menuBottom" style={menu === 'SPACESHIP' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('SPACESHIP')}}>SPACESHIP</div>
          {/*                       <div className="pi-menuBottom" style={menu === 'FEDERATION' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('FEDERATION')}}>FEDERATION</div> */}
                                <div className="pi-menuBottom" style={menu === 'PAYMENT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} /* onClick={() => setMenu('PAYMENT')} */>PAYMENT TRANSECTION</div>
                                <div className="pi-menuBottom" style={menu === 'MYFEEDBACK' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('MYFEEDBACK')}>MY FEEDBACK</div>
                             {/*    <div className="pi-menuBottom" style={menu === 'CONNECTION' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('CONNECTION')}}>CONNECTION</div>
                                <div className="pi-menuBottom" style={menu === 'ACHIEVEMENT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('ACHIEVEMENT')}}>ACHIEVEMENT</div>
                                <div className="pi-menuBottom" style={menu === 'BACKGROUND' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {setMenu('BACKGROUND')}}>BACKGROUND PROFILE</div> */}
                            </div>
                            <hr className="pi-lineBottom"></hr>
                            <div className="pi-frameDetail">
                                <DetailProject
                                    isOpen={isOpen}
                                    check={menu} /* dataProjectTa= {dataProjectTa} */
                                    tempValue = {tempValue}
                                ></DetailProject>
                                <DetailSpaceShip
                                    isOpen={isOpen}
                                    check={menu}
                                    /*   setModalDetail={setModalDetail} */
                                    callback={callBackspace}
                                    tempValue = {tempValue}
                                ></DetailSpaceShip>
                                <DetailFederation
                                    isOpen={isOpen}
                                    check={menu}
                                    tempValue = {tempValue}
                                ></DetailFederation>
                                <DetailPayment
                                    isOpen={isOpen}
                                    check={menu}
                                    tempValue = {tempValue}
                                ></DetailPayment>
                                <DetailConnection
                                    isOpen={isOpen}
                                    check={menu}
                                    cardData={cardData}
                                    imgProfile={imgProfile}
                                    tempValue = {tempValue}
                                ></DetailConnection>
                                <DetailAchievement
                                    isOpen={isOpen}
                                    check={menu}
                                    tempValue = {tempValue}
                                ></DetailAchievement>
                                <DetailBackground
                                    isOpen={isOpen}
                                    check={menu}
                                    tempValue = {tempValue}
                                ></DetailBackground>
                                <NyFeedback
                                    isOpen={isOpen}
                                    check={menu}
                                    tempValue = {tempValue}
                                ></NyFeedback>
                            </div>

                            <hr className="pi-lineBottom"></hr>
                        </div>
                    </div>
                </div>

             {/*    <div className='personalDetailBox' style={{ display: `${modalDetail ? '' : 'none'}` }}>
                    <AddMember
                        isOpen={addMemberModal}
                        reload={addMemberReload}
                        toggle_modal_addMemberReload={toggle_modal_addMemberReload}
                        toggle_modal_addMember={toggle_modal_addMember}
                        checkData={memberInSpaceShip}
                        spaceShipId={spacedata.id}
                        memberSize={memberSize}
                        spaceShipName={spacedata.spaceName}
                    />
                    <div className='personalDetailBoxIn'>
                        <div className="personalDetailClose"><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px", cursor: 'pointer' }} onClick={() => {
                            setModalDetail(false)
                        }}></i>
                        </div>
                        <ModalDetail
                            isOpen={modalDetail}
                            mode={'1'}
                            reload={addMemberReload}
                            toggle_modal_addMemberReload={toggle_modal_addMemberReload}
                            spaceShipId={spacedata.id}
                            toggle_modal_addMember={toggle_modal_addMember}
                            callBack={callBackMemberInSpaceShip}
                            setMemberSize={setMemberSize}
                            callBackspace={callBackspace}
                        />
                    </div>
                </div> */}
            </div>
        </>
    )
}
export default ViewUserProfile