import React, { useState, useEffect, useRef } from 'react';
import '../background/detailBackground.css'
import Construction from '../../../../assets/img/bg/Construction Page.png'

const DetailBackground = ({ isOpen, check }) => {

    return (
        (check === 'BACKGROUND' ? (<>
            <div /* className="detailBackground" */ style={{ visibility: `${isOpen === true && check === 'BACKGROUND' ? 'visible' : 'hidden'}` }}>
            <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "0px" }}/>
                {/* {check} */}
            </div>
        </>) : (<></>))
    )
}

export default DetailBackground