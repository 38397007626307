import React, { useState } from 'react';
import './Switch.css'; 

function Switch (param) {




  return (
    <label className={`switch ${param.isChecked ? 'checked' : ''}`}>
      <input type="checkbox" onChange={param.onChange} checked={param.isChecked}  />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;
