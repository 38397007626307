

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
// import noimg from "../../../assets/img/report/TTT Spaceship Header Report.png"
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
/* import QRCode from "qrcode"; */

async function reportmock(data,project) { 

/* console.log(data); */


    const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
    /*  const mmToPx = (mm) => (mm * 96) / 25.4; */

     return new Promise((resolve, reject) => {
         const img = new Image();
         img.crossOrigin = 'Anonymous'; 
         img.src = imageUrl;
         img.onload = () => {
             const canvas = document.createElement('canvas');
             const ctx = canvas.getContext('2d');

             let wpx = img.width;
             let hpx = img.height;
             const mwpx = /* mmToPx(maxWidth) */maxWidth;
             const mhpx = /* mmToPx(maxHeight) */maxHeight;
            /*  console.log('w',mwpx,wpx);
             console.log('h',mhpx,hpx); */

 if (wpx > hpx) {
     if (wpx > mwpx) {
         hpx = Math.round((hpx * mwpx) / wpx);
         wpx = mwpx;
     }
 } else {
     if (hpx > mhpx) {
         wpx = Math.round((wpx * mhpx) / hpx);
         hpx = mhpx;
     }
 }

 canvas.width = wpx;
 canvas.height = hpx;
 ctx.fillStyle = '#FFFFFF';
 ctx.fillRect(0, 0, wpx, hpx);
 ctx.drawImage(img, 0, 0, wpx, hpx);

 resolve(canvas.toDataURL('image/jpeg', 1)); 

            
         };

         img.onerror = () => {
             reject(new Error('Failed to load image.'));
         };
     });
 };



    let imghead = /* noimg */''
console.log('as',project,imghead);
    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    let padding = 20////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 297///สูง
        , page = 1
        , r = 194
        , g = 194
        , b = 194
        , endpage = 262,
        his = [],
        img = {}
        let heightpage = 29

       
       /*  if (project.project.federation_color !== null) {
            let hex = project.project.federation_color.replace(/^#/, '');
            let bigint = parseInt(hex, 16);
    
            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        } */
        Shot('fs', 29)

        let nametemplate = 'Mockup' 
        Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`${nametemplate}`), 'l')
        Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`${nametemplate}`), 'l')
        Shot('lw', 0.2)
        doc.line(CutPadding('w', 0, 2), 76 + 4, CutPadding('w', 100, 2), 76 + 4);
        Shot('t', CutPadding('w', 0, 2), 88, TexttoString(project.project.project_name), 'l')
        Shot('t', CutPadding('w', 0, 2), 88, TexttoString(project.project.project_name), 'l')
    
        Shot('fs', 18)
    
        Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
        Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
        Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
        Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
        Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
        Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
        Shot('t', CutPadding('w', 49, 2), 160, TexttoString(`:`), 'r')
        Shot('t', CutPadding('w', 49, 2), 166, TexttoString(`:`), 'r')
        Shot('t', CutPadding('w', 49, 2), 172, TexttoString(`:`), 'r')
    
    
        Shot('t', CutPadding('w', 51, 2), 160, TexttoString(`-`), 'l')
        Shot('t', CutPadding('w', 51, 2), 166, TexttoString(`-`), 'l')
        Shot('t', CutPadding('w', 51, 2), 172, TexttoString(`${moment(new Date()).add('year', 543).format('DD/MM/YYYY')}`), 'l')
    
    
        Shot("add");
        page += 1

    Shot('fs', 16)
     let countrow = 0,row = 0
        for (let li of data) {
            row+=1
                let ss = li.mockup_data, countss = 0
                if (ss.length > 0) {
                    for (let s of ss) {
                        let screen = s.data[s.data.length - 1]
                      let cutText = doc.splitTextToSize(`${screen.remark}`, CutPadding('w', 80, -padding));
                        if (countss === 0) {
                            if (endpage < heightpage /* + (cutText.length * 6)  */+ 80 + 8) {
                                heightpage += /* (cutText.length * 6) + */ 80 + 8
                                newpage(endpage)
    
                            }
                            heightpage += 4
                            Shot('fs', 18)
                            Shot('t', CutPadding('w', 0), heightpage, TexttoString(`${li.module_name}>>${li.menu_name}`), 'l')
                            Shot('t', CutPadding('w', 0), heightpage, TexttoString(`${li.module_name}>>${li.menu_name}`), 'l')
                            Shot('fs', 16)
                            heightpage += 4
                        }
                        heightpage += 2
    
                        if (endpage < heightpage /* + (cutText.length * 6) */ + 80) {
                            heightpage += /* (cutText.length * 6) + */ 80
                            newpage(endpage)
    
                        }
                        
                        await Shot(
                            'i',
                            /* `${screen.path}` */await getboximg(screen.pathbox? screen.pathbox :screen.path ),
                            await CutPadding('w', 20, -padding),
                            heightpage,
                            await CutPadding('w', 80, -padding),
                            75,
                            'fit',
                            screen
                        );
                        heightpage += 80
                        countrow = 0
                        for (let c of cutText) {
                            if (endpage < heightpage + ((countrow + 1) * 6)) {
                                heightpage += ((countrow + 1) * 6)
                                newpage(endpage)
                            }
                            Shot('t', CutPadding('w', 20, -padding), heightpage, TexttoString(`${c}`), 'l')
                            heightpage += 6
                        }
                        countss += 1
                    }
                   /*  if (row < data.length) {
                        Shot('add')
                        page += 1
                        heightpage = 38
                        heightpage += 4
                    } */
                   
                }
               
              

        }
        const mmToPx = (mm) => (mm * 96) / 25.4;
        if (project.img.length > 0) {
            img = await getboximg(project.img[0].fd_img_id)
            console.log(img);
            imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) *//* Configs.TTT_Game_Service_IMG + img.ul_file_path */img
        }else{
            imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg
    
        }

    for (let i = 0; i <= page; i += 1) {
        if (i > 1) {
            doc.setPage(i)
                sethead()
                setfooter(i, page)
        }

    }

    await savetopdf('all')


    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 33

        }
    }

   async function getboximg(imageid) {
    try{
        const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });
        
     return URL.createObjectURL(response2.data);
    }catch (error) {
        return null 
    }
    }

    function sethead() {
    /*     Shot('i', imghead, 0, 0, wpage + 1, 32) */
    Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)


    }
    function setfooter(on, max) {


        Shot('lw', 0.3)
        Shot('fs', 13)
        Shot('t', CutPadding('w', 50, 2), 273 + 2, TexttoString(`Page ${on} of ${max}`), 'c')
        doc.line(CutPadding('w', 0, 2), 273 + 4, CutPadding('w', 100, 2), 273 + 4);
        Shot('t', CutPadding('w', 0, 2), 273 + 8, TexttoString(`Template v2.0 Copyright © TTT Brother CO., LTD. `), 'l')
      /*   Shot('t', CutPadding('w', 95, 2), 273 + 8, TexttoString(`V${parseFloat(data.version.version) + 1}.0`), 'r') */

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */

    async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {

            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {

                    if (c6 === 'fit') {
                       
    /*  console.log(c2 + x, c3 + y, nw, nh); */
                      
    let Width = c7.width? c7.width : 1920;
    let Height = c7.height? c7.height : 1080; 
 
   const imgar = Width / Height;
   const reactar = c4 / c5;

   let scale = 1;
   if (imgar > reactar) {
       scale = c4 / Width;
   } else {
       scale = c5 / Height;
   }
   const nw = Width * scale;
   const nh = Height * scale;

   const x = (c4 - nw) / 2;
   const y = (c5 - nh) / 2;

console.log(c1);
   const compressedImgData = await compressImage(c1);


    return doc.addImage(compressedImgData, 'JPEG', c2 + x, c3 + y, nw, nh, undefined, 'FAST')
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }



    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig,header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {


                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            Shot('t', Colposition('t', head, t2, 'l', 3), higbefor + (fakecolsum), TexttoString(cx), 'l');
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            if (header !== undefined) {
                                ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                                higbefor+=7
                            }
                           
                        }

                        Shot('t', Colposition('t', head, t, 'l', 3), higbefor + (collengthsum), TexttoString(c), 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



    async function savetopdf(mode) {
      
        
            Shot('save', `${'Mockup'}${project.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`);
            return true

    }
}






export default reportmock;
