import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MyLineChart = ({data}) => {
  /* console.log(data) */
  /* const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Plan',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(0,157,255,0.4)',
        borderColor: 'rgba(0,157,255,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(0,157,255,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(0,157,255,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [65, 59, 80, 81, 56, 55, 40],
      },
      {
        label: 'Actual',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(29,204,55,0.4)',
        borderColor: 'rgba(29,204,55,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(29,204,55,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(29,204,55,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [44, 99, 85, 15, 25, 35, 95],
      },
    ],
  }; */

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
   
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // สีพื้นหลังของ tooltip
        callbacks: {
          labelColor: function(tooltipItem) {
            console.log('tooltipItem',tooltipItem)
            return {
              borderColor: tooltipItem.dataset.backgroundColor, // สีของกรอบ
              backgroundColor: tooltipItem.dataset.backgroundColor, // สีพื้นหลังใน tooltip
            };
          }
        }
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Process %',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Week In Year',
        },
      },
      
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  };

  return (<>
  <div style={{height:'100%',width:'100%',position:'relative'}}>
  <Line data={data} options={options} />
  </div>
 

  </>
 
  )
};

export default MyLineChart;
