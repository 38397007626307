import React, { useEffect } from "react";
/* import Headers from "../../component/Header"; */
/* import {
  setCurrentPath,
  removeUserSession,
  removeOem,
} from "../../Utils/Common"; */
import {
  /*   BrowserRouter as Router, */
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
/* import Footter from "../../component/Footter";
import Sidebar from "../../component/Sidebar2"; */
import Swal from "sweetalert2";
import styled from 'styled-components'

import HomeOne from "../pages/homes/HomeOne";
import HomeThree from "../pages/homes/HomeThree";
import Shop from "../pages/store/Shop";
import Contact from "../pages/contact/Contact";
import Community from "../pages/community/Community";
import Overview from "../pages/overview/Overview";
import LeaderBoard from "../pages/leaderBoard/LeaderBoard";
import Contactus from "../pages/overview/ContactusT";
import AboutUs from "../pages/aboutpage/aboutuspage/AboutUs";
import Upcoming from "../pages/aboutpage/upcoming/Upcoming";
import GameSingle from "../pages/aboutpage/singlegame/GameSingle";
import Blogs from "../pages/blogs/Blogs";
import BlogDetails from "../pages/blogdetails/BlogDetails";
import ScrollToTopRoute from "../components/scrolltotop/ScrollToTopRoute";
import HomeFour from "../pages/homes/HomeFour";
import HomeFive from "../pages/homes/HomeFive";
import HomeSix from "../pages/homes/HomeSix";
import HomeSeven from "../pages/homes/HomeSeven";
import HomeTTT from "../view/homes/HomeTTT";
import Information from "../view/information/Information";
import Info from "../view/information/Info";
import Infodetail from "../view/information/detailInfo";
import ShopTTT from "../view/shop/ShopTTT";
import Shopdetail from "../view/shop/Shopdetail";
import Login from "../view/login";
import IndexAbout from "../view/abouts/index-about";
import Guide from "../view/guide";
import DetailClass from "../view/detailClass";
import ComponentTest from "../view/component/componentTest";
import TTTgame from "../components/TTTGame";

import Register from "../view/register/";
import LandingPageGame from "../view/LandingPageGame/";
import News from "../view/news/new";
import LineModal from "../view/3dModalTEST/line_modal";
import LandingPagestage from "../view/LandingPagestage/";
import ProjectStatePreview from "../view/projectStatePreview/";
import KickOffProject from "../view/KickOffProject";

import LandingMySpace from "../view/LandingMySpace/";
import SuperDetailMode from "../view/LandingPagestage/componentSuperDetail";
import ProjectTimeline from "../view/ProjectTimeline/";
import Timesheettimeline from "../view/Timesheettimeline/";
import RiskManagement from "../view/Risk"

/* import ModelAlertMassge from "../components/AlertMassge/AlertMassge"; */
const Homepage = () => {
/* 
  const [modalMessageOpen,setModalMessageOpen] = useState(false);
  const toggle_message_modal = ()=> {setModalMessageOpen(!modalMessageOpen)}; */

  const Content = styled(Switch)`
  height:'100%';
  `;
  const location = useLocation();

 


/*   useEffect(() => {

    sessionStorage.setItem("timeline", 0);
    //setCurrentPath(location.pathname);

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keydown",
    ];
    var fiveMinutes = 60 * 2;

    var myInterval = setInterval(myTimer, 1000);
    //countdown(fiveMinutes);
    var timer = fiveMinutes,
      minutes,
      seconds;
    function myTimer() {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
      
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      // console.log(timer)
      if (timer === 60) {
      
        toggle_message_modal();
      
      }
      if (--timer < 0) {
        sessionStorage.clear(); 
        window.location.reload(); 

      } else {
        //console.log( minutes + ":" + seconds)

        sessionStorage.setItem("setupTime", minutes + ":" + seconds);
      }
    }
    for (var e = 0; e < events.length; e++) {
      //console.log(events);
      document.addEventListener(
        events[e],
        (event) => {
          clearInterval(myInterval);
          timer = 60 * 2;
          myInterval = setInterval(myTimer, 1000);
        },
        false
      );
    }
  }, []); */


  return (
    <>


{/*  <ModelAlertMassge
        isOpen={modalMessageOpen}
        toggle_alertKick={() => { toggle_message_modal() }}
        message = {{
          message: 'If no further transaction is performed, the automatic system will exit within 1 minute.', isError:null, menuTitle:"You haven't done anything for 30 minutes."
        }}
      /> */}

      <Content>
        {/*       <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/> */}

        <div className="App">
       
          <Router>
            <Switch>

              <ScrollToTopRoute exact={true} path="/LandingPageGame">
                <LandingPageGame />
              </ScrollToTopRoute>
              
              <ScrollToTopRoute exact={true} path="/LandingMySpace">
                <LandingMySpace />
              </ScrollToTopRoute>


              <ScrollToTopRoute exact={true} path="/lineModal">
                <LineModal />
              </ScrollToTopRoute>
              <ScrollToTopRoute exact={true} path="/StageMonitoring">
                <LandingPagestage />
              </ScrollToTopRoute>
              <ScrollToTopRoute exact={true} path="/StageMonitoring_package">
                <LandingPagestage display={'package'} />
              </ScrollToTopRoute>

              <ScrollToTopRoute exact={true} path="/ProjectStatePreview">
                <ProjectStatePreview />
              </ScrollToTopRoute>
              <ScrollToTopRoute exact={true} path="/ProjectTimeline">
                <ProjectTimeline />
              </ScrollToTopRoute>
              <ScrollToTopRoute exact={true} path="/Timesheettimeline">
                <Timesheettimeline />
              </ScrollToTopRoute>

       {/*        <ScrollToTopRoute exact={true} path="/KickOffProject/:id">
                <KickOffProject />
              </ScrollToTopRoute> */}
         
          {/*   <Route path={`/KickOffProject`} component={KickOffProject} /> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/KickOffProject`}
            component={() => <KickOffProject />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/SuperDetailMode`}
            component={() => <SuperDetailMode />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/RiskManagement`}
            component={() => <RiskManagement />}
          />

            </Switch>
          </Router>
          
        </div>
   
        <Redirect to={`${process.env.PUBLIC_URL}/coming_soon`} />
      </Content>




    </>
  );
};

export default Homepage;
