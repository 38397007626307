import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom'

function GameSingleArea() {
  const [nametop, setnametop] = useState('Transform');
  const [icon, seticon] = useState([1,2,3,4,5,1,2,3,4,5]);

const loopcard = []
const loop = []
  for (let index = 0; index < 96; index++) {
    loopcard.push({num:index+1})
  }
   
  return (
	      <section className="game-single-area pt-120 pb-180">
        <div className="featured-game-bg" />
            <div className="container">

            <div className="d-flex justify-content-center">
        
            <div className="">

            <button className="" style={{color:'#1c1121',borderRadius:'5px',height:'50px',width:'100%',backgroundColor:'#e4a101',paddingLeft:'27px',paddingRight:'27px'}}
            onClick={() => {
              setnametop('Transform')                      
              seticon([1,2,3,4,5,1,2,3,4,5])

          }}>Transform  </button>
               </div>
               <div className="">
            <button className="" style={{color:'#1c1121',borderRadius:'5px',height:'50px',width:'100%',backgroundColor:'#e4a101',paddingLeft:'27px',paddingRight:'27px'}}
            onClick={() => {
              setnametop('Timely')                              
              seticon([1,2,3])
          }}>Timely  </button>
          </div>
          <div className="">
            <button className="" style={{color:'#1c1121',borderRadius:'5px',height:'50px',width:'100%',backgroundColor:'#e4a101',paddingLeft:'27px',paddingRight:'27px'}}
            onClick={() => {
              setnametop('Trailblazer')                  
              seticon([1,2,3,6])

          }}>Trailblazer  </button>
              </div>
            </div>
              <div className="row justify-content-center">
              
                <div className="col-xl-6 col-lg-8">
                <br/>
            
                  <div className="section-title title-style-three text-center mb-30">
                    {/* <h2>JUST FEATURED <span>GAMES</span></h2> */}
                    <h2>{nametop}</h2>
                   {/*  <p>Compete with 100 players on a remote island for winner takes showdown
                      known issue where certain skin strategic</p> */}
                      
                    
                      <div className="d-flex justify-content-center table-responsive">
                  {icon.map((el) => {
    return (
      <div className="icon-img ml-1">
          <img src="assets/img/images/features_thumb01.jpg" alt="" />
      </div>
    );
  })}   
    </div>

                  </div>
                 
                </div>
              </div>
              <div className="row featured-active">
              <div className=" col-lg-4 col-sm-6 "></div>
                <div className="d-flex justify-content-center col-lg-4 col-sm-6 grid-item ">
                  <div className="featured-game-item mb-30" style={{height:'190px',width:'150px',overflow:'hidden'}}>
                    <div className="featured-game-thumb " >
                      <img src="assets/img/images/featured_game_thumb01.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                    <h4><Link to="/#">card {1}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">card {1}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span> 
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 "></div>
                <div className="col-lg-3 col-sm-6 "></div>
                <div className="d-flex justify-content-center col-lg-3 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30"  style={{height:'190px',width:'150px',overflow:'hidden'}}>
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb02.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                    <h4><Link to="/#">card {2}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">card {2}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
           
                <div className="d-flex justify-content-center col-lg-3 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30" style={{height:'190px',width:'150px',overflow:'hidden'}}>
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/featured_game_thumb03.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                    <h4><Link to="/#">card {3}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                    <div className="featured-game-content featured-game-overlay-content">
                      <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
                      <h4><Link to="/#">card {3}<span>GAMERS</span></Link></h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-bell" />
                        <span>Playstation 5 , Xbox</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 "></div>
                {loopcard.map((el) => {
    return (
      <div className="d-flex justify-content-center col-lg-3 col-sm-6 grid-item">
      <div className="featured-game-item mb-30" style={{height:'190px',width:'150px',overflow:'hidden'}}>
        <div className="featured-game-thumb">
          <img src="assets/img/images/featured_game_thumb01.jpg" alt="" />
        </div>
        <div className="featured-game-content">
          <h4><Link to="/#">card {el.num+3}<span>GAMERS</span></Link></h4>
          <div className="featured-game-meta">
            <i className="fas fa-bell" />
            <span>Playel {el.num+3}</span>
          </div>
        </div>
        <div className="featured-game-content featured-game-overlay-content">
          <div className="featured-game-icon"><img src="assets/img/icon/featured_game_icon.png" alt="" /></div>
          <h4><Link to="/#">card {el.num+3}<span>GAMERS</span></Link></h4>
          <div className="featured-game-meta">
            <i className="fas fa-bell" />
            <span>Playstation 5 , Xbox</span> 
          </div>
        </div>
      </div>
    </div>
    );
  })}
              </div>
          
            </div>
      </section>
  )
}

export default GameSingleArea