import React, { useEffect, useState } from "react";
import "./leaderBoardCardStyleMobile.css";
import ImgCard from '../../assets/img/userlogo/defultUser.png';
import IconProject from '../../assets/img/icon/Group11.png';



function LeaderBoardCardMobile(params) {
    const [size, setSize] = useState({
        cardWidth: '',
        cardHeight: ''
    })

    useEffect(() => {
        setSize({ ...size, cardWidth: params.cardWidth, cardHeight: params.cardHeight });
        size.cardWidth = params.cardWidth;
        size.cardHeight = params.cardHeight;
    }, [])

    return (
        <div className="cardBody_mobile" style={{ backgroundImage: `url(${params.imgTEST !== '' && params.imgTEST != null ? params.imgTEST : ImgCard})`, width: `${size.cardWidth}%`, height: `${size.cardHeight}%`,marginTop : params.top !== 0 ? `${params.top}px` : null }}>
            <div className="gradient_moblie" style={{width:  `${ params.width_change}%` , background: params.width_change ? 'none' : 'none'}}>
                <div className="frameTop_moblie">
                    <div className="iLevel_moblie">LEVEL
                        <div className="iLevelIn_moblie">210</div>
                    </div>
                    <div className="iName_moblie">NAME :
                        <div className="iNameIn_moblie">{params.cardName}</div>
                    </div>
                    <div className="iClass_moblie">

                    </div>
                </div>
                <div className={ params.is_paddingno1 ? `frameBottom_moblie2` : `frameBottom_moblie`} /* style={{margin: params.width_value <= 430 && !params.is_paddingno1 ? "74% 0% 0% 4%" : "69% 0% 0% 4%"}} */>
                    <div className="frameBottomLeft_moblie">
                        <div className="fbLine_moblie"></div>
                        <div className={params.is_paddingno1 ? `fbBoxShort_moblie2` : `fbBoxShort_moblie`}>MVP :
                            <div className="fbBoxShortInM_moblie">449</div>
                        </div>
                        <div className={params.is_paddingno1 ? `fbBoxShort_moblie2` : `fbBoxShort_moblie`}>RATTING :
                            <div className="fbBoxShortInR_moblie">{params.cardRating}%</div>
                        </div>
                        <div className="fbLine_moblie"></div>
                        <div className="fbBoxLong_moblie" > <img src={IconProject} style={{ height: "5px", marginTop: "-3px" }}></img> 
                            <div style={{marginTop:"-2px"}}>SUCCESSFUL PROJECTS</div> 
                            <div className="fbBoxLongIn_moblie" style={{marginTop:"-2px"}}>59</div>
                            <div className="fbBoxLongInText_moblie"style={{marginTop:"-2px"}}>PROJECT</div>
                        </div>
                        <div className="fbBoxLong_moblie"> <img src={IconProject} style={{ height: "5px", marginTop: "-3px" }}></img> 
                             <div style={{marginTop:"-3px"}}>PROJECTS INVOLVED</div>   
                            <div className="fbBoxLongIn_moblie" style={{marginTop:"-3px"}}>9</div>
                            <div className="fbBoxLongInText_moblie" style={{marginTop:"-3px"}}>PROJECT</div>
                        </div>
                    </div>
                    <div className="frameBottomRight_moblie" style={{marginTop: params.is_mtop ? `${params.is_mtop}px` : "3px"}}>
                        <div className="fbAm_moblie">ACHIEVEMENT</div>
                        <div className="fbRBoxMain_moblie"></div>
                        <div className="fbRBoxSec_moblie"></div>
                        <div className="fbRBoxSec_moblie"></div>
                        <div className="fbRBoxSec_moblie"></div>
                        <div className="fbRBoxSec_moblie"></div>
                        <div className="fbRBoxSec_moblie"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaderBoardCardMobile