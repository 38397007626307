import React, { useState, useEffect } from 'react'
import './stageElementStyle.css';
import Configs from '../../../config';
import axios from 'axios';
import spaceShipImage from '../../../assets/img/iconSpaceShip/Final-Logo.png';
import defaultUserLogo from '../../../assets/img/userlogo/defultUser.png';

const UserProgress = ({ dataUserPercentList,callbackUserPercent ,displayMode,percentTotal}) => {
  const [userList, setUserList] = useState(dataUserPercentList);

const [percentSelectList,setPercentSelectList] = useState([20,40,60,80,100]);
const [percentAllUser,setPercenAllUser] = useState(0)
const [test,settest] = useState({test:''})

  function changePercentProgress(usr_id,percent,old_percent){
    console.log('LEK',usr_id,percent)
    console.log('tem',percentTotal)
    let temp = userList;
    let old_temp = userList;
    let index = temp.findIndex((e)=> e.usr_id == usr_id);
    if(index > -1){
        
        let target_percent = percent;
        if(temp[index].percent == percent && temp[index].su_is_leader != true){
            target_percent = 0;
        }
        let i = 0;
        let allPercent = percentAllUser;
        allPercent = allPercent - old_percent;
        allPercent = allPercent +  target_percent;
       /*  for(let item of temp){

            allPercent = parseFloat(allPercent || 0) + parseFloat(item.percent)
     
        } */
       
      
        
      /*   let allPercent = 0;
        for(let item of temp){
            allPercent = parseFloat(allPercent || 0) + parseFloat(item.percent)
        } */
            if(allPercent <=100){
                if(temp[index].percent == percent && temp[index].su_is_leader != true){
                    temp[index].percent = 0;
                }else{
                    temp[index].percent = percent;
                }
                setPercenAllUser(allPercent);
                setUserList(temp);
                callbackUserPercent(temp);
                percentTotal(allPercent);
            }else{
                
                //setPercenAllUser(allPercent);
              /*   setUserList(old_temp); */
            }
   
       
    
        settest({...test,test:''});
        test.test = '';
    
    }
  }

  useEffect(()=>{
    setUserList(dataUserPercentList)
    let allPercent = 0;
    for(let item of dataUserPercentList ){
      allPercent = parseFloat(allPercent) + parseFloat(item.percent) ;
    }
    setPercenAllUser(allPercent)
    percentTotal(allPercent);
  },[dataUserPercentList])


  useEffect(()=>{
    // setUserList(dataUserPercentList)
    // let allPercent = 0;
    // for(let item of dataUserPercentList ){
    //   allPercent = parseFloat(allPercent) + parseFloat(item.percent) ;
    // }
    // callbackUserPercent(allPercent);
  },[])

  return (
    <>
      <div className="stageElementProgressBox">
        <div className="stageElementProgressBoxIn">
          <div className="stageElementProgressBody">
            <div className="stageElementProgressBodyIn">
              <div
                className="stageElementUserProgress"
                style={{ textAlign: 'center' }}
              >
                <img src={spaceShipImage} style={{ width: '60%' }} />
              </div>

              <div className="userProgressBody">
                {userList.map((e, index) => {

                    
                  return (
                    <>
                      <div className="stageElementUserProgress">
                        <div className="progressUserBody_">
                          <div className="progressUserLeft">
                            <img
                              src={e.ul_file_path !== undefined && e.ul_file_path !== null ? `${/* Configs.TTT_Game_Service_IMG}${ */e.ul_file_path}` : defaultUserLogo}
                            /*   src = {defaultUserLogo} */
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                            />
                          </div>
                          <div className="progressUserRight">
                            <div style={{  overflowX: 'auto',textWrap: 'nowrap'}}>
                              <div className="progressUserBody" >
                              {e.ttt_id} : {e.first_name + " " + e.last_name} {e.su_is_leader ? `(LEADER)` :''}
                              </div>
                            </div>
                            <div className="progressUserBody mt-1">
                                {percentSelectList.map((percent,index_percent)=>{
                                    
                                    return (<>
                                      <div className="progressUserBox" >
                                <div className={`${e.percent == percent ? 'progressUserBoxInSelect' : 'progressUserBoxIn'}`}
                                
                                onClick = {()=>{

                                  if(displayMode == false || displayMode == 'false'){
                                    if(parseFloat(percentAllUser || 0) <= 100 || e.percent == percent){
                                      changePercentProgress(e.usr_id,percent,e.percent);
                                     }
                                  }
                                    
                              
                               
                                
                                }}
                                >{percent}%</div>
                              </div>
                                    </>);
                                })}
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>

              <div className="fontPencentUser">{parseFloat(percentAllUser || 0).toFixed(2)} %</div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProgress
