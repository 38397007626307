 const Menu = [
    {
        id: 1,
        category:"Gamer",
        image1:"assets/img/team/team_logo01.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo02.png",
        title:"ชื่อเรื่อง",
        description:"รายละเอียด",
        price:"$125,00",
        btn:"Joining"
    },
    {
        id: 2,
        category:"Profesional",
        image1:"assets/img/team/team_logo03.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo04.png",
        title:"ชื่อเรื่อง",
        description:"รายละเอียด",
        price:"$125,00",
        btn:"Joining"
    },
    {
        id: 3,
        category:"Adventure",
        image1:"assets/img/team/team_logo05.png",
        image2:"assets/img/team/match_vs.png",
        image3:"assets/img/team/team_logo06.png",
        title:'ชื่อเรื่อง',
        description:"รายละเอียด",
        price:"$125,00",
        btn:"Joining"
    }
    
] 
export default Menu;