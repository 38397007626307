import React, { useEffect } from 'react'
/* import HeaderTTT from "../components/header/HeaderTTT"; */
import '../../assets/css/landingPageGame.css'
import { useState } from 'react'


import logo from '../../assets/img/iconSpaceShip/Logo TTT 2.png';
import icon_plus from '../../assets/img/iconSpaceShip/Frame_plus.png';
import icon_plus2 from '../../assets/img/iconSpaceShip/plus.png';
import icon_rocket from '../../assets/img/iconSpaceShip/rocket.png';
import icon_mail from '../../assets/img/iconSpaceShip/mail.png';
import icon_fresh from '../../assets/img/iconSpaceShip/Frame_fresh.png';
import icon_pen from '../../assets/img/iconSpaceShip/edit_pen.png';
import icon_sapphire from '../../assets/img/iconSpaceShip/Frame.png';
import logo_ss from '../../assets/img/iconSpaceShip/Final-Logo.png';
import logo_user from '../../assets/img/iconSpaceShip/user.png';
import logo_twitch from '../../assets/img/iconSpaceShip/twitch.png';
import logo_pointer from '../../assets/img/iconSpaceShip/mouse-pointer.png';
import img_banner from '../../assets/img/slider/banner_magazine_thumb01.jpg';
import Carddefcon from '../LandingPagestage/carddefcon'
import img_sm from '../../assets/img/icon/IconMonitoring.png';
import $ from "jquery";
import * as THREE from 'three';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import Stats from 'three/examples/jsm/libs/stats.module';
import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import LineModal from '../3dModalTEST/lineModal';
import Icon_Universal from '../../assets/img/icon/Icon_Universal.gif';
import SelectProject from '../component/SelectProject/selectProject';
import ModalProject from '../component/EditProject/modalEditProject';
/* import towerJSON from '../3dModalTEST/towerData.json'; */
/* import towerJSON from '../3dModalTEST/tower500Data.json'; */
import towerJSON from '../3dModalTEST/towerSpaceShipCenter.json';
import Configs from "../../config";
import ImgEditModal from '../component/EditProject/IconEdit.png'
import Swal from 'sweetalert2';
import axios from 'axios';

import LandingPagestage from '../LandingPagestage';

function LandingPageGame() {
  const [filterTower, setFilterTower] = useState(
    {
      mybuild: false,
      inprocess: false,
      lorem: false
    }
  );

  const [jsonArea, setJsonArea] = useState([]);
  const [openModal, setOpenModal] = useState('close')
  const [projectActive, setProjectActive] = useState({
    pf_project_id: '',
    project_progress: '',
    spaceship_size: '',
    spaceship_name: '',
    pp_start_date: '',
    pp_finish_date: '',
    federation_name: '',
    pp_team_member: '',
    ul_file_name: '',
    ul_file_path: '',
  })
  const [checkCategory, setCheckCategory] = useState(
    {
      all: true,
      red: true,
      blue: true,
      yellow: true,
      green: true,
      white: true,
    }
  );

  const [fesh, setFesh] = useState({ fesh: '' })

  const [onCategory, setOnCategory] = useState(false)



  const [test, settest] = useState({ test: '' })
  const [stateRender, setStateRender] = useState(false)


  function setTowerInGround(dataFormat) {
    Swal.fire({
      title: "Loading",
      text:`Sync Data in Process.`,
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    })
    let tempData = jsonArea;
    /* item.budgetX, item.sizeMapY , item.TimeZ, item.project_name, item.category_name, item.category_color) */
    for(let item of dataFormat){
      let x = item.budgetX ;
      let y = item.sizeMapY ;
      let z = item.TimeZ;
      let positionY = y;
      let positionX = x;
      let positionZ = z;
      let project_name = item.project_name;
      let category_name = item.category_name;
      let category_color = item.category_color;
    

   

    /*  let checkX = tempData.length > 0 ? tempData.sort((a,b)=>b.position.x - a.position.x) : [];
      //console.log(checkX)
     let MaxX = checkX.length > 0 ? checkX[0].position.x + checkX[0].budgetX  : 0;

     let MinX = checkX.length > 0 ? checkX[checkX.length -1].position.x : 0;

     let checkZ = tempData.length > 0 ? tempData.sort((a,b)=>b.position.z - a.position.z) : [];
     let MaxZ = checkZ.length > 0 ? checkZ[0].position.z + checkX[0].TimeZ : 0;

     let MinZ = checkZ.length > 0 ? checkZ[checkZ.length -1].position.z : 0; */

    // console.log(MaxX,MaxZ)


    /*    if(MaxZ < MaxX){
        console.log("เข้า 1");
           positionZ =  MaxZ +1;

           positionX = MinX;
       }else if(MaxZ > MaxX){
        console.log("เข้า 2");
           positionX =  MaxX + 1; 
           positionZ = MinZ ;
       }else if(MaxZ == MaxX){
           console.log("เข้า 3");
 
           //console.log("MaxX",MaxX,positionX)
           
           positionX =  MaxX +1
           positionZ = MinZ +2
       } */

    //positionX =  MaxX
    //positionZ = MinZ

    /*        for (var i = 0; i < steps; i++) {
            positionX = (0 + radius * Math.cos(2 * Math.PI * i / steps));
            positionZ = (0 + radius * Math.sin(2 * Math.PI * i / steps)); */

    let minArea = -9;
    let maxArea = 9;

    let isRandom = true;
    let newRandwonCount = 0;
    do {
      let RanX = /*  -8  */ getRndInteger(minArea, maxArea);
      let RanZ = /* -7 */ getRndInteger(minArea, maxArea);
      let AreaList = [];
      for (let a = 0; a < x + 1; a++) {
        for (let b = 0; b < z + 1; b++) {
          let temp = {
            x: RanX - 1 + a,
            z: RanZ - 1 + b
          }

          AreaList.push(temp)
        }
      }


      let isSuccess = true;
      for (let pos of AreaList) {


        let checkXZ = tempData.filter((e) => {
          return e.AreaSizeList.some(as => as.x == pos.x && as.z == pos.z)
        });

        if (checkXZ.length > 0) {
          /*     console.log("เจออออออออ") */
          isSuccess = false;
        }
      }

      /*      console.log("checkXZ",RanX,RanZ) */


      if (isSuccess == true) {
        let cate_stock = [
          { cate_id: 'CAT01', cate_name: 'อวกาศและพลังงาน', color: '#FFFFFF' },
          { cate_id: 'CAT02', cate_name: 'ธรณีภาคพื้นดิน', color: 'red' },
          { cate_id: 'CAT03', cate_name: 'น้ำและมาหาสมุทร', color: 'blue' },
          { cate_id: 'CAT04', cate_name: 'น้ำมันและเชื้อเพลิง', color: 'yellow' },
          { cate_id: 'CAT05', cate_name: 'ชีวิตและสิ่งแวดล้อม', color: 'green' },
        ]
        const random = Math.floor(Math.random() * cate_stock.length);
        /* console.log(random, months[random]); */
        let temp = {
          spacship_name: 'RAIZEROS',
          project_id: project_name,
          project_code: project_name,
          project_name: project_name,
          category_id: category_name,
          category_name: category_name ,
          TimeZ: z,
          budgetX: x,
          position: { x: RanX, y: positionY, z: RanZ },
          AreaSizeList: AreaList || [],
          sizeMapY: y,
          color:/* Math.floor(Math.random()*16777215).toString(16) */ category_color,
        }

        tempData.push(temp);
        isRandom = false;
      } else {
        /* console.log("ซ้ำ สุ่มใหม่"); */
        newRandwonCount++;
        if (newRandwonCount == 10) {
          newRandwonCount = 0;
          minArea = minArea + (-9);
          maxArea = maxArea + (9);
        }
      }

    }
    while (isRandom == true);
  }
    /*    } */
    /* console.log(xValues,yValues) */
    let arraM1 = [];
    let arraMatrix = [];


    /*    console.log('x:',positionX,'z:',positionZ) */
    console.log("tempData", tempData)
    setJsonArea(tempData);
    settest({ ...test, test: '' });
    test.test = '';
    Swal.close();
    /* setStateRender(true); */


  }

  function getRndInteger(min, max) {
    return Math.ceil(Math.random() * (max - min + 1)) + min;
  }
  useEffect(() => {

    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/project/formatProjectToArea`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {
        let index = 0;
        setTowerInGround(response.data);
        /* for (let item of response.data) {
          if(index < 5){
                     setTowerInGround(item.budgetX, item.sizeMapY , item.TimeZ, item.project_name, item.category_name, item.category_color);
          }
  
 
      
          index++;
        } */

        setStateRender(!stateRender);

      })
      .catch(function (error) {
        console.log(error);

      });
    /*   for (let i = 0; i < 0; i++) {
        setTowerInGround(getRndInteger(1, 10), getRndInteger(1, 10), getRndInteger(1, 10));
      } */



  }, [])

  function checkBox(check) {




    /*     let  tempData = towerJSON;
        let  resultData = tempData.filter((e)=>{return e.color == "red"});
        console.log(resultData)
        setJsonArea(resultData); */

    if (check == 1 & filterTower.mybuild != true) {
      filterTower.mybuild = true
    } else if (check == 1 & filterTower.mybuild == true) {
      filterTower.mybuild = false
    } else if (check == 2 & filterTower.inprocess != true) {
      filterTower.inprocess = true
    } else if (check == 2 & filterTower.inprocess == true) {
      filterTower.inprocess = false
    } else if (check == 3 & filterTower.lorem != true) {
      filterTower.lorem = true
    } else if (check == 3 & filterTower.lorem == true) {
      filterTower.lorem = false
    }

    setFesh({ ...fesh, fesh: '' });
    fesh.fesh = '';
    /*     const canvas = document.getElementById("3dTower");
        const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height); */
    // console.log('check',check);
    // console.log('filterTower',filterTower);
    return (
      <></>
    )
  }
  function openModals(value) {
    console.log('sssssssssssssssssssss', projectActive.pf_project_id);
    if (projectActive.pf_project_id !== '' && projectActive.pf_project_id !== null && projectActive.pf_project_id !== undefined) {
      switch (value) {
        case 'close':

          setOpenModal('open');
          break;
        default:
          setOpenModal('close');
          break;
      }
    } else {
      Swal.fire(
        'warning!',
        'Please select a project.',
        'warning'
      )
      /*    Swal.fire({
           title: 'Are you sure?',
           text: "You won't be able to revert this!",
           icon: 'warning',
           showCancelButton: true,
           confirmButtonColor: '#3085d6'
         }) */
      setOpenModal('close');
    }


  }
  function callBackModalOp(value) {
    setOpenModal(value);
    settest({ test, test: '' })
    test.test = ''
  }
  function callBackFucEditModal(value) {
    console.log('ตัวที่กลับมา', value);
    if (value.mode === 'project_plan') {
      /*   reportProjectTimeline(value.id) */
    }
    if (value.mode === 'user_map') {
      /* reportMapingUser(value.id) */
    }
  }

  function checkCategoryIs() {
    setOnCategory(!onCategory);
    sessionStorage.setItem('isSelct', onCategory)
    // console.log(onCategory);
    // if (onCategory == true) {
    //   setOnCategory(false);
    // } else {
    //   setOnCategory(true);
    //   console.log(onCategory);
    // }
    // setFesh({...fesh,fesh:''});
    // fesh.fesh = '';
  }

  function checkCategoryColor(color) {
    if (color == 1 & checkCategory.all != true) {
      checkCategory.all = true
      checkCategory.red = true
      checkCategory.blue = true
      checkCategory.yellow = true
      checkCategory.green = true
      checkCategory.white = true
    } else if (color == 1 & checkCategory.all != false) {
      checkCategory.all = false
      checkCategory.red = false
      checkCategory.blue = false
      checkCategory.yellow = false
      checkCategory.green = false
      checkCategory.white = false
    } else if (color == 2 & checkCategory.red != true) {
      checkCategory.red = true
    } else if (color == 2 & checkCategory.red != false) {
      checkCategory.red = false
    } else if (color == 3 & checkCategory.blue != true) {
      checkCategory.blue = true
    } else if (color == 3 & checkCategory.blue != false) {
      checkCategory.blue = false
    } else if (color == 4 & checkCategory.yellow != true) {
      checkCategory.yellow = true
    } else if (color == 4 & checkCategory.yellow != false) {
      checkCategory.yellow = false
    } else if (color == 5 & checkCategory.green != true) {
      checkCategory.green = true
    } else if (color == 5 & checkCategory.green != false) {
      checkCategory.green = false
    } else if (color == 6 & checkCategory.white != true) {
      checkCategory.white = true
    } else if (color == 6 & checkCategory.white != false) {
      checkCategory.white = false
    }
    if (checkCategory.red == true & checkCategory.blue == true & checkCategory.yellow == true & checkCategory.green == true & checkCategory.white == true) {
      checkCategory.all = true
    } else {
      checkCategory.all = false
    }

    let tempData = towerJSON;

    //console.log(checkCategory)
    let test = ['red', 'blue', 'yellow', 'green', '#FFFFFF'];
    if (checkCategory.all === false) {
      test = [];
    }
    if (checkCategory.red == true) {
      let checkHave = test.filter((e) => { return e == 'red' });
      if (checkHave.length == 0) {
        test.push('red');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'red' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'red')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.blue == true) {
      let checkHave = test.filter((e) => { return e == 'blue' });
      if (checkHave.length == 0) {
        test.push('blue');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'blue' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'blue')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.yellow == true) {
      let checkHave = test.filter((e) => { return e == 'yellow' });
      if (checkHave.length == 0) {
        test.push('yellow');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'yellow' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'yellow')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.green == true) {
      let checkHave = test.filter((e) => { return e == 'green' });
      if (checkHave.length == 0) {
        test.push('green');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'green' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'green')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.white == true) {
      let checkHave = test.filter((e) => { return e == '#FFFFFF' });
      if (checkHave.length == 0) {
        test.push('#FFFFFF');
      }
    } else {
      let checkHave = test.filter((e) => { return e == '#FFFFFF' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == '#FFFFFF')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }




    let resultData = tempData.filter((e) => { return test.includes(e.color) });
    //console.log(resultData)
    setJsonArea(resultData);

    setFesh({ ...fesh, fesh: '' });
    fesh.fesh = '';
    return (
      <></>
    )
  }

  /*  window.addEventListener('mousemove', (event) => {
     console.log(event.clientX,event.clientY)
   mousePos = { x: event.clientX, y: event.clientY };
   mousePosText.textContent = `(${mousePos.x}, ${mousePos.y})`;
 }); */


  async function historySetData(data) {
    /*   let list = jsonArea
      let filter = list.filter((e) => e === data.temp)
      console.log('filter',filter);
      if(filter.length > 0){
        let finIndex =  list.findIndex((e) => e === data.temp)
        if(finIndex > -1)
        {
          list[finIndex].isHistory = true
        }
      } */
    setJsonArea([data.temp])
    settest({ ...test, test: '' });
    test.test = '';
  }

  function personalInfo() {

    // console.log('test');
    // settest({ ...test, test: '' });
    // test.test = '';
  }

  async function resetDataTower(data) {
    setJsonArea(towerJSON)
  }

  const [modeldefcon, setmodeldefcon] = useState({
    isOpen: false
  });

  const [colordefcon, setcolordefcon] = useState({ color: '#0DB6FF', isuse: false })


  function returncolordefcon(color, isuse) {

    setcolordefcon({
      ...colordefcon,
      color: color,
      isuse: isuse
    })
    colordefcon.color = color
    colordefcon.isuse = isuse
  }



  function callBack(value) {
    console.log('project_progress', value);
    setProjectActive({
      ...projectActive,
      pf_project_id: value.pf_project_id,
      project_progress: value.project_progress || 0,
      spaceship_size: value.spaceship_size,
      spaceship_name: value.spaceship_name,
      pp_start_date: value.pp_start_date,
      pp_finish_date: value.pp_finish_date,
      federation_name: value.federation_name,
      pp_team_member: value.pp_team_member,
      ul_file_name: value.ul_file_name,
      ul_file_path: (value.ul_file_path !== '' && value.ul_file_path !== null) ? `${Configs.TTT_Game_Service_IMG}${value.ul_file_path}` : null,
    })

    /*  settest({test,test:''})
     test.test = '' */
  }

  function setMenuValue(value) {
    sessionStorage.setItem('isMenu', value)
  }

  const [checkOnModal, setCheckOnModal] = useState({
    isOpen: false
  })

  return (
    <>

      {/*       <canvas id='3dTower'></canvas> */}
      <div className="bg" />

      <div className='page-content'></div>

      <LandingPagestage
        display={'tower'}
        setMenuValue={setMenuValue}
        setCheckOnModal={setCheckOnModal}
      />

      {/* <i class="fa fa-square" aria-hidden="true" style={{color:"transparent",border:'1px solid white',borderRadius:'3px',height:'12px',width:'12px'}}></i> */}

      <LineModal
        dataTower={jsonArea}
        isRender={stateRender}
        checkCategory={onCategory}
        defcon={modeldefcon.isOpen}
        historySetData={historySetData}
        resetDataTower={resetDataTower}
        checkOnModal={checkOnModal.isOpen}
        />

    {/*   <Carddefcon
        isOpen={modeldefcon.isOpen}
        returncolordefcon={returncolordefcon}
      /> */}

      <div className='frame-filter' style={{display: `${checkOnModal.isOpen ? 'none' : ''}`}}>
        <div className='filter-my-build'>
          <button className='check-box' onClick={() => { checkBox(1) }} style={{ backgroundColor: `${filterTower.mybuild == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>MY BUILDING</span>
          <span className='go-my-build'>goto</span>
        </div>

        <button onClick={() => { checkCategoryIs() }} className='filter-my-build' style={{ marginTop: '15px', textAlign: "start" }}>PROJECT CATEGORY
          <i class="fa fa-angle-down" aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '68px' }}></i>
        </button>

        <div className={`filter-my-build ${onCategory ? 'opened' : 'close'}`}>
          <button className='check-box' id="all" onClick={() => { checkCategoryColor(1) }} style={{ backgroundColor: `${checkCategory.all == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(1) }}>ALL</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(2) }} style={{ backgroundColor: `${checkCategory.red == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(2) }}>RED</span><br />
          <button className='check-box' onClick={() => { checkCategoryColor(3) }} style={{ backgroundColor: `${checkCategory.blue == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(3) }}>BLUE</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(4) }} style={{ backgroundColor: `${checkCategory.yellow == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(4) }}>YELLOW</span><br />
          <button className='check-box' onClick={() => { checkCategoryColor(5) }} style={{ backgroundColor: `${checkCategory.green == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(5) }}>GREEN</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(6) }} style={{ backgroundColor: `${checkCategory.white == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(6) }}>WHITE</span>
        </div>


        <div className='filter-my-build' style={{ marginTop: '15px', height: '80px' }}>
          <button className='check-box' onClick={() => { checkBox(2) }} style={{ backgroundColor: `${filterTower.inprocess == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>IN PROCESS</span><div></div>
          <button className='check-box' onClick={() => { checkBox(3) }} style={{ backgroundColor: `${filterTower.lorem == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>LOREM</span>
        </div>
      </div>

      <ModalProject
        openModal={openModal}
        callBackModalOp={callBackModalOp}
        callBackFucEditModal={callBackFucEditModal}
        projectTemp={projectActive}
      /*   isSave = {isSaves} */
      />

    </>
  )
}

export default LandingPageGame
