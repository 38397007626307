import React, { useEffect, useState, useRef } from 'react';
import step5A from '../image/step5-a.png';
import step71 from '../image/step7-1.png';
import step711 from '../image/step7-11.png';

function HowToPlayStep7(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step5A} alt="" style={{ bottom: '-7px', left: '-4px', width: '20%' }} />
                <img src={step71} style={{width: '55%', position: 'absolute', top: '17.5%', left: '25%'}}></img>
                <div className='howToPlayStep2RedLineNo' style={{ top: '112px', left: '557px', width: '20px', height: '20px', fontSize: '11px'}}>02</div>
                <div className='howToPlayStep1Detail' style={{ top: '270px', left: '475px', width: '600px',height: '130px      ' }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1.5%' }}><div style={{ fontWeight: '700' }}>ADD MODULE</div><div>กดปุ่ม Add Module ตรงไอคอนด้านล่าง เมื่อกดเข้ามาแล้วจะมีให้ใส่ชื่อ Module ตามที่เราต้องการกรอก หลังจากนั้นให้กด Save</div></div>
                </div>
                <img src={step711} style={{width: '9%', position: 'absolute', top: '50.5%', left: '47.5%'}}></img>
                <div className='howToPlayStep1Detail' style={{ top: '410px', left: '475px', width: '600px' }}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1.5%' }}><div style={{ fontWeight: '700' }}>เพิ่มเมนู และ Type เมนู</div><div>เมื่อเพิ่ม Module มาแล้ว ให้กด Add Menu โดยจะต้องระบุ Type ของเมนูนั้นๆ และกรอกชื่อเมนู</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '510px', left: '475px', width: '600px' }}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1.5%' }}><div style={{ fontWeight: '700' }}>กรอกระยะเวลาในการทำ Project</div><div>เมื่อกรอกชื่อและระบุ Type ของเมนูได้แล้ว ให้มากรอกระยะเวลาของการทำ Project และกรอกวันที่จริงในการทำ Project และกด Save</div></div>
                </div>
                <svg width="250" height="200" style={{ position: 'absolute', top: '275px', left: '200px'}}>
                    <line x1="0" y1="200" x2="175" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                    <line x1="175" y1="50" x2="240" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                </svg>
                <div style={{position: 'absolute',position: 'absolute', top: '321px', left: '440px', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#0047FF' }}></div>
            </div>
        </>
    );
}

export default HowToPlayStep7;