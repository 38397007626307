import React, { useState, useEffect, useRef } from 'react';
import '../project/detailProject.css'
import axios from "axios";
import moment from "moment";
import proIcon from '../../../../assets/img/icon/Group11.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Color } from 'three';
import Configs from "../../../../config";
const DetailProject = ({ isOpen, check,tempValue }) => {
    const [hasMore, setHasMore] = useState(true);
    const [proData, setProData] = useState([
        /*  {
             proCode: "NX001",
             proName: "TTT Spaceship system 1",
             proPeriodStart: "Tue May 21 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue July 30 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: true,
             proProgress: 100,
             proBudget: 100000
         },
         {
             proCode: "NX002",
             proName: "TTT Spaceship system 2",
             proPeriodStart: "Tue Jan 5 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Apr 30 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 70,
             proBudget: 100000
         },
         {
             proCode: "NX003",
             proName: "TTT Spaceship system 3",
             proPeriodStart: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Mar 10 2024 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 65,
             proBudget: 100000
         },
         {
             proCode: "NX004",
             proName: "TTT Spaceship system 4",
             proPeriodStart: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 22,
             proBudget: 100000
         },
         {
             proCode: "NX005",
             proName: "TTT Spaceship system 5",
             proPeriodStart: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 19,
             proBudget: 100000
         },
         {
             proCode: "NX006",
             proName: "TTT Spaceship system 6",
             proPeriodStart: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 5,
             proBudget: 100000
         },
         {
             proCode: "NX007",
             proName: "TTT Spaceship system 7",
             proPeriodStart: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proPeriodEnd: "Tue Oct 10 2023 11:49:19 GMT+0700 (Indochina Time)",
             proStatus: false,
             proProgress: 0,
             proBudget: 100000
         }, */
    ])
    const [onSearch, setOnSearch] = useState({
        show: 7,
        current: 1,
    });
    const [countProject, setCountProject] = useState(0)



    async function getProjectByUserID(page, enties, onSearch) {
        let user_id = sessionStorage.getItem('user_id');
        console.log('เช็คหน่อยครับ',tempValue);
        var temp = {
            user_id: tempValue.user_id,
            page: page || 1,
            enties: 999999/* enties || 7 */,

        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/getProjectByUserID`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }, data: temp
        })
            .then(async function (response) {
             /*    console.log('ProjectTIME', response.data);
                setProData(response.data.data) */
                setProData((prevItems) => [...prevItems, ...response.data.data]);
                setCountProject(response.data.count || 0)
                setHasMore(response.data.isstop)
                setOnSearch({
                    ...onSearch,
                    current: page || 1,
                    show: enties || 7,
                });

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
       /*  console.log('isOpen',isOpen); */
        if (isOpen === true) {
            setProData([]);
            setCountProject(0)
            setHasMore(true)
            setOnSearch({
                ...onSearch,
                current: 1,
                show:  7,
            });

            getProjectByUserID(1, 7)
        }else{
            setProData([]);
            setCountProject(0)
        }

    }, [isOpen])


    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        (check === 'PROJECT' ? (<>
            <div className="detailProject" style={{ visibility: `${isOpen === true && check === 'PROJECT' ? 'visible' : 'hidden'}` }}>
                <div id="scrollableindiv" style={{ height: 600, overflow: "auto" }}>
                    <InfiniteScroll
                        dataLength={proData.length}
                        next={() => {
                            getProjectByUserID(onSearch.current + 1, onSearch.show);
                        }}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={<p>Item {countProject}/{countProject} {/* No more items */}</p>}
                        scrollThreshold={0.8}
                        scrollableTarget="scrollableindiv"

                    >
                       {proData.map((e, index) => {


let color_progress = 'red';
if(parseFloat(e.project_progress) <= 25){
 color_progress = 'red';
}else if(parseFloat(e.project_progress) <= 50){
 color_progress = 'orange';
}else if(parseFloat(e.project_progress) <= 75){
 color_progress = 'yellow';
}else if(parseFloat(e.project_progress) <= 100){
 color_progress = 'green';
}
 //console.log('parseFloat(e.project_progress)',parseFloat(e.project_progress));
 return (
     <div className="proBox">
         <div className="row setProRow">
             <div className='col-9 setProCol'>

                 <div className='proNameBox'>
                     <div className='proIcon' style={{ backgroundImage: `url(${proIcon})` }}></div>
                     <div className='proName'>{e.project_name}</div>

                 </div>
                 <div className='proCodeHead'>SpaceShip Name : {e.spaceship_name ? e.spaceship_name : '-'}</div>
                 <div className='proPeriod'>PERIOD : {e.pp_start_date ? moment(e.pp_start_date).format("DD MMM YYYY") : '-'} - {e.pp_finish_date ? moment(e.pp_finish_date).format("DD MMM YYYY") : '-'}</div>
             </div>
             <div className='col-3 setProCol'>

                 <div className='row' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                     <div className='proBudget' >STATUS :  <font style={{ color: `${e.pros_color}` }}> {e.pros_name} </font>  </div>
                 </div>
                 <div className='row mt-2'>
                     {' '}
                 </div>
                 <div className='row' style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-7.5px' }}>
                     <div className='proBudget' style={{ Color: 'FFF', fontFamily: 'Poppins', fontSize: '16px' }} >PROGRESS : <font style={{ color: `${color_progress}` }}> {parseFloat(e.project_progress)}%</font> </div>
                 </div>
                 <div className='row' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                     <div className='proBudget'>BUDGET : {numberWithCommas(parseFloat(e.project_budget || 0))} BAHT</div>
                 </div>


             </div>
         </div>
     </div>
 )
})}
                    </InfiniteScroll>
                </div>
            </div>
        </>) : (<></>))
    )
}

export default DetailProject