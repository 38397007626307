import React, { useState, useEffect } from 'react';
import '../../assets/css/infodetail.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Title from "../../components/customElement/headertitle";
import StoryImg from "./img/ImgInfoStory.png";
import StoryImg_2 from "./img/TTTStory.png";
import { useParams } from "react-router";
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';
import axios from "axios";
import Configs from "../../config";

const Infodetail = (params) => {
  let new_id = useParams();
  console.log('paramsparams', new_id);

  async function getNewsINfo() {
    await axios({
      method: "get",
      url: `${Configs.TTT_Game_Service}/api/news/getNewsByID/${new_id.id}`,
      headers: {
        /*  Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        if (response.data) {

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const [modeMobile, setModeMobile] = useState(false);

  useEffect(() => {

    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      setModeMobile(true);
    }
    else {
      setModeMobile(false);
    }
  })


  const [mobileMenu, setMobileMenu] = useState(false);
  const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
  const [mobileLogin, setMobileLogin] = useState(false);
  const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
  const [mobileRegister, setMobileRegister] = useState(false);
  const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };



  return (
    <>
      {modeMobile ? (<>
        <HeaderTTTMobile
           mobileMenu={mobileMenu}
           toggle_mobileMenu={toggle_mobileMenu}
           mobileLogin={mobileLogin}
           toggle_mobileLogin={toggle_mobileLogin}
           mobileRegister={mobileRegister}
           toggle_mobileRegister={toggle_mobileRegister}
          />

        <img className='bg-information' src="../../assets/img/bg/imgmian.png" alt="" />
        <div className='bg-detail' style={{ left: '-10%', top: '8%', position: 'fixed',width:'90%',overflow:'auto',height:'100%' }}>
          <div style={{ width: '100%', fontSize: '10px' }}>
            <Title name='TTT SPACESHIP STORY' no={1} fix={300} imglong={130} top={7.5} imgbox={150} imgcorner={10} />
          </div>
          <img src={StoryImg_2} style={{ width: '100%', marginBottom: '-6%', marginTop: '8%' }} />
          <div className='box-detail' style={{marginBottom:'14%'}}>

            <div className='text-heading'>“จุดเริ่มต้นของ TTT SPACESHIP</div>
            <div className='text-detailt'>
              ในอวกาศที่กว้างใหญ่ ที่ดวงดาวส่องแสงทับซ้อนกันเสมือนอัญมณีที่กระจายอยู่ ยุคของการสำรวจ และการผจญภัยในอวกาศได้เกิดขึ้นแล้วบนจักรวาล ซึ่งเป็นเวลาที่การท้าทายของความรู้ ความแข็งแกร่ง และการค้นพบที่ซ่อนอยู่ในพื้นที่ที่กว้างใหญ่ของจักรวาล ในยุคของการสำรวจ และการผจญภัยของอวกาศนี้ ได้กำเนิดอีกหนึ่งกิจกรรมที่ยิ่งใหญ่เกิดขึ้น คือ "การแข่งขันแห่งความเป็นผู้นำ” ซึ่งเป็นการทดสอบระดับตำนาน ที่รวบรวมกลุ่มของผู้เดินทางที่มีความเชี่ยวชาญ และมีความกล้าหาญพร้อมที่จะผจญภัยจากกาแลคซี่ต่าง ๆ เพื่อแข่งขันในการเดินทางที่เรียกว่าการผจญภัยอันยิ่งใหญ่ของจักรวาล
              <br /><br />
              ในยุคของความเป็นไปได้ที่ไม่มีขีดจำกัดนี้ การสำรวจ และการผจญภัยอาจพบเจอกับเหตุการณ์น่าตื่นเต้นต่าง ๆ มากมาย รวมไปถึงสิ่งที่ทำให้คุณอาจสูญเสียบางสิ่งไป เป็นเหตุการณ์ต่าง ๆ ที่ไม่สามารถคาดเดาได้ การเตรียมตัวสำหรับการผจญภัยในแต่ละครั้งนั้น จึงเป็นสิ่งที่สำคัญสำหรับนักผจญภัย ไม่ว่าจะเป็นเรื่องของการมี FEDERATION ที่ให้การสนับสนุนยานอวกาศของทีมนักผจญภัยในการเดินทางไปกับการทำภารกิจต่าง ๆ ของการผจญภัยในทุก ๆ รอบ หรือสมาชิกของยานอวกาศที่มีความรู้ ความแข็งแกร่ง และความสามารถที่จะช่วยนำพายานอวกาศของคุณผ่านไปในทุก ๆ ช่วงของเหตุการณ์ต่าง ๆ ทั้งเรื่องดี และเรื่องร้าย
              <br /><br />
              โดยการผจญภัยไปทำภารกิจในแต่ละรอบเจ้าของยานอวกาศ หรือหัวหน้ายานอวกาศสามารถเลือกสมาชิกของยานอวกาศเพื่อเข้าไปทำภารกิจในแต่ละภารกิจตามความเหมาะสมกับขนาดของยานอวกาศ ซึ่งสมาชิกแต่ละคนในทีมมาพร้อมกับทักษะ และประสบการณ์ที่ไม่เหมือนกัน เพราะฉะนั้นแล้วการเลือกผู้ร่วมเดินทางไปกับคุณด้วยนั้น คุณควรที่จะพิจารณาให้เป็นอย่างดี เพราะคุณต้องเดินทางในที่ที่เต็มไปด้วยเหตุการณ์ที่คุณไม่อาจคาดเดาได้นั้นเอง รวมถึงการมีสมาชิกทีมที่ช่วยกันรับรู้ถึงปัญหา และพยายามช่วยกันแก้ไขปัญหาไปด้วยกันคงจะเป็นเรื่องที่ดีไม่น้อย
              <br /><br />
              ทั้งนี้ที่ทางแอดมินหวังว่าทุก ๆ ท่านจะเข้ามาร่วมการผจญภัยไปกับพวกเราใน TTT SPACESHIP แห่งนี้นะครับ บทความข้างต้นเป็นเพียงแค่ส่วนหนึ่งของการผจญภัย บทความบทต่อไปจะเป็นอย่างไร อย่าลืมติดตามกันด้วยน้า
            </div>
          </div>
        </div>
      </>) : (<>

        <HeaderTTT />

        <img className='bg-information' src="../../assets/img/bg/imgmian.png" alt="" />
        <div className='bg-detail' >
          <div style={{ width: '100%' }}>

            <Title name='TTT SPACESHIP STORY' no={1} fix={300} imglong={240} top={7.5} imgbox={150} imgcorner={10} />
          </div>
          <img src={StoryImg} style={{ width: '100%', marginBottom: '-6%', marginTop: '2%' }} />
          <div className='box-detail'>

            <div className='text-heading'>“จุดเริ่มต้นของ TTT SPACESHIP</div>
            <div className='text-detailt'>
              ในอวกาศที่กว้างใหญ่ ที่ดวงดาวส่องแสงทับซ้อนกันเสมือนอัญมณีที่กระจายอยู่ ยุคของการสำรวจ และการผจญภัยในอวกาศได้เกิดขึ้นแล้วบนจักรวาล ซึ่งเป็นเวลาที่การท้าทายของความรู้ ความแข็งแกร่ง และการค้นพบที่ซ่อนอยู่ในพื้นที่ที่กว้างใหญ่ของจักรวาล ในยุคของการสำรวจ และการผจญภัยของอวกาศนี้ ได้กำเนิดอีกหนึ่งกิจกรรมที่ยิ่งใหญ่เกิดขึ้น คือ "การแข่งขันแห่งความเป็นผู้นำ” ซึ่งเป็นการทดสอบระดับตำนาน ที่รวบรวมกลุ่มของผู้เดินทางที่มีความเชี่ยวชาญ และมีความกล้าหาญพร้อมที่จะผจญภัยจากกาแลคซี่ต่าง ๆ เพื่อแข่งขันในการเดินทางที่เรียกว่าการผจญภัยอันยิ่งใหญ่ของจักรวาล
              <br /><br />
              ในยุคของความเป็นไปได้ที่ไม่มีขีดจำกัดนี้ การสำรวจ และการผจญภัยอาจพบเจอกับเหตุการณ์น่าตื่นเต้นต่าง ๆ มากมาย รวมไปถึงสิ่งที่ทำให้คุณอาจสูญเสียบางสิ่งไป เป็นเหตุการณ์ต่าง ๆ ที่ไม่สามารถคาดเดาได้ การเตรียมตัวสำหรับการผจญภัยในแต่ละครั้งนั้น จึงเป็นสิ่งที่สำคัญสำหรับนักผจญภัย ไม่ว่าจะเป็นเรื่องของการมี FEDERATION ที่ให้การสนับสนุนยานอวกาศของทีมนักผจญภัยในการเดินทางไปกับการทำภารกิจต่าง ๆ ของการผจญภัยในทุก ๆ รอบ หรือสมาชิกของยานอวกาศที่มีความรู้ ความแข็งแกร่ง และความสามารถที่จะช่วยนำพายานอวกาศของคุณผ่านไปในทุก ๆ ช่วงของเหตุการณ์ต่าง ๆ ทั้งเรื่องดี และเรื่องร้าย
              <br /><br />
              โดยการผจญภัยไปทำภารกิจในแต่ละรอบเจ้าของยานอวกาศ หรือหัวหน้ายานอวกาศสามารถเลือกสมาชิกของยานอวกาศเพื่อเข้าไปทำภารกิจในแต่ละภารกิจตามความเหมาะสมกับขนาดของยานอวกาศ ซึ่งสมาชิกแต่ละคนในทีมมาพร้อมกับทักษะ และประสบการณ์ที่ไม่เหมือนกัน เพราะฉะนั้นแล้วการเลือกผู้ร่วมเดินทางไปกับคุณด้วยนั้น คุณควรที่จะพิจารณาให้เป็นอย่างดี เพราะคุณต้องเดินทางในที่ที่เต็มไปด้วยเหตุการณ์ที่คุณไม่อาจคาดเดาได้นั้นเอง รวมถึงการมีสมาชิกทีมที่ช่วยกันรับรู้ถึงปัญหา และพยายามช่วยกันแก้ไขปัญหาไปด้วยกันคงจะเป็นเรื่องที่ดีไม่น้อย
              <br /><br />
              ทั้งนี้ที่ทางแอดมินหวังว่าทุก ๆ ท่านจะเข้ามาร่วมการผจญภัยไปกับพวกเราใน TTT SPACESHIP แห่งนี้นะครับ บทความข้างต้นเป็นเพียงแค่ส่วนหนึ่งของการผจญภัย บทความบทต่อไปจะเป็นอย่างไร อย่าลืมติดตามกันด้วยน้า
            </div>
          </div>
        </div>
      </>)}



    </>

  )
}

export default Infodetail