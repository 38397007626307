

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
import { after, before } from "lodash";
/* import QRCode from "qrcode"; */

async function SRSreport(data, id, mode, summaryofchange) {

    const compressImage = (imageUrl, maxWidth = 1920, maxHeight = 1080) => {
        /*  const mmToPx = (mm) => (mm * 96) / 25.4; */

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imageUrl;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let wpx = img.width;
                let hpx = img.height;
                const mwpx = /* mmToPx(maxWidth) */maxWidth;
                const mhpx = /* mmToPx(maxHeight) */maxHeight;
                /*  console.log('w',mwpx,wpx);
                 console.log('h',mhpx,hpx); */

                if (wpx > hpx) {
                    if (wpx > mwpx) {
                        hpx = Math.round((hpx * mwpx) / wpx);
                        wpx = mwpx;
                    }
                } else {
                    if (hpx > mhpx) {
                        wpx = Math.round((wpx * mhpx) / hpx);
                        hpx = mhpx;
                    }
                }

                canvas.width = wpx;
                canvas.height = hpx;
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, wpx, hpx);
                ctx.drawImage(img, 0, 0, wpx, hpx);

                resolve(canvas.toDataURL('image/jpeg', 0.8));


            };

            img.onerror = () => {
                reject(new Error('Failed to load image.'));
            };
        });
    };









    let imghead = noimg

    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    ///////////กำหนดตั้งค่าหน้าแนวตั้ง
    let padding = 20////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 297///สูง
        , page = 1
        , r = 194
        , g = 194
        , b = 194
        , endpage = 260,
        his = [],
        img = {}
    let heightpage = 34

    if (data.img.length > 0) {
        img = data.img[0]
    }

    /* console.log(data.project); */
    let colordrop = [
        {
            before: '#676767',
            after: '#DEDEDE'
        },
        {
            before: '#FF00E5',
            after: '#FBDCFF'
        },
        {
            before: '#BA84FF',
            after: '#CCD9FA'
        },
        {
            before: '#00A5FF',
            after: '#CDEEFF'
        },
        {
            before: '#259B24',
            after: '#D3FFCF'
        },
        {
            before: '#FFEB3B',
            after: '#FFFDCF'
        },
        {
            before: '#FF8000',
            after: '#FFE0C1'
        },
        {
            before: '#FF0000',
            after: '#FFD9D9'
        },
    ]
    if (data.project.federation_color !== null) {
        let selectcolor = colordrop.findIndex((e) => e.before == data.project.federation_color || e.after == data.project.federation_color)
        console.log(selectcolor);
        if (selectcolor !== -1) {
            let hex = colordrop[selectcolor].after.replace(/^#/, '');
            let bigint = parseInt(hex, 16);

            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        }

    }
    console.log(r, g, b);
    Shot('fs', 29)

    Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`Software Requirements Specification`), 'l')
    Shot('t', CutPadding('w', 0, 2), 76, TexttoString(`Software Requirements Specification`), 'l')
    Shot('lw', 0.2)
    doc.line(CutPadding('w', 0, 2), 76 + 4, CutPadding('w', 100, 2), 76 + 4);
    Shot('t', CutPadding('w', 0, 2), 88, TexttoString(data.project.project_name), 'l')
    Shot('t', CutPadding('w', 0, 2), 88, TexttoString(data.project.project_name), 'l')

    Shot('fs', 18)

    Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
    Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
    Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
    Shot('t', CutPadding('w', 35, 2), 160, TexttoString(`Version`), 'r')
    Shot('t', CutPadding('w', 35, 2), 166, TexttoString(`Author`), 'r')
    Shot('t', CutPadding('w', 35, 2), 172, TexttoString(`Updated Date`), 'r')
    Shot('t', CutPadding('w', 49, 2), 160, TexttoString(`:`), 'r')
    Shot('t', CutPadding('w', 49, 2), 166, TexttoString(`:`), 'r')
    Shot('t', CutPadding('w', 49, 2), 172, TexttoString(`:`), 'r')


    Shot('t', CutPadding('w', 51, 2), 160, TexttoString(`${parseFloat(data.version.version) + 1}.0`), 'l')
    Shot('t', CutPadding('w', 51, 2), 166, TexttoString(`${data.version.name}`), 'l')
    Shot('t', CutPadding('w', 51, 2), 172, TexttoString(`${moment(new Date()).add('year', 543).format('DD/MM/YYYY')}`), 'l')





    Shot('fs', 16)

    Shot('t', CutPadding('w', 0, 2), 260, TexttoString(`TTT Brother CO., LTD. `), 'l')
    Shot('t', CutPadding('w', 0, 2), 260, TexttoString(`TTT Brother CO., LTD. `), 'l')
    Shot('t', CutPadding('w', 0, 2), 266, TexttoString(`711/1-3, PRACHARAT SAI 1 ROAD, BANGSUE, BANGKOK 10800, THAILAND `), 'l')
    Shot('t', CutPadding('w', 0, 2), 272, TexttoString(`Tel. 085-818-8910 `), 'l')
    Shot('t', CutPadding('w', 0, 2), 278, TexttoString(`Fax. – `), 'l')




    Shot("add");
    page += 1

    Shot('fs', 16)


    Shot('fs', 29)

    Shot('t', CutPadding('w', 50, 2), 40, TexttoString(`Software Requirements Specification`), 'c')
    Shot('t', CutPadding('w', 50, 2), 40, TexttoString(`Software Requirements Specification`), 'c')

    Shot('t', CutPadding('w', 50, 2), 49, TexttoString(`For`), 'c')
    Shot('t', CutPadding('w', 50, 2), 49, TexttoString(`For`), 'c')

    /*  Shot('fs', 29) */
    Shot('tc', 0, 0, 0)
    Shot('t', CutPadding('w', 50, 2), 58, TexttoString(data.project.project_name), 'c')
    Shot('t', CutPadding('w', 50, 2), 58, TexttoString(data.project.project_name), 'c')
    Shot('fs', 16)
    Shot('tc', 0, 0, 0)

    let Prepared = '', Verified = '', Approved = '', Customer = ''
    let Preparedpos = '', Verifiedpos = '', Approvedpos = '', Customerpos = ''
    let Prepareddate = '', Verifieddate = '', Approveddate = '', Customerdate = ''

    for (let i of data.srs) {

        if (i.sp_operation === 'prepared_sp_operation') {
            Prepared = i.sp_name || ''
            Preparedpos = i.sp_position || ''
            Prepareddate = moment(i.sp_date_sign).add('year', 543).format('DD/MM/YYYY') || ''
        }
        if (i.sp_operation === 'verified_sp_operation') {
            Verified = i.sp_name || ''
            Verifiedpos = i.sp_position || ''
            Verifieddate = moment(i.sp_date_sign).add('year', 543).format('DD/MM/YYYY') || ''
        }
        if (i.sp_operation === 'approved_sp_operation') {
            Approved = i.sp_name || ''
            Approvedpos = i.sp_position || ''
            Approveddate = moment(i.sp_date_sign).add('year', 543).format('DD/MM/YYYY') || ''
        }
        if (i.sp_operation === 'customer_approved_sp_operation') {
            Customer = i.sp_name || ''
            Customerpos = i.sp_position || ''
            Customerdate = moment(i.sp_date_sign).add('year', 543).format('DD/MM/YYYY') || ''
        }




    }

    Shot('t', CutPadding('w', 25, 2), 90, TexttoString(`Prepared by`), 'c')


    Shot('t', CutPadding('w', 25, 2), 88 + 20, TexttoString(`${Prepared}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 90 + 20, TexttoString(`(.......................................................................................)`), 'c')
    Shot('t', CutPadding('w', 25, 2), 90 + 28, TexttoString(`${Preparedpos}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 88 + 38, TexttoString(`${Prepareddate}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 90 + 38, TexttoString(`  Date................................................................`), 'c')



    Shot('t', CutPadding('w', 75, 2), 90, TexttoString(`Verified by`), 'c')
    Shot('t', CutPadding('w', 75, 2), 88 + 20, TexttoString(`${Verified}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 90 + 20, TexttoString(`(.................................................................................)`), 'c')
    Shot('t', CutPadding('w', 75, 2), 90 + 28, TexttoString(`${Verifiedpos}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 88 + 38, TexttoString(`${Verifieddate}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 90 + 38, TexttoString(`  Date................................................................`), 'c')



    Shot('t', CutPadding('w', 25, 2), 160, TexttoString(`Approved by`), 'c')
    Shot('t', CutPadding('w', 25, 2), 158 + 20, TexttoString(`${Approved}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 160 + 20, TexttoString(`(.................................................................................)`), 'c')
    Shot('t', CutPadding('w', 25, 2), 160 + 28, TexttoString(`${Approvedpos}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 158 + 38, TexttoString(`${Approveddate}`), 'c')
    Shot('t', CutPadding('w', 25, 2), 160 + 38, TexttoString(`  Date................................................................`), 'c')



    Shot('t', CutPadding('w', 75, 2), 160, TexttoString(`Customer Approved`), 'c')
    Shot('t', CutPadding('w', 75, 2), 158 + 20, TexttoString(`${Customer}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 160 + 20, TexttoString(`(.................................................................................)`), 'c')
    Shot('t', CutPadding('w', 75, 2), 160 + 28, TexttoString(`${Customerpos}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 158 + 38, TexttoString(`${Customerdate}`), 'c')
    Shot('t', CutPadding('w', 75, 2), 160 + 38, TexttoString(`  Date................................................................`), 'c')



    Shot('t', CutPadding('w', 25, 2), 256, TexttoString(`Company Stamp`), 'c')

    Shot('t', CutPadding('w', 25, 2), 256 + 8, TexttoString(`TTT Brother CO., LTD`), 'c')
    Shot('t', CutPadding('w', 25, 2), 256 + 8, TexttoString(`TTT Brother CO., LTD`), 'c')


    Shot('t', CutPadding('w', 75, 2), 256, TexttoString(`Company Stamp`), 'c')
    Shot('t', CutPadding('w', 75, 2), 256 + 8, TexttoString(`(.................................................................................)`), 'c')

    /* doc.insertPage(2)
    page+=1 */

    Shot("add");
    page += 1
    let Historytable = {
        col1: CutPadding('w', 19, -padding),
        col2: CutPadding('w', 16, -padding),
        col3: CutPadding('w', 21, -padding),
        col4: CutPadding('w', 44, -padding),
        name1: 'Revision number',
        name2: 'Revision date',
        name3: 'Author',
        name4: 'Summary of changes',
        loop: 4
    }
    heightpage = 34
    heightpage += 2
    Shot('fs', 18)
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`I. Revision History`), 'l')
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`I. Revision History`), 'l')
    his.push(
        {
            page: page,
            name: `I. Revision History`
        }
    )
    heightpage += 4
    Shot('fs', 16)
    ShotTable('ht', Historytable, heightpage, Historytable.loop, 9, '', 'fd')
    heightpage += 14
    let datahis = 0
    if (data.version.versionlist.length > 0) {

        for (let index = 0; index < data.version.versionlist.length; index++) {
            let i = data.version.versionlist[index]
            /* console.log(i); */
            let cutText1 = doc.splitTextToSize(`${i.fname || ''} ${i.lname || ''}`, CutPadding('w', 0, Historytable.col3 - 23));
            let cutText2 = doc.splitTextToSize(`${i.dv_summary_of_change || ''}`, CutPadding('w', 0, Historytable.col4 - 23));
            /* console.log(cutText1,cutText2); */
            let iscut = cutText1.length > cutText2.length ? cutText1.length : cutText2.length



            for (let cc = 0; cc < iscut; cc += 1) {
                if (heightpage > 265) {
                    newpage(265)
                    datahis += 1
                }

                if (cc === 0) {
                    Shot('t', Colposition('t', Historytable, 1, 'c'), heightpage, TexttoString(`${parseFloat(i.dv_version)}.0`), 'c');
                    Shot('t', Colposition('t', Historytable, 2, 'c'), heightpage, TexttoString(`${moment(i.dv_date).add('year', 543).format('DD/MM/YYYY')}`), 'c');

                }
                if (cutText1.length > cc) {
                    Shot('t', Colposition('t', Historytable, 3, 'c'), heightpage, TexttoString(cutText1[cc]), 'c');
                }
                if (cutText2.length > cc) {
                    Shot('t', Colposition('t', Historytable, 4, 'l', 1), heightpage, TexttoString(cutText2[cc]), 'l');
                }
                heightpage += 6
            }

            Shot('r', Colposition('r', Historytable, 1), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col1 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 2), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col2 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 3), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col3 - padding), (iscut * 6) + 2);
            Shot('r', Colposition('r', Historytable, 4), heightpage - (iscut * 6) - 5, CutPadding('w', 0, Historytable.col4 - padding), (iscut * 6) + 2);
            heightpage += 2
        }

    }












    /*   Shot("add");
      page += 1
      let Documentstable = {
          col1: CutPadding('w', 6, -padding),
          col2: CutPadding('w', 58, -padding),
          col3: CutPadding('w', 18, -padding),
          col4: CutPadding('w', 18, -padding),
          name1: 'No.',
          name2: 'Documents Name',
          name3: 'Date',
          name4: 'Author',
          loop: 4
      }
      heightpage = 34
      heightpage += 2
      Shot('fs', 18)
      Shot('t', CutPadding('w', 0), heightpage, TexttoString(`II. Related Documents`), 'l')
      Shot('t', CutPadding('w', 0), heightpage, TexttoString(`II. Related Documents`), 'l')
      his.push(
          {
              page: page,
              name: `II. Related Documents`
          }
      )
      heightpage += 4
      Shot('fs', 16)
      ShotTable('ht', Documentstable, heightpage, Documentstable.loop, 9, '', 'fd')
  
   */
    /*   Shot("add");
      page += 1
  
  
      heightpage = 34
      heightpage += 2
      Shot('fs', 18)
      Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายการตารางประกอบ`), 'c')
      Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายการตารางประกอบ`), 'c')
      his.push(
          {
              page: page,
              name: `รายการตารางประกอบ`
          }
      )
      heightpage += 4
      Shot('fs', 16)
   */
    /*   Shot("add");
      page += 1
  
      heightpage = 34
      heightpage += 2
      Shot('fs', 18)
      Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายการรูปประกอบ`), 'c')
      Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายการรูปประกอบ`), 'c')
      his.push(
          {
              page: page,
              name: `รายการรูปประกอบ`
          }
      )
      heightpage += 4
      Shot('fs', 16) */

    heightpage = 34
    heightpage += 2
    Shot("add");
    page += 1

    let sortindex = 0
    let unit1 = data.unit1
    for (let li of unit1) {
        newpage(265)
        loopseletdata(li, 1, sortindex + 1)
    }

    let unit2 = data.unit2
    heightpage += 4
    Shot('fs', 18)
    let cutText = doc.splitTextToSize(`ข้อกำหนดความต้องการ (SPECIFIC REQUIREMENT)`, CutPadding('w', 100, -padding));
    his.push(
        {
            page: page,
            name: `${sortindex + 1}. ข้อกำหนดความต้องการ (SPECIFIC REQUIREMENT)`
        }
    )
    let countrow = 0
    for (let c of cutText) {
        newpage(endpage)
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
        countrow += 1
        heightpage += 6
    }
    heightpage += 2
    sortindex += 1

    let cutallunil = ['   ในส่วนนี้จะอธิบายความต้องการที่ระบบจะต้องดำเนินการ โดยแบ่งออกเป็น']
    cutallunil += ` ${unit2.length} ส่วนคือ `
    let lcount = 0
    for (let li of unit2) {
        if (lcount !== 0) {
            if (lcount === unit2.length - 1) {
                cutallunil += ' และ '
            } else {
                cutallunil += ', '
            }
        }

        cutallunil += `${li.header}`
        lcount += 1
    }
    /* console.log(cutallunil); */
    Shot('fs', 16)
    let cutTextlable = doc.splitTextToSize(`${cutallunil}`, CutPadding('w', 100, -padding));

    for (let c of cutTextlable) {
        newpage(endpage)
        Shot('t', CutPadding('w', 0), heightpage, TexttoString(`${c}`), 'l')

        countrow += 1
        heightpage += 6
    }


    /* console.log(cutTextlable); */
    await loopseletdata2(unit2, 2, sortindex)


    let chackrow = his.filter((e) => { return e.name === 'I. Revision History' })
    doc.insertPage(chackrow[0].page + 1 + datahis)
    page += 1
    let numhis = 0
    heightpage = 34
    heightpage += 2
    Shot('fs', 18)
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`Table of contents`), 'l')
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`Table of contents`), 'l')
    heightpage += 8
    doc.setLineDash([0.1, 0.1], 0);
    Shot('fs', 16)
    for (let h of his) {

        Shot('t', CutPadding('w', 0), heightpage, TexttoString(h.name), 'l')
        Shot('t', CutPadding('w', 100), heightpage, TexttoString(h.page + numhis), 'r')
        /* doc.line(CutPadding('w', 0), heightpage+1 , CutPadding('w', 96), heightpage+1 ); */
        heightpage += 6
        if (`I. Revision History` === h.name) {
            numhis = 1
            Shot('t', CutPadding('w', 0), heightpage, TexttoString('Table of contents'), 'l')
            Shot('t', CutPadding('w', 100), heightpage, TexttoString(h.page + numhis), 'r')
            /* doc.line(CutPadding('w', 0), heightpage+1 , CutPadding('w', 96), heightpage+1 ); */
            heightpage += 6

        }
    }

    const mmToPx = (mm) => (mm * 96) / 25.4;
    if (data.img.length > 0) {
        img = await getboximg(data.img[0].fd_img_id)
        imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) */ img
    } else {
        imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg

    }

    for (let i = 0; i <= page; i += 1) {
        if (i > 1) {
            doc.setPage(i)
            sethead()
            setfooter(i, page)
        }

    }


    if (mode === 'version') {
        const dataUri = doc.output('datauristring');
        const binaryData = atob(dataUri.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const file = new File([blob], `SRS_${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD_MM_YY')}.pdf`, { type: 'application/pdf' });
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                project_id: id,
                /*  path_file: response.data.data[0].path, */
                file_name: file.name,
                template_type: 'srs',
                dv_summary_of_change: summaryofchange || null

            }

        })
            .then(function (response) {
                if (response.data) {


                    CheckAndCreateFolderBox(response.data.data, response.data.olddata, file)




                    /*      return true */
                }



            })
            .catch(function (error) {
                console.log(error);

            });
        /*   console.log(file);
          const dataImg = new FormData();
          dataImg.append('Profile', file);
          dataImg.append('typeFile', 'document_version');
          dataImg.append('projectKey', "tttspaceship");
          await axios({
              method: 'post',
              url: `${Configs.TTT_Game_Service}/api/upload/document2`,
              headers: {
                   Authorization: getToken(),
                  'X-TTT': `${Configs.API_TTT}`,
                  'Content-Type': 'application/octet-stream',
              },
              data: dataImg,
          })
              .then(async function (response) {
                  if (response.data.status == true) {
  
                      axios({
                          method: 'post',
                          url: `${Configs.TTT_Game_Service}/api/report/saveversiondoc`,
                          headers: {
                              Authorization: sessionStorage.getItem('access_token'),
                              'X-TTT': `${Configs.API_TTT}`,
                              'Content-Type': 'application/json',
                          },
                          data: {
                              project_id: id,
                              path_file: response.data.data[0].path,
                              file_name: response.data.data[0].orgin_name,
                              template_type: 'srs',
                              dv_summary_of_change: summaryofchange || null
  
                          }
  
                      })
                          .then(function (response) {
                              if (response.data) {
                                  return true
                              }
  
  
  
                          })
                          .catch(function (error) {
                              console.log(error);
  
                          });
  
                  }
  
              })
              .catch(function (error) {
                  console.log(error);
              }); */








    } else {
        Shot('save', `SRS_${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`);
        return true
    }


    async function CheckAndCreateFolderBox(data, olddata, file) {
        let temp = {
            folderLis: ["PROJECT", `PROJECT_${id}`, "PROJECT DOCUMENTS", "SRS"]
        }
        /*  temp.folderLis.push(folder_name) */
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {

                    data.files = file
                    await UploadFiles(data, response.data.folder_id, olddata)

                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }



    async function UploadFiles(item, folder_id, olddata) {
        console.log(item);
        const dataImg = new FormData();
        dataImg.append('File', item.files);
        dataImg.append('folder_id', folder_id);
        dataImg.append('key', "SRS");
        dataImg.append('image_old_list', JSON.stringify(olddata));
        dataImg.append('ref_id', item.id);

        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            },
            data: dataImg
        })
            .then(async function (response) {
                if (response.data) {
                    if (response.data.image_id_list.length > 0) {
                        return true

                    }

                }
            })
            .catch(function (error) {

                console.log(error);
            });
    }

    /*     Shot('save', `SRS_${data.project.project_name} ${moment(new Date()).add('year', 543).format('DD/MM/YY')}.pdf`); */
    async function getboximg(imageid) {
        try{
        const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',
        });

        return URL.createObjectURL(response2.data);
    }catch (error) {

    }
    }



    function loopseletdata(data, lv, textlv) {
        console.log('data', data)
        console.log('Iv', lv)
        console.log('textlv', textlv)
        let count = 1
        for (let i of data) {

            /*  console.log(textlv); */
            let cutText = [], cutw = 2 * (lv - 1), sort = `${textlv}`

            if (i.mode === 'description') {

                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw), -padding));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    heightpage += 2
                    sortindex += 1
                }

                if (i.lable !== undefined) {
                    Shot('fs', 16)

                    cutText = doc.splitTextToSize(lv > 1 ? `${sort}.${count} ${i.lable || ''}` : `     ${i.lable || ''}`, CutPadding('w', 100 - (cutw), -padding));

                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(`${c}`), 'l')
                        heightpage += 6
                    }

                }

                if (i.sub !== undefined) {


                    loopseletdata(i.sub, lv + 1, sortindex)



                }





                /*  console.log(cutText); */
            }

            if (i.mode === 'table') {
                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw), -padding));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    heightpage += 2

                    sortindex += 1
                }
                let cutTextsub = [], cutlength = 0
                if (i.subhead !== undefined) {
                    Shot('fs', 16)
                    cutText = doc.splitTextToSize(`${i.subhead.trim() || ''}`, CutPadding('w', 20/*  - (cutw ) */, -padding));
                    console.log(cutText);
                    cutTextsub = doc.splitTextToSize(`${i.sublable.trim() || ''}`, CutPadding('w', 70 - (cutw), -padding));
                    cutlength = cutText.length > cutTextsub.length ? cutText.length : cutTextsub.length

                    if (heightpage + (cutlength * 6) > endpage) {

                        heightpage += (cutlength * 6)
                        newpage(endpage)
                    }
                    for (let c = 0; c < cutlength; c += 1) {
                        newpage(endpage)
                        if (cutText.length > c) {
                            Shot('t', CutPadding('w', 100 - (80 + cutw)), heightpage, TexttoString(`${cutText[c]}`), 'r')
                            Shot('t', CutPadding('w', 100 - (80 + cutw)), heightpage, TexttoString(`${cutText[c]}`), 'r')
                        }
                        if (cutTextsub.length > c) {
                            Shot('t', CutPadding('w', 100 - (70 + cutw)), heightpage, TexttoString(`${cutTextsub[c]}`), 'l')
                        }
                        heightpage += 6
                    }
                }

                if (i.sub !== undefined) {


                    loopseletdata(i.sub, lv + 1, sortindex)


                }


            }

            if (i.mode === 'table_column') {
                if (i.header !== undefined) {

                    heightpage += 4
                    Shot('fs', 18)
                    cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 100 - (cutw)));
                    his.push(
                        {
                            page: page,
                            name: `${sortindex + 1}. ${cutText[0]}`
                        }
                    )
                    let countrow = 0
                    for (let c of cutText) {
                        newpage(endpage)
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        Shot('t', CutPadding('w', cutw), heightpage, TexttoString(countrow === 0 ? `${sortindex + 1}. ${c}` : `${c}`), 'l')
                        countrow += 1
                        heightpage += 6
                    }
                    /*   heightpage += 2 */

                    sortindex += 1
                }

                console.log(i.datatable);

                let setingtable = {
                    col1: CutPadding('w', 50, -padding),
                    col2: CutPadding('w', 50, -padding),
                    name1: ['ชื่อ - นามสกุล'],
                    name2: ['ตำแหน่ง'],
                    loop: 2,
                    height: 1
                }
                let setingtable2 = {
                    col1: CutPadding('w', 75, -padding),
                    col2: CutPadding('w', 25, -padding),
                    name1: ['ชื่อเอกสาร'],
                    name2: ['Link Download '],
                    loop: 2,
                    height: 1
                }
                let num = 0, set = 0
                for (let c of i.datatable) {
                    /* console.log(textcutname); */
                    if (num > 0) {
                        set = 3
                    }
                    num += 1
                    cuttextseting('ครั้งที่', c[0], 13 - (set * 0.33))
                    cuttextseting('หัวข้อการประชุม', c[1], 32 - set)
                    cuttextseting('สถานที่การประชุม', c[3], 35 - set)
                    cuttextseting('วันที่ - ช่วงเวลา', c[4], 30 - set)
                    if (heightpage + 10 > endpage) {
                        heightpage += 10
                        newpage(endpage)
                    }
                    Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`ผู้เข้าร่วมการประชุม`), 'l')
                    Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`ผู้เข้าร่วมการประชุม`), 'l')
                    heightpage += 2
                    /* Shot('fs', 14) */
                    ShotTable('htc', setingtable, heightpage, setingtable.loop, 7, '', 'fd', setingtable.height)
                    /* Shot('fs', 12) */
                    let cutArray = c[2].split(","), higl = 0, befor = heightpage, countall = 0
                    heightpage += 6
                    for (let ca of cutArray) {
                        let cuting = ca.replace(/\n/g, "")
                        let cut = cuting.split("-");
                        console.log(cut);
                        let textcutname = doc.splitTextToSize(`${cut[0].trim() || ''}`, CutPadding('w', 50, -(padding + 4))), textcutname2 = []
                        if (cut.length > 1) {
                            textcutname2 = doc.splitTextToSize(`${cut[1].trim() || ''}`, CutPadding('w', 50, -(padding + 4)))
                        }
                        higl = textcutname.length
                        if (higl < textcutname2.length) {
                            higl = textcutname2.length
                        }
                        for (let x = 0; x < higl; x += 1) {
                            if (heightpage > endpage) {
                                countall += 2
                                heightpage += 2
                                Shot('r', Colposition('r', setingtable, 1), befor, CutPadding('w', 0, setingtable.col1 - padding), countall);
                                Shot('r', Colposition('r', setingtable, 2), befor, CutPadding('w', 0, setingtable.col2 - padding), countall);
                                newpage(endpage)
                                countall = 0
                                ShotTable('htc', setingtable, heightpage, setingtable.loop, 7, '', 'fd', setingtable.height)
                                befor = heightpage
                                heightpage += 6


                            }
                            if (x < textcutname.length) {
                                Shot('t', Colposition('t', setingtable, 1, 'l', 2), heightpage, TexttoString(textcutname[x]), 'l')
                            }
                            if (x < textcutname2.length) {
                                console.log(textcutname2[x]);
                                Shot('t', Colposition('t', setingtable, 2, 'l', 2), heightpage, TexttoString(textcutname2[x]), 'l')
                            }
                            heightpage += 7
                            countall += 7
                        }

                    }
                    countall += 2
                    heightpage += 2
                    Shot('r', Colposition('r', setingtable, 1), befor, CutPadding('w', 0, setingtable.col1 - padding), countall);
                    Shot('r', Colposition('r', setingtable, 2), befor, CutPadding('w', 0, setingtable.col2 - padding), countall);
                    Shot('fs', 16)
                    if (heightpage + 20 > endpage) {
                        heightpage += 20
                        newpage(endpage)
                    }
                    Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`เอกสารประกอบการประชุม`), 'l')
                    Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`เอกสารประกอบการประชุม`), 'l')
                    heightpage += 2
                    /* Shot('fs', 14) */
                    console.log(c[5]);
                    ShotTable('htc', setingtable2, heightpage, setingtable2.loop, 7, '', 'fd', setingtable2.height)
                    /* Shot('fs', 12) */
                    cutArray = c[5].match(/<<link>>(.*?)<<link>>/g)
                    higl = 0
                    befor = heightpage
                    countall = 0
                    heightpage += 6
                    console.log(cutArray);
                    if (cutArray !== null) {
                        for (let ca of cutArray) {
                            if (heightpage > endpage) {
                                countall += 2
                                heightpage += 2
                                Shot('r', Colposition('r', setingtable2, 1), befor, CutPadding('w', 0, setingtable2.col1 - padding), countall);
                                Shot('r', Colposition('r', setingtable2, 2), befor, CutPadding('w', 0, setingtable2.col2 - padding), countall);
                                newpage(endpage)
                                countall = 0
                                ShotTable('htc', setingtable2, heightpage, setingtable2.loop, 7, '', 'fd', setingtable2.height)
                                befor = heightpage
                                heightpage += 6
                            }
                            let cuting = ca.replace(/\n|<<link>>/g, "")
                            let cut = cuting.split(",");
                            console.log(cut);
                            if (cut.length > 1) {
                                let textcutname = doc.splitTextToSize(`${cut[0].trim() || ''}`, CutPadding('w', 75, -(padding + 4)))
                                let y = heightpage, x = Colposition('t', setingtable2, 2, 'c', -25)
                                let width = doc.getTextWidth('Click Download');
                                let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;
                                y += height * 0.2;
                                doc.link(x, y - height, width, height, { url: cut[1], target: '_blank' });
                                Shot('tc', 103, 103, 254)
                                Shot('t', Colposition('t', setingtable2, 2, 'c', 0), heightpage, TexttoString('Click Download'), 'c')
                                Shot('tc', 0, 0, 0)

                                higl = textcutname.length
                                for (let x = 0; x < higl; x += 1) {
                                    if (heightpage > endpage) {
                                        countall += 2
                                        heightpage += 2
                                        Shot('r', Colposition('r', setingtable2, 1), befor, CutPadding('w', 0, setingtable2.col1 - padding), countall);
                                        Shot('r', Colposition('r', setingtable2, 2), befor, CutPadding('w', 0, setingtable2.col2 - padding), countall);
                                        newpage(endpage)
                                        countall = 0
                                        ShotTable('htc', setingtable2, heightpage, setingtable2.loop, 7, '', 'fd', setingtable2.height)
                                        befor = heightpage
                                        heightpage += 6


                                    }
                                    if (x < textcutname.length) {
                                        Shot('t', Colposition('t', setingtable2, 1, 'l', 2), heightpage, TexttoString(textcutname[x]), 'l')
                                    }

                                    heightpage += 7
                                    countall += 7
                                }

                            }


                        }
                    }

                    console.log(cutArray);
                    countall += 2
                    heightpage += 2
                    Shot('r', Colposition('r', setingtable2, 1), befor, CutPadding('w', 0, setingtable2.col1 - padding), countall);
                    Shot('r', Colposition('r', setingtable2, 2), befor, CutPadding('w', 0, setingtable2.col2 - padding), countall);
                    Shot('fs', 16)

                    heightpage += 6
                    /* heightpage+= 6
                    let textcutname = doc.splitTextToSize(`หัวขอการประชุม : ${c[1] || ''}`, CutPadding('w', 80, -padding)) */

                }
                /* let jsonhead = {} */
                /* if (i.headtable !== undefined) {
                     Shot('fs', 14) 
                 
                    let counth = 0, hht = 0, lh = i.headtable.length
                    let setinghead = {
                        1:CutPadding('w', 7, -padding),
                        2:CutPadding('w', 19, -padding),
                        3:CutPadding('w', 20, -padding),
                        4:CutPadding('w', 15, -padding),
                        5:CutPadding('w', 13, -padding),
                        6:CutPadding('w',28, -padding),
                    }
                    for (let ht of i.headtable) {

                        let nameh = doc.splitTextToSize(`${ht.header || ''}`, CutPadding('w', 100 / lh, -padding));
                        jsonhead[`col${counth + 1}`] = setinghead[counth + 1]
                        jsonhead[`name${counth + 1}`] = nameh

                        if (hht < nameh.length) {
                            hht = nameh.length
                        }
                        counth += 1
                    }

                    jsonhead.loop = counth
                    jsonhead.height = hht
                    if (heightpage + (jsonhead.height * 7) > endpage) {

                        heightpage += (jsonhead.height * 7)
                        newpage(endpage)
                    }
                  
                    ShotTable('htc', jsonhead, heightpage, jsonhead.loop, 7, '', 'fd', jsonhead.height)

                } */

                /*   if (i.datatable !== undefined) {
                       Shot('fs', 12) 
                      let setingtable = {
                          1:CutPadding('w', 7, -padding),
                          2:CutPadding('w', 19, -padding),
                          3:CutPadding('w', 20, -padding),
                          4:CutPadding('w', 15, -padding),
                          5:CutPadding('w', 13, -padding),
                          6:CutPadding('w',28, -padding),
                      }
                      for (let c of i.datatable) {
                          let jsonlable = {}
                          let countl = 0, hlt = 0, ll = c.length, arrlink = []
                          for (let lt of c) {
                              let texttable = lt
                              let replacedText = texttable.match(/<<link>>(.*?)<<link>>/g);
                              if (replacedText !== null) {
                                  for (let r of replacedText) {
                                      let cut = r.replace(/<<link>>/g, "")
                                      let textArray = cut.split(",");
                                      if (textArray.length === 2) {
                                          arrlink.push({
                                              n: textArray[0],
                                              l: textArray[1],
  
                                          })
                                          let oncut = texttable.split(r)
                                          console.log(oncut);
                                          texttable = oncut.join(textArray[0] + '\n');
                                      }
                                      console.log(textArray);
                                  }
  
                              }
  
                              console.log(replacedText, texttable);
                              let namel = doc.splitTextToSize(`${texttable || ''}`,setingtable[countl + 1]-3);
  
                              jsonlable[`col${countl + 1}`] = setingtable[countl + 1]
                              jsonlable[`name${countl + 1}`] = namel
  
                              if (hlt < namel.length) {
                                  hlt = namel.length
                              }
                              countl += 1
  
                          }
                          jsonlable.loop = countl
                          jsonlable.height = hlt
                      
                          console.log(jsonlable, arrlink);
                          ShotTable('ltc', jsonlable, heightpage, jsonlable.loop, 6, '', '', jsonlable.height, arrlink,jsonhead)
  
  
  
                      }
                      heightpage += 4
                  } */

            }
            count += 1
        }

    }

    function cuttextseting(header, text, pos) {
        if (heightpage > endpage) {
            newpage(endpage)
        }
        let textcutname = doc.splitTextToSize(`${text || ''}`, CutPadding('w', 80, -padding))
        console.log(textcutname, CutPadding('w', 5, pos));
        Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`${header} :`), 'l')
        Shot('t', CutPadding('w', 5, 0), heightpage, TexttoString(`${header} :`), 'l')
        let num = 0
        for (let i of textcutname) {
            Shot('t', CutPadding('w', 5, pos), heightpage, TexttoString(`${i}`), 'l')
            num += 1
            heightpage += 6
        }

    }

    async function loopseletdata2(data, lv, textlv) {
        let count = 1
        let jsonunit2 = {
            col1: CutPadding('w', 16, -padding),
            col2: CutPadding('w', 16, -padding),
            col3: CutPadding('w', 55, -padding),
            col4: CutPadding('w', 15, -padding),
            name1: ['Menu'],
            name2: ['Requirements', ' No.'],
            name3: ['Requirement Definition'],
            name4: ['Remark'],
            loop: 4,
            height: 2
        }

        for (let i of data) {

            /*  console.log(textlv); */
            let cutText = [], cutw = 2 * (lv - 1), sort = `${textlv}`
            if (heightpage + 24 > endpage) {
                heightpage += 24
                newpage(endpage)
            }


            if (i.header !== undefined) {


                heightpage += 4

                Shot('fs', 18)
                cutText = doc.splitTextToSize(`${i.header || ''}`, CutPadding('w', 80 - (cutw), -padding));

                /*    his.push(
                       {
                           page:page,
                           name:`${sortindex + 1}. ${cutText[0]}`
                       }
                   ) */
                let countrow = 0

                if (heightpage + (24) > endpage) {
                    heightpage += (24)
                    newpage(endpage)
                }

                for (let c of cutText) {
                    newpage(endpage)

                    if (countrow === 0) {
                        await Shot('i', /* `${Configs.TTT_Game_Service_IMG + i.img}` */await getboximg(i.img), CutPadding('w', 0), heightpage - 4, 5, 5)
                    }

                    Shot('t', CutPadding('w', cutw, 4), heightpage, TexttoString(countrow === 0 ? `${sort}.${count} ${c}` : `${c}`), 'l')
                    Shot('t', CutPadding('w', cutw, 4), heightpage, TexttoString(countrow === 0 ? `${sort}.${count} ${c}` : `${c}`), 'l')
                    countrow += 1
                    heightpage += 6
                }




                heightpage += 2
                sortindex += 1




            }

            if (i.detail !== undefined) {
                Shot('fs', 16)
                let cutdetail = `   ${i.detail} ซึ่งจะแบ่งออกเป็นส่วนๆ ทั้งหมด ${i.allmanu.length} ส่วน คือ `

                let lcount = 0
                for (let li of i.allmanu) {
                    if (lcount !== 0) {
                        if (lcount === i.allmanu.length - 1) {
                            cutdetail += ' และ '
                        } else {
                            cutdetail += ', '
                        }
                    }

                    cutdetail += `${li}`
                    lcount += 1
                }

                cutText = doc.splitTextToSize(`${cutdetail}`, CutPadding('w', 100 - (cutw), -padding));

                for (let c of cutText) {
                    newpage(endpage)
                    Shot('t', CutPadding('w', cutw), heightpage, TexttoString(`${c}`), 'l')
                    heightpage += 6
                }

            }

            if (heightpage + (jsonunit2.height * 7) > endpage) {

                heightpage += (jsonunit2.height * 7)
                newpage(endpage)
            }
            /* Shot('fs', 14) */
            ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
            /* Shot('fs', 12) */

            let listunit2 = i.datalist, namelog = '', befor = 0, max = 0, countall = 0, namecut = [], ishig = 0, rows = 6
            for (let index = 0; index < listunit2.length; index += 1) {
                let u = listunit2[index]
                console.log(i.header,u.manu,namelog);
                if (namelog !== u.manu) {
                   
                    let chackrow = listunit2.filter((e) => { return e.manu === u.manu && e.miansort === u.miansort})
                    namecut = doc.splitTextToSize(`${u.manu || ''}`, CutPadding('w', 0, jsonunit2.col1 - (2 + padding)));
                    /* console.log(namecut); */
                    console.log('true',chackrow);
                    namelog = u.manu
                    befor = heightpage
                    max = chackrow.length
                    ishig = namecut.length
                    countall = 0
                }
                cutText = doc.splitTextToSize(`${u.name.trim() || ''}`, CutPadding('w', 0, jsonunit2.col3 - (3 + padding)));
               /*  console.log(cutText, heightpage, (ishig * rows)); */
                if (heightpage + (ishig * rows) > endpage) {
                    if (countall > 0) {
                        let curcount = 1
                        for (let nc of namecut) {

                            Shot('t', Colposition('t', jsonunit2, 1, 'c'), befor + (curcount * rows), TexttoString(nc), 'c');
                            curcount += 1
                        }
                        Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), (heightpage - befor));
                    }

                    heightpage += ishig * rows
                    newpage(endpage)
                    heightpage = 38
                    countall = rows
                    befor = 38

                    ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                    befor = heightpage
                }
                Shot('t', Colposition('t', jsonunit2, 2, 'c'), heightpage + (rows), TexttoString(u.code), 'c');
                /*       console.log(u.name); */
              /*   console.log(cutText); */
                let counttext = 0
                for (let c of cutText) {


                    if (heightpage + rows > endpage) {
                        let curcount = 1
                        for (let nc of namecut) {
                            Shot('t', Colposition('t', jsonunit2, 1, 'c'), befor + (curcount * rows), TexttoString(nc), 'c');
                            curcount += 1
                        }



                        Shot('r', Colposition('r', jsonunit2, 2), heightpage - (rows * counttext), CutPadding('w', 0, jsonunit2.col2 - padding), (rows * counttext) + 2);
                        Shot('r', Colposition('r', jsonunit2, 3), heightpage - (rows * counttext), CutPadding('w', 0, jsonunit2.col3 - padding), (rows * counttext) + 2);
                        Shot('r', Colposition('r', jsonunit2, 4), heightpage - (rows * counttext), CutPadding('w', 0, jsonunit2.col4 - padding), (rows * counttext) + 2);
                        Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), (heightpage - befor) + 2);

                        heightpage += rows
                        newpage(endpage)
                        heightpage = 38
                        counttext = 0

                        countall = rows
                        befor = 38

                        ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                        befor = heightpage
                        /*  heightpage += 6 */

                    }
                    let newtext = c.replace("\t", "  ")
                    /* console.log(newtext); */
                    Shot('t', Colposition('t', jsonunit2, 3, 'l', 1), heightpage + rows, TexttoString(newtext), 'l');

                    counttext += 1
                    heightpage += rows
                    countall += rows


                }


                if (u.mefile === true) {
                    counttext += 1
                    heightpage += rows
                    countall += rows

                    /*        Shot('fc', 51, 109, 255);
                           Shot('dc', 0, 71, 255);
                           Shot('r', Colposition('t', jsonunit2, 3, 'r',5), heightpage-4, 5, 5,'fd'); */
                    Shot('i', `${clipPaperIcon}`, Colposition('t', jsonunit2, 3, 'r', 5), heightpage - 4, 5, 5)
                    /* Shot('dc', 0, 0, 0); */
                }

                /*    countall += 2 */

                max -= 1
              /*   console.log(max, u.name, countall, ishig * rows, heightpage - befor); */
                let countend = 0
                if (ishig * rows > (heightpage - befor) /* && max === 0 */) {
                    countend = ((ishig * rows) - (heightpage - befor))

                }
                Shot('r', Colposition('r', jsonunit2, 2), heightpage - ((rows * counttext)), CutPadding('w', 0, jsonunit2.col2 - padding), ((rows * counttext) + countend) + 2);
                Shot('r', Colposition('r', jsonunit2, 3), heightpage - ((rows * counttext)), CutPadding('w', 0, jsonunit2.col3 - padding), ((rows * counttext) + countend) + 2);
                Shot('r', Colposition('r', jsonunit2, 4), heightpage - ((rows * counttext)), CutPadding('w', 0, jsonunit2.col4 - padding), ((rows * counttext) + countend) + 2);




                heightpage += 2
                if (ishig * rows > heightpage - befor /* && max === 0 */) {
                    heightpage += ((ishig * rows) - (heightpage - befor)) + 2
                }
                if (max === 0) {
                    let curcount = 1
                    for (let nc of namecut) {
                        Shot('t', Colposition('t', jsonunit2, 1, 'c'), befor + (curcount * rows), TexttoString(nc), 'c');
                        curcount += 1
                    }
                    Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), (heightpage - befor));
                }
                if (endpage - rows < heightpage && max !== 0) {
                    let curcount = 1
                    for (let nc of namecut) {

                        Shot('t', Colposition('t', jsonunit2, 1, 'c'), befor + (curcount * rows), TexttoString(nc), 'c');
                        curcount += 1
                    }

                    Shot('r', Colposition('r', jsonunit2, 1), befor, CutPadding('w', 0, jsonunit2.col1 - padding), (heightpage - befor));
                    newpage(endpage - rows)
                    heightpage = 38
                    counttext = 1

                    countall = rows
                    befor = 38

                    ShotTable('htc', jsonunit2, heightpage, jsonunit2.loop, 7, '', 'fd', jsonunit2.height)
                    befor = heightpage



                }
            }




            heightpage += 4

            count += 1
        }

    }


    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 38

        }
    }


    function sethead() {
        Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)

    }
    function setfooter(on, max) {

        Shot('lw', 0.3)
        Shot('fs', 13)
        Shot('t', CutPadding('w', 50, 2), 273 + 2, TexttoString(`Page ${on} of ${max}`), 'c')
        doc.line(CutPadding('w', 0, 2), 273 + 4, CutPadding('w', 100, 2), 273 + 4);
        Shot('t', CutPadding('w', 0, 2), 273 + 8, TexttoString(`Template v2.0 Copyright © TTT Brother CO., LTD. `), 'l')
        Shot('t', CutPadding('w', 95, 2), 273 + 8, TexttoString(`V${parseFloat(data.version.version) + 1}.0`), 'r')

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */


    function setDetethai(mode, date, ps, cut, length) {
        let currentDate = new Date(date);
        let day = currentDate.getDate();
        let monthNamesShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        let monthIndex = currentDate.getMonth();
        let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2); // แปลงเป็น ปีไทยย่อ
        let dayNameShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'][currentDate.getDay()];

        let formattedDate = ''
        if (mode === 'day') {
            formattedDate = `${dayNameShort} ${day}/${monthNamesShort[monthIndex]}/${thaiYear}`;
        }

        /*  Shot('t', Colposition('t', tableschedule, ps, 'r', 15), inpage + (cut - (length * 5) + 2), TexttoString(formattedDate), 'r'); */
    }



    function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {
            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i'  && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {
                    if (c6 === 'fit') {
                        const imgar = c7.width / c7.height;
                        const reactar = c4 / c5;

                        let scale = 1;
                        if (imgar > reactar) {
                            scale = c4 / c7.width;
                        } else {
                            scale = c5 / c7.height;
                        }
                        const nw = c7.width * scale;
                        const nh = c7.height * scale;

                        const x = (c4 - nw) / 2;
                        const y = (c5 - nh) / 2;
                        return doc.addImage(c1, 'JPEG', c2 + x, c3 + y, nw, nh);
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }



    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig, link = [], header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {
                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            let chackrow = link.filter((e) => { return e.n === cx })
                                            console.log(chackrow, cx, 'in');
                                            if (chackrow.length > 0) {
                                                /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                                               */
                                                let y = higbefor + (fakecolsum), x = Colposition('t', head, t2, 'l', 3)
                                                let width = doc.getTextWidth(chackrow[0].n);
                                                let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                                                y += height * 0.2;
                                                doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                                            } /* else { */
                                            Shot('t', Colposition('t', head, t2, t2 === 1 ? 'c' : 'l', 3), higbefor + (fakecolsum), TexttoString(cx), t2 === 1 ? 'c' : 'l')
                                            /* } */
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                            higbefor += 7


                        }


                        let chackrow = link.filter((e) => { return e.n === c })
                        if (chackrow.length > 0) {
                            /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                           */
                            let y = higbefor + (collengthsum), x = Colposition('t', head, t, 'l', 3)
                            let width = doc.getTextWidth(chackrow[0].n);
                            let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                            y += height * .2;
                            doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                        }
                        Shot('t', Colposition('t', head, t, t === 1 ? 'c' : 'l', 3), higbefor + (collengthsum), TexttoString(c), t === 1 ? 'c' : 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



}






export default SRSreport;
