import React from "react";

import HeaderTTT from "../../components/header/HeaderTTT";
import InputCustom from "../../components/customElement/inputCustom";
import ButtonCustom from "../../components/customElement/buttonCustom";

import "./styleTest.css";

function Test() {
  return (
    <>
      <HeaderTTT />
      <img src="../assets/img/bg/area_bg01.jpg" alt="" className="bg-login" />
      <div className="container-fluid">
        <div style={{ padding: "50px 0px 0px 40px" }}>
          <InputCustom placeholder="PERSONAL NAME" />
        </div>
        <div style={{ padding: "50px 0px 0px 40px" }}>
          <ButtonCustom
            placeholder="NEXT"
            onClick={async () => {
              alert("TEST Button Click");
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Test;
