import React, { useEffect, useRef, useState } from "react";
import Configs from "../../config";
import axios from 'axios';

import HeaderTTT from "../../components/header/HeaderTTT";
import Title from "../../components/customElement/headertitle";
import '../../assets/css/leaderboard.css';
import bgLeaderBoard from '../../assets/img/bg/bg_leaderboard.jpg';
import bgLeaderBoard_new from './img/background_leaderbord.png';
import down from '../../assets/img/icon/chevrons-up.png';
import ImCard from "../../components/customElement/leaderBoardCard";
import LeaderBoardCardMobile from "../../components/customElement/leaderBoardCardMobile";
import userImg1 from '../../assets/img/images/userImg1.jpg';
import userImg2 from '../../assets/img/images/userImg2.jpg';
import userImg3 from '../../assets/img/images/userImg3.jpg';
import userImg4 from '../../assets/img/images/userImg4.jpg';
import userImg5 from '../../assets/img/images/userImg5.jpg';
import userImg6 from '../../assets/img/images/userImg6.jpg';
import userImgDeful from './img/userDffult.png';
import Select from 'react-select'
import HeaderTTTMobile from "../../components/header/HeaderTTTMobile";
import moment from "moment";

function LeaderBoard() {
    const [menu, setMenu] = useState("ALL");
    const [roleOption, setRoleOption] = useState([
        { label: 'ALL', value: 'ALL' },
        { label: 'TRANSFORM', value: 'TRANSFORM' },
        { label: 'TIMELY', value: 'TIMELY' },
        { label: 'TRAILBLAZER', value: 'TRAILBLAZER' },
    ])

    const [roleOptionMobile, setRoleOptionMobile] = useState([
        { label: 'RANKING', value: 'ALL' },
        { label: 'TRANSFORM', value: 'TRANSFORM' },
        { label: 'TIMELY', value: 'TIMELY' },
        { label: 'TRAILBLAZER', value: 'TRAILBLAZER' },
    ])

    const [count, setCount] = useState([])

    const [DataStageFack, setDataStageFack] = useState([])

    const [card, setCard] = useState(1)
    const [cardTargetList, setTargetList] = useState([]);
    const [test, settest] = useState({ test: '' });

    async function getUniversalBoardData() {
        await axios({
            method: 'GET',
            url: `${Configs.TTT_Game_Service}/api/leaderBoard/getUniversalBoardData`,
            headers: {
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
        })
            .then(async function (response) {
                if (response.data) {
                    setCount(JSON.parse(JSON.stringify(response.data)));
                    setDataStageFack(JSON.parse(JSON.stringify(response.data)));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function setDataToCardList(page) {

        setTargetList([]);
        let tempDAta = count;

        let tempResult = [];
        for (let i = 1; i <= 3; i++) {
            let indexTarget = (3 * page) - 3 + i;
            // console.log(indexTarget)

            if (tempDAta[indexTarget - 1]) {
                let t = {
                    no: indexTarget,
                    name: tempDAta[indexTarget - 1]
                }
                tempResult.push(t)
            }


        }

        // console.log(tempResult)

        setTargetList(tempResult);

        settest({ ...test, test: '' });
        test.test = '';

    };

    useEffect(() => {
        let page = 1;
        setTargetList([]);
        let tempDAta = count;
        // console.log("count", count);
        let tempResult = [];
        for (let i = 1; i <= 3; i++) {
            let indexTarget = (3 * page) - 3 + i;
            // console.log(indexTarget)

            if (tempDAta[indexTarget - 1]) {
                let t = {
                    no: indexTarget,
                    name: tempDAta[indexTarget - 1]
                }
                tempResult.push(t)
            }
        }

        // console.log(tempResult)

        setTargetList(tempResult);

        settest({ ...test, test: '' });
        test.test = '';

    }, []);

    /*   console.log(card); */

    function FilterGroupUser(value) {
        if (value == 'TRANSFORM') {
            value = 'TRANFROM';
        };
        
        let temp = count || [];
        let fackTemp = DataStageFack || [];
        
        if (value == "ALL") {
            setCount(fackTemp)
        } else {
            // console.log('fackTemp',fackTemp);
            // let getRank = fackTemp.filter((e) => { return (e.group).toUpperCase() == value })
            // console.log('ตัวที่ filter เเล้ว',getRank);
            let getRank = fackTemp.filter((e)=>{ return e.group == value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()});
            
            setCount(getRank)
        }
        settest({ ...test, test: '' })
        test.test = '';
    };

    //---------------------------------Mobile-----------------------------
    const [modeMobile, setModeMobile] = useState(false);

    useEffect(() => {

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        }
        else {
            setModeMobile(false);
        }
    })

    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

    //---------------------------------Resize---------------------------------
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [topPosition, setTopPosition] = useState('103%');
    // console.log(windowWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        // Calculate top position based on windowWidth
        const calculateTopPosition = (width) => {
            if (width < 420) {
                return '57px'; // Example value for small screens
            }
            return '103%'; // Default value for larger screens
        };

        setTopPosition(calculateTopPosition(windowWidth));
    }, [windowWidth]);

    //----------------------------Slide----------------------------------------
    const carouselRef = useRef(null);
    const startX = useRef(0);
    const endX = useRef(0);
    const isTouching = useRef(false);

    const handleScroll = (e) => {
        const carousel = carouselRef.current;
        const cardWidth = carousel.offsetWidth;
        const scrollLeft = carousel.scrollLeft;
        const newCard = Math.round(scrollLeft / cardWidth) + 1;
        setCard(newCard);
    };

    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX;
        isTouching.current = true;
    };

    const handleTouchMove = (e) => {
        if (!isTouching.current) return;
        endX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (!isTouching.current) return;
        isTouching.current = false;
        const diffX = startX.current - endX.current;
        if (diffX > 50) {
            setCard((prevCard) => Math.min(prevCard + 1, Math.ceil(count.length / 3)));
        } else if (diffX < -50) {
            setCard((prevCard) => Math.max(prevCard - 1, 1));
        }
    };

    const handleMouseDown = (e) => {
        startX.current = e.clientX;
        isTouching.current = true;
    };

    const handleMouseMove = (e) => {
        if (!isTouching.current) return;
        endX.current = e.clientX;
    };

    const handleMouseUp = () => {
        if (!isTouching.current) return;
        isTouching.current = false;
        const diffX = startX.current - endX.current;
        if (diffX > 50) {
            setCard((prevCard) => Math.min(prevCard + 1, Math.ceil(count.length / 3)));
        } else if (diffX < -50) {
            setCard((prevCard) => Math.max(prevCard - 1, 1));
        }
    };

    useEffect(() => {
        setDataToCardList(card);
    }, [card, count]);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (!carousel) return;

        carousel.addEventListener('scroll', handleScroll);

        return () => {
            carousel.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (!carousel) return;
        const cardWidth = carousel.offsetWidth;
        carousel.scrollTo({ left: (card - 1) * cardWidth, behavior: 'smooth' });
    }, [card]);

    useEffect(() => {
        getUniversalBoardData();
    }, []);

    function numberWithFormat(value) {
        let set = 0;
        if (value >= 1000000) {
          set = `${(parseFloat((value) / 1000000).toFixed(2))}M`;
        } else if (value >= 1000) {
          set = `${(parseFloat((value) / 1000).toFixed(2))}K`;
        } else {
          set = parseFloat(value || 0).toFixed(2);
        }
        return set
      }

    return (
        <>
            {modeMobile ? (
                <>
                    <div className='bg-container_mobile'>
                        <div className="grid-container_mobile" style={{ overflow: 'hidden' }}>
                            <HeaderTTTMobile
                                mobileMenu={mobileMenu}
                                toggle_mobileMenu={toggle_mobileMenu}
                                mobileLogin={mobileLogin}
                                toggle_mobileLogin={toggle_mobileLogin}
                                mobileRegister={mobileRegister}
                                toggle_mobileRegister={toggle_mobileRegister}
                            />
                            <div className='bg-leaderboard_mobile mt-5'
                                style={{
                                    backgroundImage: `url(${bgLeaderBoard_new})`,
                                    width: `100%`,
                                    zIndex: -2,
                                    backgroundRepeat: "no-repeat",
                                    height: "auto",
                                    position: "absolute",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    top: 0
                                }}
                            >
                                <div className="lbHead_mobile ml-4 mt-4 d-flex">
                                    <Title name='LEADERBOARD' height={14} no={1} fix={160} imglong={37} top={7.5} imgbox={98} imgcorner={10} is_change={true} />
                                    <Title name='' no={3} fix={150} imglong={34} imgbox={11} imgcorner={20} left={13} height={40} is_change={true} />

                                    {/* <div className="lbLine"></div> */}

                                    <div className="pi-infoBoxTextShadow-1" style={{ width: '200px', zIndex: 9999999 }}>

                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({ //ปรับช่องหลัก
                                                    ...baseStyles,
                                                    width: '95%',
                                                    color: 'white',
                                                    border: "none",
                                                    fontSize: '14px',
                                                    backgroundColor: 'transparent',
                                                    borderRadius: 0,
                                                    // border: "none",
                                                    maxHeight: '28vh',
                                                    overflow: 'auto',
                                                    boxShadow: 'none',
                                                    "::-webkit-scrollbar": {
                                                        width: "5px",
                                                    },
                                                    "::-webkit-scrollbar-track": {
                                                        borderRadius: "10px",
                                                    },
                                                    "::-webkit-scrollbar-thumb": {
                                                        background: '#0085FF',
                                                        borderRadius: '10px'
                                                    },
                                                    "::-webkit-scrollbar-thumb:hover": {
                                                        background: '#00498d',
                                                        border: 'none'
                                                    }
                                                }),
                                                menu: (baseStyles) => ({ //ปรับช่องเมนู
                                                    ...baseStyles,
                                                    position: 'absolute',
                                                    top: '100%',
                                                    left: '0',
                                                    border: 'none',
                                                    background: 'rgba(0, 0, 0, 0.6)',

                                                    borderRadius: 0,
                                                    width: '95%',
                                                    zIndex: 9999,
                                                    fontSize: '12px',
                                                    "::-webkit-scrollbar": {
                                                        width: "5px",
                                                    },
                                                    "::-webkit-scrollbar-track": {
                                                        borderRadius: "10px",
                                                    },
                                                    "::-webkit-scrollbar-thumb": {
                                                        background: '#0085FF',
                                                        borderRadius: '10px'
                                                    },
                                                    "::-webkit-scrollbar-thumb:hover": {
                                                        background: '#00498d'
                                                    }
                                                }),
                                                menuList: (base) => ({
                                                    ...base,
                                                    padding: 0,
                                                    backgroundColor: "#021E6F",

                                                    "::-webkit-scrollbar": {
                                                        width: "5px",
                                                    },
                                                    "::-webkit-scrollbar-track": {
                                                        borderRadius: "10px",
                                                    },
                                                    "::-webkit-scrollbar-thumb": {
                                                        background: '#0085FF',
                                                        borderRadius: '10px'
                                                    },
                                                    "::-webkit-scrollbar-thumb:hover": {
                                                        background: '#00498d'
                                                    }
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ //ปรับช่องในเมนู
                                                    ...styles,
                                                    color: isSelected ? '#fff' : "#d4d4d4",
                                                    backgroundColor: isSelected ? '#2684FF' : '',
                                                    "&:hover": {
                                                        backgroundColor: isSelected ? "rgba(0,71,255,0.8)" : "rgba(0,71,255,0.5)"
                                                    },
                                                    zIndex: 99999,
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    backgroundColor: 'transparent',
                                                    color: 'white',
                                                })
                                            }}
                                            options={roleOptionMobile}
                                            placeholder={'ALL'}
                                            value={roleOptionMobile.filter((e) => { return e.value === menu })}
                                            onChange={(e) => {
                                                if (e.value != menu) {
                                                    setMenu(e.value)
                                                    setCard(1);
                                                    FilterGroupUser(e.value)
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="lbMenu_mobile mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px" }}>
                                    <a className="nav-item" style={menu === 'ALL' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'ALL') {
                                            setCard(1);
                                            FilterGroupUser('ALL');
                                            setMenu('ALL');
                                        }
                                    }}>ALL</a>
                                    <a className="nav-item" style={menu === 'TRANSFORM' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TRANSFORM') {
                                            setCard(1);
                                            FilterGroupUser('TRANSFORM');
                                            setMenu('TRANSFORM');
                                        }
                                    }}>TRANSFORM</a>
                                    <a className="nav-item" style={menu === 'TIMELY' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TIMELY') {
                                            setCard(1);
                                            FilterGroupUser('TIMELY');
                                            setMenu('TIMELY');
                                        }
                                    }}>TIMELY</a>
                                    <a className="nav-item" style={menu === 'TRAILBLAZER' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TRAILBLAZER') {
                                            setCard(1);
                                            FilterGroupUser('TRAILBLAZER');
                                            setMenu('TRAILBLAZER');
                                        }
                                    }}>TRAILBLAZER</a>
                                </div>

                                <div className="lbCard_mobile" style={{ height: "380px" }}>
                                    <div
                                        ref={carouselRef}
                                        className="lbCardDetail2_mobile"
                                        style={card !== 1 ? { color: '#0047FF', display: 'flex', overflowX: 'auto', scrollSnapType: 'x mandatory', WebkitOverflowScrolling: 'touch' } : null}
                                        onTouchStart={handleTouchStart}
                                        onTouchMove={handleTouchMove}
                                        onTouchEnd={handleTouchEnd}
                                        onMouseDown={handleMouseDown}
                                        onMouseMove={handleMouseMove}
                                        onMouseUp={handleMouseUp}
                                    >
                                        {card === 1 ? (<>
                                            <div className="row" style={{ marginRight: "4px" }}>
                                                <div className="col-4">
                                                    <div className="row" style={{ marginTop: "60px" }}>
                                                        <div className="col-12">
                                                            <div className="textRank2_mobile">
                                                                <Title name={`RANK NO.2`} no={2} fix={windowWidth < 420 ? 70 : 120} imglong_per={windowWidth < 420 ? 128 : 131} top={10} imgbox={110} imgcorner={10} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12" style={{ left: '-2px', marginTop: windowWidth >= 430 ? '17%' : "10px" }}>
                                                            {count.length >= 2 && <LeaderBoardCardMobile width_change={93} cardWidth={windowWidth <= 330 ? 153 : 130} cardHeight={windowWidth < 330 ? 595 : 665} imgTEST={count[1].imguser ? Configs.TTT_Game_Service_IMG + count[1].imguser : null} cardName={count[1].name} cardRating={count[1].rating} />}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4" >
                                                    <div className="boxRank1_mobile">
                                                        <div className="rank1TopShadow_mobile">
                                                            <div className="rank1Top_mobile"></div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: "-30px" }}>
                                                            <div className="col-12">
                                                                <div className="textRank1_mobile">
                                                                    RANK NO.1
                                                                    <span className="boxRankNo1_mobile">
                                                                        NO. 1
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="rank1Bottom_mobile">
                                                                <div className="rank1Bottom1_mobile">
                                                                    <div className="rank1Bottom2_mobile"></div>
                                                                </div>
                                                            </div>
                                                            <div className="rank1Bottom3_mobile"></div>
                                                            <div className="boxRank1In_mobile" style={{ height: windowWidth >= 430 ? "231px" : "" }}>
                                                                <div className="col-12" style={{ width: '150%', height: "20%", display: 'block', margin: "-6% 5% 0 -8%" }}>
                                                                    {count.length >= 1 && <LeaderBoardCardMobile width_value={windowWidth} is_paddingno1={true} is_mtop={2} width_change={90} cardWidth={120} cardHeight={windowWidth <= 375 ? 394 : 408} imgTEST={count[0].imguser ? Configs.TTT_Game_Service_IMG + count[0].imguser : null} cardName={count[0].name} cardRating={count[0].rating} top={20} />}
                                                                </div>
                                                            </div>

                                                            <div className="rank1Right_mobile">
                                                                <div className="rank1Right1_mobile">
                                                                    <div className="rank1Right2_mobile">
                                                                        <div className="rank1Right3_mobile"></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="rank1Left_mobile">
                                                                <div className="rank1Left1_mobile">
                                                                    <div className="rank1Left2_mobile">
                                                                        <div className="rank1Left4_mobile"></div>
                                                                    </div>
                                                                    <div className="rank1Left3_mobile"></div>
                                                                    <div className="rank1Left5_mobile"></div>
                                                                </div>
                                                            </div>

                                                            <div className="rank1LeaderBox_mobile" style={{ position: "relative" }}>
                                                                <svg height="55" width="100%" style={{ position: "relative", top: "-24px" }}>
                                                                    <line x1="18%" y1="26%" x2="18%" y2="39%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="18%" y1="27%" x2="25.5%" y2="5.5%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    {/*----------------เส้นยาว บน ----------------------- */}
                                                                    <line x1="25%" y1="6%" x2="40%" y2="6%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="60%" y1="6%" x2="74.7%" y2="6%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="74.2%" y1="5.5%" x2="82%" y2="27.5%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="81.7%" y1="26%" x2="81.7%" y2="39%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="81.7%" y1="50%" x2="81.7%" y2="60%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="74.5%" y1="80.5%" x2="81.7%" y2="59%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    {/*----------------เส้นยาว ล่าง ----------------------- */}
                                                                    <line x1="60%" y1="80%" x2="75%" y2="80%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="24.56%" y1="80%" x2="40%" y2="80%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="17.8%" y1="58%" x2="25%" y2="79.8%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="18%" y1="50%" x2="18%" y2="59%" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                </svg>
                                                                <div className="rank1Leader_mobile"/*  style={{position: "relative" , top: topPosition , left: "20%"}} */>
                                                                    <svg height="40" width="100%">
                                                                        <line x1="2.5%" y1="61.5%" x2="2.5%" y2="26%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                        <line x1="2.5%" y1="27%" x2="11%" y2="5.5%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                        <line x1="2.5%" y1="61%" x2="11%" y2="81.9%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />

                                                                        <line x1="10.5%" y1="6%" x2="89.5%" y2="6%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                        <line x1="10.5%" y1="82%" x2="89.5%" y2="82%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />

                                                                        <line x1="97.5%" y1="61.5%" x2="97.5%" y2="26%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                        <line x1="89%" y1="5.5%" x2="97.5%" y2="26%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                        <line x1="89%" y1="81.5%" x2="97.5%" y2="61%" style={{ stroke: "#7BD7FF", strokeWidth: "1" }} />
                                                                    </svg>
                                                                    <div className="rank1LeaderText_mobile">LEADER</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div className="row" style={{ marginTop: "60px" }}>
                                                        <div className="col-12" /* style={{ left: "10px"}} */>
                                                            <div className="textRank_mobile">
                                                                <Title name={`RANK NO.3`} no={2} fix={windowWidth < 420 ? 70 : 120} imglong_per={windowWidth < 420 ? 128 : 131} top={10} imgbox={110} imgcorner={10} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12" style={{ /* left: '10px', */ marginTop: windowWidth >= 430 ? '17%' : "10px" }}>
                                                            {count.length >= 2 && <LeaderBoardCardMobile width_change={93} cardWidth={windowWidth <= 330 ? 153 : 130} cardHeight={windowWidth < 330 ? 595 : 665} imgTEST={count[2].imguser ? Configs.TTT_Game_Service_IMG + count[2].imguser : null} cardName={count[2].name || ''} cardRating={count[2].rating || ''} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                            :
                                            (<>
                                                <div className="row" style={{ marginTop: "60px", width: "100%" }}>
                                                    {cardTargetList.map((e, index) => {
                                                        // console.log('CardList',e);
                                                        return (<>
                                                            <div className="col-4">
                                                                <div className="row" /* style={{ marginLeft: "5px", marginRight: "5px"}} */>
                                                                    <div className="col-12" style={{ marginBottom: "10px" }}>
                                                                        <div className="textRank_mobile">
                                                                            <Title name={`RANK NO.${e.no}`} no={2} fix={windowWidth < 420 ? 70 : 120} imglong_per={windowWidth <= 430 ? 150 : 145} top={10} imgbox={110} imgcorner={10} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12" style={{ marginTop: "6px" }}>
                                                                        {count.length >= 4 && <LeaderBoardCardMobile width_change={100} cardWidth={150} cardHeight={695} imgTEST={count[index + ((card - 1) * 3)]?.imguser != null ? Configs.TTT_Game_Service_IMG + count[index + ((card - 1) * 3)]?.imguser : null} cardName={count[index + ((card - 1) * 3)]?.name || ''} cardRating={count[index + ((card - 1) * 3) || '']?.rating} />}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    })}
                                                </div>
                                            </>)
                                        }
                                    </div>
                                </div>



                                <div className="lbList_mobile" /* style={{width: `${windowWidth}px`}} */>
                                    <div style={{ width: "88%" }}>
                                        <svg height="550" width="100%">
                                            {/* ----------บน--------------- */}
                                            <line x1="1.2%" y1="5.6%" x2="1.2%" y2="10%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="1%" y1="5.9%" x2="8%" y2="1.3%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="7.4%" y1="1.4%" x2="16%" y2="1.4%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                            <line x1="98.8%" y1="5.6%" x2="98.8%" y2="10%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="99%" y1="5.9%" x2="92%" y2="1.3%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="92.6%" y1="1.4%" x2="84%" y2="1.4%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                            {/* ----------ล่าง--------------- */}

                                            <line x1="1.2%" y1="95.4%" x2="1.2%" y2="90%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="1%" y1="95.1%" x2="8.2%" y2="100%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="7.4%" y1="99.6%" x2="16%" y2="99.6%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                            <line x1="98.8%" y1="95.4%" x2="98.8%" y2="90%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="99%" y1="95.05%" x2="91.1%" y2="100%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                            <line x1="91.6%" y1="99.6%" x2="84%" y2="99.6%" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                            {/* ---------ขอบ--------------- */}

                                            <line x1="9.1%" y1="2.7%" x2="3.7%" y2="6.3%" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                            <line x1="90.8%" y1="2.7%" x2="96.3%" y2="6.3%" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                            <line x1="3.7%" y1="94.8%" x2="9.1%" y2="98.4%" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                            <line x1="96.3%" y1="94.8%" x2="90.7%" y2="98.4%" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                        </svg>
                                    </div>

                                    <div className="lbListBox_mobile" /* style={{width: `${windowWidth - 30}px`}} */>
                                        <div className="lbListUpIn">UPDATING IN&nbsp;
                                            <span className="lbListUpTime">{moment(new Date()).format('HH:mm:ss')}</span>
                                        </div>
                                        <div className="lbListHead_mobile" style={{ fontSize: "10px" }}>
                                            <span className="lbListHeadNo">NO</span>
                                            <span className="lbListHeadName">NAME</span>
                                            <span className="lbListHeadRating">EXP</span>
                                        </div>
                                        <div className="lbListDetail">
                                            {count && count.map((e, i) => {

                                                return (
                                                    <>
                                                        <div className="lbListDetailIn" style={{ color: `${e.color}` }} /* style={{color: "#0047FF"}} */>
                                                            <span className="lbListDetailNo">{i + 1}</span>
                                                            <span className="lbListDetailName">{e.name}</span>
                                                            <span className="lbListDetailRating">{numberWithFormat(e.rating)}</span>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>




                            </div>



                        </div>

                    </div>
                </>
            )
                :
                (
                    <>
                        <HeaderTTT />
                        <img className='bg-leaderboard' src={bgLeaderBoard} alt="" />

                        <div className='bg-container'>
                            <div className="grid-container">
                                <div className="lbHead"><Title name='UNIVERSAL BOARD' no={1} fix={320} imglong={230} top={7.5} imgbox={110} imgcorner={10} /></div>
                                {/* <div className="lbHead"><Title name='LEADERBOARD' no={1} fix={windowWidth < 400 ? 220 : 320} imglong={windowWidth < 400 ? 45 : 160} top={7.5} imgbox={windowWidth < 400 ? 143 : 110} imgcorner={10} /></div> */}

                                <div className="lbMenu">
                                    <a className="lbMenuText" style={menu === 'ALL' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'ALL') {
                                            setMenu('ALL');
                                            setCard(1);
                                            FilterGroupUser('ALL');
                                        }
                                    }}>ALL</a>
                                    <a className="lbMenuText" style={menu === 'TRANSFORM' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TRANSFORM') {
                                            setMenu('TRANSFORM');
                                            setCard(1);
                                            FilterGroupUser('TRANSFORM');
                                        }
                                    }}>TRANSFORM</a>
                                    <a className="lbMenuText" style={menu === 'TIMELY' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TIMELY') {
                                            setMenu('TIMELY');
                                            setCard(1);
                                            FilterGroupUser('TIMELY');
                                        }
                                    }}>TIMELY</a>
                                    <a className="lbMenuText" style={menu === 'TRAILBLAZER' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                        if (menu !== 'TRAILBLAZER') {
                                            setMenu('TRAILBLAZER');
                                            setCard(1);
                                            FilterGroupUser('TRAILBLAZER');
                                        }
                                    }}>TRAILBLAZER</a>
                                </div>
                                <div className="lbList">
                                    <svg height="550" width="380">
                                        <line x1="2" y1="80" x2="2" y2="18" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="1" y1="20" x2="40" y2="1" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="39" y1="2" x2="60" y2="2" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                        <line x1="377" y1="80" x2="377" y2="19" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="379" y1="20" x2="340" y2="2" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="341" y1="2" x2="320" y2="2" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                        <line x1="2" y1="531" x2="2" y2="470" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="1" y1="529" x2="40" y2="547" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="39" y1="547" x2="60" y2="547" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                        <line x1="377" y1="531" x2="377" y2="470" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="379" y1="529" x2="340" y2="547" style={{ stroke: "#0047FF", strokeWidth: "5" }} />
                                        <line x1="341" y1="547" x2="321" y2="547" style={{ stroke: "#0047FF", strokeWidth: "5" }} />

                                        <line x1="43.5" y1="12" x2="10.5" y2="27.5" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                        <line x1="337.5" y1="12" x2="369.5" y2="27.5" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                        <line x1="43.5" y1="537.5" x2="10.5" y2="522.5" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                        <line x1="337.5" y1="537.5" x2="369.5" y2="522" style={{ stroke: "#0047FF", strokeWidth: "2" }} />
                                    </svg>

                                    <div className="lbListBox">
                                        <div className="lbListUpIn">UPDATING IN&nbsp;
                                            <span className="lbListUpTime">{moment(new Date()).format('HH:mm:ss')}</span>
                                        </div>
                                        <div className="lbListHead">
                                            <span className="lbListHeadNo">NO</span>
                                            <span className="lbListHeadName">NAME</span>
                                            <span className="lbListHeadRating">EXP</span>
                                        </div>
                                        <div className="lbListDetail">
                                            {count && count.map((e, i) => {

                                                return (
                                                    <>
                                                        <div className="lbListDetailIn" style={{ color: `${e.color}` }}>
                                                            <span className="lbListDetailNo">{i + 1}</span>
                                                            <span className="lbListDetailName">{e.name}</span>
                                                            <span className="lbListDetailRating">{numberWithFormat(e.rating)}</span>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="lbCard">
                                    <div className="lbCardHead">
                                        <div className="lbCardHeadTextRanking ">
                                            <div className="row" >
                                                <Title name='RANKING' no={3} fix={250} imglong={30} top={5} imgbox={110} imgcorner={10} />

                                                <div className="pi-infoBoxTextShadow-1 ml-4" style={{ width: '200px'/* zIndex: 0,border:'1px solid red'  */ }}>
                                                    <div className="pi-infoBoxText-ledrder">
                                                        <div className="pi-infoBoxTextCen-ledrder">
                                                            <div className="pi-infoBoxTextSelect-ledrder"></div>
                                                        </div>
                                                    </div>


                                                    <Select
                                                        className="selectcss2"
                                                        options={roleOption}
                                                        placeholder={'All'}
                                                        value={roleOption.filter((e) => { return e.value === menu })}
                                                        onChange={(e) => {
                                                            if (e.value != menu) {
                                                                setMenu(e.value);
                                                                setCard(1);
                                                                FilterGroupUser(e.value);
                                                            }
                                                        }}
                                                    />


                                                </div>
                                            </div>


                                        </div>
                                        <div className="lbCardHeadTextDepartment">DEPARTMENT:
                                            <div className="lbCardHeadTextDepartmentIn">{menu}</div>
                                        </div>
                                    </div>

                                    <button className={card !== 1 ? "lbCardDetailDown" : null} style={{ bottom: '-1.25%' }} onClick={() => { setCard(card - 1); setDataToCardList(card - 1); }}>
                                        {card !== 1 ? <img src={down} style={{ transform: "rotate(180deg)", width: '24px', marginTop: "-5px" }}></img> : null}
                                    </button>
                                    
                                    <div className="lbCardDetail2" style={card !== 1 ? { color: '#0047FF', borderTop: '2px solid #0047FF', borderBottom: `${(card * 3) >= count.length ? 'none' : '2px solid #0047FF'}` } : {borderBottom: `${(card * 3) >= count.length ? 'none' : '2px solid #0047FF'}`}}>

                                        {card === 1 ? (<>
                                            <div className="row" style={{ index: '0' }}>
                                                <div className="col-4">
                                                    <div className="row" style={{ marginTop: "60px" }}>
                                                        <div className="col-12">
                                                            <div className="textRank2"><Title name={`RANK NO.2`} no={2} fix={180} imglong={238} top={5} imgbox={110} imgcorner={10} /></div>
                                                        </div>
                                                        <div className="col-12" style={{ left: '-5px' }}>
                                                            {count.length >= 2 && <ImCard cardWidth={240} cardHeight={335} imgTEST={count[1].imguser != null ? Configs.TTT_Game_Service_IMG + count[1].imguser : null} cardName={count[1].name} cardRating={count[1].rating} />}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4" >
                                                    <div className="boxRank1" >
                                                        <div className="rank1TopShadow">
                                                            <div className="rank1Top" ></div>
                                                        </div>
                                                        <div className="row" style={{ marginTop: "-30px" }}>
                                                            <div className="col-12">
                                                                <div className="textRank1">RANK NO.01
                                                                    <span className="boxRankNo1">NO. 01</span>
                                                                </div>
                                                            </div>
                                                            <div className="rank1Bottom">
                                                                <div className="rank1Bottom1">
                                                                    <div className="rank1Bottom2"></div>
                                                                </div>
                                                            </div>
                                                            <div className="rank1Bottom3"></div>
                                                            <div className="boxRank1In">
                                                                <div className="col-12" style={{ marginTop: "-8%" }}>
                                                                    {count.length >= 1 && <ImCard cardWidth={220} cardHeight={310} imgTEST={count[0].imguser != null ? Configs.TTT_Game_Service_IMG + count[0].imguser : null} cardName={count[0].name} cardRating={count[0].rating} />}
                                                                </div>
                                                            </div>
                                                            <div className="rank1Right">
                                                                <div className="rank1Right1">
                                                                    <div className="rank1Right2">
                                                                        <div className="rank1Right3"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rank1Left">
                                                                <div className="rank1Left1">
                                                                    <div className="rank1Left2">
                                                                        <div className="rank1Left4"></div>
                                                                    </div>
                                                                    <div className="rank1Left3"></div>
                                                                    <div className="rank1Left3"></div>
                                                                </div>
                                                            </div>
                                                            <div className="rank1LeaderBox">
                                                                <svg height="55" width="100%">
                                                                    <line x1="92" y1="20" x2="92" y2="10" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="91.75" y1="10.5" x2="100" y2="2.5" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="99.5" y1="2.75" x2="130" y2="2.75" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="180" y1="2.75" x2="210.75" y2="2.75" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="210.25" y1="2.5" x2="218.75" y2="10.5" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="218.5" y1="9.75" x2="218.75" y2="20" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="218.5" y1="32" x2="218.75" y2="42.25" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="219" y1="41.75" x2="211" y2="50" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="211.75" y1="50" x2="180" y2="50" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />

                                                                    <line x1="99.5" y1="50" x2="130" y2="50" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="100" y1="50" x2="91.75" y2="41.75" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                    <line x1="92" y1="42.25" x2="92" y2="32" style={{ stroke: "#7BD7FF", strokeWidth: "2" }} />
                                                                </svg>
                                                                <div className="rank1Leader">
                                                                    <svg height="40" width="120">
                                                                        <line x1="2.5" y1="32.5" x2="2.5" y2="7.25" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="2.25" y1="7.5" x2="7.5" y2="2.25" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="7.25" y1="2.25" x2="112.5" y2="2.5" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="112.25" y1="2.5" x2="117.5" y2="7.5" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="117.25" y1="7.25" x2="117.5" y2="32.5" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="117.5" y1="32.25" x2="112.25" y2="37.5" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="112.5" y1="37.5" x2="7.25" y2="37.5" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                        <line x1="7.5" y1="37.75" x2="2.5" y2="32.25" style={{ stroke: "#7BD7FF", strokeWidth: "1.5" }} />
                                                                    </svg>
                                                                    <div className="rank1LeaderText">LEADER</div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-4" style={{ index: '0' }}>
                                                    <div className="row" style={{ marginTop: "80px" }}>
                                                        <div className="col-12" style={{ left: '20px' }}>
                                                            <div className="textRank"><Title name={`RANK NO.3`} no={2} fix={180} imglong={220} top={5} imgbox={110} imgcorner={10} /></div>
                                                        </div>
                                                        <div className="col-12" style={{ left: '15px' }}>
                                                            {count.length >= 3 && <ImCard cardWidth={220} cardHeight={315} imgTEST={count[2].imguser != null ? Configs.TTT_Game_Service_IMG + count[2].imguser : null} cardName={count[2].name || ''} cardRating={count[2].rating || ''} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>) : (<>
                                            <div className="row" style={{ marginTop: "30px" }}>
                                                {cardTargetList.map((e, index) => {
                                                    // console.log('CardList',e);
                                                    return (<>
                                                        <div className="col-4">
                                                            <div className="row">
                                                                <div className="col-12" style={{ marginBottom: "30px" }}>
                                                                    <div className="textRank"><Title name={`RANK NO.${e.no}`} no={2} fix={180} imglong={260} top={5} imgbox={110} imgcorner={10} /></div>
                                                                </div>
                                                                <div className="col-12">
                                                                    {count.length >= 4 && <ImCard cardWidth={245} cardHeight={335} imgTEST={count[index + ((card - 1) * 3)].imguser != null ? Configs.TTT_Game_Service_IMG + count[index + ((card - 1) * 3)].imguser : null} cardName={count[index + ((card - 1) * 3)].name || ''} cardRating={count[index + ((card - 1) * 3) || ''].rating} />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                        </>)}


                                        {/*      {cardTargetList.map((e,index)=>{return (<>
                                                
                                                </>)})} */}



                                    </div>

                                    <button className="lbCardDetailDown" style={{ top: '-1.25%', display: `${(card * 3) >= count.length ? 'none' : ''}` }} onClick={() => {
                                        setCard(card + 1); setDataToCardList(card + 1);
                                    }}>
                                        <img src={down} style={{ width: '24px', marginTop: "-5px" }}></img>
                                    </button>

                                </div>
                            </div>
                        </div>

                    </>
                )
            }

        </>
    )
}

export default LeaderBoard