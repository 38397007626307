import React, { useEffect, useState, useRef } from 'react';
import '../component/howToPlayStep.css';
import step2A from '../image/step2-a.png';
import step211 from '../image/step2-11.png';
import step212 from '../image/step2-12.png';
import step21 from '../image/step2-1.png';
import redHand from '../image/redHand.png'

function HowToPlayStep2(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step2A} alt="" style={{ bottom: '30px', left: '-4px'}}/>
                <div className='howToPlayStep1Detail' style={{ top: '125px', left: '50px', height: '200px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-1%'}}><div style={{fontWeight: '700'}}>เลือกยานอวกาศของเรา</div><div>กด icon จรวดซ้ายมือบน ระบบจะขึ้นหน้าประวัติส่วนตัวของเรา เลื่อนหน้าจอลงมาจะเจอกับแท็บ Spaceship จากนั้นกด Add New Spaceship</div></div>
                </div>
                <img src={step211} style={{width: '7.5%', position: 'absolute', top: '37.5%', left: '12.5%'}}></img>
                <img src={step212} style={{width: '12.5%', position: 'absolute', top: '37.5%', left: '22.5%'}}></img>
                <div className='howToPlayStep1Detail' style={{ top: '350px', left: '50px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-1%'}}><div style={{fontWeight: '700'}}>เลือก Slots ยานอวกาศของเรา</div><div>สามารถเลือก Slots ยานได้ถึง 3 Slots ด้วยกัน ตั้งแต่ยาน <br/>6 Slots, 10 Slots และ 15 Slots</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '465px', left: '50px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-1%'}}><div style={{fontWeight: '700'}}>อายุการใช้งานของยานอวกาศของเรา</div><div>ยานอวกาศของเราจะมีอายุ 3 ปี ถ้าหากยานอวกาศของเราหมดอายุก็สามารถต่ออายุของยานเราได้</div></div>
                </div>
                <img className='howToPlayStep1ImgBg' src={step21} alt="" style={{ top: '105px', right: '50px', width: '55%', height: '65%'}}/>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '267.5px', left: '265px', width: '2.5%'}}/>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '315px', right: '70px', width: '2.5%'}}/>
                <div className='howToPlayStep1ImgBg' style={{ top: '85px', right: '115px', width: 'fit-content'}}>ประวัติและสตอรี่ของยาน</div>
                <svg height="100" width="100" style={{ position: 'absolute', top: '77px', right: '400px'}}>
                    <line x1="0" y1="100" x2="40" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="40" y1="25" x2="70" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '87px', right: '400px'}}>02</div>
                <svg height="50" width="150" style={{ position: 'absolute', top: '323px', right: '435px'}}>
                    <line x1="0" y1="0" x2="25" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="25" y1="25" x2="110" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '333px', right: '445px'}}>03</div>
            </div>
        </>
    );
}

export default HowToPlayStep2;