import React from 'react';
import step9A from '../image/step9-a.png';
import step911 from '../image/step9-11.png';
import step912 from '../image/step9-12.png';
import step931 from '../image/step9-31.png';
import step91 from '../image/step9-1.png';
import step92 from '../image/step9-2.png';
import step93 from '../image/step9-3.png';
import step94 from '../image/step9-4.png';
import redHand from '../image/redHand.png';

function HowToPlayStep9(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep9ImgBg' src={step9A} alt="" style={{ bottom: '-7px', left: '-4px' }} />

                <div className='howToPlayStep1Detail' style={{ top: '120px', left: '350px', width: '525px',height:'150px'  }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>Initialize SRS</div><div style={{fontSize:'13.2px'}}>การเข้าไปกรอกข้อมูล Report โดยเลือก Project ทางด้านซ้ายล่าง เเละเลือกเมนู SRS</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '290px', left: '350px', width: '525px'}}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>Project Detail</div><div style={{fontSize:'13.2px'}}>กรอกรายละเอียดต่างๆ เช่น วัตถุประสงค์, ขอบเขต, ภาพรวมโครงการ</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '390px', left: '350px', width: '525px',height:'200px' }}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>เพิ่มข้อมูล SRS & Export SRS</div><div style={{fontSize:'13.2px'}}>ควรกรอกข้อมูลให้ครบถ้วนเพื่อให้ Report SRS ออกมาสมบูรณ์โดยจะแบ่งเป็น 3 หัวข้อ คือ
                    <br />1. Description 2. Column 3. Table
                    <br /><br />Export SRS - เลือก <span style={{color: '#0047FF'}}>SELECT REPORT</span> เป็น <span style={{color: '#0047FF'}}>SRS</span></div></div>
                </div>

                <img className='howToPlayStep1ImgBg' src={step911} alt="" style={{ top: '210px', left: '450px', width: '18%', height: '6%' }} />
                <img className='howToPlayStep1ImgBg' src={step912} alt="" style={{ top: '210px', left: '725px', width: '5%', height: '5.25%' }} />
                <img className='howToPlayStep1ImgBg' src={step931} alt="" style={{ top: '535px', left: '452.5px', width: '24%', height: '6%' }} />
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '230px', left: '685px', width: '2.5%'}}/>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '550px', left: '705px', width: '2.5%'}}/>

                <div className='howToPlayStep9HeadText' style={{ top: '115px', left: '905px', width: 'fit-content'}}>ข้อมูล Project</div>
                <img className='howToPlayStep1ImgBg' src={step91} alt="" style={{ top: '145px', left: '905px', width: '36%' }} />
                <div className='howToPlayStep9Line' style={{ top: '270px', left: '905px', width: '36%'}} />
                <div className='howToPlayStep9HeadText' style={{ top: '275px', left: '905px', width: 'fit-content'}}>Description</div>
                <img className='howToPlayStep1ImgBg' src={step92} alt="" style={{ top: '300px', left: '905px', width: '36%' }} />
                <div className='howToPlayStep9HeadText' style={{ top: '365px', left: '905px', width: 'fit-content'}}>Column</div>
                <img className='howToPlayStep1ImgBg' src={step93} alt="" style={{ top: '390px', left: '905px', width: '36%' }} />
                <div className='howToPlayStep9HeadText' style={{ top: '435px', left: '905px', width: 'fit-content'}}>Table</div>
                <img className='howToPlayStep1ImgBg' src={step94} alt="" style={{ top: '460px', left: '905px', width: '36%' }} />

            </div>
        </>
    );
}

export default HowToPlayStep9;