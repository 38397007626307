import React, { useState, useEffect, useRef } from 'react';
import './cardPersonalInfo.css'
import ImgCard from './userImg4.jpg';
import IconProject from './Group11.png';
/* import IconProject from '../../../assets/img/icon/Group11.png'; */
import Configs from "../../../config";

function CardPersonalInfo(params) {
    // console.log('testlog ',params);
    let profileIMG = '';
    if (params.imgProfile && params.imgProfile.length > 0) {
        profileIMG = params.imgProfile.length !== undefined &&  params.imgProfile.length > 0 && params.imgProfile[0].url !== undefined ? `${params.imgProfile[0].url}` : '';
    }

    return (
        <>

            <div className="cpi-cardBody" style={{ backgroundImage: `url(${profileIMG !== '' ? profileIMG : ImgCard})`, width: `${params.setCard == '1' ? 394 : 173}px`, height: `${params.setCard == '1' ? 535 : 235}px` }}>
                <div className="cpi-gradient" style={{ width: `${params.setCard == '1' ? 394 : 173}px`, height: `${params.setCard == '1' ? 535 : 235}px` }}>
                    <div className="cpi-boxBar"></div>
                    <div className="cpi-cardFrameTop">
                        <div className="cpi-iLevel" style={{ width: `${params.setCard == '1' ? 44.179 : 19.46}px`, height: `${params.setCard == '1' ? 44.179 : 19.46}px`, fontSize: `${params.setCard == '1' ? 10.868 : 4.787}px` }}>LEVEL
                            <div className="cpi-iLevelIn" style={{fontSize: `${params.setCard == '1' ? 22.802 : 10.044}px` }}>210</div>
                        </div>
                        <div className="cpi-iName" style={{fontSize: `${params.setCard == '1' ? 11.401 : 5.022}px` }}>NAME :
                            <div className="cpi-iNameIn" style={{fontSize: `${params.setCard == '1' ? 34.362 : 15.136}px` }}>{params.imgName} {params.imgLastName}</div>
                        </div>
                        <div className="cpi-iClass" style={{ width: `${params.setCard == '1' ? 44.179 : 19.46}px`, height: `${params.setCard == '1' ? 44.179 : 19.46}px` }}>
                            
                            {params.imgRolelogo ? (<img style= {{objectFit:'contain',position:'relative',marginLeft:'1px',marginTop:'1px',width:'40px',height:'40px'}} src = {`${params.imgRolelogo/* Configs.TTT_Game_Service_IMG+params.imgRolelogo */}`}/>) : ''}
                        </div>
                    </div>
                    <div className="cpi-cardFrameBottom">
                        <div className="cpi-fbBoxMr">
                            <div className="cpi-fbBoxMvp" style={{fontSize: `${params.setCard == '1' ? 14 : 6.544}px` }}>MVP</div>
                            <div className="cpi-fbText" style={{fontSize: `${params.setCard == '1' ? 20.427 : 8.998}px` }}>449</div>
                            <div className="cpi-fbTextRo" style={{fontSize: `${params.setCard == '1' ? 5.471 : 2.41}px` }}>TIMES</div>
                            <div></div>
                            <div className="cpi-fbBoxRat" style={{fontSize: `${params.setCard == '1' ? 14 : 6.544}px` }}>RATING</div>
                            <div className="cpi-fbText" style={{fontSize: `${params.setCard == '1' ? 20.427 : 8.998}px` }}>89</div>
                            <div className="cpi-fbTextRo" style={{fontSize: `${params.setCard == '1' ? 5.471 : 2.41}px` }}>percent</div>
                        </div>
                        <div className="cpi-fbBoxAc"></div>
                        <div className="cpi-fbBoxLong" style={{fontSize: `${params.setCard == '1' ? 16.069 : 7.078}px` }}> <img src={IconProject} style={{ height: `${params.setCard == '1' ? 14 : 6.5}px`, marginTop: `${params.setCard == '1' ? 1 : 0}px`}}></img> SUCCESSFUL PROJECTS
                            <div className="cpi-fbBoxLongIn" style={{fontSize: `${params.setCard == '1' ? 17.206 : 7.579}px` }}>59</div>
                            <div className="cpi-fbBoxLongInText" style={{fontSize: `${params.setCard == '1' ? 10.459 : 4.607}px` }}>PROJECT</div>
                        </div>
                        <div className="cpi-fbBoxLong" style={{fontSize: `${params.setCard == '1' ? 16.069 : 7.078}px` }}> <img src={IconProject} style={{ height: `${params.setCard == '1' ? 14 : 6.5}px`, marginTop: `${params.setCard == '1' ? 1 : 0}px`}}></img> PROJECTS INVOLVED
                            <div className="cpi-fbBoxLongIn" style={{fontSize: `${params.setCard == '1' ? 17.206 : 7.579}px` }}>9</div>
                            <div className="cpi-fbBoxLongInText" style={{fontSize: `${params.setCard == '1' ? 10.459 : 4.607}px` }}>PROJECT</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default CardPersonalInfo