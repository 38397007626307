import React, { useState, useEffect, useRef } from 'react'
import Configs from "../../../config";
import axios from 'axios';
import { Space, ConfigProvider, Radio } from 'antd';
import moment from 'moment';
import './stageElementStyle.css'

const BackendCodeGen = ({
  isOpen,
  toggle_modal_status,
  project_id,
  displayMode
}) => {

  const [valueTypeCodeGen, setValueTypeCodeGen] = useState("nodejs");

  const [uploadfileScreenshot, setuploadfileScreenshot] = useState({
    row: [],
    on: 0,
    size: 0,
    remove: []

  })

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleWindowResize = () => {
    if (uploadfileScreenshot.row.length > 0) {
      let windowy = document.getElementById("scrollableindiv").offsetWidth
      let cutwindowy = windowy * 0.7
      let cut = Math.floor(cutwindowy / 480)

      setuploadfileScreenshot({ ...uploadfileScreenshot, size: (cutwindowy - (cut * 480)) / 2 })
    } else {
      setuploadfileScreenshot({ ...uploadfileScreenshot, size: 0 })
    }
  };

  async function codeGen() {
    downloadZipFile(project_id, valueTypeCodeGen)
  }

  const downloadZipFile = async (project_id, type) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${Configs.TTT_Game_Service}/api/codeGen/backend/${type}/${project_id}`,
        headers: {
          "X-TTT": `${Configs.API_TTT}`,
          Authorization: sessionStorage.getItem('access_token'),
        },
        responseType: 'blob', // เพิ่ม responseType: 'blob' ที่นี่
      })

      // สร้าง URL สำหรับ Blob ที่ได้รับ

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${moment().format("YYYYMMDDHHmmss")}_code_backend.zip`); // ตั้งชื่อไฟล์ที่ต้องการดาวน์โหลด
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); // ลบ link ออกจาก DOM หลังจากดาวน์โหลดเสร็จ
      toggle_modal_status()
    } catch (error) {
      console.error('Error downloading the zip file:', error);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',
            colorTextDescription: '#000',
            colorBgContainer: '#000',
            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue',
            colorBgBase: '#778899',
            colorTextQuaternary: '#FFFFFF',
            colorFillTertiary: '#000',
            colorTextBase: 'blue',
            colorPrimaryBg: '#85BFFF',
          }
        }}
      >
        <div className='stageElementBg' style={{ display: `${isOpen ? 'flex' : 'none'}`, zIndex: '10000' }}>
          <div className='stageElementBgClose' onClick={() => {
            toggle_modal_status();
          }} />
          <div className='stageElementBlackendGenCodeBoxShadow' >
            <div className='stageElementBox'>
              <div className='stageElementBoxIn'>

                <div className='stageElementHeader'>

                  <div style={{ position: 'absolute', top: '36px', right: '25px' }}>
                    <button disabled={displayMode == true || displayMode == 'true'} className=' btn-outline-primary mr-2' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => { codeGen(); }}> Gen Code </button>
                    <button className=' btn-outline-danger' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {

                      setuploadfileScreenshot({ ...uploadfileScreenshot, row: [], size: 0, remove: [] })
                      toggle_modal_status();
                    }}> Cancel </button>
                  </div>


                  <div className='stageElementHeaderLeft' style={{ marginTop: '10px' }}  /* scrollLeft={scrollPosition} */>
                    <div className='stageElementHeaderSrs'>Back-End Code</div>
                    <div className='stageElementHeaderLine' style={{ marginTop: '16px', width: '110%' }} />
                  </div>

                </div>
                <div className='row' id="codegenbackenddiv" style={{ height: '89%', display: 'flex', justifyContent: 'center', overflowX: 'hidden', overflowY: 'auto', marginTop: '110px', marginLeft: `${uploadfileScreenshot.size}px` }} >
                  <Radio.Group value={valueTypeCodeGen} onChange={(e) => setValueTypeCodeGen(e.target.value)}>
                    <Space direction="vertical">
                      <Radio value={"nodejs"}>Node.js [Javascript]</Radio>
                      <Radio value={"nodets"} disabled>Node.js [Typescript] (coming soon)</Radio>
                      <Radio value={".net"} disabled>C# [.Net] (coming soon)</Radio>
                    </Space>
                  </Radio.Group>
                </div>

              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  )
}

export default BackendCodeGen
