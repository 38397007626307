import jsPDF from "jspdf";
import {
  Get_font_pdf_th1,
  Get_font_pdf_th2,
} from "../../../assets/fonts/pdf_font";
import moment from "moment";
import Configs from "../../../config";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png";
import clipPaperIcon from './img/clip paper.png';
import axios from "axios";
import dayjs, { isDayjs } from "dayjs";
async function MOMreport(data, id, mode, summaryofchange) {
  const userAgent = window.navigator.userAgent;

  let operatingSystem = "Unknown",
    ispah = 1;

  if (userAgent.indexOf("Win") !== -1) {
    operatingSystem = "Windows";
  } else if (userAgent.indexOf("Mac") !== -1) {
    operatingSystem = "Mac OS";
  } else if (userAgent.indexOf("Linux") !== -1) {
    operatingSystem = "Linux";
  }
  if (operatingSystem === "Mac OS") {
    ispah = 1;
  }
  let imghead = noimg;
  const doc = new jsPDF("p", "mm", "a4");

  doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
  doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal");
  doc.setFont("THSarabunNew", "normal");
  ///////////กำหนดตั้งค่าหน้าแนวตั้ง
  let padding = 20, ////เว้นขอบข้างื
    wpage = 210, ///กว้าง
    hpage = 297, ///สูง
    page = 1,
    r = 194,
    g = 194,
    b = 194,
    endpage = 250,
    his = [],
    img = {};
  let heightpage = 34;

// console.log("data เข้ามาแล้ว ",data);

  if (data.project_image.length > 0) {
    // console.log("เข้า",data.project_image);
  
      let getpath = await axios.get(`https://api.box.com/2.0/files/${data.project_image[0].fd_img_id}/content`, {
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
      });

      img["ul_file_path"] = URL.createObjectURL(getpath.data);
  
  
    // img = data.project_image[0].fd_img_id;
  }
  // console.log("img.ul_file_path: ",img);
  let colordrop = [
    {
      before: "#676767",
      after: "#DEDEDE",
    },
    {
      before: "#FF00E5",
      after: "#FBDCFF",
    },
    {
      before: "#BA84FF",
      after: "#CCD9FA",
    },
    {
      before: "#00A5FF",
      after: "#CDEEFF",
    },
    {
      before: "#259B24",
      after: "#D3FFCF",
    },
    {
      before: "#FFEB3B",
      after: "#FFFDCF",
    },
    {
      before: "#FF8000",
      after: "#FFE0C1",
    },
    {
      before: "#FF0000",
      after: "#FFD9D9",
    },
  ];
  if (data.project.federation_color !== null) {
    let selectcolor = colordrop.findIndex(
      (e) =>
        e.after == data.project.federation_color ||
        e.before == data.project.federation_color
    );
    // console.log(selectcolor);
    if (selectcolor !== -1) {
      let hex = colordrop[selectcolor].after.replace(/^#/, "");
      let bigint = parseInt(hex, 16);

      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    }
  }
  function sethead() {
    Shot('dc',0,0,0)
    Shot('r', 10, CutPadding('w', 0, 17), wpage-padding, hpage-60);
    Shot( "i", img.ul_file_path !== undefined ? img.ul_file_path : imghead, 0, 0, wpage + 1, 27 );
  }
  function setfooter(on, max) {
    Shot("lw", 0.3);
    Shot("fs", 12);
    Shot( "t", 200, 280, TexttoString( `All Rights Reserved, Copyright by TTT Brother Co., Ltd | ${on}` ), "r" );
    Shot("t", 200, 284, TexttoString(`Template Version 1.0`), "r");
    // doc.line(25, 291 , 190, 291);
    // Shot('t', CutPadding('w', 95, 2), 273 + 8, TexttoString(`V${parseFloat(data.version.version) + 1}.0`), 'r')
  }
  function CutPadding(mode, percent, num) {
    if (mode === "h") {
      if (num !== undefined) {
        return (
          (hpage /*  - (padding * 2) */ * percent) / 100 /*  + padding */ + num
        );
      }
      return (hpage /*  - (padding * 2) */ * percent) / 100 /* + padding */;
    }
    if (mode === "w") {
      if (num !== undefined) {
        return ((wpage - padding * 2) * percent) / 100 + padding + num;
      }
      return ((wpage - padding * 2) * percent) / 100 + padding;
    }
  }
  // return window.open(doc.output('bloburl'));
  async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
    if (mode === "add") {
      if (c1 !== undefined) {
        return doc.addPage(c1);
      }
      return doc.addPage();
    }
    if (mode === "new") {
      window.open(doc.output("bloburl"));
    }
    if (mode === "save") {
      if (c1 !== undefined) {
        return doc.output("save", c1);
      }
      return doc.output(
        "save",
        moment(new Date()).add("year", 543).format("DD/MM/YY")
      );
    }
    if (mode === "newsave") {
      if (c1 !== undefined) {
        return window.open(doc.output("bloburl"), doc.output("save", c1));
      }
      return window.open(
        doc.output("bloburl"),
        doc.output(
          "save",
          moment(new Date()).add("year", 543).format("DD/MM/YY")
        )
      );
    }
    // return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));
    /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
    if (mode === "fs") {
      doc.setFontSize(c1);
    }
    if (mode === "dc") {
      doc.setDrawColor(c1, c2, c3);
    }
    if (mode === "tc") {
      doc.setTextColor(c1, c2, c3);
    }
    if (mode === "fc") {
      doc.setFillColor(c1, c2, c3);
    }
    if (mode === "lw") {
      doc.setLineWidth(c1);
    }

    if (mode === "i") {
      if (c5 !== undefined) {
        if (c6 !== undefined) {
          if (c6 === "fit") {
            const imgar = c7.width / c7.height;
            const reactar = c4 / c5;

            let scale = 1;
            if (imgar > reactar) {
              scale = c4 / c7.width;
            } else {
              scale = c5 / c7.height;
            }
            const nw = c7.width * scale;
            const nh = c7.height * scale;

            const x = (c4 - nw) / 2;
            const y = (c5 - nh) / 2;
            return doc.addImage(c1, "JPEG", c2 + x, c3 + y, nw, nh);
          }
        }
        return doc.addImage(c1, "JPEG", c2, c3, c4, c5);
      }
      return doc.addImage(c1, "JPEG", c2, c3, c4, c4);
    }
    if (mode === "r") {
      if (c5 !== undefined) {
        if (c5 === "d") {
          return doc.rect(c1, c2, c3, c4, "D");
        }
        if (c5 === "fd") {
          return doc.rect(c1, c2, c3, c4, "FD");
        }
        if (c5 === "f") {
          return doc.rect(c1, c2, c3, c4, "F");
        }
      } else return doc.rect(c1, c2, c3, c4);
    }
    if (mode === "t") {
      if (c4 !== undefined) {
        if (c4 === "c") {
          return doc.text(c1, c2, c3, "center");
        }
        if (c4 === "r") {
          return doc.text(c1, c2, c3, "right");
        }
        if (c4 === "l") {
          return doc.text(c1, c2, c3, "left");
        }
      } else return doc.text(c1, c2, c3);
    }
  }
  function ShotTable(mode, head, pos, col, coll, loop, color, hig, link = [], header) {
    /* console.log(mode, head, pos, col, coll, loop, color); */
    let collengthsum = coll === undefined ? 5 : coll
    let loopsum = loop === undefined ? 10 : loop
    if (mode === 'ht') {
        for (let t = 1; t <= col; t += 1) {
            Shot('fc', r, g, b);
            Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
            if (head[`name${t}`] !== undefined) {
                Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
            }


        }
    }

    if (mode === 'lt') {
        let lengthloop = (loopsum * collengthsum) + collengthsum
        for (let t = 1; t <= col; t += 1) {

            Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


        }

    }

    if (mode === 'st') {
        let lengthloop = collengthsum
        for (let l = 0; l < loopsum; l += 1) {
            for (let t = 1; t <= col; t += 1) {
                Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

            }
            lengthloop += collengthsum
        }
    }

    if (mode === 'htc') {
        for (let t = 1; t <= col; t += 1) {
            Shot('fc', r, g, b);
            Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

            if (head[`name${t}`] !== undefined) {
                for (let c of head[`name${t}`]) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                    collengthsum += coll
                }
                collengthsum = coll
            }



        }
        heightpage += collengthsum * hig
    }
    if (mode === 'ltc') {
        let befor = 0, higbefor = pos, maxhig = 0
        for (let t = 1; t <= col; t += 1) {
            Shot('fc', r, g, b);
            /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

            if (head[`name${t}`] !== undefined) {
                for (let d = befor; d < head[`name${t}`].length; d += 1) {
                    let c = head[`name${t}`][d]
                    if (heightpage + ((d - befor) * 6) > 250) {
                        if (t < col) {
                            for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                let fakecolsum = coll
                                for (let dx = befor; dx <= d; dx += 1) {
                                    if (head[`name${t2}`].length > dx) {
                                        let cx = head[`name${t2}`][dx]

                                        let chackrow = link.filter((e) => { return e.n === cx })
                                        console.log(chackrow, cx, 'in');
                                        if (chackrow.length > 0) {
                                            /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                                           */
                                            let y = higbefor + (fakecolsum), x = Colposition('t', head, t2, 'l', 3)
                                            let width = doc.getTextWidth(chackrow[0].n);
                                            let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                                            y += height * 0.2;
                                            doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                                        } /* else { */
                                        Shot('t', Colposition('t', head, t2, t2 === 1 ? 'c' : 'l', 3), higbefor + (fakecolsum), TexttoString(cx), t2 === 1 ? 'c' : 'l')
                                        /* } */
                                        fakecolsum += coll
                                    } else {
                                        break
                                    }

                                }

                            }

                        }
                        for (let al = 1; al <= col; al += 1) {
                            Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                        }

                        heightpage += ((d - befor) * 6)
                        newpage(250)
                        higbefor = heightpage
                        collengthsum = col
                        befor = d
                        maxhig = col
                        ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                        higbefor += 7
                    }


                    let chackrow = link.filter((e) => { return e.n === c })
                    if (chackrow.length > 0) {
                        /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                       */
                        let y = higbefor + (collengthsum), x = Colposition('t', head, t, 'l', 3)
                        let width = doc.getTextWidth(chackrow[0].n);
                        let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                        y += height * .2;
                        doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                    }
                    Shot('t', Colposition('t', head, t, t === 1 ? 'c' : 'l', 3), higbefor + (collengthsum), TexttoString(c), t === 1 ? 'c' : 'l');
                    collengthsum += coll

                }
                if (collengthsum > maxhig) {
                    maxhig = collengthsum
                }

                collengthsum = coll

                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
            }
            /* befor+=1 */


        }
        for (let al = 1; al <= col; al += 1) {
            Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
        }
        heightpage += maxhig
        /*  heightpage += (collengthsum * hig) + 4 */
    }

    if (mode === 'stc') {
        for (let t = 1; t <= col; t += 1) {
            Shot('fc', r, g, b);
            Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

            if (head[`name${t}`] !== undefined) {
                for (let c of head[`name${t}`]) {
                    Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                    collengthsum += coll
                }
                collengthsum = coll
            }



        }
        heightpage += (collengthsum * hig) + 4
    }
}
function newpage(count) {
  if (heightpage > count) {
      Shot("add");
      page += 1
      heightpage = 38

  }
}
  function Colposition(mode, name, col, pos, pad) {
    if (mode === "r") {
      const poscol = pos !== undefined ? pos : 0;
      const colcount = col - 1;
      let colsum = 0;

      for (let i = colcount - 1; i > 0; i -= 1) {
        colsum += name[`col${i}`];
      }

      if (colcount === 0) {
        return CutPadding("w", 0);
      }
      return CutPadding("w", 0, name[`col${colcount}`] + poscol) + colsum;
    }
    if (mode === "t") {
      let colsum = 0;
      const paddingcol = pad !== undefined ? pad : 0;
      for (let i = col - 1; i > 0; i -= 1) {
        colsum += name[`col${i}`];
      }

      if (col === 0) {
        return CutPadding("w", 0);
      }

      if (pos !== undefined) {
        if (pos === "c") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (50 + paddingcol)) / 100) +
            colsum
          );
        }
        if (pos === "l") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (1 + paddingcol)) / 100) +
            colsum
          );
        }
        if (pos === "r") {
          return (
            CutPadding("w", 0, (name[`col${col}`] * (99 - paddingcol)) / 100) +
            colsum
          );
        }
      }
      return CutPadding("w", 0, name[`col${col}`]) + colsum;
    }
  }
  const compressImage = async (imageUrl, maxWidth = 1920, maxHeight = 1080) => {
    /*  const mmToPx = (mm) => (mm * 96) / 25.4; */

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imageUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let wpx = img.width;
        let hpx = img.height;
        const mwpx = /* mmToPx(maxWidth) */ maxWidth;
        const mhpx = /* mmToPx(maxHeight) */ maxHeight;
        /*  console.log('w',mwpx,wpx);
           console.log('h',mhpx,hpx); */

        if (wpx > hpx) {
          if (wpx > mwpx) {
            hpx = Math.round((hpx * mwpx) / wpx);
            wpx = mwpx;
          }
        } else {
          if (hpx > mhpx) {
            wpx = Math.round((wpx * mhpx) / hpx);
            hpx = mhpx;
          }
        }

        canvas.width = wpx;
        canvas.height = hpx;

        ctx.drawImage(img, 0, 0, wpx, hpx);

        resolve(canvas.toDataURL("image/jpeg", 0.8));
      };

      img.onerror = () => {
        reject(new Error("Failed to load image."));
      };
    });
  };

  const compressImageWH = async (imageUrl, maxWidth = 1920, maxHeight = 1080) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function() {
          resolve({ width: img.width, height: img.height });
      };
      img.onerror = function() {
          reject('Failed to load image.');
      };
      img.src = imageUrl;
  });
  };

  async function CheckAndCreateFolderBox(datalist,file) {
    // console.log("mom_project_id: ",mom_project_id);
    let temp = {
        folderLis: ["PROJECT",`PROJECT_${data.mom_project_id}`,"PROJECT DOCUMENTS","MOM"]
    }
   /*  temp.folderLis.push(folder_name) */
    await axios({
        method: 'post',
        url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
        data: temp
    })
        .then(async function (response) {
            if (response.data) {
                
                datalist.files = file
                await UploadFiles(datalist, response.data.folder_id,[])

            }
        })
        .catch(function (error) {
           
            console.log(error);
        });
};

async function UploadFiles(item, folder_id,old) {
  // console.log(item);
  const dataImg = new FormData();
  dataImg.append('File', item.files);
  dataImg.append('folder_id', folder_id);
  dataImg.append('key', "MOM");
  dataImg.append('image_old_list', JSON.stringify(old));
  dataImg.append('ref_id', item.id);

  await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "multipart/form-data",
      },
      data: dataImg
  })
      .then(async function (response) {
          if (response.data) {
            if (response.data.image_id_list.length > 0) {
                return true
            
            }
           
          }
      })
      .catch(function (error) {
        
          console.log(error);
      });
};

async function getboximg(imageid) {
  try{
  const response2 = await axios.get(`https://api.box.com/2.0/files/${imageid}/content`, {
      headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',
  });
  
return URL.createObjectURL(response2.data);
}catch (error) {
  return null 
}
}


  function TexttoString(text) {
    // console.log("text",text);
    if (text === null) {
      return "".toString();
    }
    return text.toString();
  }
  function splitTextToSize(text, size) {
    // Create a regular expression to match chunks of the specified size
    let regex = new RegExp(`.{1,${size}}`, "g");
    return text.match(regex) || [];
  }
  let x_line =10,
      y_line =17,
      foot_limit = hpage -80,
      foot_image_limit = hpage -100,
      head_box_limit = hpage - 70 ;
      
  Shot('fs', 20);
  let nametemplate = 'บันทึกการประชุม' ;
  
  
  y_line += 6;
  
  Shot('dc',0, 0, 0);
  Shot('fc',r, g, b);
  Shot('r',x_line,CutPadding('w', 0, y_line-6),wpage-padding,11,'f');
  y_line+=1;
  Shot('t',parseInt( wpage/2)-((doc.getStringUnitWidth(nametemplate) * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line), TexttoString(`${nametemplate}`), 'l');
  Shot('t',parseInt( wpage/2)-((doc.getStringUnitWidth(nametemplate) * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line), TexttoString(`${nametemplate}`), 'l');
  Shot('t',parseInt( wpage/2)-((doc.getStringUnitWidth(nametemplate) * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line), TexttoString(`${nametemplate}`), 'l');
  
  y_line+=4;
  doc.line(x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
  Shot('fs', 16);
  y_line+=6;
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`หัวเรื่อง`), 'l');
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`หัวเรื่อง`), 'l');
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`หัวเรื่อง`), 'l');
  Shot( "t", x_line+20, CutPadding('w', 0, y_line), TexttoString( data?.mom_header !== undefined || data?.mom_header !== null ? data?.mom_header : `ไม่ระบุ` ), "l" );
  Shot( "t", x_line+20, CutPadding('w', 0, y_line), TexttoString( data?.mom_header !== undefined || data?.mom_header !== null ? data?.mom_header : `ไม่ระบุ` ), "l" );
  Shot( "t", x_line+20, CutPadding('w', 0, y_line), TexttoString( data?.mom_header !== undefined || data?.mom_header !== null ? data?.mom_header : `ไม่ระบุ` ), "l" );
  y_line+=3;
  doc.line(x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
  y_line+=6;
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`สถานที่ประชุม`), 'l');
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`สถานที่ประชุม`), 'l');
  Shot('t',x_line+2, CutPadding('w', 0, y_line), TexttoString(`สถานที่ประชุม`), 'l');
  Shot( "t", x_line+33, CutPadding('w', 0, y_line), TexttoString( data?.mom_location !== undefined || data?.mom_location !== null ? data?.mom_location : `ไม่ระบุ` ), "l" );
  doc.line(x_line+104, CutPadding('w', 0, y_line-6), 114, CutPadding('w', 0, y_line+3));
  doc.line(x_line+143, CutPadding('w', 0, y_line-6), 153, CutPadding('w', 0, y_line+3));
  Shot("t", x_line+107, CutPadding('w', 0, y_line), TexttoString(`วันที่`), "l");
  Shot("t", x_line+107, CutPadding('w', 0, y_line), TexttoString(`วันที่`), "l");
  Shot("t", x_line+107, CutPadding('w', 0, y_line), TexttoString(`วันที่`), "l");
  Shot("t", x_line+117, CutPadding('w', 0, y_line), TexttoString( data?.mom_start_date !== undefined || data?.mom_start_date !== null ? `${dayjs(data?.mom_start_date).format("DD/MM/YYYY")}` : `ไม่ระบุ` ), "l" );
  Shot("t", x_line+146, CutPadding('w', 0, y_line), TexttoString(`เวลา`), "l");
  Shot("t", x_line+146, CutPadding('w', 0, y_line), TexttoString(`เวลา`), "l");
  Shot("t", x_line+146, CutPadding('w', 0, y_line), TexttoString(`เวลา`), "l");
  Shot("t", x_line+156,  CutPadding('w', 0, y_line), TexttoString( data?.mom_start_date !== undefined || data?.mom_end_date !== undefined ? `${dayjs(data?.mom_start_date).format("HH:mm")} - ${dayjs( data?.mom_end_date ).format("HH:mm")} น.` : `ไม่ระบุ` ), "l" );
  y_line+=3;
  doc.line(x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
  y_line+=6;
  
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้บันทึกประชุม`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้บันทึกประชุม`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้บันทึกประชุม`), "l");

  Shot( "t", x_line+33, CutPadding('w', 0, y_line), TexttoString( data?.mom_record_by !== undefined || data?.mom_record_by !== null ? data?.mom_record_by : `ไม่ระบุ` ), "l" );
  y_line+=3;
  doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
  y_line+=6;
  

  for (let index_com = 0; index_com < data?.mom_person.length; index_com++) {

    if (y_line > hpage-55) {
      Shot("add");
      page++;
      x_line =10;
      y_line =17;

      doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
      y_line+=6;
    }
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
    Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
    Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
    Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
    y_line+=3;
    doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
    y_line+=6;
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
    Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
    y_line+=3;
    doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
    y_line+=6;
    Shot("t", x_line+10, CutPadding('w', 0, y_line), TexttoString(`ชื่อ`), "l");
    doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-6), parseInt( wpage/2), CutPadding('w', 0, y_line+3));
    Shot("t", wpage-(parseInt( wpage/4)), CutPadding('w', 0, y_line), TexttoString(`ตำแหน่ง`), "r");
    y_line+=3;
    doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
    y_line+=6;

    let list_name_customer =
        data?.mom_person[index_com]?.mom_person_list.length > 0
          ? data?.mom_person[index_com]?.mom_person_list.length
          : 0;
    // let tempCount = customerlimit;
    for (let index = 0; index < list_name_customer; index++) {
      let lineSpecail = 0,lindrop=4;
      if (index==0) {
        lineSpecail+=3;
      }
      if ((index+1) === list_name_customer) {
        lindrop-=1;
      }
      if (y_line >= 249) {
        lindrop+=1;
      }

      // console.log(y_line,`${index + 1}`);
      if (y_line > hpage-45) {
        // doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-(3+lineSpecail)), parseInt( wpage/2), CutPadding('w', 0, y_line+lindrop+1));
        lineSpecail+=3;
        lindrop-=1;
        Shot("add");
        page++;
        x_line =10;
        y_line =17;
        y_line += 6;
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ตัวแทนบริษัท`), "l");
        Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
        Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
        Shot("t", x_line+33, CutPadding('w', 0, y_line), TexttoString(`${data?.mom_person[index_com]?.mpg_name}`), "l");
        y_line+=3;
        doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
        y_line+=6;
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
        Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ผู้เข้าร่วมประชุม`), "l");
        y_line+=3;
        doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
        y_line+=6;
        Shot("t", x_line+10, CutPadding('w', 0, y_line), TexttoString(`ชื่อ`), "l");
        doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-6), parseInt( wpage/2), CutPadding('w', 0, y_line+3));
        Shot("t", wpage-(parseInt( wpage/4)), CutPadding('w', 0, y_line), TexttoString(`ตำแหน่ง`), "r");
        y_line+=3;
        doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
        y_line+=6;

      }
      
      
      // Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ชื่อ`), "l");
      Shot( "t", x_line+2, CutPadding('w', 0, y_line), TexttoString( `${index + 1}. ${ data?.mom_person[index_com]?.mom_person_list[index]?.mp_name !== null ? data?.mom_person[index_com]?.mom_person_list[index]?.mp_name : "ไม่ระบุ" }` ), "l" );
      doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-(3+lineSpecail)), parseInt( wpage/2), CutPadding('w', 0, y_line+lindrop));
      Shot( "t", parseInt( wpage/2)+4, CutPadding('w', 0, y_line), TexttoString( `${ data?.mom_person[index_com]?.mom_person_list[index]?.mp_position !== null ? data?.mom_person[index_com]?.mom_person_list[index]?.mp_position : "ไม่ระบุ" }` ), "l" );
     
      if ((index+1) === list_name_customer) {
        y_line+=3;
        doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
        y_line+=6;
      }else{
        y_line+=7;
      }
    }
  }
  if (y_line > hpage-50) {
    // doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-(3+lineSpecail)), parseInt( wpage/2), CutPadding('w', 0, y_line+lindrop+1));
    Shot("add");
    page++;
    x_line =10;
    y_line =17;
    y_line += 6;
  }
  let content_list = data?.mom_srs?.none_follow_up.length > 0 ? data?.mom_srs?.none_follow_up.length : 0;
  for (let index = 0; index < content_list; index++) {
    if (y_line > hpage-55) {
      Shot("add");
      page++;
      x_line =10;
      y_line =17;

      doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
      y_line+=7;
    }
    let module_list = data?.mom_srs?.none_follow_up[index]?.menu_data.length > 0 ? data?.mom_srs?.none_follow_up[index]?.menu_data.length : 0;
    Shot("t", x_line+3, CutPadding('w', 0, y_line), TexttoString( `โมลดูล ${data?.mom_srs?.none_follow_up[index]?.module_name}` ), "l");
    Shot("t", x_line+3, CutPadding('w', 0, y_line), TexttoString( `โมลดูล ${data?.mom_srs?.none_follow_up[index]?.module_name}` ), "l");
    Shot("t", x_line+3, CutPadding('w', 0, y_line), TexttoString( `โมลดูล ${data?.mom_srs?.none_follow_up[index]?.module_name}` ), "l");
    // y_line+=3;
    // doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
    y_line+=8;
    for (let index_module = 0; index_module < module_list; index_module++) {
      let menu_list = data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.srs_data .length;
      if (y_line > hpage-55) {
        Shot("add");
        page++;
        x_line =10;
        y_line =17;
  
        doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
        y_line+=6;
      }
      Shot("t", x_line+10, CutPadding('w', 0, y_line), TexttoString( `เมนู ${data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.menu_name}` ), "l");
      Shot("t", x_line+10, CutPadding('w', 0, y_line), TexttoString( `เมนู ${data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.menu_name}` ), "l");
      Shot("t", x_line+10, CutPadding('w', 0, y_line), TexttoString( `เมนู ${data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.menu_name}` ), "l");
      y_line+=7
      for ( let index_srs_data = 0; index_srs_data < menu_list; index_srs_data++ ) {
        if (y_line > hpage-55) {
          Shot("add");
          page++;
          x_line =10;
          y_line =17;
    
          doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
          y_line+=6;
        }
        if ( data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.menu_id == data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.menu_id ) {
         let text_data = doc.splitTextToSize( `• ${ data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.sm_comment } [${dayjs( data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.sm_created_date ).format("HH:mm")} น. ${ data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.sm_follow_name !== null ? data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.sm_follow_name : "" }] `, CutPadding('w', 85, 0), "left" );
          let i_count = 0;
         for (const text of text_data) {
          if (y_line > hpage-50) {
            Shot("add");
            page++;
            x_line =10;
            y_line =17;
      
            doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
            y_line+=6;
          }

            Shot("t", x_line+20, CutPadding('w', 0, y_line), text, "l");
            // console.log("getStringUnitWidth",);
            if (i_count===(text_data.length-1) && data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.srs_file_list.length>0) {
              // Shot("t",( text.length*1.6)+x_line+20, CutPadding('w', 0, y_line), `${text.length} , ${text.length+x_line+20}`, "l");
              Shot('i', `${clipPaperIcon}`, (doc.getStringUnitWidth(text) * 16 / doc.internal.scaleFactor)+30, CutPadding('w', 0, y_line-3), 5, 5)
            }
            y_line+=7
            i_count++;
          }
          // if (data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.srs_file_list.length>0) {
          //   Shot('i', `${clipPaperIcon}`, Colposition('t', jsonunit2, 3, 'r', 5), heightpage - 4, 5, 5)
          // }
        }
          let image_list = data?.mom_srs?.none_follow_up[index]?.menu_data[index_module] ?.srs_data[index_srs_data]?.srs_image_list.length;
         
          for (let index_data = 0; index_data < image_list; index_data++) {
            
            const mmToPx = (mm) => (mm * 96) / 25.4;
            const PxTomm = (px) => (px*25.4) / 96;
            const img_url_temp = /* getboximg(  `${Configs.TTT_Game_Service_IMG+*/data?.mom_srs?.none_follow_up[index]?.menu_data[index_module]?.srs_data[index_srs_data]?.srs_image_list[index_data]?.fd_img_id
            const response2 = await axios.get(`https://api.box.com/2.0/files/${img_url_temp}/content`, {
              headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
              },
              responseType: 'blob',
          });
          const img_url = URL.createObjectURL(response2.data);
            
            // console.log("img_url: ",img_url);
            // console.log("wH brf",await compressImageWH(img_url));
            // console.log(PxTomm(await compressImageWH(img_url).width/1.5));
            // let prepareImg = await compressImage(img_url,PxTomm(await compressImageWH(img_url).width)/1.5,PxTomm(await compressImageWH(img_url).height)/1.5);
            // console.log("img",img_url);
            // hpage
            let sizeImgS = await compressImageWH(img_url);
            let maxWidthImg = wpage-70;
            let maxHeightImg = 80;
            let img_final_width = PxTomm(sizeImgS.width),img_final_height= PxTomm(sizeImgS.height),retion_scale = 0;
            // console.log("img_final_width",img_final_width,"img_final_height",img_final_height);
            if (img_final_width > maxWidthImg) {
              let diff = img_final_width - maxWidthImg;
              retion_scale = ( diff*100)/img_final_width;
              img_final_width = maxWidthImg;
              img_final_height = img_final_height - ((img_final_height * retion_scale)/100)
            }
            // console.log("img_final_height",img_final_height,"maxHeightImg",maxHeightImg);
            if (img_final_height > maxHeightImg) {
              let diff = img_final_height - maxHeightImg;
              retion_scale = ( diff*100)/img_final_height;
              img_final_height = maxHeightImg;
              img_final_width = img_final_width - ((img_final_width * retion_scale)/100)
            }
            // console.log("img_final_width",img_final_width,"img_final_height",img_final_height);
            // console.log(PxTomm(sizeImgS.width),PxTomm(sizeImgS.height));
            let paparSizeX = wpage ,paparSizeY =hpage;
            let imgStartX = ((paparSizeX/2) - (img_final_width/2));
            // console.log("paparSizeX",paparSizeX,"(paparSizeX/2)",(paparSizeX/2),"PxTomm(sizeImgS.width/2)",PxTomm(sizeImgS.width/4),"lop",(paparSizeX/2) - PxTomm(sizeImgS.width/8));
            // let imgEndY = paparSizeY - (CutPadding('w', 0, y_line))
            // console.log("imgStartX",imgStartX);
            if (y_line > hpage-50 || (y_line+parseInt(img_final_height)+7)>hpage-50 ) {
              Shot("add");
              page++;
              x_line =10;
              y_line =17;
        
              doc.line( x_line, CutPadding('w', 0, y_line), wpage-10, CutPadding('w', 0, y_line));
              y_line+=6;
            }
            doc.addImage(img_url, 'JPEG', imgStartX, CutPadding('w', 0, y_line),img_final_width, img_final_height, "", 'FAST')
            y_line+=parseInt(img_final_height)+10;

            // await Shot( 'i', prepareImg, await CutPadding('w', 20, -padding), heightpage, await CutPadding('w', 80, -padding), 75, 'fit', compressImageWH(prepareImg) );
          }
            
      }
    }
    y_line+=8;
  }
  // y_line+=6;
  if (y_line > hpage-70) {
    Shot("add");
    page++;
    x_line =10;
    y_line =17;
    y_line += 6;
  }
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของลูกค้า`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของลูกค้า`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของลูกค้า`), "l");
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));

  let follower_customer_list = data.mom_srs?.customer_follow_up.length > 0 ? data.mom_srs?.customer_follow_up.length : 0;
  let limit_follower_customer = 0;
  y_line+=3;
  Shot('dc',0, 0, 0);
  Shot('fc',r, g, b);
  Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
  doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
  doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
  doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
  doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));

  y_line+=6;
  // doc.rect(20, x_line - 10, 170, 10, "F");
  // doc.setDrawColor(r, g, b);
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");

  y_line+=3;
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน

  for (let index = 0; index < follower_customer_list; index++) {
    if (y_line > hpage-50) {
      Shot("add");
      page++;
      x_line =10;
      y_line =17;
      y_line += 6;

      Shot('dc',0, 0, 0);
      Shot('fc',r, g, b);
      Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
      doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
      doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
      doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));
      y_line+=6;
      // doc.rect(20, x_line - 10, 170, 10, "F");
      // doc.setDrawColor(r, g, b);
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
    
      y_line+=3;
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
    }
    y_line+=6;
    let text_data = doc.splitTextToSize(`${index+1}. ${data.mom_srs?.customer_follow_up[index]?.sm_comment}`, CutPadding('w', 41, 0),"left");
    let text_data_count= 0;
    let newPage = false;
    for (const text of text_data) {
      let lineSpecail = 0,lindrop=0;
      if (text_data_count==0) {
        lindrop+=6;
      }
      if ((text_data_count+1) === text_data.length) {
        lineSpecail-=3;
      }
      // if (y_line >= 249) {
      //   lindrop+=1;
      // }

      if (y_line > hpage-50) {
        if ((text_data_count+1)<=text_data.length && text_data_count!= 0) {
          doc.line(x_line+2, CutPadding('w', 0, y_line-3), wpage-12, CutPadding('w', 0, y_line-3)); //ล่าง
        }
        newPage = true;
        Shot("add");
        page++;
        x_line =10;
        y_line =17;
        y_line += 6;
  
        Shot('dc',0, 0, 0);
        Shot('fc',r, g, b);
        Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
        doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
        doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
        doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
        // doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));
        y_line+=6;
        // doc.rect(20, x_line - 10, 170, 10, "F");
        // doc.setDrawColor(r, g, b);
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของลูกค้า") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      
        y_line+=3;
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
        y_line+=6;
        lineSpecail = 0;
        lindrop=0;
        // lindrop+=6;
      }
      // let newLine = 0;
      let setnewLine = 0;
      if (newPage) {
        newPage = false;
        // newLine+=3;
        lindrop+=6;
        if ((text_data_count+1) === text_data.length) {
          setnewLine=-3;
        }
        // lineSpecail+=3;
      }
      // if (y_line+9 > hpage-50 && (text_data_count+1) <= text_data.length) {
      //   // lindrop+=6;
        
      //   if (lineSpecail === 0) {
      //     lineSpecail =-3;
      //   }else if (lineSpecail === -3){
      //     // lineSpecail =0;
      //   }
        
      // }
      if (y_line+6 > hpage-50 && (text_data_count+1) <= text_data.length) {
        console.log("ส่วนของลูกค้า",index+1 ,lindrop,lineSpecail,setnewLine);
        if (lineSpecail === 0) {
          lineSpecail =-3;
        }else if (lineSpecail === -3){
          // lineSpecail =0;
        }
      }
      doc.line(x_line+2, CutPadding('w', 0, y_line-(lindrop)), x_line+2, CutPadding('w', 0, y_line+6+lineSpecail+setnewLine));//ขวา
      doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-(lindrop)), parseInt( wpage/2), CutPadding('w', 0, y_line+6+lineSpecail+setnewLine)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line-(lindrop)), wpage-12, CutPadding('w', 0, y_line+6+lineSpecail+setnewLine)); //ซ้าย
      Shot("t", x_line+4, CutPadding('w', 0, y_line), text, "l");
      y_line+=6;
      text_data_count++;
    }
    y_line-=3;
    doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
      
  }

  y_line += 9;
  
  if (y_line > hpage-60) {
    Shot("add");
    page++;
    x_line =10;
    y_line =17;
    y_line += 6;
  }
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของบริษัท`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของบริษัท`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`ส่วนของบริษัท`), "l");
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));

  let follower_company_list = data.mom_srs?.me_follow_up.length > 0 ? data.mom_srs?.me_follow_up.length : 0;
  let limit_follower_company = 0;
  y_line+=3;
  Shot('dc',0, 0, 0);
  Shot('fc',r, g, b);
  Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
  doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
  doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
  doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
  doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));

  y_line+=6;
  // doc.rect(20, x_line - 10, 170, 10, "F");
  // doc.setDrawColor(r, g, b);
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
  Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");

  y_line+=3;
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง

  for (let index = 0; index < follower_company_list; index++) {
    if (y_line > hpage-50) {
      // console.log("goooooooooooooo",index+1);
      Shot("add");
      page++;
      x_line =10;
      y_line =17;
      y_line += 6;

      Shot('dc',0, 0, 0);
      Shot('fc',r, g, b);
      Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
      doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
      doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
      doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));
      y_line+=6;
      // doc.rect(20, x_line - 10, 170, 10, "F");
      // doc.setDrawColor(r, g, b);
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
    
      y_line+=3;
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
    }
    y_line+=6;
    let text_data = doc.splitTextToSize(`${index+1}. ${data.mom_srs?.me_follow_up[index]?.sm_comment}`, CutPadding('w', 41, 0),"left");
    let text_data_count= 0;
    let newPage = false;
    for (const text of text_data) {
      let lineSpecail = 0,lindrop=0;
      if (text_data_count==0) {
        lindrop+=6;
      }
      if ((text_data_count+1) === text_data.length) {
        lineSpecail-=3;
      }

      if (y_line > hpage-50) {
        if ((text_data_count+1) <= text_data.length && text_data_count != 0) {
       
          doc.line(x_line+2, CutPadding('w', 0, y_line-3), wpage-12, CutPadding('w', 0, y_line-3)); //ล่าง
        }
        newPage = true;
        Shot("add");
        page++;
        x_line =10;
        y_line =17;
        y_line += 6;
  
        Shot('dc',0, 0, 0);
        Shot('fc',r, g, b);
        Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
        doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
        doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line), parseInt( wpage/2), CutPadding('w', 0, y_line+9)); //กลาง
        doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
        // doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9));
        y_line+=6;
        // doc.rect(20, x_line - 10, 170, 10, "F");
        // doc.setDrawColor(r, g, b);
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", parseInt( wpage/4)-((doc.getStringUnitWidth("รายละเอียด") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`รายละเอียด`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
        Shot("t", wpage-(parseInt( wpage/4))-((doc.getStringUnitWidth("ส่วนของบริษัท") * 16 / doc.internal.scaleFactor)), CutPadding('w', 0, y_line), TexttoString(`สถานะการดำเนินการ`), "l");
      
        y_line+=3;
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
        y_line+=6;
        lindrop =-3;
        lineSpecail = 0;
        // lindrop+=6;
      }
      let newLine =0;
      if (newPage) {
        newPage = false;
        // newLine+=3;
        lindrop+=9;
        if ((text_data_count+1) === text_data.length) {
          // newLine=-6;
        }
        // lineSpecail += 3;
      }
      let setnewLine = 0;
      // console.log("ระยะที่เช็ก",y_line+9,hpage-50,(text_data_count+1),text_data.length,index+1,y_line+9 > hpage-50 , (text_data_count+1) <= text_data.length );
      if (y_line+9 > hpage-50 && (text_data_count+1) <= text_data.length) {
        // lindrop+=6;
        // console.log("ส่วนของบ.1",index+1 ,lindrop,lineSpecail,setnewLine,CutPadding('w', 0, y_line+6+lineSpecail +setnewLine),CutPadding('w', 0, y_line-(lindrop)));
        if (lineSpecail === 0) {
          if ((text_data_count+1) <= text_data.length) {
            lineSpecail -=3;
           
           
          } else {
            setnewLine= 3;
          }
          if (CutPadding('w', 0, y_line+6+lineSpecail +setnewLine) === 262) {
            // console.log("ส่วนของบ. tt");

            setnewLine =3;
          }else if (CutPadding('w', 0, y_line+6+lineSpecail +setnewLine) === 265) {
            // console.log("ส่วนของบ. yy");

            // setnewLine =3;
          }
          // setnewLine =3;
          // console.log("ส่วนของบ.2",index+1 ,lindrop,lineSpecail,setnewLine,CutPadding('w', 0, y_line+6+lineSpecail +setnewLine),CutPadding('w', 0, y_line-(lindrop)));
        }else if (lineSpecail === -3){
          // lineSpecail =0;
        }
        
      }
      doc.line(x_line+2, CutPadding('w', 0, y_line-(lindrop)), x_line+2, CutPadding('w', 0, y_line+6+lineSpecail+setnewLine ));//ขวา
      doc.line(parseInt( wpage/2), CutPadding('w', 0, y_line-(lindrop)), parseInt( wpage/2), CutPadding('w', 0, y_line+6+lineSpecail+setnewLine)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line-(lindrop)), wpage-12, CutPadding('w', 0, y_line+6+lineSpecail+setnewLine)); //ซ้าย
      Shot("t", x_line+4, CutPadding('w', 0, y_line), text, "l");
      y_line+=6;
      text_data_count++;
      // if (y_line+9 > hpage-50) {
      //   // lindrop+=6;
      //   y_line-=3;
      // }
    }
    y_line-=3;
    let newpageLine = 0;
    if (y_line > hpage-60) {
      // console.log("goooooooooooooo",index+1);
      // newpageLine = -3;
    }
    doc.line(x_line+2, CutPadding('w', 0, y_line+newpageLine), wpage-12, CutPadding('w', 0, y_line+newpageLine)); //ล่าง
   
      
  }
  y_line += 9;
  if (y_line > hpage-60) {
    Shot("add");
    page++;
    x_line =10;
    y_line =17;
    y_line += 6;
  } 
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`เอกสารประกอบการประชุม`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`เอกสารประกอบการประชุม`), "l");
  Shot("t", x_line+2, CutPadding('w', 0, y_line), TexttoString(`เอกสารประกอบการประชุม`), "l");
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("เอกสารประกอบการประชุม") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("เอกสารประกอบการประชุม") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));
  doc.line( x_line+2, CutPadding('w', 0, y_line+0.25),(doc.getStringUnitWidth("เอกสารประกอบการประชุม") * 16 / doc.internal.scaleFactor)+12, CutPadding('w', 0, y_line+0.25));

  // let follower_company_list = data.mom_srs?.me_follow_up.length > 0 ? data.mom_srs?.me_follow_up.length : 0;
  // let limit_follower_company = 0;
  y_line+=3;
  Shot('dc',0, 0, 0);
  Shot('fc',r, g, b);
  Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
  doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
  doc.line(wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line),wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line+9)); //กลาง
  doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย

  y_line+=6;
  // doc.rect(20, x_line - 10, 170, 10, "F");
  // doc.setDrawColor(r, g, b);
  Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
  Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
  Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
  let conunt_mindle = wpage- (parseInt( wpage/3)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-7))
  Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
  Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
  Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");


  y_line+=3;
  doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
  // y_line+=6;
let document_list = data.mom_document.length > 0 ? data.mom_document.length : 0;
  for (let index = 0; index < document_list; index++) {
    if (y_line > hpage-60) {
      Shot("add");
      page++;
      x_line =10;
      y_line =17;
      y_line += 6;
      Shot('dc',0, 0, 0);
      Shot('fc',r, g, b);
      Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
      doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
      doc.line(wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line),wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line+9)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย

      y_line+=6;
      // doc.rect(20, x_line - 10, 170, 10, "F");
      // doc.setDrawColor(r, g, b);
      Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
      Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
      Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
      let conunt_mindle = wpage- (parseInt( wpage/3)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-7))
      Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
      Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
      Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");


      y_line+=3;
      doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง

    } 
    y_line+=6;
    doc.link(conunt_mindle, CutPadding('w', 0, y_line), 30, 30, { url: data.mom_document[index].md_link, target: "_blank", });
    Shot('tc',103, 103, 254);
    // doc.setTextColor(103, 103, 254);
    // doc.text( conunt_mindle, CutPadding('w', 0, y_line), "Click Download");
    Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Click Download`), "l");

    Shot('tc',0, 0, 0)
    
    let document_name_text = doc.splitTextToSize( data.mom_document[index].md_name != "" ? data.mom_document[index].md_name : "ไม่ระบุ", CutPadding('w', 63, 0), "left" );
    let text_data_count= 0;
    let newPage = false;
    for (const text of document_name_text) {
      let lineSpecail = 0,lindrop=0;
      if (text_data_count==0) {
        lindrop+=6;
      }
      if ((text_data_count+1)==document_name_text.length) {
        lineSpecail-=3;
      }
      
      if (y_line > hpage-50) {
        if (text_data_count+1 <= document_name_text.length && text_data_count!= 0 ) {
          doc.line(x_line+2, CutPadding('w', 0, y_line-3), wpage-12, CutPadding('w', 0, y_line-3)); //ล่าง
        }
        newPage =true;
        Shot("add");
        page++;
        x_line =10;
        y_line =17;
        y_line += 6;
        Shot('dc',0, 0, 0);
        Shot('fc',r, g, b);
        Shot('r',x_line+2,CutPadding('w', 0, y_line), (wpage-padding)-4,9,'f');
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //บน
        doc.line(x_line+2, CutPadding('w', 0, y_line), x_line+2, CutPadding('w', 0, y_line+9)); //ขวา
        doc.line(wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line),wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line+9)); //กลาง
        doc.line(wpage-12, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line+9)); //ซ้าย
  
        y_line+=6;
        // doc.rect(20, x_line - 10, 170, 10, "F");
        // doc.setDrawColor(r, g, b);
        Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
        Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
        Shot("t", parseInt( wpage/3)-((doc.getStringUnitWidth("ชื่อเอกสาร") * 16 / doc.internal.scaleFactor)-15), CutPadding('w', 0, y_line), TexttoString(`ชื่อเอกสาร`), "l");
        let conunt_mindle = wpage- (parseInt( wpage/3)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-7))
        Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
        Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
        Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
  
  
        y_line+=3;
        doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง
        y_line+=6;
        lindrop =0;
        lineSpecail = 0;
      } 
      if (newPage) {
        newPage = false;
        // newLine+=3;
        lindrop+=6;
        // lineSpecail+=3;
      }
      let setnewLine = 0;
      if (y_line+9 > hpage-50) {
        // lineSpecail -= 3;
        // setnewLine = 3;
      }
      // Shot("t", conunt_mindle, CutPadding('w', 0, y_line), TexttoString(`Link Download`), "l");
      doc.line(x_line+2, CutPadding('w', 0, y_line-(lindrop)), x_line+2, CutPadding('w', 0, y_line+6+lineSpecail));//ขวา
      doc.line(wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line-(lindrop)),wpage- parseInt( wpage/4)-((doc.getStringUnitWidth("Link Download") * 16 / doc.internal.scaleFactor)-10), CutPadding('w', 0, y_line+6+lineSpecail)); //กลาง
      doc.line(wpage-12, CutPadding('w', 0, y_line-(lindrop)), wpage-12, CutPadding('w', 0, y_line+6+lineSpecail)); //ซ้าย
      Shot("t", x_line+4, CutPadding('w', 0, y_line), text, "l");
      y_line+=6;
      text_data_count++;
    }
    y_line-=3;
    doc.line(x_line+2, CutPadding('w', 0, y_line), wpage-12, CutPadding('w', 0, y_line)); //ล่าง

  }

  // console.log(y_line, x_line);
  // if (x_line > 240) {
  //   // console.log("yes");
  //   Shot("add");
  //   page += 1;

  //   x_line = 35;
  //   y_line = 35;

  //   x_line += 10;
  //   y_line += 10;

  //   //sethead();
  //   doc.rect(CutPadding("h", 0, 10), CutPadding("h", 0, 35), 190, 250);
  // }
  // doc.setFontSize(18);
  // Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  // Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  // Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  // x_line += 10;
  // y_line += 10;
  // doc.setFillColor(r, g, b);
  // doc.rect(20, x_line - 10, 170, 10, "F");
  // // doc.setDrawColor(r, g, b);

  // Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  // Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  // Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  // doc.line(20, x_line - 10, 190, x_line - 10); //บน
  // doc.line(190, x_line - 10, 190, CutPadding("h", 0, x_line)); //ขวา
  // doc.line(135, x_line - 10, 135, CutPadding("h", 0, x_line)); //กลาง\
  // doc.line(20, x_line - 10, 20, CutPadding("h", 0, x_line)); //ซ้าย
  // Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  // Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  // Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  // doc.line(20, x_line, 190, x_line); //ล่าง
  // // console.log("mom_document",data.mom_document);
  // let document_list =
  //   data.mom_document.length > 0 ? data.mom_document.length : 0;

  // for (let index = 0; index < document_list; index++) {
  //   if (x_line > 250) {
  //     // console.log("yes");
  //     Shot("add");
  //     page += 1;

  //     x_line = 35;
  //     y_line = 35;

  //     x_line += 10;
  //     y_line += 10;
  //     doc.setFontSize(18);
  //     Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //     Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //     Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //     x_line += 10;
  //     y_line += 10;
  //     doc.setFillColor(r, g, b);
  //     doc.rect(20, x_line - 10, 170, 10, "F");
  //     // doc.setDrawColor(r, g, b);

  //     Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //     Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //     Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //     doc.line(20, x_line - 10, 190, x_line - 10); //บน
  //     doc.line(190, x_line - 10, 190, CutPadding("h", 0, x_line)); //ขวา
  //     doc.line(135, x_line - 10, 135, CutPadding("h", 0, x_line)); //กลาง\
  //     doc.line(20, x_line - 10, 20, CutPadding("h", 0, x_line)); //ซ้าย
  //     Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //     Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //     Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //     doc.line(20, x_line, 190, x_line); //ล่าง
  //     //sethead();
  //     doc.rect(CutPadding("h", 0, 10), CutPadding("h", 0, 35), 190, 250);
  //   }
  //   x_line += 3;
  //   y_line += 3;
  //   // let urlLink = `md_link`
  //   doc.link(149, x_line - 3, 30, 30, {
  //     url: data.mom_document[index].md_link,
  //     target: "_blank",
  //   });
  //   doc.setTextColor(103, 103, 254);
  //   // Shot('t', 150, x_line-3, TexttoString('Click Download'), 'c')
  //   doc.text(149, x_line + 3, "Click Download");
  //   doc.setTextColor(0, 0, 0);

  //   let document_name_text = doc.splitTextToSize(
  //     data.mom_document[index].md_name != ""
  //       ? data.mom_document[index].md_name
  //       : "ไม่ระบุ",
  //     100,
  //     "left"
  //   );
  //   let indexOfList = 0;
  //   let x_left_ = x_line;
  //   for (const text of document_name_text) {
  //     if (x_line > 260) {
  //       // console.log("yes");
  //       Shot("add");
  //       page += 1;

  //       x_line = 35;
  //       y_line = 35;

  //       x_line += 10;
  //       y_line += 10;
  //       doc.setFontSize(18);
  //       Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //       Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //       Shot("t", 13, x_line - 3, TexttoString(`เอกสารประกอบการประชุม`), "l");
  //       x_line += 10;
  //       y_line += 10;
  //       doc.setFillColor(r, g, b);
  //       doc.rect(20, x_line - 10, 170, 10, "F");
  //       // doc.setDrawColor(r, g, b);

  //       Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //       Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //       Shot("t", 62, x_line - 3, TexttoString(`ชื่อเอกสาร`), "l");
  //       doc.line(20, x_line - 10, 190, x_line - 10); //บน
  //       doc.line(190, x_line - 10, 190, CutPadding("h", 0, x_line)); //ขวา
  //       doc.line(135, x_line - 10, 135, CutPadding("h", 0, x_line)); //กลาง\
  //       doc.line(20, x_line - 10, 20, CutPadding("h", 0, x_line)); //ซ้าย
  //       Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //       Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //       Shot("t", 150, x_line - 3, TexttoString(`Link Download`), "l");
  //       doc.line(20, x_line, 190, x_line); //ล่าง
  //       //sethead();
  //       doc.rect(CutPadding("h", 0, 10), CutPadding("h", 0, 35), 190, 250);
  //     }

  //     doc.text(27, x_line + 3, text);

  //     x_line += 7;

  //     doc.line(190, x_line - 10, 190, CutPadding("h", 0, x_line)); //ขวา
  //     doc.line(135, x_line - 10, 135, CutPadding("h", 0, x_line)); //กลาง\
  //     doc.line(20, x_line - 10, 20, CutPadding("h", 0, x_line)); //ซ้าย

  //     if (indexOfList === document_name_text.length - 1) {
  //       doc.line(20, x_line, 190, x_line);
  //     }
  //     indexOfList++;
  //   }
  // }
  // doc.setFontSize(16);
  // console.log("x_line",x_line);
  for (let i = 0; i <= page; i += 1) {
    if (i > 0) {
      doc.setPage(i);
      sethead();
      setfooter(i, page);
      // console.log("page",page);
    }
  }

  if (mode === "version") {
    const dataUri = doc.output("datauristring");
    const binaryData = atob(dataUri.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const file = new File(
      [blob],
      `${moment(new Date()).add("year", 543).format("YY_MM_DD_")}${
        data.project.project_id
      }_MOM_${data.mom_no}.pdf`,
      { type: "application/pdf" }
    );
    // console.log("data.mom_project_id: ",data.mom_project_id);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/momGenerateVersionPdf`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: {
            project_id: data.mom_project_id,
            file_name: file.name,
          
            template_type: "mom",
            mom_id: data.mom_id,

      }

  })
      .then(function (response) {
          if (response.data) {


              CheckAndCreateFolderBox(response.data,file)




         /*      return true */
          }



      })
      .catch(function (error) {
          console.log(error);

      });
    // console.log(file);

    // const dataImg = new FormData();
    // dataImg.append("Profile", file);
    // dataImg.append("typeFile", "mom_document_version");
    // dataImg.append("projectKey", "TTTSPACESHIP");
    // dataImg.append("projectID", data.mom_project_id);
    // dataImg.append("mom_id", data.mom_id);
    // await axios({
    //   method: "post",
    //   url: `${Configs.TTT_Game_Service}/api/upload/momGenerate`,
    //   headers: {
    //     /*  Authorization: getToken(), */
    //     "X-TTT": `${Configs.API_TTT}`,
    //     "Content-Type": "application/octet-stream",
    //   },
    //   data: dataImg,
    // })
    //   .then(async function (response) {
    //     if (response.data.status == true) {
    //       axios({
    //         method: "post",
    //         url: `${Configs.TTT_Game_Service}/api/report/momGenerateVersionPdf`,
    //         headers: {
    //           Authorization: sessionStorage.getItem("access_token"),
    //           "X-TTT": `${Configs.API_TTT}`,
    //           "Content-Type": "application/json",
    //         },
    //         data: {
    //           project_id: data.mom_project_id,
    //           path_file: response.data.data[0].path,
    //           file_name: response.data.data[0].orgin_name,
    //           template_type: "mom",
    //           mom_id: data.mom_id,
    //         },
    //       })
    //         .then(function (response) {
    //           if (response.data) {
    //             return true;
    //           }
    //         })
    //         .catch(function (error) {
    //           console.log(error);
    //         });
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  } else {
    Shot("save");
    // window.open(doc.output('bloburl'));
    console.log("save");
  }
}

export default MOMreport;
