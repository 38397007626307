
import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import moment from "moment";
import Configs from "../../config";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SpaceShipLogo from './Image/spaceshipLogo.png';
import borderImg from './Image/borderImg.png';
import rocket from './Image/rocket.png';
import defaultImg from './Image/defultUser.png';
import defcon1 from './Image/defcon1.png';
import defcon2 from './Image/defcon2.png';
import defcon3 from './Image/defcon3.png';
import defcon4 from './Image/defcon4.png';
import defcon5 from './Image/defcon5.png';

function CardFedSpaceShip({ mode, setmode, fedSpaceShipId }) {

    const [test, setTest] = useState({
        test: ''
    });

    const [fedSpaceShipData, setFedSpaceShipData] = useState({
        fedName: 'TTT BROTHER',
        SpaceShipName: 'RAIZEROS',
        SpaceShipDesc: 'test description',
        SpaceShipImg: null,
        SpaceShipMember: [
            {
                usr_ttt_id: 'TTT115',
                usr_name: 'KASAMA',
                usr_lastname: 'JAMNANGLAP',
                ul_file_path: null,
                percent: '0'
            },
        ],
        onProject: '66L TTT-SPACESHIP',
        projectPercent: 56,
        onStage: 3,
        projectHistory: [
            {
                project_name: 'TTT SPACESHIP SYSTEM',
                pp_start_date: new Date(),
                pp_finish_date: new Date(),
                project_progress: 100,
                pros_name: 'DONE',
                project_budget: 100000
            },
        ],
        onDefcon: 1,
        riskIssue: [
            {
                pri_comment: 'test risk text test risk text test risk text test risk text ',
                pri_type: 'RISK'
            },
        ],
        achievement: [/* 1, 2, 3 */]
    });

    const [projectStage, setProjectStage] = useState([
        {
            name: 'TOR',
        },
        {
            name: 'USER ROLES',
        },
        {
            name: 'SRS',
        },
        {
            name: 'SDD',
        },
        {
            name: 'TEST CASE',
        },
        {
            name: 'UAT ',
        },
        {
            name: 'MA/SLA',
        },
    ]);

    const [projectDefcon, setProjectDefcon] = useState({
        defcon_level: '',
        defcon_name: '',
        defcon_description: '',
        defcon_url: ''
    });

    const [onRiskIssue, setOnRiskIssue] = useState({
        on: 'RISK'
    });

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    async function GetAccesstokenToken() {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function getFedSpaceShipData() {
        await axios({
            method: 'GET',
            url: `${Configs.TTT_Game_Service}/api/federation/getFedSpaceShipData/${fedSpaceShipId.id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(async function (response) {
                if (response.data) {
                    setFedSpaceShipData(response.data);

                    if (response.data.fd_img_id_spaceship) {
                        let response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.fd_img_id_spaceship}/content`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                            },
                            responseType: 'blob',
                        });
            
                        response.data.url = URL.createObjectURL(response2.data);
                        setFedSpaceShipData(response.data);
                        setTest({...test, test: ''});
                        test.test = '';
                    } else if (response.data.fd_img_id_model) {
                        let response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.fd_img_id_model}/content`, {
                            headers: {
                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                            },
                            responseType: 'blob',
                        });
            
                        response.data.url = URL.createObjectURL(response2.data);
                        setFedSpaceShipData(response.data);
                        setTest({...test, test: ''});
                        test.test = '';
                    }

                    if (response.data.projectHistory && response.data.projectHistory.length > 0) {
                        let strDesc = '';
                        let checkDesc = response.data.projectHistory[0].defcon_description.split('\n');
                        if (checkDesc.length > 0) {
                            strDesc = checkDesc[checkDesc.length - 1]
                        };
                        let temp = {
                            defcon_level: response.data.projectHistory[0].defcon_level,
                            defcon_name: response.data.projectHistory[0].defcon_name,
                            defcon_description: strDesc
                        };
                        setProjectDefcon(temp);
                        if (response.data.projectHistory[0].fd_img_id) {
                            let response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.projectHistory[0].fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });
                
                            temp.defcon_url = URL.createObjectURL(response2.data);
                            setProjectDefcon(temp);
                            // response.data.projectHistory[0].url = URL.createObjectURL(response2.data);
                            // setFedSpaceShipData(response.data);
                            setTest({...test, test: ''});
                            test.test = '';
                        }
                    }

                    if (response.data.SpaceShipMember && response.data.SpaceShipMember.length > 0) {
                        for (let item of response.data.SpaceShipMember) {
                            if (item.fd_img_id) {
                                let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });

                                item.url = URL.createObjectURL(response2.data);
                                setFedSpaceShipData(response.data);
                                setTest({...test, test: ''});
                                test.test = '';
                            }
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        GetAccesstokenToken();
        getFedSpaceShipData();
    }, [fedSpaceShipId.id])

    return (
        <>
            <div style={{ position: 'absolute', border: '2px solid #85BFFF', width: '90%', height: 'calc(100% - 325px)', top: '150px', left: '5%', padding: '1% 2%', overflow: 'auto' }}>
                <div className='row' style={{ position: 'relative', margin: 0, padding: '0 15px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ position: 'relative', width: '50px', height: '50px', border: '2px solid #85BFFF', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#85BFFF', fontWeight: 700, fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                        if (mode.old != null) {
                            setmode({ ...mode, mode: mode.old, old: null });
                        } else {
                            setmode({ ...mode, mode: 'all' });
                        }
                    }}>{'<'}</div>
                    <span style={{ marginLeft: '25px' }}>
                        <div style={{ fontSize: '45px', fontWeight: 700, lineHeight: 0.8, textTransform: 'uppercase' }}>{fedSpaceShipData.fedName}</div>
                        <div style={{ fontSize: '14px', fontWeight: 700, lineHeight: 1 }}>FEDERATION</div>
                    </span>
                </div>
                <div style={{ borderTop: '1px solid #85BFFF', margin: '10px 0 0 0', padding: 0, width: '1650px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <div style={{ width: '300px', height: '400px', padding: '10px' }}>
                        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                            <img src={fedSpaceShipData.url != null ? `${/* Configs.TTT_Game_Service_IMG +  */fedSpaceShipData.url}` : defaultImg} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, clipPath: 'polygon(0 0.5%, 20% 0.5%, 30% 8%, 52% 8%, 62% 0.5%, 92.5% 0.5%, 100% 6.5%, 100% 38%, 95% 42.5%, 95% 91%, 85% 99.5%, 9% 99.5%, 0 92%)' }} />
                            <img src={borderImg} style={{ height: '100%', width: '100%', position: 'absolute', top: 0 }} />
                        </div>
                    </div>
                    <div style={{ width: '700px', height: '350px', padding: '10px' }}>
                        <div style={{ color: '#FFF', fontSize: '18px', fontWeight: 700 }}>SPACESHIP NAME</div>
                        <div style={{ position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', padding: '10px', border: '2px solid #0047FF', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', height: '50px', color: '#FFF', fontSize: '16px', fontWeight: 700 }}>
                            {fedSpaceShipData.SpaceShipName || 'NO DATA'}
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                        </div>
                        <div style={{ color: '#FFF', fontSize: '18px', fontWeight: 700, marginTop: '15px' }}>SPACESHIP DESCRIPTION</div>
                        <div style={{ position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', padding: '10px', border: '2px solid #0047FF', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', height: '200px', color: '#FFF', fontSize: '16px', fontWeight: 700 }}>
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div style={{ width: '100%', height: '100%', overflowY: 'auto', zIndex: 1, position: 'relative' }}>
                                {fedSpaceShipData.SpaceShipDesc || ''}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '640px', height: '350px', padding: '10px' }}>
                        <div className='row' style={{ border: '2px solid #0047FF', width: '100%', height: 'calc(100% - 10px)', position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', color: '#FFF', fontSize: '18px', fontWeight: 700, padding: '10px 15px', margin: 0 }}>
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div className='col-6' style={{ padding: 0, margin: 0 }}>
                                <div>MEMBER: {fedSpaceShipData.SpaceShipMember && fedSpaceShipData.SpaceShipMember.length}</div>
                                <div style={{ height: '250px', overflow: 'auto', marginTop: '7.5px' }}>
                                    {fedSpaceShipData.SpaceShipMember && fedSpaceShipData.SpaceShipMember.map((e) => {
                                        return (<>
                                            <div style={{ height: '50px', display: 'flex' }}>
                                                <div style={{ height: '45px', width: '45px', position: 'relative', overflow: 'hidden' }}>
                                                    <img src={e.url != null ? `${e.url}` : defaultImg} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ height: '45px', width: '100%', padding: '0 0 0 5px' }}>
                                                    <div style={{ fontSize: '12px', fontWeight: 200, textTransform: 'uppercase' }}>{e.usr_ttt_id} {e.usr_name} {e.usr_lastname} : {e.percent}%</div>
                                                    <div style={{ borderRadius: '3px', background: 'linear-gradient(to right, #0047FF, #0094FF)', height: '20px', width: `${e.percent}%` }}></div>
                                                </div>
                                            </div>
                                        </>)
                                    })}
                                </div>
                            </div>
                            <div className='col-6' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ fontSize: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', height: '35px' }}>{fedSpaceShipData.onProject}</div>
                                <div style={{ width: '200px', height: '200px' }}>
                                    <CircularProgressbar
                                        value={fedSpaceShipData.projectPercent}
                                        text={`${fedSpaceShipData.projectPercent}%`}
                                        styles={buildStyles({
                                            pathColor: '#0047FF',
                                            textColor: '#0047FF',
                                            trailColor: 'rgba(255,255,255,0.15)',
                                        })}
                                    />
                                </div>
                                <div style={{ width: '100%', height: '50px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <img src={SpaceShipLogo} style={{ height: '100%' }}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '300px', height: '250px', padding: '10px' }}>
                        <div style={{ fontWeight: 700, fontSize: '20px', color: '#FFF' }}>PROJECT HISTORY : {fedSpaceShipData.projectHistory && fedSpaceShipData.projectHistory.length}</div>
                        <div style={{ height: '195px', overflow: 'auto', gap: '5px', display: 'flex', flexDirection: 'column' }}>
                            {fedSpaceShipData.projectHistory && fedSpaceShipData.projectHistory.map((e) => {
                                return (<>
                                    <div style={{ border: '1px solid rgba(255,255,255,0.4)', height: '62.5px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '5px', borderRadius: '2.5px' }}>
                                        <div style={{ width: '50%', fontSize: '8.5px', fontWeight: 700, color: '#FFF', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.project_name}</div>
                                        <div style={{ width: '50%', fontSize: '8.5px', textAlign: 'end' }}>PROGRESS: <span style={{ fontWeight: 700, color: '#FFF' }}>{e.project_progress}%</span> </div>
                                        <div style={{ width: '50%', fontSize: '8.5px' }}>PERIOD START: {moment(e.pp_start_date).format('DD MMM YYYY') || '-'}</div>
                                        <div style={{ width: '50%', fontSize: '8.5px', textAlign: 'end' }}>STATUS: <span style={{ color: `${e.pros_color || '#FFF'}`, fontWeight: 700 }}>{e.pros_name}</span></div>
                                        <div style={{ width: '50%', fontSize: '8.5px' }}>PERIOD END: {moment(e.pp_end_date).format('DD MMM YYYY') || '-'}</div>
                                        <div style={{ width: '50%', fontSize: '8.5px', textAlign: 'end' }}>BUDGET: {numberWithCommas(e.project_budget || 0)} BAHT</div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                    <div style={{ width: '450px', height: '250px', padding: '10px' }}>
                        <div className='row' style={{ border: '2px solid #0047FF', width: '100%', height: 'calc(100%)', position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', color: '#FFF', fontSize: '18px', fontWeight: 700, padding: '5px 10px', margin: 0 }}>
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px', zIndex: 1 }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div className='col-5' style={{ margin: '-5px -10px', position: 'relative', height: 'calc(100% + 10px)', padding: 0 }}>
                                <img src={projectDefcon && projectDefcon.defcon_url ? projectDefcon.defcon_url : defaultImg} style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0 }} />
                                <div style={{ background: 'linear-gradient(to right, transparent, #16285C 95%)', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}></div>
                                <div style={{ padding: '5px', zIndex: 1, position: 'absolute' }}>DEFCON</div>
                            </div>
                            <div className='col-7' style={{ height: '100%', margin: 0, padding: '0 0 0 5px', overflowY: 'auto', position: 'relative', zIndex: 1 }}>
                                <div>DEFCON {projectDefcon && projectDefcon.defcon_level} : {projectDefcon && projectDefcon.defcon_name}</div>
                                <div style={{ fontWeight: 400, fontSize: '16px' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{projectDefcon && projectDefcon.defcon_description}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '450px', height: '250px', padding: '10px' }}>
                        <div className='row' style={{ border: '2px solid #0047FF', width: '100%', height: 'calc(100%)', position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', color: '#FFF', fontSize: '18px', fontWeight: 700, padding: '5px 10px', margin: 0 }}>
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div className='row' style={{ gap: '10px', padding: '0 15px', zIndex: 1, width: '100%', margin: 0, height: '30px' }}>
                                <div style={{ cursor: 'pointer', height: 'fit-content', textDecoration: `${onRiskIssue.on == 'RISK' ? 'underline' : 'none'}`, color: `${onRiskIssue.on == 'RISK' ? '#FFE500' : '#FFF'}` }} onClick={() => {
                                    setOnRiskIssue({ ...onRiskIssue, on: 'RISK' });
                                }}>RISK({fedSpaceShipData.riskIssue ? fedSpaceShipData.riskIssue.filter((e) => { return e.pri_type == 'RISK' }).length : 0})</div>
                                <div style={{ cursor: 'pointer', height: 'fit-content', textDecoration: `${onRiskIssue.on == 'ISSUE' ? 'underline' : 'none'}`, color: `${onRiskIssue.on == 'ISSUE' ? '#FFE500' : '#FFF'}` }} onClick={() => {
                                    setOnRiskIssue({ ...onRiskIssue, on: 'ISSUE' });
                                }}>ISSUE({fedSpaceShipData.riskIssue ? fedSpaceShipData.riskIssue.filter((e) => { return e.pri_type == 'ISSUE' }).length : 0})</div>
                            </div>
                            <div style={{ margin: 0, padding: 0, width: '100%', position: 'relative', height: '165px', display: 'flex', flexDirection: 'column', gap: '10px', overflow: 'auto' }}>
                                {fedSpaceShipData.riskIssue && fedSpaceShipData.riskIssue.filter((e) => { return e.pri_type == onRiskIssue.on }).length > 0 ? fedSpaceShipData.riskIssue.filter((e) => { return e.pri_type == onRiskIssue.on }).map((el) => {
                                    return (<>
                                        <div style={{ background: 'linear-gradient(to right, #0047FF, #002B99)', fontSize: '15px', height: 'fit-content', padding: '5px 10px', borderRadius: '5px' }}>{el.pri_comment}</div>
                                    </>)
                                }) : <>
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>NO DATA</div>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '440px', height: '300px', marginTop: '-50px', padding: '10px' }}>
                        <div className='row' style={{ border: '2px solid #0047FF', width: '100%', height: 'calc(100%)', position: 'relative', background: 'linear-gradient(to right, #051F6C, #16285C, #222E51)', clipPath: 'polygon(15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 15px)', color: '#FFF', fontSize: '18px', fontWeight: 700, padding: '5px 10px', margin: 0 }}>
                            <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', left: '-1px' }}>
                                <line x1="15px" y1="0" x2="0" y2="15px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                                <line x1="100%" y1="calc(100% - 15px)" x2="calc(100% - 15px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                            </svg>
                            <div>ACHIEVEMENT : {fedSpaceShipData.achievement && fedSpaceShipData.achievement.length}</div>
                            <div className='customScrollBar' style={{ width: '100%', height: '225px', overflowX: 'auto', zIndex: 1 }}>
                                <div style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', gap: '15px', height: '100%', width: '100%' }}>
                                    {fedSpaceShipData.achievement && fedSpaceShipData.achievement.length > 0 ? fedSpaceShipData.achievement.map((e) => {
                                        return (<>
                                            <div style={{ border: '1px solid red', width: '150px', height: '100%' }}>

                                            </div>
                                        </>)
                                    }) : <>
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>NO ACHIEVEMENT</div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '900px', height: '50px', margin: '-300px 0 0 300px', padding: '0 10px', display: 'flex', flexDirection: 'row' }}>
                        {projectStage.map((e, i) => {
                            return (<>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', left: 0, width: '30px', height: '45px' }}>
                                    <div style={{ background: `${i + 1 < fedSpaceShipData.onStage ? 'linear-gradient(to right, #1FFF1B, #259B24)' : 'linear-gradient(to right, #0047FF, #0094FF)'}`, width: '30px', height: '30px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>{i + 1}</div>
                                    <div style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{e.name}</div>
                                </div>
                                {i + 1 != 7 && <div style={{ width: '110px', height: '30px', display: 'flex', justifyItems: 'center', fontSize: '22.5px', position: 'relative' }}>- - - - - -
                                    {i + 2 == fedSpaceShipData.onStage && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'absolute', right: 0, width: '40px', height: '45px' }}>
                                        <div style={{ width: '50px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '16px' }}>
                                            <img src={rocket} style={{ height: '100%' }}></img>
                                        </div>
                                    </div>}
                                </div>}
                            </>)
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardFedSpaceShip;
