import React from "react";
import "./iconwabStyle.css";

function Iconwab({ img,path }) {
  return (
    <>
     <a href={path}>
      <div className="iconwabShadowStyle">
        <div className="iconwabOuterStyle_1">
          <div className="iconwabOuterStyle_2">
          <img className="iconwabInnerStyle" src={img} /* alt="google" */ />
          </div>
        </div>
      </div>
      </a>
      
    </>
  );
}

export default Iconwab;
