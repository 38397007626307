import React, { useEffect, useState } from 'react'
import { useParams } from "react-router";
import axios from 'axios';
import {
    LoginOutlined
  } from '@ant-design/icons';
import './style.css';
import Configs from "../../config";




function ConfirmSRS() {
    
    const {id,value} = useParams();
    const [statusConfirm,setStatusConfirm] = useState({
        text1:'',
        text2:'',
        text3:''
    });

    function saveConfirmSRS(){
       
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/project/ConfirmSRS/${id}/${value}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
               'Content-Type': 'multipart/form-data',
            },
            
        }).then((res)=>{

            setStatusConfirm({...statusConfirm,
                color:'#0146F9',
                text1:'การยืนยันสำเร็จ',
                text2:'ขอขอบคุณที่ดำเนินการยืนยัน',
                text3:'เอกสาร Software Requirements Specification (SRS) ของท่านได้ผ่านการยืนยันเรียบร้อยแล้ว'
            });
        }).catch((error)=>{
            
            if(error.response.data.error[0].errorDis == 'คุณได้ส่งผลการยืนยันของเอกสารนี้ไปแล้ว'){
                setStatusConfirm({...statusConfirm,
                    color:'red',
                    text1:'การยืนยันนี้ได้ดำเนินการไปแล้ว',
                    text2:'ขออภัย, การยืนยันของคุณไม่สำเร็จ',
                    text3:'เอกสาร Software Requirements Specification (SRS) ของท่านได้รับการยืนยันก่อนหน้านี้แล้ว'
                });
            }else{
                setStatusConfirm({...statusConfirm,
                    color:'red',
                    text1:'การยืนยันนี้ไม่สำเร็จ',
                    text2:'ขออภัย, การยืนยันของคุณไม่สำเร็จ',
                    text3:'เอกสาร Software Requirements Specification (SRS) ของท่านยืนยันไม่สำเร็จ'
                });
            }

        
       
        })
    }


    useEffect(()=>{
        saveConfirmSRS();
    },[])
    

  return (
    <>
    <div className = 'BgCon'>
        <div className='containerContent'>

            <div className='fontHead' style={{color:statusConfirm.color}}>{statusConfirm.text1}</div>
            <br/>
            <div className='fontHead2'>{statusConfirm.text2}</div>
            <div className='fontHead3'>{statusConfirm.text3}</div>
            <br/> <br/> <br/>
            <div className='btnBackHome'
            onClick={()=>{
                window.location.href = Configs.TTTClient;
                
            }}
            >
            <LoginOutlined rotate={180}  color/> Back to Spaceship
            </div>
        </div>
    </div>

    </>
  )
}

export default ConfirmSRS
