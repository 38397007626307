import Reac, { useState } from "react";
import "./headertitleStyle.css";
import bg from '../../assets/img/bg/Vectortitle.png'
import { Rotate } from "react-reveal";


function Headertitle({ name, fix, imglong, imgbox, imgcorner, no, top    ,left, is_change,height,imglong_per}) {
  /*   if (no == 1){ */
  // console.log('ไปเเล้ว', no, name);
  const [display, setDisplay] = useState({
    modal_1: false,
    modal_2: false,
    modal_3: false,
  })

  if (no === 1) {
    display.modal_1 = true
    display.modal_2 = false
    display.modal_3 = false
   /*  setDisplay({
      ...display,
      modal_1: true,
      modal_2: false,
      modal_3: false,
    }) */
  } else if (no === 2) {
   /*  setDisplay({
      ...display,
      modal_1: false,
      modal_2: true,
      modal_3: false,
    }) */
    display.modal_1 = false
    display.modal_2 = true
    display.modal_3 = false
  } else if (no === 3) {
  /*   setDisplay({
      ...display,
      modal_1: false,
      modal_2: false,
      modal_3: true,
    }) */
    display.modal_1 = false
    display.modal_2 = false
    display.modal_3 = true
  }
  return (

    <>
      {display.modal_1 === true ? (<>
        {/* {console.log('เข้าสเเล้ว', display.modal_1)} */}
        <div>
          <div className="headtext" style={{ fontSize: `${fix - ((fix * 20) / 100)}%` }}>
            {name}
            <div className="img" style={{ width: `${imglong}px` }}>
              <div className="linein" style={{ width: `${imglong + 5}px` }}></div>
              <div className="headtitle2" style={{ width: `${imgbox}px`, clipPath: `polygon(0 0, 100% 0, ${100 - imgcorner}% 80%, ${imgcorner}% 80%)`,height: height ? `${height}px` : ''}}></div>
            </div>
          </div>
        </div></>) : (<></>)}
      {display.modal_2 === true ? (<>
        <div>
          <div className="headtext" style={{ fontSize: `${fix - ((fix * 20) / 100)}%` }}>
            {name}
            <div className="img" style={{ width: imglong ? `${imglong}px` : `${imglong_per}%` }}>
              {/* <div style={{backgroundImage:`url(${bg})`,height:'15px',position:'relative', objectFit: 'contain'}} /> */}
              <div className="linein" style={{ width: imglong ? `${imglong}px` : `${imglong_per}%` }}></div>
             {/*  <div className="headtitle2" style={{ width: `${imgbox}px`, clipPath: `polygon(0 0, 100% 0, ${100 - imgcorner}% 80%, ${imgcorner}% 80%)` }}></div> */}
            </div>
          </div>
        </div></>) : (<></>)}
      {display.modal_3 === true ? (<>
        <div>
          <div className="img" style={{ height:`${imglong}px` }}>
            <div className="linerotate" style={{ height:`${imglong}px`, marginTop: `${top}px`, ...(left !== undefined && { marginLeft: `${left}px`}) }}></div>
            <div className="headtext" style={{ fontSize: `${fix - ((fix * 20) / 100)}%`,marginLeft:'25px',
              ...(is_change === true && {
                marginTop: `${top}px`, 
                height:`${height}px`,
                display: "flex",
                alignItems: "center"
              }) }}
            >
              {name}
            </div>
          </div>
        </div></>) : (<></>)}

    </>
  );
}

export default Headertitle;
