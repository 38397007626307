  import React, { useState, useEffect } from "react";
import "./elementDBA.css";
import { Upload, ConfigProvider, Button, Input, message } from "antd";
import uploadIcon from "../../../assets/img/icon/upload.png";
import Configs from "../../../config";
import styled from "styled-components";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const MyP = styled.p`
  font-family: Oxanium;
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
`;

const { Dragger } = Upload;
const { TextArea } = Input;

function ElementDBA({ isOpen, toggle_modal_dba, projectDetail }) {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [fileName, setFileName] = useState("");
  const [ModuleDba, setModuleDba] = useState({ dataList: [] });
  const [deletedData, setDeletedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (projectDetail && projectDetail.project_id) {
      GetModuleDba(projectDetail.project_id);
    }
  }, [projectDetail]);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setIsBoxVisible(!isBoxVisible);
  };

  async function GetModuleDba(dba_id) {
    try {
      const response = await axios.get(`${Configs.TTT_Game_Service}/api/project/getModuleDba/${dba_id}`, {
        headers: {
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
      });
      const moduleData = response.data;
      console.log("Fetched Module Data:", moduleData);
      moduleData.dataList = moduleData;
      setModuleDba(moduleData);
    } catch (error) {
      console.log(error);
    }
  }

  function saveConfigModuleDBA() {
    console.log("AVE:>>", ModuleDba);
    const temp = { ...ModuleDba };
    let countErr = 0;

    const nameSet = new Set();

    temp.dataList.forEach(table => {
      if (!table.delete) { // Only validate tables that are not marked for deletion
        if (!table.ds_table_name || table.ds_table_name.trim() === '') {
          countErr++;
          table.errorMessage = 'Please Enter Your Table Name !!!';
        } else if (nameSet.has(table.ds_table_name)) {
          countErr++;
          table.errorMessage = 'Table name already exists!';
        } else {
          table.errorMessage = '';
          nameSet.add(table.ds_table_name);
        }
      }
    });

    setModuleDba(temp);

    if (countErr === 0) {
      const finalDataList = temp.dataList.filter(table => !table.delete);
      axios.post(`${Configs.TTT_Game_Service}/api/project/saveConfigModuleDBA`, { ...temp, finalDataList }, {
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        }
      })
      .then(function (response) {
        if (response.data) {
          setErrorMessage('');
          setModuleDba({ dataList: [] });
          setDeletedData([]); // Clear deleted data
          toggle_modal_dba();
          GetModuleDba(projectDetail.project_id);
        }
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage(error.response.data.error[0].errorDis);
      });
    } else {
      setErrorMessage('Please fix the errors in the table names before saving.');
    }
  }

  function addTable() {
    let tempData = ModuleDba.dataList || [];
    let temp = {
      ds_id: uuidv4(),
      ds_table_name: "",
      ds_remark: "",
      ds_project_id: projectDetail.project_id,
      ds_create_date: null,
      ds_update_date: null,
      ds_create_by: null,
      ds_update_by: null,
      ds_is_use: null,
      delete: false,
      errorMessage: '',
    }
    tempData.push(temp);
    setModuleDba({ ...ModuleDba, dataList: tempData });
  }

  function handleDataChange(id, field, value) {
    const newDataList = ModuleDba.dataList.map(data => {
      if (data.ds_id === id) {
        if (field === 'ds_table_name') {
          const isDuplicateName = ModuleDba.dataList.some(data => data.ds_id !== id && data.ds_table_name.trim() !== '' && data.ds_table_name === value.trim());
          if (isDuplicateName) {
            return { ...data, [field]: value, errorMessage: 'Table name already exists!' };
          }
        }
        return { ...data, [field]: value, errorMessage: '' };
      }
      return data;
    });
    setModuleDba({ ...ModuleDba, dataList: newDataList });
  }

  function removeData(id) {
    const newDataList = ModuleDba.dataList.map(data => {
      if (data.ds_id === id) {
        return { ...data, delete: true };
      }
      return data;
    });
    setModuleDba({ ...ModuleDba, dataList: newDataList });
    setDeletedData([...deletedData, id]);
  }
  console.log("DB:>>", isOpen);

  return (
    <>
      <ConfigProvider>
        <div
          className={`backdropModuleElementDBA ${isOpen ? "opened" : "closed"}`}
          onClick={toggle_modal_dba}
        ></div>
        <div
          className={`detailBoxModuleElementDBA ${isOpen ? "opened" : "closed"}`}
          style={{
            left: "50%",
            position: "fixed",
            transform: "translate(-50%, -22%)",
            width: "80%",
            height: "80%",
            top: "25%"
          }}
        >
          <div className="row" style={{ width: "100%", margin: 0, padding: 0 }}>
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
              <h1 style={{ margin: 0, paddingTop: "3px", fontWeight: "600", fontSize: "46px" }}>
                DBA SYNC
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3">
              <h4>Project: {projectDetail.project_name}</h4>
              <h3>TTT Spaceship</h3>
            </div>
            <div
              className="col-12 col-sm-12 col-md-9 col-lg-8 col-xl-7"
              style={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
            >
              <button className="btn-upload-schema" onClick={handleButtonClick}>
                <span style={{ fontSize: "22px", fontFamily: "Oxanium", color: "white" }}>
                  + Upload Schema
                </span>
              </button>
            </div>
          </div>
          {isBoxVisible && (
            <div className="floatingBox" style={{ top: "62px", right: "10px" }}>
              <Dragger className="dragUpload" style={{ border: '1px dashed #0047FF', borderRadius: '0'}}>
                {fileName === "" ? (
                  <>
                    <img style={{ width: "25px", height: "25px" }} src={uploadIcon} alt="uploadIcon" />
                    <span style={{ paddingLeft: "10px", fontFamily: "Oxanium", color: "white" }}>
                      Upload File
                    </span>
                  </>
                ) : (
                  <MyP
                    style={{
                      paddingLeft: "10px",
                      fontFamily: "Oxanium",
                      color: "white",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "247px",
                    }}
                  >
                    {fileName}
                  </MyP>
                )}
              </Dragger>
              <MyP style={{ margin: "5px 0 5px 45%" }}>OR</MyP>
              <Upload>
                <div className="browse-file" style={{ width: '100%', height: '100%', padding: '7px', paddingLeft: '90px', paddingRight: '90px' }}>
                  <span style={{ width: '100%' }}>Browse File</span>
                </div>
              </Upload>
              <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
                <button className='btn-outline-primary mr-2' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }}>Save</button>
                <button className='btn-outline-danger' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={handleButtonClick}>Cancel</button>
              </div>
            </div>
          )}
          <div className="col-12" style={{ height: "5%", padding: 0 }}>
            <hr style={{ margin: 0, borderColor: "#0047FF" }} />
          </div>
          <div className="col-12" style={{ height: "85%", whiteSpace: "nowrap" }}>
            <h5 className="ml-1" style={{ display: "flex", flexFlow: "nowrap", marginTop: "5px", fontWeight: "100", marginBottom: "20px" }}>
              Table in Database
              <a
                className="ImgmenuHover btn-add-table"
                style={{ width: "140px", padding: "5px", marginTop: "-7px", marginLeft: "15px" }}
                onClick={addTable}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "20px",
                    fontWeight: "100",
                    fontFamily: "Oxanium",
                    color: "white",
                    textTransform: "uppercase",
                  }}
                >
                  + Add Table
                </span>
              </a>
            </h5>
            <div className="table-responsive" style={{ height: "83%", whiteSpace: "nowrap", width: "100%" }}>
              <div className="row colorGradient" style={{ display: 'flex', justifyContent: 'space-between', padding: '0px', margin: '0px', height: '40px', width: '100%', textAlign: 'center', borderRadius: '5px', paddingTop: '10px', fontWeight: 'bold' }}>
                <div style={{ flex: '0 0 3%', paddingLeft: '45px' }}>No.</div>
                <div style={{ flex: '0 0 3%' }}>Table Name</div>
                <div style={{ flex: '0 0 19%', paddingLeft: '110px' }}>Remark</div>
                <div style={{ flex: '0 0 20%' }}>Management</div>
              </div>
              {ModuleDba.dataList && ModuleDba.dataList.filter(table => !table.delete).map((table, index) => (
                <div key={table.ds_id}>
                  {table.errorMessage && (
                    <font color="red" style={{ fontSize: '12px', position: 'relative', display: 'block', marginTop: '5px', marginLeft: '18%' }}>
                      {table.errorMessage}
                    </font>
                  )}
                  <div className="row" key={table.ds_id} style={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
                    <div className="col-12 col-sm-12 col-md-2 col-lg-1 col-xl-1 mt-3" style={{ width: '100%', textAlign: 'center', padding: 0 }}>{index + 1}</div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mt-2" style={{ width: '100%', textAlign: 'center' }}>
                      <Input 
                        style={{ fontFamily: "Oxanium", backgroundColor: '#102456', width: '100%', borderRadius: '0', border: '1px solid #0047FF', color: '#FFFFFF' }} 
                        type='text' 
                        value={table.ds_table_name} 
                        onChange={(e) => { handleDataChange(table.ds_id, 'ds_table_name', e.target.value); }} 
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 mt-2" style={{ width: '20%', textAlign: 'center' }}>
                      <TextArea
                        style={{ 
                          fontFamily: "Oxanium",
                          backgroundColor: '#102456', 
                          width: '100%', 
                          borderRadius: '0', 
                          border: '1px solid #0047FF', 
                          color: '#FFFFFF',                      
                        }}
                        autoSize={{ minRows: 1 }}
                        type='text'
                        value={table.ds_remark} 
                        onChange={(e) => { handleDataChange(table.ds_id, 'ds_remark', e.target.value); }}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 mt-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '5%', textAlign: 'center', paddingTop: '7px', paddingLeft: '140px' }}>
                      <button 
                        style={{ color: 'white', backgroundColor: '#910000', width: '29px', height: '29px', border: '1px solid red', cursor: 'pointer' }} 
                        onClick={() => removeData(table.ds_id)}
                      >
                        <i className='fa fa-trash' style={{ marginTop: '5px' }}></i>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ position: 'fixed', bottom: '5px', right: '5px' }}>
                <button className='btn-outline-primary mr-2' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px', cursor: 'pointer' }} onClick={saveConfigModuleDBA}>Save</button>
                <button className='btn-outline-danger' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px', cursor: 'pointer' }} onClick={toggle_modal_dba}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
}

export default ElementDBA;
