import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/adminchat.css';
import TestBar from '../../components/stickybar/stickybar'

function AdminChat() {
  const [isShown, setIsShown] = useState(false);

  const handleClick = event => {
    // 👇️ toggle visibility
    setIsShown(current => !current);
  };


  return (
	 <>

      {/* <button onClick={handleClick}>{showComponent ? '' : 'Show Component'}</button> */}
  
     <button className="chat-nav" onClick={handleClick} style = {{width: "62px",height: "63px", textAlign:"center" }}> 
     <i class='far fa-comment-dots' style = {{ marginTop:"15%", fontSize:"30px", color:"white"}}></i>
     </button>

     <div style={{display: isShown ? 'block' : 'none'}}>
          <div className="chat-bar" style = {{width: "300px",height: "300px"}}>
          <div className='output-chat' style = {{width: "98%", height:"78%", backgroundColor:"white",marginLeft:"1%", marginTop:"10%", borderRadius:"5%"}}></div>
            <input className='input-chat' style = {{width: "80%", height:"10%", marginTop:"1%", marginLeft:"1%", }}></input>
            <button className='btn-send-admin' style={{position:"fixed"}}>send</button>
          </div>
      </div>
     </>
  )
}

export default AdminChat