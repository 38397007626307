import React from "react";
import styled from "styled-components";

function CardHalfCircle({ percent }) {
  // console.log('percent', percent)
  const Gauge = styled.div`
  width: 195px;
  max-width: 250px;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #004033;
  `;

  const GaugeBody = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: rgba(255, 255, 255, 0.15);
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
  `;

  let total = percent / 200 ;
  //  console.log('total1', total)


  const GaugeFill = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: 100%;
    background: linear-gradient(136.36deg, #0047FF 22.6%, #002586 71.47%);
    transform-origin: center top;
    transform: rotate(${total}turn);
    transition: transform 0.2s ease-out;
  `;

  const GaugeCover = styled.div`
  width: 75%;
  height: 150%;
  background: rgb(18 18 21);
  border-radius: 50%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);

  /* Text */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25%;
  box-sizing: border-box;
  
  `;

  

  // if (percent <= 25) {
  //   color = "red";
  // } else if (percent <= 50) {
  //   color = "orange";
  // } else if (percent <= 75) {
  //   color = "yellow";
  // } else if (percent <= 100) {
  //   color = "green";
  // }

  return (
    <Gauge>
      <GaugeBody>
          <GaugeFill/>
          <GaugeCover/>
      </GaugeBody>

    </Gauge>
  );
}

export default CardHalfCircle;
