import React, { useState } from "react";
import "./ShopStyle.css";
import HeaderTTT from "../../components/header/HeaderTTT";
import bgLeaderBoard from '../../assets/img/bg/bg_leaderboard.jpg';
import store from '../../assets/img/bg/StoreGif.gif';
import Inhalant from './imgProduct/IMG_4220-removebg-preview 1.png';
import mousepad1 from './imgProduct/mousepad-1.png';
import mousepad2 from './imgProduct/mousepad-2.png';
import mousepad3 from './imgProduct/mousepad-3.png';
import mousepad4 from './imgProduct/mousepad-4.png';
import mousepad5 from './imgProduct/mousepad-5.png';
import mousepad6 from './imgProduct/mousepad-6.png';
import mousepad7 from './imgProduct/mousepad-7.png';
import mousepad8 from './imgProduct/mousepad-8.png';
import StickerSoftwareDeveloper1 from './imgProduct/Raizeros.png';
import TTTJacket from './imgProduct/TTT-Jacket.png';
import TTTTshirt from './imgProduct/TTT-Tshirt.png';
import QrCode from './imgProduct/QR.png';

const ShopTTT = () => {
  const [currentImg, setCurrentImg] = useState({
    product1: mousepad1,
    product2: mousepad2,
    product3: mousepad3,
    product4: mousepad4,
    product5: mousepad5,
    product6: mousepad6,
    product7: mousepad7,
    product8: mousepad8,
  })

  const [currentDetail, setCurrentDetail] = useState({
    product1: 'TS003-001 - แผ่นรองเม้าส์ TTT',
    product2: 'TS003-002 - แผ่นรองเม้าส์ TTT',
    product3: 'TS003-003 - แผ่นรองเม้าส์ TTT',
    product4: 'TS003-004 - แผ่นรองเม้าส์ TTT',
    product5: 'TS003-005 - แผ่นรองเม้าส์ TTT',
    product6: 'TS003-006 - แผ่นรองเม้าส์ TTT',
    product7: 'TS003-007 - แผ่นรองเม้าส์ TTT',
    product8: 'TS003-008 - แผ่นรองเม้าส์ TTT',
  })

  return (
    <>
      <HeaderTTT />
      <div className="storeBgSet" style= {{background:`url(${bgLeaderBoard})`,backgroundSize:'cover',position:'fixed',marginTop:'1%'}}>
        <div className='storeBgSetIn'>
          <img className='storeBg' src={store} alt="" />

          <div className="storeProductLeft1">
            <img className='storeProductLeft1Img' src={TTTJacket} alt="" />
            <div className='storeProductDetail1'>
              TS001 - TTT Spaceship Jacket
              <div className='storeProductDetailText'>{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}</div>
            </div>
            <div className='storePrice1' style={{ top: 0, left: '65%' }}>
              <div className="storePrice1Dot"></div>
              <svg height="200" width="60" style={{ position: 'absolute' }}>
                <line x1="5" y1="45" x2="20" y2="20" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
                <line x1="20" y1="20" x2="45" y2="20" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
              </svg>
              <div className="storePrice1Box">
                <div className="storePrice1BoxIn">
                  3,500 BAHT
                </div>
              </div>
            </div>
          </div>

          <div className="storeProductLeft2">
            <img className='storeProductLeft1Img' src={TTTTshirt} alt="" />
            <div className='storeProductDetail2'>
              TS002 - Polo Shirt
              <div className='storeProductDetailText'>{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}</div>
            </div>
            <div className='storePrice1' style={{ top: 0, left: '65%' }}>
              <div className="storePrice1Dot"></div>
              <svg height="200" width="60" style={{ position: 'absolute' }}>
                <line x1="5" y1="45" x2="20" y2="20" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
                <line x1="20" y1="20" x2="45" y2="20" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
              </svg>
              <div className="storePrice1Box">
                <div className="storePrice1BoxIn">
                  1,200 BAHT
                </div>
              </div>
            </div>
          </div>

          <div className="storeProductCenter1">
            <img className='storeProductCenter1Hover' src={currentImg.product1} alt="currentImg" id="product1" />
            <div className='storeProductDetail3'>
              {currentDetail.product1}
              <div className='storeProductDetailText'>{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}</div>
            </div>
            <div className='storePrice1' style={{ top: '-65%', left: '-25%', height: '0px', width: '0px' }}>
              <div className="storePrice1Dot" style={{ top: '100px', left: '90px' }}></div>
              <svg height="70" width="3" style={{ position: 'absolute', top: '30px', left: '95px' }}>
                <line x1="1" y1="1" x2="1" y2="70" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
              </svg>
              <div className="storePrice1Box" style={{ left: 0 }}>
                <div className="storePrice1BoxIn">
                  800 BAHT
                </div>
              </div>
            </div>
          </div>

          <img className='storeProductCenter2' src={currentImg.product2} alt="" id="product2" onClick={() => {
            setCurrentImg({
              product1: currentImg.product2,
              product2: currentImg.product1,
              product3: currentImg.product3,
              product4: currentImg.product4,
              product5: currentImg.product5,
              product6: currentImg.product6,
              product7: currentImg.product7,
              product8: currentImg.product8,
            })

            setCurrentDetail({
              product1: currentDetail.product2,
              product2: currentDetail.product1,
              product3: currentDetail.product3,
              product4: currentDetail.product4,
              product5: currentDetail.product5,
              product6: currentDetail.product6,
              product7: currentDetail.product7,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter3' src={currentImg.product3} alt="" id="product3" onClick={() => {
            setCurrentImg({
              product1: currentImg.product3,
              product2: currentImg.product2,
              product3: currentImg.product1,
              product4: currentImg.product4,
              product5: currentImg.product5,
              product6: currentImg.product6,
              product7: currentImg.product7,
              product8: currentImg.product8,
            })
            setCurrentDetail({
              product1: currentDetail.product3,
              product2: currentDetail.product2,
              product3: currentDetail.product1,
              product4: currentDetail.product4,
              product5: currentDetail.product5,
              product6: currentDetail.product6,
              product7: currentDetail.product7,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter4' src={currentImg.product4} alt="" id="product4" onClick={() => {
            setCurrentImg({
              product1: currentImg.product4,
              product2: currentImg.product2,
              product3: currentImg.product3,
              product4: currentImg.product1,
              product5: currentImg.product5,
              product6: currentImg.product6,
              product7: currentImg.product7,
              product8: currentImg.product8,
            })
            setCurrentDetail({
              product1: currentDetail.product4,
              product2: currentDetail.product2,
              product3: currentDetail.product3,
              product4: currentDetail.product1,
              product5: currentDetail.product5,
              product6: currentDetail.product6,
              product7: currentDetail.product7,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter5' src={currentImg.product5} alt="" id="product5" onClick={() => {
            setCurrentImg({
              product1: currentImg.product5,
              product2: currentImg.product2,
              product3: currentImg.product3,
              product4: currentImg.product4,
              product5: currentImg.product1,
              product6: currentImg.product6,
              product7: currentImg.product7,
              product8: currentImg.product8,
            })
            setCurrentDetail({
              product1: currentDetail.product5,
              product2: currentDetail.product2,
              product3: currentDetail.product3,
              product4: currentDetail.product4,
              product5: currentDetail.product1,
              product6: currentDetail.product6,
              product7: currentDetail.product7,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter6' src={currentImg.product6} alt="" id="product6" onClick={() => {
            setCurrentImg({
              product1: currentImg.product6,
              product2: currentImg.product2,
              product3: currentImg.product3,
              product4: currentImg.product4,
              product5: currentImg.product5,
              product6: currentImg.product1,
              product7: currentImg.product7,
              product8: currentImg.product8,
            })
            setCurrentDetail({
              product1: currentDetail.product6,
              product2: currentDetail.product2,
              product3: currentDetail.product3,
              product4: currentDetail.product4,
              product5: currentDetail.product5,
              product6: currentDetail.product1,
              product7: currentDetail.product7,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter7' src={currentImg.product7} alt="" id="product7" onClick={() => {
            setCurrentImg({
              product1: currentImg.product7,
              product2: currentImg.product2,
              product3: currentImg.product3,
              product4: currentImg.product4,
              product5: currentImg.product5,
              product6: currentImg.product6,
              product7: currentImg.product1,
              product8: currentImg.product8,
            })
            setCurrentDetail({
              product1: currentDetail.product7,
              product2: currentDetail.product2,
              product3: currentDetail.product3,
              product4: currentDetail.product4,
              product5: currentDetail.product5,
              product6: currentDetail.product6,
              product7: currentDetail.product1,
              product8: currentDetail.product8,
            })
          }} />
          <img className='storeProductCenter8' src={currentImg.product8} alt="" id="product8" onClick={() => {
            setCurrentImg({
              product1: currentImg.product8,
              product2: currentImg.product2,
              product3: currentImg.product3,
              product4: currentImg.product4,
              product5: currentImg.product5,
              product6: currentImg.product6,
              product7: currentImg.product7,
              product8: currentImg.product1,
            })
            setCurrentDetail({
              product1: currentDetail.product8,
              product2: currentDetail.product2,
              product3: currentDetail.product3,
              product4: currentDetail.product4,
              product5: currentDetail.product5,
              product6: currentDetail.product6,
              product7: currentDetail.product7,
              product8: currentDetail.product1,
            })
          }} />

          <div className="storeProductRight1">
            <img src={StickerSoftwareDeveloper1} alt="" style={{height: '75px'}}/>
            <div className='storeProductDetail4'>
              TS004 - ป้ายชื่อยาน
              <div className='storeProductDetailText'>{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}</div>
            </div>
            <div className='storePrice1' style={{ top: '-130%', left: '10%', height: '0px', width: '0px' }}>
              <div className="storePrice1Dot" style={{ top: '100px', left: '90px' }}></div>
              <svg height="70" width="3" style={{ position: 'absolute', top: '30px', left: '95px' }}>
                <line x1="1" y1="1" x2="1" y2="70" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
              </svg>
              <div className="storePrice1Box" style={{ left: 0 }}>
                <div className="storePrice1BoxIn">
                  220 BAHT
                </div>
              </div>
            </div>
          </div>

          <div className="storeProductRight2">
            <img src={Inhalant} alt="" style={{height: '150px'}}/>
            <div className='storeProductDetail5'>
              TS004 - ยาดม
              <div className='storeProductDetailText'>{/* Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type
              specimen book. It has survived not only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
              release of Letraset sheets containing Lorem Ipsum passages, and more recently with
              desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}</div>
            </div>
            <div className='storePrice1' style={{ top: '-65%', left: '-25%', height: '0px', width: '0px' }}>
              <div className="storePrice1Dot" style={{ top: '100px', left: '90px' }}></div>
              <svg height="70" width="3" style={{ position: 'absolute', top: '30px', left: '95px' }}>
                <line x1="1" y1="1" x2="1" y2="70" style={{ stroke: '#1FFF1B', strokeWidth: '2' }} />
              </svg>
              <div className="storePrice1Box" style={{ left: 0 }}>
                <div className="storePrice1BoxIn">
                  70 BAHT
                </div>
              </div>
            </div>
          </div>

          <div className='storeLight' style={{ top: '61.5%', left: '6.5%', width: '200px', height: '75px',clipPath: 'polygon(0 0, 100% 0, 70% 90%, 30% 100%)' }} />
          <div className='storeLight' style={{ top: '59.5%', left: '18.5%', width: '200px', height: '75px',clipPath: 'polygon(0 0, 100% 0, 70% 90%, 30% 100%)' }} />
          <div className='storeLight' style={{ top: '64%', left: '44.25%', width: '300px', height: '75px',clipPath: 'polygon(0 0, 100% 0, 70% 100%, 30% 100%)', zIndex: 8 }} />
          <div className='storeLight' style={{ top: '60%', right: '5%', width: '200px', height: '75px',clipPath: 'polygon(0 0, 100% 0, 70% 100%, 30% 90%)' }} />
          <div className='storeLight' style={{ top: '57.75%', right: '18.5%', width: '200px', height: '75px',clipPath: 'polygon(0 0, 100% 0, 70% 100%, 30% 90%)' }} />

          <div className='storeQrCode' style={{backgroundImage: `url(${QrCode})`}}></div>

        </div>
      </div>

    </>
  );
};

export default ShopTTT;
