import react from "react";
import HeaderTTT from "../components/header/HeaderTTT";
import "../assets/css/guide.css";
import FeatureAreaTransform from "../components/index/indexTTT/FeatureAreaTransform";
import FeatureAreaTimely from "../components/index/indexTTT/FeatureAreaTimely";
import FeatureAreaTrailblazer from "../components/index/indexTTT/FeatureAreaTrailblazer";
import Achievement from "../components/index/indexTTT/Achievement";
import Footer from '../components/footer/Footer';

function Guide() {
  return (
    <>
      <HeaderTTT />
      <img className="guide-bg" src="../assets/img/bg/area_bg02.jpg"></img>
      <div className="container">
        <div className="row justify-content-center">
        <h1 className="mt-5">Hard Story</h1>
        </div>
        <div className="mb-5">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus,
          voluptate accusantium necessitatibus aliquam harum molestiae nam eum
          ipsum fuga nihil, iste earum. Exercitationem nisi quisquam quia
          deleniti dignissimos maxime vitae. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Incidunt, similique non explicabo
          aspernatur recusandae impedit. Perspiciatis esse, corrupti, illum aut
          repellat iste ipsam, cupiditate corporis ipsa similique officiis
          soluta totam. Lorem ipsum dolor sit, amet consectetur adipisicing
          elit. Exercitationem maiores iste earum dolorem quidem quas asperiores
          molestias quae, consequuntur nesciunt perferendis assumenda eius
          consectetur esse odio libero a illum sunt. Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Dolores ab modi enim sequi deserunt et
          id doloremque provident exercitationem vel blanditiis fuga excepturi,
          facere obcaecati dignissimos tempora odio quo quos! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Vel accusamus eos illum beatae!
          Voluptatibus reprehenderit non facilis eaque tenetur suscipit
          consectetur molestiae quam, fugiat deleniti autem cupiditate
          recusandae? Sunt, ipsa!Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus,
          voluptate accusantium necessitatibus aliquam harum molestiae nam eum
          ipsum fuga nihil, iste earum. Exercitationem nisi quisquam quia
          deleniti dignissimos maxime vitae. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Incidunt, similique non explicabo
          aspernatur recusandae impedit. Perspiciatis esse, corrupti, illum aut
          repellat iste ipsam, cupiditate corporis ipsa similique officiis
          soluta totam. Lorem ipsum dolor sit, amet consectetur adipisicing
          elit. Exercitationem maiores iste earum dolorem quidem quas asperiores
          molestias quae, consequuntur nesciunt perferendis assumenda eius
          consectetur esse odio libero a illum sunt. Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Dolores ab modi enim sequi deserunt et
          id doloremque provident exercitationem vel blanditiis fuga excepturi,
          facere obcaecati dignissimos tempora odio quo quos! Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Vel accusamus eos illum beatae!
          Voluptatibus reprehenderit non facilis eaque tenetur suscipit
          consectetur molestiae quam, fugiat deleniti autem cupiditate
          recusandae? Sunt, ipsa!
        </div>
      </div>
      <FeatureAreaTransform />
      <FeatureAreaTimely />
      <FeatureAreaTrailblazer />
      <Achievement />

      {/* live-match-area */}
      <section className="live-match-area pt-50 pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="section-title home-four-title text-center mb-60">
                <h2>
                  How to <span>play</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9">
              <div className="live-match-wrap">
                <img src="assets/img/images/live_match_img.jpg" alt="" />
                <a
                  href="https://www.youtube.com/watch?v=04KPiGmC7Lc"
                  className="popup-video"
                >
                  <img src="assets/img/icon/video_play_icon.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* live-match-area-end */}

      <Footer/>
    </>
  );
}

export default Guide;
