
import React, { useEffect, useState } from 'react';
import { DatePicker, Tabs, Tooltip } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import axios from "axios";
import Configs from "../../../../config";
import { Input } from 'antd';
import dayjs, { isDayjs } from 'dayjs';
import styled from 'styled-components';
import './tabMOM.css'
import { isArray, isFunction, isPlainObject, times } from 'lodash';
import AlertConfrimDelete from '../../../../components/AlertMassge/AlertConfrimDelete';
import momreport from '../../Report/momreport'
import Swal from 'sweetalert2';
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

function TabCompanentMOM({ callbackMomSpecificField, momSpecificField, srsSpecificField, userismode, project_id, getMOM, callbackMomDelSpecificField }) {
  // console.log("MOM:>>", userismode);

  const [specificField, setSpecificField] = useState([]);
  const [successMassge, setSuccessMassge] = useState(false);
  const toggle_modal_successMassge = () => setSuccessMassge(!successMassge);
  const [messageBox, setMessageBox] = useState({
    message: [],
    isError: false,
    menuTitle: ''
  })
 
  const [alertKick, setAlertKick] = useState(false);
  // const [alertKickall, setAlertKickall] = useState({ data: [] });
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const [alertKickNot, setAlertKickNot] = useState(false);
  const toggle_modal_alertKickNot = () => setAlertKickNot(!alertKickNot);

  const [alertKickSuccess, setAlertKickSuccess] = useState(false);
  const toggle_modal_alertKickSuccess = () => setAlertKickSuccess(!alertKickSuccess);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  function parseEmployees(input) {
    // แยกสตริงโดยใช้คอมม่าเป็นตัวแบ่ง
    let employees = input.split(',');

    // แปลงอาร์เรย์ที่ได้เป็นอาร์เรย์ของออบเจกต์
    let result = employees.map(employee => {
      // แยกชื่อและตำแหน่งโดยใช้ขีดเป็นตัวแบ่ง
      let [name, position] = employee.split('-');
      return {
        name: name.trim(),
        position: position ? position.trim() : null
      };
    });

    return result;
  }

  function parseLinks(input) {
    // ใช้ Regular Expression เพื่อจับคู่สตริงที่อยู่ใน <<link>>...<<link>>
    let regex = /<<link>>(.*?)<<link>>/g;
    let matches = input.match(regex);

    // ถ้าไม่มีการจับคู่ใดๆ ให้คืนค่าเป็นอาเรย์ว่าง
    if (!matches) return [];

    // แปลง matches ให้เป็นอาเรย์ของออบเจกต์
    let result = matches.map(match => {
      // ตัด <<link>> ออก
      match = match.replace(/<<link>>/g, '');

      // แยก name และ path โดยใช้คอมม่า
      let [name, path] = match.split(',');

      return {
        name: name.trim(),
        link: path.trim()
      };
    });

    return result;
  }

  const getDataForMom = async (srs_value) => {
    try {
      const temp_mom_data = [];
      const id = "738fc3d7-b0ee-4bf3-b9fa-b0f3958f77f8"; //id srs document specifig
      const res_doc = await axios({
        method: "GET",
        url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
      })
      const document_specifig = res_doc.data;
      if (document_specifig) {
        for (let item of document_specifig) {
          for (let item2 of item.dt_input_data.data) {
            for (let item3 of item2.value_list) {
              for (let item_ of srs_value) {
                for (let item2_ of item_.dt_input_data.data) {
                  for (let item3_ of item2_.value_list) {
                    if (item3.value_id == item3_.value_id) {
                      if (item3_.value_input) {
                        item3.value_input = item3_.value_input || '';
                      }
                      if (item3_.data_list) {
                        item3.data_list = item3_.data_list || [];
                      }
                      if (item3_.value_input_header) {
                        item3.value_input_header = item3_.value_input_header || '';
                      }

                    }
                  }
                }
              }
            }
          }
        }
        for (let item of document_specifig) {
          for (let item2 of item.dt_input_data.data) {
            for (let item3 of item2.value_list) {
              if (item3.value_type == 'table_column') {
                let temp_in = {
                  value_input_table: [],
                }
                for (let item of item3.table_column_list) {
                  temp_in.value_input_table.push('');
                }
                if (!item3.data_list) {
                  item3.data_list = [];
                }
                if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                  item3.data_list = [];
                }
                if (item3.data_list.length == 0 && item3.is_multiple == false) {
                  item3.data_list.push(temp_in);
                }

              }

              if (item3.value_id == "7f6d4901-319b-4aed-af5b-f0cbea20a942" || item3.value_id == "8ca1ace7-b475-4c19-9cd4-94453d39b3f7") { // ครั้งที่เก็บความต้องการ

                item3.data_list.forEach((item3Value, _index5) => {
                  const objEmployees = item3Value.value_input_table[2] && item3Value.value_input_table[2] !== "" ? parseEmployees(item3Value.value_input_table[2]) : null;
                  const objParseLink = item3Value.value_input_table[5] && item3Value.value_input_table[5] !== "" ? parseLinks(item3Value.value_input_table[5]) : null;
                  item3Value.value_participants = objEmployees ? objEmployees : [];
                  item3Value.value_link = objParseLink ? objParseLink : [];

                  // console.log('item3Value :>> ', item3Value);

                  const date = (item3Value.value_input_table[4] && item3Value.value_input_table[4] != "") ?
                    item3Value.value_input_table[4].split(' - ').map(date => dayjs(date, "DD/MM/YYYY HH:mm:ss")) :
                    [null, null];
                  const model = {
                    index: item3Value.value_input_table[0],
                    tag: "srs",
                    mom_id: null,
                    mom_no: item3Value.value_input_table[0],
                    mom_location: item3Value.value_input_table[3],
                    mom_header: item3Value.value_input_table[1],
                    mom_start_date: isDayjs(date?.[0]) ? date[0].$d : undefined,
                    mom_end_date: isDayjs(date?.[1]) ? date[1].$d : undefined,
                    mom_project_id: project_id,
                    mom_created_date: new Date(),
                    mom_link: item3Value.value_link,
                    mom_participants: item3Value.value_participants,
                    mom_group: []
                  }
                  temp_mom_data.push(model);
                });
              }
            }
          }
        }
      }

      const _momSpecificField = await getMOM();
      const mom_not_null = _momSpecificField.filter(e => e.tag == "mom" && e.mom_start_date && e.mom_id);
      const mom = _momSpecificField.filter(e => e.tag == "mom");

      // console.log('mom_not_null :>> ', mom_not_null);
      // console.log('mom :>> ', mom);
      mom_not_null.forEach(e => temp_mom_data.push(e));
      temp_mom_data.sort((a, b) => {
        return new Date(b.mom_start_date) - new Date(a.mom_start_date);
      });

      // mom.sort((a, b) => {
      //   return (a.index) - (b.index);
      // });
      // mom.forEach(e => temp_mom_data.unshift(e));


      temp_mom_data.sort((a, b) => {
        return new Date(b.mom_start_date) - new Date(a.mom_start_date);
      });
      // console.log('temp_mom_data :>> ', temp_mom_data);
      // setSpecificField(temp_mom_data)
      genNumberMOMNumber(temp_mom_data)
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  useEffect(() => {
    getDataForMom(srsSpecificField);
  }, [srsSpecificField]);

  const [linkDoc, setLinkDoc] = useState([
    {
      name: '',
      link: ''
    }
  ]);

  const addLinkDoc = (index) => {
    // setLinkDoc([...linkDoc, { name: '', link: '' }]);
    let _specificField = specificField;

    if (!isArray(_specificField?.[index]?.mom_link)) {
      _specificField[index].mom_link = [];
    }

    const model = {
      name: "",
      link: "",
    };
    _specificField?.[index].mom_link.push(model);

    setSpecificField(_specificField);
    callbackMomSpecificField(_specificField);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  };


  const handleLinkInputChange = (key, value, index, index5) => {
    // const newLinkDoc = [...linkDoc];
    // newLinkDoc[index][field] = value;
    // setLinkDoc(newLinkDoc);
    let _specificField = specificField;
    _specificField[index].mom_link[index5][key] = value;

    setSpecificField(_specificField);
    callbackMomSpecificField(_specificField);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  };

  const removeLinkDoc = (index, index5) => {
    // const newLinkDoc = linkDoc.filter((_, i) => i !== index);
    // setLinkDoc(newLinkDoc);
    let _specificField = specificField;

    if (_specificField[index].mom_link[index5]?.md_id) {
      _specificField[index].mom_link_del = isArray(_specificField[index].mom_link_del) ? _specificField[index].mom_link_del : [];
      _specificField[index].mom_link_del.push(_specificField[index].mom_link[index5])
    }

    _specificField[index].mom_link.splice(index5, 1);

    setSpecificField(_specificField);
    callbackMomSpecificField(_specificField);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';

  };

  const [textChange, setTextChange] = useState({ text: '' });

  function addValueMultiple() {
    let temp = specificField || [];
    let tempSort = specificField.filter(w => w.tag == "mom") || [];

    tempSort.sort((a, b) => {
      return Number(b.index) - Number(a.index);
    });
    let user = sessionStorage.getItem('user');
    const index = Number(tempSort[0]?.index ?? 0) + 1
    let newTemp = {
      mom_id: null,
      mom_no: index,
      mom_location: null,
      mom_header: null,
      mom_start_date: new Date(),
      mom_end_date: addHours(new Date(), 2),
      mom_project_id: null,
      mom_record_by: user,
      tag: "mom",
      index,
      mom_link: [],
      mom_participants: [],
      mom_group: [],
    }

    temp.unshift(newTemp);

    setSpecificField(temp);
    callbackMomSpecificField(temp);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  function removeValueMultiple(index) {
    let temp = specificField || [];
    if (index > -1) {
      if (temp[index]?.mom_id) {
        callbackMomDelSpecificField(temp[index]);
      }
      temp.splice(index, 1);
      genNumberMOMNumber(temp)
    }
  }

  const genNumberMOMNumber = (data) => {
    // const mom = data.filter(w => w.tag == "mom");
    // const srs = data.filter(w => w.tag == "srs");

    // if (mom.length == 1) {
    //   mom.sort((a, b) => {
    //     return Number(b.index) - Number(a.index);
    //   });
    //   const index = Number(mom[0]?.index ?? 0) + 1
    //   mom[0].index = index;
    //   mom[0].mom_no = index;
    // } else if (mom.length > 1) {
    //   let tempSort = mom || [];
    //   tempSort.sort((a, b) => {
    //     return Number(b.index) - Number(a.index);
    //   });
    //   let index = Number(tempSort[0]?.index ?? 0) + 1
    //   mom.sort((a, b) => {
    //     return Number(a.index) - Number(b.index);
    //   });
    //   mom.forEach(e => {
    //     e.index = index;
    //     e.mom_no = index;
    //     index = index + 1;
    //   })
    //   mom.sort((a, b) => {
    //     return Number(b.index) - Number(a.index);
    //   });
    // }

    // const data = [...mom, ...srs]
    // arr.sort((a, b) => {
    //   return new Date(b.mom_start_date) - new Date(a.mom_start_date);
    // });

    // setSpecificField(arr);
    // callbackMomSpecificField(arr);
    // setTextChange({ ...textChange, text: '' });
    // textChange.text = '';



    /*  */
    data.sort((a, b) => {
      return new Date(a.mom_start_date) - new Date(b.mom_start_date);
    });

    let index = 1;
    data.forEach(e => {
      if (e.tag == "mom") {
        e.index = index;
        index++
      }
    })
    data.sort((a, b) => {
      return new Date(b.mom_start_date) - new Date(a.mom_start_date);
    });
    setSpecificField(data);
    callbackMomSpecificField(data);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  function addHours(date, hours) {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }
  const [test, setTest] = useState({ test: '' });
  const changeValueInJson = (key, value, index) => {
    let _specificField = specificField;

    if (key == "mom_date") {
      _specificField[index].mom_start_date = value[0].$d;
      _specificField[index].mom_end_date = value[1].$d;
      // genNumberMOMNumber(_specificField)
    } else {
      _specificField[index][key] = value;
    }
    setSpecificField(_specificField);
    callbackMomSpecificField(_specificField);
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }
  function generateMomReport(mom_id) {
    setLoading(true);
    // toggle_modal_alertKick();
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/getMOMdataForReport`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: {
        mom_id_list: [mom_id],
        project_id:project_id
        
      }
  })
      .then( function (response) {
        console.log("getMOMdataForReport: ",response.data);
        momreport(response.data.minute_of_meeting[0],null,'version');
        // toggle_modal_alertKick();
      }).finally((res)=>{
        setTimeout(function () {
          setLoading(false);
          toggle_modal_alertKickSuccess()
        }, 10000);
       
        
        // downloadMOM(mom_id);
        // toggle_modal_alertKick();
        // toggle_modal_successMassge();

        setTest({...test,test:""});
        test.test = "";
      }).catch((error)=>{
        setLoading(false);
        console.log(error);
      });
      toggle_modal_alertKick();
  }

  function downloadMOM(mom_id) {
    setLoading(true);
    // Swal.fire({
    //   title: "Loading",
    //   text:`Sync Data in Process.`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onOpen: () => {
    //     Swal.showLoading();
    //   },
    // })
    // console.log("project_id: ",project_id);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: {
        mom_id_list: [mom_id],
        project_id:project_id
      }
  })
      .then(function (response) {
        // console.log("downLoadMom: ",response.data);
        let temp_all_data_mom = response.data.minute_of_meeting[0].mom_pdf ;
        // console.log("temp_all_data_mom",temp_all_data_mom);
        if (temp_all_data_mom.length > 0) {
          setLoading(false);
          let find_lastversion = temp_all_data_mom.sort((a,b)=> Number(b.momt_version)-Number(a.momt_version))[0]
          // console.log("find_lastversion: ",find_lastversion);
          downloadFile(find_lastversion,"");
          
          // window.open(`${Configs.TTT_Game_Service_IMG}${find_lastversion.momt_pathfile}`, "_blank", "noreferrer");
        } else {
          toggle_modal_alertKickNot()
          setLoading(false)
        }
        Swal.close();
        // momreport(response.data.minute_of_meeting[0],null,'version');
      }).catch((error)=>{
        console.log(error);
      });   
  }

  function generateMomReportAll(params) {
    // console.log("specificField",specificField);
    // Swal.fire({
    //   title: "Loading",
    //   text:`Sync Data in Process.`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onOpen: () => {
    //     Swal.showLoading();
    //   },
    // })
    let temp_mom_id = specificField.filter((el)=>{
      return el.tag =='mom' && el.mom_id !== null && el.mom_id !== undefined
    });
    if (temp_mom_id.length>0) {
      let reArangeData = temp_mom_id.map((el)=>{
        return  el.mom_id
      })
      console.log("reArangeData",reArangeData);
      for (const item of reArangeData) {
        // console.log("item",item);
        generateMomReport(item);
      }
      
    //   axios({
    //     method: 'post',
    //     url: `${Configs.TTT_Game_Service}/api/report/getMOMdataForReport`,
    //     headers: {
    //         Authorization: sessionStorage.getItem('access_token'),
    //         'X-TTT': `${Configs.API_TTT}`,
    //         'Content-Type': 'application/json',
    //     },
    //     data: {
    //       mom_id_list: reArangeData,
    //       project_id:project_id
    //     }
    // })
    //     .then( function (response) {
    //       if (response.data.minute_of_meeting.length > 0) {
    //         for (const item of response.data.minute_of_meeting) {
    //           console.log(item,"item");
    //           // momreport(item,null,'version');
    //         }
           
    //       }
    //     }).finally((res)=>{
    //       Swal.fire({
    //         icon: "success",
    //         title: "บันทึก version ปัจจุบันเเล้ว",
    //         showConfirmButton: false,
    //         timer: 1500,
    //     })
    //     }).catch((error)=>{
    //       console.log(error);
    //     });
    } else {
      toggle_modal_alertKickNot();
    //   Swal.fire({
    //     icon: "wraning",
    //     title: "Data not found!!",
    //     showConfirmButton: false,
    //     timer: 1500,
    // })
    }
  }
 function downloadMOMAll(params) {
    setLoading(true);
    // Swal.fire({
    //   title: "Loading",
    //   text:`Sync Data in Process.`,
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onOpen: () => {
    //     Swal.showLoading();
    //   },
    // })
    let temp_mom_id = specificField.filter((el)=>{
      return el.tag == 'mom' && el.mom_id !== null && el.mom_id !== undefined
    });
    if (temp_mom_id.length>0) {
      let reArangeData = temp_mom_id.map((el)=>{
        return  el.mom_id
      })
      
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
      headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: {
        mom_id_list: reArangeData,
        project_id:project_id
      }
  })
      .then(async function (response) {
     
        console.log("response.data.minute_of_meeting",response.data);
        if (response.data.minute_of_meeting.length > 0) {
   
            response.data.minute_of_meeting.forEach((url) => {
              if (url.mom_pdf.length > 0) {
                let find_lastversion = url.mom_pdf.sort((a,b)=> Number(b.momt_version)-Number(a.momt_version))[0]
                // console.log("url.mom_pdf: ",url.mom_pdf);   
                let tempArrLoad = [];
                tempArrLoad.push();
                // console.log("tempArrLoad",tempArrLoad);
              downloadFile(find_lastversion, 'downloadAll');
                
            } 
            });
        }
      }).finally((res)=>{
        setLoading(false);  
        Swal.close();
      }).catch((error)=>{
        console.log(error);
        setLoading(false);
      });   
    }else{
      setLoading(false);
      toggle_modal_alertKickNot();
    //   Swal.fire({
    //     icon: "wraning",
    //     title: "Data not found!!",
    //     showConfirmButton: false,
    //     timer: 1500,
    // })
}
  }
  const downloadFile = async (fileUrl, fileName) => {
    try {
      // Fetch the file from the server
      // console.log("fileUrl",fileUrl.fd_img_id);
      if(fileName === 'downloadAll'){
        // console.log("fileUrl: ",fileUrl);
        let response3 = {data:''}
        response3 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
      });
      // const tempUrl = URL.createObjectURL(response3.data);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response3.data);
  
      link.download = fileUrl.momt_filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);


      }else{
        let response2 = {data:''}
        response2 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
          headers: {
              Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
      });
      // const tempUrl = URL.createObjectURL(response2.data);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response2.data);
  
      link.download = fileUrl.momt_filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
  

      // console.log("tempUrl: ",tempUrl);
      // const response = await fetch(fileUrl, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/octet-stream',
      //   },
      // });

      // if (!response.ok) {
      //   throw new Error('Network response was not ok');
      // }

      // const blob = await response.blob();
      // const url = window.URL.createObjectURL(blob);

      // // Create a link element and trigger the download
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = fileName;
      // document.body.appendChild(a);
      // a.click();

      // // Cleanup
      // a.remove();
      // window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading ${fileName}:`, error);
    }
  };
  return (
    <>

      <div className="box-content-evens2">
        <div className="row delpm">
          <div className="col-12">
            <div className="box-content-evens1" style={{ height: '550px', marginBottom: "5px" }}>

              <div className="col-12">
                <div className='row'>
                  <p style={{ paddingTop: 5, paddingLeft: 5 }}>รายงานการประชุม</p>
                  {!userismode ?
                    <>
                      <button
                        className="btnAddSpecific ml-2"
                        style={{
                          border: '2px solid #0349FF',
                          width: '70px',
                          height: '34px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '0',
                          backgroundColor: '#0A2268'
                        }}
                        onClick={addValueMultiple}
                      >
                        <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 6.5V0.5H8V6.5H14V8.5H8V14.5H6V8.5H0V6.5H6Z" fill="white" />
                          </svg>

                          <span style={{ paddingTop: 5, paddingLeft: 5 }}>
                            ADD
                          </span>
                        </div>
                      </button>
                    </> : null}

                  <button
                    className="btnAddSpecific ml-2"
                    disabled={userismode}
                    style={{
                      cursor: !userismode ? "pointer" : "no-drop",
                      border: '2px solid #0349FF',
                      width: '180px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0',
                      backgroundColor: '#0A2268'
                    }}
                    onClick={() => {
                      if (!userismode) {
                        downloadMOMAll();
                      }
                    }}
                  >
                    <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                      </svg>
                      <span style={{ paddingTop: 5, paddingLeft: 5 }}>
                        Download All
                      </span>
                    </div>

                  </button>
                  <button
                    className="btnAddSpecific ml-2"
                    disabled={userismode}
                    style={{
                      cursor: !userismode ? "pointer" : "no-drop",
                      border: '2px solid #0349FF',
                      width: '180px',
                      height: '34px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0',
                      backgroundColor: '#0A2268'
                    }}
                    onClick={() => {
                      if (!userismode) {
                        generateMomReportAll();
                      }
                    }}
                  >
                    <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                      </svg>

                      <span style={{ paddingTop: 5, paddingLeft: 5 }}>
                        GENERATE ALL
                      </span>
                    </div>
                  </button>

                </div>
                {specificField && specificField.map((e, index) => {
                  const isMom = e.tag === 'mom';
                  const isSrs = e.tag === 'srs';
                  return (
                    <>
                      <div className='row'>
                        {isMom ? (
                          <div class="sidebar-tab" style={{ backgroundColor: '#0047FF' }}>MOM</div>
                        ) : (
                          <div class="sidebar-tab" style={{ backgroundColor: '#00AB11' }}>SRS</div>
                        )}
                        <div /* key={index4} */ style={{ display: 'flex', flexDirection: 'column', gap: '1rem', border: '2px solid #0349FF', padding: '25px 25px 25px 25px' }}>
                          <>
                            <div className='row'>
                              <div style={{ width: '560px' }}>
                                <div className='row' style={{ marginBottom: '1%' }}>
                                  <p style={{ paddingRight: '6px' }}>ครั้งที่ประชุม : </p>
                                  <div style={{ display: 'flex', gap: '5%' }}>
                                    <Tooltip  title={isSrs ? `${e.index}` : `ครั้งที่ ${Number(e.index).toLocaleString()}`} color='#051F6C' placement="right" >
                                      <p
                                        style={{
                                          border: '2px solid #0349FF',
                                          minWidth: '90px',
                                          height: '34.5px',
                                          // display: 'flex',
                                          // justifyContent: 'center',
                                          margin: '0',
                                          padding: 3,
                                          backgroundColor: '#0A2268',
                                          boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)',

                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          maxWidth: 430
                                        }}>
                                        {isSrs ? `${e.index}` : `ครั้งที่ ${Number(e.index).toLocaleString()}`}
                                      </p>
                                    </Tooltip>

                                    {isMom ? (
                                      <>

                                        <button
                                          className="btnAddSpecific ml-2"
                                          disabled={userismode || !e.mom_id}
                                          style={{
                                            cursor: e.mom_id && !userismode ? "pointer" : "no-drop",
                                            border: '2px solid #0349FF',
                                            width: '180px',
                                            height: '34px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            margin: '0',
                                            backgroundColor: '#0A2268',
                                            boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)'

                                          }}
                                          onClick={()=>{downloadMOM(e.mom_id);}}
                                        >

                                          <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <svg style={{ marginRight: '3px' }} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                                            </svg>
                                            <span style={{ paddingTop: 5, paddingLeft: 5 }}>
                                              Download
                                            </span>
                                          </div>

                                        </button>

                                        {
                                          isMom ?
                                            <>
                                              {userismode === false ? (
                                                // width: '34px', height: '34px'
                                                <>
                                                  {/* <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                    className='btnRed'
                                                    onClick={() => { removeValueMultiple(index); }}
                                                  ><i class="fa fa-trash" aria-hidden="true"></i></p> */}

                                                  <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                    className='btnRed'
                                                    onClick={() => { removeValueMultiple(index); }}
                                                  ><i class="fa fa-trash" style={{ paddingBottom: 4 }} aria-hidden="true"></i>
                                                  </button>
                                                </>

                                              ) : <></>}
                                            </>
                                            : null
                                        }
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className='row' style={{ marginBottom: '1%' }}>
                                  <p style={{ paddingRight: '6px', paddingLeft: '55px' }}>สถานที่ <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                  <TextArea
                                    className="areaTEst rezieznone"
                                    style={{ borderRadius: '0px', width: '435px' }}
                                    autoSize={{ minRows: 1 }}
                                    type='text'
                                    placeholder='INPUT YOUR DESCRIPTION...'
                                    readOnly={userismode || isSrs}
                                    value={e.mom_location ? e.mom_location : ''}
                                    onChange={(v) => {
                                      changeValueInJson('mom_location', v.target.value, index);
                                    }}
                                  />
                                </div>

                                <div className='row' style={{ marginBottom: '1%' }}>
                                  <p style={{ paddingRight: '6px' }}>หัวข้อการประชุม <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                  <TextArea
                                    className="areaTEst rezieznone"
                                    style={{ borderRadius: '0px', width: '435px', marginRight: '10px' }}
                                    autoSize={{ minRows: 1 }}
                                    type='text'
                                    placeholder='INPUT YOUR DESCRIPTION...'
                                    readOnly={userismode || isSrs}
                                    value={e.mom_header}
                                    onChange={(v) => {
                                      changeValueInJson('mom_header', v.target.value, index);
                                    }}
                                  />
                                </div>

                                <div className='row' style={{ marginBottom: '1%' }}>
                                  <p style={{ paddingRight: '6px' }}>เวลา : </p>
                                  <RangePicker
                                    showTime
                                    format={"DD/MM/YYYY HH:mm:ss"}
                                    className="areaTEst rezieznone"
                                    style={{ borderRadius: '0px', width: '380px' }}
                                    // placeholder='DD/MM/YYYY HH:MM:ss'
                                    suffixIcon={<div style={{ marginRight: '10px' }}><CalendarOutlined /></div>}
                                    disabled={userismode || isSrs}
                                    value={
                                      e.mom_start_date && e.mom_end_date
                                        ? [dayjs(e.mom_start_date), dayjs(e.mom_end_date)]
                                        : [null, null]
                                    }
                                    allowClear={false}
                                    onChange={(_, v) => {
                                      changeValueInJson(`mom_date`, _, index);
                                    }}
                                  />

                                  {isMom ?
                                    <button
                                      // disabled={!e.mom_id}
                                      className="btnAddSpecific ml-2"
                                      disabled={userismode || !e.mom_id}
                                      style={{
                                        cursor: e.mom_id ? "pointer" : "no-drop",
                                        cursor: e.mom_id && !userismode ? "pointer" : "no-drop",
                                        border: '2px solid #0349FF',
                                        width: '115px',
                                        height: '36px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '0',
                                        backgroundColor: '#0A2268',
                                        fontSize: '14px',
                                        boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)'
                                      }}
                                      onClick={() => {
                                        generateMomReport(e.mom_id);
                                        toggle_modal_alertKick();
                                        // console.log('specificField :>> ', specificField);
                                      }}
                                    >
                                      <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                                        <svg width="15" height="16" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                                        </svg>

                                        <span style={{ paddingTop: 5, paddingLeft: 5 }}>
                                          GENERATE
                                        </span>
                                      </div>
                                    </button>
                                    : null}
                                </div>

                              </div>

                              <div>

                                <ContentComponent userismode={userismode} index={index} mainData={specificField} setMainData={setSpecificField} callbackMainData={callbackMomSpecificField} />

                              </div>

                            </div>
                            <div>
                              <div className='row' style={{ width: '700px' }}>

                                {isSrs ? e.mom_link?.length > 0 ? <p>LINK DOCUMENT </p> : null : <p>LINK DOCUMENT </p>}
                                {isMom && !userismode ?
                                  <button
                                    className="btnAddSpecific ml-2"
                                    style={{ boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)', border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                                    onClick={() => addLinkDoc(index)}
                                  >
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                  </button>
                                  : null}

                              </div>

                              {e.mom_link?.map((linkDoc, index5) => (
                                <div className='row' style={{ display: 'flex', gap: '3%' }}>
                                  <div style={{ padding: '10px 0px 0px 30px' }}>
                                    <p style={{ boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)', border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                                      {index5 + 1}
                                    </p>
                                    <br />
                                    {isMom ? <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                      className='btnRed'
                                      onClick={() => { removeLinkDoc(index, index5); }}
                                    ><i class="fa fa-trash" aria-hidden="true" style={{ paddingBottom: 4 }}></i>
                                    </button> : null}

                                  </div>
                                  <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                                    <div className='row' style={{ marginBottom: '1%' }}>
                                      <p style={{ paddingRight: '6px' }}>name :</p>
                                      <TextArea
                                        className="areaTEst rezieznone"
                                        style={{ borderRadius: '0px', width: '1000px' }}
                                        autoSize={{ minRows: 1 }}
                                        type='text'
                                        placeholder='INPUT YOUR DESCRIPTION...'
                                        value={linkDoc.name}
                                        readOnly={userismode || isSrs}
                                        onChange={(e) => handleLinkInputChange('name', e.target.value, index, index5)}
                                      />
                                    </div>

                                    <div className='row' style={{ marginBottom: '1%' }}>
                                      <p style={{ padding: '0px 6px 0px 20px' }}>link :</p>
                                      <TextArea
                                        className="areaTEst rezieznone"
                                        style={{ borderRadius: '0px', width: '1000px' }}
                                        autoSize={{ minRows: 1 }}
                                        type='text'
                                        placeholder='INPUT YOUR DESCRIPTION...'
                                        value={linkDoc.link}
                                        readOnly={userismode || isSrs}
                                        onChange={(e) => handleLinkInputChange('link', e.target.value, index, index5)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {e.tag === 'mom' ? (
                                <>
                                  <div className='row' style={{ marginBottom: '1%', marginTop: '2%' }}>
                                    <p style={{ paddingRight: '6px' }}>ผู้บันทึกการประชุม <span style={{ display: !userismode && e.mom_record_by.length === 0 ? '' : 'none', color: 'red' }}>*</span> : </p>
                                    <TextArea
                                      readOnly={userismode}
                                      className="areaTEst rezieznone"
                                      style={{ borderRadius: '0px', width: '435px', marginRight: '10px' }}
                                      autoSize={{ minRows: 1 }}
                                      type='text'
                                      placeholder='INPUT YOUR DESCRIPTION...'
                                      value={e.mom_record_by}
                                      onChange={(v) => {
                                        changeValueInJson('mom_record_by', v.target.value, index);
                                      }}
                                    />
                                  </div>
                                </>
                              ) : <></>}

                            </div>
                          </>

                        </div>
                      </div>
                      <br />
                    </>)
                })}

              </div>


            </div>
          </div>
        </div >
      </div >
      <ModelAlertMassge
        isOpen={successMassge}
        toggle_alertKick={() => { toggle_modal_alertKick() }}
        message={messageBox}
        mainFuc={() => { toggle_modal_successMassge() }}
      />
      {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    Loading       
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='alertKick' style={{ display: `${alertKickSuccess ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `#339900` }}>
                  SUCCESS
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    Current version saved
                  </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKickSuccess()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='alertKick' style={{ display: `${alertKickNot ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `orange` }}>
                !WARNING!
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    Data not found!!
                  </div>
              </div>
              <div className='row alertKickBoxButton' >
                <button className='alertKickConfirm mr-2' onClick={() => {
                    toggle_modal_alertKickNot()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>

        {/* <ModelAlertMassge
        isOpen={errorMassge}
        toggle_alertKick={() => { toggle_modal_errorMassge() }}
        message={messageBox}
        mainFuc={() => {  }}
        /> */}
        {/* <ModelAlertMassge
        isOpen={successMassge}
        toggle_alertKick={() => { toggle_modal_successMassge() }}
        message={messageBox}
        mainFuc={() => {  }}
        /> */}

    </>

    
  );
}

const ContentComponent = ({ index, mainData, setMainData, callbackMainData, userismode }) => {

  const [activeKey, setActiveKey] = useState('0');
  const [textChange, setTextChange] = useState({ text: '' });
  const [number, setNumber] = useState(1)

  // const [momGroup, setMomGroup] = useState([])
  useEffect(() => {
    if (mainData[index].tag == "mom") {
      mainData[index].mom_group = isArray(mainData[index].mom_group) ? mainData[index].mom_group : [];
      if (mainData[index].mom_group.length == 0) {
        mainData[index].mom_group = [{
          group_name: "Company",
          person: [],
          person_del: []
        }]

        // console.log('mainData :>> ', mainData);
        // setMomGroup(mainData[index].mom_group)
        setMainData(mainData)
        if (isFunction(callbackMainData)) callbackMainData(mainData)
        setTextChange({ ...textChange, text: '' });
        textChange.text = '';
        setActiveKey("0")
        setNumber(1)
      }
    }
  }, [mainData.length])

  const callbackPerson = (index, key, value, value_del) => {
    if (!mainData[index].mom_group[Number(key)]) {
      mainData[index].mom_group[Number(key)] = {
        group_name: "Company",
        person: [],
        person_del: []
      }
    }
    if (value_del) {
      console.log('value_del :>> ', value_del);
      mainData[index].mom_group[Number(key)].person_del = isArray(mainData[index].mom_group[Number(key)].person_del) ? mainData[index].mom_group[Number(key)].person_del : []
      mainData[index].mom_group[Number(key)].person_del.push(value_del)
    }
    mainData[index].mom_group[Number(key)].person = value;
    setMainData(mainData)
    if (isFunction(callbackMainData)) callbackMainData(mainData)
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  const [objDelGroup, setObjDelGroup] = useState({
    index: undefined,
    key: undefined,
  })

  const onEdit = (action, index, key) => {
    if (action === 'add') {
      if (isArray(mainData[index].mom_group)) {
        mainData[index].mom_group.push({
          group_name: "Company " + `(${(number + 1)})`,
          person: []
        })
        setNumber((pre) => pre + 1)
        setMainData(mainData)
        if (isFunction(callbackMainData)) callbackMainData(mainData)
        setTextChange({ ...textChange, text: '' });
        textChange.text = '';
      }
    } else if (action === "remove") {
      if (mainData[index].mom_group[Number(key)].person.length > 0) {
        // toggle_modal_confrimDelModal()
        // setObjDelGroup({ index, key })
        onRemove(index, key)
      } else {
        onRemove(index, key)
      }
    }
  };

  const onRemove = (index, key) => {
    if (mainData[index].mom_group[Number(key)].mpg_id) {
      mainData[index].mom_group_del = isArray(mainData[index].mom_group_del) ? mainData[index].mom_group_del : [];
      mainData[index].mom_group_del.push(mainData[index].mom_group[Number(key)]);
    }
    if (key == activeKey) {
      setActiveKey("0")
    }
    mainData[index].mom_group.splice(Number(key), 1);
    setMainData(mainData)
    if (isFunction(callbackMainData)) callbackMainData(mainData)
    setTextChange({ ...textChange, text: '' });
    setObjDelGroup({ index: undefined, key: undefined })
    textChange.text = '';
  }

  const onChangeTabs = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const handleDoubleClick = (index, key) => {
    mainData[index].mom_group[Number(key)].isEdit = true;
    setMainData(mainData)
    if (isFunction(callbackMainData)) callbackMainData(mainData)
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  const handleTabNameChange = (index, key) => {
    mainData[index].mom_group[Number(key)].isEdit = undefined;
    setMainData(mainData)
    if (isFunction(callbackMainData)) callbackMainData(mainData)
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  const onChangeTabName = (value, index, key) => {
    mainData[index].mom_group[Number(key)].group_name = value;
    setMainData(mainData)
    setTextChange({ ...textChange, text: '' });
    textChange.text = '';
  }

  /* Modal */
  const [displayDelSwal, setDisplayDelSwal] = useState(false);
  const toggle_modal_confrimDelModal = () => setDisplayDelSwal(!displayDelSwal);
  const callbackDel = () => {
    onRemove(objDelGroup.index, objDelGroup.key)
    toggle_modal_confrimDelModal()
  }
  return (
    <>
      {mainData[index].tag == "srs" ?
        <TabContent
          isSrs={true}
          index={index}
          person={mainData[index].mom_participants}
        /> :

        <Tabs
          className='ant-tabs-nav'
          type="editable-card"
          onChange={onChangeTabs}
          activeKey={activeKey}
          style={{ maxWidth: '647px', overflowX: 'auto' }}
          hideAdd={userismode ? true : false}
          onEdit={(targetKey, action) => onEdit(action, index, targetKey)}

        >
          {
            mainData[index].mom_group.map((e, index5) =>
              <TabPane
                closable={userismode ? false : mainData[index].mom_group.length > 1 ? true : false}
                key={`${index5}`}
                tab={(
                  !e.isEdit ?
                    <div onDoubleClick={() => {
                      if (!userismode)
                        handleDoubleClick(index, `${index5}`)
                    }}>
                      {e.group_name && e.group_name != "" ? e.group_name : `${index5 + 1}. INPUT COMPANY NAME...`}
                    </div> :
                    <InputStyle
                      value={e.group_name}
                      placeholder={`INPUT COMPANY NAME...`}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          handleTabNameChange(index, `${index5}`)
                        } else if (event.keyCode === 32) {
                          onChangeTabName(`${e.group_name} `, index, `${index5}`)
                        }
                      }}
                      onBlur={(event) => {
                        handleTabNameChange(index, `${index5}`)
                      }}
                      onChange={(e) => onChangeTabName(e.target.value, index, `${index5}`)}
                    />
                )}
              >
                <TabContent
                  isSrs={false}
                  index={index}
                  person={e.person}
                  key_obj={index5}
                  callbackPerson={callbackPerson}
                  userismode={userismode}
                />
              </TabPane>
            )
          }
        </Tabs >
      }

      <AlertConfrimDelete
        isOpen={displayDelSwal}
        mainFuc={() => {
          callbackDel()
        }}
        toggle_confrim={() => toggle_modal_confrimDelModal()}
      />
    </>
  )
}
const TabContent = ({ isSrs, index, person, key_obj, callbackPerson, userismode }) => {

  const addParticipant = () => {
    if (!isSrs) {

      /* new */
      if (isFunction(callbackPerson)) {
        person.push({
          name: "",
          position: ""
        })
        // index, key, value
        callbackPerson(index, key_obj, person)
      }
    }
  };

  const removeParticipant = (index5) => {
    // const newParticipants = participants.filter((_, i) => i !== index);
    // setParticipants(newParticipants);
    if (!isSrs) {
      /* new */
      if (isFunction(callbackPerson)) {
        let del
        if (person[index5]?.mp_id) {
          del = { ...person[index5] };
        }
        person.splice(index5, 1);
        // index, key, value
        callbackPerson(index, key_obj, person, del)
      }
    }
  };

  const changeValueInJson = (key, value, index5) => {
    if (isFunction(callbackPerson)) {
      person[index5][key] = value;
      callbackPerson(index, key_obj, person)
    }
  }

  return (
    <>
      <div style={{ border: '2px solid #0349FF', padding: '25px',/*  marginTop: '10px', */ maxWidth: '647px', maxHeight: 320, overflowY: "auto" }}>
        <div className='row' style={{ width: '625px' }}>
          <p>ผู้เข้าร่วมประชุม</p>
          {!isSrs && !userismode ?
            <>
              <button
                className="btnAddSpecific ml-2"
                style={{ border: '2px solid #0349FF', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                onClick={() => addParticipant()}
              >
                <i class="fa fa-plus" aria-hidden="true" ></i>
              </button>
            </> : null}

          <div style={{ flexGrow: 1 }}></div>
          <div className='row'>
            <div style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0047FF', borderRadius: '15px', marginRight: '10px' }}>
              <i class="fa fa-user" aria-hidden="true" style={{ color: 'black' }}></i>
            </div>
            <p>{person.length}</p>
          </div>
        </div>
        <div /* style={{ maxHeight: '320px', overflowY: 'auto' }} */>

          {person.map((item, index5) => (
            <div className='row' style={{ display: 'flex', gap: '2%' }}>
              <div style={{ padding: '10px 0px 0px 10px' }}>
                <p style={{ boxShadow: '0px 0px 15px 0px rgba(255, 255, 255, 0.25)', border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                  {index5 + 1}
                </p>
                <br />
                {!isSrs && !userismode ?
                  <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                    className='btnRed'
                    onClick={() => { removeParticipant(index5); }}
                  ><i class="fa fa-trash" aria-hidden="true" style={{ paddingBottom: 4 }}></i>
                  </button>
                  : null}
              </div>
              <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                <div className='row' style={{ marginBottom: '1%' }}>
                  <p style={{ paddingRight: '6px' }}>ชื่อ - นามสกุล <span style={{ display: userismode === false ? '' : 'none', color: 'red' }}>*</span> :</p>
                  <TextArea
                    className="areaTEst rezieznone"
                    style={{ borderRadius: '0px', width: '360px' }}
                    autoSize={{ minRows: 1 }}
                    type='text'
                    readOnly={userismode || isSrs}
                    placeholder='INPUT YOUR DESCRIPTION...'
                    value={item.name}
                    onChange={(e) => changeValueInJson(`name`, e.target.value, index5)}
                  />
                </div>

                <div className='row' style={{ marginBottom: '1%' }}>
                  <p style={{ padding: '0px 6px 0px 36px' }}>ตำแหน่ง <span style={{ display: userismode === false ? '' : 'none', color: 'red' }}>*</span> :</p>
                  <TextArea
                    className="areaTEst rezieznone"
                    style={{ borderRadius: '0px', width: '360px' }}
                    autoSize={{ minRows: 1 }}
                    type='text'
                    readOnly={userismode || isSrs}
                    placeholder='INPUT YOUR DESCRIPTION...'
                    value={item.position}
                    onChange={(e) => changeValueInJson(`position`, e.target.value, index5)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
       
      </div>
    </>
  );
};

const InputStyle = styled(Input)`
  background-color: transparent;
  border: 0px;
  width: auto;
  min-width: 50px;
  padding: 0;
  font-size: 16px; /* ปรับขนาดตามฟอนต์ที่ใช้งาน */
  /* &:focus {
    outline: none;
  } */
`;


export default TabCompanentMOM;
