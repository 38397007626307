import React, { useEffect, useState, useRef } from 'react'
import './CreateSpaceship.css'
import axios from "axios";
import Configs from "../../config";
import moment from "moment";

import dayjs from "dayjs";
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal } from 'antd';
import Reportlrit from '../listtemplate/'
import FileViewer from 'react-file-viewer';
import DocViewer from 'react-doc-viewer';
import ImgDimon from './diamond.png';
import icon_sapphire from '../../assets/img/iconSpaceShip/Frame.png';
import Select from 'react-select'
import Swal from "sweetalert2";
import Slider from 'react-slick';
import spaceimg from '../../assets/img/iconSpaceShip/Space_Size-S2.png'
import mainGrafimg from './imgSkil/skilGraf.png'
import skil_1img from './imgSkil/skil1.svg'
/* import spaceimg from '../../../../assets/img/iconSpaceShip/Space_Size-S2.png' */
import ModelAlertConfrim from "../../components/AlertMassge/AlertConfrim";

const { RangePicker } = DatePicker;

const CreateSpaceship = ({
  isOpenCreate,
  callBackColse,
  sapphireData,
  getAmountSapphire,
  callBackReFilter

}) => {
  const [test, setTest] = useState({
    test: ''
  });
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const [confrimModal, setConfrimModal] = useState(false);
  const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);

  const [myTempValue, serMyTempValue] = useState({
    sapphire: 11111999999999,
    spaceship_life: ''
  })

  const [isActiveTab, setIsActiveTab] = useState({
    is_tab: ''
  })

  const [tempSave, setTempSave] = useState({
    smo_id: '',
    smo_img_name: '',
    smo_img_path: '',
    smo_sapphire: 0,
    smo_size: '6',
    smo_color: '',
    sp_name: '',
    sp_description: '',
    new_date: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    sp_life: moment(new Date()).add(3, 'years').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    power: "",
    speed: "",
    durabilty: "",
    precision: "",
    potential: "",
    technical: ""

  })
  const [spaceData, setSpaceData] = useState([])
  const [errorMasgerList, setErrorMasgerList] = useState({
    isError: false,
    massger: []
  })
  const [optintSize, setOptintSize] = useState([
    {
      value: '6',
      label: '6 SLOTS'
    }, {
      value: '10',
      label: '10 SLOTS'
    }, {
      value: '15',
      label: '15 SLOTS'
    }
  ])

  const [detailSpaceShip, setDetailSpaceShip] = useState({
    space_name: 'ASTEROID',
    space_descrip: `ยานอวกาศที่ถูกส่งออกไปยังนอกจักรวาลเพื่อทำภารกิจช่วยเหลือคนบนดาวเคราะห์
    ที่ห่างไกลความสงบ ความเจริญ`,
    skil_img: '',
    width_svg: '61',
    height_svg: '57',
    svg_d: '',
    viewBox: ""
  })

  useEffect(() => {

    if (isOpenCreate === true) {
      GetAccesstokenToken((callback) => {
        if (callback) {
          GetSpaceshipToBuy('6')
        }
      });

    } else {
      setTempSave({
        ...tempSave,
        smo_id: '',
        smo_img_name: '',
        smo_img_path: '',
        smo_sapphire: '',
        smo_size: '6',
        smo_color: '',
        sp_name: '',
        sp_description: '',
        new_date: new Date(),
        sp_life: moment(new Date()).add(3, 'years').set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      })
      setSpaceData([])
    }
  }, [isOpenCreate])


  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {

        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const GetSpaceshipToBuy = async (value) => {

    let aslist = {
      id: sessionStorage.getItem('user_id'),
      size: value
    }
    axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/spaceship/getSpaceshipToBuy`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: aslist,
    })
      .then(async function (response) {
        // console.log('ขอดูหน่อยนะครับ', response.data);
        setDetailSpaceShip({
          ...detailSpaceShip,
          space_name: '',
          space_descrip: '',
          skil_img: ''
        });
        setIsActiveTab({ ...isActiveTab, is_tab: '' });
        isActiveTab.is_tab = '';
        setSpaceData(response.data);
        let temp = response.data;
        if (temp.length > 0) {
          for (let item of temp) {
            if (item.fd_img_id_model) {
              const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id_model}/content`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
              });

              item.smo_img_path = URL.createObjectURL(response2.data);
            };

            if (item.fd_img_id_skill) {
              const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id_skill}/content`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
              });

              item.smo_img_path_status = URL.createObjectURL(response2.data);
            };

            setSpaceData(response.data);
            setTest({...test,test:''});
            test.test = '';
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} arrows nexArrowBox`}
        style={{
          ...style, display: "block",
          /*    background: "transparent", */
          position: 'absolute',
          top: '118px',
          left: '-2%',
          width: '55px',
          height: '60px',
          borderRadius: '10px 0 0 10px',
          border: '2px solide white',
          textAlign: 'center',
          padding: '8px'
        }}
        onClick={onClick}
      ><i className="fa fa-angle-left" style={{ padding: '5px', fontSize: '29px' }} /> </div>

    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} arrows nexArrowBox`}
        style={{
          ...style,
          display: "block",
          /*    background: "#0047FF", */
          position: 'absolute',
          top: '118px',
          right: '-2%',
          width: '55px',
          height: '60px',
          borderRadius: '0 10px 10px 0',
          border: '2px solide white',
          textAlign: 'center',
          padding: '8px',
        }}
        onClick={onClick}
      ><i className="fa fa-angle-right" style={{ padding: '5px', fontSize: '29px' }} /> </div>

    );
  }

  const numberWithCommas = (x) => {
    let num = x.toString().split('.');
    let num2 = "";
    if (num.length == 2) {
      num2 = `.${num[1]}`;
    }
    return num[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + num2;
  };


  const settings = {
    dots: false,
    infinite: spaceData.length > 5 ? true : false,
    slidesToShow: 6,
    slidesToScroll: 1,

    swipeToSlide: false,
    autoplaySpeed: 0,
    speed: 1000,
    autoplay: false,
    nav: false,

    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,

    beforeChange: function (currentSlide, nextSlide) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      // console.log("after change", currentSlide);
    }


  };


  function setValueInStage(value) {

    let foces = {
      power: '',
      speed: '',
      durabilty: '',
      precision: '',

    }
    let pranJson = value.smo_skill_detail !== null && value.smo_skill_detail !== "" ? JSON.parse(value.smo_skill_detail) : null
    console.log('ดูข้อมูลนี้ไอดี 1', value);

    /*     let pranJsonSVG = value.smo_img_path_status !== null && value.smo_img_path_status !== "" ? JSON.parse(value.smo_img_path_status) : null */

    /*     console.log('ดูข้อมูลนี้ไอดี TTT', pranJsonSVG); */

    setTempSave({
      ...tempSave,
      smo_id: value.smo_id,
      smo_img_name: value.smo_img_name,
      smo_img_path: value.smo_img_path,
      smo_sapphire: value.smo_sapphire,
      smo_size: value.smo_size,
      smo_color: value.smo_color,
      power: pranJson !== null ? pranJson.power : '',
      speed: pranJson !== null ? pranJson.speed : '',
      durabilty: pranJson !== null ? pranJson.durabilty : '',
      precision: pranJson !== null ? pranJson.precision : '',
      potential: pranJson !== null ? pranJson.potential : '',
      technical: pranJson !== null ? pranJson.technical : ''
    })


    let name = '';
    let remark = '';
    let img_skil = '';

    if (value.smo_size === '6') {
      name = 'ASTEROID'
      remark = `ยานอวกาศที่ถูกส่งออกไปยังนอกจักรวาลเพื่อทำภารกิจช่วยเหลือคนบนดาวเคราะห์
      ที่ห่างไกลความสงบ ความเจริญ`
      img_skil = ''
    } else if (value.smo_size === '10') {
      name = 'FIREFLY'
      remark = `ยานอวกาศรุ่นต้นแบบของ Echoes ที่เคยผ่านสงครามมานับครั้งไม่ถ้วน มันเป็นยานอวกาศที่ขับ
      เคลื่อนด้วยพลังงานคริสตัลสีเขียวทำให้เวลาขึ้นบินทำให้มันมีพลังงานมากมายในการบินกำจัดศัตรู 
      แต่ก็ต้องถูกปลดประจำการไปและถูกแทนที่ด้วยลำที่ใหม่กว่า`
      img_skil = ''
    } else if (value.smo_size === '15') {
      name = 'CRIMSON DYNAMO'
      remark = `ยานรบอวกาศที่ขับเคลื่อนด้วยพลังงานสีแดงแห่งความโกรธแค้น ไม่ว่าใครก็ตามที่ได้นั่งบนยานลำนี้ 
      ทุกสมรภูมิจะกลายเป็นนรกบนห้วงอวกาศที่ไม่มีที่สิ้นสุด ในอดีตยานลำนี้ก็เป็นยานรบธรรมดาแต่
      เหตุการณ์หนึ่งที่ทำให้ยานลำนี้หลุดเข้าไปยังมิตินรก มันได้กลับออกมายังโลกภายนอก สีของมันได้เปลี่ยน
      กลายเป็นสีแดงฉานคล้ายกับเลือดที่ท่วมไปทั่วตัว พร้อมกับความแค้นที่ไม่มีที่สิ้นสุด`
      img_skil = ''
    }

    /*  console.log('Configs.TTT_Game_Service_IMG + tempSave.smo_img_path_status',Configs.TTT_Game_Service_IMG + tempSave.smo_img_path_status); */

    setDetailSpaceShip({
      ...detailSpaceShip,
      space_name: value.smo_name || 'TTTShip',
      space_descrip: value.smo_story_model,
      skil_img: value.smo_img_path_status || skil_1img,
      power: pranJson !== null ? pranJson.power : '',
      speed: pranJson !== null ? pranJson.speed : '',
      durabilty: pranJson !== null ? pranJson.durabilty : '',
      precision: pranJson !== null ? pranJson.precision : '',
      potential: pranJson !== null ? pranJson.potential : '',
      technical: pranJson !== null ? pranJson.technical : '',
      smo_img_path_status: value.smo_img_path_status !== null ? value.smo_img_path_status : mainGrafimg
    })

    setIsActiveTab({ ...isActiveTab, is_tab: 'tab-story' })
    isActiveTab.is_tab = 'tab-story'

  }



  function wantToBuy(value) {
    toggle_modal_confrimModal()
    let errorMasger = [];
    if (tempSave.smo_img_path == undefined || tempSave.smo_img_path == null || tempSave.smo_img_path == '') {
      let temp_err = {
        message: "Please select your space ship!"
      }
      errorMasger.push(temp_err);
    }
    console.log('tempSave.sp_name', tempSave.sp_name);
    if (tempSave.sp_name == undefined || tempSave.sp_name == null || tempSave.sp_name.trim() == '') {
      let temp_err = {
        message: "Please enter your Spaceship Name!"
      }
      errorMasger.push(temp_err);
    }

    if (parseFloat(sapphireData || 0) < parseFloat(tempSave.smo_sapphire || 0)) {
      let temp_err = {
        message: "Quantity Your Sapphire is not enough to purchase!"
      }
      errorMasger.push(temp_err);
    }


    if (errorMasger.length > 0) {
      var err_message = "";
      /*  for (var e = 0; e < errorMasger.length; e++) {
         err_message += "<br/>" + errorMasger[e].message;
       } */
      setErrorMasgerList({
        ...errorMasgerList,
        isError: true,
        massger: errorMasger
      })

      toggle_modal_alertKick()
    } else {
      setErrorMasgerList({
        ...errorMasgerList,
        isError: false,
        massger: []
      })
      var saveTemp = {
        smo_id: tempSave.smo_id,
        smo_img_name: tempSave.smo_img_name,
        smo_img_path: tempSave.smo_img_path,
        smo_sapphire: tempSave.smo_sapphire,
        smo_size: tempSave.smo_size,
        smo_color: tempSave.smo_color,
        sp_name: tempSave.sp_name,
        new_date: tempSave.new_date,
        sp_life: tempSave.sp_life,
        sp_description: tempSave.sp_description,
        user_id: sessionStorage.getItem('user_id')
      }

      axios({
        method: "POST",
        url: `${Configs.TTT_Game_Service}/api/spaceship/SaveUserBuySpaceShip`,
        headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
        data: saveTemp,
      })
        .then(async function (response) {

          /*   setSpaceData(response.data) */
          setTempSave({
            ...tempSave,
            smo_id: '',
            smo_img_name: '',
            smo_img_path: '',
            smo_sapphire: '',
            smo_size: '',
            smo_color: '',
          })
          setErrorMasgerList({
            ...errorMasgerList,
            isError: false,
            massger: []
          })
          setSpaceData([])
          getAmountSapphire();
          callBackReFilter(true)
        })
        .catch(function (error) {
          console.log(error);
          setErrorMasgerList({
            ...errorMasgerList,
            isError: true,
            massger: error.response.data.error[0].errorDis
          })
          toggle_modal_alertKick();
          /* setErrorResMessage(error.response.data.error[0].errorDis) */
        });




    }






  }




  return (
    <div style={{ visibility: `${isOpenCreate ? 'visible' : 'hidden'}` }}>
      <div className='boxMainCreate'>

        <div className='addSpaceBox'>
          <div className='grob-colse'>
            <div className='btn-close-tt'>
              <i className='fa fa-times ButtonClose' onClick={() => {
                callBackColse(false)
              }}></i>
            </div>
          </div>
          <div className='row-herder'>
            <div className='RowsText'>
              <label className='text-1'> NEW SPACESHIP  </label>
            </div>
            <div className='RowsText-2 mr-3'>
              <div className='text-2'>
                <img src={icon_sapphire} style={{ marginRight: '10px', marginTop: '-5px', height: '30px' }} />
                {numberWithCommas(sapphireData || 0)} </div>

            </div>
          </div>
          <div className='body-miden row' style={{ borderBottom: '1px solid #0047FF' }}>
            {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ?
              <>
                <div className='col-2 mt-2'>
                  <div className='crad-selected'>
                    <div className='cradSpaceshipImgBox-1'>
                      {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <img src={`${tempSave.smo_img_path/* Configs.TTT_Game_Service_IMG + tempSave.smo_img_path */}`}
                        className='cradSpaceshipImg-1' style={{ objectFit: 'contain', border: `4px solid ${tempSave.smo_color}`, top: 'auto', width: 'unset' }}></img> :
                        <div className='backGroup'>
                        </div>}
                      {/*  <svg height="320" width="220" style={{ marginLeft: '-203px', zIndex: 99 }}>
                  <line x1="8" y1="15" x2="8" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="7" y1="1" x2="31" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />

                  <line x1="220" y1="50" x2="220" y2="90" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="220" y1="89" x2="235" y2="105" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="234.5" y1="104.5 " x2="234.5" y2="155" style={{ stroke: ` ${tempSave.smo_color} `, strokeWidth: '2' }} />

                  <line x1="210" y1="290 " x2="210" y2="309" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="190" y1="308" x2="210" y2="308" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />

                  <line x1="5" y1="276 " x2="5" y2="200" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="5" y1="200 " x2="10" y2="190" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="10" y1="190 " x2="10" y2="150" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                </svg> */}
                      <svg height="320" width="220" style={{ marginLeft: '-203px', zIndex: 99 }}>
                        <line x1="8" y1="15" x2="8" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="7" y1="1" x2="31" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="50" x2="190" y2="90" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="89" x2="210" y2="105" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="210" y1="104.5 " x2="210" y2="155" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="210" y1="290 " x2="210" y2="309" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="308" x2="210" y2="308" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="5" y1="276 " x2="5" y2="200" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="5" y1="200 " x2="10" y2="190" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="10" y1="190 " x2="10" y2="150" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                      </svg>
                      <div className='cardSpaceshipImgLine-1' style={{ backgroundColor: `${tempSave.smo_color}` }}></div>
                    </div>
                    <div className={`${tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? 'cardSpaceshipHover-1' : 'cardSpaceshipHoverFack-1'}`}

                    ></div>

                  </div>
                </div>
                <div className='col-5' style={{ margin: 0, top: 0, padding: 0 }}>
                  <div className='csheck' style={{ height: 'fitContent', /* border: '1px solid #fff', */ padding: '0px', margin: '0px' }}>
                    <div className='row mt-4' style={{/*  border:'1px solid red', */width: '100%', padding: '0px', margin: '0px' }}>
                      <div className='col-7' style={{ /* border: '1px solid blue', */ padding: '0px' }}>
                        <div className="pi-infoText" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` /* ,border:'1px solid red' */, width: '100%', margin: '0px' }} >Spaceship Name :</div>
                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`/* ,border:'1px solid red' */, width: '100%' }}>
                          <div className="pi-infoBoxTextCreat">
                            <div className="pi-infoBoxTextCreatCen">
                              <input
                                className="pi-infoBoxTextCreatIn"
                                type='text'
                                value={tempSave.sp_name}
                                onChange={(e) => {
                                  setTempSave({
                                    ...tempSave,
                                    sp_name: e.target.value
                                  })
                                }}
                              >
                              </input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-5' style={{ /* border: '1px solid red', */ padding: '0px', margin: '0px 0px 0px 0px' }}>
                        <div className="pi-infoText" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`, marginTop: '2px', width: '100%' }} >Size :</div>

                        <div className="pi-infoBoxTextShadow">
                          <div className="pi-infoBoxTextCreaSelectt" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`, zIndex: 1, marginTop: '-4px' }}>
                            <div className="pi-infoBoxTextCreatCenSelect" style={{ width: '100%' }}>
                              <div className="pi-infoBoxTextCreatSelect" style={{ width: '100%' }}>


                              </div>

                            </div>
                          </div>
                          {isOpenCreate && (

                            <Select
                              options={optintSize}
                              /* style={{zIndex: 999999}} */
                              className='selectNewSpace'
                              value={optintSize.filter((e) => { return e.value === tempSave.smo_size })}
                              onChange={(e) => {
                                setTempSave({
                                  ...tempSave,
                                  smo_size: e.value,
                                  smo_id: '',
                                  smo_img_name: '',
                                  smo_img_path: '',
                                  smo_sapphire: '',
                                  smo_color: '',
                                })
                                GetAccesstokenToken((callback) => {
                                  if (callback) {
                                    GetSpaceshipToBuy(e.value)
                                  }
                                });

                              }}
                            />

                          )}


                        </div>
                      </div>
                    </div>
                    <div className='row' style={{ /* border:'1px solid red',  */padding: '0px', margin: '0px' }}>
                      <div className='col-auto' style={{ width: '100%', padding: '0px' }} >
                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }} >
                          <div className="pi-infoText">Description :</div>
                          <textarea className="project-infoBoxTextIn-arex"
                            id="input_arex_description"
                            type='text'
                            style={{ height: ' 142px', overflow: 'auto' }}
                            value={tempSave.sp_description}
                            onChange={(e) => {
                              setTempSave({
                                ...tempSave,
                                sp_description: e.target.value
                              })
                            }}
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 mt-3' style={{ margin: '0px', padding: '3px' }}>
                        <div className="pi-infoText" style={{ fontSize: '14px' }}>Spaceship Life  :
                          &nbsp; {moment(myTempValue.new_date).format('DD MMM YYYY')}  - {moment(tempSave.sp_life).format('DD MMM YYYY')}&nbsp;(3 Years)
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-5' style={{ margin: 0, padding: '0 10px' }}>
                  {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <>
                    <div className='arearight' >
                      <div className='row box-right-herd' >

                        <div className={`tab-story${isActiveTab.is_tab === 'tab-story' ? '-active' : ''}`}

                          onClick={() => {
                            if (tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '') {
                              setIsActiveTab({ ...isActiveTab, is_tab: 'tab-story' })
                              isActiveTab.is_tab = 'tab-story'
                              /*   {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <>  */
                            }


                          }}>
                          <b className='text-tab-story' > STORY OF SPACESHIP </b>
                        </div>
                        <div className={`tab-skil${isActiveTab.is_tab === 'tab-skil' ? '-active' : ''}`}

                          onClick={() => {
                            if (tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '') {
                              setIsActiveTab({ ...isActiveTab, is_tab: 'tab-skil' })
                              isActiveTab.is_tab = 'tab-skil'
                            }

                          }}>
                          <b className='text-tab-skil'> SPACESHIP SKILLS </b>
                        </div>



                      </div>
                      <div className='row box-right-body'>
                        {isActiveTab.is_tab === 'tab-story' ? <>
                          <div className='area-story-head'>
                            <b className='text-story-head '>
                              {detailSpaceShip.space_name || ''}
                            </b>
                          </div>
                          <div className='area-story-body-box'>
                            <p style={{ color: '#FFFFFF' }}> {detailSpaceShip.space_descrip || ''} </p>
                          </div>
                        </> : <> </>}


                        {isActiveTab.is_tab === 'tab-skil' ?
                          <>
                            <div className='row' style={{ padding: '0ox', margin: '0px', flexWrap: 'nowrap' /* ,border:'1px solid red' */ }}>
                              <img className='area-img-skil mt-4'
                                src={detailSpaceShip.smo_img_path_status}
                              ></img>
                              {/*    <svg xmlns="http://www.w3.org/2000/svg" width={`${detailSpaceShip.width_svg}`} height={`${detailSpaceShip.height_svg}`} viewBox={`${detailSpaceShip.viewBox}`} fill="none" className='item-skil-img'>
                          <path d= {`${detailSpaceShip.svg_d}`} fill="#FF7777" fill-opacity="0.65" />
                        </svg> */}

                              {/*   <img src={detailSpaceShip.smo_img_path_status || skil_1img } className='item-skil-img' /> */}
                              <div className='item-skil-body mt-3' style={{ /* marginLeft: '34px' */ }}>
                                <div className='row ml-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-story-head '>
                                    {detailSpaceShip.space_name || 'ACSASCASAS'}
                                  </b>
                                </div>
                                <div className='row ml-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    POWER : <span className='item-skil-body-in'>{detailSpaceShip.power || 'ยานอวกาศที่ไม่ได้จริงจังกับอะไรมากนัก แต่เมื่อถึงเวลาต้องออกงานยานลำนี้จะจริงจังโดยทันที จัดสรรเวลาด'}</span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    SPEED :  <span className='item-skil-body-in'> {detailSpaceShip.speed || 'ทำงานได้รวดเร็ว จัดการและบริหารเวลางานได้เป็นอย่างดี ปิดงานได้เร็ว'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    DURABILITY : <span className='item-skil-body-in'> {detailSpaceShip.durabilty || 'เป็นทีมที่ทำงานได้อย่างเรื่อยๆ ทำงานได้ด'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    PRECISION :  <span className='item-skil-body-in'>{detailSpaceShip.precision || 'ความแม่นยำและชำนาญทุกรอบด้านของทีม'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    POTENTIAL :  <span className='item-skil-body-in'>{detailSpaceShip.potential || 'มีศักยภาพที่รอบคอบในการวางแผนทุกงาน นำตัวเองได้และสามารถนำทีมได้'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{/*  border: '1px solid red'  */ }}>
                                  <b className='text-skil-body'>
                                    TECHNICAL : <span className='item-skil-body-in'>{detailSpaceShip.technical || 'ยานลำนี้มีทักษะเฉพาะตัวอย่างอัลกอริทึมที่ซับซ้อน ความปลอดภัยบนเว็บ'} </span>
                                  </b>
                                </div>



                              </div>

                            </div>






                          </> : <></>}
                      </div>



                      <dov className="row box-right-btn">
                        <div className='btn-buy'>
                          <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }}>
                            <div className="pi-infoBoxBtnCreat">
                              <div className="pi-infoBoxBtnCreatCen">
                                <label
                                  className="pi-infoBoxBtnCreatIn text-4"
                                  onClick={() => {
                                    toggle_modal_confrimModal()
                                  }}
                                >
                                  BUY
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </dov>

                    </div>

                  </> : <>

                    {/*  <div className='ml-3 csheck col-5' style={{ width: '100%',  display: 'grid', alignItems: 'end', justifyContent: 'end' }}>
                  <dov className="row box-right-btn">
                    <div className='btn-buy' style={{ marginTop: '13px' }}>
                      <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }}>
                        <div className="pi-infoBoxBtnCreat">
                          <div className="pi-infoBoxBtnCreatCen">
                            <label
                              className="pi-infoBoxBtnCreatIn text-4"
                              onClick={() => {
                                toggle_modal_confrimModal()
                              }}
                            >
                              BUY
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dov>
                </div> */}
                  </>}
                </div>
              </> : <>
                <div className='col-3 mt-2'>
                  <div className='crad-selected'>
                    <div className='cradSpaceshipImgBox-1'>
                      {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <img src={`${tempSave.smo_img_path/* Configs.TTT_Game_Service_IMG+tempSave.smo_img_path */}`}
                        className='cradSpaceshipImg-1' style={{ objectFit: 'contain', border: `4px solid ${tempSave.smo_color}`, top: 'auto', width: 'unset' }}></img> :
                        <div className='backGroup'>
                        </div>}
                      {/*  <svg height="320" width="220" style={{ marginLeft: '-203px', zIndex: 99 }}>
                  <line x1="8" y1="15" x2="8" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="7" y1="1" x2="31" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />

                  <line x1="220" y1="50" x2="220" y2="90" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="220" y1="89" x2="235" y2="105" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="234.5" y1="104.5 " x2="234.5" y2="155" style={{ stroke: ` ${tempSave.smo_color} `, strokeWidth: '2' }} />

                  <line x1="210" y1="290 " x2="210" y2="309" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="190" y1="308" x2="210" y2="308" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />

                  <line x1="5" y1="276 " x2="5" y2="200" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="5" y1="200 " x2="10" y2="190" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                  <line x1="10" y1="190 " x2="10" y2="150" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }} />
                </svg> */}
                      <svg height="320" width="220" style={{ marginLeft: '-203px', zIndex: 99 }}>
                        <line x1="8" y1="15" x2="8" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="7" y1="1" x2="31" y2="1" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="50" x2="190" y2="90" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="89" x2="210" y2="105" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="210" y1="104.5 " x2="210" y2="155" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="210" y1="290 " x2="210" y2="309" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="190" y1="308" x2="210" y2="308" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="5" y1="276 " x2="5" y2="200" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="5" y1="200 " x2="10" y2="190" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                        <line x1="10" y1="190 " x2="10" y2="150" style={{ stroke: `${tempSave.smo_color}`, strokeWidth: '2' }}></line>
                      </svg>
                      <div className='cardSpaceshipImgLine-1' style={{ backgroundColor: `${tempSave.smo_color}` }}></div>
                    </div>
                    <div className={`${tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? 'cardSpaceshipHover-1' : 'cardSpaceshipHoverFack-1'}`}

                    ></div>

                  </div>
                </div>
                <div className='col-5' style={{ margin: 0, top: 0, padding: 0 }}>
                  <div className='csheck' style={{ height: 'fitContent', /* border: '1px solid #fff', */ padding: '0px', margin: '0px' }}>
                    <div className='row mt-4' style={{/*  border:'1px solid red', */width: '100%', padding: '0px', margin: '0px' }}>
                      <div className='col-7' style={{ /* border: '1px solid blue', */ padding: '0px' }}>
                        <div className="pi-infoText" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` /* ,border:'1px solid red' */, width: '100%', margin: '0px' }} >Spaceship Name :</div>
                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`/* ,border:'1px solid red' */, width: '100%' }}>
                          <div className="pi-infoBoxTextCreat">
                            <div className="pi-infoBoxTextCreatCen">
                              <input
                                className="pi-infoBoxTextCreatIn"
                                type='text'
                                value={tempSave.sp_name}
                                onChange={(e) => {
                                  setTempSave({
                                    ...tempSave,
                                    sp_name: e.target.value
                                  })
                                }}
                              >
                              </input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-5' style={{ /* border: '1px solid red', */ padding: '0px', margin: '0px 0px 0px 0px' }}>
                        <div className="pi-infoText" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`, marginTop: '2px', width: '100%' }} >Size :</div>

                        <div className="pi-infoBoxTextShadow">
                          <div className="pi-infoBoxTextCreaSelectt" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)`, zIndex: 1, marginTop: '-4px' }}>
                            <div className="pi-infoBoxTextCreatCenSelect" style={{ width: '100%' }}>
                              <div className="pi-infoBoxTextCreatSelect" style={{ width: '100%' }}>


                              </div>

                            </div>
                          </div>
                          {isOpenCreate && (

                            <Select
                              options={optintSize}
                              /* style={{zIndex: 999999}} */
                              className='selectNewSpace'
                              value={optintSize.filter((e) => { return e.value === tempSave.smo_size })}
                              onChange={(e) => {
                                setTempSave({
                                  ...tempSave,
                                  smo_size: e.value,
                                  smo_id: '',
                                  smo_img_name: '',
                                  smo_img_path: '',
                                  smo_sapphire: '',
                                  smo_color: '',
                                })
                                GetAccesstokenToken((callback) => {
                                  if (callback) {
                                    GetSpaceshipToBuy(e.value)
                                  }
                                });

                              }}
                            />

                          )}


                        </div>
                      </div>
                    </div>
                    <div className='row' style={{ /* border:'1px solid red',  */padding: '0px', margin: '0px' }}>
                      <div className='col-auto' style={{ width: '100%', padding: '0px' }} >
                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }} >
                          <div className="pi-infoText">Description :</div>
                          <textarea className="project-infoBoxTextIn-arex"
                            id="input_arex_description"
                            type='text'
                            style={{ height: ' 142px', overflow: 'auto' }}
                            value={tempSave.sp_description}
                            onChange={(e) => {
                              setTempSave({
                                ...tempSave,
                                sp_description: e.target.value
                              })
                            }}
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 mt-3' style={{ margin: '0px', padding: '3px' }}>
                        <div className="pi-infoText" style={{ fontSize: '14px' }}>Spaceship Life  :
                          &nbsp; {moment(myTempValue.new_date).format('DD MMM YYYY')}  - {moment(tempSave.sp_life).format('DD MMM YYYY')}&nbsp;(3 Years)
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className='col-3' style={{ margin: 0, padding: '0 10px' }}>
                  {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <>
                    <div className='arearight' >
                      <div className='row box-right-herd' >

                        <div className={`tab-story${isActiveTab.is_tab === 'tab-story' ? '-active' : ''}`}

                          onClick={() => {
                            if (tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '') {
                              setIsActiveTab({ ...isActiveTab, is_tab: 'tab-story' })
                              isActiveTab.is_tab = 'tab-story'
                              /*   {tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '' ? <>  */
                            }


                          }}>
                          <b className='text-tab-story' > STORY OF SPACESHIP </b>
                        </div>
                        <div className={`tab-skil${isActiveTab.is_tab === 'tab-skil' ? '-active' : ''}`}

                          onClick={() => {
                            if (tempSave.smo_img_path !== undefined && tempSave.smo_img_path !== null && tempSave.smo_img_path !== '') {
                              setIsActiveTab({ ...isActiveTab, is_tab: 'tab-skil' })
                              isActiveTab.is_tab = 'tab-skil'
                            }

                          }}>
                          <b className='text-tab-skil'> SPACESHIP SKILLS </b>
                        </div>



                      </div>
                      <div className='row box-right-body'>
                        {isActiveTab.is_tab === 'tab-story' ? <>
                          <div className='area-story-head'>
                            <b className='text-story-head '>
                              {detailSpaceShip.space_name || ''}
                            </b>
                          </div>
                          <div className='area-story-body-box'>
                            <p style={{ color: '#FFFFFF' }}> {detailSpaceShip.space_descrip || ''} </p>
                          </div>
                        </> : <> </>}


                        {isActiveTab.is_tab === 'tab-skil' ?
                          <>
                            <div className='row' style={{ padding: '0ox', margin: '0px', flexWrap: 'nowrap' /* ,border:'1px solid red' */ }}>
                              <img className='area-img-skil mt-4'
                                src={detailSpaceShip.smo_img_path_status}
                              ></img>
                              {/*    <svg xmlns="http://www.w3.org/2000/svg" width={`${detailSpaceShip.width_svg}`} height={`${detailSpaceShip.height_svg}`} viewBox={`${detailSpaceShip.viewBox}`} fill="none" className='item-skil-img'>
                          <path d= {`${detailSpaceShip.svg_d}`} fill="#FF7777" fill-opacity="0.65" />
                        </svg> */}

                              {/*   <img src={detailSpaceShip.smo_img_path_status || skil_1img } className='item-skil-img' /> */}
                              <div className='item-skil-body mt-3' style={{ /* marginLeft: '34px' */ }}>
                                <div className='row ml-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-story-head '>
                                    {detailSpaceShip.space_name || 'ACSASCASAS'}
                                  </b>
                                </div>
                                <div className='row ml-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    POWER : <span className='item-skil-body-in'>{detailSpaceShip.power || 'ยานอวกาศที่ไม่ได้จริงจังกับอะไรมากนัก แต่เมื่อถึงเวลาต้องออกงานยานลำนี้จะจริงจังโดยทันที จัดสรรเวลาด'}</span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    SPEED :  <span className='item-skil-body-in'> {detailSpaceShip.speed || 'ทำงานได้รวดเร็ว จัดการและบริหารเวลางานได้เป็นอย่างดี ปิดงานได้เร็ว'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    DURABILITY : <span className='item-skil-body-in'> {detailSpaceShip.durabilty || 'เป็นทีมที่ทำงานได้อย่างเรื่อยๆ ทำงานได้ด'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    PRECISION :  <span className='item-skil-body-in'>{detailSpaceShip.precision || 'ความแม่นยำและชำนาญทุกรอบด้านของทีม'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{ /* border: '1px solid red' */ }}>
                                  <b className='text-skil-body'>
                                    POTENTIAL :  <span className='item-skil-body-in'>{detailSpaceShip.potential || 'มีศักยภาพที่รอบคอบในการวางแผนทุกงาน นำตัวเองได้และสามารถนำทีมได้'} </span>
                                  </b>
                                </div>
                                <div className='row ml-1 mt-1' style={{/*  border: '1px solid red'  */ }}>
                                  <b className='text-skil-body'>
                                    TECHNICAL : <span className='item-skil-body-in'>{detailSpaceShip.technical || 'ยานลำนี้มีทักษะเฉพาะตัวอย่างอัลกอริทึมที่ซับซ้อน ความปลอดภัยบนเว็บ'} </span>
                                  </b>
                                </div>



                              </div>

                            </div>






                          </> : <></>}
                      </div>



                      <dov className="row box-right-btn">
                        <div className='btn-buy'>
                          <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }}>
                            <div className="pi-infoBoxBtnCreat">
                              <div className="pi-infoBoxBtnCreatCen">
                                <label
                                  className="pi-infoBoxBtnCreatIn text-4"
                                  onClick={() => {
                                    toggle_modal_confrimModal()
                                  }}
                                >
                                  BUY
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </dov>

                    </div>

                  </> : <>

                    {/*  <div className='ml-3 csheck col-5' style={{ width: '100%',  display: 'grid', alignItems: 'end', justifyContent: 'end' }}>
                  <dov className="row box-right-btn">
                    <div className='btn-buy' style={{ marginTop: '13px' }}>
                      <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }}>
                        <div className="pi-infoBoxBtnCreat">
                          <div className="pi-infoBoxBtnCreatCen">
                            <label
                              className="pi-infoBoxBtnCreatIn text-4"
                              onClick={() => {
                                toggle_modal_confrimModal()
                              }}
                            >
                              BUY
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </dov>
                </div> */}
                  </>}
                </div>
              </>}











          </div>
          <div className="text-5" style={{ margin: '0px 20px', padding: '20px' }}>select your spaceship</div>
          <div className='body-miden-2'>

            <div className='spaceNewBoxIn' style={{ height: 'fit-content', width: '100%' }}>
              <Slider className="latest-games-active slick" {...settings}>
                {spaceData.map((e, index) => {
                  /*  console.log('TEST SHOW', e); */
                  return (
                    <>
                      <div className='cradSpaceshipImgBox' >

                        <img src={e.smo_img_path !== undefined && e.smo_img_path !== null ? `${e.smo_img_path/* Configs.TTT_Game_Service_IMG+e.smo_img_path */}` : spaceimg} className='cradSpaceshipImg' style={{ objectFit: 'cover', border: `4px solid ${e.smo_color}` }}></img>
                        <svg height="292" width="230" style={{ position: 'absolute', marginLeft: '-15px' }}>
                          <line x1="16" y1="15" x2="16" y2="1" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="16" y1="1" x2="31" y2="1" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />

                          <line x1="213" y1="50" x2="213" y2="90" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="213" y1="90 " x2="229" y2="105" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="229" y1="105 " x2="229" y2="155" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />

                          <line x1="229" y1="260 " x2="229" y2="286" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="229" y1="285 " x2="206" y2="285" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />

                          <line x1="8" y1="275 " x2="8" y2="200" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="8" y1="200 " x2="16" y2="190" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                          <line x1="16" y1="190 " x2="16" y2="150" style={{ stroke: `${e.smo_color}`, strokeWidth: '2' }} />
                        </svg>

                        <div className='labelsapphire'>

                          <div className='labelthis' style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }} >
                            <div className='row'>
                              <img src={icon_sapphire} style={{ height: '18px', marginLeft: '2px', marginTop: '1px' }} />&nbsp;
                              {numberWithCommas(e.smo_sapphire || 0)}
                            </div>

                          </div>


                        </div>
                        <div className='cardSpaceshipImgLine' style={{ backgroundColor: `${e.smo_color}` }}></div>
                      </div>
                      <div className='cardSpaceshipHover'
                        onClick={() => {
                          setValueInStage(e);
                        }}
                      ></div>
                    </>
                  )
                })}
              </Slider>

            </div>
          </div>

        </div>


        <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox'>
              <div className='alertKickBoxIn'>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead'>
                    ! ERROR !
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    WARNING
                  </div>
                </div>
                {/*  <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    THIS SPACESHIP HAS BEEN KICK-OFF PROJECT
                  </div>
                </div> */}
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickTextSec'>
                    {errorMasgerList.massger.map((e) => {

                      return <div> {e.message} </div>

                    })}
                  </div>
                </div>
                <div className='row alertKickBoxButton mt-4'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                    setErrorMasgerList({
                      ...errorMasgerList,
                      isError: false,
                      massger: []
                    })
                  }}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox'>
              <div className='alertKickBoxIn'>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'orange' }}>
                    ! warning !
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    WARNING
                  </div>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    Do you want to confirm your purchase?
                  </div>
                </div>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickTextSec'>
                    You won't be able to revert this!
                  </div>
                </div>
                <div className='row alertKickBoxButton' >
                  <button className='alertKickConfirm mr-2' onClick={() => {
                    wantToBuy()
                  }}>YES</button>

                  <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                    toggle_modal_confrimModal()
                  }}>NO</button>


                </div>

              </div>
            </div>
          </div>
        </div>



      </div>
    </div >
  )
}

export default CreateSpaceship;
