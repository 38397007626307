import React, { useState, useEffect, useRef } from 'react';
import '../payment/detailPayment.css'
import moment from "moment";
import payIcon from '../../../../assets/img/icon/Group11.png'

const DetailPayment = ({ isOpen, check }) => {

    const [payData, setPayData] = useState([
        {
            payType: "entr",
            payDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
            payCount: 100000.00,
            payCountTo: 0
        },
        {
            payType: "with",
            payDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
            payCount: -100000.00,
            payCountTo: 0
        },
        {
            payType: "tran",
            payDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
            payCount: -10000.00,
            payCountTo: 1000000
        },
    ])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    return (
        (check === 'PAYMENT' ? (<>
            <div className="detailPayment" style={{ visibility: `${isOpen === true && check === 'PAYMENT' ? 'visible' : 'hidden'}` }}>
                {payData.map((e, index) => {
                    
                    let name = 'ENTRY MONEY';
                    let type = 'ENTRY MONEY'
                    if (e.payType === "entr") {
                        name = "ENTRY MONEY"
                        type = 'ENTRY MONEY'
                    } else if (e.payType === "with") {
                        name = "Withdrawal List"
                        type = "Withdrawal"
                    } else {
                        name = "Transfer Sapphire"
                        type = "Transfer Sapphire"
                    }
                    
                    return (
                        <div className="payBox">
                            <div className="row setPayRow">
                                <div className='col-9 setPayCol'>
                                    <div className='payCodeHead'>PAYMENT TRANSACTION</div>
                                    <div className='payNameBox'>
                                        <div className='payIcon' style={{ backgroundImage: `url(${payIcon})` }}></div>
                                        <div className='payName'>{name}</div>
                                    </div>
                                    <div className='payDateBox'>
                                        <div className='payDate'>DATE : {moment(e.payDate).format("DD MMM YYYY")} </div>
                                        <div className='payTime'>TIME : {moment(e.payDate).format("h.m a")}</div>
                                    </div>
                                </div>
                                <div className='col-3 setPayCol'>
                                    <div className='payTypeText' style={{marginTop: `${e.payType != 'tran' ? 22.5 : 5}px`}}>{type}</div>
                                    <div className='payBudget' style={{color:`${e.payCount > 0 ? '#1FFF1B' : '#ff3939'}`}}>{e.payCount > 0 ? '+' : ''}{numberWithCommas(parseFloat(e.payCount || 0).toFixed(2))} BAHT {e.payType != 'tran' ? '' : '->'}</div>
                                    <div className='payTran' style={{display: `${e.payType != 'tran' ? 'none' : 'hide'}`}}>{e.payCountTo > 0 ? '+' : ''}{numberWithCommas(parseFloat(e.payCountTo || 0).toFixed(2))} SAPPHIRE</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>) : (<></>))
    )
}

export default DetailPayment