import React, { useState, useEffect } from "react";
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import Card from "../../../../components/customElement/cardshot";
import Cardhighlight from "../../../../components/customElement/cardhighlight";
import Slider from 'react-slick';
import moment from 'moment';
import ModalNew from '../../../Modalpopup/newsModalPopUp'
import { Link } from 'react-router-dom';
import Configs from "../../../../config";

function PagenewMain({ data, callback }) {
  const [newInfos, setNewsInfos] = useState([])
  const [isOpen, setisOpen] = useState(false)
  const [test, settest] = useState({ test: '' })
  const [tempModal, setTempModal] = useState({
    headType: '',
    titleName: '',
    datas: '',
    dateValue: '',
    link: '',
    language: '',
    imgpath: '',
    new_id: '',
  })
  /*   function PrevArrow(props) {
      const { className, onClick } = props;
      return (
        <button style={{position:'relative',top:'0px',left:'0px',backgroundColor:'red'}} type='button' className={className} onClick={onClick}><i className="fa fa-angle-right"></i></button>
      );
    }
    function NextArrow(props) {
      const { className, onClick } = props;
      return (
        <button style={{position:'relative',top:'0px',left:'0px',backgroundColor:'red',display:'c'}} type='button' className={className} onClick={onClick}><i className="fa fa-angle-left"></i></button>
      );
    } */


  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} arrows`}
        style={{ ...style, display: "block", background: "transparent", position: 'absolute', top: '-65px', right: '4%', width: '40px', height: '40px', borderRadius: '10px 0 0 10px', border: '2px solide white', textAlign: 'center', padding: '8px' }}
        onClick={onClick}
      ><i className="fa fa-angle-left" /> </div>

    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={`${className} arrows`}
        style={{ ...style, display: "block", background: "transparent", position: 'absolute', top: '-65px', right: '0%', width: '40px', height: '40px', borderRadius: '0 10px 10px 0', border: '2px solide white', textAlign: 'center', padding: '8px' }}
        onClick={onClick}
      ><i className="fa fa-angle-right" /> </div>

    );
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    swipeToSlide: true,
    autoplaySpeed: 4000,
    speed: 1000,
    autoplay: true,
    nav: true,

    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,

    beforeChange: function (currentSlide, nextSlide) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      // console.log("after change", currentSlide);
    }


  };


  function OpenModal(value) {
    /*    console.log('valuevalue',value); */
    if (callback !== undefined) {
      callback(true, value)
    } else {
      sessionStorage.setItem('News_id', value.news_id)

      window.location.href = '/viewNews'

    }

    settest({ ...test, test: '' })
    test.test = '';
  }

  function onClose(value) {

    setisOpen(false)
    settest({ ...test, test: '' })
    test.test = '';
  }
  const [news_n_fake,setNews_n_fake] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      /*      console.log('datadatadatadata', data); */
      setNewsInfos(data || [])
      document.querySelector('html').classList.remove('modal-opened');


      let news_n = data;
 
      
     
      let arr_n = [];
      if(news_n.length < 4){
        
          for(let i_n = news_n.length; i_n < 4; i_n++){
            arr_n.push(i_n);
          }
          // console.log('arr_n',arr_n)
          setNews_n_fake(arr_n);
      }
    }

  }, [data])


  /*   function callback (params) {
      
    }
   */

  return (
    <>
      <div className="row"  >
   
        <div className="cardscrolly" style={{ width: '100%' }}>
       {/*    <div className="readmores">
            READMORE
          </div> */}
          <Slider className="latest-games-active slick" {...settings}>
            {/*  {newInfos.length > 0 ?
            newInfos.map((e, index)=>{
              let typeNews = '';
              let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
              if (e.kn_name === "PROMOTION") {
                typeNews = 'p'
              } else if (e.kn_name === "ACTIVITY & NEWS") {
                typeNews = 'n'
              } else if (e.kn_name === "TTT SPACESHIP STORY") {
                typeNews = 's'
              }
              return (
                <>
                 <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} bgevent={e.ul_file_path !== undefined && e.ul_file_path !== null ? `${Configs.TTT_Game_Service_IMG}${e.ul_file_path}` : imgDEfule} />
                </>
              )
            })
            : (<></>)
          } */}
            {newInfos.length > 0 &&
              newInfos.map((e, inde) => {
                /*        console.log('e', e.news_title); */
                let typeNews = '';
                let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
                if (e.kn_name === "PROMOTION") {
                  typeNews = 'p'
                } else if (e.kn_name === "ACTIVITY & NEWS") {
                  typeNews = 'n'
                } else if (e.kn_name === "TTT SPACESHIP STORY") {
                  typeNews = 's'
                }
                console.log('EDIT__',`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`);
                return (
                  <>
                  <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} bgevent={e.path_file !== undefined && e.path_file !== null ? e.path_file : imgDEfule}
                    callback={OpenModal}
                    newsid={e}
                  />
                  
                  </>)
              })}
             
            {news_n_fake.map((e)=>{
                return (<>
                <Card style= {{visibility:'hidden'}}tagevent={'s'} nameevent={'Comming Soon'} date={moment(new Date()).format('DD MMM YYYY')} bgevent={'../../../../assets/img/bg/imgbdsport.jpg'}   />
                </>)
              })}
          </Slider>


        </div>
      </div>

      {/*  <ModalNew
        isOpen={isOpen}
        onClose={onClose}
        headType={tempModal.headType}
        titleName={tempModal.titleName}
        datas={tempModal.datas}
        dateValue={tempModal.dateValue}
        link={tempModal.link}
        imgpath={tempModal.imgpath}
    

      /> */}
    </>
  );
}

export default PagenewMain;
