import React from "react";
import HeaderTTT from "../components/header/HeaderTTT";
import "../assets/css/detailClass.css";
import Footer from "../components/footer/FooterTTT";

function DetailClass() {
  return (
    <>
      <img
        className="bg-detail-class"
        src="../assets/img/bg/area_bg02.jpg"
      ></img>
      <HeaderTTT />
      <div className="container">
        <div className="row detail-con">
        <h2 className="col-12 mt-5">Detail Class</h2>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 mb-5">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque
            maiores tenetur, ullam facilis a iusto aperiam at ut obcaecati
            laboriosam accusamus sunt accusantium facere quas corporis
            veritatis, quis fugit. Possimus!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque
            maiores tenetur, ullam facilis a iusto aperiam at ut obcaecati
            laboriosam accusamus sunt accusantium facere quas corporis
            veritatis, quis fugit. Possimus!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque
            maiores tenetur, ullam facilis a iusto aperiam at ut obcaecati
            laboriosam accusamus sunt accusantium facere quas corporis
            veritatis, quis fugit. Possimus!
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 row justify-content-center">
            <div className="featured-game-thumb detail-card ">
              <img src="assets/img/images/s_featured_game_thumb02.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default DetailClass;
