import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal } from 'antd';

import axios from 'axios';
import moment from 'moment';
import dayjs from "dayjs";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Configs from "../../../config";
import IconReport from "../../../assets/img/IconStage4/reportSandMail.svg"
import ModalSendMail from "./component/SendreportMail.jsx"
import ModalViewsList from "./component/ViewListReportMail.jsx"
import './modalSpaceShip.css'
import ModalConfirm from './ModalConfirm.jsx'
import { generateUUID } from 'three/src/math/MathUtils.js';
const { RangePicker } = DatePicker;
const { confirm } = Modal;



function ModalFilterDisplayStage({
  isOpen,
  toggle_modal_filter_stage,
  project_id,
  getProjectDetail,
  ModuleDetails,
  projectDetail,
  status,
  CalPosition,
  setID,
  toggle_modal_status,
  actual_progress,
  switModeShowDisplay

}) {




  const [displayMode, setDisplayMode] = useState(false)
  const [stageMonitoringView, setStageMonitoringView] = useState([]);
  const [stageMonitoringDataReport, setStageMonitoringDataReport] = useState([]);

  const [openModalSendMail, setOpenModalSendMail] = useState(false);
  const togle_modal_send_mail = () => setOpenModalSendMail(!openModalSendMail)

  const [openModalViewListReport, setOpenModalViewListReport] = useState(false);
  const togle_modal_ViewListReport = () => setOpenModalViewListReport(!openModalViewListReport)

  const [valueSave, setValueSave] = useState({
    lsent_project_id: '',
    lsent_spaceship_id: ''
  })


  const [statusName, setStatusName] = useState('');
  const [statusID, setStatusID] = useState('');
  const [statusID2, setStatusID2] = useState('');
  const [displayProgress, setDisplayProgress] = useState({
    actual: 0,
    plan: 0
  })

  useEffect(() => {
    /*  setErrorMessage(''); */
    /*    setModuleDetail({...ModuleDetail,module_name:''});
       ModuleDetail.module_name= ''; */
    /*    console.log('ModuleDetails',ModuleDetails)
       const temp =ModuleDetails || {}
       setModuleDetail(temp); */
    if (isOpen == true) {
      console.log('projectDetail', projectDetail)
      let getUserPermisstionMode = sessionStorage.getItem('getUserMode')
      /*  console.log('cream',getUserPermisstionMode); */
      setDisplayMode(getUserPermisstionMode)

      let status_id = '';
      let status_id2 = '';
      if (status == 'Done') {
        status_id = '6e6f2408-6a06-4f93-8f0a-aa385cfc4188';
        setStatusID(status_id);
        setStatusID2(null);
        setStatusName('DONE');
      } else if (status == 'In process') {
        status_id = 'f00e63f2-ab27-4828-9d7a-f9316c28703e';
        setStatusID(status_id);
        setStatusID2(null);
        setStatusName('DOING');
      } else if (status == 'Waiting') {
        status_id = '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8';
        status_id2 = 'f00e63f2-ab27-4828-9d7a-f9316c28703e';
        setStatusID2(status_id2);
        setStatusID(status_id);
        setStatusName('TO DO');
      }
      console.log('item_1', projectDetail);
      let dataTemp = [];
      for (let item of projectDetail.moduleList) {
    
        for (let item2 of item.module_menu_list) {
          let check = item2.process_list.filter((e) => { return e.ps_id === status_id || e.ps_id === status_id2  });
          if (check.length > 0) {
            dataTemp.push(
              {
                /*        project_id: item.project_id,
                       project_spaceship_id: item.project_spaceship_id, */
                module_name: item.module_name,
                module_id: item.module_id,
                menu_name: item2.menu_name,
                menu_id: item2.menu_id,
                process_list: item2.process_list || []
              }
            )
          }
        }
      }
      /*    console.log('oneTest',projectDetail) */
      var dataSendMailreport = {
        menulist: dataTemp || [],
        project_id: projectDetail.project_id,
        project_spaceship_id: projectDetail.project_spaceship_id || null,
      }
      setStageMonitoringView(dataTemp);
      setStageMonitoringDataReport(dataSendMailreport)
      setDisplayProgress({
        ...displayProgress,
        actual: projectDetail.project_progress,
        plan: parseFloat((projectDetail.done_count + projectDetail.doing_count) / projectDetail.all_todo_count * 100).toFixed(2),
      })
      console.log('plan', parseFloat((projectDetail.done_count + projectDetail.doing_count) / projectDetail.all_todo_count * 100).toFixed(2))
    }else{
      setDisplayMode(false)
    }


  
    console.log('เปิดเเล้ว',switModeShowDisplay);
    


  }, [isOpen && projectDetail])













  return (

    <>

      {/*     {isOpen && (
    <> */}
      <ConfigProvider
        theme={{
          token: {

            /*     colorPrimary: '#85BFFF', */
            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue',//สีตอน Active ใน Select
            colorBgBase: '#778899',//พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder


            colorFillTertiary: '#000',// สีตอน hover ใน Select


            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker




            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */


          }
        }}
      >
        <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={() => {

          if (openModalSendMail !== true) {
            toggle_modal_filter_stage();
          }


        }}>      </div>
        <div className={`detailBoxModuleMenu ${isOpen ? 'opened' : 'closed'}`} style={{/* top:`10%`, */
         left: `${switModeShowDisplay === true ? '0%' : '50%'}`,
         position: `${switModeShowDisplay === true ? 'absolute':'fixed'}`, 
         transform: `${switModeShowDisplay === true ? '':'translate(-50%, -14%)'}`,
         width: `${switModeShowDisplay === true ? '100%':'80%'}` , 
         height: `${switModeShowDisplay === true ? '83vh':'80%'}`,
         top: `${switModeShowDisplay === true ? '4%':''}`,
         }}

        >
          <div style={{ width: '100%', height: '100%', margin: 0, padding: '20px', display: 'flex', flexDirection: 'column' }}>

            <div className='row' style={{ width: '100%', margin: 0, padding: 0 }}>
              <div className={statusName === 'DOING' ? `col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3` : `col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6`} /* style={{border:'1px solid red'}} */><h4>STAGE MONITORING {`"${statusName}"`}</h4> </div>
              {statusName === 'DOING' ? <>
                <div className='col-12 col-sm-12 col-md-1 col-lg-2 col-xl-3' style={{padding: '0px',display:'flex',margin:'-4px', }}> <div className='btn-sandmail' style={{height:'max-content'}} onClick={() => { 
                  if(displayMode == false || displayMode == 'false'){
                    togle_modal_send_mail() 
                  }
                  
                  }}> <img src={IconReport} /> REPORT </div> <div className='' style={{padding:'10px 5px 0px 17px',textDecoration:'underline',color:'#FFF414',fontFamily:'Oxanium',fontSize:'14px',cursor:'pointer'}} onClick={()=>{togle_modal_ViewListReport()}}> REPORT HISTORY </div> </div>
              </> : <></>}

              <div className='col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3' style={{ width: '100%' }}><h4 style={{ width: '100%', textAlign: 'end' }}>ACTUAL : {displayProgress.actual}%</h4></div>
              <div className='col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3' style={{ width: '100%' }}><h4 style={{ width: '100%', textAlign: 'end' }}>TARGET : {displayProgress.plan}%{` (+${parseFloat(displayProgress.plan - displayProgress.actual).toFixed(2)}%)`}</h4></div>
            </div>

            <div style={{ height: '15px', borderTop: '1px solid #FFF' }}>
            </div>

            <div className='table-responsive' style={{ height: '35px' }}>
              <div className="col-12 table-responsive">
                <table>
                  <tr>

                    <td style={{ minWidth: '255px', textAlign: 'left' }}>{`[MODULE] >> [MENU]`}</td>
                    {projectDetail.processList && projectDetail.processList.map((e) => {
                      return (<>
                        <td style={{ width: '10%', textAlign: 'center' }}>
                          <div style={{ height: '10px', textAlign: 'center', fontSize: '9px', color: ' rgb(255, 255, 255,0.5)' }}>{e.process_name}</div>
                        </td>
                      </>)
                    })}


                  </tr>
                </table>
              </div>
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto' }}>

              <div className='table-responsive' style={{ height: '97%', marginTop: '1%' }}>



                <div className="col-12 table-responsive">

                  <table>
                    {stageMonitoringView.length > 0 && stageMonitoringView.map((e2) => {
                      if (/* e2.menu_id && */ e2.menu_name) {
                        return (<>
                          <tr>
                            <td style={{ height: '30px', whiteSpace: 'nowrap' }}>  <div className="row mb-2">{/* <span> */}
                              <Tooltip title={e2.menu_name}>
                                <p style={{ maxWidth: '290px', fontSize: '14px', color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0 0 0' }}> {e2.module_name}{' >> '}{e2.menu_name}</p>
                              </Tooltip>
                              {/* <p style={{ margin: '0 0 0' }}>
                                {" "}

                              </p> */}
                              {/* </span> */}</div></td>
                            {e2.process_list && e2.process_list.map((e, i) => {
                              let clip = "polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)";
                              if (i == 0) {
                                clip = "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)";
                              } else
                                if (i + 1 === projectDetail.processList.length) {
                                  clip = "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)";
                                }

                              if (statusID == e.ps_id || statusID2 == e.ps_id) {
                                return (<>
                                  <td style={{ width: '10%', height: '30px', textAlign: 'center' }}>
                                    <button style={{ clipPath: clip, width: '54px', height: '12px', background: 'rgba(255, 255, 255,100%)', border: '0px' }}>
                                      <button onClick={(ee) => {

                                        toggle_modal_status();
                                        const element = document.getElementById("moduleMenuDiv");
                                        console.log(element.offsetTop)
                                        let xx = ee.clientX/*  - (element.offsetLeft) */;
                                        let yy = ee.clientY /* - (element.offsetTop) */;


                                        CalPosition(xx, yy, 'stage')
                                        setID(e.process_id, e2.menu_id)
                                      }} style={{ clipPath: clip, width: '50px', height: '10px', background: e.ps_color, border: '0px', cursor: 'pointer' }}></button>
                                    </button>
                                  </td>
                                </>)

                              } else {
                                return (<>
                                  <td style={{ width: '10%', height: '30px', textAlign: 'center' }}>
                                    <button style={{ clipPath: clip, width: '50px', height: '10px', background: e.ps_color, border: '0px' }}>
                                      <button onClick={(ee) => {

                                        toggle_modal_status();
                                        const element = document.getElementById("moduleMenuDiv");
                                        console.log(element.offsetTop)
                                        let xx = ee.clientX/*  - (element.offsetLeft) */;
                                        let yy = ee.clientY /* - (element.offsetTop) */;

                                        CalPosition(xx, yy, 'stage')
                                        setID(e.process_id, e2.menu_id)



                                      }} style={{ clipPath: clip, width: '50px', height: '10px', background: e.ps_color, border: '0px', cursor: 'pointer' }}></button>
                                    </button>
                                  </td>
                                </>)
                              }

                            })}


                          </tr>
                        </>)
                      }
                    })}



                  </table>

                </div>


              </div>

            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button className=' btn-outline-danger' style={{ padding: '5px' }} onClick={() => { toggle_modal_filter_stage(); }}> Cancel </button>
            </div>





          </div>
          <br /><br />

          {/*     </>)} */}

          <ModalSendMail
            isOpen={openModalSendMail}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_send_mail() }}
          />

          <ModalViewsList
            isOpen={openModalViewListReport}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_ViewListReport() }}
          />
        </div>



      </ConfigProvider>



      {/*     </>
    
  )} */}
    </>
  )


}
export default ModalFilterDisplayStage;


