import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import IMGQRCODE from "../../../assets/img/qrCode/QRLINETTTADMIN.png";
import ModelAlertRegis from "../../AlertMassge/AlertRegis";
import $ from 'jquery';
import './LandingpageTTTMobile.css';
import Bg from '../../../assets/img/slider/imgmian.jpg'
import axios from 'axios';
import Configs from "../../../config";


const LandingpageTTTMobile = (params) => {
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  
  const [isRegister, setIsRegister] = useState(false)
  const [test, settest] = useState({ test: '' }
  )
  async function getConfigMasterRegis() {
    let code = 'IsRegis';
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/configMgt/getConfigByCode/${code}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log('เช็คหน่อย', response.data);
        if (response.data.length > 0) {
          let is_ = response.data[0].gc_value == "true" ? true : false;
          setIsRegister(is_);
          settest({ ...test, test: '' });
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getConfigMasterRegis();
  }, [])

  return (
    <>

      <div className='mob-LandingPageTTTBg' style={{ backgroundImage: `url(${Bg})` }}>
        <div style={{ width: '100%', height: '100%', background: 'rgba(0,0,0,0.5)' }}>
          <div className="slider-content" style={{ position: 'relative', top: '17.5%', left: '7.5%', width: 'fit-content' }}>
            <Fade left delay={1800}>
              <div style={{ height: '20px', width: '5px', backgroundColor: '#0047FF', whiteSpace: 'nowrap' }}><h5 style={{ marginLeft: '13px' }}>TTT BROTHER</h5> </div>
            </Fade>
            <Fade left delay={500}>
              <h5 title="TTT SPACESHIP" style={{ fontSize: '35px', fontWeight: 'bold', width: 'fit-content', marginTop: '25px' }} className='textanime1'>TTT SPACESHIP</h5>
            </Fade>
            <Fade left delay={800}>
              <h5 style={{ fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', marginBottom: '20px', marginTop: '10px', whiteSpace: 'nowrap', width: 'fit-content' }}>HOW THE IT PROJECTS
                <div class="neon" style={{ marginTop: '0px' }}> SIMPLIFYING</div>
              </h5>
            </Fade>



          </div>
          <div style={{ width: '85%', fontSize: '10px', fontWeight: 'normal', position: 'relative', top: '17.5%', left: '7.5%' }}>Lorem ipsum dolor sit amet consectetur. Id morbi in suspendisse ante cursus magna. Porta fermentum dis amet dignissim cursus nam facilisi sed. Rutrum cursus amet sagittis eget ut dictumst. Neque nunc tincidunt ac ipsum hac lorem morbi.</div>

          <div style={{ display: 'flex', position: 'relative', top: '32.5%', left: '7.5%', width: '85%' }}>
            <Fade up delay={1800}>
              <div className='mob-LandingPageTTTBgPlayButton' onClick={() => {
                if (sessionStorage.getItem('access_token')) {
                  window.location.href = "/StageMonitoring";
                } else {
                  params.toggle_mobileLogin();
                  params.mobileMenu && params.toggle_mobileMenu();
                  // document.body.classList.add('login-menu-visible');
                }
              }}>PLAY</div>
              <div className='mob-LandingPageTTTBgPlayLine'></div>
              <div className='mob-LandingPageTTTBgPlayLine' style={{ marginLeft: '-20px' }}></div>
              <div className='mob-LandingPageTTTBgRegisterButtonBox' style={{ zIndex: 99999 }}>
                <svg width="100%" height="100%" style={{ position: 'absolute' }}>
                  <line x1="15%" y1="0" x2="0" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '1' }} />
                  <line x1="100%" y1="0" x2="85%" y2="100%" style={{ stroke: '#0047FF', strokeWidth: '1' }} />
                </svg>
                <div className='mob-LandingPageTTTBgRegisterButton' onClick={() => {
                  if(isRegister == true){
                    window.location.href = "/Register"
                  }else{
                  params.toggle_mobileRegister();
                }
                }}>
                  REGISTER
                </div>
              </div>
            </Fade>
          </div>
          
          <div className='mob-LandingPageTTTFollowSet'>
          <div className='mob-LandingPageTTTFollowBox'>
            <a className='mob-LandingPageTTTFollowFacebookIcon' target="_blank" href="https://www.facebook.com/TTTSpaceShip?mibextid=ZbWKwL"><i className="fab fa-facebook-f" style={{color: '#FFF', fontSize: '10px'}}/></a>
            <div className='mob-LandingPageTTTFollowFacebookText'>FOLLOW</div>
          </div>
          </div>
        </div>
      </div>
    </>




  )
}

export default LandingpageTTTMobile