import React from "react";
import HeaderTTT from "../components/header/HeaderTTT";
import '../assets/css/login.css';

function Login() {
  return (
    <>
      <HeaderTTT />
      <img src="../assets/img/bg/area_bg01.jpg" alt="" className="bg-login" />
      <div className="container-fluid login-page" >
        <div className="row con-login">
          <div
            className="col-12 col-md-6 col-xl-6 d-flex justify-content-center" style = {{maxHeight:'90vh'}} 
          >
            <div className="w-75">
              <div className="d-flex flex-column justify-content-center">
                <div className="col d-flex justfiy-content-start mb-5 h4">
                  Login
                </div>

                {/* Column Email */}
                <div className="row">
                <div className="col-12 col-md-4 col-xl-3 d-flex align-items-center">
                  <label className="col-3" htmlFor="email">
                    Email
                  </label>
                </div>
                <div className="col-12 col-md-8 col-xl-9 ">
                  <input type="email" id="email" className="form-control  input-" />
                </div>
                </div>

                {/* Column Password */}
                <div className="row mt-3">
                <div className="col-12 col-md-4 col-xl-3 d-flex align-items-center">
                  <label className="col-3" htmlFor="password">
                    Password
                  </label>
            
                </div>
                <div className="col-12 col-md-8 col-xl-9">
            
                  <input type="password" id="password" className="form-control  input-password" />
                </div>
                </div>
                {/* Login Button */}
                <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-login col-12 col-md-12 col-lg-6 col-xl-4">Login</button>
                </div>

                  <button className="btn btn-facebook mt-5 col-12 col-md-12 col-lg-8 col-xl-6">Login with Facebook</button>
                  <button className="mt-3 btn btn-google col-sm-12 col-md-12 col-lg-8 col-xl-6">Sign in with Google</button>

                <div className="d-flex justify-content-end">
                  <button className="btn mt-5 col-12 col-md-12 col-lg-6 col-xl-4" >Register</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-6 mt-4" >
            <img
              src="../assets/img/blog/blog_details_img01.jpg"

              style = {{width:'100%'}}
              alt=""
            />
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </>
  );
}

export default Login;
