import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal } from 'antd';
import './addMenberModal.css'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFileLines } from '@fortawesome/free-solid-svg-icons';
import dayjs from "dayjs";
import axios from "axios";
import Configs from "../../../config";
import Earth from "../img/earth.png";
import SpaceA from "../img/spaceA.png";
import TconShear from "../img/iconShear.png";
import FreamImg from "../img/fream.png";
import GretUopImg from "../img/GretUop.png";
import BinAivit from "../img/binAivit.png";
import Shprri from "../img/shprri.png";
import { io } from 'socket.io-client';
import profileImg from '../../../assets/img/userlogo/defultUser.png';
import Select, { components } from 'react-select';

function ModelAddMember({
    isOpen,
    toggle_modal_AddMenberOn,
    dataAddMeber,
    getProjectStateById,
    dataWit
}) {
    const [ButomShere, setButomShere] = useState(false);
    const btnShere = () => setButomShere(!ButomShere);
    const [findTTTUser, setFindTTTUser] = useState({
        ttt_id: '',
        err_message: '',
    });
    const [selectUser, serSelectUser] = useState({
        ttt_id: '',
        path_img: '',
        usr_id: '',
        user_name: '',
        is_have: false
    })
    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);

    const [sapphireData, setSapphireData] = useState(0);
    const [customerContact, setcustomerContact] = useState([])
    const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });

    /*     const SingleValue = ({ children, ...props }) => {
    
            return (
                <components.SingleValue {...props}>
                    {children}
                </components.SingleValue>
            )
        }; */


    function getAmountSapphire() {

        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/sapphire/getAmountSapphire/${sessionStorage.getItem('user_id')}`,
            headers: {
                /*     Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                setSapphireData(response.data || 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


  




    function searchUserByTTTID() {
        let temp = {
            ttt_id: findTTTUser.ttt_id.trim()
        }
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/userProfile/getUserByTTTIDHaveProfile`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(function (response) {
                console.log('response', response.data);
                if (response.data.length == 0) {
                    setFindTTTUser({ ...findTTTUser, err_message: 'Not Found !!!' });
                    serSelectUser({
                        ...selectUser,
                        ttt_id: '',
                        path_img: '',
                        usr_id: '',
                        user_name: '',
                        is_have: false
                    })
                    selectUser.ttt_id = '';
                    selectUser.path_img = '';
                    selectUser.usr_id = '';
                    selectUser.user_name = '';
                    selectUser.is_have = false;
                    findTTTUser.err_message = 'Not Found !!!';
                } else {

                    let element = response.data[0];
                    serSelectUser({
                        ...selectUser,
                        ttt_id: element.usr_ttt_id,
                        path_img: element.ul_file_path,
                        usr_id: element.usr_id,
                        user_name: element.usr_name /* + ' ' + element.usr_lastname */,
                        is_have: true
                    })
                    selectUser.ttt_id = element.usr_ttt_id;
                    selectUser.path_img = element.usr_ttt_id;
                    selectUser.usr_id = element.ul_file_path;
                    selectUser.user_name = element.usr_name /* + ' ' + element.usr_lastname */;
                    selectUser.is_have = true;


                    setFindTTTUser({ ...findTTTUser, ttt_id: '', err_message: '' });
                    findTTTUser.ttt_id = '';
                    findTTTUser.err_message = '';
                }


            })
            .catch(function (error) {
                console.log(error);


            });

    }



    function CheckMemberInSpace(value) {
        let memberWaitForAccpt = dataWit.memberWaitForAccpt || []
        let member = dataWit.member || []
      /*   console.log('member__',member); */
        let check_1 = memberWaitForAccpt.filter((a) => {return a.ipt_usr_id ==  selectUser.usr_id}  )
        let check_2 = member.filter((a) => {return a.usr_id ==  selectUser.usr_id && a.is_member == true}  )
       /*  console.log('member__1',check_2,check_1); */
        if(check_1.length > 0 || check_2.length > 0){
                setFindTTTUser({ ...findTTTUser, err_message: 'This user is already on the spaceship !!!' });
                findTTTUser.err_message = 'This user is already on the spaceship !!!';
        }else{
            toggle_modal_confrimModal();
        }
    }



    async function CheckBeforSaveData(value) {
        if (parseInt(sapphireData || 0) < 10000) {
            setErrorMessage({ ...errorMessage, message: 'The remaining sapphire is not available for inviting new members.', status: true });
            setAlertKick(true)
        } else {
            var temp ={
                usr_id: selectUser.usr_id,
                ipt_spaceship_id: dataAddMeber.space_id,
                ipt_project_id: dataAddMeber.project_id,
                remark:'',
                usr_name:selectUser.user_name
            }

            axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/project/addMemberOnSpace`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp

            })
                .then(function (response) {
                    socketRef.current.emit('KickOff', { message: '', room: "KickOffProject:" + dataAddMeber.project_id });
                    socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + selectUser.usr_id});

                     getProjectStateById()
                     toggle_modal_AddMenberOn()

                })
                .catch(function (error) {
                    console.log(error);
                    setErrorMessage({ ...errorMessage, message: error.response.data.error[0].errorDis, status: true });

                });
        }
    }


    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port:Configs.TTT_Game_Socket_Service_Port
              });
        }
    }, [

    ])


    useEffect(() => {

        console.log(dataAddMeber);

        setFindTTTUser({ ...findTTTUser, ttt_id: '', err_message: '' });
        findTTTUser.ttt_id = '';
        serSelectUser({
            ...selectUser,
            ttt_id: '',
            path_img: '',
            usr_id: '',
            user_name: '',
            is_have: false
        })
        selectUser.ttt_id = '';
        selectUser.path_img = '';
        selectUser.usr_id = '';
        selectUser.user_name = '';
        selectUser.is_have = false;
        findTTTUser.err_message = '';
        setButomShere(false)




        if (isOpen === true) {
            getAmountSapphire()
            socketRef.current.emit('leaveOne', "mailBox:" + sessionStorage.getItem('user_id'))
            socketRef.current.emit('join', "mailBox::" + sessionStorage.getItem('user_id'))


        } else {
            socketRef.current.emit('leaveOne', 'mailBox:' + sessionStorage.getItem('user_id'))
            socketRef.current.emit('leaveOne', 'mailBox:' + sessionStorage.getItem('user_id'))
        }
    }, [isOpen])






    return (

        <>
            <div className={`opcityBg`} style={{ display: `${isOpen ? '' : 'none'}` }}></div>

            <div className={`AddMemberModal`} style={{ display: `${isOpen ? '' : 'none'}` }}>
                <div className='close-btn-2' style={{ cursor: 'pointer'/*  ,border:'1px solid red' */ }} onClick={() => {
                    setFindTTTUser({ ...findTTTUser, ttt_id: '', err_message: '' });
                    findTTTUser.ttt_id = '';
                    serSelectUser({
                        ...selectUser,
                        ttt_id: '',
                        path_img: '',
                        usr_id: '',
                        user_name: '',
                        is_have: false
                    })
                    selectUser.ttt_id = '';
                    selectUser.path_img = '';
                    selectUser.usr_id = '';
                    selectUser.user_name = '';
                    selectUser.is_have = false;
                    findTTTUser.err_message = '';

                    toggle_modal_AddMenberOn();
                }} > X </div>
                <svg height="100" width="100" style={{ position: 'absolute', left: '90.80%', top: '87.4%' }}>
                    <line x1="108" y1="0" x2="0" y2="96" style={{ stroke: `#0094FF`, strokeWidth: '3' }} />
                </svg>
                <svg height="100" width="100" style={{ position: 'absolute', left: '-0.60%', top: '-2.50%' }}>
                    <line x1="108" y1="0" x2="0" y2="90" style={{ stroke: `#0094FF`, strokeWidth: '3' }} />
                </svg>

                <div className='titleheard'>
                    Transportation To Spaceship
                </div>

                <img src={Earth} className='imgErat' />
                <img src={SpaceA} className='SpaceAImg' />

                {ButomShere == false ?
                    <img src={TconShear} className='iconSher' onClick={() => {
                        btnShere()
                    }} /> :
                    <>
                        <font className="massgerError" /* style ={{border:'1px solid red'}}  */color="red">{findTTTUser.err_message}</font>
                       {/*  <font className="massgerError" color="red">{findTTTUser.err_message}</font> */}
                        <img src={TconShear} className='iconSher' onClick={() => {
                            searchUserByTTTID();
                        }} />
                        <div className='inputShert'>
                            <input
                                className='box-input-addM'
                                type='text'
                                value={findTTTUser.ttt_id}
                                onChange={(e) => {
                                    setFindTTTUser({ ...findTTTUser, ttt_id: e.target.value });
                                }}
                            />
                            <svg height="30" width="20" style={{ position: 'absolute', left: '-1%', top: '-7%' }}>
                                <line x1="12" y1="0" x2="0" y2="10" style={{ stroke: `#0094FF`, strokeWidth: '2.5' }} />
                            </svg>
                            <svg height="20" width="11" style={{ position: 'absolute', left: '95%', top: '78.77%' }}>
                                <line x1="17" y1="0" x2="0" y2="8" style={{ stroke: `#0094FF`, strokeWidth: '2.5' }} />
                            </svg>
                        </div>

                        {selectUser.is_have == true ? (<>
                            <div className='freamImg' />
                            <img src={GretUopImg}
                                className='groupImg'
                            />
                            <img src={selectUser.path_img !== null && selectUser.path_img !== "" ? `${Configs.TTT_Game_Service_IMG}${selectUser.path_img}` : profileImg}
                                className='profileIMG'
                            />
                            <div className='ActiveBtn'>

                                <img src={BinAivit}
                                    className='ImgBtnAvit'
                                    onClick={() => {
                                       /*  toggle_modal_confrimModal(); */
                                       CheckMemberInSpace()
                                    }}
                                />
                                <dev className="textInvite" onClick={() => {
                                   /*  toggle_modal_confrimModal(); */
                                   CheckMemberInSpace()
                                }}> INVITE </dev>
                                <img className="ShprriImg" src={Shprri} onClick={() => {
                                   /*  toggle_modal_confrimModal(); */
                                   CheckMemberInSpace()
                                }} />
                                <dev className="textAmount" onClick={() => {
                                   /*  toggle_modal_confrimModal(); */
                                   CheckMemberInSpace()
                                }}> 10,000 </dev>
                                <div className='boxShowder' onClick={() => {
                                   /*  toggle_modal_confrimModal(); */
                                   CheckMemberInSpace()
                                }}>
                                    <div className='textTTT' > {selectUser.ttt_id} </div>
                                    <div className='textName'> {selectUser.user_name} </div>
                                </div>
                            </div>

                        </>) : (<></>)}


                    </>

                }


            </div>


            <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox'>
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'red' /* ,textDecorationLine:'underline' */ }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '5.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning' >

                                </div>
                            </div>
                            <div className='row' style={{ height: '8.5%' }}>
                                <div className='alertKickText'>
                                    ! Everything comes with a price !
                                </div>
                            </div>
                            <div className='row mt-2' style={{ height: '28%',/* border:'1px solid red', */fontSize: '12px' }}>
                                <div className='alertKickTextSec'>
                                    You can add members, but you have to pay 10,000 Sapphire.
                                </div>
                            </div>
                            <div className='row alertKickBoxButton mt-2' >
                                <button className='alertKickConfirm mr-2' onClick={() => {
                                    toggle_modal_confrimModal();
                                    CheckBeforSaveData()
                                    /*  toggle_modal_AddMenberOn(); */
                                }}>YES</button>

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                    /*  toggle_confrim() */
                                    toggle_modal_confrimModal()
                                }}>NO</button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'red' : 'green'}` }}>
                                    {errorMessage.status ? '! ERROR !' : 'SUCCESS'}
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    {errorMessage.status ? 'WARNING' : 'SUCCESS'}
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    {errorMessage.message}

                                </div>
                            </div>


                            <div className='row alertKickBoxButton'>
                                <button className='alertKickButton' onClick={() => {
                                    toggle_modal_alertKick()
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </>
    )


}
export default ModelAddMember;



