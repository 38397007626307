import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import '../../assets/css/info.css';
import '../../assets/css/card.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";
import * as THREE from 'three';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { arraySlice } from 'three/src/animation/AnimationUtils';

import Configs from "../../config";
import moment from 'moment';
 let imgDeful = "../../../../assets/img/bg/imgbdsport.jpg"

const Carddefcon = (param) => {

    const [history, sethistory] = useState(false);
    const [historydata, sethistorydata] = useState({
        data: []
    });

    const sortedDataDefcon = param.dataDefcon ? param.dataDefcon.sort((a, b) => a.defcon_level - b.defcon_level) : [];

    return (<>
        <div className={`${param.isOpen === true ? 'carddefcon' : 'carddefconclon'}`} id='detai'>
            <div className='col-12' style={{ padding: '0' }}>
                {sortedDataDefcon.map((e, index) => {
                    // console.log('param', param)
                    const commentLines2 = e.defcon_description.split('\n');


                    return (<>
                        <div className='row carddefhook'


                            onClick={() => {
                                if (param.isLeader == true) {
                                    param.returncolordefcon(e.defcon_color, e.defcon_level == 4 || e.defcon_level == 5 ? false : true, e.defcon_id)
                                }

                            }}>
                            <div className='col-5' style={{ background: e.defcon_color, height: '35px' }}>

                            </div>
                            <div className='col-7 textnoclick' style={{ fontSize: '12px', padding: '0', paddingLeft: '10px' }}>
                                <div className='col-9 ' style={{ fontSize: '7px', padding: '0' }}>
                                    DEFCON {e.defcon_level}
                                </div>
                                {e.defcon_name}
                            </div>

                            <div className={`tomodel`} >
                                {e.defcon_file_path_box ? (
                                    <img
                                        className='IMGDefcon'
                                        src={`${e.defcon_file_path_box}`}
                                    />
                                ) : <>
                                    <img 
                                      className='IMGDefcon'
                                    src={imgDeful}
                                    />
                                </>}


                                <span className='textdefcon' >
                                    <font style={{ fontSize: '20px', color: `${e.defcon_color}` }} >DEFCON {e.defcon_level} </font>
                                    <br /> <br />
                                    {commentLines2.map((line, i) => (
                                        <p key={i}>
                                            {line}
                                            {i < commentLines2.length - 1 && <br />}
                                        </p>
                                    ))}
                                    {/*  <p style={{ fontSize: '16px' }}>  {`${e.defcon_description}`} </p>  */}

                                </span>


                            </div>


                        </div>
                    </>)
                })}

            </div>



        </div>



    </>)
}
export default Carddefcon