let config = {

    
    
    
    TTT_Game_Service:'http://150.95.24.71:8778',
    TTT_Game_Notification_Service:'http://150.95.24.71:8775',
    TTT_Game_Service_IMG:'http://150.95.24.71:8778/static/',
    TTT_Game_Permission_Service:'http://150.95.24.71:8779',
    TTT_Game_Socket_Service:'http://150.95.24.71:7776',
    TTT_Game_Socket_Service_Port:'7776',
    
    TTT_Dashboard_Url:'http://tttspaceship.com:7781/federation-dashboard/',
    
    TTTClient:'https://tttspaceship.com',
    
/*     TTT_Game_Service:'http://150.95.24.71:7778',
    TTT_Game_Notification_Service:'http://150.95.24.71:7775',
    TTT_Game_Service_IMG:'http://150.95.24.71:7778/static/',
    TTT_Game_Permission_Service:'http://150.95.24.71:7779', */
   /*  TTT_Game_Socket_Service:'http://150.95.24.71:7776', */
    API_TTT: '4f781ebba1a655430fb6db734c2c156f',
    API_URL_BOX_SERVICE:"http://150.95.24.71:7771",
  
    
    };
    
    export default Object.freeze(Object.assign({}, config));
    