import React from "react";
import "./buttonCustomStyle.css";

function input({ placeholder, onClick ,isuse}) {
  return (
    <>
      <div className={`${isuse === true ? 'buttonShadowStyle': ''}`} >
        <div className="buttonOuterStyle">
          <button type = "button" className={`${isuse === true ? 'buttonInnerStyle': 'buttonInnerStylelock'}`}
        
            onClick={onClick}
          
          
           >
            {placeholder || ""}
          </button>
        </div>
      </div>
    </>
  );
}

export default input;
