import React, { useEffect, useState, useRef } from 'react'
import {
  Input,
  Space,
  Tag,
  theme,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
} from 'antd'

import axios from 'axios'
import moment from 'moment'
import dayjs from 'dayjs'
import { ExclamationCircleFilled } from '@ant-design/icons'
import Configs from '../../../config'
import IconReport from '../../../assets/img/IconStage4/reportSandMail.svg'

import './processMockup.css'
import Previous from '../../../assets/img/icon/Previous.png';
import Next from '../../../assets/img/icon/Next.png';
import Getimg from '../../component/stageElement/getimgshow.jsx'
import { generateUUID } from 'three/src/math/MathUtils.js'
const { RangePicker } = DatePicker
const { confirm } = Modal

function ModalProcessMockupViewVersion({
  isOpen,

  toggle_modal_process_mockup_view_version,
  data_mockup_version
}) {
console.log(data_mockup_version);
  const [dataVersion, setDataVersion] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isOpen == true) {
      let temp_sort = [];
      for (let item of data_mockup_version) {
        console.log(item);
        
        let temp = {
          version_view: item.version,
          remark_view: item.remark,
          path_view: item.path,
          name_view: item.name,
          box_path: item?.box_path,
          pathbox: item?.pathbox,
        }
        temp_sort.push(temp);
      }
      let data_sort = temp_sort.sort((a, b) => b.version_view - a.version_view);
      setDataVersion(data_sort);
      setCurrentIndex(0);
    } else {
      setDataVersion([]);
    }

  }, [isOpen && data_mockup_version])

  const [test, settest] = useState({ test: '' });

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : dataVersion.length - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex < dataVersion.length - 1 ? prevIndex + 1 : 0));
  };


  return (
    <>
      {/*     {isOpen && (
    <> */}
      <ConfigProvider
        theme={{
          token: {
            /*     colorPrimary: '#85BFFF', */
            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',

            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue', //สีตอน Active ใน Select
            colorBgBase: '#778899', //พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: '#FFFFFF', // สีตัวหนังสือของ placeholder

            colorFillTertiary: '#000', // สีตอน hover ใน Select

            colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker

            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */
          },
        }}
      >
        <div
          className={`backdropModuleMenu2 ${isOpen ? 'showDetail' : 'endDetail'}`}
          onClick={() => {
            /*  if (openModalSendMail !== true) {
               toggle_modal_process_mockup_view_version();
             } */
          }}
        >
          {' '}
        </div>
        <div
          className={`detailBoxModuleMenu2 ${isOpen ? 'showDetail' : 'endDetail'}`}
          style={{
            /* top:`10%`, */ left: `50%`,
            position: 'fixed',
            transform: 'translate(-50%, -14%)',
            width: '90%',
            height: '85%',
            backgroundColor: '#000',
          }}
        >
          <div className="imgMockupTagVersion2">VIEW VERSION.</div>

          <div
            style={{
              width: '100%',
              height: '100%',
              margin: 0,
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* <div
              className="row"
              style={{ width: '100%', margin: 0, padding: 0 }}
            >
              <div
                className='col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3'
                  
              
              >
                <h4>Mockup View Version </h4>{' '}
              </div>

            
         
            </div>

            <div style={{ height: '15px', borderTop: '1px solid #FFF' }}></div> */}



            <div style={{flexGrow: 1, overflowY: 'auto', marginTop: '-25px'}}>
              <div
                className="table-responsive frameDisplay"
                style={{ height: '97%', marginTop: '1%' }}
              >
                <div className="col-12 table-responsive frameResponsive">
                {dataVersion.length > 0 && (
                <>
                      <div className="imgMockupCard" style={{ height: '88.4%', width: '76vw' }}>
                        <div className="imgMockupCardBody2"
                          // style={{ borderRadius: '0px 5px 5px 0px' }}
                          onClick={() => {
                            /*          toggle_modal_process_mockup_view_version(); */
                          }}>
                            
                         {/*  <img src={`${Configs.TTT_Game_Service_IMG}${dataVersion[currentIndex].path_view}`}
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />  */}                                 
                          <Getimg
                          img={dataVersion[currentIndex].box_path? dataVersion[currentIndex].box_path : dataVersion[currentIndex].pathbox? dataVersion[currentIndex].pathbox : dataVersion[currentIndex].path_view}
                          isnew={!dataVersion[currentIndex].box_path? false : true}
                          style={{width:'100%',height:'100%',objectFit:'contain'}}
                          callback={(result) => {
                            dataVersion[currentIndex].box_path = result
                           
                          }}
                          />
                        </div>
                        <div className='borderDescription'>
                          <div className="imgMockupCardDescription2">{dataVersion[currentIndex].remark_view || 'description.....'}</div>
                          <div className="TagVersion2" >VER.{dataVersion[currentIndex].version_view}</div>
                          {/* <div className="imgMockupTagVersion2">VER.<div className ="numberVersionCircle2"><div className ="numberVersion2">{e.version_view}</div></div></div> */}
                        </div>

                      </div>
                      <div className='nextImages'>
                        <img src={Previous}onClick={handlePrevClick} className='iconPrevious'></img>
                        <img src={Next} onClick={handleNextClick} className='iconNext'></img>
                      </div>
                      {/* <br /> <br /> */}
                    </>
                  )}

                  <table>
                    {/* 
                  {stageMonitoringView.length > 0 && stageMonitoringView.map((e2) => {
                    console.log(e2.menu_name)
                      
                        return (<>
                          <tr>
                            <td style={{ height: '30px', whiteSpace: 'nowrap',width:'30%' }}>  <div className="row mb-2">
                              <Tooltip title={e2.menu_name}>
                                <p
                                  onClick={() => {

                                  }}
                                  style={{ maxWidth: '290px', fontSize: '14px', color: '#fff', overflow: 'hidden', textOverflow: 'ellipsis', margin: '0 0 0' }}> {e2.module_name}{' >> '}{e2.menu_name}</p>
                              </Tooltip>
                              <p style={{ margin: '0 0 0' }}>
                                {" "}

                              </p>
                              </div></td>
                        
                                  <td style={{width:'30%', height: '30px', textAlign: 'left' }}>
                                    <button style={{ clipPath: "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)", width: '50px', height: '10px', background: 'green', border: '0px' }}>
                                      <button onClick={(ee) => {

                                   



                                      }} style={{ clipPath: "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)", width: '50px', height: '10px', background: 'green', border: '0px', cursor: 'pointer' }}></button>
                                    </button>
                                  </td>
                           


                          </tr>
                        </>)
                      
                    })} */}
                  </table>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className=" btn-outline-danger"
                style={{ padding: '5px' }}
                onClick={() => {
                  toggle_modal_process_mockup_view_version();
                }}
              >
                {' '}
                Cancel{' '}
              </button>
            </div>
          </div>
          <br />
          <br />

          {/*     </>)} */}

          {/*  <ModalSendMail
            isOpen={openModalSendMail}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_send_mail() }}
          />

          <ModalViewsList
            isOpen={openModalViewListReport}
            stageMonitoringData={stageMonitoringDataReport}
            togle_modal_send_mailFun={() => { togle_modal_ViewListReport() }}
          /> */}
        </div>
      </ConfigProvider>

      {/*     </>
    
  )} */}
    </>
  )
}
export default ModalProcessMockupViewVersion
