import React, { useEffect, useState, useRef } from 'react';
import step5A from '../image/step5-a.png';
import step51 from '../image/step5-1.png';
import step52 from '../image/step5-2.png';
import step53 from '../image/step5-3.png';
function HowToPlayStep5(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step5A} alt="" style={{ bottom: '-7px', left: '-4px', width: '25%' }} />
                <svg height="175" width="125" style={{ position: 'absolute', top: '290px', left: '230px'}}>
                    <line x1="0" y1="175" x2="50" y2="100" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                    <line x1="50" y1="100" x2="95" y2="100" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                </svg>
                <div style={{position: 'absolute',position: 'absolute', top: '387px', left: '323px', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#0047FF' }}></div>
                <div className='howToPlayStep1Detail' style={{ top: '120px', left: '350px', width: '525px' }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>รายละเอียดหน้า Projects</div><div style={{fontSize:'13.2px'}}>เราสามารถเห็นหน้า Projects ที่ทางเจ้าของ Project มาวางเพื่อให้ทาง
หัวหน้ายานที่กดเข้ามาดูแล้วสามารถกดสมัครเพื่อทำงานได้</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '230px', left: '350px', width: '525px',height:'140px' }}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>กดสมัครงานเพื่อทำ Projects</div><div style={{fontSize:'13.2px'}}>เมื่อกดดูรายละเอียดงานที่เราต้องการแล้ว ให้ทำการกดปุ่ม Apply เพื่อ
ที่จะเลือกยานในคอลเล็คชั่นของเราไปทำ Project กับทางเจ้าของ Project </div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '390px', left: '350px', width: '525px',height:'110px' }}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>เลือกยาน, อัปโหลดวิดีโอ หรือเขียนรายละเอียด</div><div style={{fontSize:'13.2px'}}>เมื่อกดเลือกยานของเราเสร็จแล้ว ระบบจะเข้าสู่หน้าจอให้อัปโหลดวิดีโอ
หรือ เขียนรายละเอียดเกี่ยวกับทีมของเราให้เจ้าของ Project ได้เห็น 
จากนั้นให้กดปุ่ม Submit เพื่อรอเจ้าของ Project ยืนยัน</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '520px', left: '350px', width: '525px' }}>
                    <div className='howToPlayStep1DetailNo'>04</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>ยืนยันจากเจ้าของ Project </div><div style={{fontSize:'13.2px'}}>เมื่อเจ้าของ Project กด Offer ระบบจะแจ้งเตือนให้หัวหน้ายาน
กด Accept ก็สามารถเริ่มทำ Project ได้</div></div>
                </div>
          {/*       <img className='howToPlayStep1ImgBg' src={step31} alt="" style={{ top: '100px', left: '395px', width: '40%' }} /> */}
                <img className='howToPlayStep1ImgBg' src={step51} alt="" style={{ top: '120px', left: '905px', width: '36%', height: '26%' }} />
                <img className='howToPlayStep1ImgBg' src={step53} alt="" style={{ top: '320px', left: '928px', width: '34.6%', height: '40%' }} />
                <img className='howToPlayStep1ImgBg' src={step52} alt="" style={{ top: '320px', left: '558px', width: '8%', height: '8%' }} />
              {/*   <svg height="100" width="250" style={{ position: 'absolute', top: '215px', right: '295px'}}>
                    <line x1="0" y1="65" x2="250" y2="65" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="250" y1="65" x2="250" y2="100" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="250" y1="100" x2="0" y2="100" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="0" y1="100" x2="0" y2="65" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="115" y1="65" x2="115" y2="15" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg> */}
             {/*    <div className='howToPlayStep2RedLineNo' style={{ top: '210px', right: '417.5px', width: '25px', height: '25px', fontSize: '12px'}}>02</div> */}
             {/*    <svg height="75" width="40" style={{ position: 'absolute', bottom: '92px', right: '39px'}}>
                    <line x1="0" y1="0" x2="40" y2="0" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="40" y1="0" x2="40" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="40" y1="25" x2="0" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="0" y1="25" x2="0" y2="0" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="20" y1="25" x2="20" y2="60" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg> */}
           {/*      <div className='howToPlayStep2RedLineNo' style={{ bottom: '85px', right: '45px', width: '25px', height: '25px', fontSize: '12px'}}>03</div>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '155px', left: '970px', width: '2.5%'}}/> */}
            </div>
        </>
    );
}

export default HowToPlayStep5;