import React, { useState } from "react";
import { TreeSelect, ConfigProvider } from "antd";
import styled from "styled-components";
import "./ModalUploadReport.css"; // Ensure you have some basic styles for the modal

const { SHOW_PARENT } = TreeSelect;

const StyledTreeSelect = styled(TreeSelect)`
  .ant-select-selector {
    border-radius: 0px !important;
  }
`;

const ModalUploadReport = ({
  isOpen,
  onClose,
  projectDetail,
  reportMockup,
  errorMessage,
  setErrorMessage,
  alertKick,
  setAlertKick,
  toggle_modal_alertKick,
  getDataElementSelectMockup,
  reportSelectMockup,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  if (!isOpen) {
    return null;
  }

  const handleChange = (value) => {
    setSelectedValues(value);
  };

  async function handleDownload() {
    const selectedData = selectedValues.reduce((acc, value) => {
      const moduleDetail = projectDetail.find((detail) => detail.module_id === value);
      if (moduleDetail) {
        acc.push(...projectDetail.filter((detail) => detail.module_id === value));
      } else {
        const menuDetail = projectDetail.find((detail) => detail.menu_id === value);
        if (menuDetail) {
          acc.push(menuDetail);
        }
      }
      return acc;
    }, []);
  
    console.log('Selected Data:>> ', selectedData);
  
    const updatedDataMenu = await getDataElementSelectMockup(selectedData);
  
   await reportSelectMockup(updatedDataMenu);
  }

  const moduleMap = projectDetail.reduce((acc, detail) => {
    if (!acc[detail.module_id]) {
      acc[detail.module_id] = {
        value: detail.module_id,
        title: detail.module_name,
        children: [],
      };
    }
    acc[detail.module_id].children.push({
      value: detail.menu_id,
      title: detail.menu_name,
    });
    return acc;
  }, {});

  const options = Object.values(moduleMap);

  return (
    <div className="modal-overlay" style={{ width: "100%", height: "100%" }}>
      <button className="close-modal-button" onClick={onClose}>
        X
      </button>
      <div className="modal-content">
        <div className="row">
        <svg height="100%" width="100%" style={{ position: "absolute" }}>
            <line x1="50%" y1="20%" x2="50%" y2="65%" style={{ stroke: "#0047FF", strokeWidth: "3" }} />
          </svg>
          <div
            className="col-12"
            style={{ paddingLeft: "10px", borderBottom: "1px solid #0047FF" }}
          >
            <h4 style={{ fontSize: "20px", paddingBottom: "2px", margin: 0 }}>
              REPORT MOCKUP
            </h4>
          </div>
          <div className="col-6">
            <div
              style={{ width: "100%", height: "16vh", alignItems: "center" }}
            >
              <div
                className="modalFontOxygen"
                style={{ fontWeight: "100", paddingTop: "40px" }}
              >
                SELECT MENU
              </div>
              <ConfigProvider
                theme={{
                  inherit: false,
                  token: {
                    colorBgBase: "#102456",
                    colorTextQuaternary: "#FFF",
                    colorBorder: "#0047FF",
                    colorPrimaryBg: "#102456",
                    colorText: "#FFF",
                    colorTextDescription: "red",
                    colorBgContainer: "#102456",
                    colorBorderBg: "#0047FF",
                    borderRadiusLG: "0",
                    zIndexPopupBase: 999999,
                  },
                }}
              >
                <StyledTreeSelect
                  treeData={options}
                  value={selectedValues}
                  onChange={handleChange}
                  treeCheckable
                  showCheckedStrategy={SHOW_PARENT}
                  placeholder="Select Menu"
                  style={{ width: "100%", borderRadius: "0" }}
                  dropdownStyle={{ border: "1px solid #0047FF" }}
                  showSearch
                  treeNodeFilterProp="title"
                  maxTagCount={1} // Set the maximum number of tags to display
                  maxTagPlaceholder={(omittedValues) => `+ ${omittedValues.length} ...`} // Customize the placeholder
                />
              </ConfigProvider>
            </div>
            <div className="buttons-container">
              <button
                className="download-button"
                onClick={() => {
                  setErrorMessage({
                    ...errorMessage,
                    message: "",
                    status: true,
                  });
                  toggle_modal_alertKick();
                  handleDownload();
                }}
              >
                Download
              </button>
            </div>
          </div>
          <div className="col-6" style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "16vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "100",
              }}
            >
              <h4>ALL MENU</h4>
            </div>
            <div className="buttons-container">
              <button
                className="download-button"
                onClick={() => {
                  setErrorMessage({
                    ...errorMessage,
                    message: "",
                    status: true,
                  });
                  toggle_modal_alertKick();
                  reportMockup();
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
        {alertKick && <div className="alert">Alert message goes here</div>}
      </div>
    </div>
  );
};

export default ModalUploadReport;
