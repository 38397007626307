import React, { useState, useEffect, useRef } from 'react';
import '../achievement/detailAchievement.css'
import icon0 from '../../../../assets/img/icon/Icon0.png'
import icon1 from '../../../../assets/img/icon/Icon1.png'
import icon2 from '../../../../assets/img/icon/Icon2.png'
import icon3 from '../../../../assets/img/icon/Icon3.png'
import icon4 from '../../../../assets/img/icon/Icon4.png'
import icon5 from '../../../../assets/img/icon/Icon5.png'
import icon6 from '../../../../assets/img/icon/Icon6.png'
import icon7 from '../../../../assets/img/icon/Icon7.png'
import Construction from '../../../../assets/img/bg/Construction Page.png'

const DetailAchievement = ({ isOpen, check }) => {
    const [achData, setAchData] = useState([
        {
            achName: "Achievement 1",
            achDetail: "Achievement Detail 1",
            achImg: icon1,
            achHow: "Achievement How to get 1",
            achHave: true
        },
        {
            achName: "Achievement 2",
            achDetail: "Achievement Detail 2",
            achImg: icon2,
            achHow: "Achievement How to get 2",
            achHave: true
        },
        {
            achName: "Achievement 3",
            achDetail: "Achievement Detail 3",
            achImg: icon3,
            achHow: "Achievement How to get 3",
            achHave: true
        },
        {
            achName: "Achievement 4",
            achDetail: "Achievement Detail 4",
            achImg: icon4,
            achHow: "Achievement How to get 4",
            achHave: true
        },
        {
            achName: "Achievement 5",
            achDetail: "Achievement Detail 5",
            achImg: icon5,
            achHow: "Achievement How to get 5",
            achHave: true
        },
        {
            achName: "Achievement 6",
            achDetail: "Achievement Detail 6",
            achImg: icon6,
            achHow: "Achievement How to get 6",
            achHave: true
        },
        {
            achName: "Achievement 7",
            achDetail: "Achievement Detail 7",
            achImg: icon7,
            achHow: "Achievement How to get 7",
            achHave: true
        },
        {
            achName: "Achievement 8",
            achDetail: "Achievement Detail 8",
            achImg: "",
            achHow: "Achievement How to get 8",
            achHave: true
        },
        {
            achName: "Achievement 9",
            achDetail: "Achievement Detail 9",
            achImg: "",
            achHow: "Achievement How to get 9",
            achHave: false
        },
        {
            achName: "Achievement 10",
            achDetail: "Achievement Detail 10",
            achImg: "",
            achHow: "Achievement How to get 10",
            achHave: false
        },
        {
            achName: "Achievement 11",
            achDetail: "Achievement Detail 11",
            achImg: "",
            achHow: "Achievement How to get 11",
            achHave: false
        },
        {
            achName: "Achievement 12",
            achDetail: "Achievement Detail 12",
            achImg: "",
            achHow: "Achievement How to get 12",
            achHave: false
        },
        {
            achName: "Achievement 13",
            achDetail: "Achievement Detail 13",
            achImg: "",
            achHow: "Achievement How to get 13",
            achHave: false
        },
        {
            achName: "Achievement 14",
            achDetail: "Achievement Detail 14",
            achImg: "",
            achHow: "Achievement How to get 14",
            achHave: false
        },
        {
            achName: "Achievement 15",
            achDetail: "Achievement Detail 15",
            achImg: "",
            achHow: "Achievement How to get 15",
            achHave: false
        },
        {
            achName: "Achievement 16",
            achDetail: "Achievement Detail 16",
            achImg: "",
            achHow: "Achievement How to get 16",
            achHave: false
        },
        {
            achName: "Achievement 17",
            achDetail: "Achievement Detail 17",
            achImg: "",
            achHow: "Achievement How to get 17",
            achHave: false
        },
        {
            achName: "Achievement 18",
            achDetail: "Achievement Detail 18",
            achImg: "",
            achHow: "Achievement How to get 18",
            achHave: false
        },
        {
            achName: "Achievement 19",
            achDetail: "Achievement Detail 19",
            achImg: "",
            achHow: "Achievement How to get 19",
            achHave: false
        },
        {
            achName: "Achievement 20",
            achDetail: "Achievement Detail 20",
            achImg: "",
            achHow: "Achievement How to get 20",
            achHave: false
        },
        {
            achName: "Achievement 21",
            achDetail: "Achievement Detail 21",
            achImg: "",
            achHow: "Achievement How to get 21",
            achHave: false
        },
        {
            achName: "Achievement 22",
            achDetail: "Achievement Detail 22",
            achImg: "",
            achHow: "Achievement How to get 22",
            achHave: false
        },
        {
            achName: "Achievement 23",
            achDetail: "Achievement Detail 23",
            achImg: "",
            achHow: "Achievement How to get 23",
            achHave: false
        },
        {
            achName: "Achievement 24",
            achDetail: "Achievement Detail 24",
            achImg: "",
            achHow: "Achievement How to get 24",
            achHave: false
        },
        {
            achName: "Achievement 25",
            achDetail: "Achievement Detail 25",
            achImg: "",
            achHow: "Achievement How to get 25",
            achHave: false
        },
        {
            achName: "Achievement 26",
            achDetail: "Achievement Detail 26",
            achImg: "",
            achHow: "Achievement How to get 26",
            achHave: false
        },
        {
            achName: "Achievement 27",
            achDetail: "Achievement Detail 27",
            achImg: "",
            achHow: "Achievement How to get 27",
            achHave: false
        },
        {
            achName: "Achievement 28",
            achDetail: "Achievement Detail 28",
            achImg: "",
            achHow: "Achievement How to get 28",
            achHave: false
        },
        {
            achName: "Achievement 29",
            achDetail: "Achievement Detail 29",
            achImg: "",
            achHow: "Achievement How to get 29",
            achHave: false
        },
        {
            achName: "Achievement 30",
            achDetail: "Achievement Detail 30",
            achImg: "",
            achHow: "Achievement How to get 30",
            achHave: false
        },
        {
            achName: "Achievement 31",
            achDetail: "Achievement Detail 31",
            achImg: "",
            achHow: "Achievement How to get 31",
            achHave: false
        },
    ])

    let [achDetail, setAchDetail] = useState(
        {
            achId: 0,
            achName: achData[0].achName,
            achDetail: achData[0].achDetail,
            achHow: achData[0].achHow
        }
    )

    function setDetail(e, index){
        console.log(e,index);
        setAchDetail({achId: index, achName: e.achName, achDetail: e.achDetail, achHow: e.achHow})
    }

    return (
        (check === 'ACHIEVEMENT' ? (<>
            <div /* className="detailAchievement" */ style={{ visibility: `${isOpen === true && check === 'ACHIEVEMENT' ? 'visible' : 'hidden'}` }}>
            <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "0px" }}/>
               {/*  <div className="row achSetRc">
                    <div className="col-8 achSetRc">
                        <div className="achSetImgOver">
                            {achData.map((e, index) => {
                                return (
                                    <div className="achBox" 
                                    style={{border: `2px solid ${achDetail.achId === index ? '#0047FF' : 'rgba(255, 255, 255, 0.80)'}`, background: `${e.achHave === true ? 'rgba(0, 0, 0, 0.40)' : 'none'}`, opacity: `${e.achHave === true ? '1' : '0.4'}`}}
                                    onClick={()=>{setDetail(e, index);}}
                                    >
                                        <div className="achBoxImg" style={{backgroundImage: `url(${e.achImg !== '' ? e.achImg : icon0})`}}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-4 achSetRc">
                        <div className="achHeadText">Achievement Detail</div>
                        <div className="achNameText">{achDetail.achName}</div>
                        <div className="achDetailHead">Detail : </div>
                        <div className="achDetailText">{achDetail.achDetail}</div>
                        <div className="achHowHead">How to get achievement :</div>
                        <div className="achHowText">-{achDetail.achHow}</div>
                    </div>
                </div> */}
            </div>
        </>) : (<></>))
    )
}

export default DetailAchievement