import React, { useState, useEffect } from 'react'
import './stageElementStyle.css'
import Configs from "../../../config";
import axios from 'axios';
import spaceShipImage from '../../../assets/img/iconSpaceShip/Final-Logo.png'
import { ConfigProvider, Tooltip } from 'antd';
import styled from 'styled-components';


const ElementComponent = ({
    elementData,
    displayMode,
    callBackElement,
    toggle_modal_elementDetailCheck,
    callBacktoScreenshot,
    callBacktoFigmalink,
    callBacktoUserMun,
    callBackBackEnd,
    callBacktoUserUAT,
    callBacktoTestCase
}) => {
    const [test, setTest] = useState({ test: '' })
    return (
        <>
            <div className='stageElementLeftElement'>
                <div className='stageElementLeftMoreDetail'>
                    <div className='stageElementMoreCircle' onClick={() => {
                        toggle_modal_elementDetailCheck();
                    }}>i</div>
                </div>
                <div className='stageElementArrowLeft'></div>
                <div className='stageElementDataBox'>
                    <div className='stageElementDataBoxIn'>
                        {elementData.element && elementData.element.map((e) => {
                            let name = e.elm_name
                            return (
                                <>
                                    <ConfigProvider
                                        theme={{
                                            zIndexPopupBase: 99999999
                                        }}
                                    >
                                        <Tooltip className='styleTooltip' color='none' style={{ zIndex: 99999 }}
                                            title={
                                                <div className="tooltipWrapper">
                                                    <div className="tooltipContainer behind">
                                                        <div className="titleTooltip">
                                                            {/* <span style={{ fontFamily: 'Oxanium', fontSize: '13.2px' }}>{name}</span> */}
                                                        </div>
                                                    </div>
                                                    <div className="tooltipContainer">
                                                        <div className="titleTooltip">
                                                            <span style={{ fontFamily: 'Oxanium', fontSize: '12px', fontWeight: 'bold' }}>{name}</span>
                                                        </div>
                                                        <div className="arrow"></div>
                                                    </div>
                                                    <div>
                                                        <div className="arrow2"></div>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className='stageElementData'
                                                style={{ backgroundImage: `url(${e.elm_img_path != null || e.elm_img_path != undefined || e.elm_img_path != '' ? /* Configs.TTT_Game_Service_IMG + */ e.elm_img_path : ''})`, border: `1px solid ${e.elm_isSelect ? 'rgba(200,200,200,1)' : 'rgba(0, 71, 255, 1)'}` }}
                                                onClick={() => {
                                                    if (elementData.process_name === 'Mockup') {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            if (e.elm_abbr == "SS") {
                                                                callBacktoScreenshot()
                                                            }
                                                        }
                                                        if (e.elm_abbr == "FL") {
                                                            callBacktoFigmalink()
                                                        }

                                                    } else if (elementData.process_name === 'Testing & Deploy') {
                                                        /*   if(displayMode == false || displayMode == 'false'){ */
                                                        if (e.elm_abbr == "UM") {
                                                            // console.log('เข้าตัวนี้');
                                                            callBacktoUserMun()
                                                        }
                                                        if (e.elm_abbr == "UA") {
                                                            // console.log('เข้าตัวนี้');
                                                            callBacktoUserUAT()
                                                        }
                                                        if (e.elm_abbr == "TC") {
                                                            // console.log('เข้าตัวนี้');
                                                            callBacktoTestCase()
                                                        }
                                                        /*     } */

                                                    } else if (elementData.process_name === 'Back-End') {
                                                        if (displayMode == false || displayMode == 'false') {
                                                            if (e.elm_abbr == "GB") {
                                                                callBackBackEnd();
                                                            }
                                                        }
                                                    } else {
                                                        if (displayMode == false || displayMode == 'false') {

                                                            let checkElement = elementData.element.filter((e) => { return e.elm_isSelect == true })
                                                            let tempElementSelectCheck = JSON.parse(sessionStorage.getItem('elementSelect'));
                                                            if (checkElement.length > 0) {
                                                                let tempCheck = tempElementSelectCheck.filter((f) => { return f == e.elm_id });
                                                                if (tempCheck.length < 1) {
                                                                    e.elm_isSelect = true;
                                                                    tempElementSelectCheck.push(e.elm_id);
                                                                    sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectCheck));
                                                                } else {
                                                                    e.elm_isSelect = !e.elm_isSelect;
                                                                    let index = tempElementSelectCheck.findIndex((f) => f == e.elm_id);
                                                                    if (index > -1) {
                                                                        tempElementSelectCheck.splice(index, 1);
                                                                        sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectCheck));
                                                                    }
                                                                }
                                                            } /* else if (checkElement.length == 1) {
                                                    let tempCheck = tempElementSelectCheck.filter((f) => { return f == e.elm_id});
                                                    if (tempCheck.length < 1) {
                                                        e.elm_isSelect = true;
                                                        tempElementSelectCheck.push(e.elm_id);
                                                        sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectCheck));
                                                    }
                                                } */ else {
                                                                e.elm_isSelect = true;
                                                                tempElementSelectCheck.push(e.elm_id);
                                                                sessionStorage.setItem('elementSelect', JSON.stringify(tempElementSelectCheck));
                                                            }
                                                            setTest({ ...test, test: '' });
                                                            test.test = '';
                                                            callBackElement();


                                                        }
                                                    }
                                                }}>
                                            </div>
                                        </Tooltip>
                                    </ConfigProvider>

                                </>
                            )
                        })
                        }
                    </div>
                </div>
                <div className='stageElementArrowRight'></div>
            </div>
        </>
    )
}

export default ElementComponent
