import React from 'react';
import Breadcrumb from './Breadcrumb';
import HeaderTTT from '../../components/header/HeaderTTT';
import Footer from '../../components/footer/Footer';
import GameSingleArea from './GameSingleArea';

function Overview() {
  return (
	<>
		<HeaderTTT/>
		<main>
			{/* <Breadcrumb/> */}
			<GameSingleArea/>

		</main>
		<Footer/>
	</>
  )
}

export default Overview