import React from "react";
import "./cardstageStyle.css";

import moment from "moment";
import moreicon from '../..//assets/img/icon/more-vertical.png'
import icondate from '../../assets/img/images/calendar_story.png'
import iconlist from '../../assets/img/icon/list.png'
import styled from "styled-components";
function cardpercent({percent,mode}) {
    
  /* console.log(percent,mode) */
  let color = 'red';
  if(percent <= 25){
    color = "red";

  }else if(percent <= 50){
    color = "orange";

  }else if(percent <= 75){
    color = "yellow";

  }else if(percent <= 100){
    color = "green";

  }

return (<>
<div className="cardPercent">
<div className="cardPercentValue" style={{width:`${percent}%`,backgroundColor:`${color}`}}>

</div>
</div>
</>)
   
}

export default cardpercent;
