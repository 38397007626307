import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import '../../assets/css/info.css';
import '../../assets/css/card.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";
import * as THREE from 'three';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { arraySlice } from 'three/src/animation/AnimationUtils';
import logo from '../../assets/img/icon/logotttdetai.png'
import icon from '../../assets/img/icon/hardiconcard.png'
import bin from '../../assets/img/icon/trash.png'
import poin from '../../assets/img/icon/poinii.png'
import iconback from "../../assets/img/icon/iconback.png";
import dataJson from './towerData.json';

import moment from 'moment';

const Carddetai = (params) => {

    const [history, sethistory] = useState(false);
    const [historydata, sethistorydata] = useState({
        data:[]
    });
    console.log('params',params)

function returnhis(date) {
    console.log(params,date);

    let y = params.data.position.y
    let arrhis = {
        date:date,
        index:params.index,
        temp:params.temp,
        data:[]
    }

    function getRndInteger(min, max) {
        return (Math.random() * (max - min + 1)) + min;
      }

for (let index = 1; index < y; index++) {
let Radom = getRndInteger(0,1)
console.log('Radom',Radom);
    arrhis.data.push({
        y:index,
        optiy: Radom,
      
    })
}

params.hisfuc(arrhis)

}



    return (<>
    
      <div className={`${params.isOpen === true? 'carddetai':'carddetaiclon'}`} id='detai'>
      <div style={{background:'#85BFFF',width:'15px',height:'15px',marginLeft:'100%',cursor:'pointer'}}  
        onClick={() => {
            params.clonfuc(false)
          
            }}></div>
<div className='row' > 
<div className='col-6' style={{width:'100%',objectFit:'cover',maxHeight:'160px',paddingLeft:'0'}}><img style={{width:'100%',display:''}} src={logo}  />
</div>
<div className='col-6 ' style={{marginTop:'15px',textAlign:'left',padding:'0'}}>
    <div style={{fontSize:'11px'}}>FEDERATION DETAIL  </div>
    <div style={{fontSize:'23px',marginBottom:'10px',marginTop:'-8px'}}>TTT BROTHER</div>
    <div style={{fontSize:'10px'}}>CURRENT PROJECT</div>
    <div style={{fontSize:'15px'}}>{params.data.project_name}</div>
    <div style={{fontSize:'15px'}}>MONITORING</div>
    
    
    </div>
</div>
{history === true ? (<>
    <img style={{width:'5%',cursor:'pointer'}} src={iconback}   onClick={() => {
           sethistory(false)
          





           
            }} /><div style={{fontSize:'17px',marginLeft:'22px',marginTop:'-22px'}}>HISTORY BUILDING</div>

<div  className='cardoverflowhistory'>
<div className='cardspacship' 
 onClick={() => {
    returnhis(moment(new Date()).format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(1, 'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(1, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(2, 'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(2, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(3, 'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(3, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(4,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(4, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(5,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(5, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(6,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(6, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(7,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(7, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(8,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(8, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(9,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(9, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>
<div className='cardspacship'
 onClick={() => {
    returnhis(moment(new Date()).add(10,'days').format())
    }}><div className='row' >
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>History building</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'-6px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-15px',fontWeight:'600'}}>{moment(new Date()).add(10, 'days').format('YYYY.MM.DD')}</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-4px',padding:'0'}}>Lorem : Lorem </div>
    </div>
            
</div></div>

</div>
</>) 
:
 (<><div style={{fontSize:'15px'}}>PROJECT SCOPE</div>
<div style={{fontSize:'10px'}}>LOREM IPSUM DOLOR SIT AMET CONSECTETUR. AT FUSCE MAECENAS SCELERISQUE ACCUMSAN SIT CRAS IN EGESTAS PHARETRA. VEL NEQUE MASSA POSUERE EUISMOD</div>
<br/>
<div><button className='buttoncardhistory'   onClick={() => {
    sethistory(true)
/* params.fucreturn(true) */

}}>HISTORY BUILDING</button> <button className=''  style={{background:`${params.data.color}`,width:'80px',height:'30px',marginLeft:'30px'}} ></button></div>
<br/>
<div className='row' >
<div style={{fontSize:'15px',marginBottom:'5px'}}>MEMBER IN SPACESHIP 8/8 </div>  <div style={{width:'150px',fontSize:'12px',marginTop:'1px',}}><img style={{width:'11%',marginLeft:'49px'}} src={poin}  /> ADD MEMBER</div>

</div>
<div  className='cardoverflowmember'>
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div>    
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
<div className='cardmember'><div className='row' > <div className='iconcarddetai' style={{width:'4%'}}><img style={{width:'100%'}} src={icon}  /></div> <div className='cardtagname'>Thanapong hongbin</div> <div className='rowname'>owner</div> <div className='seedetai'>SEE MORE</div> <div  className='iconcarddetai' style={{width:'5%',marginLeft:'5px'}}><img style={{width:'100%'}} src={bin}  /></div></div></div> 
</div>

<div className='row mt-3' >
<div style={{fontSize:'15px',marginBottom:'5px',marginTop:'15px'}}>SPACESHIP</div>
<div style={{width:'272px',fontSize:'12px',marginBottom:'5px',marginTop:'17px'}}><img style={{width:'6%',marginLeft:'160px',marginTop:'-3px'}} src={poin}  /> ADD SPACESHIP</div>
</div>
<div  className='cardoverflowspaaceship'>
<div className='cardspacship'><div className='row' >
    <div className='cardspacimg'> </div>
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>SPACESHIP</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'0px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-9%',fontWeight:'600'}}>TTT Spaceship</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-1%',padding:'0'}}>CREATE DATE : {moment(new Date()).format('DD MMM YYYY')}</div>
    </div>
    <div className='cardspacrank'> 
    <div className='col-12' style={{marginTop:'0px',padding:'0',fontSize:'9px'}}> RANK: 01</div>
    <div className='col-12' style={{marginTop:'1%',padding:'0',fontSize:'9px'}}>PEOPLE: 8/8</div>
   
    </div>
    <div style={{color:'#0066ff',fontWeight:'600',marginTop:'-28px',marginLeft:'68%',fontSize:'7px'}}>ON OPERATION</div>
</div></div>

<div className='cardspacship'><div className='row' >
    <div className='cardspacimg'> </div>
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>SPACESHIP</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'0px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-9%',fontWeight:'600'}}>TTT Spaceship</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-1%',padding:'0'}}>CREATE DATE : {moment(new Date()).format('DD MMM YYYY')}</div>
    </div>
    <div className='cardspacrank'> 
    <div className='col-12' style={{marginTop:'0px',padding:'0',fontSize:'9px'}}> RANK: 01</div>
    <div className='col-12' style={{marginTop:'1%',padding:'0',fontSize:'9px'}}>PEOPLE: 8/8</div>
   
    </div>
    <div style={{color:'#0066ff',fontWeight:'600',marginTop:'-28px',marginLeft:'68%',fontSize:'7px'}}>ON OPERATION</div>
</div></div>

<div className='cardspacship'><div className='row' >
    <div className='cardspacimg'> </div>
    <div className='cardspacdetai'> 
    <div className='col-12' style={{marginTop:'-2px',padding:'0'}}>SPACESHIP</div>
    <div className='col-12' style={{fontSize:'11px',marginTop:'0px',padding:'0'}}><div style={{width:'8%'}}><img style={{width:'100%'}} src={icon} /></div><div style={{marginLeft:'20px',marginTop:'-9%',fontWeight:'600'}}>TTT Spaceship</div></div>
    <div className='col-12'style={{fontSize:'7px',marginTop:'-1%',padding:'0'}}>CREATE DATE : {moment(new Date()).format('DD MMM YYYY')}</div>
    </div>
    <div className='cardspacrank'> 
    <div className='col-12' style={{marginTop:'0px',padding:'0',fontSize:'9px'}}> RANK: 01</div>
    <div className='col-12' style={{marginTop:'1%',padding:'0',fontSize:'9px'}}>PEOPLE: 8/8</div>
   
    </div>
    <div style={{color:'#0066ff',fontWeight:'600',marginTop:'-28px',marginLeft:'68%',fontSize:'7px'}}>ON OPERATION</div>
</div></div>
</div></>)}

      </div>
 
       

    </>)
}
export default Carddetai