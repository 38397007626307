import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal } from 'antd';
import './comment.css'
import moment from 'moment';
import dayjs from "dayjs";
import axios from "axios";
import Configs from "../../config";
import profileImg from '../../assets/img/userlogo/defultUser.png';
import { io } from 'socket.io-client';
import { generateUUID } from 'three/src/math/MathUtils.js';
import ModalConfirm from './ModalConfirm.jsx'
import Slider from "react-slick";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function CommentReport({
    isOpen,
    toggle_modal_commentReport,
    ModuleDetails,
    getTmplateAllByType,
    closeReportModal


}) {
    const [modalConfirm, setModalConfirm] = useState(false);
    const toggle_modal_confirm = () => setModalConfirm(!modalConfirm);
    const [menuIndex, setMenuIndex] = useState(null);
    const [ModuleDetail, setModuleDetail] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [userToken, setUserToken] = useState(sessionStorage.getItem('user_id'))
    const [userProfile, setUserProfile] = useState(sessionStorage.getItem('img_profile'))
    const [sandMessage, setSandMessage] = useState({
        massger: ''
    })
    const [sortNow, setSortNow] = useState({
        isSort: 'desc'
    })
    const [test, settest] = useState({
        test: ''
    })
    const [editData, setEditData] = useState({
        edit_open: false,
        edit_text: '',
        tc_comment: '',
        tc_created_name: '',
        tc_created_date: '',
        tc_id: '',
        tc_updated_date: ''
    })

    const [commentDataList, setCommentDataList] = useState([])





    useEffect(() => {
        setErrorMessage('');

        if (isOpen == true) {

            const temp = ModuleDetails || {}
            setModuleDetail(temp);
            getCommenByIDTemplate(ModuleDetails, 'desc')
        }


        // console.log('REtoy', ModuleDetails, isOpen);
    }, [isOpen, ModuleDetails])


    function formatNumberToK(value) {


        if (parseInt(value) > 999999) {
            return parseFloat(value / 1000000).toFixed(1) + 'M';
        } else if (parseInt(value) > 999) {
            return parseFloat(value / 1000).toFixed(1) + 'K';
        } else {
            return value;
        }

    }



    function getCommenByIDTemplate(listTemp, sortTem) {
        var temp = {
            tp_id: listTemp ? listTemp.tp_id : ModuleDetail.tp_id,
            sort: sortNow.isSort
            /*  tp_id: listTemp.tp_id || ModuleDetail.tp_id */
        }

        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/templateMgt/getCommentByTemplate/${temp.tp_id}/${sortTem}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            /*     data: temp */

        })
            .then(function (response) {
                /*    console.log('ดูข้อมูล',response.data); */
                if (response.data) {
                    setCommentDataList(response.data)
                    settest({ ...test, test: '' })
                    test.test = ''
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function SaveComment(tempData) {
        if (sandMessage.massger.trim() !== '' && sandMessage.massger !== null) {
            console.log('เข้้าเล้ว');
            var temp = {
                template_id: ModuleDetail.tp_id,
                comment: sandMessage.massger
            }

            axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/templateMgt/addCommentTemplate`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp

            })
                .then(function (response) {
                    setSandMessage({
                        ...sandMessage,
                        massger: ''
                    })
                    sandMessage.massger = ''
                    setSortNow({
                        ...sortNow,
                        isSort: 'desc'
                    })
                    getCommenByIDTemplate(ModuleDetail, 'desc')

                    /*  toggle_modal_commentReport() */
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    }



    async function editComment(tc_id, comment, tc_created_name, tc_created_date, tc_updated_date
    ) {
        setEditData({
            ...editData,
            edit_open: true,
            edit_text: comment,
            tc_comment: comment,
            tc_created_name: tc_created_name,
            tc_created_date: tc_created_date,
            tc_id: tc_id,
            tc_updated_date: tc_updated_date,
        })
        editData.edit_open = true
    }

    async function editClose() {
        setEditData({ ...editData, edit_open: false })
        editData.edit_open = false


    }



    async function editSubmit() {
        if (editData.edit_text.trim() !== '' && editData.edit_text !== null) {
            var temp = {
                tc_id: editData.tc_id,
                comment: editData.edit_text
            }

            axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/templateMgt/updateCommentTemplate`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            })
                .then(function (response) {
                    setSandMessage({
                        ...sandMessage,
                        massger: ''
                    })
                    sandMessage.massger = ''
                    getCommenByIDTemplate(ModuleDetail, 'desc')
                    setSortNow({
                        ...sortNow,
                        isSort: 'desc'
                    })
                    editClose()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }


    /*     function SetDayAg(date) {
            let dateCount = moment(new Date()).diff(moment(date), 'days')
            if (dateCount === 0) {
                let minute = moment(new Date()).diff(moment(date), 'minutes')
                if (parseInt(minute) >= 60) {
                    return `${Math.ceil(parseInt(minute) / 60)} HOURS AGO`
                } else if (parseInt(minute) <= 1) {
                    return `JUST NOW`
                } else {
                    return `${Math.ceil(parseInt(minute))} MINUTES AGO`
                }
            } else {
                if (parseInt(dateCount) >= 365) {
                    return `${Math.ceil(parseInt(dateCount) / 365)} YEARS AGO`
                } else {
                    return `${Math.ceil(parseInt(dateCount) / 30)} MONTHS AGO`
                }
            }
    
        } */

    function SetDayAg(date) {
        let dateCount = moment(new Date()).diff(moment(date), 'days')
        if (dateCount === 0) {
            let minute = moment(new Date()).diff(moment(date), 'minutes')
            if (parseInt(minute) >= 60) {
                return `${Math.ceil(parseInt(minute) / 60)} HOURS AGO`
            } else if (parseInt(minute) <= 1) {
                return `JUST NOW`
            } else {
                return `${Math.ceil(parseInt(minute))} MINUTES AGO`
            }
        } else {
            if (parseInt(dateCount) >= 365) {
                return `${Math.ceil(parseInt(dateCount) / 365)} YEARS AGO`
            } else if (parseInt(dateCount) >= 30) {
                return `${Math.ceil(parseInt(dateCount) / 30)} MONTHS AGO`
            } else {
                return `${Math.ceil(parseInt(dateCount))} DAYS AGO`
            }
        }
    }



    return (

        <>

            <ConfigProvider
                theme={{
                    token: {
                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',
                        colorTextDescription: '#000',
                        colorBgContainer: '#000',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: 'blue',//สีตอน Active ใน Select
                        colorBgBase: '#778899',//พื้นหลัง select ตอนกด down ลงมา
                        colorTextQuaternary: '#FFFFFF',// สีตัวหนังสือของ placeholder
                        colorFillTertiary: '#000',// สีตอน hover ใน Select
                        colorTextBase: 'blue', // ลูกศรใน datepicker & rangepicker
                        colorPrimaryBg: '#85BFFF',
                    }
                }}
            >
                <div className={`backdropModuleMenu_comment ${isOpen ? 'opened' : 'closed'}`} onClick={() => { toggle_modal_commentReport(); }}>      </div>
                <div className={`detailBoxModuleMenu_comment ${isOpen ? 'opened' : 'closed'}`} style={{ left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', width: '80%', height: '80%' }}>



                    <div className='row cutPading' style={{ height: '10%' }}>
                        <div className='col-11 mt-4' style={{ textTransform: 'uppercase' }}>
                            <div className='herdText-1'>{ModuleDetail.tp_name} {/* Software requirement specs (srs) */}</div>
                        </div>
                        <div className='col-1' style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '4px' }}>
                            <div className='toogler' onClick={() => {

                                closeReportModal();
                                getTmplateAllByType()
                            }}>
                                X
                            </div>
                        </div>

                    </div>

                    <div className='row cutPading' style={{ height: '7.5%' }}>
                        <div className='col-12 mt-2' >
                            <div className='herdText-2'>
                                <span>{formatNumberToK(parseInt(commentDataList.length || 0))} COMMENT</span>

                                <span className='boxSort'>
                                    {sortNow.isSort === 'desc' ? (<i class="fas fa-sort-amount-up"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setSortNow({
                                                ...sortNow,
                                                isSort: 'asc'
                                            })
                                            getCommenByIDTemplate(ModuleDetail, 'asc')
                                        }}
                                    > </i>) : <i class="fas fa-sort-amount-down"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setSortNow({
                                                ...sortNow,
                                                isSort: 'desc'
                                            })
                                            getCommenByIDTemplate(ModuleDetail, 'desc')
                                        }}
                                    > </i>}  &nbsp; SORT BY

                                </span>
                            </div>
                        </div>
                    </div>

                    <div className='row cutPading' style={{ height: '10%' }}>
                        <div className='col-12 mt-1'>
                            <div className='herdText-3'>
                                <img className='chatTextImg_comment'
                                    src={`${userProfile !== null && userProfile !== '' ? userProfile : profileImg}`}
                                    style={{ border: '2px solid  #3875F6' }}
                                >
                                </img>

                                <span style={{ width: '100%' }}>
                                    <Input
                                        className='inputComment'
                                        type='text' placeholder='Add A Comment...'
                                        value={sandMessage.massger}
                                        onChange={(e) => {
                                            setSandMessage({
                                                ...sandMessage,
                                                massger: e.target.value
                                            })
                                        }}
                                        onKeyDown={(item) => {
                                            if (item.keyCode === 13 || item.keyCode === '13') {
                                                SaveComment()
                                            }
                                        }}
                                    />
                                    <div className='clasSubmit-btn' onClick={() => {
                                        SaveComment()
                                    }}>
                                        comment
                                    </div>

                                </span>



                            </div>
                        </div>
                    </div>
                    <div className='row cutPading mt-4' style={{ height: '67.5%', overflowY: 'auto', display: 'flex', alignContent: 'flex-start' }}>

                        {commentDataList.length > 0 ? commentDataList.map((item, index) => {
                            /*  console.log('userToken',userToken); */
                            return (
                                <div className='row col-12 mb-2 cutPadding' >
                                    <div className='col-1 cutPading setCenterFlex' >

                                        <img className='chatTextImg_comment_line '
                                            src={`${item.ul_file_path !== null ? `${Configs.TTT_Game_Service_IMG}${item.ul_file_path}` : profileImg}`}
                                            style={{ border: '2px solid  #3875F6' }}
                                        >
                                        </img>
                                    </div>
                                    <div className='col-11' style={{ padding: '0', wordBreak: 'break-word' }} >
                                        <div className='texBoxCommentInline' >
                                            <b className='textInList' >  {item.tc_created_name}  </b>  <br />
                                            <b className='textInList-detail'>{item.tc_comment} </b>
                                            {userToken === item.tc_created_by ? <i className='fa fa-pen' style={{ marginLeft: '10px', fontSize: '10px', cursor: 'pointer' }}
                                                onClick={() => {
                                                    editComment(item.tc_id, item.tc_comment, item.tc_created_name, item.tc_created_date, item.tc_updated_date)
                                                }}
                                            ></i> : ''}

                                        </div>
                                        <span className='textInList-days'> {SetDayAg(item.tc_created_date)}{/* {moment(item.tc_created_date).format('DD/MM/YYYY HH:mm')}  */}  </span>
                                    </div>

                                    {/*   <div className='col-1'></div>
                                    <div className='col-11'>
                                  
                                    </div> */}

                                </div>)
                        })
                            : (<>

                            </>)}
















                    </div>

                    <br /><br />

                    {/*     <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                       <button className=' btn-outline-danger' style={{ padding: '5px' }} onClick={() => {

                            toggle_modal_commentReport();
                        }}> Cancel </button>
                    </div> */}




                </div>




                <div className='ModalEditComment' style={{ display: `${editData.edit_open ? '' : 'none'}`, zIndex: 9999999 }}>
                    <div className='ModalEditCommentBox'>
                        <div className='ModalEditCommentHerder'>
                            EDIT MESSAGES
                        </div>

                        <div className='ModalEditCommentBody mt-3'> <b className='BefoText'> Create by : </b>  <b className='AfterText'>{editData.tc_created_name} </b></div>
                        <div className='ModalEditCommentBody mt-3'><b className='BefoText'> Create date : </b>  <b className='AfterText'> {moment(new Date(editData.tc_created_date)).format("DD-MM-yyyy HH:mm:ss a")} </b></div>
                        <div className='ModalEditCommentBody mt-3'><b className='BefoText'> Last update : </b>  <b className='AfterText'>{editData.tc_updated_date === null || editData.tc_updated_date === undefined || editData.tc_updated_date === '' ? '-' : `${moment(new Date(editData.tc_updated_date)).format("DD-MM-yyyy HH:mm:ss a")}`} </b></div>
                        <textarea type='text'
                            id='edittext'
                            className='EditCommentBoxArea mt-3'
                            value={editData.edit_text}
                            onChange={(e) => {
                                setEditData({ ...editData, edit_text: e.target.value })
                            }}
                        >

                        </textarea>
                        <div className='commentAretButton'>
                            {/*   <div className='col-8' style={{ margin: '0', padding: '0' }}>
                               
                            </div> */}


                            <div className='row'>
                                {/*   <div className='col-2' style={{ margin: '0', padding: '0', height: '30px', }}> */}
                                <div className="editCommentEditbttTYes" onClick={() => {
                                    editSubmit()
                                }}>  SAVE</div>
                              &nbsp;
                                {/*   </div> */}


                                {/*  <div className='col-2' style={{ margin: '0', padding: '0' }}> */}
                                <div className="editCommentEditbttTYes" onClick={() => {
                                    editClose()
                                }}>CANCEL</div>
                                {/*   </div> */}
                            </div>


                        </div>
                    </div>
                </div>



            </ConfigProvider>

            {/*   <ModalConfirm isOpen={modalConfirm} toggle_modal_confirm={toggle_modal_confirm} removeMenu={[]} index={menuIndex} /> */}
            {/*     </>
      
    )} */}
        </>
    )


}
export default CommentReport;



