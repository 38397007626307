import React, { useEffect, useState, useRef } from 'react'
import "./style.css"

import { NumericFormat, PatternFormat } from 'react-number-format';


import Configs from "../../../../../config";

import axios from "axios";
import { DatePicker, Input, Tooltip, ConfigProvider, Select } from 'antd';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';

import backIcon from './Image/Icon.png'
import { isFunction } from 'lodash';
import Monthlyworksummaryreport from '../../../../component/Report/Monthlyworksummaryreport';
const { RangePicker } = DatePicker;
const { TextArea } = Input;

function FederationTimesheetMonth(params) {

    const [projectListOption, setPorjectListOption] = useState([]);

    async function getFederationWorkloadData() {

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getProjectListByFed/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let options = [];
                response.data.forEach((item) => {
                    options.push({
                        label: item.project_name,
                        value: item.project_id
                    })
                })
                setPorjectListOption(options);


            })
            .catch(function (error) {
                console.log(error);
            });
    }



    const options = [];
    for (let i = 10; i < 36; i++) {
        options.push({
            label: i.toString(36) + i,
            value: i.toString(36) + i,
        });
    }


    useEffect(() => {
        if (params.federationTimeSheetMonthReportIsOpen) {
            getFederationWorkloadData();
            getDocVersion();
            /*     getFederationWorkloadData(); */
        };

    }, [params.federationTimeSheetMonthReportIsOpen])


    const [projectSelect, setProjectSelect] = useState([]);
    const [projectSelectDetail, setProjectSelectDetail] = useState([]);
    const [test, settest] = useState({ test: '' });
    const [timeSheeMonthDetail, setTimeSheetMonthDetail] = useState({
        id: '',
        mode: 'add',
        dear: '',
        month: dayjs()/* moment(new Date()).format('YYYY-MM') */,
    })

    const [documentVersionList, setDocumentVersionList] = useState([]);

    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
    });

    const [loading, setLoading] = useState({
        status: false
    });


    function AddToprojectSelect(list) {
        setProjectSelect(list);
        let tempDetail = projectSelectDetail || [];
        for (let item of list) {
            let check = tempDetail.filter((e) => { return e.project_id == item });
            if (check.length == 0) {
                tempDetail.push({
                    project_id: item,
                    remark: ''
                })
            }

        }

        for (let item of tempDetail) {
            let check = list.filter((e) => { return e == item.project_id });
            //console.log(check)
            if (check.length == 0) {
                let findIndex = tempDetail.findIndex((e) => e.project_id == item.project_id);
                //console.log(findIndex)
                if (findIndex > -1) {
                    tempDetail.splice(findIndex, 1);
                }
            }
        }

        setProjectSelectDetail(tempDetail);
        settest({ ...test, test: '' });
        test.test = '';

    }




    function setRemarkProject(index, value) {
        let tempDetail = projectSelectDetail;
        settest({ ...test, test: value });
        test.test = value;
        tempDetail[index].remark = test.test;
        setProjectSelectDetail(tempDetail);
        settest({ ...test, test: '' });
        test.test = '';
    }


    function removeProjectSelect(index) {
        let tempDetail = projectSelectDetail;
        let temp = projectSelect;
        if (index > -1) {
            let check = temp.filter((e) => {
                return e == tempDetail[index].project_id
            });
            //console.log('check', check)
            if (check.length > 0) {
                let index_ = temp.findIndex((e) => e == tempDetail[index].project_id);
                console.log(index_)
                if (index_ > -1) {
                    temp.splice(index_, 1);
                    //console.log('temp', temp)
                    setProjectSelect([...temp]);
                    settest({ ...test, test: '' });
                    test.test = '';
                }
            }
            tempDetail.splice(index, 1);

        }

        setProjectSelectDetail(tempDetail);
        settest({ ...test, test: '' });
        test.test = '';
    }


    async function generateDoc() {
        setLoading({ ...loading, status: true });
        loading.status = true;

        let temp = {
            fed_id: params.federation_id,
            dear: timeSheeMonthDetail.dear.trim(),
            month: timeSheeMonthDetail.month,
            project_list: projectSelectDetail,

        }

        const error_list = [];

        if (temp.dear.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Dear]."
            }
            error_list.push(temp_err);
        }

        if (temp.project_list.length == 0) {
            let temp_err = {
                message: "Please select Project."
            }
            error_list.push(temp_err);
        }



        if (error_list.length > 0) {
            setLoading({ ...loading, status: false });
            loading.status = false;
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            });

            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {


            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/federation/saveDocversionTimeSheetMonth`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            })
                .then(async function (response) {
                    temp.data = response.data
                    gettimesheertreport(temp)

                })
                .catch(function (error) {
                    /*  setLoading(false); */
                    setLoading({ ...loading, status: false });
                    loading.status = false;
                    console.log(error);
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: true,
                        massger: error.response.data.error[0].errorDis
                    })
                    toggle_modal_alertKick()
                });

        }
    }

    async function generateUpdateDoc() {
        setLoading({ ...loading, status: true });
        loading.status = true;

        let temp = {
            id: timeSheeMonthDetail.id,
            fed_id: params.federation_id,
            dear: timeSheeMonthDetail.dear.trim(),
            month: timeSheeMonthDetail.month,
            project_list: projectSelectDetail,

        }

        const error_list = [];
        if (temp.id.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [id]."
            }
            error_list.push(temp_err);
        }
        if (temp.dear.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Dear]."
            }
            error_list.push(temp_err);
        }

        if (temp.project_list.length == 0) {
            let temp_err = {
                message: "Please select Project."
            }
            error_list.push(temp_err);
        }



        if (error_list.length > 0) {
            setLoading({ ...loading, status: false });
            loading.status = false;
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            });

            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {


            await axios({
                method: "POST",
                url: `${Configs.TTT_Game_Service}/api/federation/saveEditDocversionTimeSheetMonth`,
                headers: {
                    'Authorization': sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            })
                .then(async function (response) {
                    temp.data = response.data
                    gettimesheertreport(temp)

                })
                .catch(function (error) {
                    /*  setLoading(false); */
                    setLoading({ ...loading, status: false });
                    loading.status = false;
                    console.log(error);
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: true,
                        massger: error.response.data.error[0].errorDis
                    })
                    toggle_modal_alertKick()
                });

        }
    }

    async function getDocVersion() {

        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/federation/getDocversionTimeSheetMonthList/${params.federation_id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                /* 
                     for (let i of response.data) {
                         if (i.fd_img_id !== null && !isNaN(parseInt(i.fd_img_id))) {
                            let response2 = await axios.get(`https://api.box.com/2.0/files/${i.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });
                            i.href = URL.createObjectURL(response2.data);
              
                        }
                     
                     } */


                setDocumentVersionList(response.data);
                settest({ ...test, test: '' });
                test.test = '';


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function gettimesheertreport(data) {
        console.log(data);

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/Monthlyworksummaryreport`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: data
        })
            .then(async function (response) {
                console.log(response.data);
                Monthlyworksummaryreport(response.data, null, 'version', (result) => {
                    setLoading({ ...loading, status: false });
                    loading.status = false;
                    let trop = []
                    let temp_succ = {
                        message: "success"
                    }
                    trop.push(temp_succ);
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: false,
                        massger: trop
                    })
                    toggle_modal_alertKick()
                    setProjectSelect([]);
                    setProjectSelectDetail([]);
                    setTimeSheetMonthDetail({
                        ...timeSheeMonthDetail,
                        id: '',
                        mode: 'add',
                        dear: '',
                        month: dayjs(),

                    });
                    timeSheeMonthDetail.id = '';
                    timeSheeMonthDetail.mode = '';
                    timeSheeMonthDetail.dear = '';
                    timeSheeMonthDetail.month = dayjs();
                    getDocVersion();

                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    return (
        <>
            <div className='fedWorkloadBg' style={{ display: `${params.federationTimeSheetMonthReportIsOpen ? '' : 'none'}` }}>
                <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                        params.toggleFederationTimeSheetMonthReportIsOpen();
                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedWorkloadBackLine'></div>
                    <div className='fedWorkloadBackNameBox'>
                        <div className='fedWorkloadBackNameHead'>Federation Timesheet Month Report</div>
                        <div className='fedWorkloadBackNameText'>{params.federation_name}</div>
                    </div>
                </div>

                <ConfigProvider theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#001D5F',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }} >


                    <div className='fedWorkMonthBody'>
                        <div className='fedWorkMonthFilter'>
                            <div className='row' style={{ width: '50%' }}>
                                <div className='col-4'>
                                    Dear
                                    <Input className='inputFedWorkMonth'
                                        value={timeSheeMonthDetail.dear}
                                        onChange={(e) => {
                                            setTimeSheetMonthDetail({ ...timeSheeMonthDetail, dear: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className='col-4'>Month
                                    <DatePicker className='inputMonthFedWorkMonth' picker="month"
                                        /*    value={timeSheeMonthDetail.month} */

                                        disabled={timeSheeMonthDetail.mode == 'edit'}
                                        value={timeSheeMonthDetail.month}
                                        allowClear={false}
                                        onChange={(e, e2) => {
                                            //console.log(moment(e).format('DD/MM/YYYY'))
                                            setTimeSheetMonthDetail({ ...timeSheeMonthDetail, month: dayjs(e2) });
                                            //console.log(moment(e2).format('DD/MM/YYYY'))
                                        }}
                                    />
                                </div>
                                {timeSheeMonthDetail.mode != 'edit' &&
                                    <div className='col-4' style={{ overflow: 'hidden' }}>Project
                                        <Select
                                            mode="multiple"
                                            allowClear

                                            style={{
                                                borderRadius: 0,
                                                width: '100%',
                                                height: '37px'
                                            }}
                                            onChange={(e) => {
                                                //console.log(e)
                                                AddToprojectSelect(e);
                                            }}
                                            value={projectSelect}

                                            placeholder="Please select"
                                            maxTagCount='responsive'


                                            options={projectListOption}
                                        />
                                    </div>
                                }

                            </div>
                            <div style={{ display: 'flex', gap: '15px' }}>
                                {timeSheeMonthDetail.mode == 'edit' && (
                                    <div className='btnCancelGen'
                                        onClick={async () => {

                                            setProjectSelect([]);
                                            setProjectSelectDetail([]);
                                            setTimeSheetMonthDetail({
                                                ...timeSheeMonthDetail,
                                                id: '',
                                                mode: 'add',
                                                dear: '',
                                                month: dayjs(),

                                            });
                                            timeSheeMonthDetail.id = '';
                                            timeSheeMonthDetail.mode = '';
                                            timeSheeMonthDetail.dear = '';
                                            timeSheeMonthDetail.month = dayjs();
                                            /*     await generateDoc(); */
                                        }}
                                    >Cancel</div>
                                )}

                                <div className='btnGen'
                                    onClick={async () => {
                                        if (timeSheeMonthDetail.mode == 'edit') {
                                            await generateUpdateDoc();
                                        } else {
                                            await generateDoc();
                                        }

                                    }}
                                >Generate</div>
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', paddingLeft: '10px', height: '40%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr className='testtest' style={{ position: 'sticky', top: 0, zIndex: 3, maxHeight: '37px' }}>
                                <td style={{ textAlign: 'center', width: '30%' }}>Project</td>
                                <td style={{ textAlign: 'center', width: '60%' }}>Remark</td>
                                {timeSheeMonthDetail.mode != 'edit' && <td style={{ textAlign: 'center', width: '10%' }}>Management</td>}
                            </tr>

                            {projectSelectDetail.map((e, index) => {
                                let project_name = projectListOption.filter((p) => { return p.value == e.project_id });
                                return (<>
                                    <tr style={{ height: '37px', background: 'rgba(0,0,0,0.1)' }}>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>{project_name.length > 0 ? project_name[0].label : '-'}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>
                                            <TextArea
                                                placeholder=""
                                                autoSize={{
                                                    minRows: 2,
                                                    maxRows: 6,
                                                }}
                                                value={e.remark}
                                                onChange={(e2) => {
                                                    setRemarkProject(index, e2.target.value)
                                                }}
                                            />
                                        </td>
                                        {timeSheeMonthDetail.mode != 'edit' && (<td style={{ textAlign: 'center' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className='btnRed'
                                                    onClick={() => {
                                                        removeProjectSelect(index);
                                                    }}
                                                    style={{ tidth: '20px', display: 'flex', justifyContent: 'center', fontSize: '20px', paddingTop: '0px', cursor: 'pointer' }}><DeleteOutlined /></div>
                                            </div>
                                        </td>)}
                                    </tr>
                                </>)
                            })}
                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                    <div style={{ borderBottom: '1px solid #fff', marginLeft: '10px' }}></div>
                    <div style={{ display: 'flex', paddingLeft: '10px', height: '40%', overflowY: 'auto', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr className='testtest' style={{ position: 'sticky', top: 0, zIndex: 3, maxHeight: '37px' }}>
                                <td style={{ textAlign: 'center', width: '10%' }}>No.</td>
                                <td style={{ textAlign: 'center', width: '10%' }}>Date</td>
                                <td style={{ textAlign: 'center', width: '70%' }}>Generate Version</td>
                                <td style={{ textAlign: 'center', width: '10%' }}>MANAGEMENT</td>
                            </tr>
                            {documentVersionList.map((e, index2) => {
                                let bg = 'rgba(0,0,0,0.1)';
                                if (e.ftm_id == timeSheeMonthDetail.id) {
                                    bg = 'rgba(0, 71, 255, 0.15)';
                                }
                                return (<>
                                    <tr style={{ height: '37px', background: bg }}>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>{documentVersionList.length - index2}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>{moment(e.ftm_created_date).format('DD/MM/YYYY HH:mm')}</td>
                                        <td style={{ textAlign: 'center', fontSize: '18px', padding: '5px' }}>
                                            {`${e.ftm_filename}.pdf`}
                                        </td>
                                        <td style={{ textAlign: 'center', fontSize: '24px', color: '#2894DC', display: 'flex', gap: '15px', justifyContent: 'center' }}>

                                            <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                setTimeSheetMonthDetail({
                                                    ...timeSheeMonthDetail,
                                                    mode: 'edit',
                                                    id: e.ftm_id,
                                                    dear: e.ftm_dear,
                                                    month: dayjs(e.ftm_month),

                                                });
                                                setProjectSelectDetail(e.ftm_project_list.project_list);
                                                let ob = [];
                                                e.ftm_project_list.project_list.forEach((f) => {
                                                    ob.push(f.project_id)
                                                });
                                                setProjectSelect(ob);
                                            }} />

                                            <a /* href = {`${e.href}`} download={`${e.ftm_filename}.pdf`} */ target='_blank'
                                                onClick={async () => {

                                                    if (e.fd_img_id !== null && !isNaN(parseInt(e.fd_img_id))) {
                                                        let response2 = await axios.get(`https://api.box.com/2.0/files/${e.fd_img_id}/content`, {
                                                            headers: {
                                                                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                                            },
                                                            responseType: 'blob',
                                                        });

                                                        // Create a temporary anchor element
                                                        const a = document.createElement('a');
                                                        a.href = URL.createObjectURL(response2.data);
                                                        a.download = `${e.ftm_filename}.pdf`; // Set the desired filename here

                                                        // Append the anchor to the body
                                                        document.body.appendChild(a);

                                                        // Trigger a click on the anchor to open the file in a new window/tab
                                                        a.click();

                                                        // Clean up: remove the anchor and revoke the object URL
                                                        document.body.removeChild(a);
                                                        URL.revokeObjectURL(URL.createObjectURL(response2.data));
                                                        /*    console.log(response2)
                                                           window.open(
                                                            URL.createObjectURL(response2.data),
                                                            '_blank', // <- This is what makes it open in a new window.
                                                    
                                                            ); */


                                                    }


                                                }}
                                            >
                                                <DownloadOutlined style={{ cursor: 'pointer' }} /* onClick={(e)=>{

                                        }} *//>
                                            </a>

                                        </td>
                                    </tr>
                                </>)
                            })}
                        </table>
                        <div style={{ height: '100%', background: 'rgba(0,0,0,0.1)' }}></div>
                    </div>
                </ConfigProvider>




                {loading.status && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'red' : 'green'}` }}>
                                        {errorMasgerList.isError ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {errorMasgerList.massger.map((e) => {

                                            return <div> {e.message} </div>

                                        })}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {

                                        /*    if (displayPassword === true && errorMasgerList.isError !== true) {
                                               sessionStorage.clear(); window.location.href = '/'
                                           } */
                                        if (errorMasgerList.isError === false) {
                                            /*    setPersonalInfoCheck({
                                                   isOpen: false
                                               }) */
                                            /*       getInfomaionUserByID() */
                                            /*    setMenu('PROJECT') */
                                        }

                                        /*      setDisplayPassword(false) */
                                        toggle_modal_alertKick()
                                        setErrorMasgerList({
                                            ...errorMasgerList,
                                            isError: false,
                                            massger: []
                                        })
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FederationTimesheetMonth;
