import React from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom';


const FeatureAreaTimely = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="featured-game-area new-released-game-area pt-50 pb-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section-title home-four-title black-title text-center mb-30">
              <h2>
                Trailblazer
                <br />
                <span>Marketing & Finance</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid container-full">
        <Slider
          className="row no-gutters new-released-game-active"
          {...settings}
        >
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb01.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">
                    Marketing &<span> PR</span>
                  </Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">
                    Marketing &<span> PR</span>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb02.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">
                    Finance TTT &<span> Cus</span>
                  </Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">
                    Finance TTT &<span> Cus</span>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb03.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">
                    HRM &<span> HRD</span>
                  </Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">
                    HRM &<span> HRD</span>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb04.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">
                    R &<span> D</span>
                  </Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">
                    R &<span> D</span>
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb02.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">CRM</Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">CRM</Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="col-lg">
            <div className="featured-game-item mb-30">
              <div className="featured-game-thumb">
                <img
                  src="assets/img/images/s_featured_game_thumb03.jpg"
                  alt=""
                />
              </div>
              <div className="featured-game-content">
                <h4>
                  <Link to="/detailClass">Channel</Link>
                </h4>
              </div>
              <div className="featured-game-content featured-game-overlay-content">
                <div className="featured-game-icon">
                  <img src="assets/img/icon/featured_game_icon.png" alt="" />
                </div>
                <h4>
                  <Link to="/detailClass">Channel</Link>
                </h4>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default FeatureAreaTimely;
