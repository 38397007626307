import React, { useState, useEffect } from 'react';
import Menu from "../../Menu";
import Pageall from "./board/pageall";
import Pagenew from "./board/pagenew";
import PagenewMain from "./board/paganewMain";
import PageallOlnyMainNews from "./board/pageallOlnyMainNews";
import Title from "../../../components/customElement/headertitle"
import Fade from "react-reveal/Fade";
import axios from "axios";
import Configs from "../../../config";

export default function Mianpage() {

  const [memuheader, setmemuheader] = useState({
    menuon: 'ALL',
    menu1: true,
    onset: 'l',
    num: 0,
  });

  const [filterNews, setFilterNews] = useState({

  })
  const [test, settest] = useState({
    test: ''
  })
  const [newStoryTemp, setStoryTemp] = useState([])
  const [newActivityTemp, setActivityTemp] = useState([])
  const [newPromotionTemp, setPromotionTemp] = useState([])
  const [newAlllNewsTemp, setAllNewsTemp] = useState([])
  const [newHighlightTemp, setHighlightTemp] = useState([])



  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {

        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }



  async function setImginStage(fd_img_id) {
    try {
      let response2 = await axios.get(`https://api.box.com/2.0/files/${fd_img_id}/content`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
        },
        responseType: 'blob',
      });

      return URL.createObjectURL(response2.data)

    } catch {
      return null
    }

  }
  async function filterNewsInfo(value) {

    let temp = {
      keySearch: value,
      page: 1,
      enties: 9999,
      sortList: {},
    };

    await axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/news/filterNewsinspace`,
      headers: {
        /*  Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          let getStory = response.data.data.filter((e) => e.kn_name === "TTT SPACESHIP STORY" /* && e.news_is_highlight !== true */) || []
          let getActive = response.data.data.filter((e) => e.kn_name === "ACTIVITY & NEWS" /* && e.news_is_highlight !== true */) || []
          let getPromotion = response.data.data.filter((e) => e.kn_name === "PROMOTION" /* && e.news_is_highlight !== true */) || []
          let getHightLing = response.data.data.filter((l) => l.news_is_highlight === true) || []
          let getAllNOTHightLing = response.data.data.filter((l) => l.news_is_highlight !== true) || []

          setAllNewsTemp(response.data.data);
          
          for (let item = 0; item < response.data.data.length; item++) {
            response.data.data[item].path_file = null
            if (response.data.data[item].imgobject) {
              let getpath = await setImginStage(response.data.data[item].imgobject.fd_img_id);

              response.data.data[item].path_file = getpath;
              setAllNewsTemp(response.data.data);
              settest({ ...test, test: '' })
              test.test = '';
            }
          }

          setStoryTemp(getStory);
          setActivityTemp(getActive);
          setPromotionTemp(getPromotion);
          setAllNewsTemp(response.data.data);

          console.log('getHightLing',getHightLing);
          setHighlightTemp(getHightLing);

          settest({ ...test, test: '' })
          test.test = '';

          /*  console.log('dataTEMP_',getAllNOTHightLing); */
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetAccesstokenToken((result) => {
      if (result) {
        filterNewsInfo()
      }
    })

  }, [])

  return (
    <>
      <section >

        <div className="game-mange-bg" style={{ position: "relative" }}>
          <Fade left delay={100} >
            {/*  <img className='bg-information'  src="../../assets/img/bg/imgmian.png" alt="" /> */}

            <div style={{ position: "relative", paddingLeft: '9%', paddingRight: '9%', height: '100%', width: '100%' }}>
              <div className='row ' style={{ marginBottom: '40px', marginLeft: '2.7px' }}>
                <div style={{ width: '300px' }}>
                  <Title name='BULLETIN BOARD' no={1} fix={260} imglong={145} top={7.5} imgbox={110} imgcorner={12} />
                </div>
                <div style={{ width: '700px', marginTop: '15px', zIndex: 150 }}>
                  <div className='row ' >
                    <div className='fontheader ' style={{ width: '50px' }}
                      onClick={() => {
                        if (memuheader.menuon !== 'ALL') {
                          setmemuheader({
                            ...memuheader,
                            /*  menuon: 'ALL', */
                            onset: memuheader.num > 0 ? 'r' : 'l',
                            menu1: false
                          });
                          // console.log(memuheader.num, memuheader.onset);
                          memuheader.onset = memuheader.num > 0 ? 'r' : 'l'
                          memuheader.num = 0
                          memuheader.menuon = 'ALL'
                          setTimeout(function () {
                            setmemuheader({
                              ...memuheader,
                              menu1: true,
                            });
                          }, 420);
                        }

                      }}>
                      <font className={memuheader.menuon === 'ALL' ? 'fontborderfocus' : 'fontborder'} >
                        ALL
                      </font>
                    </div>
                    <div className='fontheader' style={{ width: '210px' }}
                      onClick={() => {
                        if (memuheader.menuon !== 'ACTIVITY') {
                          setmemuheader({
                            ...memuheader,
                            /*  menuon: 'ACTIVITY', */
                            onset: memuheader.num >= 1 ? 'r' : 'l',

                            menu1: false,

                          });
                          // console.log(memuheader.num, memuheader.onset);
                          memuheader.onset = memuheader.num > 1 ? 'r' : 'l'
                          memuheader.num = 1
                          memuheader.menuon = 'ACTIVITY'
                          setTimeout(function () {
                            setmemuheader({
                              ...memuheader,
                              menu1: true,
                            });
                          }, 420);
                        }
                      }}>
                      <font className={memuheader.menuon === 'ACTIVITY' ? 'fontborderfocus' : 'fontborder'} >
                        ACTIVITY & NEWS
                      </font>
                    </div>

                    <div className='fontheader' style={{ width: '150px' }}
                      onClick={() => {
                        if (memuheader.menuon !== 'PROMOTION') {

                          setmemuheader({
                            ...memuheader,
                            /*   menuon: 'PROMOTION', */
                            onset: memuheader.num > 2 ? 'r' : 'l',

                            menu1: false,

                          });
                          // console.log(memuheader.num, memuheader.onset);
                          memuheader.onset = memuheader.num > 2 ? 'r' : 'l'
                          memuheader.num = 2
                          memuheader.menuon = 'PROMOTION'
                          setTimeout(function () {
                            setmemuheader({
                              ...memuheader,
                              menu1: true,
                            });
                          }, 420);
                        }
                      }}>
                      <font className={memuheader.menuon === 'PROMOTION' ? 'fontborderfocus' : 'fontborder'} >
                        PROMOTION
                      </font>
                    </div>
                    <div className='fontheader' style={{ width: '270px' }}
                      onClick={() => {
                        if (memuheader.menuon !== 'SPACESHIP') {
                          setmemuheader({
                            ...memuheader,
                            /*  menuon: 'SPACESHIP', */
                            onset: memuheader.num > 3 ? 'r' : 'l',

                            menu1: false,

                          });
                          // console.log(memuheader.num, memuheader.onset);
                          memuheader.onset = memuheader.num > 3 ? 'r' : 'l'
                          memuheader.num = 3
                          memuheader.menuon = 'SPACESHIP'
                          setTimeout(function () {
                            setmemuheader({
                              ...memuheader,
                              menu1: true,
                            });
                          }, 420);
                        }
                      }}>
                      <font className={memuheader.menuon === 'SPACESHIP' ? 'fontborderfocus' : 'fontborder'} >
                        TTT SPACESHIP STORY
                      </font>
                    </div>
                  </div>
                  <div className="mainreadmores">
                    READMORE
                  </div>
                </div>

              </div>

              {/* {console.log('นั้นอะไรครับ', memuheader.onset)} */}
              {memuheader.onset === 'l' ? (<>
                <Fade right delay={100} opposite when={memuheader.menu1}>
                  {memuheader.menuon === 'ALL' ? (<> <Pageall data={newAlllNewsTemp} newHighlightTemp ={newHighlightTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'ACTIVITY' ? (<> <PagenewMain data={newActivityTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'PROMOTION' ? (<> <PagenewMain data={newPromotionTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'SPACESHIP' ? (<> <PagenewMain data={newStoryTemp} /></>) : (<></>)}
                </Fade>
              </>) : (<>
                <Fade left delay={100} opposite when={memuheader.menu1}>
                  {memuheader.menuon === 'ALL' ? (<> <Pageall data={newAlllNewsTemp}  newHighlightTemp ={newHighlightTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'ACTIVITY' ? (<> <PageallOlnyMainNews data={newActivityTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'PROMOTION' ? (<> <PageallOlnyMainNews data={newPromotionTemp} /></>) : (<></>)}
                  {memuheader.menuon === 'SPACESHIP' ? (<> <PageallOlnyMainNews data={newStoryTemp} /></>) : (<></>)}
                </Fade>
              </>)}



            </div>
          </Fade>
        </div>

      </section>
    </>
  )
}
