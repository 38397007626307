import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal } from 'antd';
import './Topup.css'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFileLines } from '@fortawesome/free-solid-svg-icons';
import dayjs from "dayjs";
import axios from "axios";
import Configs from "../../../config";
import profileImg from '../../../assets/img/userlogo/defultUser.png';
import ModelAlertConfrim from "../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../components/AlertMassge/AlertMassge";

import icon_sapphire from '../../../assets/img/iconSpaceShip/Frame.png';
import svg_sapphire from './sapphireSvg.svg';
import icon_Coin from './coinSVG.svg';
import icon_arrov from './arrow.svg';

import circleBg from './img/frame.png'
import boxImg from './img/box.png'
import lineTop from './img/top.png'
import lineBottom from './img/under.png'
import transferImg from './img/exchange.png';
import coinImg from './img/Group.png'
import sapImg from './img/sap.png'
/* import QRCodeToptup from './img/qrcodetopup.jpg'; */
import QRCodeToptup from './img/QrcodeSpaceshipAdmin.PNG';
import spSelct from './img/spSelct.png';
/* import { Select } from 'antd'; */
import IconFlagGermany from "./IconCompanent.svg";

import Select, { components } from 'react-select';

function TopupIndex({
    isOpen,
    toggle_modal_ModalTopUp,
    getAmountSapphireFnc
}) {
    const [modalConfirm, setModalConfirm] = useState(false);
    const toggle_modal_confirm = () => setModalConfirm(!modalConfirm);
    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })
    const [sapphireData, setSapphireData] = useState(50000000);
    const [fileList, setFileList] = useState([]);

    const [redeemCode, setRedeemCode] = useState({
        code: '',
    })



    const [optionRatePrice, setOptionRatePrice] = useState([
        { value: '1000', label: '1,000 Baht', icon: spSelct, sub: '10 K' },
        { value: '2000', label: '2,000 Baht', icon: spSelct, sub: '20 K' },
        { value: '5000', label: '5,000 Baht', icon: spSelct, sub: '50 K' },
        { value: '10000', label: '10,000 Baht', icon: spSelct, sub: '100 K' },
        { value: '20000', label: '20,000 Baht', icon: spSelct, sub: '200 K' },
    ])

    const [checkSelected, setCheckSelect] = useState(true)

    /*     const SingleValue = ({ children, ...props }) => {
    
            return (
                <components.SingleValue {...props}>
                    {children}
                </components.SingleValue>
            )
        }; */

    const Option = (props) => {
        console.log('props', props.data);
        return (
            <components.Option {...props} >
                <div className='row' style={{ padding: '0px' }}>
                    <div className='col-6 col-xl-2' style={{/*  border: '1px solid red', */ }}  >
                        <div className='boxImgSppSelect' style={{ width: '28px', height: '35' }}>
                            <img src={props.data.icon} alt="logo" style={{ width: '16px', height: '17px', padding: '0px' }} />
                        </div>



                    </div>
                    <div className='col-6 col-xl-9 textIconSelect' style={{/*  border: '1px solid green' */ }} >
                        <div className='row' style={{ padding: '4px 0px 0px 0px ' }}>
                            <div className='col-3 col-xl-4' style={{ textAlign: 'start', padding: '0px' }}>
                                {props.data.sub}
                            </div>
                            <div className='col-9  col-xl-8' style={{ textAlign: 'end', padding: '0px 8px 0px 0px' }}>
                                {props.data.label}
                            </div>
                        </div>


                    </div>

                </div>


            </components.Option>
        )
    };



    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

    const [TopUpAlert, setTopUpAlert] = useState(false);
    const toggle_modal_TopUpAlert = () => setTopUpAlert(!TopUpAlert);

    const [errorMessage, setErrorMessage] = useState('')
    const [userToken, setUserToken] = useState(sessionStorage.getItem('user_id'))

    const [test, settest] = useState({
        test: ''
    })
    const [TopupSaveData, setTopupSaveData] = useState({
        price_amount: '',
        subamount: ''
    })



    function getAmountSapphire() {

        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/sapphire/getAmountSapphire/${sessionStorage.getItem('user_id')}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            /* data: temp, */
        })
            .then(async function (response) {
                setSapphireData(response.data || 0)
            })
            .catch(function (error) {

                console.log(error);
            });
    }

    useEffect(() => {
        setErrorMessage('');

        if (isOpen == true) {
            getAmountSapphire()
            setCheckSelect(false)
            setFileList([])
            setRedeemCode({ ...redeemCode, code: '' });
            redeemCode.code = '';
            setTopupSaveData({
                ...TopupSaveData,
                price_amount: '',
                subamount: ''
            })
            TopupSaveData.price_amount = "";
            TopupSaveData.subamount = "";


        }
        // console.log('open topup', isOpen);
    }, [isOpen])






    /* 
    
      <div className='col-1' style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '4px' }}>
        <div className='toogler' onClick={() => { }}>
                X
        </div>
    </div>
    
    
    */



    const iconStyle = {
        color: '#3E3E3E',
        fontSize: '16px',
        marginTop: '2px',
    };
    const iconStylefile = {
        color: '#fff',
        fontSize: '16px',
        marginTop: '2px',
        textShadow: '-1px 0 #2400B5, 0 1px #2400B5, 1px 0 #2400B5, 0 -1px #2400B5',
        filter: 'drop-shadow(0px 0px 1px #2400B5)'

    };


    function isFileDoc(file) {

        const acceptedImageTypes = [
            'image/gif',
            'image/jpeg',
            'image/png',
        ];
        /*   const type = file.type; */
        let ll = []
        for (let item of file) {
            let x = item.type;
            let value = acceptedImageTypes.includes(x);
            if (value === false) {
                return false
            } else {
                ll.push(value)
            }
            console.log('เช็ต Type', value);
        }


        return true;
    }

    async function getFileBoxByIdImage(image_id, callback) {
        axios({
            method: "get",
            url: `https://api.box.com/2.0/files/${image_id}/content`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',

        })
            .then(async function (response) {
                if (callback) {
                    let tmp = {
                        fileImg: URL.createObjectURL(response.data),
                        file_id: image_id,

                    }
                    callback(tmp);
                }

            }).catch((err) => {

                if (callback) {
                    let tmp = {
                        fileImg: null,
                        file_id: null,

                    }
                    callback(tmp);
                }
            })

    }



    async function CheckAndCreateFolderBox(top_id,folder_name,callback){
        let temp = {
          folderLis:["PAYMENT"]
        }
        await axios({
          method: "POST",
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
          },data:temp,
      })
          .then(async function (response) {
            if(response.data){
              let  folder_id = response.data.folder_id;       
              if(callback){
                let res = {
                  ref_id:top_id,
                  folder_id:folder_id,
                  folder_name:folder_name,
                }
                callback(res)
              }
         
              /* UploadFiles(folder_id,folder_name,fed_id,file); */
            }
    
          })
          .catch(function (error) {
              console.log(error);
          });
      }



      async function UploadFiles(folder_id,key,ref_id,file,callback){
        let image_id_list =  [];
        let data_ = new FormData();
        data_.append('File',file);
        data_.append('folder_id',folder_id);
        data_.append('key',key);
        data_.append('image_old_list',JSON.stringify(image_id_list));
        data_.append('ref_id',ref_id);
    
        await axios({
          method: "POST",
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "multipart/form-data",
          },data:data_,
      })
          .then(async function (response) {
            let  temp = /* fileUploadList || */ [];
            for(let item of response.data.image_id_list){
              temp.push(item);
            }
            /* setFileUploadList(temp); */
            settest({...test,test:''});
            test.test = '';
            if(callback){
             
              callback(true)
            }
        
    
          })
          .catch(function (error) {
              console.log(error);
          });
      }



    async function PushBuyManny(params) {
        setModalConfirm(false)
        const error_list = [];
        if (fileList.length == 0) {
            let temp_err = {
                message: "Please upload proof of money transfer."
            }
            error_list.push(temp_err);
        }

        if (TopupSaveData.price_amount == null || TopupSaveData.price_amount == "") {
            let temp_err = {
                message: "Please select the amount you want to transfer."
            }
            error_list.push(temp_err);
        }

        if (error_list.length > 0) {

            var err_message = [];
            for (var e = 0; e < error_list.length; e++) {
                err_message.push(error_list[e].message);
            }
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })

            setAlertKickMassge(true)
        } else {

            var temeSave = {
                topup_slip_name: null/* SpileSave.length > 0 ? SpileSave[0].name : '' */,
                topup_slip_path: null/* SpileSave.length > 0 ? SpileSave[0].path : '' */,
                topup_rate_price: TopupSaveData.price_amount || null
            }
            await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/sapphire/buySapphireByUser`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temeSave
            })
                .then(async function (response) {
                    if (response.data) {
                        let file = fileList[0].image_file || []
                        await GetAccesstokenToken(async (resultToken) => {
                            if (resultToken) {
                                await CheckAndCreateFolderBox(response.data.id, "PAYMENT", async (resultFolder) => {
                                    if(resultFolder){
                                        await UploadFiles(resultFolder.folder_id,resultFolder.folder_name,resultFolder.ref_id,file,async (result2)=>{
                                            if(result2){
                                                setFileList([])
                                                setTopupSaveData({
                                                    ...TopupSaveData,
                                                    price_amount: '',
                                                    subamount: ''
                                                })
                                                setMessageBox({
                                                    ...messageBox,
                                                    message: 'success',
                                                    isError: false,
                                                    menuTitle: 'Save'
                                                })
                                                setCheckSelect(false)
                                                toggle_modal_alertKickMassge()
                                                
                                            }
                                        })
                                    }
                                })
                            }
                        })
                        /*   setFileList([])
                          setTopupSaveData({
                              ...TopupSaveData,
                              price_amount: '',
                              subamount: ''
                          })
                          setMessageBox({
                              ...messageBox,
                              message: 'success',
                              isError: false,
                              menuTitle: 'Save'
                          })
                          setCheckSelect(false)
                          toggle_modal_alertKickMassge()
   */
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    toggle_modal_alertKickMassge()
                });


            /*   if (fileList.length > 0) {
                  console.log('fileList', fileList);
                  const dataImg = new FormData();
                  for (let i = 0; i < fileList.length; i++) {
                      console.log('fileList[i]', fileList[i]);
                      dataImg.append('Profile', fileList[i].image_file);
                  }
                  dataImg.append('typeFile', "SpaceShipIMGSlip");
                  dataImg.append('projectKey', "SlipByUser");
                  await axios({
                      method: 'post',
                      url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                      headers: {
                          Authorization: sessionStorage.getItem('access_token'),
                          'X-TTT': `${Configs.API_TTT}`,
                          'Content-Type': 'application/octet-stream',
                      },
                      data: dataImg,
                  })
                      .then(async function (response) {
                          if (response.data.status == true) {
                              let SpileSave = []
                              response.data.data.forEach((e) => {
                                  const tmp = {
                                      name: e.orgin_name,
                                      path: e.path,
                                      is_save: true,
                                      ul_is_use: true,
                                      ul_file_size: e.size,
                                      url: e.path,
                                      ul_type: "IMG",
                                      ul_group_name: "document user",
                                      ul_file_name: e.orgin_name,
                                      ul_file_path: e.path,
                                      ul_menu_name: "document user",
                                      ul_is_profile: false,
                                      status: 'done'
  
                                  }
                                  SpileSave.push(tmp)
                              })
  
                              if (SpileSave.length > 0) {
                                  var temeSave = {
                                      topup_slip_name: SpileSave.length > 0 ? SpileSave[0].name : '',
                                      topup_slip_path: SpileSave.length > 0 ? SpileSave[0].path : '',
                                      topup_rate_price: TopupSaveData.price_amount || null
                                  }
                                  await axios({
                                      method: 'post',
                                      url: `${Configs.TTT_Game_Service}/api/sapphire/buySapphireByUser`,
                                      headers: {
                                          Authorization: sessionStorage.getItem('access_token'),
                                          'X-TTT': `${Configs.API_TTT}`,
                                          'Content-Type': 'application/json',
                                      },
                                      data: temeSave
                                  })
                                      .then(async function (response) {
                                          if (response.data) {
  
                                              setFileList([])
                                              setTopupSaveData({
                                                  ...TopupSaveData,
                                                  price_amount: '',
                                                  subamount: ''
                                              })
                                              setMessageBox({
                                                  ...messageBox,
                                                  message: 'success',
                                                  isError: false,
                                                  menuTitle: 'Save'
                                              })
                                              setCheckSelect(false)
                                              toggle_modal_alertKickMassge()
  
                                          }
                                      })
                                      .catch(function (error) {
                                          console.log(error);
                                      });
                              } else {
                                  setMessageBox({
                                      ...messageBox,
                                      message: { message: 'Processed too quickly. Please try again.' },
                                      isError: true,
                                      menuTitle: 'ERROR'
                                  })
  
                                  setAlertKickMassge(true)
                              }
                          } else {
                              setMessageBox({
                                  ...messageBox,
                                  message: { message: 'Unable to upload file' },
                                  isError: true,
                                  menuTitle: 'ERROR'
                              })
                              setAlertKickMassge(true)
                          }
  
                      })
                      .catch(function (error) {
                          console.log(error);
                      });
              } */
        }
       
    }


    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



    function submitRedeemCode() {
        let temp = {
            code: redeemCode.code
        }
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/sapphire/redeemCode`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp,
        })
            .then(async function (response) {

                setMessageBox({
                    ...messageBox,
                    message: 'Redeem Code Successfuly',
                    isError: false,
                    menuTitle: 'SUCCESS'
                });
                toggle_modal_alertKickMassge();
                getAmountSapphireFnc();
                setRedeemCode({ ...redeemCode, code: '' });
                redeemCode.code = '';
            })
            .catch(function (error) {
                console.log(error);
                setMessageBox({
                    ...messageBox,
                    message: error.response.data.error[0].errorDis,
                    isError: null,
                    menuTitle: 'WARNING'
                });
                toggle_modal_alertKickMassge();
            });
    }





    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }







    return (

        <>


            <div className={`backdropModule_Topup ${isOpen ? 'opened' : 'closed'}`} >
                <div className='topupBgTopRight'></div>
                <div className='topupBgButtomLeft'></div>
                <div className='topupBgButtomRight'></div>
                <div className='topupBgTopLeft'>
                    <div className='topupBgTopLeft1'></div>
                    <div className='topupBgTopLeft2'></div>
                    <div className='topupBgTopLeft2'></div>
                    <div className='topupBgTopLeft2'></div>
                    <div className='topupBgTopLeftLine1'></div>
                    <div className='topupBgTopLeftLine2'></div>
                </div>
            </div>
            <div className={`detailBoxModuleMenu_Topup ${isOpen ? 'opened' : 'closed'}`} style={{ left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', width: '69%', height: '80%' }}>
                <div className='close-btn' onClick={() => { toggle_modal_ModalTopUp(); }} > X </div>
                <div className='topupSet'>
                    <div className='topupDetail'>
                        <img className='topupDetailCircle' src={circleBg}></img>
                        <img className='topupDetailBoxImg' src={boxImg}></img>
                        <img className='topupDetailLineTop' src={lineTop}></img>
                        <img className='topupDetailCenter' src={transferImg}></img>
                        <img className='topupDetailLineTop' src={lineBottom}></img>
                        <div className='topupSetImg'>
                            <img className='topupDetailCoin' src={coinImg}></img>
                            <img className='topupDetailSap' src={sapImg}></img>
                        </div>
                        <div className='topupSetAmount'>
                            <div className='topupCoinAmount'>1 BAHT</div>
                            <div className='topupSapAmount'>10 SAPPHIRE</div>
                        </div>

                    </div>
                    <div className='areabox-left'>
                        <div className='TopupScripath'>
                            <b className='textTopup'> TOP UP </b>
                        </div>


                        <div className='classIMGSilp' style={{ backgroundImage: `url(${QRCodeToptup})` }} />


                        <div className='selectTopup'>
                            {checkSelected === false ?
                                <Select
                                    placeholder="Transfer amount"
                                    isSearchable={false}
                                    styles={{
                                        minHeight: '46px !important',
                                        backgroundColor: '#0047FF',
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,

                                            "&:hover": {
                                                borderColor: "#0047FF",
                                                color: "#fff"
                                            },
                                            fontSize: '16px',
                                        }),
                                        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                            ...styles,
                                            color: isSelected ? '#fff' : '#0047FF',
                                            backgroundColor: isSelected ? '#0047FF' : '#fff',
                                            textAlign: 'end',
                                            border: 'none !important',
                                            "&:hover": {
                                                border: 'none !important',
                                                backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                                                color: "#fff"
                                            },
                                        }),

                                    }}
                                    options={optionRatePrice}
                                    components={{
                                        Option,
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#0047FF',
                                        },
                                    })}
                                    className='selectDroup-toup'
                                    value={optionRatePrice.filter((e) => { return e.value === TopupSaveData.price_amount })}
                                    onChange={(e) => {
                                        setCheckSelect(true)
                                        setTopupSaveData({
                                            ...TopupSaveData,
                                            price_amount: e.value,
                                            subamount: e.sub
                                        })
                                    }}
                                />
                                : <div className='row' style={{ padding: '0px' }}>
                                    <div className='col-4' style={{ padding: '0px'/* , border: '1px solid red'  */ }}>
                                        <div className='textAfterSelect' >
                                            <div className='row' style={{ padding: '5px' }}>
                                                <div className='col-4' >
                                                    <div className='boxImgSppSelect' style={{ height: '30px', width: '30px', marginTop: '-2px' }}>
                                                        <img src={spSelct} alt="logo" style={{ width: '18px', height: '19px', padding: '0px', marginTop: '3px' }} />
                                                    </div>

                                                </div>
                                                <div className='col-8 textIconSelect' style={{ color: '#FFF', marginTop: '4px', fontSize: '17' }}>
                                                    {TopupSaveData.subamount}
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-8' style={{ padding: '0px' }}>
                                        <Select
                                            placeholder="Transfer amount"
                                            isSearchable={false}
                                            styles={{
                                                minHeight: '46px !important',
                                                backgroundColor: '#FFF !important',
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,

                                                    "&:hover": {
                                                        borderColor: "#fff",
                                                        color: "#0047FF"
                                                    },
                                                    fontSize: '16px',
                                                }),
                                                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                                                    ...styles,
                                                    color: isSelected ? '#fff' : '#0047FF',
                                                    backgroundColor: isSelected ? '#0047FF' : '#fff',
                                                    textAlign: 'end',
                                                    border: 'none !important',
                                                    "&:hover": {
                                                        border: 'none !important',
                                                        backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                                                        color: "#fff"
                                                    },
                                                }),

                                            }}
                                            options={optionRatePrice}
                                            components={{
                                                Option,
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 0,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: '#fff',
                                                },
                                            })}
                                            className='selectDroup-toup-fack'
                                            value={optionRatePrice.filter((e) => { return e.value === TopupSaveData.price_amount })}
                                            onChange={(e) => {
                                                setTopupSaveData({
                                                    ...TopupSaveData,
                                                    price_amount: e.value,
                                                    subamount: e.sub
                                                })
                                            }}
                                        />
                                    </div>


                                </div>}





                        </div>

                        <div className='area-upload'>
                            <div className='area-bg'>
                                <div className='area-upload-text'>Transfer slip</div>
                                <label for="upload-btn-sengle" style={{ top: '2px', height: '53%', position: 'relative', left: '-64px', width: '98%', clipPath: 'polygon(0 0, 91% 0%, 100% 100%, 0% 100%)' }} type="button"
                                    className='inputUpload'>
                                    {fileList.length > 0 ? fileList.map((e) => {
                                        console.log('file', e);
                                        return (
                                            <div className='fileListBox' >
                                                <FontAwesomeIcon icon={faFileLines} style={iconStylefile} /> {e.ul_file_name}
                                            </div>

                                        )
                                    }) : <> <FontAwesomeIcon icon={faUpload} style={iconStyle} />  UPLOAD</>}

                                </label>

                                <input id="upload-btn-sengle" hidden type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        console.log('ASDASDASD', isFileDoc(e.target.files));
                                        if (e.target.files.length > 0) {

                                            if (isFileDoc(e.target.files) === false) {
                                                toggle_modal_TopUpAlert()
                                            } else {
                                                const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                const str = e.target.files[0].name;
                                                const m = regex.exec(str);
                                                var list = [{
                                                    ul_file_path: URL.createObjectURL(e.target.files[0]),
                                                    ul_file_name: e.target.files[0].name,
                                                    name: e.target.files[0].name,
                                                    path: URL.createObjectURL(e.target.files[0]),
                                                    url: URL.createObjectURL(e.target.files[0]),
                                                    is_save: true,
                                                    ul_is_use: true,
                                                    ul_file_size: 0,
                                                    ul_type: "img",
                                                    ul_group_name: "document user",
                                                    ul_menu_name: "document user",
                                                    ul_is_profile: true,
                                                    status: 'done',
                                                    image_file: e.target.files[0],
                                                    is_img_upload: true
                                                }]
                                                setFileList(list)
                                            }

                                            let fileInput = document.getElementById('upload-btn-sengle')
                                            fileInput.value = ''


                                        }



                                    }}
                                />
                            </div>
                            <div className='topupTranferDiv1'></div>
                            <div className='topupTranferDiv2'></div>
                            <svg height="30" width="140" style={{ position: 'absolute', left: '54%' }}>
                                <line x1="1" y1="1" x2="16" y2="20" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                                <line x1="16" y1="20" x2="70" y2="20" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                                <line x1="21" y1="29" x2="139" y2="29" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                            </svg>
                        </div>


                        <div className='area-upload mt-2'>
                            <div className='area-bg'>
                                <div className='area-upload-text'>CODE</div>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <input type='text' className='inputCodeRedeem'
                                        value={redeemCode.code}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 8) {
                                                setRedeemCode({ ...redeemCode, code: e.target.value.trim() });

                                            }
                                        }}
                                    />
                                    <div className='btnRedeemBorder'>
                                        <div className='btnRedeem'
                                            onClick={() => {
                                                if (redeemCode.code.trim() != '') {
                                                    submitRedeemCode();
                                                }

                                            }}
                                        >Redeem</div>
                                    </div>

                                </div>


                            </div>
                            <div className='topupTranferDiv1'></div>
                            <div className='topupTranferDiv2'></div>
                            <svg height="30" width="140" style={{ position: 'absolute', left: '54%' }}>
                                <line x1="1" y1="1" x2="16" y2="20" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                                <line x1="16" y1="20" x2="70" y2="20" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                                <line x1="21" y1="29" x2="139" y2="29" style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                            </svg>
                        </div>

                        {/* 
                        <div className='boxfile'>
                            {fileList.length > 0 ? fileList.map((e) => {
                                console.log('file', e);
                                return (
                                    <div className='fileListBox'>
                                        <FontAwesomeIcon icon={faFileLines} style={iconStylefile} /> {e.ul_file_name}
                                    </div>

                                )
                            }) : <> </>}
                        </div> */}

                        <div className='btnconfrimebill' onClick={() => toggle_modal_confirm()}>
                            <b className='textBill'>Submit</b>
                        </div>


                    </div>
                </div>


                {/* <div className='buten'></div> */}

                {/* <div className='areabox-right'>
                        <div className='area-x'>
                            <div className='box-x'>
                                <div className="sapphire-main ml-3" >
                                    <img src={icon_sapphire} style={{ width: "15px", height: "20px", marginLeft: "20px" }}></img>
                                    <span className="text-sapphire">{numberWithCommas(sapphireData)}</span>
                                    <div className='iconXXX' onClick={() => {
                                        setFileList([])
                                        toggle_modal_ModalTopUp()
                                    }}>
                                        X
                                    </div>
                                </div>


                            </div>
                            <svg height="65" width="250" style={{ margin: '-54px 30px', zIndex: 898989 }}>
                                <line x1="248" y1="333" x2="50" y2="1" className='line_1' style={{ stroke: `#0047FF`, strokeWidth: '3' }} />
                            </svg>
                        </div>

                        <div className='box-body-right'>
                            <div className='row cutpadding'>
                                <div className='col-4'>
                                <img className='img-coin'
                                    src={icon_Coin}
                                />  
                                </div>
                                <div className='col-4'>
                                <img className='img-coin'
                                    src={icon_arrov}
                                />
                                </div>
                                <div className='col-4'>
                                <img className='img-arrov'
                                    src={svg_sapphire}
                                />
                                </div>
                             
                                
                             
                              
                            </div>
                            <div className='row cutpadding'>
                                <div className='col-4'>
                                <b className='text-01'> 1 BAHT</b> 
                                </div>
                                <div className='col-4'>
                                </div>
                                <div className='col-4'>
                                <b className='text-01'> 10 SAPPIE</b> 
                                </div>
                             
                                
                             
                              
                            </div>



                        </div>
                    </div> */}



                <div className='alertKick' style={{ display: `${TopUpAlert ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `red` }}>
                                        {'! ERROR !'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {'WARNING'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {'Please upload file with type img'}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_TopUpAlert()

                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>








            {/*  </ConfigProvider> */}

            <ModelAlertConfrim
                isOpen={modalConfirm}
                mainFuc={() => PushBuyManny()}
                toggle_confrim={() => toggle_modal_confirm()}
            />

            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => toggle_modal_ModalTopUp()}
            />




        </>
    )


}
export default TopupIndex;



