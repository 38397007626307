import React, { useEffect } from 'react'
/* import HeaderTTT from "../components/header/HeaderTTT"; */
import '../../assets/css/landingPageGame.css'
import { useState } from 'react'


import LineModal from '../ModalBuildMySpace/lineModal';

import ModalProject from '../component/EditProject/modalEditProject';

import towerJSON from '../3dModalTEST/towerSpaceShipCenter.json';
import Configs from "../../config";

import Swal from 'sweetalert2';
import axios from 'axios';

import LandingPagestage from '../LandingPagestage';

function LandingMySpace() {
  const [filterTower, setFilterTower] = useState(
    {
      mybuild: false,
      inprocess: false,
      lorem: false
    }
  );

  const [jsonArea, setJsonArea] = useState([]);
  const [openModal, setOpenModal] = useState('close')
  const [projectActive, setProjectActive] = useState({
    pf_project_id: '',
    project_progress: '',
    spaceship_size: '',
    spaceship_name: '',
    pp_start_date: '',
    pp_finish_date: '',
    federation_name: '',
    pp_team_member: '',
    ul_file_name: '',
    ul_file_path: '',
  })
  const [checkCategory, setCheckCategory] = useState(
    {
      all: true,
      red: true,
      blue: true,
      yellow: true,
      green: true,
      white: true,
    }
  );

  const [fesh, setFesh] = useState({ fesh: '' })

  const [onCategory, setOnCategory] = useState(false)



  const [test, settest] = useState({ test: '' })
  const [stateRender, setStateRender] = useState(false)


  function setTowerInGround(x, y, z, project_name, category_name, category_color) {
    let tempData = jsonArea;
    let positionY = y;
    let positionX = x;
    let positionZ = z;


    let minArea = -9;
    let maxArea = 9;

    let isRandom = true;
    let newRandwonCount = 0;
    do {
      let RanX = getRndInteger(minArea, maxArea);
      let RanZ =  getRndInteger(minArea, maxArea);
      let AreaList = [];
      for (let a = 0; a < x + 1; a++) {
        for (let b = 0; b < z + 1; b++) {
          let temp = {
            x: RanX - 1 + a,
            z: RanZ - 1 + b
          }

          AreaList.push(temp)
        }
      }


      let isSuccess = true;
      for (let pos of AreaList) {


        let checkXZ = tempData.filter((e) => {
          return e.AreaSizeList.some(as => as.x == pos.x && as.z == pos.z)
        });

        if (checkXZ.length > 0) {
          isSuccess = false;
        }
      }



      if (isSuccess == true) {
        let cate_stock = [
          { cate_id: 'CAT01', cate_name: 'อวกาศและพลังงาน', color: 'black' },
          { cate_id: 'CAT02', cate_name: 'ธรณีภาคพื้นดิน', color: 'red' },
          { cate_id: 'CAT03', cate_name: 'น้ำและมาหาสมุทร', color: 'blue' },
          { cate_id: 'CAT04', cate_name: 'น้ำมันและเชื้อเพลิง', color: 'yellow' },
          { cate_id: 'CAT05', cate_name: 'ชีวิตและสิ่งแวดล้อม', color: 'green' },
        ]
        const random = Math.floor(Math.random() * cate_stock.length);

        let temp = {
          spacship_name: 'RAIZEROS',
          project_id: project_name,
          project_code: project_name,
          project_name: project_name,
          category_id: category_name,
          category_name: category_name ,
          TimeZ: z,
          budgetX: x,
          position: { x: RanX, y: positionY, z: RanZ },
          AreaSizeList: AreaList || [],
          sizeMapY: y,
          color: category_color,
        }

        tempData.push(temp);
        isRandom = false;
      } else {
        newRandwonCount++;
        if (newRandwonCount == 10) {
          newRandwonCount = 0;
          minArea = minArea + (-9);
          maxArea = maxArea + (9);
        }
      }

    }
    while (isRandom == true);

    let arraM1 = [];
    let arraMatrix = [];

    console.log("tempData", tempData)
    setJsonArea(tempData);
    settest({ ...test, test: '' });
    test.test = '';


  }

  function getRndInteger(min, max) {
    return Math.ceil(Math.random() * (max - min + 1)) + min;
  }
  useEffect(() => {

    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/project/formatonProjectOnMySpace`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },


    })
      .then(function (response) {

        console.log('response.data', response.data);
        let index = 0;
        let finterTowerHaveCate = response.data.filter((e) => e.category_name !== null)

       


        for (let item of  response.data) {
            setTowerInGround(item.budgetX, item.sizeMapY , item.TimeZ, item.project_name, item.category_name, item.category_color || 'black');
          index++;
        }

        setStateRender(!stateRender);

      })
      .catch(function (error) {
        console.log(error);

      });
  }, [])

  function checkBox(check) {




    if (check == 1 & filterTower.mybuild != true) {
      filterTower.mybuild = true
    } else if (check == 1 & filterTower.mybuild == true) {
      filterTower.mybuild = false
    } else if (check == 2 & filterTower.inprocess != true) {
      filterTower.inprocess = true
    } else if (check == 2 & filterTower.inprocess == true) {
      filterTower.inprocess = false
    } else if (check == 3 & filterTower.lorem != true) {
      filterTower.lorem = true
    } else if (check == 3 & filterTower.lorem == true) {
      filterTower.lorem = false
    }

    setFesh({ ...fesh, fesh: '' });
    fesh.fesh = '';

    return (
      <></>
    )
  }


  function openModals(value) {

    if (projectActive.pf_project_id !== '' && projectActive.pf_project_id !== null && projectActive.pf_project_id !== undefined) {
      switch (value) {
        case 'close':

          setOpenModal('open');
          break;
        default:
          setOpenModal('close');
          break;
      }
    } else {
      Swal.fire(
        'warning!',
        'Please select a project.',
        'warning'
      )

      setOpenModal('close');
    }


  }
  function callBackModalOp(value) {
    setOpenModal(value);
    settest({ test, test: '' })
    test.test = ''
  }
  function callBackFucEditModal(value) {
    console.log('ตัวที่กลับมา', value);
    if (value.mode === 'project_plan') {
    }
    if (value.mode === 'user_map') {
    }
  }

  function checkCategoryIs() {
    setOnCategory(!onCategory);
    sessionStorage.setItem('isSelct', onCategory)
  }

  function checkCategoryColor(color) {
    if (color == 1 & checkCategory.all != true) {
      checkCategory.all = true
      checkCategory.red = true
      checkCategory.blue = true
      checkCategory.yellow = true
      checkCategory.green = true
      checkCategory.white = true
    } else if (color == 1 & checkCategory.all != false) {
      checkCategory.all = false
      checkCategory.red = false
      checkCategory.blue = false
      checkCategory.yellow = false
      checkCategory.green = false
      checkCategory.white = false
    } else if (color == 2 & checkCategory.red != true) {
      checkCategory.red = true
    } else if (color == 2 & checkCategory.red != false) {
      checkCategory.red = false
    } else if (color == 3 & checkCategory.blue != true) {
      checkCategory.blue = true
    } else if (color == 3 & checkCategory.blue != false) {
      checkCategory.blue = false
    } else if (color == 4 & checkCategory.yellow != true) {
      checkCategory.yellow = true
    } else if (color == 4 & checkCategory.yellow != false) {
      checkCategory.yellow = false
    } else if (color == 5 & checkCategory.green != true) {
      checkCategory.green = true
    } else if (color == 5 & checkCategory.green != false) {
      checkCategory.green = false
    } else if (color == 6 & checkCategory.white != true) {
      checkCategory.white = true
    } else if (color == 6 & checkCategory.white != false) {
      checkCategory.white = false
    }
    if (checkCategory.red == true & checkCategory.blue == true & checkCategory.yellow == true & checkCategory.green == true & checkCategory.white == true) {
      checkCategory.all = true
    } else {
      checkCategory.all = false
    }

    let tempData = towerJSON;

    let test = ['red', 'blue', 'yellow', 'green', '#FFFFFF'];
    if (checkCategory.all === false) {
      test = [];
    }
    if (checkCategory.red == true) {
      let checkHave = test.filter((e) => { return e == 'red' });
      if (checkHave.length == 0) {
        test.push('red');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'red' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'red')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.blue == true) {
      let checkHave = test.filter((e) => { return e == 'blue' });
      if (checkHave.length == 0) {
        test.push('blue');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'blue' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'blue')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.yellow == true) {
      let checkHave = test.filter((e) => { return e == 'yellow' });
      if (checkHave.length == 0) {
        test.push('yellow');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'yellow' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'yellow')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.green == true) {
      let checkHave = test.filter((e) => { return e == 'green' });
      if (checkHave.length == 0) {
        test.push('green');
      }
    } else {
      let checkHave = test.filter((e) => { return e == 'green' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == 'green')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }

    if (checkCategory.white == true) {
      let checkHave = test.filter((e) => { return e == '#FFFFFF' });
      if (checkHave.length == 0) {
        test.push('#FFFFFF');
      }
    } else {
      let checkHave = test.filter((e) => { return e == '#FFFFFF' });
      if (checkHave.length > 0) {
        let index = test.findIndex((e) => e == '#FFFFFF')
        if (index > -1) {
          test.splice(index, 1);
        }

      }
    }




    let resultData = tempData.filter((e) => { return test.includes(e.color) });
    //console.log(resultData)
    setJsonArea(resultData);

    setFesh({ ...fesh, fesh: '' });
    fesh.fesh = '';
    return (
      <></>
    )
  }


  async function historySetData(data) {

    setJsonArea([data.temp])
    settest({ ...test, test: '' });
    test.test = '';
  }


  async function resetDataTower(data) {
    setJsonArea(towerJSON)
  }

  const [modeldefcon, setmodeldefcon] = useState({
    isOpen: false
  });

  const [colordefcon, setcolordefcon] = useState({ color: '#0DB6FF', isuse: false })


  function returncolordefcon(color, isuse) {

    setcolordefcon({
      ...colordefcon,
      color: color,
      isuse: isuse
    })
    colordefcon.color = color
    colordefcon.isuse = isuse
  }



  function callBack(value) {
    console.log('project_progress', value);
    setProjectActive({
      ...projectActive,
      pf_project_id: value.pf_project_id,
      project_progress: value.project_progress || 0,
      spaceship_size: value.spaceship_size,
      spaceship_name: value.spaceship_name,
      pp_start_date: value.pp_start_date,
      pp_finish_date: value.pp_finish_date,
      federation_name: value.federation_name,
      pp_team_member: value.pp_team_member,
      ul_file_name: value.ul_file_name,
      ul_file_path: (value.ul_file_path !== '' && value.ul_file_path !== null) ? `${Configs.TTT_Game_Service_IMG}${value.ul_file_path}` : null,
    })

  }

  function setMenuValue(value) {
    sessionStorage.setItem('isMenu', value)
  }

  const [checkOnModal, setCheckOnModal] = useState({
    isOpen: false
  })

  return (
    <>
      <div className="bg" />

      <div className='page-content'></div>

      <LandingPagestage
        display={'tower'}
        setMenuValue={setMenuValue}
        setCheckOnModal={setCheckOnModal}
      />

      <LineModal
        dataTower={jsonArea}
        isRender={stateRender}
        checkCategory={onCategory}
        defcon={modeldefcon.isOpen}
        historySetData={historySetData}
        resetDataTower={resetDataTower}
        checkOnModal={checkOnModal.isOpen}
        />



      <div className='frame-filter' style={{display: `${checkOnModal.isOpen ? 'none' : ''}`}}>
        <div className='filter-my-build' style={{visibility:'hidden'}}>
          <button className='check-box' onClick={() => { 
            //checkBox(1)
            
            }} style={{ backgroundColor: `${filterTower.mybuild == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>MY BUILDING</span>
          <span className='go-my-build'>goto</span>
        </div>

        <button onClick={() => { checkCategoryIs() }} className='filter-my-build' style={{ marginTop: '15px', textAlign: "start" }}>PROJECT CATEGORY
          <i class="fa fa-angle-down" aria-hidden="true" style={{ position: 'absolute', right: '10px', top: '68px' }}></i>
        </button>

        <div className={`filter-my-build ${onCategory ? 'opened' : 'close'}`}>
          <button className='check-box' id="all" onClick={() => { checkCategoryColor(1) }} style={{ backgroundColor: `${checkCategory.all == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(1) }}>ALL</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(2) }} style={{ backgroundColor: `${checkCategory.red == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(2) }}>RED</span><br />
          <button className='check-box' onClick={() => { checkCategoryColor(3) }} style={{ backgroundColor: `${checkCategory.blue == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(3) }}>BLUE</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(4) }} style={{ backgroundColor: `${checkCategory.yellow == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(4) }}>YELLOW</span><br />
          <button className='check-box' onClick={() => { checkCategoryColor(5) }} style={{ backgroundColor: `${checkCategory.green == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(5) }}>GREEN</span><div></div>
          <button className='check-box' onClick={() => { checkCategoryColor(6) }} style={{ backgroundColor: `${checkCategory.white == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build' onClick={() => { checkCategoryColor(6) }}>WHITE</span>
        </div>


        <div className='filter-my-build' style={{ marginTop: '15px', height: '80px' }}>
          <button className='check-box' onClick={() => { checkBox(2) }} style={{ backgroundColor: `${filterTower.inprocess == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>IN PROCESS</span><div></div>
          <button className='check-box' onClick={() => { checkBox(3) }} style={{ backgroundColor: `${filterTower.lorem == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>LOREM</span>
        </div>
      </div>

      <ModalProject
        openModal={openModal}
        callBackModalOp={callBackModalOp}
        callBackFucEditModal={callBackFucEditModal}
        projectTemp={projectActive}
      />

    </>
  )
}

export default LandingMySpace
