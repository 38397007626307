import React, { useState, useEffect } from 'react';
import "./cardshotStyle.css";
import Calendar_pomotion from '../../assets/img/images/newsCalendar.png'
import calendar_story from '../../assets/img/images/calendar_story.png'
import calendar_new from '../../assets/img/images/calendar_new.png'
import moment from "moment";
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import { Link } from 'react-router-dom';
function Cardshot({ tagevent, nameevent, dateevent, bgevent, callback, newsid, date, style }) {
  /*   console.log('dateevent',date); */
  let tag = {
    nametag: 'none',
    color: '',
    icon: '',

  }
  if (tagevent === 'p') {
    tag.nametag = 'PROMOTION'
    tag.color = '#FDC700'
    tag.icon = `${Calendar_pomotion}`


  }

  if (tagevent === 'n') {
    tag.nametag = 'NEWS'
    tag.color = '#FF2B2B'
    tag.icon = `${calendar_new}`


  }

  if (tagevent === 's') {
    tag.nametag = 'TTT SPACESHIP STORY'
    tag.color = '#0047FF'
    tag.icon = `${calendar_story}`


  }
  /*  console.log(bgevent); */
  /* <Link to={`/viewNews`} onClick={()=>{
                                 sessionStorage.setItem('News_id', e.news_id)
                             }}> </Link>  */


  useEffect(() => {
    console.log('Notting sss',bgevent);
  }, [bgevent])

  return (
    <>
      <Link to={`/viewNews`} onClick={() => {
        /* console.log('newsss',newsid.news_id); */
        sessionStorage.setItem('News_id', newsid.news_id)
      }} >
        <div className="cardall" /* onClick={() => callback(newsid)}  */ style={style}>
          <div className="cardmain" style={{ height: '75%', backgroundImage: `url(${bgevent})` }}></div>
          <div className="cardbot" style={{ height: '115px' }} >
            <div>
              <div className="mb-2">
                <h style={{ border: `2px solid ${tag.color}`, color: `${tag.color}`, background: 'none', textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', paddingTop: '7px', paddingBottom: '5px', fontWeight: '700', fontSize: '10px' }}>
                  {tag.nametag}</h>
              </div>
              <div className="mb-1">
                <font style={{ fontSize: '18px', color: '#ffff', fontWeight: '600' }}>{nameevent}</font>
              </div>
              <div className='row mt-1' style={{ fontStyle: 'italic' }}>
                <font ><img src={`${tag.icon}`} style={{ display: 'inline', marginLeft: '3px' }} />{' '} {date}</font>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default Cardshot;
