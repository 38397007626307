import React, { useState, useEffect, useRef } from 'react';
import '../payment/detailPayment.css'
import moment from "moment";
import axios from "axios";
import Configs from "../../../../config";
import payIcon from '../../../../assets/img/icon/Group11.png'

const DetailPayment = ({ isOpen, check }) => {

    const [payData, setPayData] = useState([
        {
            ps_name: "approved",
            ps_color: "green",
            payCount: 100000.00,
            payCountTo: 0
        },
        {
            payType: "with",
            payDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
            payCount: -100000.00,
            payCountTo: 0
        },
        {
            payType: "tran",
            payDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
            payCount: -10000.00,
            payCountTo: 1000000
        },
    ])

    const [historyPayment, setHistoryPayment] = useState([]);

    async function getHistoryTopUpID(page, enties, onSearch) {

        var temp = {
            user_id: sessionStorage.getItem('user_id'),
            page: page || 1,
            enties: enties || 7,

        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/sapphire/getHistoryTopUp`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }, data: temp
        })
            .then(async function (response) {
                setHistoryPayment(response.data.data)
                /*   setProData((prevItems) => [...prevItems, ...response.data.data]);
                  setCountProject(response.data.count || 0)
                  setHasMore(response.data.isstop)
                  setOnSearch({
                      ...onSearch,
                      current: page || 1,
                      show: enties || 7,
                  });
   */
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (isOpen == true) {
            getHistoryTopUpID(1, 7);
        }
    }, [isOpen])

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        (check === 'PAYMENT' ? (<>
            <div className="detailPayment" style={{ visibility: `${isOpen === true && check === 'PAYMENT' ? 'visible' : 'hidden'}` }}>
                {historyPayment.length > 0 ? (<>
                    {historyPayment.map((e, index) => {

                        /*   let name = 'ENTRY MONEY';
                          let type = 'ENTRY MONEY'
                          if (e.payType === "entr") {
                              name = "ENTRY MONEY"
                              type = 'ENTRY MONEY'
                          } else if (e.payType === "with") {
                              name = "Withdrawal List"
                              type = "Withdrawal"
                          } else {
                              name = "Transfer Sapphire"
                              type = "Transfer Sapphire"
                          } */


                        if (e.ts_id == '9ec5bb52-feaf-4e42-b17e-a8197ee0c1d3') {
                            //pending
                        } else if (e.ts_id == 'c38293a3-0ec9-4d27-8cdd-82feeca73534') {
                            //approve
                        } else if (e.ts_id == '521549df-dd54-4048-b575-95737a5f6a0f') {
                            //reject
                        }

                        let moneyBaht = parseFloat(e.topup_supphire || 0) / 10;



                        return (
                            <div className="payBox">
                                <div className="row setPayRow">
                                    <div className='col-9 setPayCol'>
                                        <div className='payCodeHead'>PAYMENT TRANSACTION</div>
                                        <div className='payNameBox'>
                                            <div className='payIcon' style={{ backgroundImage: `url(${payIcon})` }}></div>
                                            <div className='payName' style={{ textTransform: 'uppercase' }}>{'TOP UP'}</div>
                                        </div>
                                        <div className='payDateBox'>
                                            <div className='payDate'>DATE : {moment(e.topup_created_date).format("DD MMM YYYY")} </div>
                                            <div className='payTime'>TIME : {moment(e.topup_created_date).format("hh.mm a")}</div>
                                        </div>
                                    </div>
                                    <div className='col-3 setPayCol'>
                                        <div className='payTypeText' style={{ marginTop: `${e.ts_id == 'c38293a3-0ec9-4d27-8cdd-82feeca73534' ? 5 : 22.5}px`, color: `${e.ts_color}`, textTransform: 'uppercase' }}>{e.ts_name}{e.ts_id == 'c38293a3-0ec9-4d27-8cdd-82feeca73534' ? ` | DATE : ${moment(e.topup_approve_date).format("DD MMM YYYY")} | TIME : ${moment(e.topup_approve_date).format("hh.mm a")}` : ''}</div>
                                        <div className='payBudget' style={{ color: 'rgb(255 51 51)' }}>{e.topup_approve_remark || ''}</div>
                                        <div className='payBudget' style={{ color: 'rgb(255 51 51)' }}>{e.ts_id == 'c38293a3-0ec9-4d27-8cdd-82feeca73534' ? `-${numberWithCommas(parseFloat(moneyBaht || 0))} BAHT -> ` : ''}</div>
                                        <div className='payTran'>

                                            {e.ts_id == 'c38293a3-0ec9-4d27-8cdd-82feeca73534' ? `+${numberWithCommas(parseFloat(e.topup_supphire || 0))} SAPPHIRE` : ''}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>) : (<>
                    No Transaction...
                </>)}

            </div>
        </>) : (<></>))
    )
}

export default DetailPayment