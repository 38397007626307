import React, { useEffect, useState, useRef } from 'react';
import '../component/howToPlayStep.css';
import step3A from '../image/step3-a.png';
import step61 from '../image/step6-1.png';
import step611 from '../image/step6-11.png';
import redHand from '../image/redHand.png'

function HowToPlayStep6(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step3A} alt="" style={{ bottom: '-7px', left: '-4px', width: '25%' }} />
                <div className='howToPlayStep1Detail' style={{ top: '125px', left: '50px', height: '200px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%', width: '90%'}}><div style={{fontWeight: '700'}}>Stage Monitoring</div><div>รายละเอียดของ Project ที่เรามีอยู่ในปัจจุบัน กด icon 3 จุดแล้วจะมีปุ่มให้กด Kick-Off! เมื่อกดไปแล้วจะเข้าสู่หน้า Kick-Off Projects</div></div>
                </div>
                <img src={step611} style={{width: '12.5%', position: 'absolute', top: '33%', left: '10.5%'}}></img>
                <div className='howToPlayStep1Detail' style={{ top: '350px', left: '50px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%', width: '90%'}}><div style={{fontWeight: '700'}}>Kick-Off Projects</div><div>เมื่อเข้าสู่หน้า Kick-Off Projects ในหน้าจอของหัวหน้ายานและสมาชิกในยานให้กดปุ่ม Ready ให้ครบทุกคน</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '465px', left: '50px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%', width: '90%'}}><div style={{fontWeight: '700'}}>On Space</div><div>เมื่อกดปุ่ม Ready ครบทุกคนแล้ว หัวหน้ายานจะทำการกด Start เพื่อออกยาน สถานะของ Project จะเปลี่ยนเป็น On Space</div></div>
                </div>
                <img className='howToPlayStep1ImgBg' src={step61} alt="" style={{ top: '125px', right: '50px', width: '55%', height: '62.5%'}}/>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '240px', left: '300px', width: '2.5%'}}/>
            </div>
        </>
    );
}

export default HowToPlayStep6;