import React, { useEffect,useState,useRef } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Input,Select, Space, Tag, theme, Tooltip, ConfigProvider,message, Upload } from 'antd';

import axios from 'axios';
import Configs from "../../../config";
import './modalSpaceShip.css'

const { Dragger } = Upload;
function ModalImportStage({
  isOpen,
  toggle_modal_import,
  project_id,
  moduleOption,
  getProjectDetail
  
}){

  const [fileImport,setFileImport] = useState({});
  const [fileImportList,setFileImportList] = useState([]);
  const props = {
    
    maxCount:1,
    name: 'file',
    accept:".xls,.xlsx",
    multiple: false,
    method:'get',
    headers: {
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
  },

    action: Configs.TTT_Game_Service+ '/api/upload/fackFile',
    onChange(info) {
      const { status } = info.file;
      info.status  ='done';
      console.log('status',status)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {  
       /*  setFileImport(info.file); */
    
        /* message.success(`${info.file.name} file uploaded successfully.`); */
      } else if (status === 'error') {
        /* message.error(`${info.file.name} file upload failed.`); */
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const [MenuDetail,setMenuDetail] = useState({
    module_id:'',
    menu_name:'',
  })

    const [ModuleDetail,setModuleDetail] = useState({
    module_name:''
  })

  
  const [moduleOptions,setModuleOptions] = useState([]);

  const [errorMessage,setErrorMessage] = useState({
    module_err:'',
    menu_err:''
  });
   const [errorResMessage,setErrorResMessage] = useState('');

  
  useEffect(()=>{
    
    setFileImport({});
    setImportErrList([]);
    setFileImportList([]);
    setErrorMessage({...errorMessage, module_err:'',menu_err:''});
    setErrorResMessage('');
    setMenuDetail({...MenuDetail,menu_name:"",module_id:''})
    MenuDetail.menu_name = '';
    MenuDetail.module_id = '';

   /*  setModuleOptions(moduleOption); */
  },[isOpen])

  useEffect(()=>{

    setModuleOptions(moduleOption);
  },[moduleOption])

  const [importErrList,setImportErrList] = useState([]);

  function saveAddModule(){
    console.log('fileImport',fileImport)

    var temp = new FormData();

    temp.append("file", fileImport);
    temp.append("file_name", fileImport.name);
  
    temp.append("project_id", project_id);
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/importStageMonitoring`,
      headers: {
         Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/octet-stream',
      },
      data: temp

    })
      .then( function (response) {
        if(response.data){

  
          toggle_modal_import();
          getProjectDetail(project_id);
          setErrorResMessage('');
          setImportErrList([]);
          setFileImport({});
          setFileImportList([]);
         
        }

        

      })
      .catch(function (error) {
        console.log(error);
        setErrorResMessage(error.response.data.error[0].errorDis)
        if(error.response.data.error[0].dataList){
          setImportErrList(error.response.data.error[0].dataList)
        }

      
      });


  }


return (

    <>
    
  
     <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#85BFFF',
                colorInfo: '#85BFFF',
                colorBorder: '#85BFFF',
                colorText: '#FFF',
                
                colorTextDescription:'#FFFFFF',
                colorBgContainer: '#000',
            
                zIndexPopupBase:999999,
                colorPrimaryBg:'blue',//สีตอน Active ใน Select
                colorBgBase:'#778899',//พื้นหลัง select ตอนกด down ลงมา
        
                colorTextQuaternary:'#B0C4DE',// สีตัวหนังสือของ placeholder

              
                colorFillTertiary:'#87CEFA',// สีตอน hover ใน Select
               
              
    
              },
          
            /*   backgroundColor:'#000' */
            }}
          >
      <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={()=>{toggle_modal_import();}}>      </div>
    <div className={`detailBoxModuleMenu ${isOpen ? 'opened':'closed'}`}    style= {{top:`10%`,left:`50%`,position:'fixed',transform: 'translate(-50%, 50%)',width:'30%'}}
              
  
    >
      <div className = "table-responsive" style= {{height:'330px'}} >
        <div className='row mt-2' >
     
            <div className='col-12'><h4>Import Stage Monitoring</h4><font color = "red">{errorResMessage}</font></div>
            <div className='col-12 mt-2' style = {{display:`${errorResMessage !== ''? 'none' :''}`}}>
            <Dragger {...props}   fileList={fileImportList}  beforeUpload={(file, fileList) => {
        // Access file content here and do something with it
        console.log(file,fileList);
        setFileImportList(fileList)
        setFileImport(file);

        // Prevent upload
        return false;
    }}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag file to this area to upload</p>
    <p className="ant-upload-hint">
      Support for a single or bulk upload. Strictly prohibited from uploading company data or other
      banned files.
    </p>
  </Dragger>
            </div>
       
          
            <div className='col-12 mt-4 '>
              {importErrList.length > 0 && (
                    <div className='row mb-2'>
                    <div className='col-6'>Module</div>
                    <div className='col-6'>Menu</div>
                    
                    </div>
              )}
        

              {importErrList.map((e)=>{return(<div className='row mb-1'>
              <div className='col-6'>
              {e.module}
              </div>
              <div className='col-6'>
              {e.menu}
              </div>
          
              </div>)})}
            </div>
          
  
         
    </div>
    </div>
    <br/><br/>
    <div style= {{position:'absolute',bottom:'5px',right:'5px'}}>
            <button className=' btn-outline-primary mr-2' style ={{padding:'5px',display:`${errorResMessage !== '' || fileImportList.length == 0 ? 'none' :''}`}} onClick={()=>{saveAddModule();}}> Save </button> <button className=' btn-outline-danger' style ={{padding:'5px'}} onClick={()=>{toggle_modal_import();}}> Cancel </button>
              </div>
    
    </div>
     
    </ConfigProvider>
   
 
    </>
)


}
export default ModalImportStage;


