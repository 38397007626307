import React, { useState, useEffect } from "react";
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import Card from "../../../../components/customElement/cardevent";
import Cardhighlight from "../../../../components/customElement/cardhighlight";
import Slider from 'react-slick';
import moment from 'moment';


import Configs from "../../../../config";


function Pageall({ data,  newHighlightTemp  }) {
  /*   console.log('dataTEMP', data); */
  const [newInfos, setNewsInfos] = useState([])
  
  const [newHightling, setNewHightling] = useState({
    news_id:'',
    name: '',
    type: '',
    description: '',
    news_id: '',
    paht_img: '',
  })

  const [test,settest] = useState({
    test:''
  })

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-right"></i></button>
    );
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type='button' className={className} onClick={onClick}><i className="fa fa-angle-left"></i></button>
    );
  }



  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} arrows`}
        style={{ ...style, display: "block", background: "transparent", position: 'absolute', top: '0px', left: '-10px', width: '40px', height: '40px', borderRadius: '10px 0 0 10px', border: '2px solide white', textAlign: 'center', padding: '8px' }}
        onClick={onClick}
      ><i className="fa fa-angle-left" /> </div>

    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      className={`${className} arrows`}
        style={{ ...style, display: "block", background: "transparent", position: 'absolute', top: '0px', left: '-10px', width: '40px', height: '40px', borderRadius: '0 10px 10px 0', border: '2px solide white', textAlign: 'center', padding: '8px' }}
        onClick={onClick}
      ><i className="fa fa-angle-right" /> </div>

    );
  }



  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplaySpeed: 4000,
    speed: 1000,
    autoplay: true,
    nav: true,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    beforeChange: function (currentSlide, nextSlide) {
    },
    afterChange: function (currentSlide) {
    }


  };


  useEffect(() => {
    document.querySelector('html').classList.remove('modal-opened');
    if (data.length > 0) {
      let getNotH = data.filter((e) => e.news_is_highlight !== true);
      let getTrue = data.filter((l) => l.news_is_highlight === true);

      setNewsInfos(getNotH || [])
      let type = '';

      if(getTrue.length > 0){
        if (getTrue[0].kn_name === "PROMOTION") {
          type = 'p'
        } else if (getTrue[0].kn_name === "ACTIVITY & NEWS") {
          type = 'n'
        } else if (getTrue[0].kn_name === "TTT SPACESHIP STORY") {
          type = 's'
        }
        setNewHightling({
          ...newHightling,
          news_id:getTrue.length > 0 ? getTrue[0].news_id : '',
          name: getTrue.length > 0 ? getTrue[0].news_title : "",
          type: type,
          description: getTrue.length > 0 ? getTrue[0].news_description : "",
          news_id: getTrue.length > 0 ? getTrue[0].news_id : "",
          paht_img: getTrue[0].path_file !== undefined && getTrue[0].path_file !== null ? getTrue[0].path_file : "../../../../assets/img/bg/imgbdsport.jpg",
         /*  paht_img: getTrue[0].ul_file_path !== undefined && getTrue[0].ul_file_path !== null ? `${Configs.TTT_Game_Service_IMG}${ getTrue[0].ul_file_path}` : "../../../../assets/img/bg/imgbdsport.jpg", */
        });
        newHightling.news_id    = getTrue.length > 0 ? getTrue[0].news_id : '';
        newHightling.name       =  getTrue.length > 0 ? getTrue[0].news_title : "";
        newHightling.type       =  type;
        newHightling.description=  getTrue.length > 0 ? getTrue[0].news_description : "";
        newHightling.news_id  = getTrue.length > 0 ? getTrue[0].news_id : "";
        newHightling.paht_img = getTrue[0].path_file !== undefined && getTrue[0].path_file !== null ? getTrue[0].path_file : "../../../../assets/img/bg/imgbdsport.jpg";
        settest({...test,test:''});
        test.test = '';
      }

    }
    settest({...test,test:''});
    test.test = '';

  }, [data,newHighlightTemp])

  
  function OpenModal(value) {

   /*  console.log('dasdasdasda',value); */
 /*    callback(true, value)
    settest({ ...test, test: '' })
    test.test = ''; */
  }


  return (
    <>
      <div className="row"  >
        <div style={{ width: '60%', paddingLeft: '1%', paddingRight: '1%' }}>
          <Cardhighlight taghl={newHightling.type} nameevent={newHightling.name} dateevent={newHightling.description} bghl={newHightling.paht_img} newsID={newHightling.news_id} test ={test}/>
        </div>
        <div className="cardscroll" style={{ width: '40%' }}>
          <Slider className="latest-games-active slick" {...settings}>
            {newInfos.length > 0 ? (<>
              {newInfos.map((e) => {
                let typeNews = '';
                let imgDEfule = '../../../../assets/img/bg/imgbdsport.jpg';
                if (e.kn_name === "PROMOTION") {
                  typeNews = 'p'
                } else if (e.kn_name === "ACTIVITY & NEWS") {
                  typeNews = 'n'
                } else if (e.kn_name === "TTT SPACESHIP STORY") {
                  typeNews = 's'
                }

            
                return (
                  <Card tagevent={typeNews} nameevent={e.news_title} date={`${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`} 
                  
                  bgevent={e.path_file !== undefined && e.path_file !== null ? e.path_file : imgDEfule} newsID={e.news_id}
                  callback={OpenModal}
                  newsid={e}
                  />

                )
              })}
            </>) : (<>
              <Card tagevent='p'nameevent={'Comming Soon'} date={moment(new Date()).format('DD MMM YYYY')} bgevent={'../../../../assets/img/bg/imgbdsport.jpg'}/>
            </>)}
          </Slider>


        </div>
      </div>
    </>
  );
}

export default Pageall;
