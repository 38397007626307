import React, { useState, useEffect, useRef } from 'react';
import '../connection/detailConnection.css'
import ImgCard from "../../cardPersonalInfo.js"
import Construction from '../../../../assets/img/bg/Construction Page.png'

const DetailConnection = ({ isOpen, check, cardData, imgProfile}) => {
    const [countData, setCountData] = useState(cardData)

    return (
        (check === 'CONNECTION' ? (<>
            <div className="detailConnection" style={{ visibility: `${isOpen === true && check === 'CONNECTION' ? 'visible' : 'hidden'}` }}>
            <img src={Construction} style={{ width: "100%", height: "100%", marginTop: "0px" }}/>
                {/* <div className="row cardPositionRow">
                    {countData.map((e, index) => {
                        return (
                            < span className="col-2 cardPositionCol" >
                                <ImgCard
                                    setCard={2}
                                    imgProfile = {imgProfile}
                                    imgName={e.card_name}
                                    imgLastName={e.card_lastname}
                                >
                                </ImgCard>
                            </span >

                        )
                    })}
                </div> */}
            </div>
        </>) : (<></>))
    )
}

export default DetailConnection

