import React from 'react';
import Footer from '../../footer/Footer';
// import HeaderTwo from '../../header/HeaderTwo';
import LandingpageTTT from './LandingpageTTT';

import NewGamesArea from './NewGamesArea'
import Mianpage from './mianpage'
import GamesArea from './GamesArea'
import CtaArea from './CtaArea'
import ShopAreaTwo from './ShopAreaTwo'
import BlogArea from './BlogArea'
import StickyBar from '../../stickybar/stickybar';

import HeaderTTT from '../../header/HeaderTTT';


function IndexTTT(){
	return(

   
    

        <>
          <HeaderTTT/>
          <main>
            <LandingpageTTT/>
            <Mianpage/>
          </main>
        
        <Footer/>

        </>
	)
}
	
export default IndexTTT