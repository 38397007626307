import React, { useEffect, useState, useRef } from 'react';
import "./style.css";

import { ConfigProvider, Select, Switch, Row, Col } from 'antd';

import backIcon from "./img/Icon.png"
import bgTop from "./img/bgTop.png"
import bgBottom from "./img/bgBottom.png"
import unkey from "./img/unkey.png"
import sapphireIcon from "./img/Frame.png"
import roleIcon from "./img/role.png"
import keyIcon from "./img/key.png"

import AlertConfrim from "../../../../components/AlertMassge/AlertConfrim"
import AlertMassage from "../../../../components/AlertMassge/AlertMassge"

function AddElement(params) {
    const bottomElementRef = useRef(null);

    const [filter, setFilter] = useState({
        type: '',
        category: ''
    });

    const [alertConfirmIsOpen, setAlertConfirmIsOpen] = useState(false);
    const toggleAlertConfirmIsOpen = () => { setAlertConfirmIsOpen(!alertConfirmIsOpen) };
    const [alertMassageIsOpen, setAlertMassageIsOpen] = useState(false);
    const toggleAlertMassageIsOpen = () => { setAlertMassageIsOpen(!alertMassageIsOpen) };

    const [messageBox, setMessageBox] = useState({
        message: 'You must purchase the previous element first.',
        isError: true,
        menuTitle: 'BUY ELEMENT FAIL.'
    })

    const [optionsSelectType, setOptionSelectType] = useState([
        {
            value: 'type1',
            label: 'type1',
        },
        {
            value: 'type2',
            label: 'type2',
        }
    ]);

    const [optionsSelectCategory, setOptionSelectCategory] = useState([
        {
            value: 'Category1',
            label: 'Category1',
        },
        {
            value: 'Category2',
            label: 'Category2',
        }
    ]);

    const [addElementData, setAddElementData] = useState([
        {
            elm_id: '111',
            elm_name: 'test element name 1',
            elm_desc: 'test element desc 1',
            fd_image_id: '1',
            elm_have: true,
            elm_is_active: true,
            elm_type: 'type1',
            elm_category: 'category1',
            elm_role: 'role1',
            elm_level: '50',
            elm_price: '10000',
            fd_image_path: ''
        },
        {
            elm_id: '222',
            elm_name: 'test element name 2 2 2 2 2 2 2 2 2 2 2 2',
            elm_desc: 'test element desc 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222',
            fd_image_id: '2',
            elm_have: true,
            elm_is_active: false,
            elm_type: 'type2',
            elm_category: 'category2',
            elm_role: 'role2',
            elm_level: '60',
            elm_price: '20000',
            fd_image_path: ''
        },
        {
            elm_id: '333',
            elm_name: 'test element name 3',
            elm_desc: 'test element desc 3',
            fd_image_id: '3',
            elm_have: false,
            elm_is_active: false,
            elm_type: 'type3',
            elm_category: 'category3',
            elm_role: 'role3',
            elm_level: '70',
            elm_price: '30000',
            fd_image_path: ''
        },
        {
            elm_id: '111',
            elm_name: 'test element name 1',
            elm_desc: 'test element desc 1',
            fd_image_id: '1',
            elm_have: true,
            elm_is_active: true,
            elm_type: 'type1',
            elm_category: 'category1',
            elm_role: 'role1',
            elm_level: '50',
            elm_price: '10000',
            fd_image_path: ''
        },
        {
            elm_id: '222',
            elm_name: 'test element name 2',
            elm_desc: 'test element desc 2',
            fd_image_id: '2',
            elm_have: true,
            elm_is_active: false,
            elm_type: 'type2',
            elm_category: 'category2',
            elm_role: 'role2',
            elm_level: '60',
            elm_price: '20000',
            fd_image_path: ''
        },
        {
            elm_id: '333',
            elm_name: 'test element name 3',
            elm_desc: 'test element desc 3',
            fd_image_id: '3',
            elm_have: false,
            elm_is_active: false,
            elm_type: 'type3',
            elm_category: 'category3',
            elm_role: 'role3',
            elm_level: '70',
            elm_price: '30000',
            fd_image_path: ''
        },
        {
            elm_id: '111',
            elm_name: 'test element name 1',
            elm_desc: 'test element desc 1',
            fd_image_id: '1',
            elm_have: true,
            elm_is_active: true,
            elm_type: 'type1',
            elm_category: 'category1',
            elm_role: 'role1',
            elm_level: '50',
            elm_price: '10000',
            fd_image_path: ''
        },
        {
            elm_id: '222',
            elm_name: 'test element name 2',
            elm_desc: 'test element desc 2',
            fd_image_id: '2',
            elm_have: true,
            elm_is_active: false,
            elm_type: 'type2',
            elm_category: 'category2',
            elm_role: 'role2',
            elm_level: '60',
            elm_price: '20000',
            fd_image_path: ''
        },
        {
            elm_id: '333',
            elm_name: 'test element name 3',
            elm_desc: 'test element desc 3',
            fd_image_id: '3',
            elm_have: false,
            elm_is_active: false,
            elm_type: 'type3',
            elm_category: 'category3',
            elm_role: 'role3',
            elm_level: '70',
            elm_price: '30000',
            fd_image_path: ''
        },
        {
            elm_id: '111',
            elm_name: 'test element name 1',
            elm_desc: 'test element desc 1',
            fd_image_id: '1',
            elm_have: true,
            elm_is_active: true,
            elm_type: 'type1',
            elm_category: 'category1',
            elm_role: 'role1',
            elm_level: '50',
            elm_price: '10000',
            fd_image_path: ''
        },
        {
            elm_id: '222',
            elm_name: 'test element name 2',
            elm_desc: 'test element desc 2',
            fd_image_id: '2',
            elm_have: true,
            elm_is_active: false,
            elm_type: 'type2',
            elm_category: 'category2',
            elm_role: 'role2',
            elm_level: '60',
            elm_price: '20000',
            fd_image_path: ''
        }
    ]);

    const [addElementDataOnPhase, setAddElementDataOnPhase] = useState([
        {
            data: [
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: true,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '1'
                },
                {
                    elm_id: '222',
                    elm_name: 'test element name 2 2 2 2 2 2 2 2 2 2 2 2',
                    elm_desc: 'test element desc 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222',
                    fd_image_id: '2',
                    elm_have: true,
                    elm_is_active: false,
                    elm_type: 'type2',
                    elm_category: 'category2',
                    elm_role: 'role2',
                    elm_level: '60',
                    elm_price: '20000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '2'
                },
                {
                    elm_id: '333',
                    elm_name: 'test element name 3',
                    elm_desc: 'test element desc 3',
                    fd_image_id: '3',
                    elm_have: false,
                    elm_is_active: false,
                    elm_type: 'type3',
                    elm_category: 'category3',
                    elm_role: 'role3',
                    elm_level: '70',
                    elm_price: '30000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '3'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
            ]
        },
        {
            data: [
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: true,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '1'
                },
                {
                    elm_id: '222',
                    elm_name: 'test element name 2 2 2 2 2 2 2 2 2 2 2 2',
                    elm_desc: 'test element desc 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222 22222',
                    fd_image_id: '2',
                    elm_have: true,
                    elm_is_active: false,
                    elm_type: 'type2',
                    elm_category: 'category2',
                    elm_role: 'role2',
                    elm_level: '60',
                    elm_price: '20000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '2'
                },
                {
                    elm_id: '333',
                    elm_name: 'test element name 3',
                    elm_desc: 'test element desc 3',
                    fd_image_id: '3',
                    elm_have: false,
                    elm_is_active: false,
                    elm_type: 'type3',
                    elm_category: 'category3',
                    elm_role: 'role3',
                    elm_level: '70',
                    elm_price: '30000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '3'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '4'
                }
            ]
        },
        {
            data: [
                {
                    elm_id: '333',
                    elm_name: 'test element name 3',
                    elm_desc: 'test element desc 3',
                    fd_image_id: '3',
                    elm_have: false,
                    elm_is_active: false,
                    elm_type: 'type3',
                    elm_category: 'category3',
                    elm_role: 'role3',
                    elm_level: '70',
                    elm_price: '30000',
                    fd_image_path: '',
                    elm_on_phase: 'p1',
                    elm_phase_level: '2'
                },
                {
                    elm_id: '111',
                    elm_name: 'test element name 1',
                    elm_desc: 'test element desc 1',
                    fd_image_id: '1',
                    elm_have: false,
                    elm_is_active: true,
                    elm_type: 'type1',
                    elm_category: 'category1',
                    elm_role: 'role1',
                    elm_level: '50',
                    elm_price: '10000',
                    fd_image_path: ''
                }
            ]
        }
    ]);

    async function buyElement() {
        setMessageBox({
            ...messageBox,
            message: 'Buy element successful.',
            isError: false,
            menuTitle: 'BUY ELEMENT SUCCESSFUL.'
        });
        messageBox.message = 'Buy element successful.';
        messageBox.isError = true;
        messageBox.menuTitle = 'BUY ELEMENT SUCCESSFUL.';
        toggleAlertMassageIsOpen();
    }

    function numberWithFormat(value) {
        let set = 0;
        if (value >= 1000000) {
            set = `${(parseFloat((value) / 1000000).toFixed(2))}M`;
        } else if (value >= 1000) {
            set = `${(parseFloat((value) / 1000).toFixed(2))}K`;
        } else {
            set = parseFloat(value || 0).toFixed(2);
        }
        return set
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <>

            {params.isOpen && <>
                <div className="addElementBg">
                    <div className="addElementBgSet" style={{ backgroundImage: `url(${bgTop})` }}>
                        <div className='addElementHeader' style={{ margin: 0, padding: 0 }}>
                            <div className="addElementHeaderLeft">
                                <div className='addElementBack' onClick={() => {
                                    params.toggleIsOpen();
                                }}>
                                    <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                                </div>
                                <div className="addElementHeadLine"></div>
                                <div className="addElementHeaderName">ELEMENT ADD ON</div>
                            </div>
                            <div className='addElementPhaseBox' style={{ border: 'none' }}>
                                <div className='addElementPhaseBox' style={{ border: 'none', cursor: 'auto' }}>
                                    <img className='addElementPhaseIcon' src={sapphireIcon}></img>
                                    <div className='addElementHeaderName' style={{ fontSize: '20px' }}>{numberWithFormat(5000)}</div>
                                </div>
                                <div className='addElementPhaseBox' onClick={() => {
                                    bottomElementRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}>
                                    <img className='addElementPhaseIcon' src={unkey} style={{ padding: 0 }}></img>
                                    <div className='addElementHeaderName' style={{ fontSize: '20px' }}>ELEMENT PHASE</div>
                                </div>
                            </div>
                        </div>
                        <div className='addElementFilter'>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorPrimary: '#0047FF',
                                        colorInfo: '#0047FF',
                                        colorBorder: '#0047FF',
                                        colorText: '#FFF',

                                        colorTextDescription: '#FFFFFF',
                                        colorBgContainer: '#000',

                                        zIndexPopupBase: 999999,
                                        colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
                                        colorBgBase: '#0047FF',//พื้นหลัง select ตอนกด down ลงมา

                                        colorTextQuaternary: '#B0C4DE',// สีตัวหนังสือของ placeholder
                                        colorBorder: '#0047FF',

                                        colorFillTertiary: '#3354aa',// สีตอน hover ใน Select
                                    },
                                }}
                            >
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Element Type</div>
                                    <Select
                                        dropdownClassName='addElementSelectCustom'
                                        value={optionsSelectType.filter((e) => { return e.value == filter.type })}
                                        onChange={(e) => {
                                            setFilter({ ...filter, type: e });
                                            filter.type = e;
                                        }}
                                        placeholder='Element Type'
                                        options={optionsSelectType}
                                        allowClear
                                    />
                                </div>
                                <div className='addElementFilterType'>
                                    <div className='addElementFilterHead'>Element Category</div>
                                    <Select
                                        dropdownClassName='addElementSelectCustom'
                                        value={optionsSelectCategory.filter((e) => { return e.value == filter.category })}
                                        onChange={(e) => {
                                            setFilter({ ...filter, category: e });
                                            filter.category = e;
                                        }}
                                        placeholder='Element Category'
                                        options={optionsSelectCategory}
                                        allowClear
                                    />
                                </div>
                            </ConfigProvider>
                        </div>
                        <Row gutter={[16, 32]} className='addElementDataBox'>
                            {addElementData && addElementData.filter((fil) => {
                                return filter.type ? filter.category ? fil.elm_category.toLowerCase() == filter.category.toLowerCase() && fil.elm_type.toLowerCase() == filter.type.toLowerCase() : fil.elm_type.toLowerCase() == filter.type.toLowerCase() : filter.category ? fil.elm_category.toLowerCase() == filter.category.toLowerCase() : fil
                            })?.map((e, i) => {
                                return <>
                                    <Col /* className='addElementData' */ xs={24} sm={12} md={8} lg={6}>
                                        <div className='addElementDataHead'>
                                            <div className='addElementDataHeadSet'>
                                                <div className='addElementDataHeadText'>{e.elm_type}</div>
                                                <div className='addElementDataHeadText'>{e.elm_category}</div>
                                            </div>
                                            {e.elm_have ?
                                                <Switch defaultChecked={e.elm_is_active} />
                                                :
                                                <div className='addElementPriceBox' onClick={() => {
                                                    toggleAlertConfirmIsOpen();
                                                }}>
                                                    <img className='addElementPriceIcon' src={sapphireIcon}></img>
                                                    <div className='addElementPrice'>{numberWithCommas(e.elm_price)}</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='addElementDataBody'>
                                            <div className='addElementDataBodyImg'>
                                                <img className='addElementPriceIcon' src={e.fd_image_path}></img>
                                            </div>
                                            <div className='addElementDataBodySet'>
                                                <div className='addElementDataName'>{e.elm_name}</div>
                                                <div className='addElementDataDesc'>{e.elm_desc}</div>
                                            </div>
                                        </div>
                                        <div className='addElementDataFooter'>
                                            <div className='addElelmentDataRoleIconBox'>
                                                <img className='addElementPriceIcon' src={roleIcon}></img>
                                                <div className='addElementRoleText'>{e.elm_role}</div>
                                            </div>
                                            <div className='addElementRoleText' style={{ color: '#FFF' }}>Level <span style={{ color: '#E4DA15' }}>{e.elm_level}</span></div>
                                        </div>
                                    </Col>
                                </>
                            })}
                        </Row>
                    </div>
                    <div ref={bottomElementRef} className="addElementBgSet" style={{ backgroundImage: `url(${bgBottom})` }}>
                        <div className='addElementHeader' style={{ margin: 0, padding: 0 }}>
                            <div className="addElementHeaderLeft">
                                <div className="addElementHeadLine"></div>
                                <div className="addElementHeaderName">ELEMENT PHASE</div>
                            </div>
                        </div>
                        <div className='addElementPhaseBodySet'>
                            <div className='addElementPhaseBody'>
                                {addElementDataOnPhase && addElementDataOnPhase.map((ep, ip) => {
                                    return <>
                                        <div className='addElementPhaseBodyPhase'>
                                            {ep.data && ep.data.map((ed, id) => {
                                                return <>
                                                    {ed.elm_have ? <>
                                                        <div className='addElementPhaseBoxIn'>
                                                            <div className='addElementPhaseIcon'>
                                                                <div className='addElementDataBodyLock'></div>
                                                                <div className='addElementDataBodyImgBox'>
                                                                    <div className='addElementDataBodyImg'>
                                                                        <img className='addElementPriceIcon' src={ed.fd_image_path}></img>
                                                                    </div>
                                                                </div>
                                                                <div className='addElementDataBodyDetail'>
                                                                    <div className='addElementDataBodyDetailName'>{ed.elm_name}</div>
                                                                    <div className='addElementDataBodyDetailDesc'>{ed.elm_desc}</div>
                                                                </div>

                                                            </div>
                                                            <div className='addElementPhaseFooter'>
                                                                <div className='addElelmentDataRoleIconBox'>
                                                                    <img className='addElementPriceIcon' src={roleIcon}></img>
                                                                    <div className='addElementRoleText'>{ed.elm_role}</div>
                                                                </div>
                                                                <div>
                                                                    Level <span style={{ color: '#E4DA15' }}>{ed.elm_level}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </> : <>
                                                        <div className='addElementPhaseBoxIn' style={{ backgroundColor: 'rgba(35,35,35,0.8)' }}>
                                                            <div className='addElementPhaseIcon'>
                                                                <div className='addElementDataBodyLock'>
                                                                    <div className='addElementDataBodyLockIcon'>
                                                                        <img className='addElementPriceIcon' src={keyIcon}></img>
                                                                    </div>
                                                                    <div className='addElementPriceBox' style={{ border: '1px solid #0047FF', backgroundColor: 'rgba(0, 71, 277, 0.2)' }} onClick={() => {
                                                                        if (addElementDataOnPhase[ip]?.data[id - 1]?.elm_have || addElementDataOnPhase[ip]?.data[id - 1]?.elm_have == undefined) {
                                                                            toggleAlertConfirmIsOpen();
                                                                        } else {
                                                                            setMessageBox({
                                                                                ...messageBox,
                                                                                message: 'You must purchase the previous element first.',
                                                                                isError: true,
                                                                                menuTitle: 'BUY ELEMENT FAIL.'
                                                                            });
                                                                            messageBox.message = 'You must purchase the previous element first.';
                                                                            messageBox.isError = true;
                                                                            messageBox.menuTitle = 'BUY ELEMENT FAIL.';
                                                                            toggleAlertMassageIsOpen();
                                                                        }
                                                                    }}>
                                                                        <img className='addElementPriceIcon' src={sapphireIcon}></img>
                                                                        <div className='addElementPrice' style={{ fontSize: '15px' }}>{numberWithCommas(ed.elm_price)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='addElementDataBodyImgBox'>
                                                                    <div className='addElementDataBodyImg'>
                                                                        <img className='addElementPriceIcon' src={ed.fd_image_path}></img>
                                                                    </div>
                                                                </div>
                                                                <div className='addElementDataBodyDetail'>
                                                                    <div className='addElementDataBodyDetailName'>{ed.elm_name}</div>
                                                                    <div className='addElementDataBodyDetailDesc'>{ed.elm_desc}</div>
                                                                </div>

                                                            </div>
                                                            <div className='addElementPhaseFooter'>
                                                                <div className='addElelmentDataRoleIconBox'>
                                                                    <img className='addElementPriceIcon' src={roleIcon}></img>
                                                                    <div className='addElementRoleText'>{ed.elm_role}</div>
                                                                </div>
                                                                <div>
                                                                    Level <span style={{ color: '#E4DA15' }}>{ed.elm_level}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>
                                            })}
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>

                    </div>
                </div>

            </>}


            <AlertConfrim
                isOpen={alertConfirmIsOpen}
                toggle_alertKick={toggleAlertConfirmIsOpen}
                mainFuc={buyElement}
            />

            <AlertMassage
                isOpen={alertMassageIsOpen}
                toggle_alertKick={toggleAlertMassageIsOpen}
                mainFuc={buyElement}
                message={messageBox}
            />
        </>
    );
}
export default AddElement;
