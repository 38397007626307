import React, { useState, useEffect } from 'react';
import Title from "../../customElement/headertitle"
import axios from "axios";
import Configs from "../../../config";
import './mainpageMobile.css';
import mainpageBg from '../../../assets/img/bg/imgmian.jpg';
import Slider from "react-slick";
import moment from 'moment';
import ModalNew from '../../../components/Modalpopup/newsModalPopUp'
  let imgDeful = "../../../../assets/img/bg/imgbdsport.jpg"

export default function MainpageMobile() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    className: "center",
    centerPadding: "60px",
    swipeToSlide: true,
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    }
  };

  const [test, settest] = useState({
    test: ''
  })
  const [tempModal, setTempModal] = useState({
    headType: '',
    titleName: '',
    datas: '',
    dateValue: '',
    link: '',
    language: '',
    imgpath: '',
    news_id: ''
  })
  const [toogModal, setToogModal] = useState(false)

  const [bulletMenu, setBulletMenu] = useState('ALL');
  const [bulletMenuData, setBulletMenuData] = useState([]);

  const [contactsIndex, setContactsIndex] = useState(0);
  const [contactsMenuData, setContactsMenuData] = useState([
    {
      header: 'Services',
      body: '• สนับสนุนบริษัทเพื่อจัดทำระบบสารสนเทศ<br />• สนับสนุนทีมพัฒนาเพื่อให้มีกระบวนการที่ง่ายต่อการดำเนินการ<br />• เอกสาร Template , Process มาตรฐาน ISO29110 , CMMI<br />• สร้างทีม สร้างงาน สร้างอาชีพ<br />• สนุกกับการทำงานในรูปแบบเกมส์ ทำงานจริง รับเงินจริง ปรับจริง<br />'
    },
    {
      header: 'About us',
      body: '• Platform Contact<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />• Technical Support<br />&nbsp;&nbsp;&nbsp;Tel : 098 907 8389. , Line ID : .rivendell<br />• Sales Support<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />• Partner TTT Spaceship<br />&nbsp;&nbsp;&nbsp;Tel : 085 818 8910. , Line ID : n.teewin<br />'
    },
    {
      header: 'Resouce',
      body: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;บริษัทที่ให้บริการด้านไอที โซลูชั่น แนะนำแนวทาง การประยุกต์ใช้ระบบสารสนเทศเพื่อการบริหารจัดการและการฝึกอบรม เน้นการพัฒนากระบวนการแบบก้าวกระโดด เพิ่มประสิทธิภาพการทำงาน ลดระยะเวลาการดำเนินการอย่างมีนัยสำคัญ รวมทั้ง แก้ปัญหา Human error เพื่อให้ธุรกิจของลูกค้าทุกท่านบรรลุเป้าหมายอย่างรวดเร็ว และมั่นคง แข็งแรงยั่งยืน'
    },
    {
      header: 'Our Platform',
      body: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แพลตฟอร์มสำหรับบริการให้ บุคคล ,องค์กร , หน่วยงาน ที่ต้องการจัดทำระบบสารสนเทศในด้านต่าง ๆ โดยแพลตฟอร์มจะมีทีมงาน คุณภาพ จากหลากหลายภาคธุรกิจ ในด้านไอที เพื่อรอรับความต้องการ และดำเนินการวิเคราะห์ระบบ และจัดทำตามมาตรฐานการผลิต ISO29110 , CMMI ทำให้ได้ระบบที่มีคุณภาพสามารถต่อยอดและสนับสนุนงานทางธุรกิจได้'
    },
  ]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        style={{ ...style, display: "none" }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        style={{ ...style, display: "none" }}
      />
    );
  }


  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {

        if (callback) {
          callback(response.data.tokenBox);
        }
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  async function setImginStage(fd_img_id) {
    try {
      let response2 = await axios.get(`https://api.box.com/2.0/files/${fd_img_id}/content`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
        },
        responseType: 'blob',
      });

      return URL.createObjectURL(response2.data)

    } catch {
      return null
    }

  }

  async function filterNewsInfo(value) {

    let temp = {
      keySearch: value,
      page: 1,
      enties: 9999,
      sortList: {},
    };

    await axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/news/filterNewsinspace`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          /*   for (let item = 0; item < response.data.data.length; item++) {
              console.log('Chiosl',response.data.data[item]);
              if (response.data.data[item].imgobject.fd_img_id) {
                  let response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.data[item].imgobject.fd_img_id}/content`, {
                      headers: {
                          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                      },
                      responseType: 'blob',
                  });
                  response.data.data[item].path_file = URL.createObjectURL(response2.data)
              } else {
                response.data.data[item].path_file = null
              }
          } */

          for (let item = 0; item < response.data.data.length; item++) {
            response.data.data[item].ul_file_path = null
            if (response.data.data[item].imgobject) {
              let getpath = await setImginStage(response.data.data[item].imgobject.fd_img_id);

              response.data.data[item].ul_file_path = getpath;
              settest({ ...test, test: '' })
              test.test = '';
            }
          }


          if (bulletMenu == 'ALL') {
            let temp = response.data.data;
            if (temp.length < 3) {
              let fakeData = {
                kn_name: null
              };
              let checkLength = 3 - temp.length;
              for (let index = 0; index < checkLength; index++) {
                temp.push(fakeData);
              }
            }
            setBulletMenuData(response.data.data);
          } else {
            let temp = response.data.data.filter((e) => { return e.kn_name == bulletMenu });
            if (temp.length < 3) {
              let fakeData = {
                kn_name: null
              };
              let checkLength = 3 - temp.length;
              for (let index = 0; index < checkLength; index++) {
                temp.push(fakeData);
              }
            }
            setBulletMenuData(temp);
          }


          settest({ ...test, test: '' })
          test.test = '';

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    GetAccesstokenToken((result) => {
      if (result) {
        filterNewsInfo()
      }
    })

  }, [bulletMenu])

  function onClose(value) {

    document.querySelector('html').classList.remove('modal-opened');
    setToogModal(false)
    settest({ ...test, test: '' })
    test.test = '';
  }

  return (
    <>
      <div className='mob-mainpageMobileBg' style={{ backgroundImage: `url(${mainpageBg})` }}>
        <div className='mob-mainpageMobileBulletBox'>
          <Title name='BULLETIN BOARD' no={1} fix={140} imglong={75} top={0} imgbox={65} imgcorner={22} />
          <div className='mob-mainpageMobileBulletSelect'>
            <div className='mob-mainpageMobileBulletSelectText' style={{ color: `${bulletMenu == 'ALL' ? 'blue' : '#FFF'}`, textDecoration: `${bulletMenu == 'ALL' ? 'underline' : 'none'}` }} onClick={() => {
              setBulletMenu('ALL')
            }}>ALL</div>
            <div className='mob-mainpageMobileBulletSelectText' style={{ color: `${bulletMenu == 'ACTIVITY & NEWS' ? 'blue' : '#FFF'}`, textDecoration: `${bulletMenu == 'NEWS' ? 'underline' : 'none'}` }} onClick={() => {
              setBulletMenu('ACTIVITY & NEWS')
            }}>ACTIVITY & NEWS</div>
            <div className='mob-mainpageMobileBulletSelectText' style={{ color: `${bulletMenu == 'PROMOTION' ? 'blue' : '#FFF'}`, textDecoration: `${bulletMenu == 'PROMOTION' ? 'underline' : 'none'}` }} onClick={() => {
              setBulletMenu('PROMOTION')
            }}>PROMOTION</div>
            <div className='mob-mainpageMobileBulletSelectText' style={{ color: `${bulletMenu == 'TTT SPACESHIP STORY' ? 'blue' : '#FFF'}`, textDecoration: `${bulletMenu == 'STORY' ? 'underline' : 'none'}` }} onClick={() => {
              setBulletMenu('TTT SPACESHIP STORY')
            }}>TTT SPACESHIP STORY</div>
          </div>

          <div className='mob-mainpageMobileBulletSlide'>
            <Slider {...settings}>
              {bulletMenuData && bulletMenuData.map((e, index) => {
                return (<>
                  <div className='mob-mainpageMobileBulletSlideShowBox' style={{ visibility: `${e.kn_name != null ? 'visible' : 'hidden'}` }} onClick={() => {
                          sessionStorage.setItem('News_id', e.news_id);
                    window.location.href = '/viewNews';
                    /* setTempModal({
                      ...tempModal,
                      headType: e.kn_name,
                      titleName: e.news_title,
                      datas: `${moment(e.news_start_date).format('DD MMM')} - ${moment(e.news_end_date).format('DD MMM YYYY')}`,
                      dateValue: e.news_description,
                      link: e.news_url,
                      imgpath: e.ul_file_path,
                      news_id: e.news_id
                    })
                    setToogModal(true) */
              
                  }}>
                    <div className='mob-mainpageMobileBulletSlideShowImg' style={{ backgroundImage: `url(${e.ul_file_path ? e.ul_file_path : imgDeful})` }}></div>
                    <div className='mob-mainpageMobileBulletSlideShowDetail'>
                      {e.kn_name && e.kn_name == 'ACTIVITY & NEWS' &&
                        <>
                          < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #FF2B2B', color: '#FF2B2B' }}>
                            NEWS
                          </div>
                          <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                          <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#FF2B2B', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                        </>
                      }
                      {e.kn_name && e.kn_name == 'PROMOTION' &&
                        <>
                          < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #FDC700', color: '#FDC700' }}>
                            PROMOTION
                          </div>
                          <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                          <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#FDC700', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                        </>
                      }
                      {e.kn_name && e.kn_name == 'TTT SPACESHIP STORY' &&
                        <>
                          < div className='mob-mainpageMobileBulletSlideShowType' style={{ border: '1px solid #0047FF', color: '#0047FF' }}>
                            TTT SPACESHIP STORY
                          </div>
                          <div className='mob-mainpageMobileBulletSlideShowDesc'>{e.news_description || ''}</div>
                          <div className='mob-mainpageMobileBulletSlideShowDate'><i class="fa fa-calendar" aria-hidden="true" style={{ color: '#0047FF', marginRight: '5px' }}></i>{moment(e.news_start_date).format('MMMM DD, YYYY')}</div>
                        </>
                      }


                    </div>
                  </div >
                </>)
              })}
            </Slider>
          </div>
        </div>
        <div className='mob-mainpageMobileContactBox'>
          <div className='mob-mainpageMobileContactBoxSet'>
            <Title name='CONTACTS' no={1} fix={140} imglong={30} top={0} imgbox={55} imgcorner={22} />
            <div className='mob-mainpageMobileContactArrowBox'>
              <div className='mob-mainpageMobileContactArrow' style={{ visibility: `${contactsIndex == 0 ? 'hidden' : 'visible'}`, borderRadius: '5px 0 0 5px' }} onClick={() => {
                let temp = contactsIndex;
                if (temp > 0) {
                  temp = temp - 1;
                  setContactsIndex(temp);
                };
              }}>
                {'<'}
              </div>
              <div className='mob-mainpageMobileContactArrow' style={{ visibility: `${contactsIndex == contactsMenuData.length - 1 ? 'hidden' : 'visible'}`, borderRadius: '0 5px 5px 0' }} onClick={() => {
                let temp = contactsIndex;
                if (temp < contactsMenuData.length - 1) {
                  temp++;
                  setContactsIndex(temp);
                };

              }}>
                {'>'}
              </div>
            </div>
          </div>
          <div style={{ position: 'relative', top: '10%', height: '100%', overflowX: 'auto' }}>
            <div style={{ width: 'fit-content', display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <Title name={contactsMenuData[contactsIndex].header} no={1} fix={140} imglong={75} top={0} imgbox={0} imgcorner={22} />
                <div className='mob-mainpageMobileContactBoxDesc' dangerouslySetInnerHTML={{ __html: contactsMenuData[contactsIndex].body }}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <ModalNew
        isOpen={toogModal}
        onClose={onClose}
        headType={tempModal.headType}
        titleName={tempModal.titleName}
        datas={tempModal.datas}
        dateValue={tempModal.dateValue}
        link={tempModal.link}
        imgpath={tempModal.imgpath}
        newID={tempModal.news_id}

      />
    </>
  )
}
