import React, { useState } from 'react';
import '../../assets/css/OrganizeModal.css'
import logoTTT from '../../assets/img/logo/Rectangle 154.png'

function TotipMD({isOpen,mouseX,mouseY,dataTopil}) {
    let spacship_name = dataTopil.spacship_name;
    let color = dataTopil.color;
    let path_img = dataTopil.path_img;
/* console.log(mouseX,mouseY) */
    return (
        isOpen && (
            <div className={`detailBox ${isOpen ? 'opened' : 'close'}`} style={{top:`${mouseY < 860 ? mouseY+20 : mouseY-75}px`,left:`${mouseX < 1600 ? mouseX+10 : mouseX-250}px`,width:'fit-content'}}>
                <div className='row'>
                    <div className='col-3'>
                        <img src={logoTTT} className='logoDetailBox'></img>
                    </div>
                    <div className='col-9'>
                 
                        <div className='textFed' style= {{marginTop:"15px"}}>{/* FEDERATION DETAIL */}       {dataTopil.project_name}<br/>[{dataTopil.category_name}]   </div>
              
                     {/*    <div className='textBus'>TTT BROTHER</div> */}
                    </div>

                </div>
                
            </div>
        )
    );
}

export default TotipMD;