import React, { useEffect, useState, useRef } from 'react';
import "./superDetail.css"
import iconback_doorStage from "../../../assets/img/icon/IconBack_stage.png";
import iconNumberOfPeople from "../../../assets/img/icon/Frame 238710.png";
import iconTime from "../../../assets/img/icon/Frame 238711.png";
import Configs from "../../../config";
import axios from 'axios';
function SuperDetailMode({ isOpen, toggle_dashFedModal_modal }) {

    const [test, settest] = useState({
        test: ''
      });
    const [filterProjectData, setFilterProjectData] = useState({
        onDue: true,
        onSpace: true,
        onFinish: false
      });

      const [filterSuperDetailList, setfilterSuperDetailList] = useState([]);
      const [filterSuperDetailListResult, setfilterSuperDetailListResult] = useState(filterSuperDetailList);
      const [filterSuperDetailListCount, setfilterSuperDetailListCount] = useState("");
      const [searchQuery, setSearchQuery] = useState('');
      const [loading, setLoading] = useState(false);
      
    useEffect(() => {
        filterProjectSuperDetail(1, 9999);
    }, [])

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        
        const filteredResults = filterSuperDetailList.filter(item =>
            item.project_name.toLowerCase().includes(query.trim().toLowerCase())
        );
        setfilterSuperDetailListResult(filteredResults);
    };

    function filterProjectSuperDetail(page, enties) {
        setLoading(true);
    let temp = {
        user_id: sessionStorage.getItem('user_id'),
        fed_id: sessionStorage.getItem('fed_id'),
        page: page || 1,
        enties: enties || 10,
        onDue: filterProjectData.onDue,
        onSpace: filterProjectData.onSpace,
        onFinish: filterProjectData.onFinish,
      };
    axios({
        method: "POST",
        url: `${Configs.TTT_Game_Service}/api/superDetail/filterSuperDetail`,
        headers: {
          /*     Authorization: getToken(), */
          "X-TTT": `${Configs.API_TTT}`,
          "Content-Type": "application/json",
        },
        data: temp,
      })
        .then(async function (response) { 
            // console.log("superDetail: ",response.data.data);
            
            setfilterSuperDetailList(response.data.data);
            setfilterSuperDetailListResult(response.data.data);
            setfilterSuperDetailListCount(response.data.count);
            setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    function getProgressBarColor(percentage) {
        if (percentage <= 25) {
            return "#FF0000";
        } else if (percentage <= 50) {
            return "#FF7A00";
        } else if (percentage <= 75) {
            return "#FFF414";
        } else {
            return "#1DCC37";
        }
    }

    function getProgressBarColorModule(percentage) {
        if (percentage <= 25) {
            return "#FF000080";
        } else if (percentage <= 50) {
            return "#FF7A0080";
        } else if (percentage <= 75) {
            return "#FFF41480";
        } else {
            return "#1DCC3780";
        }
    }
    




    return (
        <>
          <div className='bgSperDetail'>
    <div className='containerSuperDetail'>
        <div className="row">
            <div className='col-10' style={{ position: 'relative', display: 'flex' }} 

            >
                <img 

                    onClick={() => {
                        window.location.href = `/StageMonitoring`
                    }}
                    style={{ 
                        cursor: 'pointer', 
                        // position: 'absolute', 
                        width: "70px", 
                        height: "70px", 
                        marginTop: "8px",
                        border: "2px solid #fff",
                        borderRadius: "4px",
                        padding: "10px",
                        boxSizing: "border-box"
                    }} 
                    src={iconback_doorStage}
                />
                <div 
                    style={{ 
                        position: 'absolute',       
                        top: '8px',              
                        left: '90px',             
                        width: "10px",      
                        height: "72px",            
                        background: "linear-gradient(#0047FF, #002B99)" 
                    }}
                ></div>
                <span 
                    style={{ 
                        position: 'absolute',       
                        top: '14px',     
                        left: '110px',          
                        color: '#fff',           
                        fontSize: '40px',          
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        fontFamily: 'Oxanium'          
                    }}
                >
                    Super Detail
                </span>
            </div>
            <div className='col-2'>
                <div className='InputSearchSuperDetail'>
                    <input
                        className='inputSearch'
                        placeholder='search'
                        type='text'
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                </div>
            </div>
        </div>
        <div className='row mt-1'>
        <div className='containerCaedSuperDetail'>
            <div className='cardContainerOutline'>
               {!filterSuperDetailListResult ? <></> : <> 
                    {filterSuperDetailListResult.map((card, index) => (
                        <div key={index} className='cardSuperDetail'>

                            <div className='row'>
                            <div className='col-4'>
                                <div className='boxModuleSuperDetail' style={{backgroundColor:getProgressBarColorModule(card.project_progress), marginLeft:"-6px"}}>
                                    <span style={{display: 'block', fontSize:"64px", fontWeight: 700, lineHeight:"80px"}}>{card.moduleFormatList.length || 0}</span>
                                    <span style={{display: 'block', fontSize:"16px",fontWeight: 500, lineHeight:"20px"}}>MODULE</span>
                                </div>
                            </div>
                            <div className='col-8'>
                            <div className='row'>
                                <div className='col-8'>
                                    <span style={{ marginLeft: "-65px", fontSize:"16px", fontWeight:500,lineHeight:"20px" }}>Project Name</span>
                                </div>
                                <div className='col-4'> 
                                    <div     
                                        style={{
                                            textAlign: 'center', 
                                            border: "1px solid #0047FF",
                                            backgroundColor: "#0047FF26",
                                            color: "#0047FF",
                                            padding: "2px, 4px, 2px, 4px", 
                                            borderRadius: "5px",
                                            width: "51px",
                                            height: "15px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            window.location.href = `/StageMonitoring?id=${card.project_id}`
                                        }}
                                    >
                                        <span style={{ fontSize: "10px", fontWeight: 500, lineHeight: "15px", color:"#fff", marginTop:"2px" }}>View All</span>
                                    </div>
                                </div>
                            </div>

                                <div className='row' style={{fontSize:"24px", fontWeight:600,lineHeight:"30px", textAlign:"left"}}>{card.project_name}</div>
                                <div className='row' style={{fontSize:"16px", fontWeight:600,lineHeight:"20px"}}>Total Project Hours : &nbsp;<span style={{color:"#009DFF"}}>{card.SumHoursProject || 0}</span>&nbsp;H</div>
                            </div>
                            </div>

                            <div className='row mt-3'>
                        <div className='DescriptionSuperDetail' style={{paddingLeft:"10px", textAlign:"left", fontSize:"16px", fontWeight:500,lineHeight:"20px"}}>Description : {card.project_description || ''}</div>
                            </div>

                            <div className='row mt-2 containerModuleSuperDetail'>
                            {!card.moduleFormatList ? <></> : <>
                                {card.moduleFormatList.map((card2, index2) => (
                                <div key={index2} className='col-12 moduleSuperDetail'> 
                                
                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "10px"}}>
                                    <div style={{textAlign: "left"}}>
                                        <span style={{fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>
                                            {card2.module_name}
                                        </span>
                                        <span style={{fontSize: "14px", fontWeight: 500, lineHeight: "17.5px"}}>
                                            {" "}(<span style={{color:getProgressBarColor(card2.module_progress)}}>{card2.module_progress || 0}%</span>){" "} 
                                        </span>
                                    </div>
                                    <div style={{textAlign: "right"}}>
                                        <img style={{marginTop:"-3px"}} src={iconNumberOfPeople}/>&nbsp;
                                        <span style={{fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>
                                            {card2.countUser || 0}
                                        </span>
                                        &nbsp;<img  style={{marginTop:"-3px"}}  src={iconTime}/>&nbsp;
                                        <span style={{fontSize: "16px", fontWeight: 500, lineHeight: "20px"}}>
                                            {card2.SumHoursModule || '0'}&nbsp;H
                                        </span>
                                    </div>
                                </div>
                                <div style={{ marginTop:"5px", width: "100%", height:'22px', backgroundColor: "#D9D9D9", borderRadius: "5px", overflow: "hidden" }}>
                                    <div
                                        style={{
                                            width: `${card2.module_progress}%`,
                                            backgroundColor: getProgressBarColor(card2.module_progress),
                                            height: "22px",
                                            borderRadius: "5px"
                                        }}
                                    >
                                    </div>
                                </div>

                                </div>
                            ))}
                            </>
                            }
            
                            </div>
                        </div>
                    ))}
                
                 </>} 

            </div>
        </div>
        </div>
        <div className='row mt-2'>
            <div className='col-12'>
            <div className='textTotalSuperDetail'>TOTAL&nbsp;{filterSuperDetailListResult.length}&nbsp;PROJECT </div>
            </div>
 
        </div>

    </div>
</div>

{loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'green' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    
       </>
    );
}
export default SuperDetailMode;