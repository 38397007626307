import React, { useEffect, useState, useRef } from 'react'
import './addMember.css'
import axios from "axios";
import Configs from "../../../config";
import Select from 'react-select'
import { Input } from 'antd';
import { io } from 'socket.io-client';
import ViewUserProfile from '../../component/ViewUserProfile'

const AddMember = ({
    isOpen,
    reload,
    toggle_modal_addMemberReload,
    toggle_modal_addMember,
    checkData,
    spaceShipId,
    memberSize,
    spaceShipName
}) => {
    const [test, setTest] = useState({ test: '' })
    const [onPage, setOnPage] = useState(1)
    const [sizePage, setSizePage] = useState(999999999)
    const [search, setSearch] = useState({
        text: ''
    })
    const [checkDataIn, setCheckDataIn] = useState([])
    // let checkDataIn = [];
    const [checkMemberAccept, setCheckMemberAccept] = useState([]);
    const [checkMemberInvite, setCheckMemberInvite] = useState([]);
    const [checkMemberUnInvite, setCheckMemberUnInvite] = useState([]);
    const [userDataOptions, setUserDataOptions] = useState([
        /*         { value: 'chocolate', label: 'Chocolate' },
                { value: 'strawberry', label: 'Strawberry' },
                { value: 'vanilla', label: 'Vanilla' } */
    ])

    const [sendData, setSendData] = useState([/* {
        usr_id: ''
    } */])

    const [personalInfoCheck, setPersonalInfoCheck] = useState({
        isOpen: false,
        submode: null
    });

    const [tempValue, setTempValue] = useState({
        user_id: ''
    });

    async function getUserData(value) {
        console.log('testlog', value, checkDataIn);
        let temp = {
            onPage: onPage,
            sizePage: sizePage,
            checkData: value || checkDataIn,
            search: search.text || ''
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/userprofile/getUser`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp,
        })
            .then(async function (response) {
                let temp = [];
                for (let item of response.data) {
                    let check = checkSelect.filter((e)=>{return e.value == item.usr_id})
                    temp.push({
                        value: item.usr_id,
                        label: `${item.usr_name}  ${item.usr_lastname}`,
                        role: item.role_name,
                        tttid: item.usr_ttt_id,
                        isSelect: check.length > 0
                    })
                }
                setUserDataOptions(temp)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const [checkSelect, setCheckSelect] = useState([]);
    const [checkCount, setCheckCount] = useState(0);
    async function sendInvite(value, size) {
        // let temp = checkSelect;
        // let data = checkSelect.filter((e) => { return e.value == value.value })
        // if (data.length > 0) {
        //     document.getElementById(value.value).style.border = "1px solid rgba(255,255,255,0.8)";
        //     let index = temp.findIndex((e) => { return e.value == value.value })
        //     if (index > -1) {

        //         temp.splice(index, 1)
        //     }
        // } else {
        //     temp.push(value)
        //     document.getElementById(value.value).style.border = "1px solid yellow";
        // }

        // setCheckSelect(temp)
        console.log('testlog', userDataOptions);

        let temp = userDataOptions;
        let index = temp.findIndex((e) => { return e.value == value.value })
        let check = checkSelect;
        let data = checkSelect.filter((e) => { return e.value == value.value })

        if (index > -1) {
            if (temp[index].isSelect || data.length > 0) {
                let indexCheck = check.findIndex((e) => { return e.value == value.value })
                if (indexCheck > -1) {
    
                    check.splice(indexCheck, 1)
                }

                temp[index].isSelect = false;
                setUserDataOptions(temp);   
                setTest({...test,test: ''});
                test.test = '';
            } else {
                if (check.length < size) {
                    check.push(value)
            
                    temp[index].isSelect = true;
                    setUserDataOptions(temp);
                    setTest({...test,test: ''});
                    test.test = '';
                }
            }
        }
        setCheckSelect(check)
       
        setTest({...test,test: ''});
        test.test = '';
    }
    //console.log('testlog',checkSelect);
    async function setDisabled() {
        checkSelect.map((e, index) => {
            document.getElementById(e.value).disabled = true
            document.getElementById(e.value).style.border = "1px solid rgba(0,255,0,0.8)";
        })
        setCheckSelect([])
    }

    async function setToSelect() {
        let temp = [...checkMemberInvite, ...checkSelect]
        setCheckMemberInvite(temp)
        // setDisabled()


        let invite = [];
        for (let item of temp) {
            invite.push(item.value)
        }
        let inv = [...checkDataIn,...invite]
        setCheckSelect([])
        checkSelect.map((e, index) => {
            document.getElementById(e.value).style.border = "1px solid rgba(255,255,255,0.8)";
        })
        await getUserData(inv)
    }

    async function setToUnInvite(value) {
        let temp = checkMemberUnInvite;
        let data = checkMemberUnInvite.filter((e) => { return e == value.value })
        if (data.length > 0) {
            document.getElementById('un' + value.value).style.border = "1px solid rgba(255,255,255,0.8)";
            let index = temp.findIndex((e) => { return e == value.value })
            if (index > -1) {

                temp.splice(index, 1)
            }
        } else {
            temp.push(value.value)
            document.getElementById('un' + value.value).style.border = "1px solid yellow";
        }
        setCheckMemberUnInvite(temp)
    }

    async function setToUnSelect() {
        let temp = checkMemberInvite;

        for (let item of checkMemberUnInvite) {

            let index = temp.findIndex((e) => { return e.value == item })
            if (index > -1) {
                temp.splice(index, 1)
                document.getElementById('un' + item).style.border = "1px solid rgba(255,255,255,0.8)";
            }
        }
        setCheckMemberUnInvite([])
        setCheckMemberInvite(temp)
        setTest({ test: '' })
        test.test = ''
        
        let invite = [];
        for (let item of temp) {
            invite.push(item.value)
        }
        let inv = [...checkDataIn,...invite]

        await getUserData(inv)
    }

    async function setSelectMemberToStart() {
        let temp = checkMemberInvite;
        for (let item of temp) {
            document.getElementById(item.value).style.border = "1px solid rgba(255,255,255,0.8)";
            document.getElementById(item.value).disabled = false
        }
    }

    async function sendMemberInvite() {
        if (checkMemberInvite.length > 0) {
            // console.log('testlog checkMemberInvite', checkMemberInvite);
            let temp = {
                su_sps_id: spaceShipId,
                data: checkMemberInvite
            }
            await axios({
                method: 'POST',
                url: `${Configs.TTT_Game_Service}/api/spaceship/addSpaceShipDetail`,
                headers: {
                    //  Authorization: getToken(),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp,
            })
                .then(async function (response) {
                    // console.log('testlog res', response.data.data);
                    // setSendData([])
                    for (let item of response.data.data) {
                        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.value });
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });

            for (let item of checkMemberInvite) {
                let tempNoti = {
                    header: "INVITE YOU TO",
                    // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
                    // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
                    noti_menu_name: "invite_spaceship",
                    user_to_id: item.value,
                    user_to_name: item.label,
                    link_to: "",
                    space_ship_id: spaceShipId
                }
                await axios({
                    method: 'POST',
                    url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: tempNoti,
                })
                    .then(async function (response) {
                        // setSendData([])
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }

        }
    }

    useEffect(async () => {

        if (isOpen === true) {
            setCheckDataIn([]);
            let temp = [];
            for (let item of checkData) {
                temp.push(item.usr_id)
            }
            setCheckDataIn(temp);
            getUserData(temp)
            setCheckMemberAccept(checkData)
        }
    }, [isOpen])

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port:Configs.TTT_Game_Socket_Service_Port
              });
            
        }
    }, [

    ])

    useEffect(() => {
        /*      console.log('onRoom', "PROJECT-CHAT:" + getProjectId.pf_project_id); */
        if (isOpen === true) {

            socketRef.current.emit('leaveOne', "mailBox:" + sessionStorage.getItem('user_id'))
            socketRef.current.emit('join', "mailBox::" + sessionStorage.getItem('user_id'))


        } else {
            socketRef.current.emit('leaveOne', 'mailBox:' + sessionStorage.getItem('user_id'))
            socketRef.current.emit('leaveOne', 'mailBox:' + sessionStorage.getItem('user_id'))
        }
    }, [isOpen])

    return (
        <>
            <div className='addMemberBg' style={{ display: `${isOpen ? '' : 'none'}` }}>
                <div className='addMemberBox'>
                    <div className='addMemberClose'>
                        <div className='addMemberHead'>ADD MEMBER TO {'"' + spaceShipName + '"'}</div>
                        <i className='fa fa-times ButtonClose' onClick={() => {
                            toggle_modal_addMember()
                            toggle_modal_addMemberReload()
                            // setDisabled()
                            setSearch({
                                text: ''
                            })
                            search.text = ''
                        }}></i>
                    </div>
                    <div className='addMemberListBox'>
                        <div className='addMemberListBoxSearch'>
                            <Input
                                className='addMemberSearchInput'
                                value={search.text}
                                onChange={(e) => {
                                    setSearch({
                                        text: e.target.value
                                    })
                                    search.text = e.target.value
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        toggle_modal_addMemberReload()
                                        let invite = [];
                                        for (let item of checkMemberInvite) {
                                            invite.push(item.value)
                                        }
                                        let inv = [...checkDataIn,...invite]
                                
                                        getUserData(inv)
                                        // getUserData(null)
                                        // setDisabled()
                                    }
                                }}
                            />
                            <i class="fa fa-search addMemberIconSend" aria-hidden="true" onClick={() => {
                                toggle_modal_addMemberReload()
                                let invite = [];
                                for (let item of checkMemberInvite) {
                                    invite.push(item.value)
                                }
                                let inv = [...checkDataIn,...invite]
                        
                                getUserData(inv)
                                // getUserData(null)
                                // setDisabled()
                            }}></i>
                        </div>
                        <div className='addMemberListBoxList'>
                            {userDataOptions.map((e, index) => {
                                return (
                                    <>
                                        <button disabled={false} id={e.value} className='addMemberList' style={{border: `1px solid ${e.isSelect ? 'yellow':'white'}`}} onClick={() => {
                                            let temp = 0;
                                            temp = memberSize.max - (checkMemberAccept.length + (checkMemberInvite.length || 0))
                                            // if (checkSelect.length < temp) {
                                                sendInvite(e, temp);
                                            // }
                                        }}>
                                            <div className='addMemberInSpaceBoxListBoxInName'>{e.label}</div>
                                            <div className='addMemberInSpaceBoxListBoxInId'>{e.tttid}</div>
                                            <div className='addMemberInSpaceBoxListBoxInRole'>{e.role}</div>

                                        </button>
                                        <div className='addMemberInSpaceBoxListBoxInEyeLeft'><i class="fa fa-eye" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => {
                                            setPersonalInfoCheck({ ...personalInfoCheck, isOpen: true })
                                            setTempValue({ ...tempValue, user_id: e.value })
                                        }}></i></div>
                                    </>
                                )
                            })}
                        </div>
                    </div>

                    <div className='addMemberCenterBox'>
                        <div className='addMemberSendToInvite' onClick={() => {
                            setToUnSelect()
                        }}>{'<<<'}</div>
                        <div className='addMemberSendToInvite' onClick={() => {
                            setToSelect()
                        }}>{'>>>'}</div>
                        <div style={{margin: '0 auto'}}>{checkSelect.length > 0 ? checkSelect.length : ''}</div>
                    </div>

                    <div className='addMemberInBox'>
                        <div className='addMemberInSpaceBox'>
                            <div className='addMemberInSpaceBoxName'>{spaceShipName}</div>
                            <div className='addMemberInSpaceBoxSize'>{checkMemberAccept.length + checkMemberInvite.length}/{memberSize.max}</div>
                        </div>
                        <div className='addMemberInSpaceBoxList'>
                            <div className='addMemberInSpaceBoxListHead'>MEMBER IN SPAPACESHIP LIST :</div>
                            <div className='addMemberInSpaceBoxListBox'>
                                {checkMemberAccept.map((e) => {
                                    return (
                                        <>
                                            <div className='addMemberInSpaceBoxListBoxIn' style={{ cursor: 'no-drop' }}>
                                                <div className='addMemberInSpaceBoxListBoxInName'>{e.usr_name} {e.usr_lastname}</div>
                                                <div className='addMemberInSpaceBoxListBoxInId'>{e.usr_ttt_id}</div>
                                                <div className='addMemberInSpaceBoxListBoxInRole'>{e.usr_role_name}</div>

                                            </div>
                                            <div className='addMemberInSpaceBoxListBoxInEye'><i class="fa fa-eye" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => {
                                                setPersonalInfoCheck({ ...personalInfoCheck, isOpen: true })
                                                setTempValue({ ...tempValue, user_id: e.usr_id })
                                            }}></i></div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='addMemberInSpaceBoxList'>
                            <div className='addMemberInSpaceBoxListHead'>MEMBER INVITE LIST :</div>
                            <div className='addMemberInSpaceBoxListBox'>
                                {checkMemberInvite.map((e) => {
                                    return (
                                        <>
                                            <button disabled={false} id={'un' + e.value} className='addMemberInSpaceBoxListBoxIn' style={{ cursor: 'pointer' }} onClick={() => {
                                                setToUnInvite(e)
                                            }}>
                                                <div className='addMemberInSpaceBoxListBoxInName'>{e.label}</div>
                                                <div className='addMemberInSpaceBoxListBoxInId'>{e.tttid}</div>
                                                <div className='addMemberInSpaceBoxListBoxInRole'>{e.role}</div>

                                            </button>
                                            <div className='addMemberInSpaceBoxListBoxInEye'><i class="fa fa-eye" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => {
                                                setPersonalInfoCheck({ ...personalInfoCheck, isOpen: true })
                                                setTempValue({ ...tempValue, user_id: e.value })
                                            }}></i></div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='addMemberButtonBox'>
                            <div className='addMemberInviteButtonClick' onClick={() => {
                                sendMemberInvite()
                                toggle_modal_addMember()
                                setSelectMemberToStart()

                                toggle_modal_addMemberReload()
                                // setDisabled()
                                setSearch({
                                    text: ''
                                })
                                search.text = ''
                                setCheckMemberAccept([])
                                setCheckMemberInvite([])
                                setCheckMemberUnInvite([])
                            }}>INVITE</div>
                        </div>
                    </div>
                </div>
                <ViewUserProfile
                    isOpen={personalInfoCheck.isOpen}
                    fncSetProfile={null}
                    submode={personalInfoCheck}
                    setPersonalInfoCheck={setPersonalInfoCheck}
                    close={null}
                    tempValue={tempValue}

                />
                {/*  <div className='addMemberBox'>
                    <div className='row setRowCol' style={{ padding: '10px' }}>
                        <div className='col-11 setRowCol'>
                            <Input
                                className='addMemberSearchInput'
                                value={search.text}
                                onChange={(e) => {
                                    setSearch({
                                        text: e.target.value
                                    })
                                    search.text = e.target.value
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        toggle_modal_addMemberReload()
                                        getUserData()
                                        setDisabled()
                                    }
                                }}
                            />
                        </div>

                        <div className='col-1 setRowCol'>
                            <div className='addMemberSearchSend'>
                                <i class="fa fa-search addMemberIconSend" aria-hidden="true" onClick={() => {
                                    toggle_modal_addMemberReload()
                                    getUserData()
                                    setDisabled()
                                }}></i>
                            </div>
                        </div>
                    </div>
                    <div className='row setRowCol setOverFlowY'>
                        {userDataOptions.map((e, index) => {
                            return (
                                    <button disabled={false} id={e.value} className='addMemberListBox' onClick={() => {
                                        let temp = 0;
                                        temp = memberSize.max - checkData.length;
                                        if (checkSelect.length < temp) {
                                            setSendData({
                                                usr_id: e.value
                                            })
                                            sendData.usr_id = e.value
                                            // sendInvite(e.value, e.label)
                                        }
                                    }}>{e.label}<br />{e.role}<br />{e.tttid}</button>
                            )
                        })}
                    </div>
                </div> */}

            </div >
        </>
    )
}

export default AddMember;
