import React, { useEffect, useState } from 'react'
/* import HeaderTTT from "../components/header/HeaderTTT"; */
import '../../assets/css/register.css'
import ComponentTest from '../component/componentTest'
import axios from "axios";
import InputCustom from '../../components/customElement/inputCustom'
import ButtonCustom from '../../components/customElement/buttonCustom'
import CardStep from '../../components/customElement/cardstep'
import imgTTTshot from '../../assets/img/logo/Final-Logo-05 2.png';
import Numberbig from "../../components/customElement/numbarbig";
import Numberbig2 from "../../components/customElement/numbarbig_2";
import imgbgstep1 from '../../assets/img/bg/bg1.jpg';
import imgbgstep2 from '../../assets/img/bg/bg2.jpg';
import imgbgstep3 from '../../assets/img/bg/bg3.jpg';
import Configs from "../../config";
import PatternFormat from '../../components/customElement/PatternFormat'
import Dopdownclass from '../../components/customElement/dopdownclass'
import Swal from "sweetalert2";
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';
import Select from 'react-select';
import { DatePicker ,ConfigProvider} from 'antd';
import dayjs from 'dayjs';
function Register() {
  const [stepList, setStepList] = useState({
    step1: true,
    vector1: false,
    step2: false,
    vector2: false,
    step3: false,
    vector3: false,
  })
  const [onStep, setOnStep] = useState(1)
  const [maritalOption, setmMritalOption] = useState([])
  const [genderOption, setGenderOption] = useState([])
  const [classlist, setclasslist] = useState([])
  const [register, setregister] = useState({
    username: '',
    name: '',
    lastname: '',
    tel: '',
    email: '',
    class: '',
    classid: '',
    ttt_id: '',
    card_id_num:'',
    date_of_birth:'',
    gender: '',
    genderid: '',
    gender: '',
    genderid: '',
    ms_id: '',
    ms_name: '',
    chcekmode1: {
      chcek1: false,
      chcek2: false,
      chcek3: false,
      chcek4: false,
      class5: false,
      class6: false,
      class7: false,
      class8: false,
      class9: false,
      class10: false,
      onchcek: false
    },
    pass: '',
    confirmpass: '',
    chcekmode3: {
      chcek1: false,
      chcek2: false,
      chcek3: false,
      onchcek: false
    },
  })

  async function maritalStatusOption() {

    await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/master/maritalStatusOption`,
        headers: {
            /*  Authorization: getToken(), */
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },

    })
        .then(async function (response) {
            // console.log(response);
            let tem = []
            response.data.map((e) => {

                var setT = {
                    value: e.ms_id,
                    label: e.ms_name
                }
                tem.push(setT)
            })
            setmMritalOption(tem)
        })
        .catch(function (error) {
            console.log(error);
        });
}
  async function optionGender() {

    await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/master/optionGender`,
        headers: {
            /*  Authorization: getToken(), */
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },

    })
        .then(async function (response) {
            // console.log(response);
            let tem = []
            response.data.map((e) => {

                var setT = {
                    value: e.gender_id,
                    label: e.gender_name
                }
                tem.push(setT)
            })
    
            setGenderOption(tem)
        })
        .catch(function (error) {
            console.log(error);
        });
}



  async function Getdopdownclass() {
    /*     setisLoading(true); */


    await axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/register/getClassUser`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {
        // console.log(response);
        setclasslist(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    maritalStatusOption()
    optionGender()
    Getdopdownclass()
    /*   handleSendMail() */
  }, [])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  function returnclass(data) {
    if (data !== undefined) {
      setregister({
        ...register,
        classid: data.value,
        class: data.label,
      });
      register.classid = data.value
      register.class = data.label

      register.chcekmode1.chcek6 = true
      if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
        register.chcekmode1.onchcek = true
      }
    } else {
      setregister({
        ...register,
        classid: '',
      });
      register.classid = ''

      register.chcekmode1.chcek6 = false
      register.chcekmode1.onchcek = false



    }
    console.log(register);

  }
  function returngender(data) {
    if (data !== undefined) {
      setregister({
        ...register,
        genderid: data.value,
        gender: data.label,
      });
      register.genderid = data.value
      register.gender = data.label

      register.chcekmode1.chcek7 = true
      if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true && register.chcekmode1.chcek7 === true) {
        register.chcekmode1.onchcek = true
      }
    } else {
      setregister({
        ...register,
        genderid: '',
      });
      register.genderid = ''

      register.chcekmode1.chcek7 = false
      register.chcekmode1.onchcek = false



    }
    console.log(register);

  }
/*   function returnclass(data) {
    if (data !== undefined) {
      setregister({
        ...register,
        classid: data.value,
        class: data.label,
      });
      register.classid = data.value
      register.class = data.label

      register.chcekmode1.chcek6 = true
      if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
        register.chcekmode1.onchcek = true
      }
    } else {
      setregister({
        ...register,
        classid: '',
      });
      register.classid = ''

      register.chcekmode1.chcek6 = false
      register.chcekmode1.onchcek = false



    }
    console.log(register);

  } */
  async function saveregiter() {
    let aslist = {
      usr_username: register.username,
      usr_password: register.confirmpass,
      usr_name: register.name,
      usr_lastname: register.lastname,
      usr_phone_number: register.tel,
      usr_email: register.email,
      usr_role_id: register.classid,
      usr_role_name: register.class,
      usr_date_of_birth: register.date_of_birth,
      usr_gender_id: register.genderid,
      usr_gender_name: register.gender,
      usr_ms_id: register.ms_id,
      usr_ms_name: register.ms_name,
      usr_national_id_card_number: register.card_id_num,
      usr_is_verify: null,
      usr_created_date: null,
      usr_updated_date: null,
      usr_created_by: null,
      usr_created_by_name: null,
      usr_updated_by: null,
      usr_updated_by_name: null,
      filelist: [],
      ttt_id: register.ttt_id,

    }

    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/register/saveregiter`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {
        // console.log(response);

        handleSendMail(response.data)


      })
      .catch(function (error) {
        console.log(error);
        /*  Swal.fire({
           icon: "success",
           title: error,
           showConfirmButton: false,
           timer: 1500,
       }) */
      });




  }

  async function checkstep1() {
    let aslist = {
      usr_username: register.username,
      usr_name: register.name,
      usr_lastname: register.lastname,
      usr_phone_number: register.tel,
      usr_email: register.email,
      usr_role_id: register.classid,
      usr_role_name: register.class,
      /*   usr_date_of_birth: null,
        usr_gender_id: "4a26c5ad-1c20-47cc-a081-a544d1aae964",
        usr_gender_name: "ชาย",
        usr_ms_id: "22b6e03a-cc5d-40ec-8d0e-dd6d267635f1",
        usr_ms_name: "marry", */

    }
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/register/checkstep1`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: aslist

    })
      .then(async function (response) {
        // console.log(response);
        if (response.data.status === true) {
          setOnStep(2)
          setStepList({ ...stepList, step2: true, vector1: true })
          setregister({
            ...register,
            ttt_id: response.data.ttt_id,
          });

        }


      })
      .catch(function (error) {
        console.log(error);
        Swal.fire("Error", error.response.data.error[0].errorDis, "error");
      });




  }

  const handleSendMail = async (massgerCodeTTT) => {
    console.log('massgerCodeTTT', massgerCodeTTT);

    var temp = `
    <FONT color="#000" > เรียนคุณ ${register.name} ${register.lastname}, <br /><br />
    </br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ขอแสดงความยินดี ตอนนี้คุณได้เข้าร่วม Platform TTT SpaceShip เรียบร้อยเเล้ว   <br />
    </br />
โดยข้อมูลการเข้าสู่ระบบ ตามรายละเอียดด้านล่างดังนี้    <br /><br />
</br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เข้าสู่ระบบด้วย URL : https://tttspaceship.com/ 
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ชื่อผู้ใช้ : ${register.username || 'ไม่พบข้อมูล'} <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;รหัสผ่าน : tttspaceship@1234 <br />
    <br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* เมื่อคุณได้ทำการ Log in เข้าสู่ระบบ TTT SpaceShip เรียบร้อยเเล้วนั้น เพื่อความปลอดภัยในบัญชีของคุณ <br />
    กรุณาทำการเปลี่ยนรหัสผ่านทันทีหลังจากเข้าใช้งานครั้งเเรก <br /> </br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรุณาตรวจสอบข้อมูลส่วนตัว เเละอัปเดตข้อมูลส่วนตัวของคุณเพื่อให้ระบบ TTT SpaceShip มีข้อมูลที่ถูกต้อง <br />
    สำหรับใช้ภายในระบบ และเพื่อใช้ในการติดต่อกับคุณ <br /><br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ชื่อ : ${register.name || 'ไม่พบข้อมูล'}<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;นามสกุล : ${register.lastname || 'ไม่พบข้อมูล'}<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เบอร์โทร : ${register.tel || 'ไม่พบข้อมูล'}<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;อีเมล : ${register.email || 'ไม่พบข้อมูล'}<br /><br />
    </br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;หากคุณมีคำถามหรือข้อสงสัยใดๆ กรุณาติดต่อเราทางอีเมลที่ <br /> n.teewin@tttbrother.com<br />
    </br /></br />
    <br /> ขอเเสดงความนับถือ,<br />
    </br />ทีม TTT SpaceShip
    </Font>
    `

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/mail/getmail`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        to: `${register.email}`,
        userby: 'TTT',
        subject: `ยินดีตอนรับคุณ ${register.username} `,
        body: temp
        /*   to: `${register.email}`,
          userby: 'TTT',
          subject: `ยินดีตอนรับคุณ ${register.username} `,
          body: `ขอขอบคุณ ${register.username} ที่ร่่วมเป็นหนึ่งใน TTTSPACESHIP TTT ID : ${massgerCodeTTT.code}` */
      },

    })
      .then(async function (response) {
        // console.log(response);
        if (massgerCodeTTT) {
          Swal.fire({
            icon: "success",
            title: `Save`,
            text: `${massgerCodeTTT.massge}`,
            showConfirmButton: true,
          }).then(function () {
            console.log('ไปหน้าอื่นเเล้ว')
            window.location.href = '/#'
          }

          )
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#3b82f6' : '#d1d5db', 
      boxShadow: state.isFocused ? '0 0 0 1px #3b82f6' : null,
      width: modeMobile === true? '95%' : '105%',marginLeft:'10px',
      backgroundColor: 'transparent', 
      border: 'none', 
      boxShadow: 'none', 
      marginTop:'-50px',paddingBottom:'8px',
      '&:hover': {
      
      }
    }),
    menu: (provided) => ({
      ...provided,
      width: '100%',
      backgroundColor: '#021E6F',
      zIndex: 9999,
    }),
    menuPortal: (base) => ({
      ...base,
      width: '100%', // ให้ dropdown อยู่บนสุด
      backgroundColor: '#021E6F',
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#042895' : state.isFocused ? '#042895 ' : null,
      color: state.isSelected ? '#ffffff' : '#ffffff',
      '&:hover': {
        backgroundColor: state.isSelected ? '#042895 ' : '#042895 ',
        color: state.isSelected ? '#ffffff' : '#ffffff'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff', // สีของข้อความที่เลือกแล้วเป็นสีขาว
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal'
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.60)', // สีของ placeholder เป็นสีแดง
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal'
    }),
    input: (provided) => ({
      ...provided,
      color: '#ffffff', // สีของข้อความที่พิมพ์เป็นสีขาว
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '170px', // จำกัดความสูงของ dropdown ไม่เกิน 100px
      overflowY: 'auto',
        // เพิ่ม scroll bar ถ้าเนื้อหาเกิน 100px
    }),
  };


  const [modeMobile, setModeMobile] = useState(false);



  const [mobileMenu, setMobileMenu] = useState(false);
  const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
  const [mobileLogin, setMobileLogin] = useState(false);
  const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
  const [mobileRegister, setMobileRegister] = useState(false);
  const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };



  useEffect(() => {
    /*   getNewsINfo() */
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      setModeMobile(true);
    }
    else {
      setModeMobile(false);
    }


  }, [])


  async function checkallchecking() {
    if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true && register.chcekmode1.chcek7 === true && register.chcekmode1.chcek8 === true && register.chcekmode1.chcek9 === true && register.chcekmode1.chcek10 === true ) {
      register.chcekmode1.onchcek = true
    }
  }
  return (
    <>
   <ConfigProvider
                                                        theme={{
                                                            token: {
                                                                // fontFamily:'Oxanium',
                                                                fontSize:'17.005px;',
                                                                
                                                                /*     colorPrimary: '#85BFFF', */
                                                                colorInfo: '#85BFFF',
                                                                colorBorder: '#0047FF',
                                                                colorText: '#FFF',

                                                                colorTextDescription: 'White',
                                                                colorBgContainer: '#222e51',

                                                                zIndexPopupBase: 99999,
                                                                colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
                                                                colorBgBase: '#001D5F',//พื้นหลัง select ตอนกด down ลงมา

                                                                colorTextQuaternary: 'White',// สีตัวหนังสือของ placeholder


                                                                colorFillTertiary: '#0047FF',// สีตอน hover ใน Select


                                                                colorTextBase: 'White', // ลูกศรใน datepicker & rangepicker




                                                                colorPrimaryBg: '#85BFFF',
                                                                /*        
                                                                       colorBgLayout:'red',
                                                                       colorFillQuaternary:'red', */


                                                            }
                                                        }}
                                                    >
      {modeMobile ? (<>
        <HeaderTTTMobile
          mobileMenu={mobileMenu}
          toggle_mobileMenu={toggle_mobileMenu}
          mobileLogin={mobileLogin}
          toggle_mobileLogin={toggle_mobileLogin}
          mobileRegister={mobileRegister}
          toggle_mobileRegister={toggle_mobileRegister}
        />


        <div className='bg-detail' style={{ left: '-10%', top: '8%', position: 'fixed', width: '90%', overflowY: 'auto', overflowX: 'hidden', height: '100%' }}>
          <div className='bg' />

          <a href='/'><div className="logoregister" style={{ position: 'relative' }} ><img src={imgTTTshot} style={{ height: '85px' }} alt="" /></div></a>
          <Numberbig2 num={onStep} />

          <div
            className="row mt-2 mb-4"
            style={{ paddingLeft: '15%', paddingRight: '15%' }}
          >
            <CardStep num={1} active={stepList.step1} vector={stepList.vector1} />
            <div className="line" style={{ zIndex: '999', width: '7%' }} />
            <CardStep num={2} active={stepList.step2} vector={stepList.vector2} />
            <div className="line" style={{ zIndex: '999', width: '7%' }} />
            <CardStep num={3} active={stepList.step3} vector={stepList.vector3} />
          </div>


          <div className="card-shadow" >
            <div className="card-line" style={{ height: 'auto' }}>
              <div className="card" style={{ height: 'auto',padding:'20px' }}>
                {onStep === 1 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h1 style={{ fontSize: '30px' }}>1. REGISTER</h1>
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'USERNAME'}
                          value={register.username}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              username: e.target.value,
                            });
                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek1 = true
                              if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              }
                            } else {
                              register.chcekmode1.chcek1 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'PERSONAL NAME'}
                          value={register.name}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              name: e.target.value,
                            });
                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek2 = true
                              if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              }
                            } else {
                              register.chcekmode1.chcek2 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'PERSONAL LASTNAME'}
                          value={register.lastname}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              lastname: e.target.value,
                            });

                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek3 = true
                              if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              }
                            } else {
                              register.chcekmode1.chcek3 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <PatternFormat
                          placeholder={'PHONE NUMBER'}
                          value={register.tel}
                          onChange={(e) => {

                            let cut = e.target.value.split('-')
                            let cutphone = ''
                            for (let c = 0; c < cut.length; c += 1) {
                              cutphone += cut[c]

                            }
                            console.log(cutphone);
                            setregister({
                              ...register,
                              tel: cutphone,
                            });
                            let telstring = cutphone
                            console.log(telstring, telstring.trim().length);
                            if (telstring.trim().length < 10) {
                              register.chcekmode1.chcek4 = false
                              register.chcekmode1.onchcek = false
                            } else {
                              register.chcekmode1.chcek4 = true
                              if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              }

                            }
                          }}
                        />

                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'EMAIL'}
                          value={register.email}
                          onChange={(e) => {

                            setregister({
                              ...register,
                              email: e.target.value,
                            });

                            if (isValidEmail(e.target.value) === true) {
                              register.chcekmode1.chcek5 = true
                              if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              }
                            } else {
                              register.chcekmode1.chcek5 = false
                              register.chcekmode1.onchcek = false
                            }
                            console.log(register, isValidEmail(e.target.value));
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3" style={{zIndex:13}}>
                   
                   <div className="inputShadowStyle">
                                         <div className="inputOuterStyle_1">
                                             <div className="inputOuterStyle_2">
                                                 <div className="inputInnerStyledop"></div>
                                             </div>
                                         </div>
                                       

                                             <Select
                                              placeholder={'SELECT ROLE...'}
                                                /*  className="selectcss3" */
                                                 styles={customStyles}
                                                 
                                                 options={classlist}
                                                 value={classlist.filter((e) => { return e.label === register.class })}
                                                 onChange={(e) => {
                                                   console.log(e);
                                           
                                                   setregister({
                                                     ...register,
                                                     classid: e.value,
                                                     class: e.label,
                                                   });
                                                   register.classid = e.value
                                                   register.class = e.label
                                             
                                                   register.chcekmode1.chcek6 = true
                                                  /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                     register.chcekmode1.onchcek = true
                                                   } */
                                                     checkallchecking()
                                                 }}
                                             />
                                        

                                     </div>
                                           
                 
                   </div>
                       <div className="col-12 mb-3" style={{zIndex:12}}>
                       <div className="inputShadowStyle">
                                         <div className="inputOuterStyle_1">
                                             <div className="inputOuterStyle_2">
                                             <DatePicker
                                                         style={{ border:'none'}}
                                                         className="inputInnerStyledop"
                                                         value={register.date_of_birth ? dayjs(register.date_of_birth) : null}
                                                         placeholder="DD/MM/YYYY"
                                                         format={"DD/MM/YYYY"}
                                                         onChange={(date, dateString) => {

                                                             setregister({
                                                                 ...register,
                                                                 date_of_birth: date ? date.$d : null
                                                             })
                                                             if (date !== null) {
                                                               register.chcekmode1.chcek7 = true
                                                              /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                                 register.chcekmode1.onchcek = true
                                                               } */
                                                                 checkallchecking()
                                                             } else {
                                                               register.chcekmode1.chcek7 = false
                                                               register.chcekmode1.onchcek = false
                                                             }
                                                         }}

                                                     />
                                             </div>
                                         </div>
                                       
                                       
                                        

                                     </div>
                      
                 </div>
                   <div className="col-12 mb-3" style={{zIndex:11}}>
                
                <div className="inputShadowStyle">
                                      <div className="inputOuterStyle_1">
                                          <div className="inputOuterStyle_2">
                                              <div className="inputInnerStyledop"></div>
                                          </div>
                                      </div>
                                    

                                          <Select
                                           placeholder={'SELECT GENDER...'}
                                             /*  className="selectcss3" */
                                              styles={customStyles}
                                              
                                              options={genderOption}
                                              value={genderOption.filter((e) => { return e.label === register.gender })}
                                              onChange={(e) => {
                                                console.log(e);
                                              /*   setregister({
                                                  ...register,
                                                  class: e.label,
                                                  classid:e.value
                                                });
                                                register.class = e.label
                                                register.classid = e.value
                                                returnclass() */
                                                setregister({
                                                  ...register,
                                                  genderid: e.value,
                                                  gender: e.label,
                                                });
                                                register.genderid = e.value
                                                register.gender = e.label
                                          
                                                register.chcekmode1.chcek8 = true
                                                /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                  register.chcekmode1.onchcek = true
                                                } */
                                                  checkallchecking()
                                              }}
                                          />
                                     

                                  </div>
                                        
                
                </div>

                <div className="col-12 mb-3" style={{zIndex:10}}>
                
                <div className="inputShadowStyle">
                                      <div className="inputOuterStyle_1">
                                          <div className="inputOuterStyle_2">
                                              <div className="inputInnerStyledop"></div>
                                          </div>
                                      </div>
                                    

                                          <Select
                                           placeholder={'SELECT Marital Status...'}
                                             /*  className="selectcss3" */
                                              styles={customStyles}
                                              
                                              options={maritalOption}
                                              value={maritalOption.filter((e) => { return e.label === register.ms_name })}
                                              onChange={(e) => {
                                                console.log(e);
                                             
                                                setregister({
                                                  ...register,
                                                  ms_id: e.value,
                                                  ms_name: e.label,
                                                });
                                                register.ms_id = e.value
                                                register.ms_name = e.label
                                          
                                                register.chcekmode1.chcek9 = true
                                                /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                  register.chcekmode1.onchcek = true
                                                } */
                                                  checkallchecking()
                                              }}
                                          />
                                     

                                  </div>
                                        
                
                </div>
                <div className="col-12 mb-5" style={{zIndex:9}}>
                
                <PatternFormat
                       placeholder={'CARD ID'}
                       value={register.card_id_num}
                       onChange={(e) => {

                         let cut = e.target.value.split('-')
                         let cutphone = ''
                         for (let c = 0; c < cut.length; c += 1) {
                           cutphone += cut[c]

                         }
                         console.log(cutphone);
                         setregister({
                           ...register,
                           card_id_num: cutphone,
                         });
                         let telstring = cutphone
                         console.log(register.chcekmode1);
                         if (telstring.trim().length < 13) {
                           register.chcekmode1.chcek10 = false
                           register.chcekmode1.onchcek = false
                         } else {
                           register.chcekmode1.chcek10 = true
                           checkallchecking()

                         }
                       }}
                     />
                                        
                
                </div>
                      <div className="col-12 mb-3">
                        <ButtonCustom
                          isuse={register.chcekmode1.onchcek}
                          onClick={() => {
                            if (register.chcekmode1.onchcek === true) {
                              checkstep1()
                            }

                          }}
                          placeholder={'NEXT'}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {onStep === 2 ? (
                  <>
                    <div className="row" >
                      <div className="col-12">
                        <h1>2. Verify your identity
                          with thai id</h1>
                      </div>
                      {/*  <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-5">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div> */}
                      <div

                        className="col-12 mb-5"
                        style={{ textAlign: 'center', marginTop: '36%' }}
                      >
                        <p style={{ fontSize: '19px' }}>
                          <font color="white" >
                            Verify your identity with thai id

                          </font>
                        </p>
                        <p style={{ fontSize: '46px' }}>
                          <font color="white">
                            SUCCESS

                          </font>
                        </p>
                      </div>
                      <div className="col-2 mb-3" />

                      <div className="col-8 mb-3">
                        <ButtonCustom
                          isuse={true}
                          onClick={() => {
                            setOnStep(3)
                            setStepList({ ...stepList, step3: true, vector2: true })
                          }}
                          placeholder={'NEXT'}
                        />
                      </div>
                      {/* <div className="col-6 mb-3">
                      <ButtonCustom
                        onClick={() => {
                          setOnStep(3)
                          setStepList({ ...stepList, step3: true,vector2:true })
                        }}
                        placeholder={'NEXT'}
                      />
                    </div>{' '} */}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {onStep === 3 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h1>3. REGISTER</h1>
                      </div>
                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">
                            TTT ID
                          </font>
                        </p>
                        <InputCustom
                          placeholder={'TTT ID'}
                          value={register.ttt_id}
                          disabled={true}

                        />

                      </div>

                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">
                            THE PASSWORD IS FOR YOU OR ENTER YOUR OWN PASSWORD.
                          </font>
                        </p>
                        <InputCustom
                          placeholder={'PASSWORD'}
                          value={register.pass}
                          type="password"
                          onChange={(e) => {
                            let chcekpass1 = false
                            let chcekpass2 = false
                            let chcekpass3 = false
                            if (e.target.value.length > 7) {
                              chcekpass1 = true
                            }
                            if (/^(?=.*[a-zA-Z])(?=.*\d).+$/.test(e.target.value) === true) {
                              chcekpass2 = true
                            }
                            if (e.target.value === register.pass) {
                              chcekpass3 = true
                            } else {
                              chcekpass3 = false
                            }
                            setregister({
                              ...register,
                              pass: e.target.value,
                            });
                            register.chcekmode3.chcek1 = chcekpass1
                            register.chcekmode3.chcek2 = chcekpass2
                            register.chcekmode3.chcek3 = chcekpass3
                            console.log(register.chcekmode3);
                            if (register.chcekmode3.chcek1 === true && register.chcekmode3.chcek2 === true && register.chcekmode3.chcek3 === true) {
                              register.chcekmode3.onchcek = true
                            } else {
                              register.chcekmode3.onchcek = false
                            }

                          }}
                        />
                        <div className="row">
                          <div className="col-12">
                            <p
                              style={{
                                fontSize: '14px',
                                margin: '0px 0px 0px',
                              /*   textDecorationLine: 'underline', */
                              }}
                            >
                              <font color="#4FC7FB">REQUEST NEW PASSWORD</font>
                            </p>
                          </div>
                          <div className="col-12">
                            <p
                              style={{ fontSize: '14px', margin: '0px 0px 0px' }}
                            >
                              <font color="#4FC7FB">
                                THIS PASSWORD IS VERY STRONG.
                              </font>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">CONFIRM PASSWORD</font>
                        </p>
                        <InputCustom
                          placeholder={'CONFIRM PASSWORD'}
                          type="password"
                          value={register.confirmpass}
                          onChange={(e) => {
                            let chcekpass3 = false
                            if (e.target.value === register.pass) {
                              chcekpass3 = true
                            } else {
                              chcekpass3 = false
                            }
                            setregister({
                              ...register,
                              confirmpass: e.target.value,
                            });
                            register.chcekmode3.chcek3 = chcekpass3
                            if (register.chcekmode3.chcek1 === true && register.chcekmode3.chcek2 === true && register.chcekmode3.chcek3 === true) {
                              register.chcekmode3.onchcek = true
                            } else {
                              register.chcekmode3.onchcek = false
                            }

                          }}
                        />
                        {/*       <div className="row">
                        <div className="col-6">
                          <p
                            style={{
                              fontSize: '10px',
                              margin: '0px 0px 0px',
                              textDecorationLine: 'underline',
                            }}
                          >
                            <font color="#4FC7FB">REQUEST NEW PASSWORD</font>
                          </p>
                        </div>
                        <div className="col-6">
                          <p
                            style={{ fontSize: '10px', margin: '0px 0px 0px' }}
                          >
                            <font color="#4FC7FB">
                              THIS PASSWORD IS VERY STRONG.
                            </font>
                          </p>
                        </div>
                      </div> */}
                        <br />
                      </div>

                      <input
                        id="checkbox"
                        type="checkbox"
                        checked={register.chcekmode3.chcek1}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox">
                        THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.
                      </label>
                      <input
                        id="checkbox2"
                        type="checkbox"
                        checked={register.chcekmode3.chcek2}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox2">
                        THE PASSWORD MUST CONTAIN AT LEAST 1 DIGIT AND 1 ENGLISH
                        LETTER, SUCH AS "1234567A".
                      </label>
                      <input
                        id="checkbox3"
                        type="checkbox"
                        checked={register.chcekmode3.chcek3}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox3">
                        THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.
                      </label>

                      {/*  <div className = 'col-12 mb-3' style= {{lineHeight:'1px'}}>
        <div className = 'row'>
            
        <div className = 'col-1 mt-2'>
        <input id="checkbox" type="checkbox" name="checkbox" className=" ml-2 mr-2"/>
        </div>
           <div className = 'col-11'>
     <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.</font></p>
   
        </div>
        </div>
        <div className = 'row'>
        <div className = 'col-1 mt-2'>
        <input type = "checkbox" className="ml-2 mr-2"/>
        </div>
             <div className = 'col-11'>
       <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">THE PASSWORD MUST CONTAIN AT LEAST 1 DIGIT AND 1 ENGLISH LETTER, SUCH AS "1234567A".</font></p>
        </div>
        </div>
        
        <div className = 'row'>
        <div className = 'col-1 mt-2'>
        <input type = "checkbox" className="ml-2 mr-2"/>
        </div>
             <div className = 'col-11'>
       <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">CONFIRM THAT THE PASSWORDS MATCH.</font></p>
        </div>
        </div>
         
     </div> */}

                      <div className="col-12 mb-3 mt-4" >
                        <ButtonCustom
                          isuse={register.chcekmode3.onchcek}
                          onClick={() => {
                            if (register.chcekmode3.onchcek === true) {
                              setOnStep(3)
                              saveregiter()
                              setStepList({ ...stepList, step3: true, vector3: true })

                            }

                          }}
                          placeholder={'REGISTER'}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

        </div>



      </>) : (<>
        <div className='bg' />
        <a href='/'><div className=" logoregister" ><img src={imgTTTshot} style={{ height: '85px' }} alt="" /></div></a>
        <div className="lay_out">


          {/*  <div style={{marginLeft:'200%'}}> */}
          <Numberbig num={onStep} />
          {/*   </div> */}

          <div
            className="row mt-2 mb-4"
            style={{ paddingLeft: '15%', paddingRight: '15%' }}
          >
            <CardStep num={1} active={stepList.step1} vector={stepList.vector1} />
            <div className="line" />
            <CardStep num={2} active={stepList.step2} vector={stepList.vector2} />
            <div className="line" />
            <CardStep num={3} active={stepList.step3} vector={stepList.vector3} />
          </div>

          <br />
          <div className="card-shadow">
            <div className="card-line">
              <div className="card">
                {onStep === 1 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h1>1. REGISTER</h1>
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'USERNAME'}
                          value={register.username}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              username: e.target.value,
                            });
                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek1 = true
                              checkallchecking()
                             /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              } */
                            } else {
                              register.chcekmode1.chcek1 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'PERSONAL NAME'}
                          value={register.name}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              name: e.target.value,
                            });
                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek2 = true
                              /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              } */
                                checkallchecking()
                            } else {
                              register.chcekmode1.chcek2 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'PERSONAL LASTNAME'}
                          value={register.lastname}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setregister({
                              ...register,
                              lastname: e.target.value,
                            });

                            if (e.target.value.trim().length > 0) {
                              register.chcekmode1.chcek3 = true
                             /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              } */
                                checkallchecking()
                            } else {
                              register.chcekmode1.chcek3 = false
                              register.chcekmode1.onchcek = false
                            }
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <PatternFormat
                          placeholder={'PHONE NUMBER'}
                          value={register.tel}
                          onChange={(e) => {

                            let cut = e.target.value.split('-')
                            let cutphone = ''
                            for (let c = 0; c < cut.length; c += 1) {
                              cutphone += cut[c]

                            }
                            console.log(cutphone);
                            setregister({
                              ...register,
                              tel: cutphone,
                            });
                            let telstring = cutphone
                            console.log(telstring, telstring.trim().length);
                            if (telstring.trim().length < 10) {
                              register.chcekmode1.chcek4 = false
                              register.chcekmode1.onchcek = false
                            } else {
                              register.chcekmode1.chcek4 = true
                              /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              } */
                                checkallchecking()

                            }
                          }}
                        />

                      </div>
                      <div className="col-12 mb-3">
                        <InputCustom
                          placeholder={'EMAIL'}
                          value={register.email}
                          onChange={(e) => {

                            setregister({
                              ...register,
                              email: e.target.value,
                            });

                            if (isValidEmail(e.target.value) === true) {
                              register.chcekmode1.chcek5 = true
                             /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                register.chcekmode1.onchcek = true
                              } */
                                checkallchecking()
                            } else {
                              register.chcekmode1.chcek5 = false
                              register.chcekmode1.onchcek = false
                            }
                            console.log(register, isValidEmail(e.target.value));
                          }}
                        />
                      </div>
                      <div className="col-12 mb-3" style={{zIndex:13}}>
                   
                      <div className="inputShadowStyle">
                                            <div className="inputOuterStyle_1">
                                                <div className="inputOuterStyle_2">
                                                    <div className="inputInnerStyledop"></div>
                                                </div>
                                            </div>
                                          

                                                <Select
                                                 placeholder={'SELECT ROLE...'}
                                                   /*  className="selectcss3" */
                                                    styles={customStyles}
                                                    
                                                    options={classlist}
                                                    value={classlist.filter((e) => { return e.label === register.class })}
                                                    onChange={(e) => {
                                                      console.log(e);
                                              
                                                      setregister({
                                                        ...register,
                                                        classid: e.value,
                                                        class: e.label,
                                                      });
                                                      register.classid = e.value
                                                      register.class = e.label
                                                
                                                      register.chcekmode1.chcek6 = true
                                                     /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                        register.chcekmode1.onchcek = true
                                                      } */
                                                        checkallchecking()
                                                    }}
                                                />
                                           

                                        </div>
                                              
                    
                      </div>
                          <div className="col-12 mb-3" style={{zIndex:12}}>
                          <div className="inputShadowStyle">
                                            <div className="inputOuterStyle_1">
                                                <div className="inputOuterStyle_2">
                                                <DatePicker
                                                            style={{ border:'none'}}
                                                            className="inputInnerStyledop"
                                                            value={register.date_of_birth ? dayjs(register.date_of_birth) : null}
                                                            placeholder="DD/MM/YYYY"
                                                            format={"DD/MM/YYYY"}
                                                            onChange={(date, dateString) => {

                                                                setregister({
                                                                    ...register,
                                                                    date_of_birth: date ? date.$d : null
                                                                })
                                                                if (date !== null) {
                                                                  register.chcekmode1.chcek7 = true
                                                                 /*  if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                                    register.chcekmode1.onchcek = true
                                                                  } */
                                                                    checkallchecking()
                                                                } else {
                                                                  register.chcekmode1.chcek7 = false
                                                                  register.chcekmode1.onchcek = false
                                                                }
                                                            }}

                                                        />
                                                </div>
                                            </div>
                                          
                                          
                                           

                                        </div>
                         
                    </div>
                      <div className="col-12 mb-3" style={{zIndex:11}}>
                   
                   <div className="inputShadowStyle">
                                         <div className="inputOuterStyle_1">
                                             <div className="inputOuterStyle_2">
                                                 <div className="inputInnerStyledop"></div>
                                             </div>
                                         </div>
                                       

                                             <Select
                                              placeholder={'SELECT GENDER...'}
                                                /*  className="selectcss3" */
                                                 styles={customStyles}
                                                 
                                                 options={genderOption}
                                                 value={genderOption.filter((e) => { return e.label === register.gender })}
                                                 onChange={(e) => {
                                                   console.log(e);
                                                 /*   setregister({
                                                     ...register,
                                                     class: e.label,
                                                     classid:e.value
                                                   });
                                                   register.class = e.label
                                                   register.classid = e.value
                                                   returnclass() */
                                                   setregister({
                                                     ...register,
                                                     genderid: e.value,
                                                     gender: e.label,
                                                   });
                                                   register.genderid = e.value
                                                   register.gender = e.label
                                             
                                                   register.chcekmode1.chcek8 = true
                                                   /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                     register.chcekmode1.onchcek = true
                                                   } */
                                                     checkallchecking()
                                                 }}
                                             />
                                        

                                     </div>
                                           
                   
                   </div>

                   <div className="col-12 mb-3" style={{zIndex:10}}>
                   
                   <div className="inputShadowStyle">
                                         <div className="inputOuterStyle_1">
                                             <div className="inputOuterStyle_2">
                                                 <div className="inputInnerStyledop"></div>
                                             </div>
                                         </div>
                                       

                                             <Select
                                              placeholder={'SELECT Marital Status...'}
                                                /*  className="selectcss3" */
                                                 styles={customStyles}
                                                 
                                                 options={maritalOption}
                                                 value={maritalOption.filter((e) => { return e.label === register.ms_name })}
                                                 onChange={(e) => {
                                                   console.log(e);
                                                
                                                   setregister({
                                                     ...register,
                                                     ms_id: e.value,
                                                     ms_name: e.label,
                                                   });
                                                   register.ms_id = e.value
                                                   register.ms_name = e.label
                                             
                                                   register.chcekmode1.chcek9 = true
                                                   /* if (register.chcekmode1.chcek1 === true && register.chcekmode1.chcek2 === true && register.chcekmode1.chcek3 === true && register.chcekmode1.chcek4 === true && register.chcekmode1.chcek5 === true && register.chcekmode1.chcek6 === true) {
                                                     register.chcekmode1.onchcek = true
                                                   } */
                                                     checkallchecking()
                                                 }}
                                             />
                                        

                                     </div>
                                           
                   
                   </div>
                   <div className="col-12 mb-5" style={{zIndex:9}}>
                   
                   <PatternFormat
                          placeholder={'CARD ID'}
                          value={register.card_id_num}
                          onChange={(e) => {

                            let cut = e.target.value.split('-')
                            let cutphone = ''
                            for (let c = 0; c < cut.length; c += 1) {
                              cutphone += cut[c]

                            }
                            console.log(cutphone);
                            setregister({
                              ...register,
                              card_id_num: cutphone,
                            });
                            let telstring = cutphone
                            console.log(register.chcekmode1);
                            if (telstring.trim().length < 13) {
                              register.chcekmode1.chcek10 = false
                              register.chcekmode1.onchcek = false
                            } else {
                              register.chcekmode1.chcek10 = true
                              checkallchecking()

                            }
                          }}
                        />
                                           
                   
                   </div>
               
                      <div className="col-12 mb-3"style={{zIndex:8}}>
                        <ButtonCustom
                          isuse={register.chcekmode1.onchcek}
                          onClick={() => {
                            if (register.chcekmode1.onchcek === true) {
                              checkstep1()
                            }

                          }}
                          placeholder={'NEXT'}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {onStep === 2 ? (
                  <>
                    <div className="row" >
                      <div className="col-12">
                        <h1>2. Verify your identity
                          with thai id</h1>
                      </div>
                      {/*  <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-5">
                      <InputCustom
                        placeholder={''}
                      />
                    </div>
                    <div className="col-2 mb-3">
                      <InputCustom
                        placeholder={''}
                      />
                    </div> */}
                      <div

                        className="col-12 mb-5"
                        style={{ textAlign: 'center', marginTop: '36%' }}
                      >
                        <p style={{ fontSize: '19px' }}>
                          <font color="white" >
                            Verify your identity with thai id

                          </font>
                        </p>
                        <p style={{ fontSize: '46px' }}>
                          <font color="white">
                            SUCCESS

                          </font>
                        </p>
                      </div>
                      <div className="col-2 mb-3" />

                      <div className="col-8 mb-3">
                        <ButtonCustom
                          isuse={true}
                          onClick={() => {
                            setOnStep(3)
                            setStepList({ ...stepList, step3: true, vector2: true })
                          }}
                          placeholder={'NEXT'}
                        />
                      </div>
                      {/* <div className="col-6 mb-3">
                      <ButtonCustom
                        onClick={() => {
                          setOnStep(3)
                          setStepList({ ...stepList, step3: true,vector2:true })
                        }}
                        placeholder={'NEXT'}
                      />
                    </div>{' '} */}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {onStep === 3 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <h1>3. REGISTER</h1>
                      </div>
                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">
                            TTT ID
                          </font>
                        </p>
                        <InputCustom
                          placeholder={'TTT ID'}
                          value={register.ttt_id}
                          disabled={true}

                        />

                      </div>

                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">
                            THE PASSWORD IS FOR YOU OR ENTER YOUR OWN PASSWORD.
                          </font>
                        </p>
                        <InputCustom
                          placeholder={'PASSWORD'}
                          value={register.pass}
                          type="password"
                          onChange={(e) => {
                            let chcekpass1 = false
                            let chcekpass2 = false
                            let chcekpass3 = false
                            if (e.target.value.length > 7) {
                              chcekpass1 = true
                            }
                            if (/^(?=.*[a-zA-Z])(?=.*\d).+$/.test(e.target.value) === true) {
                              chcekpass2 = true
                            }
                            if (e.target.value === register.pass) {
                              chcekpass3 = true
                            } else {
                              chcekpass3 = false
                            }
                            setregister({
                              ...register,
                              pass: e.target.value,
                            });
                            register.chcekmode3.chcek1 = chcekpass1
                            register.chcekmode3.chcek2 = chcekpass2
                            register.chcekmode3.chcek3 = chcekpass3
                            console.log(register.chcekmode3);
                            if (register.chcekmode3.chcek1 === true && register.chcekmode3.chcek2 === true && register.chcekmode3.chcek3 === true) {
                              register.chcekmode3.onchcek = true
                            } else {
                              register.chcekmode3.onchcek = false
                            }

                          }}
                        />
                        <div className="row">
                          <div className="col-6">
                            <p
                              style={{
                                fontSize: '10px',
                                margin: '0px 0px 0px',
                                textDecorationLine: 'underline',
                              }}
                            >
                              <font color="#4FC7FB">REQUEST NEW PASSWORD</font>
                            </p>
                          </div>
                          <div className="col-6">
                            <p
                              style={{ fontSize: '10px', margin: '0px 0px 0px' }}
                            >
                              <font color="#4FC7FB">
                                THIS PASSWORD IS VERY STRONG.
                              </font>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mb-3" style={{ lineHeight: '1px' }}>
                        <p style={{ fontSize: '13px', margin: '0px 0px 0px' }}>
                          <font color="white">CONFIRM PASSWORD</font>
                        </p>
                        <InputCustom
                          placeholder={'CONFIRM PASSWORD'}
                          type="password"
                          value={register.confirmpass}
                          onChange={(e) => {
                            let chcekpass3 = false
                            if (e.target.value === register.pass) {
                              chcekpass3 = true
                            } else {
                              chcekpass3 = false
                            }
                            setregister({
                              ...register,
                              confirmpass: e.target.value,
                            });
                            register.chcekmode3.chcek3 = chcekpass3
                            if (register.chcekmode3.chcek1 === true && register.chcekmode3.chcek2 === true && register.chcekmode3.chcek3 === true) {
                              register.chcekmode3.onchcek = true
                            } else {
                              register.chcekmode3.onchcek = false
                            }

                          }}
                        />
                        {/*       <div className="row">
                        <div className="col-6">
                          <p
                            style={{
                              fontSize: '10px',
                              margin: '0px 0px 0px',
                              textDecorationLine: 'underline',
                            }}
                          >
                            <font color="#4FC7FB">REQUEST NEW PASSWORD</font>
                          </p>
                        </div>
                        <div className="col-6">
                          <p
                            style={{ fontSize: '10px', margin: '0px 0px 0px' }}
                          >
                            <font color="#4FC7FB">
                              THIS PASSWORD IS VERY STRONG.
                            </font>
                          </p>
                        </div>
                      </div> */}
                        <br />
                      </div>

                      <input
                        id="checkbox"
                        type="checkbox"
                        checked={register.chcekmode3.chcek1}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox">
                        THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.
                      </label>
                      <input
                        id="checkbox2"
                        type="checkbox"
                        checked={register.chcekmode3.chcek2}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox2">
                        THE PASSWORD MUST CONTAIN AT LEAST 1 DIGIT AND 1 ENGLISH
                        LETTER, SUCH AS "1234567A".
                      </label>
                      <input
                        id="checkbox3"
                        type="checkbox"
                        checked={register.chcekmode3.chcek3}
                        name="checkbox"
                        className="checkbox"
                      />
                      <label class="checkbox" for="checkbox3">
                        THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.
                      </label>

                      {/*  <div className = 'col-12 mb-3' style= {{lineHeight:'1px'}}>
        <div className = 'row'>
            
        <div className = 'col-1 mt-2'>
        <input id="checkbox" type="checkbox" name="checkbox" className=" ml-2 mr-2"/>
        </div>
           <div className = 'col-11'>
     <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">THE PASSWORD MUST BE AT LEAST 8 CHARACTERS LONG.</font></p>
   
        </div>
        </div>
        <div className = 'row'>
        <div className = 'col-1 mt-2'>
        <input type = "checkbox" className="ml-2 mr-2"/>
        </div>
             <div className = 'col-11'>
       <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">THE PASSWORD MUST CONTAIN AT LEAST 1 DIGIT AND 1 ENGLISH LETTER, SUCH AS "1234567A".</font></p>
        </div>
        </div>
        
        <div className = 'row'>
        <div className = 'col-1 mt-2'>
        <input type = "checkbox" className="ml-2 mr-2"/>
        </div>
             <div className = 'col-11'>
       <p style ={{fontSize:"12px",margin:"0px 0px 0px"}}><font color = "white">CONFIRM THAT THE PASSWORDS MATCH.</font></p>
        </div>
        </div>
         
     </div> */}

                      <div className="col-12 mb-3 mt-4">
                        <ButtonCustom
                          isuse={register.chcekmode3.onchcek}
                          onClick={() => {
                            if (register.chcekmode3.onchcek === true) {
                              setOnStep(3)
                              saveregiter()
                              setStepList({ ...stepList, step3: true, vector3: true })

                            }

                          }}
                          placeholder={'REGISTER'}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </>)}

      </ConfigProvider>
    </>
  )
}

export default Register
