import React, { useEffect, useState, useRef } from 'react';
import step3A from '../image/step3-a.png';
import step31 from '../image/step3-1.png';
import step32 from '../image/step3-2.png';
import redHand from '../image/redHand.png'

function HowToPlayStep3(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step3A} alt="" style={{ bottom: '-7px', left: '-4px', width: '25%' }} />
                <svg height="175" width="125" style={{ position: 'absolute', top: '250px', left: '260px'}}>
                    <line x1="0" y1="175" x2="80" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                    <line x1="80" y1="50" x2="115" y2="50" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                </svg>
                <div style={{position: 'absolute',position: 'absolute', top: '295px', left: '375px', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#0047FF' }}></div>
                <div className='howToPlayStep1Detail' style={{ top: '250px', left: '400px', width: '525px' }}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>การเพิ่มสมาชิกในยานของเรา</div><div>เลือกยานในหน้าแท็บ Spaceship ระบบจะขึ้นหน้า Spaceship Detail ให้กดปุ่ม ADD MEMBER</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '360px', left: '400px', width: '525px' }}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>ค้นหาสมาชิกของเรา</div><div>สามารถค้นหาสมาชิกที่เราต้องการนำขึ้นยาน ผ่านการค้นหาสมาชิกผ่าน TTT ID หรือ ชื่อสมาชิกที่ต้องการนำขึ้นยาน</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '470px', left: '400px', width: '525px' }}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{ marginTop: '-1%' }}><div style={{ fontWeight: '700' }}>เชิญสมาชิกขึ้นยานของเรา</div><div>กดปุ่ม INVITE เพื่อเชิญสมาชิก คำเชิญในระบบจะแจ้งเตือนไปยังบัญชีของสมาชิกคนนั้น</div></div>
                </div>
                <img className='howToPlayStep1ImgBg' src={step31} alt="" style={{ top: '100px', left: '395px', width: '40%' }} />
                <img className='howToPlayStep1ImgBg' src={step32} alt="" style={{ top: '250px', left: '935px', width: '36%', height: '47.5%' }} />
                <svg height="100" width="250" style={{ position: 'absolute', top: '215px', right: '295px'}}>
                    <line x1="0" y1="65" x2="250" y2="65" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="250" y1="65" x2="250" y2="100" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="250" y1="100" x2="0" y2="100" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="0" y1="100" x2="0" y2="65" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="115" y1="65" x2="115" y2="15" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '210px', right: '417.5px', width: '25px', height: '25px', fontSize: '12px'}}>02</div>
                <svg height="75" width="40" style={{ position: 'absolute', bottom: '92px', right: '39px'}}>
                    <line x1="0" y1="0" x2="40" y2="0" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="40" y1="0" x2="40" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="40" y1="25" x2="0" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="0" y1="25" x2="0" y2="0" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="20" y1="25" x2="20" y2="60" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ bottom: '85px', right: '45px', width: '25px', height: '25px', fontSize: '12px'}}>03</div>
                <img className='howToPlayStep1ImgBg' src={redHand} alt="" style={{ top: '155px', left: '970px', width: '2.5%'}}/>
            </div>
        </>
    );
}

export default HowToPlayStep3;