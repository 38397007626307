import React, { useEffect, useState, useRef } from 'react';
import step81 from '../image/step8-1.png';

function HowToPlayStep8(params) {
    return (
        <>
            <div style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: `${params.isOpen ? '' : 'none'}`}}> 
                {/* แก้ไขในนี้ */}
                <img className='howToPlayStep1ImgBg' src={step81} alt="" style={{ top: '150px', left: '50px', width: '55%'}}/>
                <div className='howToPlayStep1Detail' style={{ top: '150px', left: '900px', height: '160px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>01</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%'}}><div style={{fontWeight: '700'}}>สถานะของแต่ละสี</div>
                    <div>สามารถกดตรงปุ่มสีเพื่อบอกการทำงานแต่ละ Module นั้นๆได้</div>
                    <div><span style={{color: '#808080'}}>เทา</span>: สีที่ไม่ได้มีการเริ่มต้นงานใดๆ</div>
                    <div><span style={{color: '#FF0000'}}>แดง</span>: สีที่เราต้องการจะทำ</div>
                    <div><span style={{color: '#FFF300'}}>เหลือง</span>: สีที่เรากำลังทำ</div>
                    <div><span style={{color: '#1FFF1B'}}>เขียว</span>: สีที่งานของเราสำเร็จลุล่วงแล้ว</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '325px', left: '900px', width: '550px', height: '110px'}}>
                    <div className='howToPlayStep1DetailNo'>02</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%'}}><div style={{fontWeight: '700'}}>เปอร์เซ็นต์ของสมาชิก</div><div>เราสามารถกดปุ่มเปอร์เซ็นต์ของงานในส่วนที่เราทำได้ว่าเราทำได้กี่เปอร์เซ็นต์แล้ว หากเปอร์เซ็นต์ที่รวมมาได้ 100% แล้วจะไม่สามารถกดได้</div></div>
                </div>
                <div className='howToPlayStep1Detail' style={{ top: '450px', left: '900px', width: '550px'}}>
                    <div className='howToPlayStep1DetailNo'>03</div>
                    <div className='howToPlayStep1DetailDesc' style={{marginTop: '-2%'}}><div style={{fontWeight: '700'}}>เมนู Elements</div><div>Elements สามารถบอกถึงการทำงานได้มากขึ้นว่าเรากำลังคุยถึง requirements แบบใด แต่ละ icon บ่งบอกถึงการทำงานของมัน</div></div>
                </div>
                <svg width="300" height="100" style={{ position: 'absolute', top: '130px', left: '355px'}}>
                    <line x1="0" y1="55" x2="290" y2="55" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="290" y1="55" x2="290" y2="99" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="290" y1="99" x2="0" y2="99" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="0" y1="99" x2="0" y2="55" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="140" y1="55" x2="140" y2="20" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '125px', left: '480px'}}>03</div>
                <svg width="150" height="100" style={{ position: 'absolute', top: '280px', left: '715px'}}>
                    <line x1="1" y1="1" x2="120" y2="1" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="120" y1="1" x2="120" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="120" y1="25" x2="1" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="1" y1="25" x2="1" y2="1" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="120" y1="15" x2="135" y2="25" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="135" y1="25" x2="135" y2="40" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '315px', left: '835px'}}>02</div>
                <svg width="200" height="100" style={{ position: 'absolute', top: '480px', left: '680px'}}>
                    <line x1="1" y1="1" x2="180" y2="1" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="180" y1="1" x2="180" y2="60" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="180" y1="60" x2="1" y2="60" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="1" y1="60" x2="1" y2="1" style={{ stroke: 'red', strokeWidth: '2' }} />
                    <line x1="90" y1="60" x2="90" y2="75" style={{ stroke: 'red', strokeWidth: '2' }} />
                </svg>
                <div className='howToPlayStep2RedLineNo' style={{ top: '550px', left: '755px'}}>01</div>
            </div>
        </>
    );
}

export default HowToPlayStep8;