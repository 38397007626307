import React, { useEffect, useRef } from 'react'
import img from '../../assets/img/bg/imgbdsport.jpg'
import Card from "../../components/customElement/cardshot";
import axios from "axios";
import Configs from "../../config";
import Swal from "sweetalert2";

import Slider from 'react-slick';

import { io } from 'socket.io-client';

function SpaceShipview({ data, mode, project_id, project_name, project_stage_id, fncAlert, callback }) {
  console.log('project_stage_id', project_stage_id)
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <button type='button' className='prevArrowpackage' onClick={onClick}><i className="fa fa-angle-right"></i></button>
    );
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type='button' className='nextArrowpackage' style={{ marginTop: '-260px' }} onClick={onClick}><i className="fa fa-angle-left"></i></button>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: data.length > 3 ? 3 : data.length,
    slidesToScroll: 1,

    swipeToSlide: true,
    autoplaySpeed: 6000,
    speed: 1000,
    autoplay: true,
    nav: true,
    /*  className:'cutpal', */

    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    beforeChange: function (currentSlide, nextSlide) {
      // console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      // console.log("after change", currentSlide);
    }


  };


  async function offerSpaceship(user, user_name, space_id) {
    let tempNoti = {
      header: "OFFER YOUR SPACESHIP TO PROJECT ",
      // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
      // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
      noti_menu_name: "offer_project",
      user_to_id: user,
      user_to_name: user_name,
      link_to: "",
      space_ship_id: space_id,
      project_id: project_id
    }
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: tempNoti,
    })
      .then(async function (response) {
        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + user });
        // setSendData([])
        fncAlert('SEND OFFER SUCCESS.', false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const socketRef = useRef(null);
  useEffect(() => {

    if (socketRef.current == null) {
      socketRef.current = io(Configs.TTT_Game_Socket_Service, {
        //path: '/Socket-service/socket.io'
        port: Configs.TTT_Game_Socket_Service_Port
      });
    }
  }, [

  ])





  return (
    <>
      <div className="row">
        <div className="" style={{ width: '100%', zIndex: '98', marginLeft: '10px' }}>

          <Slider className="latest-games-active slick "  {...settings}>
            {data.map((e, index) => {
              /*   let arr_fake = [];
                  if(index == data.length - 1 && data.length < 4){
                    let result = 4 - data.length;
                    console.log('result',result);
                    for(let i =0;i < result; i++){
                      arr_fake.push(i);
                    }
                  } */
              // console.log(Configs.TTT_Game_Service_IMG + e.ul_file_path)

              return (<>
                <div className="cardallspaec" >
                  <div style={{ width: '100%', height: `${mode == 'offer' ? '85%' : '100%'}`, position: 'absolute' }} onClick={() => {
                    callback(e.spaceship_id,e.ul_file_path)
                  }}></div>
                  <div className="cardmainspaec" style={{ backgroundImage: `url(${e.ul_file_path/* Configs.TTT_Game_Service_IMG + e.ul_file_path */})`, textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                    /*   callback(e.spaceship_id) */
                  }}><font size={2}>{e.spaceship_name}</font>
                    {mode == 'offer' && (<>
                      <div style={{ position: 'absolute', bottom: '0', width: '100%', zIndex: '1' }}>
                        <button className='btn-block btn-outline-primary' style={{ cursor: 'pointer' }} disabled={(project_stage_id == '7acfb2d4-eb27-406d-a0ff-f4dff01c7460' || project_stage_id == 'd736a8bf-0b5c-4b38-88d6-cc8de684d58f' || project_stage_id == null) ? false : true}
                          onClick={() => { offerSpaceship(e.pi_created_by, e.pi_created_name, e.spaceship_id); }}>OFFER</button>
                      </div>
                    </>)}

                  </div>
                </div>

                {/*    {index == data.length - 1 && arr_fake.map((f)=>{  console.log("INNN"); return ( 
              <>
     
      </>
      )})} */}


              </>)
            })}


            {/*   <div className="cardallspaec">
        <div className="cardmainspaec" style={{backgroundImage: `url(${img})`}}>Empty</div>
      </div>
          <div className="cardallspaec">
        <div className="cardmainspaec" style={{backgroundImage: `url(${img})`}}>Empty</div>
      </div>
      <div className="cardallspaec">
        <div className="cardmainspaec" style={{backgroundImage: `url(${img})`}}>Empty</div>
      </div>
      <div className="cardallspaec">
        <div className="cardmainspaec" style={{backgroundImage: `url(${img})`}}>Empty</div>
      </div> */}


          </Slider>


        </div>
      </div>



    </>
  );
}

export default SpaceShipview;
