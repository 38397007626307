import React, { useState } from 'react';
import Slider from 'react-slick';
import '../../assets/css/info.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";
import LineModalJs from "./lineModal.js";
import * as THREE from 'three';




const LineModal = () => {





    return (
        <>
            <HeaderTTT />

            <div className="info-area live-streaming-two">
                <div className="hf-section-title mb-50 text-center">
                    <h4 className="title"> Modal TEST 3 D</h4>
                </div>
                <div className='row'>
                    <div className='col-12'
                     
                    style={{
                    minWidth: '250px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                  }}
                    >
                        <LineModalJs />
                    </div>

                </div>


            </div>
            <Footer />
        </>
    )

}
export default LineModal