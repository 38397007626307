import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import '../../assets/css/info.css';
import HeaderTTT from "../../components/header/HeaderTTT";
import Footer from "../../components/footer/Footer";
import * as THREE from 'three';

import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import { KTX2Loader } from 'three/addons/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/addons/libs/meshopt_decoder.module.js';
import { arraySlice } from 'three/src/animation/AnimationUtils';

import ModalTotip from './totipMD';
import ModalDetai from './carddetai';

import dataJson from './towerData.json';

const LineModalJs = (params) => {

    const [dataTopil, setDataTopil] = useState({
        spacship_name: '',
        project_name:'',
        category_name:'',
        path_img: '',
        color: ''
    })
    //console.log(params)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpencick, setIsModalOpencick] = useState({
        isOpen: false,
        data: [],
        index: '',
        temp:''
    });
    const [checkCategory, setCheckCategory] = useState({
        check: false
    })
    const [opacityFack, setopacityFack] = useState(null)

    const [positionMouse, setPositionMouse] = useState({
        mouseX: '',
        mouseY: ''
    });
    let camera, scene, renderer, mouse, raycaster;
    let boxHover = [];
    let boxClick = [];
    const containerRef = useRef(null);
    /*  if (params.isRender == true) {
         init(dataJson || []);
 
         render();
     } */

    useEffect(() => {
        setCheckCategory({ ...checkCategory, check: params.checkCategory })
        checkCategory.check = params.checkCategory
        let isOpacity = sessionStorage.getItem('opacity')
        if(isOpacity !== undefined && isOpacity !== null && isOpacity !== ''){
            setopacityFack(JSON.parse(isOpacity));
        }
  
        sessionStorage.setItem('opacity', '')
        
    }, [params.checkCategory])


    /*   console.log('TTTTTt',checkCategory); */
    useEffect(async() => {
        // console.log('testlog',params.checkOnModal);
        // console.log('params.dataTower',params.dataTower)
        let myCanvas = document.querySelector('canvas')
        // console.log("myCanvas", params.dataTower)
        if (myCanvas) {

            myCanvas.remove()

        }
        init(params.checkOnModal ? [] : (params.dataTower || []));
        // init(params.dataTower || []);

        render();
    }, [/* params.dataTower , */ params.isRender,params.checkOnModal])



    function init(data_) {

        const container = document.createElement('div');
        document.body.appendChild(container);
        raycaster = new THREE.Raycaster();
        mouse = new THREE.Vector3();
        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setClearColor(0xbbbbbb, 0);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        container.appendChild(renderer.domElement);



        const environment = new RoomEnvironment(renderer);
        const pmremGenerator = new THREE.PMREMGenerator(renderer);

        scene = new THREE.Scene();
        scene.environment = pmremGenerator.fromScene(environment).texture;
        environment.dispose();
        let maxLength = Math.max(...data_.map((e)=>{return Math.max(...e.AreaSizeList.map(o => o.x)) })) ;
        // console.log('maxLength',maxLength)
        const grid = new THREE.GridHelper(maxLength*2 +100, maxLength*2 +100, 0xffffff, 0xffffff);
        grid.material.opacity = 0.1;
        grid.material.depthWrite = false;
        grid.material.transparent = true;
        scene.add(grid);


        let positionSetX = 5;
        positionSetX = positionSetX * 0.5;

        let positionSetZ = 5;
        positionSetZ = positionSetZ * 0.5;

        let dataTest = data_ || [];

        let count = 0;
        // console.log('itemitem',dataTest);
        for (let item of dataTest) {
     
            for (let i = 1; i <= parseInt(item.sizeMapY); i++) {
                const boxGeometry = new THREE.BoxGeometry(item.budgetX, 1, item.TimeZ);
                const edges = new THREE.EdgesGeometry(boxGeometry);
                const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: '#000000' }));
                scene.add(line);
              
                let opParmter = 0.9
           

           /*      if(item.isHistory !== undefined && item.isHistory === true){ */
                    if(opacityFack !== null && opacityFack !== ''){
                        // console.log('เข้ามาาเเล้ว',opacityFack);
                      let setOp = opacityFack.data.filter((o) => o.y === i)
                    //   console.log('opacityFack',setOp);
  
                      if(setOp.length > 0){
                          opParmter = setOp[0].optiy 
                        //   console.log('treeeeee',opParmter);
                      }else{
                          opParmter =  0.9
                      }
                  }
           
               /*  }else{
                    opParmter = 0.9
                } */
                
                
                const boxMaterial = new THREE.MeshStandardMaterial({
                    color: `${item.color}`,
                    clipShadows: true,
                    transparent: true,
                    opacity: opParmter || 0.9
                });
                const boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
                if (i === 1) {
                    boxMesh.position.y = (i / 2);
                    line.position.y = (i / 2);
                } else {
                    boxMesh.position.y = (i / 2) + (i / 2) - 0.5;
                    line.position.y = (i / 2) + (i / 2) - 0.5;
                }

                boxMesh.position.z = (item.TimeZ / 2) + parseInt(item.position.z);
                boxMesh.position.x = (item.budgetX / 2) + parseInt(item.position.x);
                boxMesh.color = `${item.color}`
                boxMesh.userData = {
                    id: i,
                    color: item.color,
                    otherData: item,
                    elmant: 'btn',
                };
                line.position.z = (item.TimeZ / 2) + parseInt(item.position.z);
                line.position.x = (item.budgetX / 2) + parseInt(item.position.x);

                scene.add(boxMesh);
                boxHover.push(boxMesh);
                boxClick.push(boxMesh);
                count++
            }

        }



        let tarX = 0;

        let tarZ = 0;

        camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 2000);
        camera.position.set(tarX + 50, 50, tarZ + 5);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.addEventListener('change', render);
        controls.minDistance = 1;
        controls.maxDistance = 2000;

        controls.target.set(tarX, 0, tarZ);
        controls.update();
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('click', handleDocumentClick);
        window.addEventListener('resize', onWindowResize);





    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

        render();

    }

    // function onMouseMove(event) {
    //     event.preventDefault();
    //     const container = containerRef.current;

    //     if (!container) {
    //         /*  console.error('Container is null.'); */
    //         return;
    //     }




    //     mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    //     mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    //     raycaster.setFromCamera(mouse, camera);
    //     const intersects = raycaster.intersectObjects(boxHover);

    //     for (let i = 0; i < boxHover.length; i++) {
    //         const originalColor = new THREE.Color(`${boxHover[i].color}`);
    //         boxHover[i].material.color.copy(originalColor);
    //     }

    //     if (intersects.length > 0) {
    //         const intersectedObject = intersects[0].object;
    //         setIsModalOpen(true);
    //         intersectedObject.material.color.set('#ebd45f');  
    //         console.log('intersectedObject.userData.otherData',intersectedObject.userData.otherData);
    //         if(intersectedObject.userData.otherData !== undefined && intersectedObject.serData.otherData !== null ){
    //             setIsModalOpen(true);
    //         }else{
    //             setIsModalOpen(false);
    //             document.body.classList.remove('modal-opened');
    //         }
    //     }

    //     render();
    // }

    function onMouseMove(event) {
        event.preventDefault();
        const container = containerRef.current;
        let isSelct = sessionStorage.getItem('isSelct')
        let isModal = sessionStorage.getItem('isModal')
        let isDefcon = sessionStorage.getItem('isDefcon')
        let isMenu = sessionStorage.getItem('isMenu')
        if (!container) {
            return;
        }

        if (event.clientY > 130 & event.clientY < 780 && (isMenu === false || isMenu === 'false' || isMenu === null || isMenu === 'null' || isMenu === undefined || isMenu === 'undefined')) {
            if (event.clientX > 33 & event.clientY > 153 & event.clientX < 200 & event.clientY < 555) {
                if (isSelct === "false" || isSelct === false) {
                    mouse.x = params.dataTower.length * 1000
                    mouse.y = params.dataTower.length * 1000
                } else {
                    if (event.clientX > 33 & event.clientY > 153 & event.clientX < 200 & event.clientY < 344) {
                        mouse.x = params.dataTower.length * 1000
                        mouse.y = params.dataTower.length * 1000
                    } else {
                        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
                    }
                }
            }else if (event.clientX > 1442 & event.clientY > 107 & event.clientX < 1851 & event.clientY < 785) {
                if (isModal === "true" || isModal === true) {
                    mouse.x = params.dataTower.length * 1000
                    mouse.y = params.dataTower.length * 1000
                } else {
                    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
                }
            } else if (event.clientX > 628 & event.clientY > 562 & event.clientX < 830 & event.clientY < 800) {
                if (isDefcon === true || isDefcon === "true") {
                    mouse.x = params.dataTower.length * 1000
                    mouse.y = params.dataTower.length * 1000
                } else {
                    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
                }
            } else {
                mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
            }
        } else {
            mouse.x = params.dataTower.length * 1000
            mouse.y = params.dataTower.length * 1000
        }

        // console.log('event.clientX',event.clientX);
        // console.log('event.clientY',event.clientY);

        // console.log('isSelct',isSelct);

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(boxHover);

        for (let i = 0; i < boxHover.length; i++) {
            const originalColor = new THREE.Color(`${boxHover[i].color}`);
            boxHover[i].material.color.copy(originalColor);
        }

        let isAnyIntersected = false;

        if (intersects.length > 0) {
            window.addEventListener('mousemove', (event) => {
                setPositionMouse({ ...positionMouse, mouseX: event.clientX, mouseY: event.clientY })
                positionMouse.mouseX = event.clientX;
                positionMouse.mouseY = event.clientY;
                /*      console.log(event.clientX,event.clientY)
                   mousePos = { x: event.clientX, y: event.clientY };
                   mousePosText.textContent = `(${mousePos.x}, ${mousePos.y})`; */
            });
            const intersectedObject = intersects[0].object;
            intersectedObject.material.color.set('#00FFFF');
            if (intersectedObject.userData.otherData !== undefined && intersectedObject.userData.otherData !== null) {
                /* console.log('สีที่เหปลี่ยน',intersectedObject.userData); */
                let element = intersectedObject.userData.otherData
         
                setDataTopil({
                    ...dataTopil,
                    project_name:element.project_name,
                    category_name:element.category_name,
                    spacship_name: element.spacship_name,
                    path_img: element.path_img || '',
                    color: element.color || '#ffff'
                })
                setIsModalOpen(true);
                isAnyIntersected = true;
            }
        }

        if (!isAnyIntersected) {
            setIsModalOpen(false);
            setDataTopil({
                ...dataTopil,
                spacship_name: '',
                category_name:'',
                project_name:'',
                path_img: '',
                color: ''
            })
            closeModal();
        }

        render();
    }







    function handleDocumentClick(event) {
        event.preventDefault();
       

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(boxHover);
        if (intersects.length > 0) {
            const intersectedObject = intersects[0].object;
            // console.log('intersectedObject', intersectedObject.userData);
            setIsModalOpencick({
                ...intersectedObject,
                data: intersectedObject.userData.otherData,
                index: intersectedObject.userData.id,
                isOpen: true,
                temp: intersectedObject.userData.otherData
            });
            isModalOpencick.data = intersectedObject.userData.otherData
            isModalOpencick.isOpen = true
            isModalOpencick.index = intersectedObject.userData.id
            isModalOpencick.temp = intersectedObject.userData.otherData
            sessionStorage.setItem('isModal', true)
            // console.log('Box Color',intersectedObject.userData.otherData);
        }/* else{
            setIsModalOpencick({
                ...isModalOpencick,
                data: [],
                isOpen:false,
              });
            isModalOpencick.data = []
            isModalOpencick.isOpen = false
        } */
    }

    const closeModal = () => {
        /* boxHover.forEach(box => {
          scene.remove(box);
        });
        boxHover = []; */
        /*       init(dataJson) */
        setIsModalOpen(false);
        document.body.classList.remove('modal-opened');
        /*   render(); */
    };






    function clondetai(chack) {
        // console.log(chack);
        setIsModalOpencick({
            ...isModalOpencick,
            data: [],
            isOpen: false,
        });
        isModalOpencick.data = []
        isModalOpencick.isOpen = false
        sessionStorage.setItem('isModal', false)
        sessionStorage.setItem('opacity', null)
        setopacityFack(null);
        params.resetDataTower();
    }


    async function hislog(data) {
     params.historySetData(data);
     let opacityPren = JSON.stringify(data); 
     sessionStorage.setItem('opacity', opacityPren)
     
     setopacityFack(data);
    }



    function render() {
        renderer.render(scene, camera);
    }

    return (<>

        <div className='col-12' id="threejs-container" ref={containerRef}>

        </div>
        {isModalOpencick.isOpen ? ( <ModalDetai
            isOpen={isModalOpencick.isOpen}
            data={isModalOpencick.data}
            index={isModalOpencick.index}
            temp={isModalOpencick.temp}
            clonfuc={clondetai}
            hisfuc={hislog}

        />):(<></>)}
       

        <ModalTotip
            isOpen={isModalOpen}
            mouseX={positionMouse.mouseX}
            mouseY={positionMouse.mouseY}
            dataTopil={dataTopil}
        />
    </>)
}
export default LineModalJs